import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { LoginContainer } from "components/Login";
import SignupForms from "components/Signup/SignupForms";
import backgroundImage from "assets/images/Faith-2000x1340-c-center.jpg";

/**
 * Login
 */
const Signup = () => {
  const intl = useIntl();
  const selectedBanner = banners[0];

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Signup.Title" })}</title>
      </Helmet>
      <LoginContainer banner={selectedBanner}>
        <SignupForms />
      </LoginContainer>
    </>
  );
};

const banners = [
  {
    title: "Gain access to exclusive opportunities and a network of top professionals.",
    label: "Faith Adekogbe",
    description: "Frontend Developer",
    cover: `url(${backgroundImage})`,
  },
];

export default Signup;
