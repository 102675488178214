import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { colors, fontSize } from "style";
import { H3 } from "components/Text";
import { Row } from "components/Containers";
import Tabs from "components/Tabs";
import { TagTicker } from "components/Tags";
import { useTeamHistoricalScores, useTeamScores } from "hooks";
import { TEAM_CATEGORY_SCORES } from "constants/index";
import { DashboardTitle } from "components/Dashboard";
import { Spinner } from "components/Loader";
import { formatNumber } from "utils";

/**
 * DashboardChart
 *
 * @params {Object} intl
 * @params {String} teamId
 * @params {String} profileId
 */
const DashboardChart = ({ intl, teamId, profileId, ...props }) => {
  const chartRef = useRef(null);
  const { formatMessage } = intl;
  const seriesRef = Object.keys(TEAM_CATEGORY_SCORES).map(() => useRef(null));
  const { data: dataStore, loading, error } = useTeamHistoricalScores({ teamId })
  const { data: teamScores, loading: loadingTeamScores } = useTeamScores(teamId);
  const [formattedData, setFormattedData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = Object.values(TEAM_CATEGORY_SCORES).map((item) => (
    <FormattedMessage
      key={item}
      id={`Dashboard.MetricsCard.TitleType_${item}`}
    />
  ))

  /**
   * When tab is selected, isolate the respective line series
   */
  useEffect(() => {
    seriesRef.forEach((item, i) => {
      let series = item.current;
      let visible = ((i + 1) === selectedTab) || selectedTab === 0;

      if (!series) return;

      series.strokes.template.setAll({
        visible,
        strokeWidth: 1
      });

      series.fills.template.setAll({
        visible,
        fillOpacity: 0.2
      });
    });
  },[selectedTab])

  /**
   * AmCharts format-friendly data
   */
  useEffect(() => {
    const tempFormattedData = [];

    dataStore?.historicalScores?.forEach((item) => {
      let itemDate = new Date(item.weekDate);
      tempFormattedData.push({ ...item, ...{ weekDate: itemDate.getTime() }});
    });

    setFormattedData(tempFormattedData);
  },[dataStore]);

  /**
   * Make the chart
   */
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    root.setThemes([ am5themes_Animated.new(root), am5themes_Dark.new(root)]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX:true
    }));

    chart.get("colors").set("step", 3);

    const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));

    cursor.lineY.set("visible", false);

    const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      maxDeviation: 0.3,
      baseInterval: {
        timeUnit: "day",
        count: 1
      },
      startLocation: 0.48,
      endLocation: 0,
      renderer: am5xy.AxisRendererX.new(root, {}),
      tooltip: am5.Tooltip.new(root, {})
    }));

    const xRenderer = xAxis.get("renderer");

    xRenderer.labels.template.setAll({
      fill: am5.color(colors.grayAnatomyLight1),
      fontSize: fontSize.xxsmall
    });

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.3,
      min:0,
      max:100,
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    let yRenderer = yAxis.get("renderer");

    yRenderer.labels.template.setAll({
      fill: am5.color(colors.grayAnatomyLight1),
      fontSize: fontSize.xxsmall
    });

    root.dateFormatter.setAll({
      dateFormat: "yyyy-MM-dd",
      dateFields: ["valueX"]
    });

    Object.keys(TEAM_CATEGORY_SCORES).forEach((type, i) => {
      const label = formatMessage(messages[type]);
      const visible = selectedTab === (i + 1) || selectedTab === 0;

      let series = chart.series.push(am5xy.LineSeries.new(root, {
        name: `Series ${i + 1}`,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: type,
        valueXField: "weekDate",
        tooltip: am5.Tooltip.new(root, {
          labelText: `${label}: {valueY}`
        })
      }));

      series.strokes.template.setAll({
        visible,
        strokeWidth: 1
      });

      series.fills.template.setAll({
        visible,
        fillOpacity: 0.2
      });

      /*
      // May need to add this - don't remove
      series.bullets.push(function() {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Circle.new(root, {
            radius: 4,
            stroke: root.interfaceColors.get("background"),
            strokeWidth: 1,
            fill: series.get("fill")
          })
        });
      });
      */

      series.strokes.template.setAll({
        strokeDasharray: [2, 2],
        strokeWidth: 1
      });

      series.get("tooltip").get("background").set("fillOpacity", 0.5);
      series.data.setAll(formattedData);
      series.appear(1000);
      seriesRef[i].current = series;
    });

    chart.appear(1000, 100);
    chartRef.current = chart;

    return () => {
      root.dispose();
    };
  }, [formattedData]);

  return (
    <ChartContainer {...props}>
      <StyledRow>
        <DashboardTitle
          name={teamScores?.team?.name}
          organizationName={teamScores?.organization?.name}
          loading={loadingTeamScores}
          profileId={profileId}
        />
        <TeamScore>
          <Row>
            <H3 css={css`color:#fff;`}>
              <FormattedMessage
                id="Dashboard.DashboardChart.H3"
                values={{
                  score: formatNumber(teamScores?.score, "", 2)
                }}
              />
            </H3>
            <TagTicker trend={teamScores?.scoreDeltaPercent} />
          </Row>
        </TeamScore>
      </StyledRow>
      <Tabs
        data={[<FormattedMessage key='overAll' id={`Dashboard.MetricsCard.TitleType_OVERALL`} />].concat(tabs)}
        selected={selectedTab}
        onSelect={setSelectedTab}
        onDark
      />
      <ChartCanvas id="chartdiv">
        {loading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        {error && (
          <SpinnerContainer>
            <FormattedMessage id={`Dashboard.DashboardChart.Error`} />
          </SpinnerContainer>
        )}
      </ChartCanvas>
    </ChartContainer>
  );
}

const messages = defineMessages({
  overall: {
    id: 'Dashboard.MetricsCard.TitleType_OVERALL',
  },
  teamDynamics: {
    id: 'Dashboard.MetricsCard.TitleType_TEAM_DYNAMICS',
  },
  engineeringPerformance: {
    id: 'Dashboard.MetricsCard.TitleType_ENGINEERING_PERFORMANCE',
  },
  productDelivery: {
    id: 'Dashboard.MetricsCard.TitleType_PRODUCT_DELIVERY',
  },
  clientSatisfaction: {
    id: 'Dashboard.MetricsCard.TitleType_CLIENT_SATISFACTION',
  },
});

const ChartCanvas = styled.div`
  background: ${colors.purpleRainDark5};
  width:100%;
  height:50rem;
`;

const ChartContainer = styled.div`
  background:  ${colors.purpleRainDark5};
  padding: 3rem 1rem;
  margin-bottom:-3rem;
  width:100%;
`;

const StyledRow = styled(Row)`
  justify-content:space-between;
  align-items:center;
  width:100%;
  margin-bottom:2rem;
  padding-right:4rem;
`;

const TeamScore = styled.div`
  font-weight: ${fontSize.large};
  color:#fff;
`;

const SpinnerContainer = styled.div`
  width:100%;
  height:50rem;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#fff;
`;

DashboardChart.propTypes = {
  intl: PropTypes.object,
  teamId: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired
};

export default injectIntl(DashboardChart);
