import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { useTimeSheetSummary } from "hooks";
import missionTimeGradient from "assets/images/mission-time-gradient.png";
import { TimeSheetIFrame, TimeSheetIFrameUrl } from "./index";
import { fontSize, margins } from "style";
import { colors } from "style";
import { Header } from "components/DisplayBox";
import { H3 } from "components/Text";
import Card from "components/Card";
import { PrimaryLink } from "components/Links";
import { LINKS } from "constants/index";
import { formatHours } from "utils";

/**
 * TimeSheetSummary
 */
const TimeSheetSummary = () => {
  const { loading, data } = useTimeSheetSummary();
  const todayMonth = moment().format("MMMM");

  if (loading || !data || data?.length === 0) return null;

  return (
    <>
      <StyledHeader marginSize={margins.normal} noUnderline>
        <H3>Time Sheets for {todayMonth}</H3>
        <PrimaryLink to={LINKS.time_sheet} withMoreIcon>
          <FormattedMessage id="Missions.TimeSheets.TimeSheetSummary.CompleteLink" />
        </PrimaryLink>
      </StyledHeader>
      <div css={styles.container}>
        {data.map((item) => (
          <div key={item?.name} css={styles.item}>
            <Card key={item?.name}>
              <Header marginSize={margins.normal}>
                <H3>{item?.name}</H3>
                <div>
                  <FormattedMessage
                    id="Missions.TimeSheets.TimeSheetSummary"
                    values={{
                      completedHours: <b>{formatHours(item?.timeSheetSummary?.completedMinutes, false)}</b>,
                      allocatedHours: <b>{formatHours(item?.timeSheetSummary?.allocatedMinutes, false)}</b>,
                    }}
                  />
                </div>
              </Header>
              <div css={styles.time_content}>
                <div css={styles.time_data}>
                  <TimeSheetIFrame
                    src={TimeSheetIFrameUrl(item)}
                    insights={item?.timeSheetSummary?.insights}
                    billableMonth={item?.timeSheetSummary?.billableMonth}
                  />
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </>
  );
};

const StyledHeader = styled(Header)`
  margin-bottom: 0;
`;

const styles = {
  container: css`
    display: flex;
    gap: 3rem;
    padding-bottom: 3.2rem;
    margin-bottom: 3.2rem;
    flex-wrap: wrap;
    border-bottom: 1px ${colors.grayAnatomyLight3} solid;
  `,
  item: css`
    flex: 1;
    min-width: 30rem;
  `,
  image: css`
    margin: 0 1.6rem;
    background-image: url(${missionTimeGradient});
    min-height: 6.4rem;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.4rem;
  `,
  title: css`
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.33;
    color: ${colors.white};
  `,
  hours_container: css`
    padding: 1rem 1rem;
    border-radius: 0.6rem;
    background-color: ${colors.grayAnatomyLight5};
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  `,
  time_content: css`
    display: flex;
    padding: 0 ${margins.normal};
  `,
  time_graph: css`
    width: 100%;
    padding-left: 4rem;
  `,
  time_data: css`
    width: 100%;
  `,
  billable_month: css`
    font-size: ${fontSize.small};
    font-weight: 500;
  `,
  hours_text: css`
    font-size: ${fontSize.small};
    font-weight: 400;
    color: ${colors.purpleRainDark2};
  `,
};

export default TimeSheetSummary;
