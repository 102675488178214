import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "components/Buttons";
import { RoleForm } from "components/Missions/Roles";
import { useAuth } from "hooks";

/**
 * RoleAddButton
 */
const RoleAddButton = (props) => {
  const [showForm, setShowForm] = useState(false);
  const { isAdmin } = useAuth();

  if (!isAdmin) return null;

  return (
    <>
      <PrimaryButton onClick={() => setShowForm(true)}>
        <FormattedMessage id={`Mission.RoleAddButton.Button`} />
      </PrimaryButton>
      <RoleForm show={showForm} onClose={() => setShowForm(false)} isEditing={false} {...props} />
    </>
  );
};

export default RoleAddButton;
