import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useQueryData, useToast } from "hooks";
import { GET_INTERVIEWS } from "graphql/queries";
import { HIREFLIX_INVITE_CANDIDATE } from "graphql/mutations";

/**
 * useCandidateProfileInterview
 *
 * @params {String}   profileId
 * @params {Boolean}  isAdmin
 */
export default function useCandidateProfileInterview({ profileId, isAdmin }) {
  const { toast } = useToast();
  const STATUS_COMPLETE = "completed";
  const [publicUrl, setPublicUrl] = useState();
  const [filteredData, setFilteredData] = useState();
  const [isComplete, setIsComplete] = useState(false);
  const [getInterviewUrl, { data: interviewData }] = useMutation(HIREFLIX_INVITE_CANDIDATE);
  const { data, loading } = useQueryData({
    queryName: GET_INTERVIEWS,
    keyName: "interviews",
    variables: {
      profileId,
    },
  });
  const unfilteredData = data?.interviews?.nodes;

  useEffect(() => {
    const completedInterview = unfilteredData?.filter((item) => item?.interviewResponse?.completed);
    const isComplete = completedInterview && completedInterview.length > 0;

    setIsComplete(isComplete);
  }, [unfilteredData]);

  useEffect(() => {
    setPublicUrl(interviewData?.addInterviewResponse?.interviewResponse?.publicUrl);
  }, [interviewData]);

  useEffect(() => {
    if (publicUrl) {
      // eslint-disable-next-line no-undef
      window.location = publicUrl;
    }
  }, [publicUrl]);

  // For Admins, only show data that has a response
  useEffect(() => {
    setFilteredData(
      isAdmin
        ? unfilteredData?.filter(
            (interview) => interview?.interviewResponse && interview?.interviewResponse?.status === STATUS_COMPLETE
          )
        : unfilteredData
    );
  }, [unfilteredData, isAdmin]);

  const handleInterviewLink = (item) => {
    const { id: interviewId, interviewResponse } = item;

    if (isAdmin) {
      // eslint-disable-next-line no-undef
      window.location = interviewResponse?.privateUrl;
    } else if (interviewResponse?.publicUrl) {
      setPublicUrl(interviewResponse?.publicUrl);
    } else {
      getInterviewUrl({
        variables: {
          input: {
            profileId,
            enableNotifications: false,
            params: {
              interviewId,
            },
          },
        },
        onError: () => {
          toast.error(<FormattedMessage id={"Candidate.CandidateInterview.Error"} />);
        },
      });
    }
  };

  return {
    data: filteredData,
    loading,
    isComplete,
    handleInterviewLink,
    STATUS_COMPLETE,
  };
}
