import { gql } from "@apollo/client";
import { PUBLIC_MISSION_FIELDS, PUBLIC_MISSION_ROLE_FIELDS } from "graphql/fragments";

const GET_PUBLIC_MISSION_ROLE = gql`
  ${PUBLIC_MISSION_ROLE_FIELDS}
  ${PUBLIC_MISSION_FIELDS}
  query GetPublicMissionRole($slug: ID!) {
    publicMissionRole(slug: $slug) {
      ...PublicMissionRoleFields
      mission {
        ...PublicMissionFields
      }
    }
  }
`;

export { GET_PUBLIC_MISSION_ROLE };
