import React from "react";
import { Toaster } from "react-hot-toast";
import { colors, fontSize } from "style";

const Toast = () => (
  <Toaster
    position="top-center"
    reverseOrder={false}
    gutter={8}
    containerClassName=""
    containerStyle={{}}
    toastOptions={{
      duration: 5000,
      style: {
        background: colors.purpleRainDark2,
        color: "#fff",
        fontSize: fontSize.small,
        fontWeight: 500,
        boxShadow: "0 1px 3px 0 rgba(85, 76, 197, 0.16)",
      },
      // Default options for specific types
      success: {
        duration: 3000,
        theme: {
          primary: "green",
          secondary: "black",
        },
      },
    }}
  />
);

export default Toast;
