import React from "react";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { GET_DAILY_REPORT_SETTINGS } from "graphql/queries";
import { CHANGE_DAILY_REPORT_STATUS } from "graphql/mutations";
import { useQueryData, useToast } from "hooks";

export default function useDailyReportSettings({ id, skip }) {
  const keyName = "dailyReportSettings";
  const { toast } = useToast();
  const { loading, data, error, refetch } = useQueryData({
    queryName: GET_DAILY_REPORT_SETTINGS,
    keyName: keyName,
    skip,
    variables: { id },
  });

  const [changeStatus, { loading: changingStatus }] = useMutation(CHANGE_DAILY_REPORT_STATUS);

  /**
   * @description Handle the status change
   *
   * @param {String}  teamId
   * @param {String}  status
   */
  const handleStatusChange = (teamId, status) => {
    changeStatus({
      variables: {
        input: {
          teamId,
          status,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="useDailyReportSettings.StatusChangeSuccess" />),
      onError: () => toast.error(<FormattedMessage id="useDailyReportSettings.StatusChangeError" />),
    });
  };

  return {
    loading,
    data: data?.[keyName],
    error,
    handleStatusChange,
    changingStatus,
    refetch,
  };
}
