import React from "react";
import { useQueryData, useToast } from "hooks";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { GET_ORGANIZATION, GET_ORGANIZATIONS } from "graphql/queries";
import { ARCHIVE_ORGANIZATION, RESTORE_ORGANIZATION, UPDATE_ORGANIZATION_CONTACT } from "graphql/mutations";

export default function useOrganization({ id, includeMissions = false } = {}) {
  const { toast } = useToast();
  const keyName = "organization";
  const { data, loading, error, refetch } = useQueryData({
    queryName: GET_ORGANIZATION,
    keyName,
    skip: !id,
    variables: {
      id,
      includeMissions,
    },
  });

  const [handleUpdate, { loading: updating }] = useMutation(UPDATE_ORGANIZATION_CONTACT, {
    refetchQueries: [GET_ORGANIZATION],
  });

  const [handleRemoveOrganization] = useMutation(ARCHIVE_ORGANIZATION, {
    refetchQueries: [GET_ORGANIZATIONS],
  });

  const [handleRestoreOrganization] = useMutation(RESTORE_ORGANIZATION, {
    refetchQueries: [GET_ORGANIZATIONS],
  });

  const handlePrimaryChange = ({ id: contactId, firstName, lastName, email }) => {
    handleUpdate({
      variables: {
        input: {
          id,
          params: {
            users: { id: contactId, firstName, lastName, email },
            primaryContact: true,
          },
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="Organization.MakePrimaryContact.MessageSuccess" />),
      onError: () => toast.error(<FormattedMessage id="Organization.MakePrimaryContact.MessageError" />),
    });
  };

  const removeOrganization = (id) => {
    handleRemoveOrganization({
      variables: {
        input: {
          id,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="useOrganization.RemoveSuccess" />),
      onError: (error) => {
        toast.error(error.message);
      },
    });
  };

  const restoreOrganization = (id) => {
    handleRestoreOrganization({
      variables: {
        input: {
          id,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="useOrganization.RestoreSuccess" />),
      onError: () => toast.error(<FormattedMessage id="useOrganization.RestoreError" />),
    });
  };

  return {
    loading: loading,
    data: data?.organization,
    error,
    isEditable: true,
    refetch,
    handlePrimaryChange,
    removeOrganization,
    restoreOrganization,
    updating,
    keyName
  };
}
