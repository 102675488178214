import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { ROLE_OPTIONS, FormRole, FormExperience, FormEmail, SignupComplete } from "components/Signup";
import { useTracking } from "hooks";
import { TRACKING_SECTIONS, TRACKING_PAGE_VIEWS, LINKS, ORIGINS } from "constants/index";
import { colors, fontSize } from "style";
import { Col, Row } from "components/Containers";
import { PrimaryLink } from "components/Links";

/**
 * SignupForms
 */
const SignupForms = () => {
  const extractFromSearchParams = () => {
    const [searchParams] = useSearchParams();
    let signupOriginType, signupOriginId;
    const originKey = Object.keys(ORIGINS).find((key) => {
      const value = searchParams.get(key);

      if (value) {
        signupOriginType = ORIGINS[key];
        signupOriginId = value;
        return true;
      }

      return false;
    });

    return {
      partner: searchParams.get("partner"),
      channel: searchParams.get("channel"),
      source: searchParams.get("source"),
      ...(originKey && { signupOriginType, signupOriginId }),
    };
  };

  const initialFormData = extractFromSearchParams();
  const [formData, setFormData] = useState(initialFormData);
  const [step, setStep] = useState(1);
  const [signupCompleted, setSignupCompleted] = useState(false);
  const handleFormChange = (payload) => setFormData({ ...formData, ...payload });
  const { trackPage, trackablePage, identifyEvent } = useTracking();

  useEffect(() => {
    if (TRACKING_SECTIONS[step] && trackablePage(TRACKING_SECTIONS[step])) {
      trackPage(TRACKING_PAGE_VIEWS[TRACKING_SECTIONS[step]].page_name, TRACKING_PAGE_VIEWS[TRACKING_SECTIONS[step]]);
    }
  }, [step]);

  if (signupCompleted) {
    return <SignupComplete formData={formData} />;
  }

  function getForm() {
    let { role, ...rest } = formData;

    switch (step) {
      default:
        return (
          <Col>
            <FormRole
              values={formData}
              onChange={handleFormChange}
              onSubmit={(payload) => {
                handleFormChange(payload);
                setStep(2);
              }}
            />
            <span css={styles.already_member}>
              <FormattedMessage
                id="Signup.SignupHeader.Text"
                values={{
                  link: (
                    <PrimaryLink href={LINKS.login} routerEnabled>
                      <FormattedMessage id="Signup.SignupHeader.Link" />
                    </PrimaryLink>
                  ),
                }}
              />
            </span>
          </Col>
        );

      case 2:
        return (
          <FormExperience
            values={formData}
            onChange={handleFormChange}
            onCancel={() => {
              setStep(1);
            }}
            onSubmit={(payload) => {
              handleFormChange(payload);
              setStep(3);
            }}
          />
        );

      case 3:
        return (
          <FormEmail
            values={{
              ...rest,
              ...{
                archetype: findArchetypeByRole(role),
              },
            }}
            onCompleted={(res) => {
              identifyEvent(res?.userRegister?.profile);
              setSignupCompleted(true);
            }}
            onChange={handleFormChange}
            onCancel={() => {
              setStep(2);
            }}
          />
        );
    }
  }

  return (
    <>
      <Row css={styles.container}>
        <span css={styles.title}>
          <FormattedMessage id="Signup.SignupForms.Title" />
        </span>
        <span css={styles.counter}>
          <FormattedMessage id="Signup.StepCounter" values={{ step, total: 3 }} />
        </span>
      </Row>
      <div css={styles.container}>{getForm()}</div>
    </>
  );
};

function findArchetypeByRole(role) {
  const selectedRole = ROLE_OPTIONS.filter((item) => item.value === role);

  return selectedRole && selectedRole[0].archetype;
}

const styles = {
  container: css`
    justify-content: space-between;
    margin-bottom: 1.5rem;
  `,
  title: css`
    color: ${colors.black};
    font-size: ${fontSize.xxlarge};
    font-weight: 700;
  `,
  counter: css`
    font-size: ${fontSize.normal};
    color: ${colors.grayAnatomyLight1};
    font-weight: 300;
  `,
  subtitle: css`
    font-size: ${fontSize.normal};
    color: ${colors.grayAnatomyLight1};
    font-weight: 500;
    margin-bottom: 3rem;
  `,
  already_member: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyLight1};
    margin: 3rem auto 0 auto;
  `,
};

export default SignupForms;
