import React from "react";
import PropTypes from "prop-types";
import { PrimaryLink } from "components/Links";
import styled from "@emotion/styled";
import { Row } from "components/Containers";

/**
 * ListTitle
 * @param {Object}  list
 * @param {Function}  onClick
 */
const ListTitle = ({ list, onClick, ...props }) => {
  const handleClick = (event) => {
    event.stopPropagation();
    onClick?.(list);
  };

  return (
    <StyledRow {...props}>
      {onClick ? (
        <PrimaryLink onClick={handleClick}>
          <span>{list.title}</span>
        </PrimaryLink>
      ) : (
        <span>{list.title}</span>
      )}
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  flex-wrap: nowrap;
  display: inline-flex;
`;

ListTitle.propTypes = {
  list: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default ListTitle;
