import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { MEMBER_STATUS, PROFILE_STATUS_MAPPINGS } from "constants/index";
import { useProfile } from "hooks";
import { PrimaryButton } from "components/Buttons";
import { ProfileArchiveForm } from "components/Profile";
import DropDown, { DropDownToggle, DropDownMenu, DropDownItem } from "components/DropDown";

/**
 * ProfileStatusUpdateButton
 * @param {object} profile
 */
const ProfileStatusUpdateButton = ({ profile, ...props }) => {
  const { handleSubmitForReview, handleMoveToVetting } = useProfile({ profileId: profile?.id });
  const [options, setOptions] = useState([]);
  const [showArchiveForm, setShowArchiveForm] = useState(false);

  useEffect(() => {
    if (profile?.status) {
      setOptions(PROFILE_STATUS_MAPPINGS[profile.status] || []);
    }
  }, [profile?.status]);

  const handleChange = (status) => {
    if (status === profile?.status) return;

    if (status === MEMBER_STATUS.reviewing) {
      handleSubmitForReview();
    } else if (status === MEMBER_STATUS.archived) {
      setShowArchiveForm(true);
    } else if (status === MEMBER_STATUS.assessing) {
      handleMoveToVetting();
    }
  };

  if (options.length === 0) return null;

  return (
    <>
      <DropDown {...props}>
        <DropDownToggle as={PrimaryButton} css={styles.dropdown}>
          <FormattedMessage id={`Profile.ProfileStatusUpdateButton.Button`} />
        </DropDownToggle>
        <DropDownMenu>
          {options.map((option) => (
            <DropDownItem key={option} onClick={() => handleChange(option)}>
              <FormattedMessage id={`Profile.ProfileStatusUpdateButton.${option}`} />
            </DropDownItem>
          ))}
        </DropDownMenu>
      </DropDown>
      <ProfileArchiveForm show={showArchiveForm} onClose={() => setShowArchiveForm(false)} profileId={profile?.id} />
    </>
  );
};

const styles = {
  dropdown: css`
    width: 100%;
  `,
};

ProfileStatusUpdateButton.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileStatusUpdateButton;
