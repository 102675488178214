import { GET_TERM_SHEET_PAST_EVENTS, GET_TERM_SHEET_FUTURE_EVENTS } from "graphql/queries";
import { useQueryData } from "hooks";

export default function useTermSheetEvents({ teamId, isPastEvents }) {
  const keyName = "termSheetTeamEvents";
  const nestedKeyName = isPastEvents ? "pastEvents" : "futureEvents";
  const { loading, data, error, refetch, refetching } = useQueryData({
    queryName: isPastEvents ? GET_TERM_SHEET_PAST_EVENTS : GET_TERM_SHEET_FUTURE_EVENTS,
    keyName,
    variables: {
      teamId,
    },
    notifyOnNetworkStatusChange: true,
    skip: Number.isInteger(parseInt(teamId)) === false,
  });

  return {
    loading: loading || refetching,
    refetch,
    data: data && data[keyName][nestedKeyName].nodes,
    error,
  };
}
