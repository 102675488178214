import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import Card from "components/Card";
import PageTitle from "components/PageTitle";
import { MissionForm } from "components/Missions";

/**
 * NewMission
 */
const NewMission = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "Meta.Missions.New.Title" })}
        </title>
      </Helmet>
      <PageTitle title={<FormattedMessage id="Missions.New.Title" />} />
      <Card noPadding>
        <MissionForm />
      </Card>
    </>
  );
}

export default NewMission;
