import { gql } from "@apollo/client";
import { COMMENT_FIELDS } from "graphql/fragments";

const ADD_COMMENT = gql`
  ${COMMENT_FIELDS}
  mutation AddComment($input: AddCommentInput!) {
    addComment(input: $input) {
      comment {
        ...CommentFields
        user {
          id
          lastName
          firstName
          email
        }
      }
    }
  }
`;

const REMOVE_COMMENT = gql`
  mutation RemoveComment($input: RemoveCommentInput!) {
    removeComment(input: $input) {
      id
    }
  }
`;

const UPDATE_COMMENT = gql`
  ${COMMENT_FIELDS}
  mutation UpdateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      comment {
        ...CommentFields
        user {
          id
          lastName
          firstName
          email
        }
      }
    }
  }
`;

export { ADD_COMMENT, REMOVE_COMMENT, UPDATE_COMMENT };
