import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Card from "components/Card";
import ListTable from "components/ListTable";
import { H3 } from "components/Text";
import { Header } from "components/DisplayBox";
import { ProposalButton } from "components/Missions/Proposal";
import { capitalizeFirst } from "utils";
import { margins } from "style";

/**
 * Editable Proposal
 *
 * @param {String}   uuid
 * @param {Boolean}  loading
 * @param {String}   type
 * @param {Function} handleClose
 * @param {Boolean}  status
 */
const EditableProposal = ({ uuid, loading, type, handleClose, status, ...props }) => (
  <Card>
    <Header marginSize={margins.normal} loading={loading}>
      <H3>
        <FormattedMessage id={`Mission.Proposal.Title${capitalizeFirst(type)}Requirements`} />
      </H3>
      <ProposalButton show={status} type={type} updateId={uuid} isEditing={true} onClose={handleClose} />
    </Header>
    <ListTable isLoading={loading} singleColumn {...props} />
  </Card>
);

EditableProposal.propTypes = {
  uuid: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
  handleClose: PropTypes.func,
  status: PropTypes.bool,
};

export default EditableProposal;
