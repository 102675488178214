import React, { useState } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { CardMenu, CardPostTitle } from "components/Card";
import { NoteAdvancedOptions, NoteBody } from "components/Notes";

/**
 * NoteComment
 *
 * @param {Object}    comment
 * @param {Function}  setReplyUser
 */
const NoteComment = ({ comment, setReplyUser, ...props }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <div css={styles.comment}>
        <CardPostTitle user={comment?.user} date={comment?.createdAt} />
        <NoteBody
          data={comment}
          onReply={(userProp) => setReplyUser({ user: userProp, cache: Math.random() })}
          isEditing={isEditing}
          onEdit={setIsEditing}
          isComment
          {...props}
        />
        <CardMenu>
          <NoteAdvancedOptions data={comment} onEdit={setIsEditing} isComment />
        </CardMenu>
      </div>
    </>
  );
};

const styles = {
  comment: css`
    position: relative;
    padding: 2rem 3rem 0 3rem;
    margin-bottom: -2.2rem;
  `,
};

NoteComment.propTypes = {
  comment: PropTypes.object,
  setReplyUser: PropTypes.func,
};

export default NoteComment;
