import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors } from "style";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { MissionInviteForm } from "components/Missions";
import { ProfileArchiveForm, ProfileBadgesForm, ProfileEngineeringLevelForm } from "components/Profile";
import { ListsAddForm } from "components/Lists";
import { useProfile } from "hooks";
import { Link } from "components/Links";
import { LINKS, MEMBER_STATUS, PAGES } from "constants/index";
import Tooltip from "components/Tooltip";

/**
 * Admin Bar
 *
 * @params {String} profileId
 */
const ProfileAdminBar = ({ profileId }) => {
  const { data, handleSubmitForReview } = useProfile({ profileId });
  const [showForm, setShowForm] = useState({});
  const isActive = data?.profile?.status === MEMBER_STATUS.active;

  if (data?.profile?.status === MEMBER_STATUS.archived) return null;

  return (
    <>
      <div css={styles.container}>
        <div css={styles.icons}>
          <div css={styles.icon_container} onClick={() => setShowForm({ ...showForm, list: true })}>
            <Tooltip tooltipId={"ProfileAdminBar.List.Tooltip"} hideIcon>
              <Icon type={ICON_TYPE.duplicate} color={"white"} size={ICON_SIZE.xlarge} />
            </Tooltip>
          </div>
          <div css={styles.icon_container}>
            <Tooltip tooltipId={"ProfileAdminBar.BrowseAs.Tooltip"} values={{ name: data?.profile?.name }} hideIcon>
              <Link to={LINKS.start_impersonating(data?.profile?.user?.id)}>
                <Icon type={ICON_TYPE.user} color={"white"} size={ICON_SIZE.xlarge} />
              </Link>
            </Tooltip>
          </div>
          <div css={styles.icon_container} onClick={() => setShowForm({ ...showForm, invite: true })}>
            <Tooltip tooltipId={"ProfileAdminBar.InviteMember.Tooltip"} hideIcon>
              <Icon type={ICON_TYPE.share} color={"white"} size={ICON_SIZE.xlarge} />
            </Tooltip>
          </div>
          <div css={styles.icon_container} onClick={() => setShowForm({ ...showForm, badges: true })}>
            <Tooltip tooltipId={"ProfileAdminBar.Badges.Tooltip"} hideIcon>
              <Icon type={ICON_TYPE.star} color={"white"} size={ICON_SIZE.xlarge} />
            </Tooltip>
          </div>
          <div css={styles.icon_container} onClick={() => setShowForm({ ...showForm, profile: true })}>
            <Tooltip tooltipId={"ProfileAdminBar.Archive.Tooltip"} hideIcon>
              <Icon type={ICON_TYPE.archive} color={"white"} size={ICON_SIZE.xlarge} />
            </Tooltip>
          </div>
          <div css={styles.icon_container} onClick={() => setShowForm({ ...showForm, engineeringLevel: true })}>
            <Tooltip tooltipId={"ProfileAdminBar.EngineeringLevel.Tooltip"} hideIcon>
              <Icon type={ICON_TYPE.stairs} color={"white"} size={ICON_SIZE.xlarge} />
            </Tooltip>
          </div>
          {isActive && (
            <div css={styles.icon_container} onClick={handleSubmitForReview}>
              <Tooltip tooltipId={"ProfileAdminBar.ToReview.Tooltip"} hideIcon>
                <Icon type={ICON_TYPE.warning} color={"white"} size={ICON_SIZE.xlarge} />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <ProfileArchiveForm
        show={showForm.profile === true}
        onClose={() => setShowForm({ ...showForm, profile: false })}
        profileId={profileId}
      />
      <MissionInviteForm
        show={showForm.invite === true}
        onClose={() => setShowForm({ ...showForm, invite: false })}
        profile={data?.profile}
        page={PAGES.profileInviteMember}
      />
      <ListsAddForm
        show={showForm.list === true}
        onClose={() => setShowForm({ ...showForm, list: false })}
        profileId={profileId}
      />
      <ProfileBadgesForm
        show={showForm.badges === true}
        onClose={() => setShowForm({ ...showForm, badges: false })}
        data={data}
      />
      <ProfileEngineeringLevelForm
        show={showForm.engineeringLevel === true}
        onClose={() => setShowForm({ ...showForm, engineeringLevel: false })}
        data={data}
      />
    </>
  );
};

ProfileAdminBar.propTypes = {
  profileId: PropTypes.string,
};

const styles = {
  container: css`
    height: 30rem;
    position: fixed;
    top: 50%;
    margin-top: -15rem;
    right: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justfiy-content: center;
  `,
  icons: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    background: ${colors.peach};
    border-radius: 4rem;
    gap: 0rem;
  `,
  icon_container: css`
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${colors.darkPeach};
    }
  `,
};

ProfileAdminBar.propTypes = {
  profileId: PropTypes.string.isRequired,
};

export default ProfileAdminBar;
