import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { UserPhoto } from "components/User";
import { useAuth, useTeam } from "hooks";
import { Row } from "components/Containers";
import { breakpoints, colors, margins } from "style";
import Tooltip from "components/Tooltip";

/**
 * SquadMembers
 *
 * @param {String}   teamId
 * @param {Boolean}  loading
 * @param {Function} onChange
 * @param {Array}    selected
 */
const SquadMemberFilter = ({ teamId, loading, onChange, selected, ...props }) => {
  const { isAdmin } = useAuth();
  const { loading: loadingTeam, data } = useTeam({ id: teamId });

  const handleSelection = (data) => {
    if (selected.indexOf(data?.profile?.uuid) !== -1) {
      onChange((prev) => prev.filter((item) => item !== data?.profile?.uuid));
    } else {
      onChange((prev) => [...prev, ...[data?.profile?.uuid]]);
    }
  };

  if (!isAdmin) return null;

  return (
    <div css={styles.container}>
      <Row gap="0.25rem" {...props}>
        {(loadingTeam || loading) &&
          [1, 2, 3, 4].map((item) => (
            <div key={item} css={styles.thumb(false)}>
              <UserPhoto loading width={`4rem`} height={`4rem`} />
            </div>
          ))}
        {data?.assignments?.map((item) => (
          <div
            key={item.id}
            onClick={() => handleSelection(item)}
            css={styles.thumb(selected.indexOf(item?.profile?.uuid) !== -1)}
          >
            <Tooltip tooltipId={`${item?.profile?.firstName} ${item?.profile?.lastName}`} hideIcon>
              <UserPhoto
                data={{
                  avatarUrl: item?.profile?.avatarUrl,
                  firstName: item?.profile?.firstName,
                  lastName: item?.profile?.lastName,
                }}
                width={`4rem`}
                height={`4rem`}
              />
            </Tooltip>
          </div>
        ))}
      </Row>
    </div>
  );
};

const styles = {
  container: css`
    margin: 0 ${margins.normal};

    @media (max-width: ${breakpoints.portrait}) {
      margin: 0 2.5rem;
    }

    @media (max-width: ${breakpoints.mobile}) {
      margin: 0 1rem;
    }
  `,
  thumb: (isSelected) => css`
    border: 2px ${isSelected ? colors.pinkPantherBase : colors.grayAnatomyLight3} solid;
    border-radius: 100%;
    padding: 2px;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    width: calc(4rem + 8px);
    height: calc(4rem + 8px);

    &:hover {
      border: 2px ${isSelected ? colors.pinkPantherBase : colors.grayAnatomyLight1} solid;
    }

    &:active {
      transform: scale(0.8);
    }
  `,
};

SquadMemberFilter.defaultProps = {
  selected: [],
}

SquadMemberFilter.propTypes = {
  teamId: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  selected: PropTypes.array,
};

export default SquadMemberFilter;
