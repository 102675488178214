import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Grid, PageWrapper } from "components/Containers";
import { useAuth, useTracking } from "hooks";
import { Banner, BannerShowcaseSignup } from "components/Banner";
import { css } from "@emotion/react";
import { PAGES, TRACKING_PAGE_VIEWS } from "constants/index";
import { RolesList } from "components/Missions/Roles";
import PageTitle from "components/PageTitle";

/**
 * Client Mission Board
 */
const ClientMissionBoard = () => {
  const { canViewUnpublishedRoles, isShowcase, isClient } = useAuth();
  const { trackPage } = useTracking();

  useEffect(() => {
    trackPage(
      TRACKING_PAGE_VIEWS[PAGES.showcaseMissionBoard].page_name,
      TRACKING_PAGE_VIEWS[PAGES.showcaseMissionBoard]
    );
  }, []);
  return (
    <PageWrapper>
      <BannerShowcaseSignup
        css={css`
          margin: 4rem 0 1rem 0;
        `}
      />
      {isShowcase && (
        <Banner keyName="showClientMissionBoardBanner">
          <FormattedMessage
            id="Missions.Board.Banner.Title1"
            values={{
              intro: (
                <b>
                  <FormattedMessage id="Missions.Board.Banner.Title2" />
                </b>
              ),
            }}
          />
        </Banner>
      )}
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle title={<FormattedMessage id={`Client.MissionBoard.H1`} />} />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <RolesList
            isCardView={true}
            filters={{ published: !canViewUnpublishedRoles }}
            hideTutorial={isClient || isShowcase}
          />
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default ClientMissionBoard;
