import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import GradientLogo from "assets/images/gradient-mission-logo.png";
import { FormattedMessage } from "react-intl";
import Image from "components/Image";
import Menu from "components/Menu";
import { NavList } from "components/Nav";
import { Link } from "components/Links";
import HeaderGradient from "./HeaderGradient";
import { LINKS } from "constants/index";

/**
 * Shows a gradient header for private pages
 *
 * @param {Element}   children
 */
const HeaderPrivateGradient = ({ children }) => (
  <HeaderGradient>
    <div css={styles.container}>
      <div>
        <Link to="/">
          <Image url={GradientLogo} alt="Mission Logo" css={styles.logo} />
        </Link>
      </div>
      <div>
        <NavList
          pages={[
            {
              to: LINKS.candidate_home,
              label: <FormattedMessage id="Nav.Home" />,
            },
            {
              to: LINKS.candidate_profile,
              label: <FormattedMessage id="Nav.Profile" />,
            },
          ]}
          onDark
          hideOnMobile
          routerEnabled
        />
      </div>
      <div css={styles.auth_header}>
        <Menu />
      </div>
    </div>
    {children}
  </HeaderGradient>
);

const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
  `,
  logo: css`
    max-width: 7.3rem;
    margin-left: 3rem;
  `,
  auth_header: css`
    margin-right: 2rem;
  `,
};

HeaderPrivateGradient.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeaderPrivateGradient;
