import styled from "@emotion/styled";
import { DataTableProvider } from "components/DataTable";
import { useOrganization } from "hooks";
import React from "react";
import { Row } from "components/Containers";
import { useParams } from "react-router";
import BillingAdvanceOption from "components/Organization/BillingAdvanceOption";
import Card from "components/Card";

/**
 * OrganizationBilling
 */
const OrganizationBilling = () => {
  const { id } = useParams();
  const { data, loading, error } = useOrganization({ id });

  return(
    <Card>
      <DataTableProvider
        data={data?.organizationBilling?.map((item) => ({
          id: item.id,
          name: item.name,
          currencyCode: item.currencyCode,
        }))}
        resultsPerPage={1}
        error={error}
        loading={loading}
        columns={COLUMN_DATA}
        paginationType={false}
      />
    </Card>
  );
}
const COLUMN_DATA = [
  {
    label: "Name",
    cell: (row) => row?.name,
    width: "30%",
  },
  {
    label: "Currency",
    cell: (row) => row?.currencyCode,
    width: "30%",
  },
  {
    label: "",
    width: "10%",
    cell: (row) => (
      <StyledRow>
         <BillingAdvanceOption billingId={row?.id} data={row} />
      </StyledRow>
    ),
  },
];

const StyledRow = styled(Row)`
  justify-content: flex-end;
`;

export default OrganizationBilling;
