import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { EngineeringLevelsModal } from "components/EngineeringLevels";
import { PrimaryLink } from "components/Links";
import { fontSize } from "style";

/**
 * User Engineering Level
 *
 * @param {Object} data
 */
const UserEngineeringLevel = ({ data }) => {
  const [show, setShow] = useState(false);

  if (!data.engineeringLevelWithoutCode) return null;

  const handleShow = () => setShow(true);

  return (
    <>
      <FormattedMessage
        id={"User.EngineeringLevel"}
        values={{
          engineeringLevel: data?.engineeringLevelWithoutCode,
          link: (
            <span css={styles.text}>
              <PrimaryLink onClick={handleShow} withMoreIcon>
                <FormattedMessage id="Global.LearnMore" />
              </PrimaryLink>
            </span>
          ),
        }}
      />
      <EngineeringLevelsModal show={show} setShow={setShow} data={data} />
    </>
  );
};

const styles = {
  text: css`
    font-size: ${fontSize.small};
    margin-left: 1rem;
  `,
};

UserEngineeringLevel.propTypes = {
  data: PropTypes.object,
};

export default UserEngineeringLevel;
