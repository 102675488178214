import { gql } from "@apollo/client";

const REMOVE_PROFILE_FROM_LIST = gql`
  mutation RemoveProfileFromList($input: RemoveProfileFromListInput!) {
    removeProfileFromList(input: $input) {
      list {
        id
        title
        profiles {
          id
          name
          email
          avatarUrl
        }
      }
    }
  }
`;

export { REMOVE_PROFILE_FROM_LIST };
