import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { PAGINATION_TYPE } from "components/Pagination";
import { GET_NETWORK_PROFILES } from "graphql/queries";
import { useAuth, useParamFunctions, useQueryData } from "hooks";
import { GLOBAL_FORM_FILTERS, OPERATORS, ALLOWED_FILTERS_BY_USER_TYPE, GLOBAL_VALUE_TYPES } from "constants/index";

const PARAM_KEYS = {
  filters: "f",
  operators: "o",
};

export default function useSearch({
  resultsPerPage,
  staticFilters = [],
  search,
  sort = "candidate_at",
  sortDirection = "desc",
  keyName = "networkProfiles",
  queryName = GET_NETWORK_PROFILES,
  initialValues,
  initialOperators,
  additionalVariables = {},
  skip = false,
}) {
  const { paramParse, setParams } = useParamFunctions();
  const SEARCH_KEY = "search";
  const SORT_KEY = "sort";
  const SORT_DIRECTION_KEY = "sortDirection";
  const PROFILE_STATUS = "profile_status";
  const location = useLocation();
  const [mappedFilters, setMappedFilters] = useState([]);
  const [filterMetaData, setFilterMetaData] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [storedCurrentPage, setStoredCurrentPage] = useState(null);
  const [storedPerPage, setStoredPerPage] = useState(null);
  const [storedTotalCount, setStoredTotalCount] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [variables, setVariables] = useState(additionalVariables);
  const [operators, setStateOperators] = useState(initialOperators);
  const [filters, setStateFilters] = useState({ ...initialValues, ...paramParse(PARAM_KEYS.filters) });
  const [allowedFilters, setAllowedFilters] = useState({});
  const { isAdmin, isClient, isShowcase, isPartner, isRecruiter, isSignalClient } = useAuth();
  const me = useAuth();
  const handleSkip = !filterMetaData || filterMetaData.length === 0 || skip;

  const {
    loading: queryLoading,
    loadingMore: queryLoadingMore,
    data: queryData,
    error,
    networkStatus,
    handlePageChange,
    handlePreviousPage,
    handleNextPage,
  } = useQueryData({
    queryName,
    showLog: true,
    keyName,
    fetchPolicy: "cache-and-network",
    resultsPerPage,
    paginationType: PAGINATION_TYPE.classic,
    variables: {
      sort,
      sortDirection,
      filters: [...mappedFilters, ...staticFilters],
      resultFilterMetadata: filterMetaData,
      ...variables,
    },
    skip: handleSkip,
  });

  /**
   * @description Handle operator change events
   *
   * @param {String}  name
   * @param {Boolean} value
   */
  const handleOperatorChange = useCallback((name, value) => setOperators({ ...operators, [name]: value }), [operators]);

  /**
   * @description Set the search params when form filters change
   *
   * @param {Object} items
   */
  const setFilters = (items) => setParams(PARAM_KEYS.filters, items);

  /**
   * @description Set the search params when form filters change
   *
   * @param {Object} items
   */
  const setOperators = (items) => setParams(PARAM_KEYS.operators, items);

  /**
   * @description When search value is provided append it to list of filters
   */
  useEffect(() => {
    if (search !== false) {
      setFilters({ search });
    }
  }, [search]);

  /**
   * @description On location change, set the state filters, operators and page
   */
  useEffect(() => {
    setStateFilters({ ...initialValues, ...paramParse(PARAM_KEYS.filters) });
    setStateOperators(paramParse(PARAM_KEYS.operators));
  }, [location, initialValues]);

  /**
   * @description Initialize
   */
  useEffect(() => {
    if (queryLoading) return;

    setData(queryData ? queryData[keyName]?.networkProfiles : []);
    setFilterData(queryData ? queryData[keyName]?.resultFilterMetadata : {});
    setStoredTotalCount(queryData ? queryData[keyName]?.pageInfo?.totalCount : 0);
    setStoredCurrentPage(queryData ? queryData[keyName]?.pageInfo?.currentPage : null);
    setStoredPerPage(queryData ? queryData[keyName]?.pageInfo?.perPage : null);
    setHasPreviousPage(queryData ? queryData[keyName]?.pageInfo?.hasPreviousPage : false);
    setHasNextPage(queryData ? queryData[keyName]?.pageInfo?.hasNextPage : false);
  }, [queryData, keyName, queryLoading, queryLoadingMore]);

  /**
   * @description Set loading state based on query loading state
   */
  useEffect(() => {
    if (error) {
      setLoading(false);
      setLoadingMore(false);
    } else {
      setLoading(queryLoading || typeof queryData === "undefined");
      setLoadingMore(queryLoadingMore);
    }
  }, [queryLoading, queryLoadingMore, queryData, error]);

  /**
   * @description Set the filter metadata based on state changes
   */
  useEffect(() => {
    let tempFilters = [];

    Object.keys(filters).forEach((key) => {
      let valueKey = key;
      let defaultValues = null;
      let hasDefaultValue = false;

      /**
       * If the filter field is "search", "sort" or "sortDirection", then set as variable as they are not filters
       */
      if ([SEARCH_KEY, SORT_KEY, SORT_DIRECTION_KEY].includes(key)) {
        setVariables((prev) => ({ ...prev, [key]: filters[key] }));
        return;

        /**
         * If the field is no longer in the list of filters, do not include it
         */
      } else if (filterMetaData?.indexOf(key) === -1) {
        return;
      }

      /**
       * FIXME: I put this but no idea why - anyone know why this is here? -Andrew
       */
      if (key === PROFILE_STATUS) {
        valueKey = filters[key];
      }

      /**
       * Find the filter item in GLOBAL_FORM_FILTERS
       */
      const { includeHiddenFilters, operator, valuesType, transformValues, ...rest } = GLOBAL_FORM_FILTERS()[key];

      /**
       * Include additional hidden filters with a specific filter if applicable
       * For example, if we are filtering by "location", we may also want to filter by "remote" and "relocation".
       */
      if (typeof includeHiddenFilters === "object") {
        includeHiddenFilters.forEach((filter) => {
          if (filter.shouldApplyFilter({ ...me, filters })) {
            tempFilters.push(filter);
          }
        });
      }

      /**
       * Set default values if applicable
       */
      if (rest?.properties) {
        if (Object.prototype.hasOwnProperty.call(rest?.properties, "defaultValue")) {
          defaultValues = transformValues(rest?.properties?.defaultValue);
          hasDefaultValue = true;
        }
      }

      /**
       * Format object to include key, operator and valuesType (ie: categoricalValues, numericValues, dateValues, etc...)
       */
      const values = transformValues(filters[key]);

      const hasValues =
        valuesType === GLOBAL_VALUE_TYPES.numericValues ? values && Object.keys(values).length : values?.length;

      if (hasValues || hasDefaultValue) {
        tempFilters.push({
          key: valueKey,

          /**
           * if user specified operator value is true then it's "and", if false then it's "or",
           * otherwise it's the default operator specified in globalFormFilters
           */
          operator: operators[key] === true ? OPERATORS.and : operators[key] === false ? OPERATORS.or : operator,
          [valuesType]: hasValues ? values : defaultValues,
        });
      }
    });

    setMappedFilters(tempFilters);
  }, [filters, operators, filterMetaData]);

  /**
   * @description Restrict the list of allowed filters by user type
   */
  useEffect(() => {
    const af = GLOBAL_FORM_FILTERS(handleOperatorChange, operators);

    Object.keys(af)
      ?.filter(
        (key) =>
          !ALLOWED_FILTERS_BY_USER_TYPE({
            isAdmin,
            isClient,
            isShowcase,
            isPartner,
            isRecruiter,
            isSignalClient,
          }).includes(key)
      )
      ?.forEach((key) => delete af[key]);

    setAllowedFilters(af);
  }, [handleOperatorChange, isAdmin, isClient, isShowcase, isPartner, isRecruiter, isSignalClient, operators]);

  return {
    handlePageChange,
    handleNextPage,
    handlePreviousPage,
    loading,
    loadingMore,
    networkStatus,
    hasNextPage,
    hasPreviousPage,
    setFilterMetaData,
    data,
    filterData,
    setFilters,
    filters,
    operators,
    setOperators,
    allowedFilters,
    error,
    totalCount: storedTotalCount,
    currentPage: storedCurrentPage,
    perPage: storedPerPage,
  };
}

export { PARAM_KEYS };
