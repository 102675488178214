import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { TermSheetScenarioForm } from ".";
import { PrimaryButton } from "components/Buttons";

/**
 * TermSheetScenarioAddButton
 *
 * @param {Object} data
 * @param {Function} onComplete
 */
const TermSheetScenarioAddButton = ({ data, onComplete, ...props }) => {
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      <PrimaryButton onClick={() => setShowForm(true)}>
        <FormattedMessage id={`TermSheets.TermSheetScenarioAddButton`} />
      </PrimaryButton>
      <TermSheetScenarioForm
        show={showForm}
        onClose={() => setShowForm(false)}
        onComplete={onComplete}
        data={data}
        {...props}
      />
    </>
  );
};

TermSheetScenarioAddButton.propTypes = {
  data: PropTypes.object.isRequired,
  onComplete: PropTypes.func,
};

export default TermSheetScenarioAddButton;
