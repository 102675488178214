import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { SubmitForm } from "components/TimeSheet";
import { colors, fontSize, margins } from "style";
import { Col, Grid } from "components/Containers";
import { formatHours } from "utils";
import { PrimaryButton } from "components/Buttons";
import { H4 } from "components/Text";
import { TextLoader } from "components/Loader";

/**
 * SubmitMonthButton
 *
 * @param {Object}    data
 * @param {Number}    total
 * @param {Function}  onClose
 * @param {String}    show
 * @param {Boolean}   disabled
 * @param {Function}  callBack
 */
const SubmitMonthButton = ({ data, total, onClose, show, disabled, callBack, ...props }) => {
  const [showForm, setShowForm] = useState(false);
  const [surveyId, setSurveyId] = useState(null);

  useEffect(() => {
    setSurveyId(!disabled && data?.monthlyTeamSurvey?.id);
  }, [data?.monthlyTeamSurvey?.id, disabled]);

  const handleClose = () => {
    setShowForm(false);
    onClose?.();
  };

  const onComplete = (res) => {
    callBack?.({ ...data, ...res.submitTimeCard.timeCard });
  };

  if (!show) return null;

  return (
    <>
      <PrimaryButton onClick={() => setShowForm(true)} disabled={disabled} {...props}>
        <FormattedMessage id="TimeSheet.SubmitMonthButton.Button" />
      </PrimaryButton>
      <SubmitForm
        show={showForm}
        onClose={handleClose}
        type={"MonthlyTeamSurvey"}
        updateId={data?.id}
        title={<FormattedMessage id="TimeSheet.SubmitModal.Title" />}
        info={data?.period}
        surveyId={surveyId}
        onComplete={onComplete}
        description={(loading) => (
          <div css={styles.description}>
            <Col gap="1rem">
              <span css={styles.description1}>
                <FormattedMessage
                  id="TimeSheet.SubmitModal.Description1"
                  values={{ total: `${formatHours(parseInt(total) / 60)} hours` }}
                />
              </span>
              <span>
                <b>
                  <FormattedMessage id="TimeSheet.SubmitModal.Description21" />
                </b>
                <FormattedMessage id="TimeSheet.SubmitModal.Description22" />
              </span>
              <FormattedMessage id="TimeSheet.SubmitModal.Description23" />
            </Col>
            {surveyId && (
              <Col gap="1rem">
                <H4 css={styles.monthly_title}>
                  <FormattedMessage id="TimeSheet.SubmitModal.Description24" />
                </H4>
              </Col>
            )}
            {loading &&
              [0, 1, 2, 3].map((item) => (
                <>
                  <Grid col={1} key={item}>
                    <TextLoader css={styles.text_loader(margins.normal)} />
                  </Grid>
                  <Grid cols="4">
                    {[0, 1, 2, 3].map((col) => (
                      <Grid.col key={col}>
                        <TextLoader css={styles.option_loader} />
                      </Grid.col>
                    ))}
                  </Grid>
                </>
              ))}
          </div>
        )}
        primaryButtonMessageId="TimeSheet.SubmitMonthButton.Button"
        disclaimer={
          surveyId && (
            <div css={styles.disclaimer}>
              <FormattedMessage id="TimeSheet.SubmitModal.Description26" />
            </div>
          )
        }
        marginSize={margins.normal}
        {...props}
      />
    </>
  );
};

const styles = {
  description1: css`
    font-weight: 700;
    color: ${colors.red};
  `,
  description: css`
    font-size: ${fontSize.small};
    line-height: 2.4rem;
    color: ${colors.grayAnatomyBase};
  `,
  monthly_title: css`
    margin-top: 2rem;
  `,
  text_loader: (marginSize) => css`
    margin: ${marginSize} 0 ${marginSize} 0;
    width: 50rem;
  `,
  option_loader: css`
    width: 10rem;
  `,
  disclaimer: css`
    font-style: italic;
    margin-top: 2rem;
    color: ${colors.grayAnatomyLight1};
  `,
};

SubmitMonthButton.defaultProps = {
  show: true,
};

SubmitMonthButton.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
  data: PropTypes.object,
  total: PropTypes.number,
  disabled: PropTypes.bool,
  callBack: PropTypes.func,
};

export default SubmitMonthButton;
