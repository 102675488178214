/* eslint-disable react/display-name */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { breakpoints, colors, fontSize, margins } from "style";
import { FormElement, FormFieldError, SIZE, TYPE, FormLabel, LABEL_DIRECTION } from "components/Form";
import { useMutateData, MUTATION_TYPE } from "hooks";
import { PrimaryButton, SecondaryButton, DestructableButton } from "components/Buttons";

/**
 * Shows a Form
 *
 * @param {Array}    data            -The form data to build layout (example below)
 * @param {Object}   initialValues   -An object representing initial values for all elements in form
 * @param {String}   updateId        -The associated mutation id
 * @param {String}   updateIdKey
 * @param {Function} onChange        -Callback triggered when data changes
 * @param {Function} onLoadingState  -Callback when loading state changes
 * @param {Boolean}  noPadding       -Removes padding from form container
 * @param {String}   mutationType    -Add or update mutation
 * @param {Object}   mutationData    -The gql mutation
 * @param {Function} onCompleted     -Callback triggered after successful form completion
 * @param {Function} onError         -Callback triggered after form submission if errors occur
 * @param {Function} numColumns      -Number of columns for form
 * @param {Object}   params          -An object that will be passed to all form children
 * @param {String}   parentKeyName   -Parent object key name
 * @param {String}   parentKeyValue  -Parent object key value
 * @param {String}   parentKeyValue  -Parent object key value
 * @param {Object}   cancelButton
 * @param {Object}   saveButton
 * @param {Object}   deleteButton
 * @param {Function} onSubmit
 * @param {Function} onRequiredValuesChange
 * @param {String}   marginSize
 * @param {Boolean}  disabled        -Disabled the entire form
 * @param {Boolean}  values
 * @param {Boolean}  hideOptionalLabel Hides the "optional" label on form fields
 * @param {Boolean}  isExpandable    -Allows form fields to be expandable
 * @param {Object}   initialExpandedItems -Initial visibility of expandable fields
 * @param {Function} onExpand        -Callback triggered when expandable fields are toggled
 */
const Form = forwardRef(
  (
    {
      data,
      values,
      initialValues,
      updateId,
      updateIdKey,
      onChange,
      noPadding,
      mutationType,
      mutationData = {},
      onCompleted,
      onError,
      numColumns,
      params,
      parentKeyName,
      parentKeyValue,
      onLoadingState,
      cancelButton,
      saveButton,
      deleteButton,
      onSubmit,
      onRequiredValuesChange,
      marginSize,
      disabled,
      hideOptionalLabel,
      isExpandable,
      initialExpandedItems,
      onExpand,
      ...props
    },
    ref
  ) => {
    const [formData, updateFormData] = useState(initialValues);
    const [hasRequiredValues, setHasRequiredValues] = useState(false);
    const { handleAdd, handleUpdate, errors, loading } = useMutateData(mutationData, mutationType);
    const submitButton = useRef(null);
    const [expandedItems, setExpandedItems] = useState(initialExpandedItems);

    useEffect(() => {
      if (values) {
        updateFormData({ ...formData, ...values });
      }
    }, [values]);

    useEffect(() => {
      if (onExpand) {
        onExpand(expandedItems);
      }
    }, [expandedItems]);

    useEffect(() => {
      changeable() && onChange && onChange(formData);
    }, [formData]);

    useEffect(() => {
      updateFormData({ ...formData, ...values });
    }, [values]);

    useEffect(() => {
      setHasRequiredValues(checkRequiredValues(formData, data));
    }, [formData, data]);

    useEffect(() => {
      if (typeof onRequiredValuesChange === "function") {
        onRequiredValuesChange(hasRequiredValues);
      }
    }, [hasRequiredValues]);

    useEffect(() => {
      if (onLoadingState) {
        onLoadingState(loading);
      }
    }, [loading]);

    // Exposes `handleSubmit` to parent
    useImperativeHandle(
      ref,
      () => ({
        triggerSubmitForm,
      }),
      [formData, updateId, mutationType]
    );

    // To prevent unnecessary onChange calls incase of values being passed from the hook results
    const changeable = () => JSON.stringify(values) !== JSON.stringify(formData);

    // Saves data in form object based on field name and parent object key name
    const handleChange = (name, value, parentKey) => {
      if (parentKey) {
        updateFormData((prev) => {
          return {
            ...prev,
            [parentKey]: {
              ...prev[parentKey],
              [name]: value,
            },
          };
        });
      } else {
        updateFormData((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    };

    // Handle GraphQL mutation on form submit
    const handleSubmit = () => {
      if (onSubmit) {
        onSubmit(formData);
      } else if (mutationType === MUTATION_TYPE.add) {
        handleAdd({
          params: formData,
          parentKeyName,
          parentKeyValue,
          onError,
          onCompleted,
        });
      } else if (mutationType === MUTATION_TYPE.update) {
        handleUpdate({
          [updateIdKey]: updateId,
          params: formData,
          onError,
          onCompleted,
        });
      }
    };

    const triggerSubmitForm = () => submitButton.current.click();

    const handleFormSubmit = (event) => {
      event.preventDefault();
      handleSubmit();
    };

    const handleError = ({ item, error }) => {
      if (!item || !error) return;

      if (typeof item?.customizedErrorMessage === "function") {
        return item.customizedErrorMessage(error);
      } else {
        return (
          <>
            {item.label} {error.message}
          </>
        );
      }
    };

    const handleExpand = (container, item) => {
      const filteredItems = container.items.filter((i) => i.parentKey === item.id || i.id === item.id).map((i) => i.id);

      if (expandedItems.find((i) => i === item.id)) {
        setExpandedItems((prev) => prev.filter((i) => !filteredItems.includes(i)));
      } else {
        setExpandedItems((prev) => [...prev, ...filteredItems]);
      }
    };

    return (
      <>
        <form {...props} onSubmit={handleFormSubmit}>
          <GlobalError errors={errors} />
          <div css={styles.form(disabled)}>
            {data.map((container, indexContainer) => (
              <div key={indexContainer} css={[styles.container(noPadding, numColumns, marginSize), container.style]}>
                {container?.items
                  ?.filter((i) => !i.parentKey)
                  ?.map((item, index) => (
                    <FormItem
                      key={index}
                      item={item}
                      items={container?.items}
                      container={container}
                      isExpandable={isExpandable}
                      expandedItems={expandedItems}
                      formData={formData}
                      errors={errors}
                      params={params}
                      disabled={disabled}
                      hideOptionalLabel={hideOptionalLabel}
                      onChange={handleChange}
                      onError={handleError}
                      onExpand={handleExpand}
                    />
                  ))}
              </div>
            ))}
          </div>
          {/*
            This button allows us to submit form programmatically
            AND trigger HTML5 browser-based validations
          */}
          <button ref={submitButton} css={styles.hidden_submit} disabled={!hasRequiredValues}>
            Submit
          </button>
          <div css={styles.footer(marginSize)}>
            {deleteButton?.show && (
              <div css={styles.destructable_container}>
                <DestructableButton onClick={deleteButton.onClick} disabled={loading}>
                  <FormattedMessage id={deleteButton.labelId || "Global.Cancel"} />
                </DestructableButton>
              </div>
            )}
            {cancelButton?.show && (
              <SecondaryButton onClick={cancelButton.onClick} disabled={loading}>
                <FormattedMessage id={cancelButton.labelId || "Global.Cancel"} />
              </SecondaryButton>
            )}
            {saveButton?.show && (
              <PrimaryButton disabled={loading || saveButton.disabled || !hasRequiredValues} {...saveButton.props}>
                <FormattedMessage id={saveButton.labelId || "Global.Save"} />
              </PrimaryButton>
            )}
          </div>
        </form>
      </>
    );
  }
);

/**
 * Form Item
 *
 * @param {Array} item
 * @param {Array} items
 * @param {Object} container
 * @param {Boolean} isExpandable
 * @param {Array} expandedItems
 * @param {Function} onChange
 * @param {Object} formData
 * @param {Object} errors
 * @param {Object} params
 * @param {Boolean} disabled
 * @param {Function} onError
 * @param {Function} onExpand
 * @param {Boolean} hideOptionalLabel
 * @param {Boolean} isChild
 */
const FormItem = ({
  item,
  items,
  container,
  isExpandable,
  expandedItems,
  onChange,
  formData,
  errors,
  params,
  disabled,
  onError,
  onExpand,
  hideOptionalLabel,
  isChild,
}) => (
  <div
    css={styles.item_container(
      item.size,
      !item.label || expandedItems?.find((i) => i === item.id),
      isExpandable,
      item.labelOrientation
    )}
  >
    <>
      {!isText(item.type) && (
        <>
          <FormElement
            item={item}
            onChange={onChange}
            formData={formData}
            hasError={hasError(errors?.fields, item.properties?.name) || item.hasError}
            decorator={item?.decorator}
            params={params}
            disabled={disabled || item.disabled}
          />
          {item.note && <div css={styles.note}>{item.note}</div>}
          {errors?.fields?.map(
            (error, i) =>
              error.field === item.properties?.name && (
                /* In that case the CharactersCount componnet is also there on the right side
                  so making sure it doesnot overlap with errors */
                <FormFieldError
                  key={error.field}
                  index={i}
                  width={item.type === TYPE.textarea && item.properties?.maxLength ? "calc(100% - 5rem)" : "100%"}
                >
                  {onError({ item, error })}
                </FormFieldError>
              )
          )}
        </>
      )}
      {item.id && items?.find((i) => i.parentKey === item.id) && (
        <div css={styles.item_children}>
          {item.id &&
            items
              ?.filter((i) => i.parentKey === item.id)
              ?.map((x, idx) => (
                <FormItem
                  key={idx}
                  item={x}
                  items={items}
                  container={container}
                  isExpandable={false}
                  expandedItems={expandedItems}
                  onChange={onChange}
                  formData={formData}
                  errors={errors}
                  params={params}
                  disabled={disabled}
                  onError={onError}
                  hideOptionalLabel={hideOptionalLabel}
                  onExpand={onExpand}
                  isChild={true}
                />
              ))}
        </div>
      )}
      <FormLabel
        onClick={() => onExpand(container, item)}
        isExpandable={item.label && isExpandable && item.labelOrientation !== LABEL_DIRECTION.horizontal}
        isExpanded={!isExpandable || !item.label || expandedItems.find((i) => i === item.id)}
        label={item.label}
        hint={item.hint}
        required={item?.properties?.required}
        requiredAll={item?.properties?.requiredAll}
        description={item.description}
        name={item.properties?.name}
        type={item.type}
        hideOptionalLabel={hideOptionalLabel}
        hasError={hasError(errors?.fields, item.properties?.name)}
        isChild={isChild}
      />
    </>
  </div>
);

const hasError = (errors, name) => errors?.some((err) => err.field === name);

const isText = (type) => [TYPE.h4, TYPE.label, TYPE.p].includes(type);

/**
 * Checks form if all required values are inputted
 *
 * @params {Object}   values
 * @params {Array}    data
 */
function checkRequiredValues(values, data) {
  for (let x = 0; x < data.length; x++) {
    let container = data[x];

    for (let i = 0; i < container?.items?.length; i++) {
      let item = container?.items[i];
      let isRequired = item?.properties?.required;
      let isRequiredAll = item?.properties?.requiredAll;
      let options = item?.properties?.options;
      let name = item?.properties?.name;
      let parentObjectKey = item?.parentObjectKey;
      let value = parentObjectKey ? values[parentObjectKey][name] : values[name];
      if (isRequired) {
        if ((!value && parseInt(value) !== 0 && value !== false) || value.length === 0) {
          return false;
        }
      }
      if (isRequiredAll) {
        if (values[name]?.length != options?.length) {
          return false;
        }
      }
    }
  }
  return true;
}

/**
 * GraphQL Error Handling
 *
 * GraphQL lacks consistency with how errors are returned. Sometimes field errors
 * are returned as ambiguous global error strings. This method will look at the
 * the returned error object. If it's a string then it will output at the top of
 * of the form. If it's an object then it's not handled here.
 */
const GlobalError = ({ errors }) => {
  if (!errors || Object.keys(errors).length === 0) return null;

  const errorList = errors?.fields;

  // This is a hack to online show errors that have no corresponding field
  const filteredList = errorList?.filter((item) => typeof item === "string");

  if (filteredList?.length > 0) {
    return filteredList?.map((item, index) => (
      <div key={index} css={styles.global_error}>
        {item}
      </div>
    ));
  } else {
    if (errorList?.length > 0) {
      return (
        <div css={styles.global_error}>
          <FormattedMessage id="Global.ErrorsInLine" />
        </div>
      );
    } else {
      return (
        <div css={styles.global_error}>{errors?.message ? errors.message : <FormattedMessage id="Global.Error" />}</div>
      );
    }
  }
};

GlobalError.propTypes = {
  errors: PropTypes.object,
};

const styles = {
  form: (disabled) => css`
    display: flex;
    opacity: ${disabled ? "0.3" : "1"};
  `,
  note: css`
    font-size: ${fontSize.xsmall};
    color: ${colors.grayAnatomyLight1};
    margin-top: 0.5rem;
    order: 2;
  `,
  global_error: css`
    margin-bottom: 2rem;
    padding: 1rem;
    background: ${colors.lightRed};
    color: ${colors.red};
    border-radius: 0.5rem;
    font-size: ${fontSize.small};
    font-weight: 500;
    line-height: normal;
  `,
  hidden_submit: css`
    display: none;
  `,
  container: (noPadding, numColumns, marginSize) => css`
    padding: 1rem ${marginSize};
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(${numColumns}, 1fr);
    gap: 1rem 1.6rem;
    border-right: 1px solid ${colors.grayAnatomyLight4};
    box-sizing: border-box;
    width: 100%;

    ${noPadding && `padding:0;`}

    &:last-of-type {
      border-right-width: 0;
    }
  `,
  item_children: css`
    order: 2;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;

    > * {
      border-bottom: 1px ${colors.grayAnatomyLight5} solid;
      padding-bottom: 0.5rem;
    }

    > *:last-of-type {
      border-bottom-width: 0;
      padding-bottom: 0;
    }
  `,
  item_container: (itemSize, isVisible, isExpandable, orientation) => css`
    display: flex;
    flex-direction: ${orientation === LABEL_DIRECTION.horizontal ? `row` : `column`};
    position: relative;
    ${itemSize === SIZE.small && `grid-column-start: span 1;`}
    ${itemSize === SIZE.medium && `grid-column-start: span 2;`}
    ${itemSize === SIZE.large && `grid-column-start: span 3;`}
    ${(!itemSize || itemSize === SIZE.xlarge) && `grid-column-start: span 4;`}

    &:first-of-type > * {
      margin-top: auto;
    }

    ${orientation === LABEL_DIRECTION.horizontal &&
    `
      > * {
        flex: 1;
      }
    `}

    ${isExpandable &&
    `
      padding-bottom: 1rem;
      padding-left:2rem;
      padding-right:2rem;
      margin-left:-2rem;
      margin-right:-2rem;
      border-bottom: 1px ${colors.grayAnatomyLight5} solid;
    `}

    ${!isVisible &&
    isExpandable &&
    `
      @media (max-width: ${breakpoints.portrait}) {
        padding-left:2.5rem;
        padding-right:2.5rem;
        margin-left:-2.5rem;
        margin-right:-2.5rem;
      }

      > *:not(label) {
        margin-top: auto;
        display:none;
      }
    `}

    @media (max-width: ${breakpoints.portrait}) {
      grid-column-start: span 4;
    }
  `,
  footer: (marginSize) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 ${marginSize};

    * {
      margin-top: 1rem;
    }

    * + * {
      margin-left: 1rem;
    }
  `,
  destructable_container: css`
    margin-right: auto;
  `,
};

FormItem.propTypes = {
  item: PropTypes.object,
  items: PropTypes.array,
  container: PropTypes.object,
  isExpandable: PropTypes.bool,
  expandedItems: PropTypes.array,
  onChange: PropTypes.func,
  formData: PropTypes.object,
  errors: PropTypes.object,
  params: PropTypes.object,
  disabled: PropTypes.bool,
  onError: PropTypes.func,
  onExpand: PropTypes.func,
  hideOptionalLabel: PropTypes.bool,
  isChild: PropTypes.bool,
};

Form.defaultProps = {
  initialValues: {},
  numColumns: 4,
  updateIdKey: "id",
  marginSize: margins.normal,
  disabled: false,
  values: {},
  hideOptionalLabel: false,
  isExpandable: false,
  onError: () => {},
  initialExpandedItems: [],
};

Form.propTypes = {
  data: PropTypes.array,
  initialValues: PropTypes.object,
  updateId: PropTypes.string,
  updateIdKey: PropTypes.string,
  onChange: PropTypes.func,
  onLoadingState: PropTypes.func,
  noPadding: PropTypes.bool,
  mutationType: PropTypes.string,
  mutationData: PropTypes.object,
  handleComplete: PropTypes.func,
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  numColumns: PropTypes.number,
  params: PropTypes.object,
  parentKeyName: PropTypes.string,
  parentKeyValue: PropTypes.string,
  saveButton: PropTypes.object,
  cancelButton: PropTypes.object,
  deleteButton: PropTypes.object,
  onSubmit: PropTypes.func,
  onRequiredValuesChange: PropTypes.func,
  marginSize: PropTypes.string,
  disabled: PropTypes.bool,
  hideOptionalLabel: PropTypes.bool,
  values: PropTypes.object,
  isExpandable: PropTypes.bool,
  initialExpandedItems: PropTypes.array,
  onExpand: PropTypes.func,
};

export default Form;
