import React from "react";
import { useOrganization } from "hooks";
import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import { Row } from "components/Containers";
import { DataTableProvider } from "components/DataTable";
import Status from "components/Status";
import styled from "@emotion/styled";
import ContactAdvanceOption from "components/Organization/ContactAdvanceOption";
import Card from "components/Card";

/**
 * OrganizationContact
 */
const OrganizationContact = ({ ...props }) => {
  const { id } = useParams();
  const { data, loading, error, refetch } = useOrganization({ id });

  return (
    <Card>
      <DataTableProvider
        data={data?.users?.map((item) => ({
          id: item.id,
          name: item.name,
          email: item.email,
          firstName: item.firstName,
          lastName: item.lastName,
          primaryContact: data?.primaryContactId === item.id ?? data?.primaryContactId,
        }))}
        resultsPerPage={1}
        error={error}
        loading={loading}
        columns={COLUMN_DATA(id, refetch)}
        paginationType={false}
        {...props}
      />
    </Card>
  );
};

const COLUMN_DATA = (id, refetch) => [
  {
    label: "Full Name",
    cell: (row) => row?.name,
    width: "30%",
  },
  {
    label: "Email",
    cell: (row) => row?.email,
    width: "40%",
  },
  {
    label: "Status",
    width: "40%",
    cell: (row) =>
      row?.primaryContact && (
        <Status enabled={row?.primaryContact}>
          <FormattedMessage id="Organizations.OrganizationContact.PrimaryContact" />
        </Status>
      ),
  },
  {
    label: "",
    width: "10%",
    cell: (row) => (
      <StyledRow>
        <ContactAdvanceOption data={{ ...row, organization: { id }, role: "client" }} refetch={refetch} />
      </StyledRow>
    ),
  },
];

const StyledRow = styled(Row)`
  justify-content: flex-end;
`;

export default OrganizationContact;
