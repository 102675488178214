import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import BootstrapTooltip from "react-bootstrap/Tooltip";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { fontSize } from "style";

/**
 * Tooltip Hover
 *
 * @params {String}     tooltipId
 * @params {Node}       children
 * @params {Object}     values
 * @params {Boolean}    hideIcon
 */
const Tooltip = ({ tooltipId, children, values, hideIcon }) => (
  <OverlayTrigger
    placement="top-end"
    overlay={
      <StyledToolTip fontSize={fontSize}>
        <FormattedMessage id={tooltipId} values={values} />
      </StyledToolTip>
    }
  >
    {({ ref, ...triggerHandler }) =>
      hideIcon ? (
        <div {...triggerHandler} ref={ref}>
          {children}
        </div>
      ) : (
        <StyledWrapper>
          <div>{children}</div>
          <StyledIcon type={ICON_TYPE.info} size={ICON_SIZE.small} {...triggerHandler} ref={ref} />
        </StyledWrapper>
      )
    }
  </OverlayTrigger>
);

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  display: inline-flex;
  justify-content: center;
`;

const StyledIcon = styled(Icon)`
  position: relative;
  top: -0.3rem;
`;

const StyledToolTip = styled(BootstrapTooltip)`
  font-size: ${fontSize.xxsmall};
  line-height: normal;
  white-space: pre-line;
`;

Tooltip.defaultProps = {
  hideIcon: false,
  fontSize: "",
};

Tooltip.propTypes = {
  labelId: PropTypes.string,
  children: PropTypes.node,
  tooltipId: PropTypes.node.isRequired,
  values: PropTypes.object,
  hideIcon: PropTypes.bool,
  fontSize: PropTypes.string,
};

export default Tooltip;
