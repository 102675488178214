import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import { PrimaryLink } from "components/Links";
import { withIcon, ICON_TYPE, ICON_SIZE } from "components/Icon";

/**
 * Photo
 *
 * @params {Object}     value
 * @params {Function}   onChange
 * @params {String}     name
 * @params {Object}     params
 */
const Photo = ({ value, onChange, name }) => {
  const [image, setImage] = useState(value);
  const fileInput = useRef(null);

  useEffect(() => {
    if(value instanceof File){
      let reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onloadend = () => setImage(reader.result);
    }
  },[value])

  const handleFileSelect = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    /* eslint-disable no-undef */
    let reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = () => setImage(reader.result);

    if (validity.valid) {
      onChange(name, file);
    }
  };

  const handleReplacePhoto = () => fileInput.current.click();

  const handleRemovePhoto = () => {
    setImage(null);
    onChange(name, null);
  };

  const LinkReplacePhoto = withIcon(PrimaryLink, {
    type: ICON_TYPE.upload,
    size: ICON_SIZE.xlarge,
    color: "inherit",
  });

  const LinkRemovePhoto = withIcon(PrimaryLink, {
    type: ICON_TYPE.close,
    size: ICON_SIZE.xlarge,
    color: "inherit",
  });

  return (
    <>
      <div css={styles.container}>
        {image && <div css={styles.image_container(image)} />}
        <div css={styles.link_container}>
          <div>
            <LinkReplacePhoto onClick={handleReplacePhoto}>
              {image ? (
                <FormattedMessage id="Form.Photo.TextReplace" />
              ) : (
                <FormattedMessage id="Form.Photo.UploadPhoto" />
              )}
            </LinkReplacePhoto>
          </div>
          {image && (
            <div>
              <LinkRemovePhoto onClick={handleRemovePhoto}>
                <FormattedMessage id="Form.Photo.TextRemove" />
              </LinkRemovePhoto>
            </div>
          )}
        </div>
      </div>
      <div css={styles.input}>
        <input ref={fileInput} accept="image/png, image/jpeg" type="file" onChange={handleFileSelect} />
      </div>
    </>
  );
};

const styles = {
  container: css`
    display: flex;
    align-items: center;
    font-size: ${fontSize.small};
    font-weight: 600;
  `,
  image_container: (image) => css`
    width: 18rem;
    height: 18rem;
    background: ${colors.grayAnatomyLight3};
    border-radius: 0.6rem;
    margin-right: 2rem;

    ${image &&
    `
      background: url(${image}) 50% 50% no-repeat;
      background-size:cover;
    `}
  `,
  input: css`
    display: none;
  `,
  link_container: css`
    display: flex;
    gap: 2rem;
  `,
};

Photo.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
  params: PropTypes.object,
};

export default Photo;
