/* eslint-disable no-undef */
const LINKS = {
  admin_squads_feedback: (id) => `/squads/${id}/feedback`,
  applicant_public_link: (mission_slug, applicantType) =>
    `${location.origin}/mission_role/${mission_slug}/${applicantType?.toLocaleLowerCase()}`,
  book_meeting_link: `/client/book_meeting`,
  candidate: `/candidate`,
  candidate_home: `/candidate/home`,
  candidate_profile: `/candidate/profile`,
  client_applications: "/client/applicants",
  client_faq: "https://mission.dev/build-with-mission/faq",
  client_home: "/client/home",
  client_mission_board: "/client/missions/board",
  client_profile: "/client/profile",
  client_search: "/client/search",
  client_team_builder: `/client/team_builder`,
  client_signup: "/client/signup",
  signal_signup: "/signal/signup",
  community: "/community",
  contact_member_experience: "https://gsquadio.atlassian.net/servicedesk/customer/portal/2/group/-1",
  dashboard: (slug) => `/dashboard/${slug}`,
  dashboard_activity_log: (slug) => `/dashboard/${slug}/activity_log`,
  dashboard_home: `/dashboard`,
  dashboard_activity_logs: `/dashboard/activity_logs`,
  favorites: "/favorites",
  favorite: (slug) => `/favorites/${slug}`,
  forgot_password: "/forgot_password",
  featured_teams: `/featured_teams`,
  featured_team: (slug) => `/featured_teams/${slug}`,
  home: "/home",
  insights: (profile_slug) => `${window.location.origin}/platform/profiles/${profile_slug}/insights`,
  legacy_network: `/platform/network`,
  partners_legacy_network: `/partners/network`,
  library: `https://library.mission.dev`,
  list: (slug) => `/lists/${slug}`,
  lists: "/lists",
  login: "/signin",
  logout: "/users/sign_out",
  member_faq: "https://mission.dev/join-the-network/faq",
  member_mission_report: (id) => `/squads/${id}/mission_report`,
  mission: (mission_slug) => `/missions/${mission_slug}`,
  missions: "/missions",
  mission_applicants: "/missions/applicants",
  mission_board: "/missions/board",
  mission_create: `/missions/new`,
  mission_edit: (mission_slug) => `/missions/${mission_slug}/edit`,
  mission_role: (mission_slug, role_slug) => `/missions/${mission_slug}/${role_slug}`,
  mission_url: (mission_slug) => `/missions/${mission_slug}/proposal`,
  network: "/network",
  network_with_defaults:
    "/network?f=eyJzb3J0Ijoic2hvd2Nhc2Vfc2NvcmUiLCJzb3J0RGlyZWN0aW9uIjoiZGVzYyIsInN0YXR1cyI6IkFueSIsImlzX29wZW5fdG9fZW1wbG95bWVudCI6MSwiaGFzX3ZpZGVvX2ludGVydmlld3MiOjEsImlzX2F2YWlsYWJsZSI6MX0M",
  note: (id, noteId = null) => `/network/profiles/${id}/notes#note-${noteId}`,
  notes: (id) => `/network/profiles/${id}/notes`,
  onboarding: "/onboarding",
  organizations: "/organizations",
  organization_profile: (id) => `/organizations/${id}`,
  partners: "/partners",
  privacy: "/legal/privacy-policy",
  private_profile: (slug) => `/network/profiles/${slug}`,
  profile_code_challenges: (slug) => `/network/profiles/${slug}/code_challenges`,
  profile_video_interviews: (slug) => `/network/profiles/${slug}/video_interviews`,
  public_list: (id) => `/lists/${id}/public`,
  public_mission_role: (mission_slug, role_slug) => `/missions/${mission_slug}/public/${role_slug}`,
  public_profile: (uuid) => `/profile/public/${uuid}`,
  pulse_survey_faq: `https://library.mission.dev/team-dynamics`,
  rate: (slug) => `/network/profiles/${slug}/rate`,
  reset_password: (id) => `/reset_password/${id}`,
  settings: (slug) => `/network/profiles/${slug}/edit`,
  platform_settings: "/settings",
  settings_code_challenges: "/settings/code_challenges",
  setting_mission_events: "/settings/mission_events/manage",
  setting_platform_access: "/settings/access",
  setting_prompts: "/settings/prompts",
  signup: "/signup", // This should be changed
  squads: "/squads",
  squad: (id) => `/squads/${id}`,
  squad_create: `/squads/new`,
  squad_activity_log: (teamSlug) => `/squads/${teamSlug}/engineering/activity`,
  squad_activity_report: (id) => `/squads/${id}/engineering/activity`,
  squad_activity_all_time: (id) => `/squads/${id}/engineering/all_time_activity`,
  squad_daily_report: (id) => `/squads/${id}/daily_report`,
  squad_effort_report: (id) => `/squads/${id}/effort_report`,
  squad_insights: (id) => `/squads/${id}/insights`,
  squad_engineering: (id) => `/squads/${id}/engineering`,
  squad_engineering_code_telemetry: (id) => `/squads/${id}/engineering/code_telemetry`,
  squad_engineering_code_telemetry_individual: (id, uuid) => `/squads/${id}/engineering/code_telemetry/${uuid}`,
  squad_general_settings: (id) => `/squads/${id}/settings/general`,
  squad_profile: (id) => `/squads/${id}/profile`,
  squad_pulse: (id) => `/squads/${id}/pulse`,
  squad_settings: (id) => `/squads/${id}/settings`,
  squad_daily_report_settings: (id) => `/squads/${id}/settings/daily_report`,
  squad_engineering_metrics_settings: (id) => `/squads/${id}/settings/engineering_metrics`,
  squad_signal_installation_settings: (id) => `/squads/${id}/settings/signal_installation`,
  squad_term_sheet: (id) => `/squads/${id}/term_sheet`,
  showcase_magic_link: (showcaseId) => `/showcase/${showcaseId}/access`,
  showcase_mission: (mission_slug) => `/missions/${mission_slug}`,
  showcase_user_favorite_list: (showcase_slug, list_slug) => `/showcase/${showcase_slug}/lists/${list_slug}`,
  showcase_search: `/client/search`,
  showcase_signup_to_client: (uuid) => `/client/signup?showcase_id=${uuid}`,
  showcase_signup: "/showcase/signup",
  showcase_team_builder: `/client/team_builder`,
  start_impersonating: (user_id) => `/admin/start_impersonating/${user_id}`,
  stop_impersonating: `/admin/stop_impersonating`,
  terms_and_conditions: "/legal/terms-and-conditions",
  timecards: `/timecards`,
  time_sheet: "/timesheet",
  timeline: (id) => `/network/profiles/${id}/timeline`,
  mission_github_app: `https://github.com/apps/mission-signal/installations/new`,
  mission_github_app_settings: (organization, installationId) =>
    `https://github.com/organizations/${organization}/settings/installations/${installationId}`,
  mission_github_staging_app: `https://github.com/apps/mission-insights-staging/installations/new`,
  github_base_url: `https://github.com`,
};

export default LINKS;
