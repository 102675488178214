import React from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";

/**
 * DropDown
 *
 * @params {Element}  children
 */
const DropDown = ({ children, ...props }) => <Dropdown {...props}>{children}</Dropdown>;

DropDown.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DropDown;
