import { useEffect, useState } from "react";
import { GET_PROFILES_AFFINITIES } from "graphql/queries";
import { useQueryData } from "hooks";

/**
 * useProfilesAffinities
 *
 * @param {Array}  profileIds
 * @param {String}  month
 */
export default function useProfilesAffinities({ profileIds, month }) {
  const [data, setData] = useState([]);
  const {
    loading,
    data: response,
    error,
  } = useQueryData({
    queryName: GET_PROFILES_AFFINITIES,
    keyName: "profileAffinities",
    skip: !profileIds,
    variables: { profileIds, month },
  });

  useEffect(() => {
    if (response) {
      setData(response.profilesAffinities);
    }
  }, [response]);

  return {
    loading,
    data,
    error,
  };
}
