import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import Card, { CardMenu } from "components/Card";
import { css } from "@emotion/react";
import { fontSize, colors } from "style";
import { PrimaryButton } from "components/Buttons";
import { formatNumber, shortenText } from "utils";
import { TextLoader } from "components/Loader";
import { LINKS } from "constants/index";
import { Link, PrimaryLink } from "components/Links";
import { useAuth, useMission } from "hooks";
import MissionAdvancedOptions from "./MissionAdvancedOptions";
import { Row } from "components/Containers";
import { OrganizationLink } from "components/Organization";

/**
 * MissionCard
 *
 * @param {String}  id
 * @param {Object}  data
 * @param {Boolean} loading
 */
const MissionCard = ({ id, data, loading }) => {
  const { isAdmin } = useAuth();
  const { data: mission, loading: loadingMission } = useMission({ id, skip: !id || data?.name });
  const isLoading = loading || loadingMission;
  const missionData = mission || data;

  if (isLoading) {
    return <PlaceHolder />;
  }

  return (
    <Card css={styles.container}>
      <CardMenu>
        <MissionAdvancedOptions data={data} loading={isLoading} />
      </CardMenu>
      <div css={styles.company}>
        <OrganizationLink
          data={{ ...missionData?.organization, isConfidential: missionData?.isOrganizationConfidential }}
          withOverlay={isAdmin}
          placement="auto"
        />
      </div>
      <div css={styles.title}>{missionData?.name}</div>
      <div css={styles.body}>
        {shortenText(missionData?.description, 200)}
        <div css={styles.read_more_link}>
          <PrimaryLink to={LINKS.mission(missionData?.uuid)} routerEnabled withMoreIcon>
            <FormattedMessage id="Global.ReadMore" />
          </PrimaryLink>
        </div>
      </div>
      <div css={styles.data_container}>
        <div css={styles.label}>
          <FormattedMessage id="Missions.MissionCard.LabelTimezone" />
          <div css={styles.item}>
            <span>{missionData?.timezone?.name}</span>
          </div>
        </div>
        <div css={styles.label}>
          <FormattedMessage id="Missions.MissionCard.LabelStartDate" />
          <div css={styles.item}>
            <span>{moment(missionData?.startDate).format("MMMM Do, YYYY")}</span>
          </div>
        </div>
        <div css={styles.label}>
          <FormattedMessage id="Missions.MissionCard.LabelRoles" />
          <div css={styles.item}>
            <span>{formatNumber(missionData?.rolesCount)}</span>
          </div>
        </div>
      </div>
      <Row css={styles.button_container}>
        <Link to={LINKS.mission(missionData?.uuid)} routerEnabled>
          <PrimaryButton>
            <FormattedMessage id="Mission.MissionCard.Cta" />
          </PrimaryButton>
        </Link>
      </Row>
    </Card>
  );
};

/**
 * Placeholder
 */
const PlaceHolder = () => (
  <Card css={styles.container} fixedHeight>
    <div css={styles.company}>
      <TextLoader width="30%" />
    </div>
    <div css={styles.title}>
      <TextLoader width="80%" />
    </div>
    <div css={styles.body}>
      {[1, 2, 3, 4].map((item) => (
        <TextLoader key={item} width="100%" />
      ))}
      <TextLoader width="60%" />
    </div>
  </Card>
);

const styles = {
  container: css`
    padding: 3rem;
  `,
  button_container: css`
    justify-content: flex-end;

    a {
      width: 100%;
      > * {
        width: 100%;
      }
    }
  `,
  data_container: css`
    margin: 2rem 0;
    border-bottom: 1px ${colors.grayAnatomyLight4} solid;
  `,
  company: css`
    font-size: ${fontSize.xsmall};
    color: ${colors.grayAnatomyLight1};
    font-weight: 500;
    padding-right: 3rem;
  `,
  title: css`
    font-size: ${fontSize.large};
    font-weight: 600;
    margin-top: 0.5rem;
    line-height: 1.25;
    padding-right: 5rem;
  `,
  body: css`
    font-size: ${fontSize.small};
    margin-top: 2rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  `,
  label: css`
    font-size: ${fontSize.small};
    font-weight: 500;
    border-top: 1px ${colors.grayAnatomyLight4} solid;
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  item: css`
    font-weight: 400;
    text-align: left;
    color: ${colors.grayAnatomyLight1};
    line-height: normal;
  `,
  read_more_link: css`
    margin-top: 0.5rem;
  `,
};

MissionCard.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
};

export default MissionCard;
