import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { MUTATION_TYPE, useToast } from "hooks";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { ADD_USER } from "graphql/mutations";
import { GET_PLATFORM_ACCESS_USERS } from "graphql/queries";
import { USER_FORM_CONFIGS, USER_TYPES } from "constants/index";

/**
 * User Invite Form
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {String}    userType
 * @param {Object}    data
 * @param {Object}    refetchQuery
 */
const UserInviteForm = ({ show, onClose, userType, refetchQuery, data, ...props }) => {
  const { toast } = useToast();

  const initialValues = {
    role: userType.toUpperCase(),
    ...(userType.toUpperCase() === USER_TYPES.showcase && { skipInvite: true }),
    ...data,
  };

  const formProps = {
    values: initialValues,
    mutationType: MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: ADD_USER,
      },
      refetchAfterMutate: true,
      refetchQueries: [refetchQuery],
    },
    ...props,
  };

  const handleComplete = () => {
    onClose();
    toast.success(<FormattedMessage id="Settings.Users.UserInviteForm.CreateSuccess" />);
  };

  const handleError = () => {
    toast.error(<FormattedMessage id="Settings.Users.UserInviteForm.CreateError" />);
  };

  return (
    <ModalForm
      title={<FormattedMessage id="Settings.Users.UserInviteForm.Title" values={{ type: userType }} />}
      data={FORM_DATA(userType, data)}
      show={show}
      size={MODAL_SIZE.small}
      onClose={onClose}
      onCompleted={handleComplete}
      onError={handleError}
      {...formProps}
    />
  );
};

const FORM_DATA = (userType, data) => [
  {
    style: css`
      width: 100%;
    `,
    items: USER_FORM_CONFIGS[userType]?.(data) || [],
  },
];

UserInviteForm.defaultProps = {
  show: false,
  onClose: () => {},
  userType: "client",
  data: {},
  refetchQuery: GET_PLATFORM_ACCESS_USERS,
};

UserInviteForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  userType: PropTypes.string,
  data: PropTypes.object,
  refetchQuery: PropTypes.object,
};

export default UserInviteForm;
