import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useQueryData, useToast } from "hooks";
import { GET_LIST } from "graphql/queries";
import { ADD_MEMBER_TO_PROFILE_LIST, ARCHIVE_LIST, REMOVE_PROFILE_FROM_LIST } from "graphql/mutations";
import { PAGINATION_TYPE } from "components/Pagination";

export default function useList({
  profileId,
  variables,
  paginationType,
  ignoreEmpty = false,
  skipListQuery = false,
} = {}) {
  const resolver = useRef();
  const { toast } = useToast();
  const [isProfileAdded, setIsProfileAdded] = useState(false);
  const [lists, setLists] = useState([]);
  const [handleArchiveList] = useMutation(ARCHIVE_LIST);
  const [handleProfileList, { loading: adding }] = useMutation(ADD_MEMBER_TO_PROFILE_LIST);
  const [removeProfile, { loading: removing }] = useMutation(REMOVE_PROFILE_FROM_LIST);
  const {
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    data,
    loading,
    loadingMore,
    error,
    refetch,
  } = useQueryData({
    queryName: GET_LIST,
    variables,
    isCursorPagination: paginationType === PAGINATION_TYPE.cursor,
    skip: skipListQuery,
  });

  useEffect(() => {
    const list = data?.list?.nodes?.find((list) => list.id);
    setIsProfileAdded(list?.profiles?.map((profile) => profile.id)?.includes(profileId));
  }, [data, profileId]);

  useEffect(() => {
    if (ignoreEmpty) {
      setLists(data?.list?.nodes.filter((list) => list.profiles.length > 0) || []);
    } else {
      setLists(data?.list?.nodes || []);
    }
  }, [data]);

  const addToList = (listIds, onCompleted) => {
    handleProfileList({
      variables: {
        input: {
          profileId,
          listIds,
        },
      },
      onCompleted: () => {
        onCompleted && onCompleted();
        toast.success(<FormattedMessage id={"Profile.AddedToProfileList"} />);
        resolver.current && resolver.current(true);
      },
      onError: () => {
        toast.error(<FormattedMessage id={"Profile.ErrorOnAddToProfileList"} />);
        resolver.current && resolver.current(false);
      },
    });

    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  };

  const removeFromList = (id, listId) => {
    removeProfile({
      variables: {
        input: {
          profileId: id || profileId,
          listId,
        },
      },
      onCompleted: () => {
        toast.success(<FormattedMessage id={"Profile.RemovedFromProfileList"} />);
      },
      onError: () => {
        toast.error(<FormattedMessage id={"Profile.ErrorOnRemoveFromProfileList"} />);
      },
    });
  };

  const archiveList = (list) => {
    handleArchiveList({
      variables: {
        input: { id: list?.id },
      },
      refetchQueries: [GET_LIST],
      onCompleted: () => {
        setLists((prevLists) => prevLists.filter((item) => item.id !== list.id));
        toast.success(<FormattedMessage id={"List.Archived"} />);
      },
      onError: () => toast.error(<FormattedMessage id={"List.ErrorOnArchive"} />),
    });
  };

  return {
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    loadingMore,
    loading,
    refetch,
    error,
    addToList,
    removeFromList,
    removing,
    adding,
    isProfileAdded,
    setLists,
    archiveList,
    data: lists,
    pageInfo: data,
    totalCount: data?.list?.totalCount,
    currentPage: data?.list?.currentPage,
  };
}
