import React from "react";
import moment from "moment";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import DisplayBox from "components/DisplayBox";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { SIZE, TYPE } from "components/Form";
import { ADD_PROFILE_VACATION, UPDATE_PROFILE_VACATION, REMOVE_PROFILE_VACATION } from "graphql/mutations";
import { GET_PROFILE } from "graphql/queries";
import { sortByDate, filterByFunction } from "utils";
import Icon, { ICON_TYPE } from "components/Icon";
import { colors } from "style";
import { PAGES } from "constants/index";

/**
 * ProfileDaysOff
 *
 * @params {Array}    data
 * @params {String}   profileId
 * @params {Boolean}  isEditable
 */
const ProfileDaysOff = ({ data, profileId, isEditable, ...props }) => {
  const vacationDays = data && data?.vacations ? data?.vacations : [];

  const upcomingDates = sortByDate(
    filterByFunction(vacationDays, "endDate", (val) => moment().isBefore(val)),
    "startDate",
    "asc"
  );

  const pastDates = sortByDate(
    filterByFunction(vacationDays, "endDate", (val) => moment().isAfter(val)),
    "startDate",
    "desc"
  );

  const sortedData = [...upcomingDates, ...pastDates];

  return (
    <DisplayBox
      title={<FormattedMessage id="Profile.ProfileDaysOff.Title" />}
      editButtonText={<FormattedMessage id="Profile.ProfileDaysOff.AddDaysOff" />}
      numColumns={1}
      data={VACATION_DATA(sortedData, isEditable)}
      parentKeyName="profileId"
      parentKeyValue={profileId}
      canAdd={isEditable}
      isEditable={isEditable}
      initialValues={vacationDays?.map((i) => ({
        id: i.id,
        startDate: i.startDate,
        endDate: i.endDate,
      }))}
      isEmpty={vacationDays.length === 0}
      {...props}
    />
  );
};

const VACATION_DATA = (data, isEditable) =>
  data?.map((item) => ({
    id: item.id,
    title: (
      <span css={styles.title(moment().isAfter(item.startDate), moment().isBefore(item.endDate))}>
        <FormattedMessage
          id={
            moment().isAfter(item.startDate)
              ? moment().isAfter(item.endDate)
                ? "Profile.ProfileDaysOff.LabelPastEnded"
                : "Profile.ProfileDaysOff.LabelPast"
              : "Profile.ProfileDaysOff.LabelFuture"
          }
          values={{
            fromNow:
              moment().isAfter(item.startDate) && moment().isAfter(item.endDate)
                ? moment(item.endDate).fromNow()
                : moment(item.startDate).fromNow(),
          }}
        />
      </span>
    ),
    body: (
      <span css={styles.body}>
        <Icon type={ICON_TYPE.calendar} />
        <FormattedMessage
          id="Profile.ProfileDaysOff.Range"
          values={{
            startDate: moment(item.startDate).format("MMMM Do"),
            endDate: moment(item.endDate).format("MMMM Do"),
            endDateYear: moment(item.endDate).format("YYYY"),
          }}
        />
      </span>
    ),
    isEditable,
  }));

const styles = {
  title: (startedInPast, finishesInFuture) => css`
    ${startedInPast &&
    !finishesInFuture &&
    `
      font-weight:400;
      color: ${colors.grayAnatomyLight1};
    `}
  `,
  body: css`
    display: flex;
    align-items: center;

    * {
      margin-right: 0.5rem;
    }
  `,
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Start Date",
        type: TYPE.datePicker,
        size: SIZE.medium,
        properties: {
          name: "startDate",
          required: true,
        },
      },
      {
        label: "End Date",
        type: TYPE.datePicker,
        size: SIZE.medium,
        properties: {
          name: "endDate",
          required: true,
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Profile.ProfileDaysOff.FormTitle" />,
  description: <FormattedMessage id="Profile.ProfileDaysOff.FormDescription" />,
  isDestructable: true,
  mutationData: {
    mutationName: {
      update: UPDATE_PROFILE_VACATION,
      add: ADD_PROFILE_VACATION,
      delete: REMOVE_PROFILE_VACATION,
    },
    refetchQueries: [GET_PROFILE],
    refetchAfterMutate: true,
  },
  page: PAGES.vacationDates,
};

ProfileDaysOff.propTypes = {
  data: PropTypes.object,
  profileId: PropTypes.string,
  isEditable: PropTypes.bool,
};

export default withModalForm(ProfileDaysOff, FORM_SETTINGS);
