import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { TERM_SHEET_TYPES } from "constants/index";
import ListTable from "components/ListTable";
import { useTeams } from "hooks";
import { formatCurrency } from "utils";

/**
 * ProfileAssignedRateAndCapacity
 *
 * @params {String} profileId
 */
const ProfileAssignedRateAndCapacity = ({ profileId, ...props }) => {
  const { data, loading, error } = useTeams({ profileId, limit: null });

  if (!data) return null;

  return (
    <ListTable
      title={<FormattedMessage id="Profile.AssignedRateAndCapacity.Title" />}
      data={loading ? null : TABLE_DATA(data, profileId)?.flat()}
      loading={loading}
      error={error}
      singleColumn
      {...props}
    />
  );
};

const TABLE_DATA = (data, id) =>
  data?.map((item) => {
    const { buyingCurrency, buyingAmount, termSheetType, averageCapacity } =
      item?.assignments.find(({ slug, profileId }) => [profileId, slug].includes(id)) || {};
    const valuesFound = averageCapacity && termSheetType && buyingAmount && buyingCurrency;
    return {
      label: item?.name,
      value: (
        <FormattedMessage
          id={`${valuesFound ? "Profile.AssignedRateAndCapacity.RowData" : "Global.NotAvailable"}`}
          values={{
            averageCapacity,
            termSheetType: TERM_SHEET_TYPES[termSheetType],
            buyingAmount: formatCurrency(buyingAmount, buyingCurrency),
          }}
        />
      ),
    };
  });

ProfileAssignedRateAndCapacity.propTypes = {
  profileId: PropTypes.string.isRequired,
};

export default ProfileAssignedRateAndCapacity;
