/**
 * Convert snake case string to capitalized string
 *
 * @param {string} str
 *
 * @returns {string}
 */
export default function snakeToCapitalized(str) {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
