import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useAuth, useProfile } from "hooks";
import DisplayBox from "components/DisplayBox";
import RenderIconWithText, { RENDER_SIZE } from "components/DisplayBox/Renderers/RenderIconWithText";
import { ICON_TYPE, ICON_SIZE, StatusIcon } from "components/Icon";
import { colors, margins } from "style";

/**
 * ProfileProgress
 *
 * @param {String}    id
 */
const ProfileProgress = ({ id, ...props }) => {
  const { profileCompletion } = useProfile({ profileId: id });
  const { canViewEditRate } = useAuth();

  return (
    <DisplayBox
      title={<FormattedMessage id="Profile.ProfileProgress.Title" />}
      numColumns={1}
      marginSize={margins.normal}
      data={Object.entries(profileCompletion)}
      rowGap={0}
      itemRenderer={(item) => {
        const { isComplete, titleMessageId } = item[1];
        const key = item[0];

        return (
          <RenderIconWithText
            size={RENDER_SIZE.small}
            icon={
              <StatusIcon
                type={ICON_TYPE.checkmark}
                size={ICON_SIZE.large}
                color={colors.grayAnatomyLight2}
                isComplete={isComplete}
              />
            }
            isComplete={isComplete}
            color={isComplete ? colors.purpleRainDark2 : colors.grayAnatomyLight1}
            text={{
              title: <FormattedMessage id={getMessageId(key, titleMessageId, { canViewEditRate })} />,
            }}
          />
        );
      }}
      {...props}
    />
  );
};

/**
 * getMessageId
 *
 * @param {String}  key
 * @param {String}  titleId
 * @param {Object}  settings
 * @returns
 */
function getMessageId(key, titleId, settings) {
  switch (key) {
    case "rate":
      // If user doesn't have the permission to view/edit rate, then show the title "Profile.ProfileAvailability.Title"
      return settings?.canViewEditRate ? titleId : "Profile.ProfileAvailability.Title";
    default:
      return titleId;
  }
}

ProfileProgress.propTypes = {
  id: PropTypes.string,
};

export default ProfileProgress;
