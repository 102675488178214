import { gql } from "@apollo/client";

const clientFeedbackFields = `
  churnRisk
  csat
  healthScore
  id
  postMortemScore
  notes
  nps
  onboardingScore
  submittedAt
  uuid
  version {
    id
    title
  }
`;

const CLIENT_FEEDBACK_FIELDS = gql`
  fragment ClientFeedbackFields on ClientFeedback {
    ${clientFeedbackFields}
  }
`;

export { CLIENT_FEEDBACK_FIELDS };
