import React from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import DisplayBox from "components/DisplayBox";
import { getYearRange } from "utils";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { SIZE, TYPE } from "components/Form";
import { ADD_PROFILE_EDUCATION, UPDATE_PROFILE_EDUCATION, REMOVE_PROFILE_EDUCATION } from "graphql/mutations";
import { GET_PROFILE } from "graphql/queries";
import { sortByDate, getYearFromDate } from "utils";
import { PAGES } from "constants/index";

/**
 * ProfileEducation
 *
 * @params {Array}    data
 * @params {String}   profileId
 * @params {Boolean}  isEditable
 */
const ProfileEducation = ({ data, profileId, isEditable, ...props }) => {
  const sortedData = sortByDate(data, "startDate", "desc");

  return (
    <DisplayBox
      title={<FormattedMessage id="Profile.ProfileEducation.Title" />}
      editButtonText={<FormattedMessage id="Profile.ProfileEducation.AddEducation" />}
      numColumns={1}
      data={EDUCATION_DATA(sortedData, isEditable)}
      parentKeyName="profileId"
      parentKeyValue={profileId}
      canAdd={isEditable}
      isEditable={isEditable}
      initialValues={sortedData?.map((i) => ({
        id: i.id,
        profileId: data?.id,
        degreeName: i.degreeName,
        startDate: i.startDate,
        endDate: i.endDate,
        schoolName: i.schoolName,
        fieldOfStudy: i.fieldOfStudy,
      }))}
      {...props}
    />
  );
};

const EDUCATION_DATA = (data, isEditable) =>
  data?.map((item) => ({
    id: item.id,
    title: item.degreeName,
    subtitle: !item.endDate ? `${getYearFromDate(item.startDate)} - Present` : getYearRange(item.startDate, item.endDate),
    body: `${item.schoolName}, ${item.fieldOfStudy}`,
    isEditable,
  }));

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Degree Name",
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "degreeName",
          type: "text",
          maxLength: 255,
          required: true,
          autoFocus: true,
        },
      },
      {
        label: "Start Date",
        type: TYPE.datePicker,
        size: SIZE.medium,
        properties: {
          name: "startDate",
          required: true,
          yearDropdownItemNumber: 40,
        },
      },
      {
        label: "End Date",
        type: TYPE.datePicker,
        size: SIZE.medium,
        properties: {
          name: "endDate",
          required: false,
          yearDropdownItemNumber: 40,
        },
      },
      {
        label: "School Name",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "schoolName",
          type: "text",
          required: true,
        },
      },
      {
        label: "Field of Study",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "fieldOfStudy",
          type: "text",
          required: true,
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Profile.ProfileEducation.FormTitle" />,
  description: <FormattedMessage id="Profile.ProfileEducation.FormDescription" />,
  isDestructable: true,
  mutationData: {
    mutationName: {
      update: UPDATE_PROFILE_EDUCATION,
      add: ADD_PROFILE_EDUCATION,
      delete: REMOVE_PROFILE_EDUCATION,
    },
    refetchQueries: [GET_PROFILE],
    refetchAfterMutate: true,
  },
  page: PAGES.profileEducation,
};

ProfileEducation.propTypes = {
  data: PropTypes.array,
  profileId: PropTypes.string,
  isEditable: PropTypes.bool,
};

export default withModalForm(ProfileEducation, FORM_SETTINGS);
