import React, { useContext } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { ThemeContext, colors, fontSize, breakpoints } from "style";
import { H1 } from "components/Text";
import { TextLoader } from "components/Loader";
import {
  UserArchetype,
  UserCapacity,
  UserEngineeringLevel,
  UserFullName,
  UserLocation,
  UserBadges,
} from "components/User";
import { useAuth } from "hooks";
import { ProfileFlag } from "components/Profile";
import { Col, Row } from "components/Containers";

/**
 * ProfileHeader
 *
 * @param {Object}   data
 * @param {Boolean}  loading
 */
const ProfileHeader = ({ loading, data, ...props }) => {
  const theme = useContext(ThemeContext);
  const { isAdmin, isCandidate, isClient, isShowcase } = useAuth();

  if (loading) {
    return (
      <div css={styles.container}>
        <PlaceHolder />
      </div>
    );
  }

  return (
    <div css={styles.container} {...props}>
      <p css={styles.archetype_status(theme.secondary)}>
        <FormattedMessage
          id={
            theme.isPublic || isCandidate
              ? "Profile.ProfileHeader.SubtitleHideCapacity"
              : "Profile.ProfileHeader.Subtitle"
          }
          values={{
            archetype: <UserArchetype data={data} />,
            capacity: <UserCapacity data={data} />,
          }}
        />
      </p>
      <H1 css={styles.name}>
        <UserFullName data={data} loading={loading} disableLink={true} withLastInitial={theme.isPublic} />
        {isAdmin && <ProfileFlag count={data?.flaggedCommentsCount} />}
      </H1>
      <UserBadges badges={data?.badges} size="3rem" />
      <Col css={styles.col} gap={`0.75rem`}>
        <Row>
          <span css={styles.level}>
            <UserEngineeringLevel data={data} />
          </span>
        </Row>
        <span>
          <UserLocation data={data} />
        </span>
        {!theme.isPublic && !isClient && !isShowcase && <span css={styles.email}>{data?.email}</span>}
      </Col>
    </div>
  );
};

const PlaceHolder = () => (
  <>
    <div css={styles.archetype_status}>
      <TextLoader
        css={css`
          width: 10rem;
        `}
      />
    </div>
    <H1 css={styles.name}>
      <TextLoader
        css={css`
          width: 20rem;
        `}
      />
    </H1>
  </>
);

const styles = {
  container: css`
    @media (max-width: ${breakpoints.mobile}) {
      text-align: center;
      padding-top: 1rem;

      > * {
        margin: 0 auto;
        justify-content: center;
      }
    }
  `,
  engineering_icon: css`
    width: 2rem;
    height: 2rem;
  `,
  archetype_status: (styleOverride) => css`
    font-size: ${fontSize.small};
    font-weight: 500;
    line-height: 1.14;
    color: ${colors.grayAnatomyLight1};
    margin-bottom: 1.2rem;
    ${styleOverride}
  `,
  name: css`
    margin-left: 0;
    margin-bottom: 0.5rem;
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    font-weight: 600;
  `,
  col: css`
    margin-bottom: 3.2rem;
    font-size: ${fontSize.normal};
    font-weight: 400;

    @media (max-width: ${breakpoints.mobile}) {
      margin-right: 0;
    }
  `,
  email: css`
    color: ${colors.grayAnatomyLight1};
  `,
  level: css`
    font-weight: 500;
    font-size: ${fontSize.medium};

    @media (max-width: ${breakpoints.mobile}) {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
    }
  `,
};

ProfileHeader.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
};

export default ProfileHeader;
