import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import Icon, { ICON_TYPE } from "components/Icon";
import { colors } from "style";

/**
 * Progress Icon
 *
 * @params {String}   type
 * @params {String}   color
 * @params {bool}     isComplete
 * @params {bool}     isError
 */
const StatusIcon = ({ type, color, isComplete, isError, ...props }) => (
  <div css={styles.icon_container(isComplete, isError)}>
    <Icon
      type={isComplete ? ICON_TYPE.checkmark : isError ? ICON_TYPE.close : type}
      color={isComplete || isError ? `#fff` : color}
      {...props}
    />
  </div>
);

const styles = {
  icon_container: (isComplete, isError) => css`
    background: ${isComplete ? colors.green : isError ? colors.red : colors.grayAnatomyLight5};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    position: relative;
    width:100%;
    height:100%;
  `,
};

StatusIcon.defaultProps = {
  type: ICON_TYPE.checkmark
};

StatusIcon.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  isComplete: PropTypes.bool,
  isError: PropTypes.bool,
};

export default StatusIcon;
