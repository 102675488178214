import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { Outlet, useOutletContext } from "react-router-dom";
import { NavList, SubNav } from "components/Nav";
import { LINKS } from "constants";
import { Grid, PageWrapper } from "components/Containers";
import { SignalNotice } from "components/Squads/settings";
import { useAuth } from "hooks";

/**
 * Engineering
 */
const Engineering = () => {
  const intl = useIntl();
  const parentContext = useOutletContext();
  const { isClient, isSignalClient } = useAuth();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.Engineering.Title" })}</title>
      </Helmet>
      <SubNav>
        <NavList pages={NAV_ITEMS(parentContext.id)} />
      </SubNav>
      <PageWrapper>
        {(isSignalClient || isClient) && !parentContext.loading && (
          <Grid topMargin>
            <Grid.col start={1} end={13}>
              <SignalNotice teamData={parentContext.selectedSquad} />
            </Grid.col>
          </Grid>
        )}
        <Outlet context={{ ...parentContext }} />
      </PageWrapper>
    </>
  );
};

const NAV_ITEMS = (id) => [
  {
    label: <FormattedMessage id="Squads.SquadSubNav.Engineering.CodeTelemetry" />,
    to: LINKS.squad_engineering_code_telemetry(id),
    props: {
      end: false,
    },
  },
  {
    label: <FormattedMessage id="Squads.SquadSubNav.Engineering.Activity" />,
    to: LINKS.squad_activity_report(id),
    props: {
      end: true,
    },
  },
  {
    label: <FormattedMessage id="Squads.SquadSubNav.Engineering.ActivityAllTime" />,
    to: LINKS.squad_activity_all_time(id),
    props: {
      end: true,
    },
  },
];

export default Engineering;
