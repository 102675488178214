import { gql } from "@apollo/client";
import { ORGANIZATION_TEAM_FIELDS } from "graphql/fragments";

const GET_TEAMS = gql`
  ${ORGANIZATION_TEAM_FIELDS}
  query GetTeams(
    $profileId: ID = null
    $organizationId: ID = null
    $organization: Boolean = true
    $assignments: Boolean = true
    $timeSheetSummary: Boolean = false
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filters: TeamFilter
    $limit: Int = 4
    $paginationInfo: PaginationInfo
    $sort: String
    $sortDirection: String
  ) {
    teams(
      profileId: $profileId
      organizationId: $organizationId
      first: $first
      last: $last
      after: $after
      before: $before
      filters: $filters
      paginationInfo: $paginationInfo
      sort: $sort
      sortDirection: $sortDirection
    ) {
      totalCount
      currentPage
      perPage
      currentOffset
      nodes {
        id
        slug
        name
        size
        missionId
        status
        versionStatus
        teamType
        gitRepositories
        ...OrganizationTeamFields
        assignments(limit: $limit) @include(if: $assignments) {
          id
          slug
          firstName
          lastName
          avatarUrl
          termSheetType
          buyingAmount
          buyingCurrency
          sellingAmount
          sellingCurrency
          termSheetType
          averageCapacity
          profileId
          profile {
            slug
            avatarUrl
            firstName
            lastName
            name
            uuid
          }
        }
        timeSheetSummary(profileId: $profileId) @include(if: $timeSheetSummary) {
          billableMonth
          allocatedHours
          allocatedMinutes
          completedHours
          completedMinutes
          timeCardOverage
          insights {
            startDate
            endDate
            chartLink
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

const GET_TEAMS_FOR_SELECT = gql`
  query GetTeamsForSelect($filters: TeamFilter) {
    teams(filters: $filters) {
      nodes {
        id
        name
      }
    }
  }
`;

const GET_ACTIVE_TEAMS = gql`
  query GetTeams($year: String, $month: String) {
    activeTeams(year: $year, month: $month) {
      nodes {
        id
        slug
        name
        size
      }
    }
  }
`;

export { GET_TEAMS, GET_ACTIVE_TEAMS, GET_TEAMS_FOR_SELECT };
