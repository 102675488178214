/** @jsx jsx */
import styled from "@emotion/styled";
import { breakpoints } from "style";

/**
 * Table
 */
const Table = styled.table`
  line-height: 1.71;
  width: calc(100% + 6rem);
  vertical-align: top;
  table-layout: ${(props) => props.layout};
  margin-left: -3rem;

  @media (max-width: ${breakpoints.portrait}) {
    width: calc(100% + 5rem);
    margin-left: -2.5rem;
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }
`;

Table.defaultProps = {
  layout: `fixed`,
};

export default Table;
