/**
 * timeSheetReducer
 *
 * @param {Array}     state
 * @param {Object}    action
 */
const timeSheetReducer = (state, action) => {
  switch (action.type) {
    case TIME_SHEET_ACTIONS.init:
      return action.payload;

    case TIME_SHEET_ACTIONS.update:
      return state.map((item) => {
        if (item.assignmentId !== action.payload.assignmentId || item.assignmentDate !== action.payload.activityDate) {
          return item;
        }

        return {
          ...item,
          ...{
            [action.payload.activityType]: {
              ...item[action.payload.activityType],
              timeLogged: action.payload.timeLogged,
            },
            description: action.payload?.description
          },
        };
      });

    case TIME_SHEET_ACTIONS.filter:
      return action.payload.data.filter((item) => item.teamId === action.payload.team);
  }
};

const TIME_SHEET_ACTIONS = {
  init: "init",
  update: "update",
  filter: "filter",
};

export { TIME_SHEET_ACTIONS };

export default timeSheetReducer;
