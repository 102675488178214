import { gql } from "@apollo/client";

const GET_TEAM_ACTIVITY_SCORE = gql`
  query teamPerformanceActivityScore($teamId: ID!) {
    teamPerformanceActivityScore(teamId: $teamId) {
      week
      weekDate
      score
      scoreDelta
      scoreDeltaPercent
      sparkline
    }
  }
`;

const GET_TEAM_VOLUME_SCORE = gql`
  query teamPerformanceVolumeScore($teamId: ID!) {
    teamPerformanceVolumeScore(teamId: $teamId) {
      week
      weekDate
      score
      scoreDelta
      scoreDeltaPercent
      sparkline
    }
  }
`;

const GET_TEAM_EFFICIENCY_SCORE = gql`
  query teamPerformanceEfficiencyScore($teamId: ID!) {
    teamPerformanceEfficiencyScore(teamId: $teamId) {
      week
      weekDate
      score
      scoreDelta
      scoreDeltaPercent
      sparkline
    }
  }
`;

export { GET_TEAM_ACTIVITY_SCORE, GET_TEAM_VOLUME_SCORE, GET_TEAM_EFFICIENCY_SCORE };
