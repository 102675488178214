import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { TermSheetScenarioScheduleForm } from ".";
import { PrimaryButton } from "components/Buttons";
import Icon, { ICON_POSITION, ICON_SIZE, ICON_TYPE } from "components/Icon";
import { Row } from "components/Containers";
import { scheduledChangesChecker } from "./TermSheetScenarioScheduleForm";

/**
 * TermSheetScenarioScheduleButton
 *
 * @param {Object}    selectedScenario
 * @param {Object}    changes
 * @param {Function}  onComplete
 */
const TermSheetScenarioScheduleButton = ({ selectedScenario, changes, onComplete, ...props }) => {
  const [showForm, setShowForm] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (changes) {
      setHasChanges(Object.keys(changes)?.some((key) => scheduledChangesChecker(changes?.[key])));
    }
  }, [changes]);

  return (
    <>
      <PrimaryButton variant="green" onClick={() => setShowForm(true)} disabled={!selectedScenario || !hasChanges}>
        <Row gap="0.5rem">
          <FormattedMessage id={`TermSheets.TermSheetScenarioScheduleButton`} />
          <Icon type={ICON_TYPE.clock} size={ICON_SIZE.normal} position={ICON_POSITION.left} color={"inherit"} />
        </Row>
      </PrimaryButton>
      <TermSheetScenarioScheduleForm
        show={showForm}
        onClose={() => setShowForm(false)}
        onComplete={onComplete}
        selectedScenario={selectedScenario}
        changes={changes}
        {...props}
      />
    </>
  );
};

TermSheetScenarioScheduleButton.propTypes = {
  selectedScenario: PropTypes.object,
  changes: PropTypes.object,
  onComplete: PropTypes.func,
};

export default TermSheetScenarioScheduleButton;
