import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { GET_TERM_SHEET_SCENARIOS } from "graphql/queries";
import { useToast, useQueryData, useModalConfirmation } from "hooks";
import { REMOVE_TERM_SHEET_SCENARIO, UPDATE_TERM_SHEET_SCENARIO } from "graphql/mutations/termSheet";
import { FormattedMessage } from "react-intl";

/**
 * useTermSheetScenarios
 */
export default function useTermSheetScenarios({ teamId, state }) {
  const { toast } = useToast();
  const { show } = useModalConfirmation();
  const keyName = "teamScenarios";
  const defaultVariables = { after: null, first: null, last: null, orderBy: {} };
  const [variables, setVariables] = useState({ teamId, ...defaultVariables });

  const [removeScenario] = useMutation(REMOVE_TERM_SHEET_SCENARIO, {
    refetchQueries: [GET_TERM_SHEET_SCENARIOS],
  });

  const [updateScenario, { loading: saving }] = useMutation(UPDATE_TERM_SHEET_SCENARIO);

  const { loading, data, error } = useQueryData({
    queryName: GET_TERM_SHEET_SCENARIOS,
    keyName,
    variables,
    skip: Number.isInteger(parseInt(teamId)) === false,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    setVariables({ filters: { teamId, state }, ...defaultVariables });
  }, [teamId]);

  /**
   * Remove Scenario
   */
  const handleRemoveScenario = async (id, name) => {
    const response = await show(
      <FormattedMessage id="Global.Confirmation" />,
      <FormattedMessage id="useTermSheetScenario.RemovePrompt" values={{ name: <b>{name}</b> }} />,
      {
        showCancel: true,
        showSave: false,
        showSaveAsDestructable: true,
        primaryButtonMessageId: "Global.Remove",
        size: "sm",
      }
    );

    if (response) {
      removeScenario({
        variables: {
          input: { id },
        },
        onCompleted: () => {
          toast.success(<FormattedMessage id={"useTermSheetScenario.RemoveSuccess"} />);
          return response;
        },
        onError: () => {
          toast.error(<FormattedMessage id={"useTermSheetScenario.RemoveError"} />);
        },
      });
    }
  };

  /**
   * Update Scenario
   */
  const handleUpdateScenario = (id, payload) => {
    updateScenario({
      variables: {
        input: {
          id,
          params: {
            payload,
          },
        },
      },
      onCompleted: () => {
        // toast.success(<FormattedMessage id={"useTermSheetScenario.UpdateSuccess"} />);
      },
      onError: () => {
        toast.error(<FormattedMessage id={"useTermSheetScenario.UpdateError"} />);
      },
    });
  };

  return {
    loading,
    data: data && data[keyName]?.nodes,
    error,
    variables,
    saving,
    handleRemoveScenario,
    handleUpdateScenario,
  };
}
