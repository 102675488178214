import { gql } from "@apollo/client";

const GET_ACTIVE_DAYS_FOR_TEAM = gql`
  query activeDaysForTeam($teamId: ID!, $filters: CodeTelemetryFilter) {
    activeDaysForTeam(teamId: $teamId, filters: $filters) {
      label
      value
      profile {
        avatarUrl
        firstName
        lastName
        uuid
      }
    }
  }
`;

const GET_COMMITS_PER_ACTIVE_DAY_FOR_TEAM = gql`
  query commitsPerActiveDayForTeam($teamId: ID!, $filters: CodeTelemetryFilter) {
    commitsPerActiveDayForTeam(teamId: $teamId, filters: $filters) {
      label
      value
      profile {
        avatarUrl
        firstName
        lastName
        uuid
      }
    }
  }
`;

const GET_COMMITS_FOR_TEAM = gql`
  query commitsForTeam($teamId: ID!, $filters: CodeTelemetryFilter) {
    commitsForTeam(teamId: $teamId, filters: $filters) {
      label
      value
      profile {
        avatarUrl
        firstName
        lastName
        uuid
      }
    }
  }
`;

const GET_PULL_REQUESTS_MERGED_FOR_TEAM = gql`
  query pullRequestsMergedForTeam($teamId: ID!, $filters: CodeTelemetryFilter) {
    pullRequestsMergedForTeam(teamId: $teamId, filters: $filters) {
      label
      value
      profile {
        avatarUrl
        firstName
        lastName
        uuid
      }
    }
  }
`;

const GET_LARGEST_PULL_REQUESTS_FOR_TEAM = gql`
  query largestPullRequestsForTeam($teamId: ID!, $filters: PullRequestInfoFilter) {
    largestPullRequestsForTeam(teamId: $teamId, filters: $filters) {
      label
      value
    }
  }
`;

const GET_OLDEST_OPEN_PULL_REQUESTS_FOR_TEAM = gql`
  query oldestOpenPullRequestsForTeam($teamId: ID!, $filters: PullRequestInfoFilter) {
    oldestOpenPullRequestsForTeam(teamId: $teamId, filters: $filters) {
      label
      value
      valueLabel
    }
  }
`;

const GET_COMMITS_BREAKDOWN_FOR_TEAM = gql`
  query commitsBreakdownForTeam($teamId: ID!, $filters: CodeTelemetryFilter) {
    commitsBreakdownForTeamMembers(teamId: $teamId, filters: $filters) {
      metadata {
        handle
        profile {
          id
          avatarUrl
          firstName
          lastName
          uuid
        }
      }
      data
    }
  }
`;

const GET_PULL_REQUEST_STATUS_BREAKDOWN_FOR_TEAM = gql`
  query pullRequestStatusBreakdownForTeam($teamId: ID!, $filters: CodeTelemetryFilter) {
    pullRequestStatusBreakdownForTeam(teamId: $teamId, filters: $filters) {
      metadata {
        handle
        profile {
          id
          avatarUrl
          firstName
          lastName
          uuid
        }
      }
      data
    }
  }
`;

const GET_PULL_REQUEST_MERGE_TIME_FOR_TEAM = gql`
  query pullRequestMergeTimeForTeam($teamId: ID!, $filters: CodeTelemetryFilter) {
    pullRequestMergeTimeForTeam(teamId: $teamId, filters: $filters) {
      metadata {
        handle
        profile {
          id
          avatarUrl
          firstName
          lastName
          uuid
        }
      }
      data
    }
  }
`;

const GET_PULL_REQUEST_SIZE_BREAKDOWN_FOR_TEAM = gql`
  query pullRequestSizeBreakdownForTeam($teamId: ID!, $filters: CodeTelemetryFilter) {
    pullRequestSizeBreakdownForTeam(teamId: $teamId, filters: $filters) {
      metadata {
        handle
        profile {
          id
          avatarUrl
          firstName
          lastName
          uuid
        }
      }
      data
    }
  }
`;

const GET_CUMULATIVE_COMMENTS_FOR_TEAM = gql`
  query cumulativeCommentsForTeam($teamId: ID!, $filters: CodeTelemetryFilter) {
    cumulativeCommentsForTeam(teamId: $teamId, filters: $filters) {
      metadata {
        handle
        profile {
          id
          avatarUrl
          firstName
          lastName
          uuid
        }
      }
      data
    }
  }
`;

const GET_CUMULATIVE_LOCS_FOR_TEAM = gql`
  query cumulativeLocsForTeam($teamId: ID!, $filters: CodeTelemetryFilter) {
    cumulativeLocsForTeam(teamId: $teamId, filters: $filters) {
      metadata {
        handle
        profile {
          id
          avatarUrl
          firstName
          lastName
          uuid
        }
      }
      data
    }
  }
`;

const GET_CUMULATIVE_PULL_REQUEST_STATUSES_FOR_TEAM = gql`
  query cumulativePullRequestStatusesForTeam($teamId: ID!, $filters: CodeTelemetryFilter) {
    cumulativePullRequestStatusesForTeam(teamId: $teamId, filters: $filters) {
      metadata {
        handle
        profile {
          id
          avatarUrl
          firstName
          lastName
          uuid
        }
      }
      data
    }
  }
`;

const GET_AVERAGE_WEEKLY_PR_MERGE_TIME_FOR_TEAM = gql`
  query averageWeeklyPrMergeTimeForTeam($teamId: ID!, $filters: CodeTelemetryFilter) {
    averageWeeklyPrMergeTimeForTeam(teamId: $teamId, filters: $filters) {
      metadata {
        handle
        profile {
          id
          avatarUrl
          firstName
          lastName
          uuid
        }
      }
      data
    }
  }
`;

const GET_AVERAGE_WEEKLY_PR_SIZE_FOR_TEAM = gql`
  query averageWeeklyPrSizeForTeam($teamId: ID!, $filters: CodeTelemetryFilter) {
    averageWeeklyPrSizeForTeam(teamId: $teamId, filters: $filters) {
      metadata {
        handle
        profile {
          id
          avatarUrl
          firstName
          lastName
          uuid
        }
      }
      data
    }
  }
`;

export {
  GET_ACTIVE_DAYS_FOR_TEAM,
  GET_COMMITS_PER_ACTIVE_DAY_FOR_TEAM,
  GET_COMMITS_FOR_TEAM,
  GET_PULL_REQUESTS_MERGED_FOR_TEAM,
  GET_LARGEST_PULL_REQUESTS_FOR_TEAM,
  GET_OLDEST_OPEN_PULL_REQUESTS_FOR_TEAM,
  GET_COMMITS_BREAKDOWN_FOR_TEAM,
  GET_PULL_REQUEST_STATUS_BREAKDOWN_FOR_TEAM,
  GET_PULL_REQUEST_MERGE_TIME_FOR_TEAM,
  GET_PULL_REQUEST_SIZE_BREAKDOWN_FOR_TEAM,
  GET_CUMULATIVE_COMMENTS_FOR_TEAM,
  GET_CUMULATIVE_LOCS_FOR_TEAM,
  GET_CUMULATIVE_PULL_REQUEST_STATUSES_FOR_TEAM,
  GET_AVERAGE_WEEKLY_PR_MERGE_TIME_FOR_TEAM,
  GET_AVERAGE_WEEKLY_PR_SIZE_FOR_TEAM,
};
