import React, { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { chart_colors, colors } from "style";
import { initializeAmCharts } from "utils";
import { Spinner } from "components/Loader";
import { MessagesError, MessagesNoResults } from "components/Messages";

/**
 * HorizontalBar
 *
 * @param {Array}   data
 * @param {Boolean} loading
 * @param {String}  error
 * @param {String}  height
 */
const HorizontalBar = ({ data, error, loading, height, ...props }) => {
  const [rootId] = useState(`horizontal-bar-${Math.random()}`);

  useLayoutEffect(() => {
    if (!data?.data || loading) return;

    initializeAmCharts();

    let root = am5.Root.new(rootId);
    let customTheme = am5.Theme.new(root);

    customTheme.rule("AxisLabel", ["minor"]).setAll({
      fill: am5.color(0xff0000),
      fontSize: 11,
    });

    customTheme.rule("Label").setAll({
      fontFamily: "Matter",
      fill: am5.color(colors.grayAnatomyBase),
      fontSize: 11,
      paddingTop: 10,
    });

    root.setThemes([am5themes_Animated.new(root), customTheme]);

    // create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        wheelX: "none",
        wheelY: "none",
        panX: false,
        panY: false,
        layout: root.verticalLayout,
        paddingRight: 10,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 5,
      })
    );

    chart.zoomOutButton.set("forceHidden", true);

    chart.children.unshift(
      am5.Label.new(root, {
        visible: false,
      })
    );

    var yRenderer = am5xy.AxisRendererY.new(root, {
      inversed: true,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minorGridEnabled: true,
    });

    yRenderer.grid.template.set("visible", false);

    // create axes
    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "name",
        visible: false,
        renderer: yRenderer,
      })
    );

    yAxis.data.setAll(data?.data);

    var xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0,
      minGridDistance: 50,
    });

    xRenderer.grid.template.set("strokeDasharray", [3]);

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer,
        min: 0,
      })
    );

    function createSeries(field, name) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: field,
          valueYField: "label",
          categoryYField: "name",
          sequencedInterpolation: true,
          tooltip: am5.Tooltip.new(root, {
            autoTextColor: true,
            labelHTML: `<div style="line-height:normal; white-space: wrap; max-width:200px; color:#000; font-size: 1.2rem;">{tooltip} <span style="font-weight: 600;">{valueY}</span></div>`,
          }),
        })
      );

      series.columns.template.setAll({
        fillOpacity: 1.0,
        strokeWidth: 0,
        strokeOpacity: 0,
        cornerRadiusTR: 5,
        cornerRadiusBR: 5,
        height: 30,
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 0,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerX: am5.p0,
            centerY: am5.p50,
            autoTextColor: true,
            fill: am5.color(0x000000),
            text: `{name}`,
            textAlign: "left",
            populateText: true,
          }),
        });
      });

      series.columns.template.adapters.add(
        "fill",
        (fill, target) => chart_colors.sequential_light[series.columns.indexOf(target)]
      );
      series.data.setAll(data?.data);
      series.appear();

      return series;
    }

    createSeries(data?.seriesField, data?.seriesName);

    // add cursor
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );

    cursor.lineY.set("forceHidden", true);
    cursor.lineX.set("forceHidden", true);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data, loading, rootId]);

  return (
    <ChartContainer>
      <ChartCanvas
        id={rootId}
        minHeight={height}
        height={data?.data?.length * 40 + 20}
        shouldCenter={loading || (!loading && !data?.data)}
        {...props}
      >
        {loading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : !data?.data && !error ? (
          <MessagesNoResults title="Squads.NoActivityTitle" description="Squads.NoActivityDescription" />
        ) : error ? (
          <MessagesError />
        ) : null}
      </ChartCanvas>
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  width: 100%;
  padding: 0;
  overflow: visible;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1.5rem;
  margin-left: -1.5rem;
`;

const ChartCanvas = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  min-height: ${(props) => props.minHeight};
  margin-left: -5px;
  overflow: visible;
  ${(props) =>
    props.shouldCenter &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

HorizontalBar.defaultProps = {
  height: `27rem`,
};

HorizontalBar.propTypes = {
  data: PropTypes.array.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool,
  height: PropTypes.string,
};

export default HorizontalBar;
