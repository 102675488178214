import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { colors } from "style";

/**
 * ExpandIcon
 *
 * @param {Booleam}   isExpanded
 * @param {String}    color
 */
const ExpandIcon = ({ isExpanded, color, size, ...props }) => (
  <div css={styles.container(isExpanded, color)} {...props}>
    <Icon type={ICON_TYPE.expanded} size={size} color={"inherit"} />
  </div>
);

const styles = {
  container: (isExpanded, color) => css`
    width: 2rem;
    height: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    cursor: pointer;
    color: ${color || colors.grayAnatomyLight1};
    user-select: none;

    &:hover {
      color: ${color || colors.purpleRainBase};
    }

    ${!isExpanded &&
    `
      transform: rotate(-90deg);
    `}

    ${isExpanded &&
    `
      color: ${color || colors.purpleRainBase};
    `}
  `,
};

ExpandIcon.defaultProps = {
  size: ICON_SIZE.small,
};

ExpandIcon.propTypes = {
  isExpanded: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
};

export default ExpandIcon;
