import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { formatCurrency } from "utils";
import { TAG_COLORS, TAG_SIZE } from "components/Tags/Tag";
import { Tag } from "components/Tags";

/**
 * RateTag
 *
 * @param {Object}  price
 * @param {String}  currencyDisplay
 * @param {Boolean} singleCurrencyDisplay
 * @param {Object}  tagColor
 */
const RateTag = ({ price, currencyDisplay, singleCurrencyDisplay, tagColor, ...props }) => (
  <NoWrapTag color={tagColor} size={TAG_SIZE.small} {...props}>
    {price?.hourly?.low && price?.hourly?.high ? (
      <FormattedMessage
        id="Teams.TeamSummary.AverageHourlyRate"
        values={{
          rate: (
            <FormattedMessage
              id="Teams.TeamSummary.AverageMonthlyPrice"
              values={{
                min: formatCurrency(price.hourly.low, price.currency, currencyDisplay, true),
                max:
                  singleCurrencyDisplay && currencyDisplay === "code"
                    ? Math.round(price.hourly.high)
                    : formatCurrency(price.hourly.high, price.currency, currencyDisplay, true),
              }}
            />
          ),
        }}
      />
    ) : (
      <FormattedMessage id="Teams.TeamSummary.PriceNotSet" />
    )}
  </NoWrapTag>
);

const NoWrapTag = styled(Tag)`
  white-space: nowrap;
  padding: 0.4rem 0.8rem;
  line-height: 1.33;
`;

RateTag.defaultProps = {
  currencyDisplay: "symbol",
  singleCurrencyDisplay: false,
  tagColor: TAG_COLORS.purple,
};

RateTag.propTypes = {
  price: PropTypes.object,
  currencyDisplay: PropTypes.string,
  singleCurrencyDisplay: PropTypes.bool,
  tagColor: PropTypes.object,
};

export default RateTag;
