const MEMBER_STATUS = {
  sourced: "SOURCED",
  assessing: "ASSESSING",
  reviewing: "REVIEWING",
  rejected: "REJECTED",
  active: "ACTIVE",
  archived: "ARCHIVED",
  inactive: "INACTIVE",
};

export default MEMBER_STATUS;
