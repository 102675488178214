const CODE_CHALLENGE_TYPES = {
  ajax: "Ajax",
  android: "Android",
  angular: "Angular",
  apple: "Apple",
  aws: "AWS",
  azure: "Azure",
  bootstrap: "Bootstrap",
  csharp: "C#",
  css: "CSS",
  cypress: "Cypress",
  data: "Data",
  dotnet: ".net",
  flux: "Flux",
  flutter: "Flutter",
  graphql: "GraphQL",
  go: "Go",
  heroku: "Heroku",
  ios: "iOS",
  java: "Java",
  javascript: "JavaScript",
  jenkins: "Jenkins",
  kubernetes: "Kubernetes",
  linux: "Linux",
  mysql: "MySQL",
  nest: "Nest",
  node: "Node",
  pearl: "Pearl",
  php: "PHP",
  postman: "Postman",
  python: "Python",
  qa: "QA",
  react: "React",
  rubyonrails: "Ruby on Rails",
  selenium: "Selenium",
  sql: "SQL",
  swift: "Swift",
  typescript: "TypeScript",
  vue: "Vue",
  artificialintelligence: "Artificial Intelligence",
  datascience: "Data Science",
};

export default CODE_CHALLENGE_TYPES;
