import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { Form } from "react-bootstrap";
import { colors, gradients } from "style";

/**
 * Switch
 */
const Switch = ({ ...props }) => {
  return <Form.Check css={styles.switch} {...props} />;
};

const focusStyle = css`
  box-shadow: none;
  border-image-source: ${gradients.missionCircularGradient};
  border-width: 2px;
  border-image-slice: 1;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-color: transparent !important;
`;

const styles = {
  switch: css`
    cursor: pointer;

    &.form-switch {
      position: relative;
      padding-left: 0;
      display: flex;
      align-items: center;
      gap: 2rem;

      .form-check-input {
        width: 4rem;
        height: 2.4rem;
        border: 1px solid ${colors.grayAnatomyLight3};
        background-image: none;
        margin: 0;
        border-radius: 1.2rem;
        order: 1;
        background: ${colors.grayAnatomyLight4};

        &:hover {
          border-color: ${colors.purpleRainLight4};
        }

        &:focus {
          ${focusStyle}
          background-image: linear-gradient(to bottom, #fff, #fff), ${gradients.missionCircularGradient};
        }

        &:checked {
          background: ${colors.purpleRainBase};
          background-image: none;

          &:focus {
            ${focusStyle}
            background-image: linear-gradient(to bottom, ${colors.purpleRainBase}, ${colors.purpleRainBase}), ${gradients.missionCircularGradient} !important;
          }

          &:before {
            left: 1.8rem;
          }
        }

        &:disabled {
          background: ${colors.grayAnatomyLight4};
          opacity: 1;

          &:before {
            background: ${colors.grayAnatomyLight2};
            box-shadow: none;
          }
        }

        &:before {
          content: "";
          width: 1.6rem;
          height: 1.6rem;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
          background: ${colors.white};
          position: absolute;
          border-radius: 100%;
          left: 0.4rem;
          top: 0;
          bottom: 0;
          margin: auto;
          transition: left 0.3s ease-in-out;
        }
      }

      label {
        order: 0;
      }
    }
  `,
};

const SwitchInputWithOnChange = ({ onChange, ...props }) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.name, e.target.checked);
    }
  };

  return <Switch {...props} onChange={handleChange} />;
};

SwitchInputWithOnChange.propTypes = {
  onChange: PropTypes.func,
};

export { Switch };

export default SwitchInputWithOnChange;
