import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { CREATE_NEW_LIST } from "graphql/mutations";
import { MODAL_SIZE, ModalForm } from "components/Modal";
import { useList } from "hooks";
import { METHOD, SIZE, TYPE } from "components/Form";
import { GET_LIST } from "graphql/queries";
import { PAGES, LIST_TYPES } from "constants/index";

/**
 * Profile Archive Form
 *
 * @params {String}   profileId
 * @params {Boolean}  show
 * @params {Function} onClose
 *
 */
const ListsAddForm = ({ profileId, show, onClose }) => {
  const [data, setData] = useState({});
  const [variables] = useState({ search: "", filters: { profileId }, includeProfiles: false });
  const [submitting, setSubmitting] = useState(false);
  const { data: listData, loading, refetch, addToList } = useList({ profileId, variables });

  const handleSubmit = async (formData) => {
    setSubmitting(true);
    const { list } = formData;
    const ids = list?.map(({ id }) => id);
    await addToList(ids);
    onClose(false);
    setSubmitting(false);
  };

  useEffect(() => {
    setData({ list: listData });
  }, [listData]);

  return (
    <ModalForm
      show={show}
      size={MODAL_SIZE.medium}
      onClose={() => onClose(false)}
      data={FORM_DATA(loading, refetch, setData)}
      title={<FormattedMessage id="List.AddToList" />}
      onSubmit={handleSubmit}
      method={METHOD.post}
      loading={loading || submitting}
      onChange={(data) => setData(data)}
      isFlex={false}
      initialValues={data}
      page={PAGES.addToList}
    />
  );
};

const FORM_DATA = (loading, refetch, setData) => [
  {
    style: css``,
    items: [
      {
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "list",
          placeholder: "List name...",
          queryName: GET_LIST,
          queryKeyName: "list",
          queryVariables: { includeProfiles: false },
          autoFocus: true,
          loading,
          required: true,
          labelKey: "title",
          valueKey: "id",
          minLength: 1,
          searchQuery: handleSearch,
          descriptionKey: "description",
        },
      },
      {
        type: TYPE.createNewInstance,
        properties: {
          name: "createNewList",
          queryName: CREATE_NEW_LIST,
          queryKeyName: "newList",
          labelKey: "Profile.CreateNewList",
          fields: [
            {
              name: "type",
              type: TYPE.select,
              size: SIZE.medium,
              properties: {
                required: true,
                options: [
                  { label: "General", value: LIST_TYPES.general },
                  { label: "Featured Team", value: LIST_TYPES.featuredTeam },
                  { label: "Favorite", value: LIST_TYPES.favorite },
                ],
              },
            },
            {
              name: "title",
              type: TYPE.input,
              size: SIZE.medium,
              properties: {
                placeholder: "Type name for list...",
                autoFocus: true,
                required: true,
              },
            },
          ],
          refetch: () => {},
          loading,
          required: false,
          onComplete: (list) => setData((prev) => ({ list: [...prev.list, list?.createNewList?.list] })),
        },
      },
    ],
  },
];

const handleSearch = (search) => ({ filters: { search } });

ListsAddForm.propTypes = {
  profileId: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ListsAddForm;
