import React from "react";
import PropTypes from "prop-types";
import { ChangesText, KeyValueText } from "components/ActivityTooltip";

/**
 * PullRequestActivityTooltip
 *
 * @params {Object}      tooltipData
 */
const PullRequestActivityTooltip = ({ tooltipData }) => {
  const {
    isMembersView,
    title,
    repository,
    diffAdditions,
    diffDeletions,
    diffTotalChanges,
    totalDiffAdditions,
    totalDiffDeletions,
    totalDiffTotalChanges,
    count,
    titles,
    repositories,
    users,
  } = tooltipData;

  return isMembersView ? (
    <>
      <div>{title}</div>
      <div>
        <ChangesText additions={diffAdditions} deletions={diffDeletions} changes={diffTotalChanges} />
      </div>
      <div>
        <KeyValueText id="Repository" value={repository} />
      </div>
    </>
  ) : (
    <>
      <div>
        <KeyValueText id="Count" value={count} />
      </div>
      <div>
        <ChangesText additions={totalDiffAdditions} deletions={totalDiffDeletions} changes={totalDiffTotalChanges} />
      </div>
      <div>
        <ul>
          {titles.map((title) => (
            <li key={title}>{title}</li>
          ))}
        </ul>
      </div>
      <div>
        <KeyValueText id="Repositories" value={repositories.join(", ")} />
      </div>
      <div>
        <KeyValueText id="Users" value={users.join(", ")} />
      </div>
    </>
  );
};

PullRequestActivityTooltip.propTypes = {
  tooltipData: PropTypes.object,
};

export default PullRequestActivityTooltip;
