import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { useAuth, useToast } from "hooks";
import { UPDATE_USER } from "graphql/mutations";
import { USER_TYPES, LINKS } from "constants";
import { ICON_TYPE } from "components/Icon";
import { USER_TRANSITIONS } from "constants/index";

export default function useAccessUsers() {
  const { toast } = useToast();
  const me = useAuth();
  const keyName = "platformAccessUsers";
  const [updateUser, { loading: updating }] = useMutation(UPDATE_USER);
  const [selectedUser, setSelectedUser] = useState({});
  const [showForm, setShowForm] = useState(false);

  /**
   * @description Handle the form update
   *
   * @param {String}  id
   * @param {Object}  params
   * @param {Boolean} sendResetPasswordEmail
   * @param {String}  messageId
   */
  const handleUpdateUser = (id, params, sendResetPasswordEmail = false, messageId = "Edit") => {
    updateUser({
      variables: {
        input: {
          id,
          sendResetPasswordEmail,
          params,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id={`useAccessUsers.${messageId}Success`} />),
      onError: () => toast.error(<FormattedMessage id={`useAccessUsers.${messageId}Error`} />),
    });
  };

  /**
   * @description Get the advanced options for each row based on user type
   *
   * @param {Object} row
   */
  const getAdvancedOptions = (row) => {
    const { id, uuid, name, deactivated, signInCount, selectedTab, role } = row;
    const showcaseUserType = USER_TYPES.showcase.toLowerCase();
    const signInCountIsZero = signInCount === 0;

    const EDIT_USER_OPTION = {
      label: <FormattedMessage id={"Settings.Users.UserRowAdvancedOptions.Edit"} />,
      icon: ICON_TYPE.edit,
      onClick: () => {
        setSelectedUser({ ...row, type: row?.role });
        setShowForm(true);
      },
      disabled: id === me?.id,
    };

    const ACTIVATE_OPTION = {
      label: (
        <FormattedMessage id={`Settings.Users.UserRowAdvancedOptions.${deactivated ? `Activate` : `Deactivate`}`} />
      ),
      icon: deactivated ? ICON_TYPE.checkDotted : ICON_TYPE.close,
      onClick: () => handleUpdateUser(id, { deactivated: !deactivated }),
      disabled: id === me?.id,
    };

    const RE_SEND_OPTION = {
      label: <FormattedMessage id={"Settings.Users.UserRowAdvancedOptions.Resend"} />,
      icon: ICON_TYPE.checkDotted,
      onClick: () => handleUpdateUser(id, {}, true, "Resend"),
    };

    const BROWSE_AS_OPTION = {
      label: <FormattedMessage id={"Settings.Users.UserRowAdvancedOptions.BrowseAs"} values={{ name: name }} />,
      icon: ICON_TYPE.user,
      onClick: () => window.open(LINKS.start_impersonating(id), "_self"),
      disabled: deactivated,
    };

    const MAGIC_LINK_OPTION = {
      label: <FormattedMessage id="Settings.Users.UserRowAdvancedOptions.ShowcaseLink" />,
      icon: ICON_TYPE.copy,
      onClick: async () => {
        const link = `${window.location.origin}${LINKS.showcase_magic_link(uuid)}`;

        await window.navigator.clipboard.writeText(link);
        toast.success(<FormattedMessage id="Missions.MissionPublicLink.LinkCopied" />);
      },
      disabled: deactivated,
    };

    const CONVERT_TO_CLIENT_OPTION = {
      label: <FormattedMessage id="Settings.Users.UserRowAdvancedOptions.ConvertToClient" />,
      icon: ICON_TYPE.user,
      onClick: () => {
        setSelectedUser({ ...row, type: USER_TRANSITIONS.showcaseToClient });
        setShowForm(true);
      },
    };

    let options = [ACTIVATE_OPTION, BROWSE_AS_OPTION, EDIT_USER_OPTION];

    if ([selectedTab, role].includes(showcaseUserType)) {
      options.push(...[MAGIC_LINK_OPTION, CONVERT_TO_CLIENT_OPTION]);
    } else if (signInCountIsZero) {
      options.push(RE_SEND_OPTION);
    }

    return options;
  };

  return {
    selectedUser,
    showForm,
    setShowForm,
    handleUpdateUser,
    getAdvancedOptions,
    loading: updating,
    keyName,
  };
}
