/** @jsx jsx */
import styled from "@emotion/styled";
import { colors, fontSize } from "style";

/**
 * SubTitle
 */
const SubTitle = styled.span`
  font-size: ${fontSize.xsmall};
  line-height: 1;
  margin-left: 0.8rem;
  color: ${colors.grayAnatomyLight1};
`;

export default SubTitle;
