import { useQueryData } from "hooks";
import { GET_MISSION_ROLE_APPLICANT, GET_MISSION_ROLE_APPLICANTS_COUNT } from "graphql/queries";
import { CODE_CHALLENGE_STATUS } from "constants/index";

export default function useMissionRoleApplicant({ id }) {
  const { loading, data, error } = useQueryData({
    queryName: GET_MISSION_ROLE_APPLICANT,
    skip: !id,
    variables: {
      id,
      profileChallengesStatus: CODE_CHALLENGE_STATUS.completed,
    },
  });

  const {
    loading: loadingApplicants,
    data: dataApplicants,
    error: errorApplicants,
  } = useQueryData({
    queryName: GET_MISSION_ROLE_APPLICANTS_COUNT,
    skip: !data?.missionRoleApplicant?.profile?.id,
    variables: {
      filters: {
        profileId: data?.missionRoleApplicant?.profile?.id,
      },
    },
  });

  return {
    loading,
    data: data?.missionRoleApplicant,
    list: {
      loading: loadingApplicants,
      data: dataApplicants,
      error: errorApplicants,
    },
    error,
  };
}
