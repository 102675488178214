import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { Col, Row } from "components/Containers";
import { colors, fontSize } from "style";
import { UserPhoto, UserFullName, UserLocation, UserArchetype, UserBadges } from "components/User";
import { useAuth } from "hooks";

/**
 * UserTableCell
 *
 * @param {Object}   data
 * @param {Boolean}  disableLink
 * @param {Function} onClick
 */
const UserTableCell = ({ data, disableLink, onClick }) => {
  if (!data) return null;

  const { isAdmin } = useAuth();

  return (
    <Row>
      <UserPhoto width="4rem" height="4rem" borderRadius="100%" data={data} />
      <Col gap="0.15rem">
        <Row>
          <span css={styles.username}>
            <UserFullName data={data} disableLink={disableLink} onClick={onClick} />
          </span>
          <UserLocation data={data} abbreviate />
        </Row>
        <span css={styles.archetype}>
          <UserArchetype data={data} />
        </span>
        {isAdmin && (
          <div css={styles.badge_container}>
            <UserBadges badges={data?.badges} size="1.75rem" limit={6} />
          </div>
        )}
      </Col>
    </Row>
  );
};

const styles = {
  username: css`
    font-size: ${fontSize.small};
    font-weight: 600;
  `,
  archetype: css`
    font-size: ${fontSize.xsmall};
    color: ${colors.graysAnatomyBase};
  `,
  badge_container: css`
    margin-top: 0.5rem;
  `,
};

UserTableCell.propTypes = {
  data: PropTypes.object.isRequired,
  disableLink: PropTypes.bool,
  onClick: PropTypes.func,
};

export default UserTableCell;
