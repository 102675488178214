import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import Modal from "components/Modal";
import { LINKS } from "constants/index";
import { TextInput } from "components/Form";
import { PrimaryButton } from "components/Buttons";
import { MODAL_SIZE } from "components/Modal";
import { useToast } from "hooks";

/**
 * Misssion Public Link
 *
 * @params {Boolean}  show
 * @params {Function} onClose
 * @params {Object}   data
 */
const MisssionPublicLink = ({ show, onClose, data }) => {
  const { toast } = useToast();
  const link = `${window.location.origin}${LINKS.mission_url(data?.uuid)}`;

  const handleClick = async () => {
    await window.navigator.clipboard.writeText(link);
    onClose();
    toast.success(<FormattedMessage id="Missions.MissionPublicLink.LinkCopied" />);
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
      showSave={false}
      showCancel={true}
      secondaryButtonMessageId="Global.Close"
      size={MODAL_SIZE.medium}
      title={<FormattedMessage id="Missions.MissionPublicLink.ModalHeader" />}
    >
      <div css={styles.container}>
        <TextInput type="text" value={link} css={styles.text_input_radius}/>
        <PrimaryButton onClick={handleClick} css={styles.button_radius}>
          <FormattedMessage id="Missions.MissionPublicLink.Copy" />
        </PrimaryButton>
      </div>
    </Modal>
  )
};

MisssionPublicLink.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
};

const styles = {
  container: css`
    display: flex;
    flex-direction: row-reverse;
  `,
  button_radius: css`
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  `,
  text_input_radius: css`
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  `
};

export default MisssionPublicLink;
