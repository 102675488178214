import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { colors } from "style";

/**
 * TermSheetMemberRemoveButton
 *
 * @param {Boolean} isRemoved
 */
const TermSheetMemberRemoveButton = ({ isRemoved, ...props }) => <DeleteIcon isRemoved={isRemoved} {...props} />;

const DeleteIcon = styled.div`
  background: ${({ isRemoved }) => (isRemoved ? colors.red : colors.grayAnatomyLight1)};
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${colors.red};
    transform: scale(1.1);
  }

  &:after {
    content: " ";
    height: 2px;
    width: 1.25rem;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 0.35rem;
    background: #fff;
    transition: all 0.3s ease;
  }
`;

TermSheetMemberRemoveButton.propTypes = {
  isRemoved: PropTypes.bool,
};

export default TermSheetMemberRemoveButton;
