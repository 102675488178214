import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { SignupComplete, ClientDetailForm, CompanyDetailForm } from "components/ClientSignup";
import { useTracking, useSignupDetails, useLogout, useAuth } from "hooks";
import { TRACKING_SECTIONS, TRACKING_PAGE_VIEWS } from "constants/index";
import { colors, fontSize, margins } from "style";
import { Row } from "components/Containers";

/**
 * SignupForms
 * @params {String}   role
 * @params {String}   titleId
 * @params {Boolean}  showSteps
 */
const SignupForms = ({ role, titleId, showSteps }) => {
  const { isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const [showcaseId, setShowcaseId] = useState(null);
  const [formData, setFormData] = useState({});
  const [step, setStep] = useState(1);
  const [signupCompleted, setSignupCompleted] = useState(false);
  const handleFormChange = (payload) => setFormData({ ...formData, ...payload });
  const { trackPage, trackablePage, identifyEvent } = useTracking();
  const { data, loading } = useSignupDetails({ showcaseId });
  const { handleLogout } = useLogout({ messageId: false });

  useEffect(() => {
    const value = searchParams.get("showcase_id");

    if (value) {
      setShowcaseId(value);
    }
  }, [searchParams]);

  useEffect(() => {
    if (TRACKING_SECTIONS[step] && trackablePage(TRACKING_SECTIONS[step])) {
      trackPage(TRACKING_PAGE_VIEWS[TRACKING_SECTIONS[step]].page_name, TRACKING_PAGE_VIEWS[TRACKING_SECTIONS[step]]);
    }
  }, [step]);

  useEffect(() => {
    if (data) {
      // To remove __typename from the query
      const { __typename, ...cleanData } = data;
      setFormData({ ...formData, ...cleanData }) && __typename;
    }
  }, [data]);

  if (signupCompleted) {
    return <SignupComplete formData={formData} />;
  }

  function getForm() {
    let { organization = {} } = formData;
    if (loading) return;

    switch (step) {
      default:
        return (
          <CompanyDetailForm
            values={{ organization, role }}
            loading={loading}
            onChange={handleFormChange}
            onSubmit={(payload) => {
              handleFormChange(payload);
              setStep(2);
            }}
          />
        );

      case 2:
        return (
          <ClientDetailForm
            isPreloaded={!!showcaseId}
            loading={loading}
            values={formData}
            onChange={handleFormChange}
            onCompleted={(res) => {
              identifyEvent(res?.userRegister?.profile);
              isAuthenticated && handleLogout();
              setSignupCompleted(true);
            }}
            onCancel={() => {
              setStep(1);
            }}
          />
        );
    }
  }

  return (
    <>
      <Row css={styles.container}>
        <span css={styles.title}>
          <FormattedMessage id={titleId} />
        </span>
        {showSteps && (
          <span css={styles.counter}>
            <FormattedMessage id="Signup.StepCounter" values={{ step, total: 2 }} />
          </span>
        )}
      </Row>
      {!loading && getForm()}
    </>
  );
};

const styles = {
  container: css`
    justify-content: space-between;
    margin-bottom: 1.5rem;
  `,
  title: css`
    color: ${colors.black};
    font-size: ${fontSize.xxlarge};
    font-weight: 700;
  `,
  counter: css`
    font-size: ${fontSize.normal};
    color: ${colors.grayAnatomyLight1};
    font-weight: 300;
  `,
  subtitle: css`
    font-size: ${fontSize.normal};
    color: ${colors.grayAnatomyLight1};
    font-weight: 500;
    margin-bottom: ${margins.normal};
  `,
  already_member: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyLight1};
    margin: ${margins.normal} auto 0 auto;
  `,
};

SignupForms.propTypes = {
  role: PropTypes.string,
  titleId: PropTypes.string,
  showSteps: PropTypes.bool,
};

SignupForms.defaultProps = {
  role: "client",
  titleId: "Signup.SignupForms.Title",
  showSteps: true,
};

export default SignupForms;
