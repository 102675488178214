import React from "react";
import styled from "@emotion/styled";
import { breakpoints } from "style";
import { useScrollTop } from "hooks";

const PAGE_WRAPPER_SIZE = `3rem`;
const PAGE_WRAPPER_MAX_WIDTH = `144rem`;

/**
 * PageWrapper
 */
const Container = (props) => {
  useScrollTop();

  return <PageWrapper className="page-wrapper" {...props} />;
};

/**
 * Page Wrapper
 */
const PageWrapper = styled.div`
  margin: 0rem auto;
  margin-bottom: 5rem;
  padding-left: ${PAGE_WRAPPER_SIZE};
  padding-right: ${PAGE_WRAPPER_SIZE};
  max-width: ${PAGE_WRAPPER_MAX_WIDTH};
  width: 100%;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0 2rem;
    margin: 2rem auto;
  }
`;

PageWrapper.defaultProps = {
  center: true,
};

export { PAGE_WRAPPER_SIZE, PAGE_WRAPPER_MAX_WIDTH };

export default Container;
