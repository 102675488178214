import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { css } from "@emotion/react";
import { NarrowContainer } from "components/Containers";
import { H1 } from "components/Text";
import { fontSize, colors } from "style";
import Card from "components/Card";
import { PrivacyPolicyContent } from "components/Legal";

/**
 * Signup
 */
const Signup = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Legal.Privacy.Title" })}</title>
      </Helmet>
      <NarrowContainer>
        <Card css={styles.container}>
          <H1>Privacy Policy</H1>
          <PrivacyPolicyContent />
        </Card>
      </NarrowContainer>
    </>
  );
};

const styles = {
  container: css`
    font-size: ${fontSize.normal};
    line-height: 1.5;
    padding: 1rem 3rem;

    h1,
    h2 {
      margin-left: 0;
      margin-bottom: 1rem;
      margin-top: 2rem;
    }

    p {
      margin-bottom: 1rem;
    }

    table {
      margin: 3rem 0;
      border: 1px ${colors.grayAnatomyLight3} solid;
      line-height: normal;

      td {
        border-left: 1px ${colors.grayAnatomyLight3} solid;
      }

      thead {
        td,
        th {
          top: 0;
          background: ${colors.grayAnatomyLight5};
        }
      }
    }

    b {
      font-weight: 600;
    }
  `,
  small: css`
    color: ${colors.grayAnatomyLight1};
    font-size: ${fontSize.small};
  `,
};

export default Signup;
