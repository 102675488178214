import React from "react";
import PropTypes from "prop-types";
import DropdownButton from "react-bootstrap/DropdownButton";

/**
 * DropDownToggle
 *
 * @params {Array}  options
 */
const Button = ({ children, ...props }) => <DropdownButton {...props}>{children}</DropdownButton>;

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
