import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { SIZE, TYPE } from "components/Form";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { MUTATION_TYPE } from "hooks";
import { ADD_TEAM } from "graphql/mutations";
import { GET_ME, GET_TEAMS } from "graphql/queries";

/**
 * SquadFormAsClient
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Function}  onComplete
 * @param {String}    organizationId
 */
const SquadFormAsClient = ({ show, onClose, onComplete, organizationId, ...props }) => {
  const formProps = {
    initialValues: {
      organizationId,
    },

    mutationType: MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: ADD_TEAM,
      },
      refetchAfterMutate: true,
      refetchQueries: [GET_TEAMS, GET_ME],
    },
  };

  return (
    <ModalForm
      title={<FormattedMessage id={`Squads.SquadFormAsClient.Title`} />}
      description={<FormattedMessage id={`Squads.SquadFormAsClient.Description`} />}
      primaryButtonMessageId="Squads.SquadFormAsClient.Save"
      data={FORM_DATA}
      show={show}
      size={MODAL_SIZE.small}
      onClose={onClose}
      onCompleted={onComplete}
      closeOnComplete={false}
      {...formProps}
      {...props}
    />
  );
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        type: TYPE.hidden,
        properties: {
          name: "organizationId",
        },
      },
      {
        label: "Team Name",
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "name",
          type: "text",
          placeholder: "Enter name here...",
          maxLength: 100,
          required: true,
          autoFocus: true,
        },
      },
    ],
  },
];

SquadFormAsClient.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  organizationId: PropTypes.string,
};

export default SquadFormAsClient;
