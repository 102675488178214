import { gql } from "@apollo/client";

const CREATE_NEW_LIST = gql`
  mutation CreateNewList($input: CreateNewListInput!) {
    createNewList(input: $input) {
      list {
        id
        title
      }
    }
  }
`;

export { CREATE_NEW_LIST };
