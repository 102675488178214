import React from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ICON_TYPE } from "components/Icon";
import { LINKS } from "constants/index";
import { useAuth, useLogout } from "hooks";
import { SideNavLink } from "components/Nav/SideNav";

/**
 * MenuItemLogout
 */
const MenuItemLogout = () => {
  const { isShowcase } = useAuth();
  const { handleLogout } = useLogout();
  const navigate = useNavigate();

  return (
    <SideNavLink onClick={() => handleLogout(() => navigate(LINKS.login))} type={ICON_TYPE.logout}>
      <FormattedMessage id={`Global.${isShowcase ? "EndSession" : "Logout"}`} />
    </SideNavLink>
  );
};

export default MenuItemLogout;
