import { gql } from "@apollo/client";

const GET_CLIENT = gql`
  query ShowcaseUserFetch($uuid: String!) {
    showcaseUserFetch(uuid: $uuid) {
      email
      firstName
      lastName
      organization {
        industries {
          id
          name
        }
        location
        noOfEmployees
        noOfEngineers
      }
    }
  }
`;

export { GET_CLIENT };
