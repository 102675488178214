import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { startCase } from "lodash";
import { PAGINATION_TYPE } from "components/Pagination";
import { GET_MISSIONS } from "graphql/queries";
import { UPDATE_MISSION } from "graphql/mutations";
import { useQueryData, useToast } from "hooks";
import { MISSIONS_RESULTS_PER_PAGE } from "constants/index";
import { useModalConfirmation } from "hooks";
import { MODAL_SIZE } from "components/Modal";
import { Row, Col } from "components/Containers";
import Icon, { ICON_TYPE } from "components/Icon";
import { colors } from "style";

export default function useMissions({
  resultsPerPage = MISSIONS_RESULTS_PER_PAGE,
  published = null,
  orderBy = {
    organizationName: "asc",
  },
  readUrlPagination = false,
  paginationType = PAGINATION_TYPE.paginate,
  filters = {},
  additionalVariables = {},
  skip = false,
}) {
  const { toast } = useToast();
  const [storedTotalCount, setStoredTotalCount] = useState(0);
  const isCursorPagination = paginationType !== PAGINATION_TYPE.classic;

  /**
   * Defaults used for fetching
   */
  const defaultVariables = (isPublished) => ({
    orderBy,
    filters: {
      published: isPublished,
      ...filters,
    },
    ...additionalVariables,
  });

  /**
   * Refetch array for all missions, unpublished, published
   */
  const refetchQueries = [
    { query: GET_MISSIONS, variables: defaultVariables(null) },
    { query: GET_MISSIONS, variables: defaultVariables(false) },
    { query: GET_MISSIONS, variables: defaultVariables(true) },
  ];

  const {
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    handlePageChange,
    loading,
    loadingMore,
    data,
    error,
  } = useQueryData({
    queryName: GET_MISSIONS,
    keyName: "missions",
    resultsPerPage,
    paginationType,
    variables: defaultVariables(published),
    isCursorPagination,
    readUrlPagination,
    skip,
  });

  const [handlePublish, { loading: updating }] = useMutation(UPDATE_MISSION, { refetchQueries });

  const { show } = useModalConfirmation();

  const handlePublishUnpublish = ({ id, publish, setIsPublished }) => {
    handlePublish({
      variables: {
        input: {
          id,
          params: {
            publish,
          },
        },
      },
      onCompleted: (res) => {
        let result = res?.updateMission?.mission?.published;
        toast.success(
          <FormattedMessage id={`Missions.MissionPublishButton.Message${result ? `Published` : `Unpublished`}`} />
        );
      },
      onError: async (errors) => {
        setIsPublished(!publish);
        await show(
          <FormattedMessage id={"Missions.MissionPublishButton.MessageError"} />,
          <>
            <p>
              <FormattedMessage id="Missions.MissionPublishButton.DetailedErrorDescription" />
            </p>
            <p>
              <b>
                <FormattedMessage id="Missions.MissionPublishButton.DetailedErrorDescriptionIssuesCopy" />
              </b>
            </p>
            <Col gap="0.5rem">
              {errors.graphQLErrors.map((error, index) => (
                <Row key={index} noWrap>
                  <Icon type={ICON_TYPE.unavailable} color={colors.red} />
                  {error.extensions.errors.map((detailedError, childIndex) => (
                    <span key={childIndex}>
                      {startCase(detailedError.field)} {detailedError.message}
                    </span>
                  ))}
                </Row>
              ))}
            </Col>
          </>,
          {
            showCancel: true,
            showSave: false,
            secondaryButtonMessageId: "Global.Close",
            size: MODAL_SIZE.small,
          }
        );
      },
    });
  };

  const handleAssessmentDraftedApproved = ({ id, assessmentDrafted }) => {
    handlePublish({
      variables: {
        input: {
          id,
          params: {
            assessmentDrafted,
          },
        },
      },
      onCompleted: (res) => {
        let result = res?.updateMission?.mission?.assessmentDrafted;
        toast.success(
          <FormattedMessage id={`Missions.AssessmentDraftButton.Message${result ? `Drafted` : `Published`}`} />
        );
      },
      onError: () => {
        toast.error(<FormattedMessage id={"Missions.AssessmentDraftButton.MessageError"} />);
      },
    });
  };

  const updatedTotalCount = data?.missions?.totalCount;

  useEffect(() => {
    if (typeof updatedTotalCount !== "undefined") {
      setStoredTotalCount(updatedTotalCount);
    }
  }, [updatedTotalCount]);

  return {
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePageChange,
    handlePreviousPage,
    handlePublishUnpublish,
    handleAssessmentDraftedApproved,
    updating,
    loading,
    loadingMore,
    data: data?.missions?.nodes,
    pageInfo: data?.missions?.pageInfo,
    totalCount: storedTotalCount,
    currentPage: data?.missions?.currentPage,
    resultsPerPage: data?.missions?.perPage,
    error,
    refetchQueries,
  };
}
