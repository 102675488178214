import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import TextInputWithOnChange from "./TextInput";

/**
 * Slider
 *
 * @param {String}   name
 * @param {Number}   value
 * @param {Number}   min
 * @param {Number}   max
 * @param {Number}   step
 * @param {Function} onChange
 */
const Slider = ({ name, value, min, max, step, onChange, ...props }) => {
  const { hasError, formData, ...rest } = props;
  const [localValue, setLocalValue] = useState(value);
  const handleChange = (e) => setLocalValue(e.target.value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div css={styles.slider}>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={localValue}
        onChange={handleChange}
        onMouseUp={() => onChange(name, localValue)}
        onTouchEnd={() => onChange(name, localValue)}
        css={styles.html_range}
        {...rest}
      />
      <div css={styles.range_value}>
        <TextInputWithOnChange
          value={localValue}
          onChange={(n, val) => {
            onChange(name, val);
            setLocalValue(val);
          }}
          css={styles.input}
        />
      </div>
    </div>
  );
};

const styles = {
  slider: css`
    position: relative;
    order: 1;
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
  range_value: css``,
  html_range: css`
    -webkit-appearance: none !important;
    width: 100%;
    height: 2px;
    background: ${colors.grayAnatomyLight3};
    border: none;
    outline: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none !important;
      width: 1rem;
      height: 1rem;
      background: ${colors.purpleRainBase};
      border: 2px solid ${colors.purpleRainBase};
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background: ${colors.purpleRainDark1};
      }
    }
  `,
  input: css`
    height: 3rem;
    padding: 0 0.5rem;
    width: 6rem;
    font-size: ${fontSize.xsmall};
  `,
};

Slider.defaultProps = {
  step: 1,
};

Slider.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default Slider;
