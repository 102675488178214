import React from "react";
import propTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { CONNECTION_STATES } from "constants/index";
import { Row } from "components/Containers";
import { Refresher } from "components/Loader";

/**
 * SignalStatus
 *
 * @param {object} data
 * @param {object} githubApplicationConnection
 * @param {boolean} loading
 */
const SignalStatus = ({ data, githubApplicationConnection, loading }) => {
  const reposSelected = data?.gitRepositories?.length > 0 && data?.gitRepositories?.split(",")?.length > 0;
  const isConnected =
    githubApplicationConnection?.status === CONNECTION_STATES.active && githubApplicationConnection?.enabled;

  if (!isConnected || loading) return null;

  return (
    <Row>
      <Refresher refreshing={reposSelected} />
      <span>
        <FormattedMessage
          id={`Squads.Settings.SignalStatus`}
          values={{
            type: (
              <b>
                <FormattedMessage id={`Squads.Settings.SignalStatus.${reposSelected ? `Connected` : `NoRepos`}`} />
              </b>
            ),
          }}
        />
      </span>
    </Row>
  );
};

SignalStatus.propTypes = {
  data: propTypes.object.isRequired,
  githubApplicationConnection: propTypes.object,
  loading: propTypes.bool,
};

export default SignalStatus;
