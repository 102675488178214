import React, { Suspense, lazy } from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useSquadDynamics } from "hooks";
import { colors } from "style";
import DataTable from "components/DataTable";
import { Grid } from "components/Containers";
import Card from "components/Card";
import { FeedbackCell } from "components/Squads/Pulse";

// TODO - Using conventional import like above causes an error and the app to crash.
// Lazy loading the ProfileLink component fixes the issue. Investigate why this is happening. - Asad
const ProfileLink = lazy(() => import("components/Profile/ProfileLink"));

/**
 * SquadDynamicsTable
 *
 * @param {String}    teamId
 * @param {Date}      date
 */
const SquadDynamicsTable = ({ teamId, date, ...props }) => {
  const { data, loading, error, questionText } = useSquadDynamics({
    teamId,
    date,
  });
  const teamMembers = data
    .reduce((arr, a) => Array.from(new Set([...arr, ...Object.keys(a).filter((b) => b !== "profile")])), [])
    .sort();

  return (
    <Card>
      <Grid>
        <Grid.col start={1} end={13}>
          <DataTable
            css={styles.table}
            data={teamMembers.length ? data : []}
            error={error}
            loading={loading}
            columns={COLUMN_DATA(teamMembers)}
            {...props}
          />
          {questionText && (
            <div css={styles.disclaimer}>
              <FormattedMessage id="SquadDynamicsTable.Disclaimer.Text" values={{ question: questionText }} />
            </div>
          )}
        </Grid.col>
      </Grid>
    </Card>
  );
};

const COLUMN_DATA = (teamMembers) => [
  {
    label: <FormattedMessage id="SquadDynamicsTable.Header.MemberName" />,
    cell: ({ profile }) => (
      <Suspense fallback={<div />}>
        <ProfileLink profile={profile} withOverlay />
      </Suspense>
    ),
    width: "12%",
  },
  ...teamMembers.map((member) => ({
    label: (
      <div css={styles.headerDynamicCell}>
        <FormattedMessage id="SquadDynamicsTable.Header.Placeholder" values={{ placeholder: member }} />
      </div>
    ),
    cell: (data) => (
      <div css={styles.headerDynamicCell}>{data[member] ? <FeedbackCell data={data[member]} /> : "-"}</div>
    ),
    width: `${Math.ceil(88 / teamMembers.length)}%`,
  })),
];

const styles = {
  table: css`
    td,
    td:last-of-type {
      border: 0;
      padding: 0rem;
      height: 4rem;
    }
  `,
  disclaimer: css`
    font-style: italic;
    margin-top: 2rem;
    color: ${colors.grayAnatomyBase};
  `,
  headerDynamicCell: css`
    text-align: center;
  `,
};

SquadDynamicsTable.propTypes = {
  teamId: PropTypes.string,
  date: PropTypes.date,
};

export default SquadDynamicsTable;
