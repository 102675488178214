import { gql } from "@apollo/client";

const GET_INTERESTS = gql`
  query interests($filters: TagFilter) {
    interests(filters: $filters) {
      nodes {
        name
        id
        uuid
        description
      }
    }
  }
`;

export { GET_INTERESTS };
