import { SET_PREFERENCES } from "graphql/mutations";

const MOCK_SET_PREFERENCES = [
  {
    request: {
      query: SET_PREFERENCES,
      variables: {
        input: {
          id: "1",
          params: {
            preferences: {
              homepage_tour_visible: true,
            },
          },
        },
      },
    },
    result: {
      data: {
        setUserPreferences: {
          user: {
            id: "1",
            preferences: {
              homepage_tour_visible: false,
              mission_board_tour_visible: false,
            },
            __typename: "User",
          },
          __typename: "SetUserPreferencesPayload",
        },
      },
    },
  },
];

export { MOCK_SET_PREFERENCES };
