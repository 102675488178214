import React from "react";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import { MUTATION_TYPE, useToast } from "hooks";
import { UPDATE_LIST, GET_USERS } from "graphql/queries";
import { LIST_TYPES, USER_TYPES } from "constants/index";
import { MODAL_SIZE, ModalForm } from "components/Modal";
import { SIZE, TYPE } from "components/Form";

/**
 * EditListModal
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Object}    data
 * @param {Boolean}   loading
 * @param {Boolean}   isEditing
 */
const EditListModal = ({ show, onClose, data, loading, isEditing, ...props }) => {
  const { toast } = useToast();

  if (isEditing && loading) return null;

  const handleComplete = () => {
    onClose();
    toast.success(<FormattedMessage id={`Lists.EditListModal.EditSuccess`} />);
  };

  const handleError = () => {
    onClose();
    toast.error(<FormattedMessage id={`Lists.EditListModal.EditError`} />);
  };

  const formProps = {
    initialValues: {
      title: data?.title,
      userId: data?.user?.id,
    },
    updateId: data?.id,
    mutationType: MUTATION_TYPE.update,
    mutationData: {
      mutationName: {
        update: UPDATE_LIST,
      },
    },
    ...props,
  };

  return (
    <ModalForm
      data={FORM_DATA({ canUpdateUser: data?.type === LIST_TYPES.favorite })}
      show={show}
      onClose={onClose}
      onCompleted={handleComplete}
      onError={handleError}
      size={MODAL_SIZE.small}
      title={<FormattedMessage id="Lists.EditListModal.Title" />}
      {...formProps}
    />
  );
};

const FORM_DATA = ({ canUpdateUser }) => {
  const defaultData = [
    {
      items: [
        {
          label: "Title",
          type: TYPE.input,
          size: SIZE.xlarge,
          properties: {
            name: "title",
            type: "text",
            required: true,
            autoFocus: true,
          },
        },
      ],
    },
  ];

  if (canUpdateUser) {
    defaultData[0].items.push({
      label: "User",
      type: TYPE.select,
      size: SIZE.xlarge,
      properties: {
        name: "userId",
        required: true,
        isClearable: false,
        queryName: GET_USERS,
        queryKeyName: "users",
        queryFieldName: "users",
        queryVariables: { filters: { roles: [USER_TYPES.showcase, USER_TYPES.client] }, first: 50 },
        isSearchable: true,
        querySearchFieldName: "search",
        noOptionsMessage: "No showcase or client user found",
        placeholder: "Search by name...",
        labelKey: "name",
        valueKey: "id",
      },
    });
  }

  return defaultData;
};

EditListModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  loading: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default EditListModal;
