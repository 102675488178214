import React from "react";
import { useParams } from "react-router-dom";
import Card from "components/Card";
import { Grid, PageWrapper } from "components/Containers";
import AddBillingButton from "components/Organizations/AddBillingButton";
import AddContactButton from "components/Organizations/AddContactButton";
import OrganizationBasicProfile from "components/Organizations/OrganizationBasicProfile";
import OrganizationBilling from "components/Organizations/OrganizationBilling";
import OrganizationContact from "components/Organizations/OrganizationContact";
import PageTitle from "components/PageTitle";
import { SquadsHighlight } from "components/Squads";
import { PrimaryLink } from "components/Links";
import { useAuth, useOrganization } from "hooks";
import { FormattedMessage } from "react-intl";
import { OrganizationPhoto } from "components/Organization";
import { SectionTitle } from "components/Layouts";
import { LINKS } from "constants";
import { MissionsHighlights } from "components/Missions";

const OrganizationProfile = () => {
  const { id } = useParams();
  const { data, loading } = useOrganization({ id, includeMissions: true });
  const { isAdmin } = useAuth();

  return (
    <PageWrapper>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            backLink={
              isAdmin && (
                <PrimaryLink to={LINKS.organizations} routerEnabled>
                  <FormattedMessage id="Organizations.H1" />
                </PrimaryLink>
              )
            }
            title={data?.name}
          />
        </Grid.col>
        <Grid.col start={1} end={9}>
          <Grid>
            <Grid.col start={1} end={13}>
              <Card>
                <OrganizationBasicProfile />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <SectionTitle
                title={<FormattedMessage id="Organization.OrganizationContact.Title" />}
                actionBar={<AddContactButton isEditing={false} data={{ organizationId: data?.id }} />}
              />
            </Grid.col>
            <Grid.col start={1} end={13}>
              <OrganizationContact />
            </Grid.col>
            <Grid.col start={1} end={13}>
              <SectionTitle
                title={<FormattedMessage id="Organizations.OrganizationBillingInformation.Title" />}
                actionBar={<AddBillingButton isEditing={false} organizationId={data?.id} />}
              />
            </Grid.col>
            <Grid.col start={1} end={13}>
              <OrganizationBilling />
            </Grid.col>
          </Grid>
        </Grid.col>
        <Grid.col start={9} end={13}>
          <Grid>
            <Grid.col start={1} end={13}>
              <Card>
                <OrganizationPhoto
                  id={data?.id}
                  isEditable={true}
                  url={data?.logoUrl}
                  name={data?.name}
                  loading={loading}
                  width={`18rem`}
                  height={`18rem`}
                />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                <SquadsHighlight
                  organizationId={id}
                  title={<FormattedMessage id="Squads.SquadsHighlight.Title" />}
                  emptyMsgId={"Squads.SquadsHighlight.OrgEmptyMsg"}
                />
              </Card>
            </Grid.col>
            {isAdmin && (
              <Grid.col start={1} end={13}>
                <Card isAdmin>
                  <MissionsHighlights
                    data={data?.missions}
                    loading={loading}
                    emptyMsgId={"Missions.MissionsHighlight.MissionsEmptyMsg"}
                  />
                </Card>
              </Grid.col>
            )}
          </Grid>
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default OrganizationProfile;
