import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { MUTATION_TYPE, useToast } from "hooks";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { UPDATE_USER } from "graphql/mutations";
import { USER_FORM_CONFIGS, USER_TRANSITIONS, USER_TYPES } from "constants/index";

/**
 * User Update Form
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Object}    userData
 * @param {Function}  refetch
 */
const UserUpdateForm = ({ show, onClose, userData, refetch }) => {
  const { toast } = useToast();

  const initialValues = {
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    email: userData?.email,
    organizationId: userData?.organization?.id,
    partnerId: userData?.linkedPartner?.id,
    ...(userData?.type === USER_TRANSITIONS.showcaseToClient && { role: USER_TYPES.client }),
  };

  const handleComplete = () => {
    refetch();
    onClose();
    toast.success(<FormattedMessage id="Settings.Users.UserEditForm.Success" />);
  };

  const handleError = () => {
    toast.error(<FormattedMessage id="Settings.Users.UserEditForm.Error" />);
  };

  return (
    <ModalForm
      title={<FormattedMessage id="Settings.Users.UserEditForm.Title" values={{ type: userData.role }} />}
      data={FORM_DATA(userData?.type, initialValues)}
      initialValues={initialValues}
      updateId={userData?.id}
      mutationType={MUTATION_TYPE.update}
      mutationData={{
        mutationName: {
          update: UPDATE_USER,
        },
      }}
      show={show}
      size={MODAL_SIZE.medium}
      onClose={onClose}
      onCompleted={handleComplete}
      onError={handleError}
      {...userData}
    />
  );
};

const FORM_DATA = (userType, data) => [
  {
    style: css`
      width: 100%;
    `,
    items: USER_FORM_CONFIGS[userType]?.(data) || [],
  },
];

UserUpdateForm.defaultProps = {
  show: false,
  onClose: () => {},
  userData: {},
  refetch: () => {},
};

UserUpdateForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  userData: PropTypes.object,
  refetch: PropTypes.func,
};

export default UserUpdateForm;
