import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { validatePassword } from "utils";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { colors, fontSize } from "style";

/**
 * Password rules
 *
 * @params {String}   password
 */
const PasswordRules = ({ password }) => {
  const passwordResult = validatePassword(password);

  return (
    <div css={styles.container}>
      <div css={styles.title}>Password Requirements</div>
      <div css={styles.line_item}>
        <div>
          <div css={styles.icon_container(passwordResult.hasLowerCase)}>
            <Icon type={ICON_TYPE.checkmark} size={ICON_SIZE.small} color={colors.white} />
          </div>
        </div>
        <div css={styles.label(passwordResult.hasLowerCase)}>A lowercase letter</div>
      </div>
      <div css={styles.line_item}>
        <div>
          <div css={styles.icon_container(passwordResult.hasUpperCase)}>
            <Icon type={ICON_TYPE.checkmark} size={ICON_SIZE.small} color={colors.white} />
          </div>
        </div>
        <div css={styles.label(passwordResult.hasUpperCase)}>An UPPERCASE letter</div>
      </div>
      <div css={styles.line_item}>
        <div>
          <div css={styles.icon_container(passwordResult.hasNumberOrSpecial)}>
            <Icon type={ICON_TYPE.checkmark} size={ICON_SIZE.small} color={colors.white} />
          </div>
        </div>
        <div css={styles.label(passwordResult.hasNumberOrSpecial)}>A number or symbol</div>
      </div>
      <div css={styles.line_item}>
        <div>
          <div css={styles.icon_container(passwordResult.hasMinLength)}>
            <Icon type={ICON_TYPE.checkmark} size={ICON_SIZE.small} color={colors.white} />
          </div>
        </div>
        <div css={styles.label(passwordResult.hasMinLength)}>Minimum 8 characters</div>
      </div>
    </div>
  );
};

const styles = {
  title: css`
    font-size: ${fontSize.xsmall};
    font-weight: 600;
    margin-bottom: 1rem;
  `,
  line_item: css`
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
  `,
  icon_container: (isValid) => css`
    width: 1.5rem;
    height: 1.5rem;
    background: ${isValid ? colors.green : colors.grayAnatomyLight2};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  label: (isValid) => css`
    margin-left: 0.5rem;
    font-size: ${fontSize.xsmall};
    color: ${isValid ? "inherit" : colors.grayAnatomyLight1};
    font-weight: 500;
  `,
};

PasswordRules.propTypes = {
  password: PropTypes.string,
};

export default PasswordRules;
