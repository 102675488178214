import React from "react";
import PropTypes from "prop-types";
import { AdvancedButton } from "components/Buttons";
import { RoleForm } from "components/Missions/Roles";
import { useMissionRoles, useAuth } from "hooks";

/**
 * RoleAdvancedOptions
 *
 * @param {Object}  data
 */
const RoleAdvancedOptions = ({ data }) => {
  const { isAdmin } = useAuth();
  const { showForm, setShowForm, getAdvancedOptions } = useMissionRoles({ skipQuery: true });

  if (!isAdmin) return null;

  return (
    <>
      <AdvancedButton options={getAdvancedOptions(data)} />
      <RoleForm show={showForm} onClose={() => setShowForm(false)} isEditing={true} data={data} />
    </>
  );
};

RoleAdvancedOptions.propTypes = {
  data: PropTypes.object,
};

export default RoleAdvancedOptions;
