import React, { useState, useRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { breakpoints, colors, fontSize, gradients, margins } from "style";
import Card from "components/Card";
import Icon, { ICON_TYPE, ICON_SIZE, CloseIcon } from "components/Icon";
import { TRACKING_EVENTS, useAuth, useTracking } from "hooks";
import { Header } from "components/DisplayBox";
import { H3 } from "components/Text";
import { WhiteLink } from "components/Links";

/**
 * Tutorials
 *
 * @param {Boolean}   isCarousel
 * @param {Function}  keyName
 * @param {Array}     videoList
 * @param {String}    source
 * @param {String}    text
 * @param {Boolean}   hideOnClose
 * @param {Function}  handleToggle
 * @param {Object}    videoProps
 * @param {Boolean}   isCloseable
 */
const Tutorials = ({
  isCarousel,
  keyName,
  videoList,
  source,
  text,
  hideOnClose,
  handleToggle,
  videoProps,
  isCloseable,
}) => {
  const { preferences, id, updatePreferences } = useAuth();
  const { trackEvent } = useTracking();
  const [open, setOpen] = useState(preferences?.[keyName] === false ? false : true);
  const [selectedVideo, setVideo] = useState({ ...videoList[0], index: 0 });
  const handleSelectVideo = (video) => setVideo(video);
  const videoRef = useRef();

  const toggleWindow = () => {
    updatePreferences({ keyName, keyValue: !open });
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(preferences?.[keyName] === false ? false : true);
  }, [preferences]);

  useEffect(() => {
    handleToggle(open);
  }, [open]);

  const handleVideoEvent = (event, selectedVideo) =>
    trackEvent(event, {
      scope: source,
      scope_resource_id: id,
      name: selectedVideo?.name,
    });

  useEffect(() => {
    if (selectedVideo?.index) {
      videoRef.current?.load();
      // In Chrome version 50 play method started to return promise
      // It raises exception if not being handled as promise while changing videos
      let playPromise = videoRef.current?.play();

      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          console.log("Failed to play", error);
        });
      }
    }
  }, [selectedVideo]);

  const playNext = () => {
    if (isCarousel && selectedVideo.index < videoList.length - 1) {
      const idx = selectedVideo.index + 1;

      setVideo({ ...videoList[idx], index: idx });
    } else {
      setOpen(false);
    }
  };

  if (!open && hideOnClose) return null;

  if (!open) {
    return (
      <MinimizedBox>
        <Icon type={ICON_TYPE.lightBulb} color="#fff" size={ICON_SIZE.large} />
        <FormattedMessage id={text.minimizedTitle} />
        <WhiteLink onClick={toggleWindow} withMoreIcon>
          <FormattedMessage id={text.minimizedCTA} />
        </WhiteLink>
      </MinimizedBox>
    );
  }

  return (
    <>
      {text.title && (
        <StyledHeader marginSize={margins.normal} noUnderline>
          <H3>
            <FormattedMessage id={text.title} />
          </H3>
        </StyledHeader>
      )}
      <StyledCard>
        <VideoContainer>
          <Video
            ref={videoRef}
            controls={true}
            poster={selectedVideo?.cover}
            onEnded={() => {
              handleVideoEvent(TRACKING_EVENTS.videoEnded, selectedVideo);
              playNext();
            }}
            onPlay={() => handleVideoEvent(TRACKING_EVENTS.videoOnPlay, selectedVideo)}
            {...videoProps}
          >
            <source type="video/mp4" src={selectedVideo?.source} />
          </Video>
        </VideoContainer>
        {isCarousel && (
          <Carousel>
            {videoList.map((video, index) => (
              <CarouselItem
                key={video.source}
                onClick={() => handleSelectVideo({ ...video, index })}
                className={video.source === selectedVideo?.source && `selected`}
              >
                <img src={video.cover} />
                <div>{video.name}</div>
                <div>{video.duration}</div>
              </CarouselItem>
            ))}
          </Carousel>
        )}
        {isCloseable && <CloseIcon onClick={toggleWindow} />}
      </StyledCard>
      {(text.bodyTitle || text.bodyDescription) && (
        <StyledFooter marginSize={margins.normal}>
          {text.bodyTitle && (
            <FooterHeader>
              <FormattedMessage id={text.bodyTitle} />
            </FooterHeader>
          )}
          {text.bodyDescription && (
            <FooterDescription>
              <FormattedMessage id={text.bodyDescription} />
            </FooterDescription>
          )}
        </StyledFooter>
      )}
    </>
  );
};

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  ${(props) => props.marginSize && `margin: 0 ${props.marginSize};`}
  line-height: normal;
`;

const FooterHeader = styled.div`
  font-size: ${fontSize.normal};
  margin-bottom: 0.6rem;
  font-weight: 600;
`;

const FooterDescription = styled.div`
  color: ${colors.grayAnatomyLight2};
  font-size: ${fontSize.xsmall};
`;

const StyledHeader = styled(Header)`
  margin-bottom: 0;
  margin-top: 2.4rem !important;
  ${(props) => props.marginSize && `margin: 0 ${props.marginSize};`}

  @media (max-width: ${breakpoints.tablet}) {
    margin-left: 2rem;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${colors.grayAnatomyDark};
  color: #fff;
  transition: all 0.3s ease;
  padding: 0;
  margin-top: 0;
  border: 0;
  border-radius: 1rem;
`;

const MinimizedBox = styled.div`
  background: ${gradients.orangeLinearGradient};
  padding: 1rem 1rem 1rem 1rem;
  margin-top: 2.4rem;
  font-size: ${fontSize.small};
  color: #fff;
  font-weight: 400;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  > *:last-child {
    margin-left: auto;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 56.29%;
  padding-top: 56.29%;
  position: relative;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin-top: -2px;
  position: absolute;
  top: 0;
  left: 0;
`;

const Carousel = styled.div`
  display: flex;
  padding: 2rem 4rem;
  gap: 2rem;
  background: ${colors.grayAnatomyDark};
  width: 100%;
  align-items: flex-start;
  border-radius: 0 0 1rem 1rem;

  @media (max-width: ${breakpoints.tablet}) {
    padding: 0;
    margin-top: 2rem;
    gap: 1rem;
    border-radius: 0;
    width: 100%;
    overflow-x: scroll;
  }
`;

const CarouselItem = styled.div`
  flex: 1;
  position: relative;
  font-size: ${fontSize.xsmall};
  color: ${colors.grayAnatomyLight1};
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  transition: color 0.3s ease;

  &:hover {
    img {
      border: 2px rgba(255, 255, 255, 0.5) solid;
      opacity: 0.8;
    }
  }

  &.selected {
    color: #fff;

    img {
      opacity: 1 !important;
      border: 2px ${colors.orangeCountyBase} solid !important;
    }
  }

  img {
    width: 100%;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 0.5rem;
    border: 2px transparent solid;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 17rem;

    img {
      width: auto;
    }
  }
`;

Tutorials.propTypes = {
  isCarousel: PropTypes.bool,
  keyName: PropTypes.string,
  videoList: PropTypes.array,
  source: PropTypes.string,
  text: PropTypes.object,
  hideOnClose: PropTypes.bool,
  handleToggle: PropTypes.func,
  videoProps: PropTypes.object,
  isCloseable: PropTypes.bool,
};

Tutorials.defaultProps = {
  isCarousel: false,
  videoList: [],
  handleToggle: () => {},
  videoProps: {},
  isCloseable: true,
};

export default Tutorials;
