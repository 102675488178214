import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { colors, fontSize } from "style";

/**
 * Icon Nav
 *
 * @params {Array}  items
 */
const IconNav = ({ items }) => (
  <nav css={styles.container}>
    {items?.map((item, index) => (
      <NavLink to={item.to} key={index} css={styles.list_item}>
        <div css={styles.items}>{item.label}</div>
      </NavLink>
    ))}
  </nav>
);

const styles = {
  container: css`
    background-color: ${colors.purpleRainDark2};
    flex-direction: column;
    width: 10rem;
    height: 100vh;
    display: flex;
    align-items: center;
  `,
  list_item: css`
    background: ${colors.white};
    height: 5rem;
    width: 5rem;
    margin-top: 2rem;
    border-radius: 1rem;
    color: ${colors.purpleRainDark2};

    &.active {
      background: ${colors.blueVelvetBase};
      color: ${colors.white};
    }
  `,
  items: css`
    text-align: center;
    padding-top: 1.6rem;
    font-size: ${fontSize.medium};
  `,
};

IconNav.propTypes = {
  items: PropTypes.array,
};

export default IconNav;
