import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useSquadInsights } from "hooks";
import { GradientLabel } from "components/Labels";
import Tooltip from "components/Tooltip";
import DisplayBox from "components/DisplayBox";
import { colors, fontSize, margins } from "style";
import { SquadInsightItem } from "components/Squads/Insights";
import { PrimaryLink } from "components/Links";
import { LINKS } from "constants/index";

/**
 * SquadInsights
 *
 * @param {String}  teamId
 */
const SquadInsights = ({ teamId, ...props }) => {
  const { data, loading } = useSquadInsights({ teamId: teamId, resultsPerPage: 10 });

  const isEmpty = !loading && (!data || data?.length === 0);

  return (
    <>
      <DisplayBox
        title={
          <>
            <Tooltip tooltipId="Squads.SquadInsights.Title.Tooltip">
              <FormattedMessage id="Squads.SquadInsights.Title" />
            </Tooltip>
          </>
        }
        subtitle={<GradientLabel />}
        data={data}
        itemRenderer={(insight, index, loading) => (
          <SquadInsightItem insight={insight} index={index} loading={loading} />
        )}
        loading={loading}
        numColumns={1}
        rowGap={0}
        isEmpty={isEmpty}
        emptyMsg={<FormattedMessage id="Squads.SquadInsights.EmptyMsg" />}
        marginSize={margins.normal}
        {...props}
      />
      {!loading && !isEmpty && (
        <div css={styles.link_container}>
          <PrimaryLink css={styles.link} to={LINKS.squad_insights(teamId)} routerEnabled>
            <FormattedMessage id="Squads.SquadInsights.ShowMore" />
          </PrimaryLink>
        </div>
      )}
    </>
  );
};

const styles = {
  comingSoon: css`
    margin-top: 1rem;
    font-weight: 600;
    font-size: ${fontSize.medium};
    gap: 0.5rem;
    display: flex;
    align-items: center;
  `,
  container: css`
    padding: 2rem 1rem 2rem 1rem;
    text-align: left;
    font-size: ${fontSize.xxsmall};
    flex: 1;
    border-right: 1px ${colors.grayAnatomyLight5} solid;
    margin-left: 3rem;
    &:last-child {
      border-right: none;
    }
  `,
  link_container: css`
    width: 100%;
    border: 0;
    border-top: 1px ${colors.grayAnatomyLight4} solid;
    border-radius: 0;
    border-radius: 1rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-bottom: 0;
  `,
  link: css`
    margin-left: 33%;
    margin-top: 5%;
  `,
};

SquadInsights.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export default SquadInsights;
