import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useInView } from "react-intersection-observer";
import { formatDate } from "utils";
import { TimelineEvent } from "components/Timeline";
import Card from "components/Card";
import { H3 } from "components/Text";
import { colors, fontSize } from "style";
import { useTimeline } from "hooks";
import { TIMELINE_EVENTS } from "constants/index";
import { Spinner } from "../Loader";
import { Grid } from "components/Containers";
import { Header } from "components/DisplayBox";

/**
 * TimelineList
 *
 * @param {String} id
 * @param {Object} user
 * @param {String} eventStartDate
 */
const TimelineList = ({ id, user, eventStartDate }) => {
  const { dataByDate, loading, handleNextPage, hasNextPage, loadingMore } = useTimeline({
    profileId: id,
    eventStartDate,
  });
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      handleNextPage();
    }
  }, [inView, loading, loadingMore]);

  return (
    <>
      <Grid>
        {dataByDate.map(({ date, items }) => {
          const hasChildren = items.some(({ key }) => Object.values(TIMELINE_EVENTS).includes(key));

          if (!hasChildren) return;

          return (
            <Fragment key={date}>
              <Grid.col start={1} end={13}>
                <StyledCard>
                  <Header>
                    <H3>{formatDate(date)}</H3>
                  </Header>
                  {items.map((data) => (
                    <TimelineEvent key={data.uuid} data={data} user={user} profileId={id} />
                  ))}
                </StyledCard>
              </Grid.col>
            </Fragment>
          );
        })}
        <div ref={ref} />
      </Grid>
      {(loading || loadingMore) && <Spinner margin={`1rem auto`} />}
    </>
  );
};

const StyledCard = styled(Card)`
  padding: 3rem 3rem 0 3rem;
  font-size: ${fontSize.small};
  line-height: normal;
  color: ${colors.grayAnatomyLight1};
`;

TimelineList.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object,
  eventStartDate: PropTypes.string,
};

export default TimelineList;
