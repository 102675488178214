import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "components/Buttons";
import { useAuth } from "hooks";
import { UserInviteForm } from "components/Settings/Users";
import { GET_ORGANIZATION } from "graphql/queries";

/**
 * AddContactButton
 */
const AddContactButton = (props) => {
  const [showForm, setShowForm] = useState(false);
  const { isAdmin } = useAuth();

  if (!isAdmin) return null;

  return (
    <>
      <PrimaryButton onClick={() => setShowForm(true)}>
        <FormattedMessage id={`Organization.OrganizationContact.AddContact`} />
      </PrimaryButton>
      <UserInviteForm
        show={showForm}
        onClose={() => setShowForm(false)}
        isEditing={false}
        refetchQuery={GET_ORGANIZATION}
        {...props}
      />
    </>
  );
};

export default AddContactButton;
