import React from "react";
import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useProfile } from "hooks";

/**
 * Get Profile
 */
const Profile = () => {
  const { id } = useParams();
  const { data, loading, error } = useProfile({ profileId: id });

  return <Outlet context={[id, data, loading, error]} />;
};

export default Profile;
