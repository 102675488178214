import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { colors, fontSize } from "style";
import { Row } from "components/Containers";
import { css } from "@emotion/react";
import { shortenText } from "utils";
import { PrimaryLink } from "components/Links";

/**
 * ListNote
 *
 * @param {String} note
 */
const ListNote = ({ note, ...props }) => {
  const [truncate, setTruncate] = useState(true);

  const handleClick = (event) => {
    event.stopPropagation();
    setTruncate(false);
  };

  if (!note) {
    return null;
  }

  return (
    <Row css={styles.container} gap="0.5rem" {...props}>
      <Icon type={ICON_TYPE.message} size={ICON_SIZE.large} color={colors.grayAnatomyBase} />
      <span>
        {truncate ? shortenText(note, 240) : note}
        {truncate && note.length > 240 && (
          <>
            {` `}
            <PrimaryLink onClick={handleClick}>
              <FormattedMessage id="Global.ReadMore" />
            </PrimaryLink>
          </>
        )}
      </span>
    </Row>
  );
};

const styles = {
  container: css`
    color: ${colors.grayAnatomyBase};
    flex-wrap: nowrap;
    align-items: flex-start;
    font-size: ${fontSize.xsmall};
  `,
};

ListNote.propTypes = {
  note: PropTypes.string,
};

export default ListNote;
