import moment from "moment";

/**
 * isFutureDate
 *
 * @param {String}    date
 *
 * @returns {Boolean}
 */
export default function isFutureDate(date) {
  return moment().diff(date) <= 0;
}
