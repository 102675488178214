import React from "react";
import { CLOUDFRONT_URL } from "constants/index";
import { Tutorials } from "components/Tutorials";

// Image Imports
import onboarding_slack_and_communication from "assets/images/video_covers/onboarding_slack_and_communication.jpg";
import onboarding_welcome from "assets/images/video_covers/onboarding_welcome.jpg";
import onboarding_whats_next from "assets/images/video_covers/onboarding_whats_next.jpg";
import onboarding_interview_tips from "assets/images/video_covers/onboarding_interview_tips.jpg";
import onboarding_mission_values from "assets/images/video_covers/onboarding_mission_values.jpg";
import onboarding_assigned_members from "assets/images/video_covers/onboarding_assigned_members.jpg";
import showcase_demo from "assets/images/video_covers/showcase_demo.jpg";
/**
 * WelcomeTour
 */
const WelcomeTour = () => (
  <Tutorials
    isCarousel={true}
    source="member_homepage"
    keyName="homepage_tour_visible"
    videoList={VIDEOS(CLOUDFRONT_URL)}
    text={{
      title: "Tutorials.WelcomeTour.Title",
      minimizedTitle: "Tutorials.WelcomeTour.MinimizedTitle",
      minimizedCTA: "Tutorials.WelcomeTour.MinimizedCTA",
    }}
  />
);

const VIDEOS = (cloudfrontUrl) => [
  {
    name: "Welcome",
    cover: onboarding_welcome,
    source: `${cloudfrontUrl}videos/onboarding/onboarding_welcome.m4v`,
    duration: "0:56",
  },
  {
    name: "What's Next",
    cover: onboarding_whats_next,
    source: `${cloudfrontUrl}videos/onboarding/onboarding_whats_next.m4v`,
    duration: "0:39",
  },
  {
    name: "Slack & Communication",
    cover: onboarding_slack_and_communication,
    source: `${cloudfrontUrl}videos/onboarding/onboarding_slack_and_communication.m4v`,
    duration: "3:23",
  },
  {
    name: "Mission Values",
    cover: onboarding_mission_values,
    source: `${cloudfrontUrl}videos/onboarding/onboarding_mission_values.m4v`,
    duration: "1:03",
  },
  {
    name: "Interview Tips",
    cover: onboarding_interview_tips,
    source: `${cloudfrontUrl}videos/onboarding/onboarding_interview_tips.m4v`,
    duration: "0:31",
  },
  {
    name: "Assigned Members",
    cover: onboarding_assigned_members,
    source: `${cloudfrontUrl}videos/onboarding/onboarding_assigned_members.m4v`,
    duration: "1:02",
  },
  {
    name: "Showcase Demo",
    cover: showcase_demo,
    source: `${cloudfrontUrl}videos/demos/demo_product.m4v`,
    duration: "2:08",
  },
];

export default WelcomeTour;
