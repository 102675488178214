import { gql } from "@apollo/client";
import {
  CORE_PROFILE_FIELDS,
  CANDIDATE_PROGRESS_FIELDS,
  DISCIPLINE_FIELDS,
  LANGUAGE_FIELDS,
  EDUCATION_FIELDS,
  INTERESTS_FIELDS,
  EXTERNAL_LINKS_FIELDS,
  OLD_LOCATION_FIELDS,
  RATE_FIELDS,
  SKILLS_FIELDS,
  TAGS_FIELDS,
  WORK_EXPERIENCES_FIELDS,
  WORK_SCHEDULE_FIELDS,
  VACATIONS_FIELDS,
  USER_FIELDS,
} from "graphql/fragments";

const GET_PROFILES = gql`
  ${CORE_PROFILE_FIELDS}
  ${CANDIDATE_PROGRESS_FIELDS}
  ${DISCIPLINE_FIELDS}
  ${LANGUAGE_FIELDS}
  ${EDUCATION_FIELDS}
  ${EXTERNAL_LINKS_FIELDS}
  ${OLD_LOCATION_FIELDS}
  ${RATE_FIELDS}
  ${SKILLS_FIELDS}
  ${TAGS_FIELDS}
  ${WORK_EXPERIENCES_FIELDS}
  ${WORK_SCHEDULE_FIELDS}
  ${VACATIONS_FIELDS}
  ${USER_FIELDS}
  ${INTERESTS_FIELDS}
  query profiles(
    $filters: ProfileFilter
    $includeProfileFields: Boolean = true
    $includeCandidateProgressFields: Boolean = true
    $includeDisciplineFields: Boolean = true
    $includeLanguageFields: Boolean = true
    $includeEducationFields: Boolean = true
    $includeExternalLinksFields: Boolean = true
    $includeOldLocationFields: Boolean = true
    $includeRateFields: Boolean = true
    $includeSkillsFields: Boolean = true
    $includeTagsFields: Boolean = true
    $includeWorkExperiencesFields: Boolean = true
    $includeWorkScheduleFields: Boolean = true
    $includeVacationsFields: Boolean = true
    $includeUserFields: Boolean = true
    $includeInterestsFields: Boolean = true
  ) {
    profiles(filters: $filters) {
      nodes {
        ...ProfileFields @include(if: $includeProfileFields)
        ...CandidateProgressFields @include(if: $includeCandidateProgressFields)
        ...DisciplineFields @include(if: $includeDisciplineFields)
        ...LanguageFields @include(if: $includeLanguageFields)
        ...EducationFields @include(if: $includeEducationFields)
        ...ExternalLinksFields @include(if: $includeExternalLinksFields)
        ...OldLocationFields @include(if: $includeOldLocationFields)
        ...RateFields @include(if: $includeRateFields)
        ...SkillsFields @include(if: $includeSkillsFields)
        ...TagsFields @include(if: $includeTagsFields)
        ...WorkExperiencesFields @include(if: $includeWorkExperiencesFields)
        ...WorkScheduleFields @include(if: $includeWorkScheduleFields)
        ...VacationsFields @include(if: $includeVacationsFields)
        ...UserFields @include(if: $includeUserFields)
        ...InterestsFields @include(if: $includeInterestsFields)
      }
    }
  }
`;

export { GET_PROFILES };
