import { gql } from "@apollo/client";

const GET_DAILY_REPORT_SETTINGS = gql`
  query GetDailyReportSettings($id: ID) {
    dailyReportSettings(id: $id) {
      channels {
        name
        detailedId
      }
      templates {
        uuid
        name
        welcomeMessage
        reportSchedule {
          startDate
          broadcastTime
          days
        }
        reportQuestions {
          id
          title
        }
      }
      users {
        name
        detailedId
      }
      report {
        uuid
        name
        isEnabled
        welcomeMessage
        reportSchedule {
          startDate
          broadcastTime
          days
        }
        reportQuestions {
          id
          title
        }
        reportParticipants {
          name
          detailedId
        }
        reportChannels {
          name
          detailedId
        }
      }
    }
  }
`;

export { GET_DAILY_REPORT_SETTINGS };
