import { makeVar } from "@apollo/client";
import { isNull } from "utils";

const profileCompletion = makeVar({
  bio: {
    isComplete: false,
    isEmpty: false,
    isLoading: true,
    titleMessageId: "Profile.ProfileDescription.FormTitle",
  },
  photo: {
    isComplete: false,
    isEmpty: false,
    isLoading: true,
    titleMessageId: "Profile.ProfilePic.FormTitle",
  },
  disciplines: {
    isComplete: false,
    isEmpty: false,
    isLoading: true,
    titleMessageId: "Profile.ProfileDisciplines.FormTitle",
  },
  skills: {
    isComplete: false,
    isEmpty: false,
    isLoading: true,
    titleMessageId: "Profile.ProfileSkills.FormTitle",
  },
  info: {
    isComplete: false,
    isEmpty: false,
    isLoading: true,
    titleMessageId: "Profile.ProfileContact.FormTitle",
  },
  workExperiences: {
    isComplete: false,
    isEmpty: false,
    isLoading: true,
    titleMessageId: "Profile.ProfileWorkExperience.Title",
  },
  resume: {
    isComplete: false,
    isEmpty: false,
    isLoading: true,
    titleMessageId: "Profile.ProfileWorkExperience.Resume",
  },
  education: {
    isComplete: false,
    isEmpty: false,
    isLoading: true,
    titleMessageId: "Profile.ProfileEducation.FormTitle",
  },
  rate: {
    isComplete: false,
    isEmpty: false,
    isLoading: true,
    titleMessageId: "Profile.ProfileRateAndAvailability.Title",
  },
});

const updateProfileCompletion = (d, data, settings) => {
  if (!d) return;

  profileCompletion({
    bio: {
      ...data.bio,
      isLoading: false,
      isComplete: !isNull(d.description) && !isNull(d.location?.place_id),
      isEmpty: isNull(d.description) && isNull(d.location?.place_id),
    },
    photo: {
      ...data.photo,
      isLoading: false,
      isComplete: !isNull(d.avatarUrl),
      isEmpty: isNull(d.avatarUrl),
    },
    disciplines: {
      ...data.disciplines,
      isLoading: false,
      isComplete: !isNull(d.disciplines),
      isEmpty: isNull(d.disciplines),
    },
    skills: {
      ...data.skills,
      isLoading: false,
      isComplete: !isNull(d.skills),
      isEmpty: isNull(d.skills),
    },
    info: {
      ...data.info,
      isLoading: false,
      isComplete: !isNull(d.languages),
      isEmpty: isNull(d.phoneNumber) && isNull(d.languages),
    },
    workExperiences: {
      ...data.workExperiences,
      isLoading: false,
      isComplete: !isNull(d.workExperiences),
      isEmpty: isNull(d.workExperiences),
    },
    education: {
      ...data.education,
      isLoading: false,
      isComplete: !isNull(d.education),
      isEmpty: isNull(d.education),
    },
    resume: {
      ...data.resume,
      isLoading: false,
      isComplete: !isNull(d.resumeUrl),
      isEmpty: isNull(d.resumeUrl),
    },
    rate: {
      ...data.rate,
      isLoading: false,
      isComplete:
        // If the user can view/edit rate, then all fields are required
        settings?.canViewEditRate
          ? !isNull(d?.rate?.frequency) &&
            !isNaN(d?.rate?.amount) &&
            d?.rate?.amount > 0 &&
            !isNull(d?.rate?.currency) &&
            !isNaN(d?.capacity) &&
            !isNull(d?.timezone?.value)
          : // If the user can't view/edit rate, then only capacity and timezone are required
            !isNaN(d?.capacity) && !isNull(d?.timezone?.value),
      isEmpty:
        // If the user can view/edit rate, then all fields are required
        settings?.canViewEditRate
          ? isNull(d?.rate?.frequency) &&
            isNaN(d?.rate?.amount) &&
            isNull(d?.rate?.currency) &&
            isNaN(d?.capacity) &&
            isNull(d?.timezone?.value)
          : // If the user can't view/edit rate, then only capacity and timezone are required
            isNaN(d?.capacity) && isNull(d?.timezone?.value),
    },
  });
};

export { updateProfileCompletion };

export default profileCompletion;
