import { GET_MISSION_BOARD_SUMMARY } from "graphql/queries";

const MOCK_GET_MISSION_BOARD_SUMMARY = {
  request: {
    query: GET_MISSION_BOARD_SUMMARY,
    variables: {
      orderBy: {},
      first: null,
      last: null,
      after: null,
      id: "136",
    },
  },
  result: {
    data: {
      missionBoardSummaryFetch: {
        openMissionRolesCount: 135,
        openMissionsCount: 45,
        __typename: "MissionBoardSummary",
      },
    },
  },
};

export { MOCK_GET_MISSION_BOARD_SUMMARY };
