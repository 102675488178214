import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useQueryData, useProfile } from "hooks";
import { GET_CHALLENGES } from "graphql/queries";
import { CHALLENGE_CATEGORIES } from "constants/index";
import { PAGINATION_TYPE } from "components/Pagination";
import { ADD_CHALLENGE_RESPONSE } from "graphql/mutations";

/**
 * useCandidateProfileInterview
 *
 * @params {String}   profileId
 * @params {Boolean}  isAdmin
 * @params {Number}   resultsPerPage
 */
export default function useCandidateCodeChallenge({ profileId, isAdmin, resultsPerPage = 2 }) {
  const STATUS_PASSED = "passed";
  const STATUS_FAILED = "failed";
  const [filteredData, setFilteredData] = useState([]);
  const [publicUrl, setPublicUrl] = useState();
  const [skills, setSkills] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const { data: profileData, loading: profileLoading } = useProfile({ profileId });
  const [getChallengeUrl, { data: challengeData }] = useMutation(ADD_CHALLENGE_RESPONSE);
  const { hasNextPage, handleNextPage, loading, data, refetch } = useQueryData({
    queryName: GET_CHALLENGES,
    keyName: "challenges",
    resultsPerPage,
    paginationType: PAGINATION_TYPE.append,
    variables: {
      profileId,
      filters: {
        skills: skills,
        disciplines: disciplines,
        types: CHALLENGE_CATEGORIES.vetting,
      },
    },
  });

  const unfilteredData = data?.challenges?.nodes;
  const hasChallenge = unfilteredData?.some((item) => item.challengeResponse);

  useEffect(() => {
    setSkills(profileData?.profile?.skills?.map((item) => item.name));
    setDisciplines(profileData?.profile?.disciplines?.map((item) => item.name));
  }, [profileData?.profile]);

  useEffect(() => {
    refetch({ skills, disciplines });
  }, [skills, disciplines]);

  useEffect(() => {
    setPublicUrl(challengeData?.addChallengeResponse?.challengeResponse?.testUrl);
  }, [challengeData]);

  useEffect(() => {
    if (publicUrl) {
      // eslint-disable-next-line no-undef
      window.location = publicUrl;
    }
  }, [publicUrl]);

  const handleCodeChallengeClick = (challengeId, challengeResponse) => {
    if (isAdmin) {
      // eslint-disable-next-line no-undef
      window.open(challengeResponse?.reportUrl, "_blank");
    } else if (challengeResponse?.publicUrl) {
      setPublicUrl(challengeResponse?.publicUrl);
    } else {
      getChallengeUrl({
        variables: {
          input: {
            profileId,
            params: {
              challengeId,
            },
          },
        },
      });
    }
  };

  // For Admins, only show data that has a response
  useEffect(() => {
    setFilteredData(
      isAdmin
        ? unfilteredData?.filter(
            (challenge) =>
              challenge?.challengeResponse &&
              (challenge?.challengeResponse?.status === STATUS_PASSED ||
                challenge?.challengeResponse?.status === STATUS_FAILED)
          )
        : hasChallenge
        ? unfilteredData?.filter((challenge) => challenge?.challengeResponse)
        : unfilteredData
    );
  }, [unfilteredData, isAdmin, hasChallenge]);

  return {
    hasNextPage: isAdmin ? hasNextPage : hasChallenge ? false : hasNextPage,
    handleNextPage,
    refetch,
    loading: profileLoading || loading,
    data: filteredData,
    handleCodeChallengeClick,
    STATUS_PASSED,
    STATUS_FAILED,
  };
}
