export { default as Paginate } from "./Paginate";
export { default as ShowMore } from "./ShowMore";
export { default as ClassicPaginate } from "./ClassicPaginate";
export { default as CursorPaginate } from "./CursorPaginate";

const PAGINATION_TYPE = {
  paginate: "paginate",
  append: "append",
  classic: "classic",
  cursor: "cursor",
};

export { PAGINATION_TYPE };
