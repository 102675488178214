import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import PageTitle from "components/PageTitle";
import { ClientFeedbackAddButton, ClientFeedbackList } from "components/Client";
import { PageWrapper, Grid } from "components/Containers";
import { useAuth } from "hooks";
import Card from "components/Card";

/**
 * Client Feedback Page
 */
const ClientFeedback = () => {
  const { id } = useParams();
  const { isAdmin } = useAuth();
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.ClientFeedback.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              subtitle={<FormattedMessage id="Squads.ClientFeedbackTitle" />}
              actionBar={isAdmin && <ClientFeedbackAddButton teamId={id} />}
            />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <Card>
              <ClientFeedbackList teamId={id} />
            </Card>
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default ClientFeedback;
