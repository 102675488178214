import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { PageWrapper, Grid } from "components/Containers";
import Card from "components/Card";
import PageTitle from "components/PageTitle";
import { TermSheetLayout, TermSheetEvents } from "components/TermSheets";

/**
 * TermSheet
 */
const TermSheet = () => {
  const intl = useIntl();
  const { selectedSquad: data } = useOutletContext();
  const [teamId, setTeamId] = useState(data?.id);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
  const [refetchEvents, setRefetchEvents] = useState(false);
  const [selectedViewType, setSelectedViewType] = useState(0);

  useEffect(() => {
    setTeamId(data?.id);
  }, [data]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.Squad.TermSheet.H1" })}</title>
      </Helmet>
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle subtitle={<FormattedMessage id={`Squads.Squad.TermSheet.H1`} />} />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <Card>
              <TermSheetLayout
                {...{ teamId, selectedDate, setSelectedDate, selectedViewType, setSelectedViewType, setRefetchEvents }}
              />
            </Card>
          </Grid.col>
          <Grid.col start={1} end={13}>
            <Card>
              <TermSheetEvents
                title={<FormattedMessage id={`Squads.Squad.TermSheet.TitleFutureEvents`} />}
                teamId={teamId}
                isPastEvents={false}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                refetchEvents={refetchEvents}
                setRefetchEvents={setRefetchEvents}
                canTimeTravel={selectedViewType === 0}
              />
              <TermSheetEvents
                title={<FormattedMessage id={`Squads.Squad.TermSheet.TitlePastEvents`} />}
                teamId={teamId}
                isPastEvents={true}
                refetchEvents={refetchEvents}
                setRefetchEvents={setRefetchEvents}
                canTimeTravel={false}
              />
            </Card>
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default TermSheet;
