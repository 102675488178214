import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { AdvancedButton } from "components/Buttons";
import { ICON_TYPE } from "components/Icon";
import { OrganizationBillingForm } from "components/Organizations";
import { useAuth } from "hooks";

/**
 * BillingAdvanceOption
 */
const BillingAdvanceOption = ({ ...props }) => {
  const [showForm, setShowForm] = useState(false);
  const { isAdmin } = useAuth();

  if (!isAdmin) return null;

  const editOption = [
    {
      label: <FormattedMessage id={`Organization.BillingAdvanceOption.EditBilling`} />,
      icon: ICON_TYPE.edit,
      onClick: () => setShowForm(true),
    }
  ];

  return (
    <>
      <AdvancedButton options={editOption} />
      <OrganizationBillingForm
        show={showForm}
        onClose={() => setShowForm(false)} 
        isEditing={true} 
        {...props}
      />
    </>
  );
};

export default BillingAdvanceOption;
