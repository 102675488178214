import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { colors, fontSize } from "style";
import { TableFoot, FooterTableRow, FooterTableCell } from "components/Table";

/**
 * DataTableFooter
 *
 * @param {Array}   data
 * @param {String}  marginSize
 */
const DataTableFooter = ({ data, marginSize }) => {
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <TableFoot>
      {data.map((row) => (
        <FooterTableRow key={row?.name}>
          {row?.columns?.map((footerData, i) => (
            <FooterTableCell key={i} marginSize={marginSize}>
              <>
                <div>{footerData?.label}</div>
                <FooterDetail incomplete={footerData?.incomplete}>{footerData?.detail}</FooterDetail>
              </>
            </FooterTableCell>
          ))}
        </FooterTableRow>
      ))}
    </TableFoot>
  );
};

const FooterDetail = styled.span`
  font-size: ${fontSize.xsmall};
  line-height: 1;
  color: ${(props) => (props.incomplete ? colors.red : colors.grayAnatomyLight1)};
  padding-top: 1rem;
`;

DataTableFooter.propTypes = {
  data: PropTypes.array,
  marginSize: PropTypes.string,
};

export default DataTableFooter;
