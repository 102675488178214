import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import Image from "components/Image";
import Modal from "components/Modal";
import { fontSize, colors } from "style";
import { Link } from "components/Links";

/**
 * EventDetailsModal
 *
 * @params {Boolean}  show
 * @params {Function} onClose
 * @params {Object}   data
 */
const EventDetailsModal = ({ show, onClose, data }) => (
  <Modal
    show={show}
    onClose={onClose}
    showSave={false}
    showCancel={true}
    secondaryButtonMessageId="Global.Close"
    size={"md"}
    title={data?.name}
  >
    {data && (
      <div css={styles.container}>
        <Image css={styles.event_image} url={data.displayImageUrl} alt={data.name} />
        <div>
          <div css={styles.time_stamp}>
            {
              <FormattedMessage
                id="Events.EventsDetailsModal.dateTimezone"
                values={{ date: data.startsAtWithTimezone }}
              />
            }
          </div>
          <div css={styles.event_link}>
            {data.recordingLink ? (
              <>
                <FormattedMessage id="Events.EventsDetailsModal.WatchThe" />
                <Link href={data.recordingLink} target="_black">
                  <FormattedMessage id="Events.EventsDetailsModal.recording" />
                </Link>
              </>
            ) : (
              <>
                <Link href={data.url} target="_black">
                  <FormattedMessage id="Events.EventsDetailsModal.Rsvp" />
                </Link>
              </>
            )}
          </div>
          <div css={styles.description}>{data.description}</div>
        </div>
      </div>
    )}
  </Modal>
);

EventDetailsModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
};

const styles = {
  container: css`
    display: flex;
    gap: 4rem;
  `,
  event_image: css`
    width: 12.8rem;
    height: 12.8rem;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
  `,
  time_stamp: css`
    font-size: ${fontSize.medium};
    font-weight: 500;
    line-height: 1.33;
    margin: 0.8rem 0;
  `,
  event_link: css`
    font-size: ${fontSize.small};
    font-weight: 500;
    line-height: 1.71;
    color: ${colors.grayAnatomyBase};
  `,
  description: css`
    margin-top: 2.4rem;
    font-size: ${fontSize.small};
    line-height: 1.71;
  `,
};

export default EventDetailsModal;
