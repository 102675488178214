/** @jsx jsx */
import styled from "@emotion/styled";

/**
 * Row
 */
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: ${({ align }) => align || `center`};
  gap: ${({ gap }) => gap || `1rem`};

  ${({ noWrap }) =>
    noWrap &&
    `
    flex-wrap: nowrap;
  `}
`;

export default Row;
