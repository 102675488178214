import { useEffect, useState } from "react";
import { useQueryData } from "hooks";
import { GET_ALL_TIME_ACTIVITY } from "graphql/queries";

/**
 * useAllTimeActivity
 *
 * @param {String}  teamId
 * @param {Object}  filters
 */
export default function useAllTimeActivity({ teamId, filters }) {
  const [data, setData] = useState([]);
  const keyName = "teamAllTimeActivity";

  const {
    loading,
    data: response,
    error,
  } = useQueryData({
    queryName: GET_ALL_TIME_ACTIVITY,
    keyName,
    skip: !teamId,
    variables: { teamId, filters },
  });

  useEffect(() => {
    if (response) {
      setData(response[keyName]);
    }
  }, [response]);

  return {
    loading,
    data,
    error,
  };
}
