const AVAILABILITIES = [
  {
    label: "Immediately",
    value: "IMMEDIATELY",
  },
  {
    label: "One week",
    value: "ONE_WEEK",
  },
  {
    label: "Two weeks",
    value: "TWO_WEEKS",
  },
  {
    label: "Three weeks",
    value: "THREE_WEEKS",
  },
  {
    label: "Four weeks",
    value: "FOUR_WEEKS",
  },
  {
    label: "Five weeks",
    value: "FIVE_WEEKS",
  },
];

export default AVAILABILITIES;
