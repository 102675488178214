import { COUNTRIES } from "constants/index.js";

/**
 * Returns country code by label
 *
 * @param {string} country
 *
 * @returns {string}
 */
export default function getLegacyCountryCode(country) {
  const filteredCountries = COUNTRIES.filter(
    ({ label }) => String(label).toLowerCase() === String(country).toLowerCase()
  );

  return filteredCountries && filteredCountries.length > 0 && filteredCountries[0].value;
}
