import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { PageWrapper, Grid } from "components/Containers";
import Card from "components/Card";
import { NotesInputBox, NotesList } from "components/Notes";
import { ProfileNav, ProfileSummary } from "components/Profile";
import { useAuth, useProfile } from "hooks";
import PageTitle from "components/PageTitle";

/**
 * ProfileNotes
 */
const ProfileNotes = () => {
  const { id } = useParams();
  const me = useAuth();
  const { data } = useProfile({ profileId: id });

  const resultsPerPage = 20;
  const intl = useIntl();

  return (
    <>
      <ProfileNav id={id} />
      <PageWrapper>
        <Helmet>
          <title>{intl.formatMessage({ id: "Meta.Profiles.Notes.Title" })}</title>
        </Helmet>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle subtitle={<FormattedMessage id={"Profile.Notes.Title"} />} />
          </Grid.col>
          <Grid.col start={1} end={9}>
            <Card>
              <NotesInputBox
                user={me}
                commentableId={data?.profile?.id}
                resultsPerPage={resultsPerPage}
                placeholder={<FormattedMessage id={`Profile.Notes.Placeholder`} />}
              />
            </Card>
          </Grid.col>
          <Grid.col start={9} end={13} row={2} rowSpan={100}>
            <Card>
              <ProfileSummary id={id} />
            </Card>
          </Grid.col>
          <Grid.col start={1} end={9}>
            <NotesList user={me} profileId={data?.profile?.id} resultsPerPage={resultsPerPage} />
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default ProfileNotes;
