import React from "react";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { LINKS } from "constants/index";
import { useAuth, useLogout } from "hooks";
import { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { AdvancedButton } from "components/Buttons";
import { Link } from "components/Links";
import { colors } from "style";
import { useLocation, useParams } from "react-router-dom";

/**
 * HeaderAuth
 */
const HeaderAuth = () => {
  const { isAuthenticated, isImpersonating, firstName } = useAuth();
  const { handleLogout } = useLogout();
  const navigate = useNavigate();

  const HamburgerMenu = () => (
    <div css={styles.navToggle} data-hamburger-menu>
      <span css={styles.name}>
        <FormattedMessage
          id={isImpersonating ? "Menu.Impersonating" : "Menu.Name"}
          values={{
            name: <b>{firstName}</b>,
          }}
        />
      </span>
      <AdvancedButton
        iconType={ICON_TYPE.listLines}
        iconSize={ICON_SIZE.xlarge}
        options={[
          {
            label: (
              <Link to={LINKS.candidate_home} css={styles.link} routerEnabled>
                <FormattedMessage id="Nav.Home" />
              </Link>
            ),
          },
          {
            label: (
              <Link to={LINKS.candidate_profile} css={styles.link} routerEnabled>
                <FormattedMessage id="Nav.Profile" />
              </Link>
            ),
          },
          {
            label: (
              <Link onClick={() => handleLogout(() => navigate(LINKS.login))} css={styles.link}>
                <FormattedMessage id="Global.Logout" />
              </Link>
            ),
          },
        ]}
      />
    </div>
  );

  const ButtonMenu = () => {
    const { id, missionId } = useParams();
    const { search, pathname } = useLocation();
    let rolePath = "";

    if (pathname === LINKS.public_mission_role(missionId, id)) {
      rolePath = `${search ? "&" : "?"}role=${id}`;
    }

    return (
      <div css={styles.buttons} data-button-menu>
        <a href={`${LINKS.login}?redirect=${pathname}`}>
          <SecondaryButton>
            <FormattedMessage id="Global.Login" />
          </SecondaryButton>
        </a>
        <a href={`${LINKS.signup}${search}${rolePath}`}>
          <PrimaryButton>
            <FormattedMessage id="Global.JoinMission" />
          </PrimaryButton>
        </a>
      </div>
    );
  };

  return isAuthenticated ? <HamburgerMenu /> : <ButtonMenu />;
};

const styles = {
  navToggle: css`
    display: flex;
    align-items: center;

    button {
      background-color: transparent;
      color: ${colors.white};
    }

    ul > div {
      padding: 0;
    }
  `,
  name: css`
    color: ${colors.white};
    font-family: Matter;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1;
    display: block;
    margin-bottom: -2px;
  `,
  buttons: css`
    display: flex;
    justify-content: flex-end;
    gap: 1.6rem;
  `,
  link: css`
    padding: 1.2rem 1.6rem;
    font-family: Matter;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.33;
    display: block;
    color: ${colors.purpleRainDark2};
    width: 100%;

    &:hover {
      background-color: ${colors.grayAnatomyLight5};
      color: ${colors.purpleRainDark2};
    }
  `,
};

export default HeaderAuth;
