import React from "react";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useList } from "hooks";
import { LINKS, LIST_TYPES } from "constants/index";
import { Grid, PageWrapper } from "components/Containers";
import { Banner, BannerShowcaseSignup } from "components/Banner";
import { css } from "@emotion/react";
import PageTitle from "components/PageTitle";
import { TeamCard } from "components/Teams";
import { CardList } from "components/Card";

/**
 * FeaturedTeams
 */
const FeaturedTeams = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const variables = { filters: { type: LIST_TYPES.featuredTeam } };
  const { data, loading } = useList({ variables, ignoreEmpty: true });

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.FeaturedTeams.Title" })}</title>
      </Helmet>
      <BannerShowcaseSignup
        css={css`
          margin: 4rem 0 1rem 0;
        `}
      />
      <Banner keyName="showFeaturedTeamsBanner">
        <FormattedMessage
          id="Nav.SideNav.LabelFeaturedTeams.Tooltip"
          values={{
            intro: (
              <b>
                <FormattedMessage id="Nav.SideNav.LabelFeaturedTeams.Tooltip.Intro" />
              </b>
            ),
          }}
        />
      </Banner>
      <Grid cols={3}>
        <Grid.col start={1} end={4}>
          <PageTitle title={<FormattedMessage id="FeaturedTeams.H1" />} />
        </Grid.col>
        <Grid.col start={1} end={4}>
          <CardList
            renderer={TeamCard}
            data={data}
            loading={loading}
            rendererProps={{
              onClick: (list) => navigate(LINKS.featured_team(list?.slug)),
            }}
          />
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default FeaturedTeams;
