import { gql } from "@apollo/client";

const GET_BADGES = gql`
  query badges($filters: TagFilter) {
    badges(filters: $filters) {
      nodes {
        name
        id
        uuid
        description
      }
    }
  }
`;

export { GET_BADGES };
