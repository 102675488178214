import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { validatePassword } from "utils";
import { CLIENT_SIGNUP } from "graphql/mutations";
import { MUTATION_TYPE } from "hooks";
import Form, { SIZE, TYPE } from "components/Form";
import { margins } from "style";

/**
 * ClientDetailForm
 *
 * @params {Function}   onCancel
 * @params {Boolean}    loading
 * @params {Object}     values
 * @params {Boolean}    isPreloaded
 */
const ClientDetailForm = ({ onCancel, loading, values, isPreloaded, ...props }) => {
  if (loading) return;

  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const passwordResult = validatePassword(values.password);

  useEffect(() => {
    const pw = values?.password;
    const pwConfirm = values?.passwordConfirmation;

    setPasswordsMatch(!pwConfirm || pwConfirm?.length === 0 || pwConfirm === pw);
  }, [values]);

  return (
    <Form
      data={FORM_DATA(passwordsMatch, isPreloaded)}
      values={values}
      mutationType={MUTATION_TYPE.add}
      mutationData={{
        mutationName: {
          add: CLIENT_SIGNUP,
        },
        unWrapParams: false,
      }}
      marginSize={margins.none}
      cancelButton={{
        show: true,
        labelId: "Global.GoBack",
        onClick: onCancel,
      }}
      saveButton={{
        show: true,
        labelId: "Global.Complete",
        disabled: !passwordsMatch || !passwordResult.isValid,
      }}
      {...props}
    />
  );
};

const FORM_DATA = (passwordsMatch, isPreloaded) => [
  {
    style: css`
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
    `,
    items: [
      {
        label: <FormattedMessage id="Signup.ClientDetail.FormLabelHeader" />,
        type: TYPE.h4,
        size: SIZE.xlarge,
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.ClientDetail.FormLabelFirstName" />,
        disabled: isPreloaded,
        properties: {
          name: "firstName",
          placeholder: "First Name",
          autoFocus: true,
          required: true,
        },
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.ClientDetail.FormLabelLastName" />,
        disabled: isPreloaded,
        properties: {
          name: "lastName",
          placeholder: "Last Name",
          required: true,
        },
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.ClientDetail.FormLabelEmail" />,
        disabled: isPreloaded,
        properties: {
          type: "email",
          name: "email",
          placeholder: "Enter email",
          required: true,
        },
      },
      {
        type: TYPE.password,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.FormEmail.FormLabelPassword" />,
        properties: {
          name: "password",
          type: "password",
          autoComplete: "new-password",
          required: true,
        },
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.FormEmail.FormLabelPasswordConfirm" />,
        hasError: !passwordsMatch,
        errorMessage: "Passwords do not match",
        properties: {
          name: "passwordConfirmation",
          type: "password",
          autoComplete: "new-password",
          required: true,
        },
      },
    ],
  },
];

ClientDetailForm.propTypes = {
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  values: PropTypes.object,
  isPreloaded: PropTypes.bool,
};

export default ClientDetailForm;
