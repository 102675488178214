import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import PageTitle from "components/PageTitle";
import { Grid, PageWrapper } from "components/Containers";
import { UserInviteButton, UserList } from "components/Settings/Users";
import Card from "components/Card";
import { USER_ACCESS_RESULTS_PER_PAGE } from "constants/index";

/**
 * Platform Access
 */
const PlatformAccess = () => {
  const intl = useIntl();

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Users.Home.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            subtitle={<FormattedMessage id={`Settings.PlatformAccess.Title`} />}
            actionBar={<UserInviteButton />}
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <UserList resultsPerPage={USER_ACCESS_RESULTS_PER_PAGE} />
          </Card>
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default PlatformAccess;
