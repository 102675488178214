import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useAuth } from "hooks";
import { CLIENT_MEETING_URL } from "constants/index";

const BookAMeeting = () => {
  const { ...me } = useAuth();
  const { firstName, lastName, email } = me || {};
  const [meetingUrl, setMeetingUrl] = useState();

  useEffect(() => {
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    function formatUrl() {
      const url = new URL(CLIENT_MEETING_URL);

      url.searchParams.append("firstname", firstName || "");
      url.searchParams.append("lastname", lastName || "");
      url.searchParams.append("email", email || "");

      return url;
    }

    setMeetingUrl(formatUrl());
  }, [firstName, lastName, email]);

  if (!meetingUrl) return null;

  return (
    <div css={styles.body}>
      <div className="meetings-iframe-container" css={styles.iframe} data-src={meetingUrl}></div>
    </div>
  );
};

const styles = {
  iframe: css`
    width: 100%;
    height: 100%;
  `,
  body: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 3rem 0 0 0rem;
  `,
};

export default BookAMeeting;
