import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useComments } from "hooks";
import { colors, fontSize } from "style";
import { Editor } from "components/Form";
import { PrimaryLink } from "components/Links";
import { Col, Row } from "components/Containers";
import { PrimaryButton, SecondaryButton, BUTTON_SIZE } from "components/Buttons";
import { COMMENTABLE_TYPES } from "constants/index";

/**
 * NoteBody
 *
 * @param {Object}    data
 * @param {Boolean}   isComment
 * @param {Boolean}   isEditing
 * @param {Function}  onEdit
 * @param {Function}  onReply
 * @param {Boolean}   allowReply
 * @param {Boolean}   isLast
 */
const NoteBody = ({ data, isComment, isEditing, onEdit, onReply, allowReply, isLast }) => {
  const [value, setValue] = useState(data?.messageJson);
  const [undoValue, setUndoValue] = useState(data?.messageJson);
  const handleChange = (name, value) => setValue(value);
  const editor = useRef(null);
  const focusEditor = () => editor.current.focus(true);
  const { handleUpdateComment, updating } = useComments({});
  const handleUpdateComplete = () => onEdit(false);
  const Container = isComment ? StyledRow : StyledCol;

  useEffect(() => {
    setUndoValue(data?.messageJson);
  }, [data?.messageJson]);

  const handleCancel = () => {
    setValue(undoValue);
    setTimeout(() => onEdit(false), 100);
  };

  useEffect(() => {
    if (isEditing) {
      focusEditor();
    }
  }, [isEditing]);

  return (
    <>
      <div id={`note-${data?.id}`} css={styles.container(isComment, isLast)}>
        <Container>
          <EditorContainer>
            <StyleEditor
              ref={editor}
              value={value}
              css={!isEditing ? styles.body : styles.editing}
              readOnly={!isEditing}
              showFormattingOptions={!isComment}
              onChange={handleChange}
            />
          </EditorContainer>
          {isEditing && (
            <Row css={styles.edit_buttons}>
              <PrimaryButton
                size={BUTTON_SIZE.medium}
                onClick={() =>
                  handleUpdateComment(
                    value,
                    data?.id,
                    handleUpdateComplete,
                    COMMENTABLE_TYPES[isComment ? "comment" : "profile"]
                  )
                }
                disabled={updating}
              >
                <FormattedMessage id="Global.Save" />
              </PrimaryButton>
              <SecondaryButton size={BUTTON_SIZE.medium} onClick={handleCancel} disabled={updating}>
                <FormattedMessage id="Global.Cancel" />
              </SecondaryButton>
            </Row>
          )}
        </Container>
        {isComment && !isEditing && allowReply && (
          <ReplyLink onClick={() => onReply(data?.user)}>
            <FormattedMessage id="Notes.NoteBody.Reply" />
          </ReplyLink>
        )}
      </div>
    </>
  );
};

const StyledRow = styled(Row)`
  align-items: flex-end;
`;

const StyledCol = styled(Col)`
  gap: 1rem;
`;

const StyleEditor = styled(Editor)`
  flex: 1;
`;

const EditorContainer = styled.div`
  flex: 1;
`;

const styles = {
  container: (isComment, isLast) => css`
    font-size: ${fontSize.small};
    line-height: 1.5;
    margin: 1rem 0 1rem 0;

    ${isComment &&
    ` 
      padding-left: 2.5rem;
      padding-bottom:1.5rem;
      margin: -0.5rem 0 0.5rem 1.5rem;
      position:relative;
      
      ${
        !isLast &&
        `
       &:before {
        content:" ";
        position:absolute;
        top:0.5rem;
        left:-1px;
        bottom:-0.5rem;
        border-left: 2px ${colors.grayAnatomyLight3} solid; /* px OK for border */
      }
      `
      }
    `}
  `,
  edit_buttons: css`
    justify-content: flex-end;
    margin-left: 1rem;
  `,
  body: css`
    margin: 0;
    padding: 0;
    border: 0;
    box-shadow: none;
  `,
  editing: css`
    margin: 0;
    padding: 1rem;
  `,
  read_more_link: css`
    margin-top: 0.5rem;
  `,
};

const ReplyLink = styled(PrimaryLink)`
  font-size: ${fontSize.xsmall};
`;

NoteBody.defaultProps = {
  isLast: false,
  allowReply: true,
};

NoteBody.propTypes = {
  data: PropTypes.object,
  isComment: PropTypes.bool,
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func,
  onReply: PropTypes.func,
  allowReply: PropTypes.bool,
  isLast: PropTypes.bool,
};

export default NoteBody;
