import React from "react";
import { DatePicker } from "components/Form";

/**
 * ActivityPeriodSelector
 */
const ActivityPeriodSelector = (props) => <DatePicker enableWeekPicker {...props} maxDate={new Date()} />;

ActivityPeriodSelector.propTypes = {};

export default ActivityPeriodSelector;
