import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useToast } from "hooks";
import Form, { SIZE, TYPE } from "components/Form";
import { GET_DISCIPLINES, GET_SKILLS, GET_PROFILES } from "graphql/queries";
import { ADD_USER_PROFILE } from "graphql/mutations";
import { MUTATION_TYPE } from "hooks";
import { PAGES, ARCHETYPES, USER_TYPES } from "constants/index";
import { ModalForm, MODAL_SIZE } from "components/Modal";

/**
 * ProfileForm
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Object}    data
 * @param {Boolean}   loading
 * @param {Boolean}   isModal
 * @param {Boolean}   isEditing
 */
const ProfileForm = ({ show, onClose, loading, isModal, isEditing }) => {
  const { toast } = useToast();

  if (loading) return null;

  const formProps = {
    mutationType: MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: ADD_USER_PROFILE,
      },
      refetchAfterMutate: true,
      refetchQueries: [GET_PROFILES],
    },
  };

  const handleComplete = () => {
    if (onClose) {
      onClose();
    }

    toast.success(<FormattedMessage id="Missions.ProfileForm.Success" />);
  };

  if (isModal) {
    return (
      <ModalForm
        data={FORM_DATA}
        show={show}
        size={MODAL_SIZE.medium}
        onClose={onClose}
        title={<FormattedMessage id="Missions.ProfileForm.FormTitle" />}
        description={<FormattedMessage id="Missions.ProfileForm.FormDescription" />}
        onCompleted={handleComplete}
        initialValues={{ profile: {}, role: USER_TYPES.freelancer }}
        {...formProps}
        page={isEditing ? PAGES.editMissionDetails : PAGES.newMission}
      />
    );
  }

  return <Form data={FORM_DATA} onCompleted={handleComplete} {...formProps} />;
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "First Name",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "firstName",
          type: "text",
          maxLength: 255,
          required: true,
        },
      },
      {
        label: "Last Name",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "lastName",
          type: "text",
          maxLength: 255,
          required: true,
        },
      },
      {
        label: "Email",
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "email",
          required: true,
          type: "email",
        },
      },
      {
        label: "Disciplines",
        type: TYPE.tags,
        size: SIZE.xlarge,
        parentObjectKey: "profile",
        properties: {
          name: "disciplines",
          placeholder: "Type a discipline...",
          queryName: GET_DISCIPLINES,
          queryKeyName: "disciplines",
          minLength: 0,
          required: false,
        },
      },
      {
        label: "Archetype",
        type: TYPE.select,
        size: SIZE.xlarge,
        parentObjectKey: "profile",
        properties: {
          name: "archetype",
          required: true,
          options: Object.values(ARCHETYPES),
          labelKey: "name",
          valueKey: "id",
        },
      },
      {
        label: "Skills",
        type: TYPE.tags,
        size: SIZE.xlarge,
        parentObjectKey: "profile",
        properties: {
          name: "skills",
          placeholder: "Type a skill...",
          queryName: GET_SKILLS,
          queryKeyName: "skills",
          required: true,
          minLength: 1,
        },
      },
      {
        type: TYPE.hidden,
        properties: {
          name: "role",
        },
      },
    ],
  },
];

ProfileForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  isModal: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default ProfileForm;
