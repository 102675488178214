const RESOURCE_TYPES = {
  assignment: "Assignment",
  profile: "Profile",
  profileEducation: "ProfileEducation",
  profileLink: "ProfileLink",
  profileSchedule: "ProfileSchedule",
  profileVacation: "ProfileVacation",
  profileWorkExperience: "ProfileWorkExperience",
  profileList: "ProfileList",
  timeCard: "TimeCard",
  timeSlip: "TimeSlip",
  interviewResponse: "InterviewResponse",
  missionRoleApplicant: "MissionRoleApplicant",
  missionRoleInvitation: "MissionRoleInvitation",
  missionInterviewCreated: "MissionInterview",
  comment: "Comment",
  coderbyteChallengeResponse: "CoderbyteChallengeResponse",
  deelInvoicesTask: "DeelInvoicesTask",
};

export default RESOURCE_TYPES;
