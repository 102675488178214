import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { SIZE, TYPE } from "components/Form";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { MUTATION_TYPE, useToast } from "hooks";
import { ADD_ORGANIZATION_BILLING, UPDATE_ORGANIZATION_BILLING } from "graphql/mutations";
import { CURRENCIES } from "constants/index";
import { GET_ORGANIZATION } from "graphql/queries";

/**
 * OrganizationBillingForm
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {String}    billingId
 * @param {Boolean}   isEditing
 * @param {String}    organizationId
 * @param {Object}    data
 */
const OrganizationBillingForm = ({ organizationId, data, billingId, show, onClose, isEditing, ...props }) => {
  const { toast } = useToast();

  const formProps = {
    updateId: billingId,
    parentKeyValue: organizationId,
    parentKeyName: "organizationId",
    mutationType: isEditing ? MUTATION_TYPE.update : MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: ADD_ORGANIZATION_BILLING,
        update: UPDATE_ORGANIZATION_BILLING,
      },
      refetchQueries: [GET_ORGANIZATION],
      refetchAfterMutate: true,
    },
  };

  const handleComplete = () => {
    onClose();
    toast.success(<FormattedMessage id={`Organization.AddBillingButton.${isEditing ? `EditSuccess` : `CreateSuccess`}`} />);
  };

  const handleError = () => {};

  return (
    <ModalForm
      data={FORM_DATA}
      initialValues={data}
      show={show}
      size={MODAL_SIZE.medium}
      onClose={onClose}
      onCompleted={handleComplete}
      onError={handleError}
      title={<FormattedMessage id={"Organizations.OrganizationBillingInformation.Title"} />}
      {...formProps}
    />
  );
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Name",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "name",
          type: "text",
          required: true,
        },
      },
      {
        label: "Currency",
        type: TYPE.select,
        size: SIZE.medium,
        properties: {
          name: "currencyCode",
          required: true,
          options: CURRENCIES,
        },
      },
    ],
  },
];

OrganizationBillingForm.propTypes = {
  organizationId: PropTypes.string,
  billingId: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  isEditing: PropTypes.bool,
  data: PropTypes.array,
};

export default OrganizationBillingForm;
