import React from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { formatCurrency, formatDate, formatHours, getMonthFromDate, getYearFromDate } from "utils";
import { colors, fontSize } from "style";
import { DataTableProvider } from "components/DataTable";
import { DEEL_TASK_STATUS, TIME_CARD_STATUS } from "constants/index";
import { TimeCardDetails, TimeCardReopenForm } from "components/TimeCard";
import { Grid, Row } from "components/Containers";
import { useAuth, useTimeCard } from "hooks";
import { GET_TIME_CARD_REPORT } from "graphql/queries";
import Status from "components/Status";
import { ProfileLink } from "components/Profile";
import { SquadLink } from "components/Squads";

/**
 * TimeCardList
 *
 * @param {object}   filters
 * @param {object}   setTotalCount
 * @param {Object}   selectedDate
 * @param {Function} setSubmittedCount
 * @param {Function} onLoading
 * @param {Function} onDataFetched
 */
const TimeCardList = ({ filters, setTotalCount, selectedDate, setSubmittedCount, onDataFetched, onLoading }) => {
  const { isPartner, isAdmin } = useAuth();
  const { getAdvancedOptions, showReopenForm, setShowReopenForm, selected } = useTimeCard();

  const headerOptions = (selected, data) =>
    getAdvancedOptions({
      listData: data,
      selection: selected,
      isBatch: true,
    });

  const rowOptions = (row) =>
    !isPartner &&
    getAdvancedOptions({
      data: row,
      month: getMonthFromDate(selectedDate, "M"),
      year: getYearFromDate(selectedDate),
      isBatch: false,
    });

  return (
    <>
      <Grid>
        <Grid.col start={1} end={13}>
          <DataTableProvider
            queryName={GET_TIME_CARD_REPORT}
            keyName="timeCardReportForMonth.report"
            setTotalCount={(_total, data) => {
              const { timeCardReportForMonth } = data ?? [];

              if (timeCardReportForMonth?.report?.length) {
                const reviewedOrAcceptedCount = timeCardReportForMonth.report.filter(
                  (item) =>
                    item.timeCard?.status === TIME_CARD_STATUS.review ||
                    item.timeCard?.status === TIME_CARD_STATUS.accepted
                ).length;
                setSubmittedCount(reviewedOrAcceptedCount);

                setTotalCount(timeCardReportForMonth.report.length);
              }
            }}
            variables={{
              filters: {
                monthKey: `${String(filters.year)}-${String(filters.month)}`,
                teamIds: filters.teamIds?.map(({ id }) => id),
                organizationIds: filters.organizationIds?.map(({ id }) => id),
                partnerIds: filters.partnerIds?.map(({ id }) => id),
                timeCardStatuses: filters.statuses?.map(({ value }) => value),
                queryString: filters.search,
                isOverAllocatedTime: filters.overAllocatedHours,
              },
            }}
            rowOptions={rowOptions}
            headerOptions={headerOptions}
            columns={COLUMNS(selectedDate, isAdmin)}
            expandable={true}
            expandableKeyName={`breakdown`}
            expandableColumns={EXPANDABLE_COLUMNS(isAdmin)}
            selectable={true}
            clearable={false}
            onLoading={onLoading}
            onDataFetched={onDataFetched}
            disablePagination
          />
        </Grid.col>
      </Grid>
      <TimeCardReopenForm data={selected} show={showReopenForm} setShow={setShowReopenForm} />
    </>
  );
};

const EXPANDABLE_COLUMNS = (isAdmin) => [
  {
    cell: (row) => (
      <>
        <SquadLink data={row.team} withOverlay={isAdmin} />

        <div css={styles.organization}>{row.organization.name}</div>
      </>
    ),
    cellProps: {
      alignLeft: true,
    },
  },
  {
    cell: (row) => formatHours(row.timeLoggedMinutes),
  },
  {
    cell: (row) => formatHours(row.timeAllocatedMinutes),
  },
  {
    cell: (row) => formatHours(parseFloat(row.timeAllocatedMinutes) - parseFloat(row.timeLoggedMinutes)),
  },
  ...(isAdmin
    ? [
        {
          cell: (row) => formatCurrency(row.rate, row.currency),
        },
      ]
    : []),
  {
    cell: ({ deelStatus }) =>
      deelStatus && (
        <Status enabled={DEEL_TASK_STATUS[deelStatus] === DEEL_TASK_STATUS.SUCCESS}>
          <FormattedMessage id="TimeCard.TimeCardTable.DeelStatus" />
        </Status>
      ),
  },
  {
    cell: (row) => (row?.lastActivityDate ? formatDate(row?.lastActivityDate) : "-"),
    cellProps: {
      colSpan: 4,
    },
  },
];

const COLUMNS = (selectedDate, isAdmin) => [
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.Name" />,
    width: `20%`,
    cell: (row) => (
      <ProfileLink
        profile={row?.profile}
        withOverlay
        withPhoto
        dataFormatted
        photoProps={{ width: "2rem", height: "2rem", borderRadius: "100%" }}
      />
    ),
  },
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.Logged" />,
    width: 80,
    cell: (row) => formatHours(row?.timeLoggedMinutes),
  },
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.Allocated" />,
    width: 80,
    cell: (row) => formatHours(row?.timeAllocatedMinutes),
  },
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.Difference" />,
    width: 80,
    cell: (row) => formatHours(row?.timeDifferenceMinutes),
  },
  ...(isAdmin
    ? [
        {
          label: <FormattedMessage id="TimeCard.TimeCardTable.Rate" />,
          width: 80,
          cell: () => "-",
        },
      ]
    : []),
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.Status" />,
    width: `10%`,
    cell: (row) => (
      <Status enabled={row?.timeCard?.status || TIME_CARD_STATUS.open}>
        <FormattedMessage id={`TimeCardStatus.${row?.timeCard?.status || TIME_CARD_STATUS.open}`} />
      </Status>
    ),
  },
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.LastEntry" />,
    width: `10%`,
    cell: (row) => (row?.lastActivityDate ? formatDate(row?.lastActivityDate) : "-"),
  },
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.DaysOff" />,
    width: `10%`,
    cell: (row) => row?.daysOff || "-",
  },
  {
    cellProps: {
      alignRight: true,
    },
    width: `20%`,
    cell: (row) => (
      <Row css={styles.row}>
        <TimeCardDetails id={row.profile.id} month={selectedDate} data={row.profile} />
      </Row>
    ),
  },
];

const styles = {
  row: css`
    justify-content: flex-end;
  `,
  centered_row: css`
    justify-content: center;
  `,
  organization: css`
    color: ${colors.purpleRainLight4} !important;
    font-weight: 400 !important;
    font-size: ${fontSize.xsmall};
    margin-top: 0.5rem;
  `,
};

TimeCardList.propTypes = {
  filters: PropTypes.object,
  selectedDate: PropTypes.object,
  setSubmittedCount: PropTypes.func,
  setTotalCount: PropTypes.func,
  onLoading: PropTypes.func,
  onDataFetched: PropTypes.func,
};

export default TimeCardList;
