import { GET_SKILLS, GET_TIME_ZONES, GET_TEAMS, GET_TEAM_VERSIONS } from "graphql/queries";

const QUERIES = {
  skills: GET_SKILLS,
  timezones: GET_TIME_ZONES,
  teams: GET_TEAMS,
  teamVersions: GET_TEAM_VERSIONS
};

export default QUERIES;
