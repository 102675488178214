import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "components/Buttons";
import { ShowcaseInviteModal } from "components/Showcase";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * ShowcaseInviteButton
 *
 * @param {Boolean} isShowcase
 */
const ShowcaseInviteButton = ({ isShowcase, ...props }) => {
  if (!isShowcase) {
    return null;
  }

  const [showInviteForm, setShowInviteForm] = useState(false);

  return (
    <>
      <PrimaryButton variant="green" {...props} onClick={() => setShowInviteForm(true)}>
        <FormattedMessage id="Showcase.ShowcaseInviteButton.Text" />
        <Icon type={ICON_TYPE.share} size={ICON_SIZE.small} color="inherit" />
      </PrimaryButton>
      {showInviteForm && <ShowcaseInviteModal show={showInviteForm} onClose={() => setShowInviteForm(false)} />}
    </>
  );
};

ShowcaseInviteButton.propTypes = {
  isShowcase: PropTypes.bool.isRequired,
};

export default ShowcaseInviteButton;
