import { GET_EVENTS } from "graphql/queries";

const MOCK_GET_EVENTS = {
  request: {
    query: GET_EVENTS,
    variables: {
      orderBy: {},
      first: 2,
      last: null,
      after: null,
    },
  },
  result: {
    data: {
      missionEvents: {
        nodes: [
          {
            id: "10",
            name: "Investor Community-Services Specialist",
            title: "Orchestrate strategic portals",
            description: "Mandatory methodical conglomeration",
            url: "http://carroll-gottlieb.io/derick",
            recordingLink: null,
            displayImageUrl:
              "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhOR3gwYVhkdGFHaHdZelEwYzJzM01taG1aRFl3WjNNek5YZ3lOd1k2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpTjJsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpFd0xtcHdaeUk3SUdacGJHVnVZVzFsS2oxVlZFWXRPQ2NuTVRBdWFuQm5CanNHVkRvUlkyOXVkR1Z1ZEY5MGVYQmxTU0lQYVcxaFoyVXZhbkJsWndZN0JsUTZFWE5sY25acFkyVmZibUZ0WlRvS2JHOWpZV3c9IiwiZXhwIjoiMjAyMi0xMi0wNVQyMDo0OTowMC44NjZaIiwicHVyIjoiYmxvYl9rZXkifX0=--e71faf0372b05498c648496faba91f51838246cf/10.jpg",
            startsAt: "2022-09-22T13:01:32Z",
            startsAtWithTimezone: "Sep 22, 2022, 10:01pm PKT",
            __typename: "MissionEvent",
          },
          {
            id: "9",
            name: "Marketing Designer",
            title: "Incentivize impactful channels",
            description: "Organic mission-critical support",
            url: "http://konopelski-casper.biz/wendell",
            recordingLink: null,
            displayImageUrl:
              "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhhSG96YkhWaWNtRjJiSFYwWm1OemR6aDRjVEV6Ym5RMGF6bHBkQVk2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpTldsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWprdWFuQm5JanNnWm1sc1pXNWhiV1VxUFZWVVJpMDRKeWM1TG1wd1p3WTdCbFE2RVdOdmJuUmxiblJmZEhsd1pVa2lEMmx0WVdkbEwycHdaV2NHT3daVU9oRnpaWEoyYVdObFgyNWhiV1U2Q214dlkyRnMiLCJleHAiOiIyMDIyLTEyLTA1VDIwOjQ5OjAwLjg5OVoiLCJwdXIiOiJibG9iX2tleSJ9fQ==--a1b146fd6a902da813c5c0c889dca312e63a1dbc/9.jpg",
            startsAt: "2022-09-22T13:01:31Z",
            startsAtWithTimezone: "Sep 22, 2022, 10:01pm PKT",
            __typename: "MissionEvent",
          },
        ],
        pageInfo: {
          hasNextPage: true,
          hasPreviousPage: false,
          startCursor: "MQ",
          endCursor: "Mg",
          __typename: "PageInfo",
        },
        edges: [
          {
            cursor: "MQ",
            node: {
              id: "10",
              __typename: "MissionEvent",
            },
            __typename: "MissionEventEdge",
          },
          {
            cursor: "Mg",
            node: {
              id: "9",
              __typename: "MissionEvent",
            },
            __typename: "MissionEventEdge",
          },
        ],
        __typename: "MissionEventConnection",
      },
    },
  },
};

export { MOCK_GET_EVENTS };
