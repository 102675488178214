const fontSize = {
  xxxsmall: `1rem`,
  xxsmall: `1.2rem`,
  xsmall: `1.3rem`,
  small: `1.4rem`,
  normal: `1.6rem`,
  medium: `1.8rem`,
  large: `2rem`,
  xlarge: `2.4rem`,
  xxlarge: `3.2rem`,
  xxxlarge: `3.6rem`,
  xxxxlarge: `4rem`,
};

export { fontSize };
