import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { PageWrapper, Grid } from "components/Containers";
import Card from "components/Card";
import { DashboardChart } from "components/Dashboard";
import { MissionMemberList } from "components/Missions";
import PageTitle from "components/PageTitle";
import { useAuth } from "hooks";

/**
 * Engineering Dashboard
 */
const Dashboard = () => {
  const { id } = useParams();
  const { profileId, isAdmin } = useAuth();
  const intl = useIntl();

  if (!isAdmin) return null;

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Dashboard.View.Title" })}</title>
      </Helmet>
      <DashboardChart teamId={id} profileId={profileId} />
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle title={<FormattedMessage id="Dashboard.H2" />} />
            <Card isAdmin>
              <MissionMemberList teamId={id} />
            </Card>
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default Dashboard;
