import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import ImageHeroBackground from "assets/images/hero-Background.jpg";

/**
 * HeaderBox
 *
 * @params {Element}   children
 */
const GradientHeader = ({ children }) => <div css={styles.container}>{children}</div>;

const styles = {
  container: css`
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    background-image: url(${ImageHeroBackground});
    background-size: 100% 41.2rem;
    background-repeat: no-repeat;
    padding: 1.6rem;
  `,
};

GradientHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GradientHeader;
