import React from "react";
import { FormattedMessage } from "react-intl";
import { useMissionContacts } from "hooks";
import { UserList } from "components/User";

/** 
 * MissionContacts
 */
const MissionContacts = props => {
  const { hasNextPage, handleNextPage, loading, data } = useMissionContacts({ resultsPerPage: 5 });

  return (
    <UserList
      data={data}
      title={<FormattedMessage id="Mission.Contacts.Title" />}
      loading={loading}
      hasNextPage={hasNextPage}
      handleNextPage={handleNextPage}
      {...props}
    />
  )
};

export default MissionContacts;
