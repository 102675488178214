const TIMELINE_EVENTS = {
  assignmentCreated: "platform:backend:assignment:created",
  assignmentUpdated: "platform:backend:assignment:updated",
  assignmentDestroyed: "platform:backend:assignment:destroyed",
  profileCreated: "platform:backend:profile:created",
  profileUpdated: "platform:backend:profile:updated",
  profileEducationCreated: "platform:backend:profile_education:created",
  profileEducationUpdated: "platform:backend:profile_education:updated",
  profileEducationDestroyed: "platform:backend:profile_education:destroyed",
  profileLinkCreated: "platform:backend:profile_link:created",
  profileLinkUpdated: "platform:backend:profile_link:updated",
  profileLinkDestroyed: "platform:backend:profile_link:destroyed",
  profileScheduleCreated: "platform:backend:profile_schedule:created",
  profileScheduleUpdated: "platform:backend:profile_schedule:updated",
  profileScheduleDestroyed: "platform:backend:profile_schedule:destroyed",
  profileVacationCreated: "platform:backend:profile_vacation:created",
  profileVacationUpdated: "platform:backend:profile_vacation:updated",
  profileVacationDestroyed: "platform:backend:profile_vacation:destroyed",
  profileWorkExperienceCreated: "platform:backend:profile_work_experience:created",
  profileWorkExperienceUpdated: "platform:backend:profile_work_experience:updated",
  profileWorkExperienceDestroyed: "platform:backend:profile_work_experience:destroyed",
  profileListCreated: "platform:backend:profile_list:created",
  profileListUpdated: "platform:backend:profile_list:updated",
  profileListDestroyed: "platform:backend:profile_list:destroyed",
  interviewResponseCreated: "platform:backend:interview_response:created",
  interviewResponseUpdated: "platform:backend:interview_response:updated",
  interviewResponseDestroyed: "platform:backend:interview_response:destroyed",
  coderbyteChallengeResponseCreated: "platform:backend:coderbyte_challenge_response:created",
  coderbyteChallengeResponseUpdated: "platform:backend:coderbyte_challenge_response:updated",
  coderbyteChallengeResponseDestroyed: "platform:backend:coderbyte_challenge_response:destroyed",
  timeCardCreated: "platform:backend:time_card:created",
  timeCardUpdated: "platform:backend:time_card:updated",
  timeCardDestroyed: "platform:backend:time_card:destroyed",
  timeSlipCreated: "platform:backend:time_slip:created",
  timeSlipUpdated: "platform:backend:time_slip:updated",
  timeSlipDestroyed: "platform:backend:time_slip:destroyed",
  missionRoleApplicantCreated: "platform:backend:mission_role_applicant:created",
  missionRoleApplicantUpdated: "platform:backend:mission_role_applicant:updated",
  missionRoleApplicantDestroyed: "platform:backend:mission_role_applicant:destroyed",
  missionRoleInvitationCreated: "platform:backend:mission_role_invitation:created",
  missionInterviewCreated: "platform:backend:mission_interview:created",
  missionInterviewUpdated: "platform:backend:mission_interview:updated",
  missionInterviewDestroyed: "platform:backend:mission_interview:destroyed",
  commentCreated: "platform:backend:comment:created",
  commentUpdated: "platform:backend:comment:updated",
  commentDestroyed: "platform:backend:comment:destroyed",
  deelTaskCreated: "platform:backend:deel_invoices_task:created",
  deelTaskUpdated: "platform:backend:deel_invoices_task:updated",
};

export default TIMELINE_EVENTS;

// TO BE IMPLEMENTED
/*          
platform:backend:profile:destroyed
platform:backend:interview_review:created
platform:backend:interview_review:updated
platform:backend:interview_review:destroyed
platform:backend:mission_role_invitation:updated
platform:backend:mission_role_invitation:destroyed
platform:backend:profile_interview:created
platform:backend:profile_interview:updated
platform:backend:profile_interview:destroyed
platform:backend:state_transition:created
platform:backend:state_transition:updated
platform:backend:state_transition:destroyed
platform:backend:tagging:created
platform:backend:tagging:updated
platform:backend:tagging:destroyed
platform:backend:team_assignment_period:created
platform:backend:team_assignment_period:updated
platform:backend:team_assignment_period:destroyed
platform:backend:team_profile_account:created
platform:backend:team_profile_account:updated
platform:backend:team_profile_account:destroyed
*/
