/**
 * FormatNameForChart - Formats name for Code Telemetry Charts
 *
 * @param {Object} profile
 * @param {String} handle
 * @returns {String}
 */
export default function formatNameForChart(profile, handle) {
  return profile ? `${profile.firstName} ${profile.lastName} (@${handle})` : `@${handle}`;
}
