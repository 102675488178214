import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { css } from "@emotion/react";
import { EngineeringMetricsForm } from "components/Squads/settings";
import PageTitle from "components/PageTitle";
import { PageWrapper, Grid } from "components/Containers";
import Card from "components/Card";

/**
 * SignalSettings
 */
const SignalSettings = ({ ...props }) => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.Settings.Title" })}</title>
      </Helmet>
      <PageWrapper {...props}>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle subtitle={<FormattedMessage id="Squads.Settings.Signal.Title" />} />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <Card css={styles.card}>
              <EngineeringMetricsForm />
            </Card>
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

const styles = {
  card: css`
    padding: 0;
  `,
};

export default SignalSettings;
