import React from "react";
import PropTypes from "prop-types";
import { LINKS } from "constants";
import Overlay from "components/Overlay";
import { MissionCard } from "components/Missions";
import { PrimaryLink } from "components/Links";

/**
 * MissionLink
 *
 * @param {Object}  data
 * @param {Boolean} isEdit
 * @param {Boolean} withOverlay
 * @param {String}  placement
 */
const MissionLink = ({ data, isEdit, withOverlay, placement, ...props }) => {
  const Link = () => (
    <PrimaryLink to={isEdit ? LINKS.mission_edit(data?.uuid) : LINKS.mission(data?.uuid)} routerEnabled>
      {data?.name}
    </PrimaryLink>
  );

  if (!withOverlay) {
    return <Link />;
  }

  return (
    <Overlay
      asCardOverlay
      placement={placement}
      width={`40rem`}
      overlay={<MissionCard data={data} />}
      trigger={<Link />}
      delay={{ show: 500 }}
      {...props}
    />
  );
};

MissionLink.defaultProps = {
  withOverlay: false,
  placement: "right",
};

MissionLink.propTypes = {
  data: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
  withOverlay: PropTypes.bool,
  placement: PropTypes.string,
};

export default MissionLink;
