const CHANGE_KEYS = {
  scheduledAt: "scheduledAt",
  profile: "profile",
  remove: "remove",
  archetype: "archetype",
  buyingCurrency: "buyingCurrency",
  sellingCurrency: "sellingCurrency",
};

export default CHANGE_KEYS;
