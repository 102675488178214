import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useToast, MUTATION_TYPE } from "hooks";
import { CHANGE_KEYS, TERM_SHEET_SCENARIOS } from "constants/index";
import { SIZE, TYPE } from "components/Form";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { GET_TERM_SHEET_FUTURE_EVENTS, GET_TERM_SHEET_SCENARIOS } from "graphql/queries";
import { formatNumber } from "utils";
import { SCHEDULE_TERM_SHEET_SCENARIO } from "graphql/mutations/termSheet";

/**
 * TermSheetScenarioForm
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Function}  onComplete
 * @param {Object}    selectedScenario
 * @param {String}    teamId
 * @param {Object}    changes
 * @param {Boolean}   loading
 */
const TermSheetScenarioForm = ({ show, onClose, onComplete, selectedScenario, teamId, changes, loading }) => {
  const { toast } = useToast();
  const [teamMembers, setTeamMembers] = useState([]);
  const [initialValues, setInitialValues] = useState({});

  /**
   * @description Filter out team members that have no changes
   */
  useEffect(() => {
    const stateTeamMembers = selectedScenario?.termSheetTeamState?.teamMembers;
    let newAdditions = [];

    Object.keys(changes).forEach((key) => {
      if (changes[key]?.add === true) {
        newAdditions.push(changes[key]);
      }
    });

    setTeamMembers([
      ...(stateTeamMembers
        ? stateTeamMembers?.filter((member) => scheduledChangesChecker(changes?.[member?.profile?.id]))
        : []),
      ...newAdditions,
    ]);
  }, [selectedScenario, changes]);

  /**-
   * @description Set the initial values for the form
   */
  useEffect(() => {
    const iv = {};

    Object.keys(changes).forEach((id) => (iv[id] = changes[id].scheduledAt));
    setInitialValues(iv);
  }, [changes]);

  if (loading) return null;

  const formProps = {
    initialValues,
    mutationType: MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: SCHEDULE_TERM_SHEET_SCENARIO,
        update: SCHEDULE_TERM_SHEET_SCENARIO,
      },
      refetchAfterMutate: true,
      refetchQueries: [
        {
          query: GET_TERM_SHEET_FUTURE_EVENTS,
          variables: { teamId, first: null, last: null, after: null, orderBy: {} },
        },
        {
          query: GET_TERM_SHEET_SCENARIOS,
          variables: {
            first: null,
            last: null,
            after: null,
            orderBy: {},
            filters: { teamId, state: TERM_SHEET_SCENARIOS.active },
          },
        },
      ],
    },
  };

  const handleComplete = (payload) => {
    toast.success(<FormattedMessage id="TermSheets.TermSheetScenarioScheduleForm.Success" />);

    if (typeof onComplete === "function") {
      onComplete(payload);
    }
  };

  return (
    <ModalForm
      data={FORM_DATA(teamMembers, changes)}
      parentKeyName="teamScenarioId"
      parentKeyValue={selectedScenario?.id}
      show={show}
      size={MODAL_SIZE.small}
      onClose={onClose}
      title={<FormattedMessage id="TermSheets.TermSheetScenarioScheduleForm.FormTitle" />}
      description={<FormattedMessage id="TermSheets.TermSheetScenarioScheduleForm.FormDescription" />}
      onCompleted={handleComplete}
      saveButton={{
        labelId: "TermSheets.TermSheetScenarioScheduleButton",
      }}
      {...formProps}
    />
  );
};

const FORM_DATA = (teamMembers, changes) => [
  {
    style: css`
      width: 100%;
    `,
    items: teamMembers?.map((member) => ({
      label: member?.profile?.name,
      type: TYPE.datePicker,
      size: SIZE.xlarge,
      description: <ul>{setDescription(changes[member?.profile?.id], member)}</ul>,
      properties: {
        required: true,
        name: member?.profile?.id,
        minDate: new Date(),
      },
    })),
  },
];

/**
 * @description Checks if there are real changes to schedule
 *
 * @param {Object}  changes
 */
function scheduledChangesChecker(changes) {
  if (!changes) return false;

  const realChanges = Object.keys(changes)?.filter((key) => {
    if (
      key === "scheduledAt" ||
      (key === "remove" && changes[key] === false) ||
      (key === "add" && changes[key] === false)
    )
      return false;

    return true;
  });

  return realChanges?.length > 0;
}

/**
 * @description Format the changes object into a list of human readable messages
 *
 * @param {Object}  changes
 * @param {Object}  member
 */
function setDescription(changes, member) {
  if (!changes) return null;

  const stringKeys = [CHANGE_KEYS.archetype, CHANGE_KEYS.buyingCurrency, CHANGE_KEYS.sellingCurrency];

  return Object.keys(changes).map((key) => {
    if (
      key === CHANGE_KEYS.scheduledAt ||
      key === CHANGE_KEYS.profile ||
      (key === CHANGE_KEYS.remove && changes[key] === false)
    )
      return;

    let value1, value2;

    if (stringKeys.includes(key)) {
      value1 = member?.[key];
      value2 = changes?.[key];
    } else {
      value1 = formatNumber(member?.[key]);
      value2 = formatNumber(changes?.[key]);
    }

    return (
      <li key={key}>
        <FormattedMessage
          id={`TermSheets.TermSheetScenarioScheduleForm.Label_${key}${changes?.add === true ? "_Add" : ""}`}
          values={{
            value1: value1,
            value2: value2,
          }}
        />
      </li>
    );
  });
}

TermSheetScenarioForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  selectedScenario: PropTypes.object,
  teamId: PropTypes.string,
  changes: PropTypes.object,
  loading: PropTypes.bool,
};

TermSheetScenarioForm.defaultProps = {
  changes: {},
};

export { scheduledChangesChecker };

export default TermSheetScenarioForm;
