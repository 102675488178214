import { gql } from "@apollo/client";

const UPDATE_PROFILE_ASSIGNEE = gql`
  mutation UpdateProfileAssignee($input: UpdateProfileAssigneeInput!) {
    updateProfileAssignee(input: $input) {
      profile {
        id
        email
        assignedTo {
          id
          name
        }
      }
    }
  }
`;

export { UPDATE_PROFILE_ASSIGNEE };
