const TRACKING_FLOW = {
  dashboard: "Dashboard",
  network: "Network",
  mission: "Mission",
  missionBoard: "Mission Board",
  partners: "Partners",
  timecard: "Time Cards",
  timesheet: "Time Sheet",
  profile: "Profile",
  signup: "Sign Up",
  client: "Client",
  showcase: "Showcase",
};

export default TRACKING_FLOW;
