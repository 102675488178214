import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { colors } from "style";
import { EditButton, SecondaryButton } from "components/Buttons";

/**
 * Create New Instance
 *
 * @params {Array} fields
 * @params {Function} refetch
 * @params {String} placeholder
 * @params {Object} queryName
 * @params {String} labelKey
 * @params {Function} onComplete
 * @params {Object} variables
 *
 */
const CreateNewInstance = ({ fields, queryName, refetch, labelKey, onComplete, variables }) => {
  const [showInput, setShowInput] = useState(false);
  const [handleCreateInstance, { loading }] = useMutation(queryName);
  const [fieldValues, setFieldValues] = useState({});

  const handleFieldChange = (name, value) => setFieldValues({ ...fieldValues, [name]: value });

  const handleSubmit = (event) => {
    handleCreateInstance({
      variables: {
        input: {
          params: {
            ...fieldValues,
          },
          ...variables,
        },
      },
      onCompleted: (data) => {
        onComplete?.(data);
        setShowInput(false);
        setFieldValues({});
        refetch();
      },
    });
    event.preventDefault();
  };

  if (!showInput) {
    return (
      <div css={styles.container}>
        <EditButton onClick={() => setShowInput(true)}>
          <FormattedMessage id={labelKey} />
        </EditButton>
      </div>
    );
  }

  return (
    <div>
      <div css={[styles.container, styles.form_container]}>
        {fields.map((field) => (
          <field.type
            key={field.name}
            name={field.name}
            type={field.type}
            value={fieldValues[field.name]}
            placeholder={field.properties.placeholder}
            autoFocus={field.properties.autoFocus}
            onChange={handleFieldChange}
            {...field.properties}
          />
        ))}
        <SecondaryButton onClick={handleSubmit} disabled={loading}>
          <Icon type={ICON_TYPE.plus} size={ICON_SIZE.large} color={"inherit"} />
        </SecondaryButton>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    margin-left: -5rem;
    margin-right: -5rem;
    padding: 2rem 5rem;
  `,
  form_container: css`
    background: ${colors.grayAnatomyLight5};
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
  `,
  icon: css`
    border-radius: 50%;
    width: 4rem;
    height: 3.5rem;
    background: ${colors.red};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.7rem;
  `,
};

CreateNewInstance.defaultProps = {
  variables: {},
  fields: [],
};

CreateNewInstance.propTypes = {
  fields: PropTypes.array.isRequired,
  refetch: PropTypes.func,
  queryName: PropTypes.object,
  placeholder: PropTypes.string,
  labelKey: PropTypes.string,
  onComplete: PropTypes.func,
  keyName: PropTypes.string,
  variables: PropTypes.object,
};

export default CreateNewInstance;
