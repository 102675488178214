import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { DataTableProvider } from "components/DataTable";
import { formatDate, shortenText } from "utils";
import { ClientFeedbackAdvancedOptions } from "components/Client";
import { PAGINATION_TYPE } from "components/Pagination";
import { useAuth, useClient } from "hooks";

/**
 * ClientFeedbackList
 *
 * @param {String}    teamId
 * @param {Number}    resultsPerPage
 */
const ClientFeedbackList = ({ teamId, resultsPerPage }) => {
  const { isAdmin } = useAuth();
  const { data, loading, error, handleNextPage, handlePreviousPage, hasNextPage, hasPreviousPage } = useClient({
    resultsPerPage,
    teamId,
  });

  return (
    <DataTableProvider
      data={data}
      error={error}
      loading={loading}
      resultsPerPage={resultsPerPage}
      columns={COLUMN_DATA(isAdmin)}
      paginationType={PAGINATION_TYPE.cursor}
      paginationProps={{
        type: PAGINATION_TYPE.cursor, // FIXME: Change to classic
        resultsPerPage: resultsPerPage,
        onNextPage: handleNextPage,
        onPreviousPage: handlePreviousPage,
        hasNextPage: !loading && hasNextPage,
        hasPreviousPage: !loading && hasPreviousPage,
        loading,
        // FIX ME: Need to add pageInfo, totalCount, currentPage props for client feedback pagination
        //handlePageChange: handlePageChange,
        //pageInfo,
        //totalCount,
        //currentPage,
        //countMessage: "Global.Missions.Count",
      }}
    />
  );
};

const COLUMN_DATA = (isAdmin) => [
  {
    label: <FormattedMessage id="Client.ClientFeedbackList.TableColumnDate" />,
    cell: (row) => formatDate(row?.submittedAt),
    width: "10%",
  },
  {
    label: <FormattedMessage id="Client.ClientFeedbackList.TableColumnNPS" />,
    cell: (row) => row?.nps,
    width: "5%",
  },
  ...(isAdmin
    ? [
        {
          label: <FormattedMessage id="Client.ClientFeedbackList.TableColumnCSAT" />,
          cell: (row) => row?.csat,
          width: "5%",
        },
        {
          label: <FormattedMessage id="Client.ClientFeedbackList.TableColumnHealthScore" />,
          cell: (row) => row?.healthScore,
          width: "5%",
        },
        {
          label: <FormattedMessage id="Client.ClientFeedbackList.TableColumnChurnRisk" />,
          cell: (row) => row?.churnRisk,
          width: "7%",
        },
        {
          label: <FormattedMessage id="Client.ClientFeedbackList.TableColumnOnboardingScore" />,
          cell: (row) => row?.onboardingScore,
          width: "7%",
        },
        {
          label: <FormattedMessage id="Client.ClientFeedbackList.TableColumnPostMortemScore" />,
          cell: (row) => row?.postMortemScore,
          width: "10%",
        },
        {
          label: <FormattedMessage id="Client.ClientFeedbackList.TableColumnVersion" />,
          cell: (row) => row?.version?.title,
          width: "10%",
        },
        {
          label: <FormattedMessage id="Client.ClientFeedbackList.TableColumnComment" />,
          cell: (row) => shortenText(row?.notes, 50),
          width: "15%",
        },
        {
          label: "",
          width: "5%",
          cellProps: {
            alignRight: true,
          },
          cell: (row) => <ClientFeedbackAdvancedOptions updateId={row?.id} data={row} />,
        },
      ]
    : []),
];

ClientFeedbackList.defaultProps = {
  resultsPerPage: 20,
};

ClientFeedbackList.propTypes = {
  teamId: PropTypes.string,
  resultsPerPage: PropTypes.number,
};

export default ClientFeedbackList;
