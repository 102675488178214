import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { useOutletContext } from "react-router";
import PageTitle from "components/PageTitle";
import { AllTimeActivityLogLayout } from "components/ActivityLog";
import { useAuth, useAllTimeActivity, useTracking } from "hooks";
import { PAGES, TEAM_TYPES, TRACKING_PAGE_VIEWS } from "constants/index";
import { Grid } from "components/Containers";
import { MessagesNoResults } from "components/Messages";
import Card from "components/Card";
import { ScopedRepositoriesFilter } from "components/Squads/Engineering";

/**
 * Leaderboard
 */
const Leaderboard = (props) => {
  const { isShowcase } = useAuth();
  const { id } = useOutletContext();
  const [gitRepositories, setGitRepositories] = useState();
  const [selectedGitRepositories, setSelectedGitRepositories] = useState([]);

  const { data, loading, error } = useAllTimeActivity({
    teamId: id,
    filters: { repositories: selectedGitRepositories.map((repo) => repo.id) },
  });
  const intl = useIntl();
  const { trackPage } = useTracking();
  const teamMembersData = data?.filter((item) => !!item.assignment && !!item.profile);
  const guestContributorsData = data?.filter((item) => !item.assignment || !item.profile);
  const { selectedSquad: teamData, loading: teamLoading } = useOutletContext();
  const isExternalTeam = teamData?.teamType === TEAM_TYPES.external;

  useEffect(() => {
    if (isShowcase) {
      trackPage(TRACKING_PAGE_VIEWS[PAGES.squadActivityLog].page_name, TRACKING_PAGE_VIEWS[PAGES.squadActivityLog]);
    }
  }, []);

  useEffect(() => {
    if (teamData?.gitRepositories) {
      setGitRepositories(teamData.gitRepositories);
    }
  }, [teamData?.gitRepositories]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.Leaderboard.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            subtitle={<FormattedMessage id="Squads.Squad.Leaderboard.Title" />}
            actionBar={
              <ScopedRepositoriesFilter
                id={id}
                gitRepositories={gitRepositories}
                onChange={(_name, value) => setSelectedGitRepositories(value)}
                value={selectedGitRepositories}
                disabled={!gitRepositories}
                loading={loading}
              />
            }
            {...props}
          />
        </Grid.col>
        {((data && data.length > 0) || loading) && (
          <>
            {!isExternalTeam && (teamMembersData.length > 0 || loading) && (
              <Grid.col start={1} end={13}>
                <AllTimeActivityLogLayout data={teamMembersData} loading={loading || teamLoading} error={error} />
              </Grid.col>
            )}
            {guestContributorsData.length > 0 && (
              <Grid.col start={1} end={13}>
                <AllTimeActivityLogLayout
                  titleId="Squads.Squad.Engineering.ActivityLog.CardTitleGuests"
                  data={guestContributorsData}
                  loading={loading || teamLoading}
                  error={error}
                  headerTitleId="Dashboard.ActivityLog.ActivityLogLayout.ColumnHeaderGuestContributor"
                  hideHeader={isExternalTeam}
                />
              </Grid.col>
            )}
          </>
        )}
        {!loading && (!data || data.length === 0) && (
          <Grid.col start={1} end={13}>
            <Card>
              <MessagesNoResults
                title={!teamData?.gitRepositories ? "Squads.NoReposTitle" : "Squads.NoActivityTitle"}
                description={!teamData?.gitRepositories ? "Squads.NoReposDescription" : "Squads.NoActivityDescription"}
              />
            </Card>
          </Grid.col>
        )}
      </Grid>
    </>
  );
};

export default Leaderboard;
