import { gql } from "@apollo/client";
import { CORE_MISSION_FIELDS } from "graphql/fragments";

const ADD_MISSION = gql`
  ${CORE_MISSION_FIELDS}
  mutation AddMission($input: AddMissionInput!) {
    addMission(input: $input) {
      mission {
        ...MissionFields
      }
    }
  }
`;

const UPDATE_MISSION = gql`
  ${CORE_MISSION_FIELDS}
  mutation UpdateMission($input: UpdateMissionInput!) {
    updateMission(input: $input) {
      mission {
        ...MissionFields
      }
    }
  }
`;

export { ADD_MISSION, UPDATE_MISSION };
