import { gql } from "@apollo/client";
import { OLD_LOCATION_FIELDS, CORE_PROFILE_FIELDS } from "graphql/fragments";

const GET_TERM_SHEET_TEAM_STATE = gql`
  ${CORE_PROFILE_FIELDS}
  ${OLD_LOCATION_FIELDS}
  query getTermSheetTeamState($teamId: ID!, $upto: ISO8601DateTime) {
    termSheetTeamState(teamId: $teamId, upto: $upto) {
      id
      uuid
      name
      slug
      organization {
        id
        name
        slug
      }
      hasStarted
      startedAt
      isPaused
      lastPausedAt
      lastResumedAt
      hasEnded
      endedAt
      lastModifiedAt
      billingAccountId
      billingAccountName
      billingAccountCurrency
      billingInterval
      teamMembers {
        ... {
          profile {
            ...ProfileFields
            ...OldLocationFields
          }
          archetype
          contractType
          buyingQuantity
          buyingRate
          buyingCurrency
          commissionPercent
          sellingBasePrice
          sellingCurrency
          sellingDiscount
        }
      }
    }
  }
`;

const GET_TERM_SHEET_FUTURE_EVENTS = gql`
  query getTermSheetEvents($teamId: ID!, $upto: ISO8601Date, $first: Int, $last: Int, $after: String, $before: String) {
    termSheetTeamEvents(teamId: $teamId, upto: $upto) {
      futureEvents(first: $first, last: $last, after: $after, before: $before) {
        nodes {
          id
          key
          impersonator {
            id
          }
          payload
          type
          createdAt
          updatedAt
          user {
            id
          }
          uuid
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        edges {
          cursor
          node {
            id
          }
        }
        currentOffset
        totalCount
      }
    }
  }
`;

const GET_TERM_SHEET_PAST_EVENTS = gql`
  query getTermSheetEvents($teamId: ID!, $upto: ISO8601Date, $first: Int, $last: Int, $after: String, $before: String) {
    termSheetTeamEvents(teamId: $teamId, upto: $upto) {
      pastEvents(first: $first, last: $last, after: $after, before: $before) {
        nodes {
          id
          key
          impersonator {
            id
          }
          payload
          type
          createdAt
          updatedAt
          user {
            id
          }
          uuid
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        edges {
          cursor
          node {
            id
          }
        }
        currentOffset
        totalCount
      }
    }
  }
`;

const GET_TERM_SHEET_SCENARIOS = gql`
  ${CORE_PROFILE_FIELDS}
  ${OLD_LOCATION_FIELDS}
  query GetTeamScenarios($filters: TeamScenarioFilter, $first: Int, $last: Int, $before: String, $after: String) {
    teamScenarios(filters: $filters, first: $first, last: $last, before: $before, after: $after) {
      nodes {
        id
        name
        formattedName
        payload
        state
        type
        billingAccount {
          id
          name
          currencyCode
        }
        termSheetTeamState {
          billingAccountCurrency
          billingAccountId
          billingAccountName
          billingInterval
          endedAt
          hasEnded
          hasStarted
          id
          isPaused
          lastModifiedAt
          lastPausedAt
          lastResumedAt
          name
          organization {
            id
            name
            slug
          }
          slug
          startedAt
          teamMembers {
            ... {
              profile {
                ...ProfileFields
                ...OldLocationFields
              }
              archetype
              contractType
              buyingQuantity
              buyingRate
              buyingCurrency
              commissionPercent
              sellingBasePrice
              sellingCurrency
              sellingDiscount
            }
          }
          uuid
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
      currentOffset
      totalCount
    }
  }
`;

export {
  GET_TERM_SHEET_TEAM_STATE,
  GET_TERM_SHEET_FUTURE_EVENTS,
  GET_TERM_SHEET_PAST_EVENTS,
  GET_TERM_SHEET_SCENARIOS,
};
