import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import Form, { SIZE, TYPE } from "components/Form";
import { ICON_TYPE } from "components/Icon";
import { ARCHETYPES, ROLES } from "constants/index";
import { margins } from "style";

/**
 * FormRole
 *
 * @params {Function}   onChange
 * @params {Function}   onSubmit
 * @params {Object}     values
 */
const FormRole = ({ onChange, onSubmit, values }) => {
  const [initialValues] = useState(values);

  return (
    <Form
      data={FORM_DATA}
      onChange={onChange}
      onSubmit={onSubmit}
      initialValues={initialValues}
      marginSize={margins.none}
      saveButton={{
        show: true,
        labelId: "Global.Continue",
        disabled: !values?.role,
        props: {
          css: css`
            width: 100%;
          `,
        },
      }}
    />
  );
};

const ROLE_OPTIONS = [
  {
    value: ROLES.developer,
    icon: ICON_TYPE.code,
    archetype: ARCHETYPES.contributor.id,
  },
  {
    value: ROLES.tech_lead,
    icon: ICON_TYPE.mentorship,
    archetype: ARCHETYPES.tech_lead.id,
  },
  {
    value: ROLES.squad_lead,
    icon: ICON_TYPE.mentorship,
    archetype: ARCHETYPES.squad_lead.id,
  },
  {
    value: ROLES.business_analyst,
    icon: ICON_TYPE.lightBulb,
    archetype: ARCHETYPES.contributor.id,
  },
  {
    value: ROLES.designer,
    icon: ICON_TYPE.eye,
    archetype: ARCHETYPES.contributor.id,
  },
  {
    value: ROLES.qa,
    icon: ICON_TYPE.performance,
    archetype: ARCHETYPES.contributor.id,
  },
];

const FORM_DATA = [
  {
    style: css`
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
    `,
    items: [
      {
        label: <FormattedMessage id="Signup.FormDiscipline.FormLabelRole" />,
        type: TYPE.h4,
        size: SIZE.xlarge,
      },
      {
        type: TYPE.boxSelector,
        size: SIZE.xlarge,
        properties: {
          name: "role",
          type: "text",
          required: true,
          options: ROLE_OPTIONS.map(({ value, icon }) => ({
            title: <FormattedMessage id={`Signup.FormDiscipline.FormItemRoleTitle_${value}`} />,
            body: <FormattedMessage id={`Signup.FormDiscipline.FormItemRoleBody_${value}`} />,
            icon,
            value,
          })),
        },
      },
    ],
  },
];

FormRole.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
};

export { ROLE_OPTIONS };

export default FormRole;
