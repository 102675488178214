import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { useAuth } from "hooks";
import { PageWrapper, Grid } from "components/Containers";
import Card from "components/Card";
import {
  SquadDetails,
  SquadMembers,
  SquadVacationDays,
  SquadActivityLogLayout,
  SquadMetrics,
  SquadDynamics,
} from "components/Squads";
import { SquadInsights } from "components/Squads/Insights";
import PageTitle from "components/PageTitle";
import { formatCurrency, formatNumber } from "utils";
import { colors, fontSize } from "style";
import { TERM_SHEET_TYPES } from "constants";
import { PrimaryLink } from "components/Links";
import { LINKS, TEAM_TYPES } from "constants/index";
import { SignalNotice, useRenderSignalNotice } from "components/Squads/settings";
import { Spinner } from "components/Loader";

/**
 * Profile
 */
const Profile = (props) => {
  const intl = useIntl();
  const { selectedSquad: data, loading, error, id, teamPermissions } = useOutletContext();
  const { isShowcase, isClient, isSignalClient, isAdmin } = useAuth();
  const componentProps = { data, loading, error, id, teamPermissions, ...props };
  const shouldRenderSignalNotice = useRenderSignalNotice({ teamData: data, teamLoading: loading });

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.Profile.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              subtitle={<FormattedMessage id="Squads.Profile.Title" />}
              actionBar={
                (isShowcase || isClient || isSignalClient) && (
                  <span>
                    <FormattedMessage
                      id="Squads.Profile.CTA"
                      values={{
                        link: (
                          <PrimaryLink to={LINKS.showcase_search} withMoreIcon routerEnabled>
                            <FormattedMessage id="Squads.Profile.CTA.Link" />
                          </PrimaryLink>
                        ),
                      }}
                    />
                  </span>
                )
              }
            />
          </Grid.col>
          {shouldRenderSignalNotice && (
            <Grid.col start={1} end={13}>
              <SignalNotice teamData={data} />
            </Grid.col>
          )}

          {loading ? (
            <Grid.col start={1} end={13}>
              <Spinner size={`1.5rem`} />
            </Grid.col>
          ) : data?.teamType === TEAM_TYPES.external ? (
            <ExternalSquadLayout {...componentProps} />
          ) : isShowcase || isClient ? (
            <ShowcaseLayout {...componentProps} />
          ) : isAdmin ? (
            <AdminLayout {...componentProps} />
          ) : isSignalClient ? (
            <SignalClientLayout {...componentProps} />
          ) : (
            <MemberLayout {...componentProps} />
          )}
        </Grid>
      </PageWrapper>
    </>
  );
};

/**
 * SignalClientLayout
 *
 * @param {Object}  data
 * @param {Boolean} loading
 * @param {Boolean} error
 * @param {String}  id
 * @param {Object}  teamPermissions
 */
const SignalClientLayout = ({ data, loading, error, id, teamPermissions, ...props }) => {
  return (
    <>
      {!error && (
        <Grid.col start={1} end={13}>
          <Card {...props}>
            <SquadMetrics
              teamId={data?.id}
              teamSlug={data?.slug}
              loading={loading}
              organization={data?.organization}
              showAll={data?.teamType !== TEAM_TYPES.external}
              {...props}
            />
          </Card>
        </Grid.col>
      )}
      <Grid.col start={1} end={9}>
        <Grid>
          <Grid.col start={1} end={13}>
            <Card>
              <SquadActivityLogLayout teamId={id} slug={data?.slug} />
            </Card>
          </Grid.col>
          <Grid.col start={1} end={13}>
            <SquadMembersCard
              data={data}
              loading={loading}
              hideRate={true}
              hidePerformance={!teamPermissions?.canAccessEngineering}
              {...props}
            />
          </Grid.col>
        </Grid>
      </Grid.col>
      <Grid.col start={9} end={13}>
        <Grid>
          {teamPermissions?.canAccessInsights && (
            <Grid.col start={1} end={13}>
              <Card>
                <SquadInsights teamId={data?.id} />
              </Card>
            </Grid.col>
          )}
        </Grid>
      </Grid.col>
    </>
  );
};

/**
 * MemberLayout
 *
 * @param {Object}  data
 * @param {Boolean} loading
 * @param {Boolean} error
 * @param {Object}  teamPermissions
 */
const MemberLayout = ({ data, loading, error, teamPermissions, ...props }) => (
  <>
    <Grid.col start={1} end={9}>
      <Grid>
        {!error && (
          <Grid.col start={1} end={13}>
            <Card {...props}>
              <SquadMetrics
                teamId={data?.id}
                teamSlug={data?.slug}
                loading={loading}
                organization={data?.organization}
                showAll={false}
                {...props}
              />
            </Card>
          </Grid.col>
        )}
        <Grid.col start={1} end={13}>
          <SquadMembersCard
            data={data}
            loading={loading}
            hidePerformance={!teamPermissions?.canAccessEngineering}
            hideRate={true}
            {...props}
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <SquadVacationDays data={data?.assignments} loading={loading} {...props} />
          </Card>
        </Grid.col>
      </Grid>
    </Grid.col>
    <Grid.col start={9} end={13}>
      <Grid>
        <Grid.col start={1} end={13}>
          <Card>
            <SquadDetails data={data} loading={loading} {...props} />
          </Card>
        </Grid.col>
      </Grid>
    </Grid.col>
  </>
);

/**
 * AdminLayout
 *
 * @param {Object}  data
 * @param {Boolean} loading
 * @param {Boolean} error
 * @param {String}  id
 * @param {Object}  teamPermissions
 */
const AdminLayout = ({ data, loading, error, id, teamPermissions, ...props }) => (
  <>
    {!error && (
      <Grid.col start={1} end={13}>
        <Card {...props}>
          <SquadMetrics
            teamId={data?.id}
            teamSlug={data?.slug}
            loading={loading}
            organization={data?.organization}
            showAll={data?.teamType !== TEAM_TYPES.external}
            {...props}
          />
        </Card>
      </Grid.col>
    )}
    <Grid.col start={1} end={9}>
      <Grid>
        <Grid.col start={1} end={13}>
          <Card>
            <SquadActivityLogLayout teamId={id} slug={data?.slug} />
          </Card>
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <SquadDynamics data={data} loading={loading} />
          </Card>
        </Grid.col>
        <Grid.col start={1} end={13}>
          <SquadMembersCard
            data={data}
            loading={loading}
            hideRate={false}
            hidePerformance={!teamPermissions?.canAccessEngineering}
            {...props}
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <SquadVacationDays data={data?.assignments} loading={loading} {...props} />
          </Card>
        </Grid.col>
      </Grid>
    </Grid.col>
    <Grid.col start={9} end={13}>
      <Grid>
        <Grid.col start={1} end={13}>
          <Card>
            <SquadDetails data={data} loading={loading} {...props} />
          </Card>
        </Grid.col>
        {teamPermissions?.canAccessInsights && (
          <Grid.col start={1} end={13}>
            <Card>
              <SquadInsights teamId={data?.id} />
            </Card>
          </Grid.col>
        )}
      </Grid>
    </Grid.col>
  </>
);

/**
 * ShowcaseLayout
 *
 * @param {Object}  data
 * @param {Boolean} loading
 * @param {Boolean} error
 * @param {String}  id
 * @param {Object}  teamPermissions
 */
const ShowcaseLayout = ({ data, loading, error, id, teamPermissions, ...props }) => (
  <>
    {!error && (
      <Grid.col start={1} end={13}>
        <Card {...props}>
          <SquadMetrics
            teamId={data?.id}
            teamSlug={data?.slug}
            loading={loading}
            organization={data?.organization}
            showAll={data?.teamType !== TEAM_TYPES.external}
            {...props}
          />
        </Card>
      </Grid.col>
    )}
    <Grid.col start={1} end={9}>
      <Grid>
        <Grid.col start={1} end={13}>
          <Card>
            <SquadActivityLogLayout teamId={id} slug={data?.slug} />
          </Card>
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <SquadDynamics data={data} loading={loading} />
          </Card>
        </Grid.col>
        <Grid.col start={1} end={13}>
          <SquadMembersCard
            data={data}
            loading={loading}
            hideRate={false}
            hidePerformance={!teamPermissions?.canAccessEngineering}
            {...props}
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <SquadVacationDays data={data?.assignments} loading={loading} {...props} />
          </Card>
        </Grid.col>
      </Grid>
    </Grid.col>
    <Grid.col start={9} end={13}>
      <Grid>
        {teamPermissions?.canAccessInsights && (
          <Grid.col start={1} end={13}>
            <Card>
              <SquadInsights teamId={data?.id} />
            </Card>
          </Grid.col>
        )}
        <Grid.col start={1} end={13}>
          <Card>
            <SquadDetails data={data} loading={loading} {...props} />
          </Card>
        </Grid.col>
      </Grid>
    </Grid.col>
  </>
);

/**
 * SquadMembersCard
 *
 * @param {Object}    data
 * @param {Boolean}   loading
 * @param {Boolean}   hideRate
 */
const SquadMembersCard = ({ data, loading, hideRate, ...props }) => (
  <Card>
    <SquadMembers
      data={data}
      loading={loading}
      hideRate={hideRate}
      hidePerformance={true} /* Hide performance always until filtering feature is complete */
      subtitle={
        !loading &&
        data?.totalSellingCost &&
        data?.totalCapacity && (
          <span css={styles.subtitle}>
            <FormattedMessage
              id="Squads.Squad.Profile.SquadMembers.Subtitle"
              values={{
                totalCapacity: <b>{formatNumber(data?.totalCapacity)}</b>,
                totalSellingCost: <b>{formatCurrency(data?.totalSellingCost, data?.sellingCurrency)}</b>,
                teamVersionType: TERM_SHEET_TYPES[data?.teamVersionType],
              }}
            />
          </span>
        )
      }
      {...props}
    />
  </Card>
);

/**
 * ExternalSquadLayout
 *
 * @param {Object}  data
 * @param {Boolean} loading
 * @param {String}  id
 */
const ExternalSquadLayout = ({ data, loading, id, ...props }) => (
  <>
    <Grid.col start={1} end={13}>
      <Card {...props}>
        <SquadMetrics
          teamId={data?.id}
          teamSlug={data?.slug}
          loading={loading}
          organization={data?.organization}
          showAll={data?.teamType !== TEAM_TYPES.external}
          {...props}
        />
      </Card>
    </Grid.col>
    <Grid.col start={1} end={13}>
      <Card>
        <SquadActivityLogLayout teamId={id} slug={data?.slug} />
      </Card>
    </Grid.col>
  </>
);

const styles = {
  subtitle: css`
    font-size: ${fontSize.normal};
    color: ${colors.purpleRainDark2};
  `,
};

SquadMembersCard.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  hideRate: PropTypes.bool,
};

MemberLayout.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  teamPermissions: PropTypes.object,
};

AdminLayout.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.string,
  teamPermissions: PropTypes.object,
};

ShowcaseLayout.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.string,
  teamPermissions: PropTypes.object,
};

SignalClientLayout.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.string,
  teamPermissions: PropTypes.object,
};

ExternalSquadLayout.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  id: PropTypes.string,
};

export default Profile;
