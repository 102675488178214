import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { COUNTRIES } from "constants";

/**
 * User Archetype
 *
 * @param {Object}    data
 * @param {Boolean}   abbreviate // show countryCode and flag only
 * @param {Boolean}   hideFlag
 * @param {Boolean}   hideState // will hide the state
 */
const UserLocation = ({ data, abbreviate, hideFlag, hideState }) => {
  if (!data) return null;

  const city = data?.location?.city;
  const country = data?.location?.country;
  const code = data?.location?.country_code;
  const label = hideState ? (city ? `${city}, ${country}` : country) : data?.location?.label;
  const countryFlag = data?.location?.country_flag || COUNTRIES.find((item) => item.value === code)?.flag;

  return (
    <FormattedMessage
      id={abbreviate ? `User.Location.Abbreviated` : getCountryText(data)}
      values={{
        city,
        country,
        countryFlag: hideFlag ?? countryFlag,
        code: String(code)?.toUpperCase(),
        label,
      }}
    />
  );
};

function getCountryText(data) {
  const nl = data?.location;

  if (nl?.place_id || nl?.placeId) {
    return "Profile.ProfileContact.DataLocation";
  } else if (nl?.city && nl?.country) {
    return "Profile.ProfileContact.DataLocationCityAndCountry";
  } else if (nl?.country) {
    return "Profile.ProfileContact.DataLocationCountryOnly";
  } else if (nl?.city) {
    return "Profile.ProfileContact.DataLocationCityOnly";
  } else {
    return "Profile.ProfileContact.LocationNotProvided";
  }
}

UserLocation.propTypes = {
  data: PropTypes.object,
  abbreviate: PropTypes.bool,
  hideFlag: PropTypes.bool,
  hideState: PropTypes.bool,
};

export default UserLocation;
