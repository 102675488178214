import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import Card from "components/Card";
import { ActivityLogHeader, ActivityLogBody } from "components/ActivityLog";
import { colors } from "style";
import { TEAM_ACTIVITY_TYPES } from "constants";
import Legend from "components/Legend";
import { Header } from "components/DisplayBox";
import { H3 } from "components/Text";
import CheckBox from "components/Form/CheckBox";

/**
 * ActivityLogLayout
 *
 * @param {Object}     data
 * @param {Object}     days
 * @param {String}     titleId
 * @param {String}     headerTitleId
 * @param {Boolean}    isGuest
 * @param {Boolean}    showFilter
 * @param {Boolean}    hideHeader
 * @param {Boolean}    loading
 */
const ActivityLogLayout = ({
  data,
  days,
  titleId,
  headerTitleId,
  isGuest,
  showFilter,
  hideHeader,
  loading,
  ...props
}) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <StyledCard {...props}>
      {!hideHeader && (
        <Header>
          <H3 loading={loading}>
            <FormattedMessage id={titleId} />
          </H3>
          {showFilter && (
            <div>
              <CheckBox
                value={showAll}
                label={
                  <FormattedMessage
                    id={`ActivityLog.ActivityLogLayout.CheckBoxLabel`}
                    values={{
                      type: (
                        <FormattedMessage id={`ActivityLog.ActivityLogLayout.Type${isGuest ? `Guest` : `Member`}`} />
                      ),
                    }}
                  />
                }
                onChange={(n, v) => setShowAll(v)}
              />
            </div>
          )}
        </Header>
      )}
      <Legend
        data={Object.values(TEAM_ACTIVITY_TYPES)?.map((type, index) => ({
          name: <FormattedMessage id={`Dashboard.ActivityLog.ActivityLogLayout.Legend.${type}`} />,
          color: COLOR_TYPES[index],
        }))}
      />
      <ActivityLogHeader days={days} titleId={headerTitleId} />
      <ActivityLogBody data={data} showAll={showFilter ? showAll : true} loading={loading} {...props} />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  overflow: visible;
`;

export const COLOR_TYPES = [
  colors.purpleRainBase,
  colors.pinkPantherBase,
  colors.orangeCountyBase,
  colors.blueVelvetBase,
  colors.green,
];

ActivityLogLayout.defaultProps = {
  titleId: "Squads.Squad.Engineering.ActivityLog.CardTitle",
  hideHeader: false,
};

ActivityLogLayout.propTypes = {
  data: PropTypes.array,
  days: PropTypes.array,
  titleId: PropTypes.string,
  headerTitleId: PropTypes.string,
  isGuest: PropTypes.bool,
  showFilter: PropTypes.bool,
  hideHeader: PropTypes.bool,
  loading: PropTypes.bool,
};

export default ActivityLogLayout;
