import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { fontSize, colors } from "style";
import { TextInput } from "components/Form";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { Row } from "components/Containers";

/**
 * MinMaxInput
 *
 * @param {String}   name
 * @param {Number}   min
 * @param {Object}   value
 * @param {Number}   max
 * @param {Function} onChange
 * @param {Boolean}  isClearable
 */
const MinMaxInput = ({ name, min, max, value, onChange, isClearable, ...props }) => {
  const [localMin, setLocalMin] = useState(value?.min || min);
  const [localMax, setLocalMax] = useState(value?.max || max);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (value) {
      setLocalMin(value?.min || "");
      setLocalMax(value?.max || "");
    }
  }, [value]);

  useEffect(() => {
    if (focus) return;

    onChange(name, { min: localMin, max: localMax });
  }, [localMin, localMax, name, focus]);

  const clear = (name) => {
    if (name === "min") {
      setLocalMin("");
    } else {
      setLocalMax("");
    }
  };

  return (
    <div css={styles.container}>
      <Row gap={`0rem`}>
        <div css={styles.input_container}>
          <TextInput
            name="min"
            value={localMin}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={(name, val) => setLocalMin(val)}
            placeholder="Min"
            css={[styles.input, styles.left]}
            {...props}
          />
          {isClearable && localMin && (
            <Icon type={ICON_TYPE.close} size={ICON_SIZE.large} css={styles.clearIcon} onClick={() => clear("min")} />
          )}
        </div>
        <div css={styles.divider} />
        <div css={styles.input_container}>
          <TextInput
            name="max"
            value={localMax}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={(name, val) => setLocalMax(val)}
            placeholder="Max"
            css={[styles.input, styles.right]}
            {...props}
          />
          {isClearable && localMax && (
            <Icon type={ICON_TYPE.close} size={ICON_SIZE.large} css={styles.clearIcon} onClick={() => clear("max")} />
          )}
        </div>
      </Row>
    </div>
  );
};

const styles = {
  container: css`
    position: relative;
    order: 1;
  `,
  input_container: css`
    display: flex;
    position: relative;
    align-items: center;
    flex: 1;
  `,
  divider: css`
    width: 1px;
    height: 4rem;
    background: ${colors.grayAnatomyLight3};
  `,
  input: css`
    width: 100%;
    font-size: ${fontSize.xsmall};
    padding-right: 3.5rem;
  `,
  left: css`
    border-right-color: transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `,
  right: css`
    border-left-color: transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `,
  hiddenSubmit: css`
    display: none;
  `,
  clearIcon: css`
    position: absolute;
    right: 1rem;
    top: 50%;
    margin-top: -1rem;
    color: ${colors.grayAnatomyLight2};
    cursor: pointer;

    &:hover {
      color: ${colors.grayAnatomyLight1};
    }
  `,
};

MinMaxInput.propTypes = {
  name: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isClearable: PropTypes.bool,
};

export default MinMaxInput;
