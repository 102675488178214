/** @jsx jsx */
import styled from "@emotion/styled";
import { colors, fontSize } from "style";

/**
 * Label
 */
const Link = styled.a`
  color: ${colors.grayAnatomyLight1};
  display: flex;
  align-items: center;
  font-size: ${fontSize.small};
  font-weight: 600;
  line-height: 2.4rem;
`;

export default Link;
