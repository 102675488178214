import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import { FormattedMessage } from "react-intl";

/**
 * Show a generic error message
 *
 * @params {String}  messageId
 */
const Error = ({ messageId }) => (
  <div data-no-results css={style}>
    <FormattedMessage id={messageId ? messageId : `Global.Error`} />
  </div>
);

const style = css`
  font-size: ${fontSize.xlarge};
  width: 100%;
  text-align: center;
  color: ${colors.grayAnatomyLight2};
  padding: 2rem 0;
  font-weight: 300;
`;

Error.propTypes = {
  messageId: PropTypes.string,
};

export default Error;
