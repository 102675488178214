import { gql } from "@apollo/client";
import { OLD_LOCATION_FIELDS, CORE_PROFILE_FIELDS } from "graphql/fragments";

const ADD_TERM_SHEET_SCENARIO = gql`
  ${CORE_PROFILE_FIELDS}
  ${OLD_LOCATION_FIELDS}
  mutation AddTeamScenario($input: AddTeamScenarioInput!) {
    addTeamScenario(input: $input) {
      teamScenario {
        id
        name
        formattedName
        payload
        state
        type
        billingAccount {
          id
          name
          currencyCode
        }
        termSheetTeamState {
          teamMembers {
            ... {
              profile {
                ...ProfileFields
                ...OldLocationFields
              }
              archetype
              contractType
              buyingQuantity
              buyingRate
              buyingCurrency
              commissionPercent
              sellingBasePrice
              sellingCurrency
              sellingDiscount
            }
          }
          uuid
        }
      }
    }
  }
`;

const UPDATE_TERM_SHEET_SCENARIO = gql`
  mutation UpdateTeamScenario($input: UpdateTeamScenarioInput!) {
    updateTeamScenario(input: $input) {
      teamScenario {
        id
        name
        formattedName
        payload
        state
        type
        billingAccount {
          id
          name
          currencyCode
        }
      }
    }
  }
`;

const REMOVE_TERM_SHEET_SCENARIO = gql`
  mutation RemoveTeamScenario($input: RemoveTeamScenarioInput!) {
    removeTeamScenario(input: $input) {
      id
    }
  }
`;

const SCHEDULE_TERM_SHEET_SCENARIO = gql`
  mutation scheduleTeamScenario($input: ScheduleTeamScenarioInput!) {
    scheduleTeamScenario(input: $input) {
      teamScenario {
        id
      }
    }
  }
`;

export {
  ADD_TERM_SHEET_SCENARIO,
  UPDATE_TERM_SHEET_SCENARIO,
  REMOVE_TERM_SHEET_SCENARIO,
  SCHEDULE_TERM_SHEET_SCENARIO,
};
