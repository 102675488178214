import { gql } from "@apollo/client";

const assessmentFields = `
  id
  fieldsKey
  valuesKey
  labelKey
`;

const questionFields = `
  id
  wording
  type
  description
  allowAddMore
  options {
    id
    label
    value
    description
    isRemovable
    size
  }        
  properties {
    name
    required
    labelKey
    valueKey
    queryKey
    mutationKey
    parentDependent
    keepLabelInline
    header
    answerablePath
    charactersLimit
  }
`;

const coreAssessmentFields = `
  uuid
  displayName
  type
`;

const requirementFields = `
  label
  value
  description
  explanation
`;

const QUESTION_FIELDS = gql`
  fragment QuestionFields on AssessmentQuestion {
    ${questionFields}
  }
`;

const ASSESSMENT_FIELDS = gql`
  fragment AssessmentFields on Assessment {
    ${assessmentFields}
  }
`;

const CORE_ASSESSMENT_FIELDS = gql`
  fragment CoreAssessmentFields on AssessmentProperties {
    ${coreAssessmentFields}
  }
`;

const REQUIREMENTS_FIELDS = gql`
  fragment RequirementsFields on AssessmentProperties {
    uuid
    type
    requirements {
      ${requirementFields}
    }
  }
`;

export { ASSESSMENT_FIELDS, QUESTION_FIELDS, CORE_ASSESSMENT_FIELDS, REQUIREMENTS_FIELDS };
