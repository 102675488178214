import FeaturedBadge from "./Featured";
import Billed1kBadge from "./Billed1k";
import Billed2kBadge from "./Billed2k";
import Billed5kBadge from "./Billed5k";
import Billed10kBadge from "./Billed10k";
import FoundingMember from "./FoundingMember";
import MissionAccomplished1 from "./MissionAccomplished1";
import MissionAccomplished2 from "./MissionAccomplished2";
import MissionAccomplished3 from "./MissionAccomplished3";
import MissionAccomplished4 from "./MissionAccomplished4";
import MissionAccomplished5 from "./MissionAccomplished5";
import MissionAccomplished6 from "./MissionAccomplished6";
import MissionAccomplished7 from "./MissionAccomplished7";
import MissionAccomplished8 from "./MissionAccomplished8";
import MissionAccomplished9 from "./MissionAccomplished9";
import MissionAccomplished10 from "./MissionAccomplished10";
import MissionAccomplished11 from "./MissionAccomplished11";
import MissionAccomplished12 from "./MissionAccomplished12";
import MissionAccomplished13 from "./MissionAccomplished13";
import MissionAccomplished14 from "./MissionAccomplished14";
import MissionAccomplished15 from "./MissionAccomplished15";
import MissionAccomplished16 from "./MissionAccomplished16";
import MissionAccomplished17 from "./MissionAccomplished17";
import MissionAccomplished18 from "./MissionAccomplished18";
import MissionAccomplished19 from "./MissionAccomplished19";
import MissionAccomplished20 from "./MissionAccomplished20";
import MissionAccomplished21 from "./MissionAccomplished21";
import MissionAccomplished22 from "./MissionAccomplished22";
import MissionAccomplished23 from "./MissionAccomplished23";
import MissionAccomplished24 from "./MissionAccomplished24";
import MissionAccomplished25 from "./MissionAccomplished25";
import MissionAccomplished26 from "./MissionAccomplished26";
import MissionAccomplished27 from "./MissionAccomplished27";
import MissionAccomplished28 from "./MissionAccomplished28";
import MissionAccomplished29 from "./MissionAccomplished29";
import MissionAccomplished30 from "./MissionAccomplished30";
import CustomerSatisfaction2023 from "./CustomerSatisfaction2023";
import CustomerSatisfaction2024 from "./CustomerSatisfaction2024";
import CustomerSatisfaction2025 from "./CustomerSatisfaction2025";
import CustomerSatisfaction2026 from "./CustomerSatisfaction2026";
import CommunityChampion2023 from "./CommunityChampion2023";
import CommunityChampion2024 from "./CommunityChampion2024";
import CommunityChampion2025 from "./CommunityChampion2025";
import CommunityChampion2026 from "./CommunityChampion2026";

export { default as FeaturedBadge } from "./Featured";
export { default as Billed1kBadge } from "./Billed1k";
export { default as Billed2kBadge } from "./Billed2k";
export { default as Billed5kBadge } from "./Billed5k";
export { default as Billed10kBadge } from "./Billed10k";
export { default as FoundingMember } from "./FoundingMember";
export { default as MissionAccomplished1 } from "./MissionAccomplished1";
export { default as MissionAccomplished2 } from "./MissionAccomplished2";
export { default as MissionAccomplished3 } from "./MissionAccomplished3";
export { default as MissionAccomplished4 } from "./MissionAccomplished4";
export { default as MissionAccomplished5 } from "./MissionAccomplished5";
export { default as MissionAccomplished6 } from "./MissionAccomplished6";
export { default as MissionAccomplished7 } from "./MissionAccomplished7";
export { default as MissionAccomplished8 } from "./MissionAccomplished8";
export { default as MissionAccomplished9 } from "./MissionAccomplished9";
export { default as MissionAccomplished10 } from "./MissionAccomplished10";
export { default as MissionAccomplished11 } from "./MissionAccomplished11";
export { default as MissionAccomplished12 } from "./MissionAccomplished12";
export { default as MissionAccomplished13 } from "./MissionAccomplished13";
export { default as MissionAccomplished14 } from "./MissionAccomplished14";
export { default as MissionAccomplished15 } from "./MissionAccomplished15";
export { default as MissionAccomplished16 } from "./MissionAccomplished16";
export { default as MissionAccomplished17 } from "./MissionAccomplished17";
export { default as MissionAccomplished18 } from "./MissionAccomplished18";
export { default as MissionAccomplished19 } from "./MissionAccomplished19";
export { default as MissionAccomplished20 } from "./MissionAccomplished20";
export { default as MissionAccomplished21 } from "./MissionAccomplished21";
export { default as MissionAccomplished22 } from "./MissionAccomplished22";
export { default as MissionAccomplished23 } from "./MissionAccomplished23";
export { default as MissionAccomplished24 } from "./MissionAccomplished24";
export { default as MissionAccomplished25 } from "./MissionAccomplished25";
export { default as MissionAccomplished26 } from "./MissionAccomplished26";
export { default as MissionAccomplished27 } from "./MissionAccomplished27";
export { default as MissionAccomplished28 } from "./MissionAccomplished28";
export { default as MissionAccomplished29 } from "./MissionAccomplished29";
export { default as MissionAccomplished30 } from "./MissionAccomplished30";
export { default as CustomerSatisfaction2023 } from "./CustomerSatisfaction2023";
export { default as CustomerSatisfaction2024 } from "./CustomerSatisfaction2024";
export { default as CustomerSatisfaction2025 } from "./CustomerSatisfaction2025";
export { default as CustomerSatisfaction2026 } from "./CustomerSatisfaction2026";
export { default as CommunityChampion2023 } from "./CommunityChampion2023";
export { default as CommunityChampion2024 } from "./CommunityChampion2024";
export { default as CommunityChampion2025 } from "./CommunityChampion2025";
export { default as CommunityChampion2026 } from "./CommunityChampion2026";

const BADGE_TYPES = {
  ["Featured"]: FeaturedBadge,
  ["Founding Member"]: FoundingMember,
  ["Billed Hours 1K"]: Billed1kBadge,
  ["Billed Hours 2K"]: Billed2kBadge,
  ["Billed Hours 5K"]: Billed5kBadge,
  ["Billed Hours 10K"]: Billed10kBadge,
  ["Mission Accomplished 1"]: MissionAccomplished1,
  ["Mission Accomplished 2"]: MissionAccomplished2,
  ["Mission Accomplished 3"]: MissionAccomplished3,
  ["Mission Accomplished 4"]: MissionAccomplished4,
  ["Mission Accomplished 5"]: MissionAccomplished5,
  ["Mission Accomplished 6"]: MissionAccomplished6,
  ["Mission Accomplished 7"]: MissionAccomplished7,
  ["Mission Accomplished 8"]: MissionAccomplished8,
  ["Mission Accomplished 9"]: MissionAccomplished9,
  ["Mission Accomplished 10"]: MissionAccomplished10,
  ["Mission Accomplished 11"]: MissionAccomplished11,
  ["Mission Accomplished 12"]: MissionAccomplished12,
  ["Mission Accomplished 13"]: MissionAccomplished13,
  ["Mission Accomplished 14"]: MissionAccomplished14,
  ["Mission Accomplished 15"]: MissionAccomplished15,
  ["Mission Accomplished 16"]: MissionAccomplished16,
  ["Mission Accomplished 17"]: MissionAccomplished17,
  ["Mission Accomplished 18"]: MissionAccomplished18,
  ["Mission Accomplished 19"]: MissionAccomplished19,
  ["Mission Accomplished 20"]: MissionAccomplished20,
  ["Mission Accomplished 21"]: MissionAccomplished21,
  ["Mission Accomplished 22"]: MissionAccomplished22,
  ["Mission Accomplished 23"]: MissionAccomplished23,
  ["Mission Accomplished 24"]: MissionAccomplished24,
  ["Mission Accomplished 25"]: MissionAccomplished25,
  ["Mission Accomplished 26"]: MissionAccomplished26,
  ["Mission Accomplished 27"]: MissionAccomplished27,
  ["Mission Accomplished 28"]: MissionAccomplished28,
  ["Mission Accomplished 29"]: MissionAccomplished29,
  ["Mission Accomplished 30"]: MissionAccomplished30,
  ["Customer Satisfaction Award 2023"]: CustomerSatisfaction2023,
  ["Customer Satisfaction Award 2024"]: CustomerSatisfaction2024,
  ["Customer Satisfaction Award 2025"]: CustomerSatisfaction2025,
  ["Customer Satisfaction Award 2026"]: CustomerSatisfaction2026,
  ["Community Champion 2023"]: CommunityChampion2023,
  ["Community Champion 2024"]: CommunityChampion2024,
  ["Community Champion 2025"]: CommunityChampion2025,
  ["Community Champion 2026"]: CommunityChampion2026,
};
/*
TODO
"Outstanding Performance 2023"
"Outstanding Performance 2024"
"Outstanding Performance 2025"
"Outstanding Performance 2026"
*/

export { BADGE_TYPES };
