import { gql } from "@apollo/client";

const GET_ADMINS = gql`
  query GetAdmins($filters: UserFilter, $first: Int, $last: Int, $after: String, $before: String) {
    admins(filters: $filters, first: $first, last: $last, after: $after, before: $before) {
      nodes {
        id
        name
      }
    }
  }
`;

export { GET_ADMINS };
