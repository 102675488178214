import React from "react";
import PropTypes from "prop-types";
import { BrandedButton, TertiaryButton } from "components/Buttons";
import useTeamBuilder from "hooks/useTeamBuilder";
import { FormattedMessage } from "react-intl";
import { Spinner } from "components/Loader";

/**
 * TeamAddButton
 *
 * @param {String}      id
 */
const TeamAddButton = ({ id }) => {
  const { handleAddProfile, handleRemoveProfile, isProfileAdded, adding, removing } = useTeamBuilder({ profileId: id });
  const Button = isProfileAdded ? TertiaryButton : BrandedButton;

  return (
    <Button
      disabled={adding || removing}
      onClick={isProfileAdded ? () => handleRemoveProfile(id) : () => handleAddProfile(id)}
    >
      {adding || removing ? (
        <Spinner size={`2rem`} />
      ) : (
        <FormattedMessage id={`Teams.TeamAddButton.${isProfileAdded ? `Remove` : `Add`}`} />
      )}
    </Button>
  );
};

TeamAddButton.propTypes = {
  id: PropTypes.string,
};

export default TeamAddButton;
