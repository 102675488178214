import { gql } from "@apollo/client";

const GET_INTERVIEWS = gql`
  query interviews($profileId: ID) {
    interviews(profileId: $profileId) {
      nodes {
        id
        uuid
        type
        serviceId
        name
        description
        active
        timeLimit
        videoUrl
        thumbnailUrl
        questions
        responsesCount
        questionsCount
        publishedAt
        archivedAt
        interviewResponse {
          id
          uuid
          slug
          type
          serviceId
          status
          notes
          answered
          completed
          publicUrl
          privateUrl
          thumbnailUrl
          answers
          answersCount
          startedAt
          completedAt
          approvedAt
          profileId
          interviewId
        }
      }
    }
  }
`;

export { GET_INTERVIEWS };
