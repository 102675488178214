import React from "react";
import { css } from "@emotion/react";
import { FormattedMessage, useIntl } from "react-intl";
import Form, { SIZE, TYPE } from "components/Form";
import { Col } from "components/Containers";
import { breakpoints, colors, fontSize, margins } from "style";
import { LINKS } from "constants/index";
import { PrimaryLink } from "components/Links";
import { REQUEST_PASSWORD_RESET } from "graphql/mutations";
import { MUTATION_TYPE, useToast } from "hooks";
import { useNavigate } from "react-router";

/**
 * ForgotPasswordForm
 */
const ForgotPasswordForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { toast } = useToast();

  /**
   * Handle RequestComplete
   */
  const handleComplete = () => {
    toast.success(<FormattedMessage id="Login.ForgotPasswordForm.Success" />);
    navigate(LINKS.login);
  };

  return (
    <Col>
      <span css={styles.title}>
        <FormattedMessage id="Login.ForgotPasswordForm.Title" />
      </span>
      <span css={styles.subtitle}>
        <FormattedMessage id="Login.ForgotPasswordForm.Subtitle" />
      </span>
      <Form
        data={FORM_DATA}
        initialValues={{
          redirectUrl: LINKS.login,
        }}
        saveButton={{
          show: true,
          labelId: intl.formatMessage({ id: "Login.ForgotPasswordForm.Button" }),
          props: {
            css: styles.button,
          },
        }}
        mutationData={{
          mutationName: {
            add: REQUEST_PASSWORD_RESET,
          },
          unWrapParams: true,
        }}
        mutationType={MUTATION_TYPE.add}
        onCompleted={handleComplete}
        marginSize={margins.none}
      />
      <span css={styles.already_member}>
        <FormattedMessage
          id="Login.ForgotPasswordForm.LoginLabel"
          values={{
            link: (
              <PrimaryLink href={LINKS.login} css={styles.link} routerEnabled>
                <FormattedMessage id="Login.ForgotPasswordForm.LoginLink" />
              </PrimaryLink>
            ),
          }}
        />
      </span>
    </Col>
  );
};

const styles = {
  title: css`
    color: ${colors.black};
    font-size: ${fontSize.xxlarge};
    margin-bottom: 1.5rem;
    font-weight: 700;
  `,
  subtitle: css`
    font-size: ${fontSize.normal};
    color: ${colors.grayAnatomyLight1};
    margin-bottom: 3rem;
    font-weight: 500;
  `,
  already_member: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyLight1};
    margin: 3rem auto 0 auto;
  `,
  link: css`
    @media (max-width: ${breakpoints.mobile}) {
      color: ${colors.purpleRainLight3};
    }
  `,
  button: css`
    width: 100%;
  `,
  form_container: css`
    width: 100%;
  `,
};

const FORM_DATA = [
  {
    style: styles.form_container,
    items: [
      {
        label: "Email",
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "email",
          autoFocus: true,
          required: true,
          type: "email",
        },
      },
    ],
  },
];

ForgotPasswordForm.propTypes = {};

export default ForgotPasswordForm;
