import React from "react";
import PropTypes from "prop-types";
import { ApolloProvider } from "@apollo/client";
import { IntlProvider } from "react-intl";
import { Global } from "@emotion/react";
import { CLIENT, LOCALE } from "./constants";
import locales from "./locales/data.json";
import { themes, ThemeContext } from "style";
import globalStyles from "style/global.css";
import Toast from "components/Toast";
import { ModalConfirmationProvider } from "components/Modal";

/**
 * App
 *
 * @params {element}  children
 */
const App = ({ children, theme }) => (
  <ThemeContext.Provider value={theme}>
    <ApolloProvider client={CLIENT}>
      <IntlProvider locale={LOCALE} messages={locales[LOCALE]}>
        <ModalConfirmationProvider>
          <Global styles={globalStyles} />
          {children}
          <Toast />
          <div data-page-end />
        </ModalConfirmationProvider>
      </IntlProvider>
    </ApolloProvider>
  </ThemeContext.Provider>
);

App.defaultProps = {
  theme: themes.app,
};

App.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object,
};

export default App;
