import React from "react";
import { DatePicker } from "components/Form";

/**
 * CodeTelemetryPeriodSelector
 */
const CodeTelemetryPeriodSelector = (props) => <DatePicker enableWeekRangePicker maxDate={new Date()} {...props} />;

CodeTelemetryPeriodSelector.propTypes = {};

export default CodeTelemetryPeriodSelector;
