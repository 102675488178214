import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import Icon, { ICON_TYPE } from "components/Icon";
import { colors } from "style";
import { StyledTag } from "components/Tags";

/**
 * TagDuration
 *
 * @params {String}   text
 */
const TagDuration = ({ text, ...props }) => (
  <StyledTag css={styles.container}>
    <Icon type={ICON_TYPE.clock} color={`#fff`} {...props} />
    {text}
  </StyledTag>
);

const styles = {
  container: css`
    background: ${colors.warning};
  `,
};

TagDuration.propTypes = {
  text: PropTypes.string,
};

export default TagDuration;
