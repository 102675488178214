import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { GET_MISSION_PROPOSAL } from "graphql/queries";
import { FormattedMessage } from "react-intl";
import { useQueryData, useToast } from "hooks";
import Tooltip from "components/Tooltip";
import { REQUIREMENT_TYPES } from "constants/index";
import { UPDATE_MISSION } from "graphql/mutations";

export default function useMissionProposal(id) {
  const { toast } = useToast();
  const keyName = "missionProposal";
  const [editableAssessments, setEditableAssessments] = useState([]);

  const { data, error, loading, refetch } = useQueryData({
    queryName: GET_MISSION_PROPOSAL,
    keyName,
    variables: {
      id,
    },
  });
  const [mission, { loading: statusUpdateLoading }] = useMutation(UPDATE_MISSION);

  useEffect(() => {
    setEditableAssessments(getEditableAssessments(data, keyName));
  }, [data]);

  const setProposalToApproved = () => {
    mission({
      variables: {
        input: {
          id,
          params: {
            assessmentDrafted: false,
          },
        },
      },
      onCompleted: () => {
        toast.success(<FormattedMessage id="Missions.AssessmentDraftButton.MessageApproved" />);
        refetch();
      },
      onError: () => toast.error(<FormattedMessage id="Missions.AssessmentDraftButton.MessageError" />),
    });
  };

  return {
    data: {
      addToolTip,
      editableAssessments,
      mission: {
        name: data && data[keyName]?.name,
        description: data && data[keyName]?.description,
        assessmentDrafted: data && data[keyName]?.assessmentDrafted,
      },
      termSheets: data && data[keyName]?.termSheets,
    },
    loading,
    error,
    setProposalToApproved,
    statusUpdateLoading,
    refetch,
  };
}

const getEditableAssessments = (data, keyName) =>
  data ? data[keyName]?.assessments?.filter((a) => Object.values(REQUIREMENT_TYPES).includes(a.type)) : [];

/**
 * Extract specific requirement questions from the list of all
 * And add the tooltip where required
 * Usually questions with description would have tooltips
 */
const addToolTip = (requirements) =>
  requirements.map((r) => (r.description ? { ...r, value: toolTip(r.description, r.value) } : r));

/**
 * Alias tooltip component to expose value with tooltip
 */
const toolTip = (desc, value) => <Tooltip tooltipId={desc}>{value}</Tooltip>;
