import React from "react";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { REMOVE_PROMPT } from "graphql/mutations";
import { GET_PROMPTS } from "graphql/queries";
import { useToast } from "hooks";

export default function usePrompt({ paginationType = "classic" } = {}) {
  const { toast } = useToast();

  const [removeRemovePrompt, { loading: deleting }] = useMutation(REMOVE_PROMPT, {
    refetchQueries: [GET_PROMPTS],
  });

  const handleRemovePrompt = (id) => {
    removeRemovePrompt({
      variables: {
        input: {
          id,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="usePrompt.RemoveSuccess" />),
      onError: () => toast.error(<FormattedMessage id="usePrompt.RemoveError" />),
    });
  };

  return {
    deleting,
    handleRemovePrompt,
    paginationType,
  };
}
