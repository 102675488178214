import { gql } from "@apollo/client";
import { CLIENT_FEEDBACK_FIELDS } from "graphql/fragments";

const ADD_CLIENT_FEEDBACK = gql`
  ${CLIENT_FEEDBACK_FIELDS}
  mutation addClientFeedback($input: AddClientFeedbackInput!) {
    addClientFeedback(input: $input) {
      clientFeedback {
        ...ClientFeedbackFields
      }
    }
  }
`;

const UPDATE_CLIENT_FEEDBACK = gql`
  ${CLIENT_FEEDBACK_FIELDS}
  mutation updateClientFeedback($input: UpdateClientFeedbackInput!) {
    updateClientFeedback(input: $input) {
      clientFeedback {
        ...ClientFeedbackFields
      }
    }
  }
`;

const REMOVE_CLIENT_FEEDBACK = gql`
  mutation RemoveClientFeedback($input: RemoveClientFeedbackInput!) {
    removeClientFeedback(input: $input) {
      id
    }
  }
`;

export { ADD_CLIENT_FEEDBACK, UPDATE_CLIENT_FEEDBACK, REMOVE_CLIENT_FEEDBACK };
