import React from "react";
import PropTypes from "prop-types";
import DisplayBox from "components/DisplayBox";
import Tags from "components/Tags";
import { ARCHETYPES } from "constants/index";

/**
 * RoleMatchingArcheTypes
 *
 * @params {Array}    data
 */
const RoleMatchingArcheTypes = ({ data, ...props }) => {
  return <DisplayBox numColumns={1} data={ARCHE_TYPES_DATA(data)} {...props} />;
};

const ARCHE_TYPES_DATA = (data) => [
  {
    title: null,
    body: data?.archetype?.length > 0 && (
      <Tags data={data?.archetype.map((a) => ({ name: Object.values(ARCHETYPES).find((o) => o.id === a)?.name }))} />
    ),
  },
];

RoleMatchingArcheTypes.propTypes = {
  data: PropTypes.object,
};

export default RoleMatchingArcheTypes;
