/**
 * Get the str
 *
 * @param {string} str
 *
 * @returns {string}
 */

export default function toUcFirst(str) {
  if (typeof str !== "string") return str;

  return str
    .split("_")
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    .join(" ");
}
