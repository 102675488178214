/** @jsx jsx */
import styled from "@emotion/styled";

/**
 * Page Wrapper
 */
const PageWrapper = styled.div`
  max-width: 88.8rem;
  margin:0 auto;
`;

export default PageWrapper;
