import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { useAuth } from "hooks";
import { DATE_FORMAT_TYPE, TIME_CARD_OPTIONS } from "constants/index";
import Form, { TYPE, SIZE } from "components/Form";

/**
 * TimeCardFilterForm
 * 
 * @param {object} formValues
 */
const TimeCardFilterForm = ({ formValues, ...props}) => {
  const { isPartner } = useAuth();
  const [showFilters, setShowFilters] = useState(false);

  const columns = useMemo(
    () => COLUMNS(isPartner, setShowFilters, showFilters, formValues),
    [isPartner, setShowFilters, showFilters, formValues]
  );

  return <Form data={columns} {...props} />;
};

const COLUMNS = (isPartner, setShowFilters, showFilters, formValues) => [
  {
    style: css`
      width: 100%;
      padding: 0;
    `,
    items: [
      {
        label: "Period",
        type: TYPE.datePicker,
        size: SIZE.small,
        properties: {
          name: "period",
          showMonthYearPicker: true,
          showFullMonthYearPicker: true,
          format: DATE_FORMAT_TYPE.yyyyMM,
          popperPlacement: "bottom-start",
          maxDate: new Date(),
        },
      },
      {
        label: "Search",
        type: TYPE.input,
        size: SIZE.small,
        hint: (
          <span>
            <b>enter</b> to submit
          </span>
        ),
        properties: {
          name: "search",
          type: "search",
          maxLength: 100,
          placeholder: "Type keywords...",
        },
      },
      {
        type: TYPE.primaryLink,
        size: SIZE.medium,
        properties: {
          children: (
            <FormattedMessage
              id={`TimeCard.TimeCardFilterMenu.${showFilters ? "HideFilters" : "ExpandFilters"}Link.Title`}
            />
          ),
          onClick: () => setShowFilters(!showFilters),
          css: {
            "padding-top": `5rem`,
          },
        },
      },
      ...(showFilters
        ? [
            ...(!isPartner
              ? [
                  {
                    label: "Organizations",
                    type: TYPE.tags,
                    size: SIZE.small,
                    properties: {
                      name: "organizationIds",
                      placeholder: "Search organizations...",
                      options: formValues?.organizations,
                      labelKey: "name",
                      valueKey: "id",
                      minLength: 0,
                    },
                  },
                ]
              : []),
            {
              label: "Teams",
              type: TYPE.tags,
              size: SIZE.small,
              properties: {
                name: "teamIds",
                placeholder: "Search teams...",
                options: formValues?.teams,
                labelKey: "name",
                valueKey: "id",
                minLength: 0,
              },
            },
            ...(!isPartner
              ? [
                  {
                    label: "Partners",
                    type: TYPE.tags,
                    size: SIZE.small,
                    properties: {
                      name: "partnerIds",
                      placeholder: "Search partners...",
                      options: formValues?.partners,
                      labelKey: "name",
                      valueKey: "id",
                      minLength: 0,
                    },
                  },
                ]
              : []),
            {
              label: "Status",
              type: TYPE.tags,
              size: SIZE.small,
              properties: {
                name: "statuses",
                placeholder: "Select status...",
                labelKey: "name",
                valueKey: "value",
                options: formValues?.timeCardStatus?.map((status) => ({
                  name: TIME_CARD_OPTIONS.find((option) => option.value === status)?.name,
                  value: status,
                })),
                minLength: 0,
              },
            },
            {
              label: "Over Allocated Hours",
              type: TYPE.select,
              size: SIZE.small,
              properties: {
                name: "overAllocatedHours",
                options: formValues?.isOverAllocatedTime?.map((overAllocated) => ({
                  name: overAllocated ? "Yes" : "No",
                  value: overAllocated,
                })),
                labelKey: "name",
                valueKey: "value",
                isClearable: true,
              },
            },
          ]
        : []),
    ],
  },
];

TimeCardFilterForm.propTypes = {
  formValues: PropTypes.object,
};

export default TimeCardFilterForm;
