import { gql } from "@apollo/client";

const USER_REGISTER = gql`
  mutation UserRegister(
    $firstName: String!
    $lastName: String!
    $email: String!
    $bachelorDegree: String
    $password: String!
    $passwordConfirmation: String!
    $archetype: ProfileArchetype!
    $yearsExperience: String
    $linkedinUrl: String
    $disciplines: [TaggableInput!]
    $partner: String
    $channel: String
    $source: String
    $termsService: Boolean = false
    $privacyPolicy: Boolean = false
    $signupOriginType: String
    $signupOriginId: String
  ) {
    userRegister(
      firstName: $firstName
      lastName: $lastName
      email: $email
      bachelorDegree: $bachelorDegree
      password: $password
      passwordConfirmation: $passwordConfirmation
      archetype: $archetype
      yearsExperience: $yearsExperience
      linkedinUrl: $linkedinUrl
      disciplines: $disciplines
      partner: $partner
      channel: $channel
      source: $source
      termsService: $termsService
      privacyPolicy: $privacyPolicy
      signupOriginType: $signupOriginType
      signupOriginId: $signupOriginId
    ) {
      profile {
        id
        email
        uuid
        slug
        avatarUrl
        firstName
        lastName
        archetype
        disciplines {
          id
          uuid
          name
        }
        yearsExperience
        bachelorDegree
      }
    }
  }
`;

export { USER_REGISTER };
