import { GET_TEAMS } from "graphql/queries";

const MOCK_GET_TEAMS = {
  request: {
    query: GET_TEAMS,
    variables: {
      profileId: "136",
      timeSheetSummary: true,
      orderBy: {},
      first: 10,
      last: null,
      after: null,
    },
  },
  result: {
    data: {
      missionContacts: {
        nodes: [
          {
            id: "1",
            slug: "legal-08bdface",
            name: "Legal",
            size: 6,
            organization: {
              id: "2",
              slug: "block-west-and-kovacek-3fdb5f75",
              name: "Block, West and Kovacek",
              __typename: "Organization",
            },
            __typename: "Team",
            assignments: [
              {
                id: "1",
                slug: "yaseen-ahmed-a48b3956",
                firstName: "Yaseen",
                lastName: "Ahmed",
                avatarUrl: null,
                __typename: "Assignment",
              },
              {
                id: "2",
                slug: "clyde-kertzmann-a0133d59",
                firstName: "Clyde",
                lastName: "Kertzmann",
                avatarUrl:
                  "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhNM2N3TWpOdmIyaHFNM2N6WnpOcGNqUjZPR2wwYlRrNFp6VXlaZ1k2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpTjJsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpVMUxtcHdaeUk3SUdacGJHVnVZVzFsS2oxVlZFWXRPQ2NuTlRVdWFuQm5CanNHVkRvUlkyOXVkR1Z1ZEY5MGVYQmxTU0lQYVcxaFoyVXZhbkJsWndZN0JsUTZFWE5sY25acFkyVmZibUZ0WlRvS2JHOWpZV3c9IiwiZXhwIjoiMjAyMi0xMi0wNVQyMDo0OTowMC45ODdaIiwicHVyIjoiYmxvYl9rZXkifX0=--ecea83dafe3e29c02d01d24da95df83ac972d655/55.jpg",
                __typename: "Assignment",
              },
              {
                id: "4",
                slug: "clotilde-franecki-a38a9551",
                firstName: "Clotilde",
                lastName: "Franecki",
                avatarUrl:
                  "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhZMmwzYUhOb1lqaDBORGRwTXpkd2IyMDFabWQ1WW5GaVl6SjNkZ1k2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpTjJsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpVeUxtcHdaeUk3SUdacGJHVnVZVzFsS2oxVlZFWXRPQ2NuTlRJdWFuQm5CanNHVkRvUlkyOXVkR1Z1ZEY5MGVYQmxTU0lQYVcxaFoyVXZhbkJsWndZN0JsUTZFWE5sY25acFkyVmZibUZ0WlRvS2JHOWpZV3c9IiwiZXhwIjoiMjAyMi0xMi0wNVQyMDo0OTowMS4wNjFaIiwicHVyIjoiYmxvYl9rZXkifX0=--3e101c9b4891cd1963900417d0aa677ff3969d01/52.jpg",
                __typename: "Assignment",
              },
              {
                id: "3",
                slug: "william-blick-9e351879",
                firstName: "William",
                lastName: "Blick",
                avatarUrl:
                  "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhOemhxY21rMGRqSXdPWEpzTTJ0NU9XWTNOM2xrTkdJek9HNXhiQVk2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpTjJsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWprNUxtcHdaeUk3SUdacGJHVnVZVzFsS2oxVlZFWXRPQ2NuT1RrdWFuQm5CanNHVkRvUlkyOXVkR1Z1ZEY5MGVYQmxTU0lQYVcxaFoyVXZhbkJsWndZN0JsUTZFWE5sY25acFkyVmZibUZ0WlRvS2JHOWpZV3c9IiwiZXhwIjoiMjAyMi0xMi0wNVQyMDo0OTowMS4xNjFaIiwicHVyIjoiYmxvYl9rZXkifX0=--943bf301f2cf731888dfe806574e48d86f429b7c/99.jpg",
                __typename: "Assignment",
              },
            ],
          },
          {
            id: "4",
            slug: "administration-71c53385",
            name: "Administration",
            size: 6,
            organization: {
              id: "5",
              slug: "weber-inc-2f26df9f",
              name: "Weber Inc",
              __typename: "Organization",
            },
            __typename: "Team",
            assignments: [
              {
                id: "21",
                slug: "ralph-powlowski-1fb0f6e2",
                firstName: "Ralph",
                lastName: "Powlowski",
                avatarUrl:
                  "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhlV014T0RKaWJIQnROM2RxWW5KMGNXYzFiamQyTTNKb2RYWmxNd1k2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpTjJsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpZd0xtcHdaeUk3SUdacGJHVnVZVzFsS2oxVlZFWXRPQ2NuTmpBdWFuQm5CanNHVkRvUlkyOXVkR1Z1ZEY5MGVYQmxTU0lQYVcxaFoyVXZhbkJsWndZN0JsUTZFWE5sY25acFkyVmZibUZ0WlRvS2JHOWpZV3c9IiwiZXhwIjoiMjAyMi0xMi0wNVQyMDo0OTowMS4yNjRaIiwicHVyIjoiYmxvYl9rZXkifX0=--1f46291daa37b5df5601dd7ee448c37e9a2a8ab0/60.jpg",
                __typename: "Assignment",
              },
              {
                id: "22",
                slug: "yaseen-ahmed-a48b3956",
                firstName: "Yaseen",
                lastName: "Ahmed",
                avatarUrl: null,
                __typename: "Assignment",
              },
              {
                id: "23",
                slug: "lesley-waelchi-08038f86",
                firstName: "Lesley",
                lastName: "Waelchi",
                avatarUrl:
                  "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhPVFUxT0hrM2R6RnBOM1J5TUdGemJHY3diRFpyYldsd2J6Snlhd1k2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpT1dsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpFME1TNXFjR2NpT3lCbWFXeGxibUZ0WlNvOVZWUkdMVGduSnpFME1TNXFjR2NHT3daVU9oRmpiMjUwWlc1MFgzUjVjR1ZKSWc5cGJXRm5aUzlxY0dWbkJqc0dWRG9SYzJWeWRtbGpaVjl1WVcxbE9ncHNiMk5oYkE9PSIsImV4cCI6IjIwMjItMTItMDVUMjA6NDk6MDEuMzk0WiIsInB1ciI6ImJsb2Jfa2V5In19--f6b60022167c4ac8270728278ddbca3300336240/141.jpg",
                __typename: "Assignment",
              },
              {
                id: "24",
                slug: "adrian-johns-2f0a3994",
                firstName: "Adrian",
                lastName: "Johns",
                avatarUrl:
                  "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhiM2M1TURGek1qSTFiakJ5Wm13d09HVnlkM1ZzYVdKbGFIWnVPQVk2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpT1dsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpFME1DNXFjR2NpT3lCbWFXeGxibUZ0WlNvOVZWUkdMVGduSnpFME1DNXFjR2NHT3daVU9oRmpiMjUwWlc1MFgzUjVjR1ZKSWc5cGJXRm5aUzlxY0dWbkJqc0dWRG9SYzJWeWRtbGpaVjl1WVcxbE9ncHNiMk5oYkE9PSIsImV4cCI6IjIwMjItMTItMDVUMjA6NDk6MDEuNTE4WiIsInB1ciI6ImJsb2Jfa2V5In19--432ee7bdea6712be74e1097c43ecd860648d636d/140.jpg",
                __typename: "Assignment",
              },
            ],
          },
        ],
        __typename: "TeamConnection",
      },
    },
  },
};

export { MOCK_GET_TEAMS };
