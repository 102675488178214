import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { H1 } from "components/Text";
import { useTeams } from "hooks";
import { AdvancedButton } from "components/Buttons";
import { LINKS } from "constants/index";
import { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { Spinner } from "components/Loader";

/**
 * DashboardChart
 *
 * @params {String}     profileId
 * @params {String}     name
 * @params {Boolean}    loading
 */
const DashboardTitle = ({ profileId, name, organizationName, loading: loadingProp }) => {
  const navigate = useNavigate();
  const {
    data: teamData,
    loading,
    loadingMore,
    hasNextPage,
    handleNextPage,
  } = useTeams({ profileId, resultsPerPage: 10 });

  const handleInView = () => {
    if (!loading && !loadingMore && hasNextPage) {
      handleNextPage();
    }
  };

  return (
    <StyledH1>
      <AdvancedButton
        iconType={ICON_TYPE.chevronDown}
        iconSize={ICON_SIZE.large}
        handleInView={handleInView}
        loadingMore={loadingMore}
        hasNextPage={hasNextPage}
        options={teamData?.map((item) => ({
          label: <FormattedMessage 
            id="Organization.Squad.Title"
            values={{
              organization_name: item?.organization?.name,
              squad_name: item?.name
            }}
          />,
          onClick: () => navigate(LINKS.dashboard(item?.slug)),
        }))}
        onDark
      />
      {loadingProp ? <Spinner /> : <span>{organizationName} - {name}</span>}
    </StyledH1>
  );
};

const StyledH1 = styled(H1)`
  color: #fff;
`;

DashboardTitle.propTypes = {
  profileId: PropTypes.string,
  name: PropTypes.string,
  organizationName: PropTypes.string,
  loading: PropTypes.bool,
};

export default DashboardTitle;
