import { GET_TEAM_PROFILES_SCORE } from "graphql/queries";
import { PAGINATION_TYPE } from "components/Pagination";
import { useQueryData } from "hooks";

export default function useTeamProfilesScores({ teamId, resultsPerPage = 2 }) {
  const { hasNextPage, handleNextPage, hasPreviousPage, handlePreviousPage, loading, loadingMore, data, error } =
    useQueryData({
      queryName: GET_TEAM_PROFILES_SCORE,
      keyName: "teamProfilesScoresFetch",
      resultsPerPage,
      paginationType: PAGINATION_TYPE.paginate,
      variables: {
        teamId,
      },
    });

  return {
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    loading,
    loadingMore,
    data: data?.teamProfilesScoresFetch?.nodes,
    error,
  };
}
