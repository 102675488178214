import React from "react";
import "@testing-library/jest-dom";
import { render, screen } from "@testing-library/react";
import { AuthProvider } from "hooks";
import { MemoryRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { MockedProvider } from "@apollo/client/testing";
import Routes from "routes";
import { setStubs } from "utils/testHelper";
import {
  MOCK_GET_TEAMS,
  MOCK_GET_INSIGHTS,
  MOCK_GET_ME,
  MOCK_GET_PROFILE,
  MOCK_GET_PROFILE_COMPLETION,
  MOCK_GET_EVENTS,
  MOCK_GET_TEAM_SCORE,
  MOCK_GET_TEAM,
} from "graphql/queries/mocks";
import { MOCK_SET_PREFERENCES } from "graphql/mutations/mocks";
import locales from "locales/data.json";
import { CACHE } from "constants/index";

const TestPage = ({ route }) => (
  <MemoryRouter initialEntries={[route]}>
    <MockedProvider
      mocks={[
        MOCK_GET_ME[0],
        MOCK_SET_PREFERENCES[0],
        MOCK_GET_PROFILE_COMPLETION,
        MOCK_GET_PROFILE,
        MOCK_GET_TEAMS,
        MOCK_GET_EVENTS,
        MOCK_GET_TEAM_SCORE,
        MOCK_GET_TEAM,
      ]}
      cache={CACHE}
    >
      <IntlProvider locale={"en"} messages={locales["en"]}>
        <Routes />
      </IntlProvider>
    </MockedProvider>
  </MemoryRouter>
);

describe("Page Renders...", () => {
  setStubs();

  it("Home", async () => {
    render(<TestPage route={`/home`} />);

    // Test page load by searching for welcome text
    await screen.findByText(/welcome/i);
    expect(screen.getByText(/welcome/i)).toBeInTheDocument();
  });

  it("Candidate Home", async () => {
    render(<TestPage route={`/candidate/home`} />);

    // Test page load by searching for welcome text
    await screen.findByText(/welcome/i);
    expect(screen.getByText(/welcome/i)).toBeInTheDocument();
  });
  /*
  it("Squad Profile Page", async () => {
    render(<TestPage route={`/squads/:id/profile`} />);

    // Test page load by searching for Details text
    await screen.findByText(/Mission Description/);
    expect(screen.getByText(/Mission Description/)).toBeInTheDocument();
  });

  it("Squad Effort Report Page", async () => {
    render(<TestPage route={`/squads/:id/effort_report`} />);

    // Test page load by searching for Time Allocation
    await screen.findByText(/Time Allocation/);
    expect(screen.getByText(/Time Allocation/)).toBeInTheDocument();
  });

  it("Squad Mission Report Page", async () => {
    render(<TestPage route={`/squads/:id/mission_report`} />);

    // Test page load by searching for Mission Report text
    await screen.findByText(/Mission Report/);
    expect(screen.getByText(/Mission Report/)).toBeInTheDocument();
  });

  it("Squad Engineering Page", async () => {
    render(<TestPage route={`/squads/:id/engineering`} />);

    // Test page load by searching for Engineering text
    await screen.findByText(/Engineering/);
    expect(screen.getByText(/Engineering/)).toBeInTheDocument();
  });

  it("Squad Daily Report Page", async () => {
    render(<TestPage route={`/squads/:id/daily_report`} />);

    // Test page load by searching for Daily Report text
    await screen.findByText(/Daily Report/);
    expect(screen.getByText(/Daily Report/)).toBeInTheDocument();
  });

  // it("Squad Setting Page", async () => {
  //   render(<TestPage route={`/squads/:id/settings`} />);

  //   // Test page load by searching for settings text
  //   await screen.findByText(/Squad Settings/);
  //   expect(screen.getByText(/Squad Settings/)).toBeInTheDocument();
  // });
  */
});
