import React, { useContext } from "react";
import styled from "@emotion/styled";
import { colors, ThemeContext, fontSize } from "style";

/**
 * Heading 5
 */
const StyledH5 = styled.h5`
  font-size: ${fontSize.normal};
  font-weight: 600;
  color: ${colors.purpleRainDark2};
  line-height: normal;
  margin: 1rem 0 0.5rem 0;
  ${(props) => props.themeStyle}
`;

const H5 = ({ ...props }) => {
  const theme = useContext(ThemeContext);

  return <StyledH5 themeStyle={theme.h1} {...props} />;
};

export default H5;
