import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { fontSize } from "style";
import { colors } from "style";
import { Select } from "components/Form";
import { THIS_WEEK, LAST_WEEK } from "constants/index";

/**
 * TimeSheetIFrame
 *
 * @params {String}   src
 * @params {Array}    insights
 * @params {String}   billableMonth
 */
const TimeSheetIFrame = ({ src, insights, billableMonth, ...props }) => {
  const [week, setWeek] = useState(THIS_WEEK);
  const [weekOptions, setWeekOptions] = useState([]);

  useEffect(() => {
    const [
      thisWeekDate,
      lastWeekDate,
      firstDayOfCurrentWeek,
      lastDayOfCurrentWeek,
      firstDayOfPreviousWeek,
      lastDayOfPreviousWeek,
    ] = getWeekDates(insights);
    let enKeyCurrentPeriod = "Mission.WeekTime";
    let enKeyPerviousPeriod = "Mission.WeekTime";
    let currentWeekOption = {
      firstDayOfWeek: firstDayOfCurrentWeek,
      startDate: thisWeekDate.startDate.slice(-2),
      endDate: thisWeekDate.endDate.slice(-2),
    };
    let previousWeekOption = {
      firstDayOfWeek: firstDayOfPreviousWeek,
      startDate: lastWeekDate.startDate.slice(-2),
      endDate: lastWeekDate.endDate.slice(-2),
    };
    if (firstDayOfCurrentWeek != lastDayOfCurrentWeek) {
      enKeyCurrentPeriod = "Mission.DifferentWeekTime";
      currentWeekOption["lastDayOfWeek"] = lastDayOfCurrentWeek;
    }
    if (firstDayOfPreviousWeek != lastDayOfPreviousWeek) {
      enKeyPerviousPeriod = "Mission.DifferentWeekTime";
      previousWeekOption["lastDayOfWeek"] = lastDayOfPreviousWeek;
    }

    const WEEKLY_TIME_OPTIONS = [
      {
        label: <FormattedMessage id={enKeyCurrentPeriod} values={currentWeekOption} />,
        value: THIS_WEEK,
      },
      {
        label: <FormattedMessage id={enKeyPerviousPeriod} values={previousWeekOption} />,
        value: LAST_WEEK,
      },
    ];
    setWeekOptions(WEEKLY_TIME_OPTIONS);
  }, [billableMonth]);

  const handleChange = (event, value) => {
    setWeek(value);
  };

  if (!weekOptions.length > 0) return null;

  return (
    <div css={styles.time_graph} {...props}>
      <div css={styles.billable_month}>
        <Select
          name="week"
          onChange={(n, v) => handleChange(n, v)}
          options={weekOptions}
          required={true}
          defaultValue={weekOptions[0]?.value}
          value={week}
        />
      </div>
      <iframe src={`${src}${week}`} />
    </div>
  );
};

const getMonthName = (date) => {
  return new Date(date).toLocaleString("default", { month: "short" });
};

function getWeekDates(insights) {
  let minDate =
    insights && insights?.length > 0
      ? insights?.reduce(function (prev, curr) {
          return prev.startDate < curr.startDate ? prev : curr;
        })
      : null;

  let lastWeek = insights?.filter((word) => word === minDate);
  let thisWeek = insights?.filter((word) => word !== minDate);
  let firstDayOfCurrentWeek = getMonthName(thisWeek[0].startDate);
  let lastDayOfCurrentWeek = getMonthName(thisWeek[0].endDate);
  let firstDayOfPreviousWeek = getMonthName(lastWeek[0].startDate);
  let lastDayOfPreviousWeek = getMonthName(lastWeek[0].endDate);

  return [
    thisWeek[0],
    lastWeek[0],
    firstDayOfCurrentWeek,
    lastDayOfCurrentWeek,
    firstDayOfPreviousWeek,
    lastDayOfPreviousWeek,
  ];
}

const styles = {
  billable_month: css`
    font-size: ${fontSize.medium};
    font-weight: 500;
    line-height: 1.33;
    margin: 0.8rem 0 1.6rem 0;
    color: ${colors.purpleRainDark2};
    display: block;
  `,
  time_graph: css`
    width: 100%;
    > iframe {
      width: 100%;
    }
  `,
  select: css`
    border: none;
    font-size: ${fontSize.medium};
    font-weight: 500;
    line-height: 1.33;
    margin: 0.8rem 0 1.6rem 0;
    color: ${colors.purpleRainDark2};
    display: block;
  `,
};

TimeSheetIFrame.defaultProps = {
  insights: [],
};

TimeSheetIFrame.propTypes = {
  billableMonth: PropTypes.string,
  insights: PropTypes.array,
  src: PropTypes.string.isRequired,
};

export default TimeSheetIFrame;
