const FEEDBACK_OPTIONS = {
  Agree: {
    text: "75%",
    color: 3,
  },
  "Strongly Agree": {
    text: "100%",
    color: 4,
  },
  Disagree: {
    text: "50%",
    color: 2,
  },
  "Strongly Disagree": {
    text: "25%",
    color: 1,
  },
  "Does not Apply": {
    text: "N/A",
    color: 0,
  },
};

export default FEEDBACK_OPTIONS;
