import React, { useEffect, useState } from "react";
import moment from "moment";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useProfile, useAuth, useToast } from "hooks";
import { SUBMIT_PROFILE } from "graphql/mutations";
import { GET_PROFILE } from "graphql/queries";
import { PrimaryButton } from "components/Buttons";
import { MEMBER_STATUS, VETTING_STEPS } from "constants/index";
import { useMutateData, MUTATION_TYPE } from "hooks";

/**
 * Candidate Submit Button
 */
const CandidateSubmitButton = ({ ...props }) => {
  const { toast } = useToast();
  const { profileId } = useAuth();
  const [status, setStatus] = useState();
  const [canSubmit, setCanSubmit] = useState();
  const { isProfileComplete, isCodeChallengeComplete, isInterviewComplete, data, vettingConfiguration } = useProfile({
    profileId,
  });

  const [isCandidateComplete, setIsCandidateComplete] = useState(false);

  const { handleAdd, loading } = useMutateData(
    {
      mutationName: {
        add: SUBMIT_PROFILE,
      },
      refetchAfterMutate: true,
      refetchQueries: [GET_PROFILE],
      unWrapParams: true,
    },
    MUTATION_TYPE.add
  );

  const isStepComplete = (step) => {
    const stepCompletionMap = {
      [VETTING_STEPS.codeChallenge]: isCodeChallengeComplete,
      [VETTING_STEPS.videoInterview]: isInterviewComplete,
      [VETTING_STEPS.profileCompletion]: isProfileComplete,
    };

    return stepCompletionMap[step.name] || false;
  };

  useEffect(() => {
    if (vettingConfiguration?.length) {
      const requiredVettingSteps = vettingConfiguration.filter((step) => step.required);
      const isVettingComplete = requiredVettingSteps.length === 0 || requiredVettingSteps.every(isStepComplete);

      setIsCandidateComplete(isVettingComplete);
    }
  }, [isCodeChallengeComplete, isInterviewComplete, isProfileComplete, vettingConfiguration]);

  useEffect(() => {
    setStatus(data?.profile?.status);
  }, [data?.profile]);

  useEffect(() => {
    setCanSubmit(
      (status === MEMBER_STATUS.sourced || status === MEMBER_STATUS.assessing) && !loading && isCandidateComplete
    );
  }, [status, loading, isCandidateComplete]);

  const handleSubmit = () => {
    handleAdd({
      params: {
        input: {
          id: profileId,
          submit: true,
        },
      },
      onError: ({ message }) => {
        toast.error(message);
      },
    });
  };

  return (
    <PrimaryButton disabled={!canSubmit} variant="green" css={styles.button} onClick={handleSubmit} {...props}>
      {status === MEMBER_STATUS.reviewing ? (
        <FormattedMessage
          id="Candidate.CandidateSubmitButton.MessageSubmitted"
          values={{
            date: moment(data?.profile?.submittedAt).format("MMMM Do, YYYY"),
          }}
        />
      ) : (
        <FormattedMessage id="Candidate.CandidateSubmitButton.CTA" />
      )}
    </PrimaryButton>
  );
};

const styles = {
  button: css`
    width: 100%;
  `,
};

export default CandidateSubmitButton;
