import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import App from "App";
import { useParams } from "react-router-dom";
import { css } from "@emotion/react";
import { NarrowContainer, Grid } from "components/Containers";
import { HeaderPublicGradient } from "components/Header";
import { themes, colors, fontSize } from "style";
import { useMissionProposal, useAuth } from "hooks";
import Card from "components/Card";
import { MissionAbout } from "components/Missions";
import { RecommendedSquads } from "components/Squads";
import { BUTTON_SIZE, SecondaryButton } from "components/Buttons";
import { EditableProposal } from "components/Missions/Proposal";

/**
 * Mission Proposal
 */
const MissionProposal = () => {
  const { id } = useParams();
  const { data, error, loading, setProposalToApproved, statusUpdateLoading, refetch } = useMissionProposal(id);
  const intl = useIntl();
  const { isAdmin } = useAuth();

  const handleStatusUpdate = () => {
    setProposalToApproved();
  };

  const handleClose = () => refetch();

  const { mission: missionData, termSheets, editableAssessments, addToolTip } = data || {};

  return (
    <App theme={themes.website}>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Proposal.Title" })}</title>
      </Helmet>
      <HeaderPublicGradient>
        {!loading && (
          <div css={styles.status_container}>
            {isAdmin && missionData?.assessmentDrafted && (
              <SecondaryButton
                onClick={handleStatusUpdate}
                css={styles.item_container(colors.orangeCountyBase, colors.white, "pointer")}
                disabled={statusUpdateLoading}
              >
                <FormattedMessage id="Mission.Proposal.ApproveAssessment" />
              </SecondaryButton>
            )}
            <div css={styles.item_container(colors.darkYellow, colors.black, "auto")}>
              <FormattedMessage
                id={`Mission.Proposal.${missionData?.assessmentDrafted ? "Draft" : "Approved"}Status`}
              />
            </div>
          </div>
        )}
      </HeaderPublicGradient>
      <NarrowContainer>
        <Grid topMargin>
          <Grid.col start={1} end={13}>
            <Card>
              <MissionAbout mission={missionData} isLoading={loading} />
            </Card>
          </Grid.col>
          {editableAssessments.map((assessment) => (
            <Grid.col start={1} end={13} key={assessment.uuid}>
              <EditableProposal
                error={error}
                handleClose={handleClose}
                uuid={assessment.uuid}
                loading={loading}
                type={assessment.type}
                data={addToolTip(assessment.requirements)}
                status={missionData?.assessmentDrafted}
              />
            </Grid.col>
          ))}
          <Grid.col start={1} end={13}>
            <Card>
              <RecommendedSquads data={termSheets} loading={loading} />
            </Card>
          </Grid.col>
        </Grid>
      </NarrowContainer>
    </App>
  );
};

const styles = {
  status_container: css`
    display: flex;
    justify-content: flex-end;
    margin-right: 12rem;
  `,
  item_container: (color, fontColor, cursor) => css`
    height: ${BUTTON_SIZE.medium};
    min-height: ${BUTTON_SIZE.medium};
    font-size: ${fontSize.small};
    font-weight: 600;
    background: ${color};
    color: ${fontColor};
    border-radius: 0.6rem;
    border: solid 2px ${color};
    display: inline-flex;
    padding: 1rem 2.2rem;
    transition: all 0.2s;
    text-decoration: none;
    margin: 1rem;
    cursor: ${cursor};
  `,
};

export default MissionProposal;
