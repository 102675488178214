import { gql } from "@apollo/client";

const GET_LANGUAGES = gql`
  query languages($filters: TagFilter) {
    languages(filters: $filters) {
      nodes {
        name
        id
      }
    }
  }
`;

export { GET_LANGUAGES };
