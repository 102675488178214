import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { colors, fontSize, gradients } from "style";
import { WhiteLink } from "components/Links";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { LINKS } from "../../constants";
import Card from "components/Card";

/**
 * LibrarySummary
 */
const LibrarySummary = () => (
  <StyledCard css={styles.container}>
    <div>
      <div css={styles.icon_container}>
        <Icon type={ICON_TYPE.lightBulb} size={ICON_SIZE.xxlarge} color="#fff" />
      </div>
      <div css={styles.info}>
        <FormattedMessage id="Library.LibrarySummary.info" />
      </div>
      <div css={styles.link}>
        <WhiteLink href={LINKS.library} target="_blank" withMoreIcon>
          <FormattedMessage id="Library.LibrarySummary.explore" />
        </WhiteLink>
        <WhiteLink href={LINKS.contact_member_experience} target="_blank" withMoreIcon>
          <FormattedMessage id="Home.ContactMemberExperience" />
        </WhiteLink>
      </div>
    </div>
  </StyledCard>
);

const StyledCard = styled(Card)`
  background: ${gradients.orangeLinearGradient};
  color: #fff;
  padding: 3rem;
  font-size: ${fontSize.normal};
  box-shadow: 0 2rem 3rem rgba(230, 106, 129, 0.3);
`;

const styles = {
  icon_container: css`
    background: ${colors.orangeCountyDark3};
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.6rem;
    margin-bottom: 3rem;
  `,
  info: css`
    font-size: ${fontSize.large};
    font-weight: 500;
    line-height: normal;
    margin-bottom: 2rem;
  `,
  link: css`
    margin: 0 -3rem -3rem 0rem;
    border-top: 1px rgba(255, 255, 255, 0.2) solid;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 0 0 0.6rem 0.6rem;
    padding: 2rem 3rem 3rem 0;
    font-size: ${fontSize.small};
  `,
};

export default LibrarySummary;
