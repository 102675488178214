const TRACKING_SECTIONS = {
  1: "roleSelectionSignUp",
  2: "roleDetailsSignUp",
  3: "personalDetailsSignUp",
  roleInformation: "Role Information",
  personalInformation: "Personal Information",
  signin: "Sign In",
  home: "Home",
  dashboard: "Dashboard",
  events: "Events",
  squadPerformance: "Squad Performance",
  all: "All",
  candidates: "Candidates",
  members: "Members",
  publicProfile: "Public Profile",
  profileDetails: "Profile Details",
  clientSearch: "Showcase/Client Search",
  showcaseBookMeeting: "Showcase Book Meeting",
  showcaseTeamBuilder: "Showcase Team Builder",
  clientCommunity: "Client Community",
  showcaseApplicants: "Showcase Applicants",
  showcaseMissionBoard: "Showcase Mission Board",
  showcaseSignUp: "Showcase SignUp",
  rateAndAvailability: "Rate & Availability",
  setting: "Settings",
  missionsOverview: "Missions Overview",
  missionDetails: "Mission Details",
  timeSheet: "Time Sheet",
  squadsOverview: "Squads Overview",
  squadDetails: "Squad Details",
  squadActivityLog: "Squad Activity Log",
  squadSettings: "Squad Settings",
  listOverview: "List Overview",
  boardOverview: "Mission Board Overview",
  roleDetails: "Role Details",
  timecardsOverview: "Time Cards Overview",
  organizationsOverview: "Organizations Overview",
  organizationsDetails: "Organizations Details",
  organizationsEdit: "Organizations Edit",
  partnersOverview: "Partners Overview",
  addProfile: "Add Profile",
  addOrganization: "Add Organization",
  addSquad: "Add Squad",
  access: "Access",
  missionEvents: "Mission Events",
};

export default TRACKING_SECTIONS;
