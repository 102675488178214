import React from "react";
import styled from "@emotion/styled";
import { Tutorials } from "components/Tutorials";
import member_matching from "assets/images/video_covers/member_matching.jpg";
import { CLOUDFRONT_URL } from "constants/index";
import { gradients } from "style";
import Card from "components/Card";

/**
 * TutorialMissions
 */
const TutorialMissions = (props) => (
  <TutorialCard fixedHeight>
    <Tutorials
      source="mission_board"
      keyName={TUTORIAL_MISSIONS_KEYNAME}
      videoList={[
        {
          name: "Welcome",
          cover: member_matching,
          source: `${CLOUDFRONT_URL}videos/explainers/explainer_mission_board.mov`,
          duration: "0:56",
        },
      ]}
      text={{
        bodyTitle: "Missions.MissionsTutorial.Title",
        bodyDescription: "Missions.MissionsTutorial.Description",
      }}
      hideOnClose
      {...props}
    />
  </TutorialCard>
);

const TutorialCard = styled(Card)`
  padding: 0;
  background: ${gradients.sideBarGradient};
  color: #fff;
  border: 0;
`;

const TUTORIAL_MISSIONS_KEYNAME = `mission_board_tour_visible`;

export { TUTORIAL_MISSIONS_KEYNAME };

export default TutorialMissions;
