import React from "react";
import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";

/**
 * useMutateData
 *
 * @param {Object}   mutationName            -Object of queries with key names: add, update, delete
 * @param {Array}    refetchQueries           -refetchQueries
 * @param {Boolean}  refetchAfterMutate      -Set to true to refetch queryName after mutation
 * @param {Funcion}  updateCache             -Provide a custom cache callback function (useful for complex local cache updates)
 * @param {Boolean}  unWrapParams            -In some cases, params need to be unwrapped for mutations (as oppose to be wrapped in an "input" object)
 */
export default function useMutateData(
  { mutationName, refetchAfterMutate, refetchQueries = [], updateCache, unWrapParams },
  type = MUTATION_TYPE.update
) {
  if (!mutationName || !mutationName[type]) {
    return DEFAULTS;
  }

  const [errors, setError] = useState({});
  const [handleMutate, { loading, error }] = useMutation(mutationName[type]);

  /**
   * Update
   */
  const handleUpdate = ({ id, params, onCompleted, onError }) => {
    const variables = unWrapParams ? params : { input: { id, params } };

    handleMutate({ variables, onError, onCompleted });
  };

  /**
   * Delete
   */
  const handleDelete = ({ id, onCompleted, onError }) =>
    handleMutate({
      variables: { input: { id } },
      awaitRefetchQueries: true,
      refetchQueries,
      update: updateCache,
      onQueryUpdated(observableQuery) {
        if (refetchAfterMutate) {
          return observableQuery.refetch();
        }
      },
      onError,
      onCompleted,
    });

  /**
   * Add/Create
   */
  const handleAdd = ({ parentKeyName, parentKeyValue, params, onCompleted, onError }) => {
    const input = parentKeyName && parentKeyValue ? { [parentKeyName]: parentKeyValue, params } : { params };
    const variables = unWrapParams ? params : { input };

    handleMutate({
      variables,
      awaitRefetchQueries: true,
      refetchQueries,
      update: updateCache,
      onQueryUpdated(observableQuery) {
        if (refetchAfterMutate) {
          return observableQuery.refetch();
        }
      },
      onError,
      onCompleted,
    });
  };

  useEffect(() => {
    if (error) {
      const globalErrors = error?.graphQLErrors?.map(({ extensions }) => extensions?.errors);
      let fields = [];

      if (globalErrors && globalErrors.length > 0) {
        if (!globalErrors[0]) {
          setError({
            message: error?.message,
            fields: [],
          });
        } else {
          globalErrors?.forEach((item) => {
            fields = [...item];
          });

          setError({
            message: error?.graphQLErrors?.map(({ message }) => message),
            fields,
          });
        }
      } else {
        setError({
          message: <FormattedMessage id="Global.Error" />,
          fields: [],
        });
      }
    }
  }, [error, setError]);

  return {
    handleUpdate,
    handleAdd,
    handleDelete,
    loading,
    errors,
  };
}

const DEFAULTS = {
  handleUpdate: () => {},
  handleAdd: () => console.log("Invalid"),
  handleDelete: () => {},
  loading: false,
  errors: {},
};

const MUTATION_TYPE = {
  add: "add",
  update: "update",
  delete: "delete",
};

export { MUTATION_TYPE };
