import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { gradients, colors, fontSize } from "style";
import { GET_PROFILE } from "graphql/queries";
import { UPDATE_PROFILE } from "graphql/mutations";
import { useMutateData, MUTATION_TYPE, useAuth, useQueryData } from "hooks";
import Card from "components/Card";
import { Switch } from "components/Form";
import { TextLoader } from "components/Loader";
import { CAPACITY_STATUS } from "constants/index";

/**
 * Shows a toggle for the user to switch on/off their availability
 *
 * @params {Number}   id
 * @params {Object}   queryName
 * @params {String}   keyName
 */
const ProfileRate = ({ id, queryName, keyName, ...props }) => {
  const [isAvailable, setAvailability] = useState(false);
  const { isAdmin } = useAuth();
  const { handleUpdate } = useMutateData(
    {
      mutationName: {
        update: UPDATE_PROFILE,
      },
      refetchAfterMutate: true,
      refetchQueries: [GET_PROFILE],
    },
    MUTATION_TYPE.type
  );

  const { loading, data, error } = useQueryData({
    queryName,
    keyName,
    variables: {
      id,
    },
  });

  const handleToggle = () => {
    setAvailability(!isAvailable);
    handleUpdate({
      id,
      params: {
        capacityStatus: isAvailable ? CAPACITY_STATUS.offDuty : CAPACITY_STATUS.available,
      },
    });
  };

  const ToggleLabel = () =>
    loading ? (
      <TextLoader />
    ) : (
      <FormattedMessage
        id={
          isAvailable
            ? `Profile.ProfileAvailabilityToggle.MsgAvailable`
            : `Profile.ProfileAvailabilityToggle.MsgNotAvailable`
        }
      />
    );

  useEffect(() => {
    setAvailability(data?.profile?.capacityStatus === CAPACITY_STATUS.available);
  }, [data]);

  if (error) return null;

  return (
    <Card css={styles.container(isAvailable)} {...props}>
      <div css={styles.label}>
        <ToggleLabel />
      </div>
      <div>
        <Switch type="switch" checked={isAvailable} onChange={handleToggle} css={styles.switch} />
      </div>
      {isAdmin && data?.profile?.availabilityLastUpdatedAt && (
        <div css={styles.duration}>
          <FormattedMessage
            id="Global.LastUpdated"
            values={{ dateTime: moment(data?.profile?.availabilityLastUpdatedAt).fromNow() }}
          />
        </div>
      )}
    </Card>
  );
};

const styles = {
  container: (isAvailable) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: ${isAvailable ? gradients.missionLinearGradient : `#fff`};
    color: ${isAvailable ? `#fff` : `inherit`};
    transition: all 0.3s ease;
    padding-top: 0.5rem;
    padding: 3rem 3rem 3rem 3rem;
  `,
  duration: css`
    width: 100%;
    opacity: 0.6;
    font-size: ${fontSize.xsmall};
    text-align: center;
    position: absolute;
    bottom: 1rem;
    left: 0;
  `,
  label: css`
    font-size: ${fontSize.medium};
    font-weight: 500;
    margin-bottom: 0.5rem;
    padding: 0 2.5rem;
  `,
  switch: css`
    &.form-switch .form-check-input {
      &:checked {
        background: #fff;

        &:before {
          background: ${colors.pinkPantherBase};
        }
      }
    }
  `,
};

ProfileRate.defaultProps = {
  queryName: GET_PROFILE,
  keyName: "profile",
};

ProfileRate.propTypes = {
  id: PropTypes.string,
  queryName: PropTypes.object,
  keyName: PropTypes.string,
};

export default ProfileRate;
