import React from "react";
import { FormattedMessage } from "react-intl";
import { SUBMIT_PROFILE } from "graphql/mutations";
import { useModalConfirmation, useToast, useMutateData, MUTATION_TYPE } from "hooks";
import { GET_PROFILE } from "graphql/queries";

/**
 * useProfileReview
 *
 * @params {String}   profileId
 */
export default function useProfileReview({ profileId }) {
  const { toast } = useToast();
  const { show } = useModalConfirmation();
  const { handleAdd } = useMutateData(
    {
      mutationName: {
        add: SUBMIT_PROFILE,
      },
      refetchQueries: [GET_PROFILE],
      refetchAfterMutate: true,
      unWrapParams: true,
    },
    MUTATION_TYPE.add
  );

  const handleSubmitForReview = async () => {
    const response = await show(
      <FormattedMessage id="Candidate.CandidateProgress.ProfileReview.Title" />,
      <FormattedMessage id="Candidate.CandidateProgress.RejectedCandidate.Modal.Description" />,
      {
        primaryButtonMessageId: "Global.Confirm",
      }
    );

    if (response) {
      handleAdd({
        params: {
          input: {
            id: profileId,
            submit: true,
          },
        },
        onCompleted: () => {
          toast.success(<FormattedMessage id="Candidate.CandidateProgress.ProfileReview.Success" />);
        },
        onError: ({ message }) => {
          toast.error(message || <FormattedMessage id="Squads.AdvancedActions.RemoveError" />);
        },
      });
    }
  };

  return {
    handleSubmitForReview,
  };
}
