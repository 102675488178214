import { gql } from "@apollo/client";

const ARCHIVE_LIST = gql`
  mutation RemoveList($input: RemoveListInput!) {
    removeList(input: $input) {
      clientMutationId
      id
    }
  }
`;

const UPDATE_PROFILE_LIST = gql`
  mutation updateProfileList($input: UpdateProfileListInput!) {
    updateProfileList(input: $input) {
      profileList {
        id
        negotiatedRate
        negotiatedRateCurrency
        note
        profile {
          id
        }
      }
    }
  }
`;

export { ARCHIVE_LIST, UPDATE_PROFILE_LIST };
