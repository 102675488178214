import moment from "moment";

/**
 * Generate week keys based on the selected date range
 *
 * @param {String}  startDate
 * @param {String}  endDate
 *
 * @returns {Array} The week keys in format YYYY-WW
 *
 */
export default function generateWeekKeys(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  const weekKeys = new Set(); // Use a set to avoid duplicates

  let current = start.clone();

  while (current.isSameOrBefore(end, "day")) {
    const weekKey = current.isoWeekYear() + "-" + current.isoWeek().toString().padStart(2, "0");
    weekKeys.add(weekKey);
    current.add(1, "week");
  }

  return Array.from(weekKeys);
}
