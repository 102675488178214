import Modal from "./Modal";

const MODAL_SIZE = {
  small: "sm",
  medium: "md",
  large: "lg",
  xlarge: "xlg"
};

export { default as ModalForm } from "./ModalForm";
export { default as ModalConfirmationProvider } from "./ModalConfirmationProvider";
export { ModalConfirmationContext } from "./ModalConfirmationProvider";
export { default as withModalForm } from "./withModalForm";
export { default as ConfirmationModal } from "./ConfirmationModal";
export { MODAL_SIZE };

export default Modal;
