import { ARCHETYPES } from "constants/index";

/**
 * isSimplifiedVetting
 *
 * @param {Object}    profile     Object representing the members profile
 * @param {Boolean}   isTrusted   Boolean identifying if this is from a trusted partner
 *
 * @returns {Boolean}
 */
export default function isSimplifiedVetting(profile, isTrusted) {
  const memberArchetype = profile?.archetype;
  const isFromTrustedPartner = isTrusted;
  const TL = ARCHETYPES.tech_lead.id;
  const SL = ARCHETYPES.squad_lead.id;
  const isValidMemberType = memberArchetype === TL || memberArchetype === SL;

  return isValidMemberType && isFromTrustedPartner;
}
