import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { colors, fontSize } from "style";
import { UserPhotoList } from "components/User";
import { TextLoader } from "components/Loader";
import { PrimaryLink } from "components/Links";
import { useAuth } from "hooks";
import { LINKS } from "constants";

/**
 * Shows a list with X thumbs on each row
 *
 * @params {String}     title
 * @params {String}     subtitle
 * @params {Array}      users
 * @params {Boolean}    loading
 * @params {String}     linkTo
 */
const RenderMemberList = ({ title, subtitle, users, loading, linkTo, ...props }) => {
  const { isPartner } = useAuth();

  if (loading) {
    return <Placeholder />;
  }

  const handleThumbClick = (user) => {
    if (isPartner || !user?.profile?.uuid) {
      return;
    }

    window.open(LINKS.public_profile(user?.profile?.uuid), "_blank", "noreferrer");
  };

  return (
    <div css={styles.container}>
      <div>
        <div css={styles.title}>
          <PrimaryLink href={linkTo} routerEnabled>
            {title}
          </PrimaryLink>
        </div>
        <div css={styles.subtitle}>{subtitle}</div>
      </div>
      <UserPhotoList onThumbClick={handleThumbClick} users={users} loading={loading} {...props} />
    </div>
  );
};

/**
 * Placeholder
 *
 */
const Placeholder = () => (
  <div css={styles.container}>
    <div>
      <div css={styles.title}>
        <TextLoader
          css={css`
            width: 12rem;
          `}
        />
      </div>
      <div css={styles.subtitle}>
        <TextLoader
          css={css`
            width: 8rem;
          `}
        />
      </div>
    </div>
  </div>
);

const styles = {
  container: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 0 1.25rem 0;
    min-height: 4rem;
    box-sizing: content-box;
    border-bottom: 1px solid ${colors.grayAnatomyLight4};

    &:first-of-type {
      margin-top: -1rem;
    }

    &:last-of-type {
      border-bottom-width: 0 !important;
    }
  `,
  title: css`
    font-size: ${fontSize.small};
    font-weight: 600;
    margin-bottom: 0.4rem;
    padding-right: 1.5rem;
  `,
  subtitle: css`
    color: ${colors.purpleRainLight4};
    font-weight: 400;
    font-size: ${fontSize.xsmall};
    margin-top: 0.5rem;
    padding-right: 1.5rem;
  `,
};

RenderMemberList.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  users: PropTypes.array,
  loading: PropTypes.bool,
  linkTo: PropTypes.string,
  thumbSize: PropTypes.string,
};

export default RenderMemberList;
