import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useAuth, useProfile } from "hooks";
import {
  ProfileBox,
  ProfileProgress,
  ProfileRateAndAvailability,
  ProfileAvailability,
  ProfilePreferences,
} from "components/Profile";
import { Grid } from "components/Containers";
import Card from "components/Card";
import { CandidateProgress, CandidateTitle, CandidateStatus } from "components/Candidate";

/**
 * Profile Page
 */
const Profile = () => {
  const { profileId, canViewEditRate, isMember } = useAuth();
  const intl = useIntl();
  const { data, loading } = useProfile({ profileId });
  const keyName = "profile";

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Candidate.Profile.Title" })}</title>
      </Helmet>
      <Grid topMargin>
        <Grid.col start={1} end={9}>
          <Grid>
            <Grid.col start={1} end={13}>
              <CandidateTitle profileId={profileId} />
            </Grid.col>
            <Grid.col start={1} end={13}>
              <ProfileBox id={profileId} isEditable={true} />
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                {canViewEditRate ? (
                  <ProfileRateAndAvailability data={data && data[keyName]} loading={loading} isEditable={true} />
                ) : (
                  <ProfileAvailability data={data && data[keyName]} loading={loading} isEditable={true} />
                )}
              </Card>
            </Grid.col>
          </Grid>
        </Grid.col>
        <Grid.col start={9} end={13}>
          <Grid>
            <Grid.col start={1} end={13}>
              <Card>
                <CandidateProgress profileId={profileId} isCandidate />
              </Card>
            </Grid.col>
            {isMember && (
              <Grid.col start={1} end={13}>
                <Card>
                  <ProfilePreferences id={profileId} />
                </Card>
              </Grid.col>
            )}
            <Grid.col start={1} end={13}>
              <Card>
                <ProfileProgress id={profileId} />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <CandidateStatus />
            </Grid.col>
          </Grid>
        </Grid.col>
      </Grid>
    </>
  );
};

export default Profile;
