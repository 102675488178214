const REJECTED_REASON = {
  not_enough_experience: "NOT_ENOUGH_EXPERIENCE",
  lacking_communication_skills: "LACKING_COMMUNICATION_SKILLS",
  incomplete_profile: "INCOMPLETE_PROFILE",
  poor_connection: "POOR_CONNECTION",
  failed_code_challenge: "FAILED_CODE_CHALLENGE",
  others: "OTHERS",
};

export default REJECTED_REASON;
