/**
 * getMinsFromClock (00:00)
 *
 * @param {String}    val
 *
 * @returns {Number} The number of minutes
 */
export default function getMinsFromClock(val) {
  let str = String(val);

  if (!str) return 0;

  let splitVals = str.split(":");

  if (splitVals[1]) {
    return (parseInt(splitVals[0]) || 0) * 60 + (parseInt(splitVals[1]) || 0);
  } else {
    return (parseFloat(splitVals[0]) || 0) * 60;
  }
}
