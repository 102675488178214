import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { usePrevious } from "utils";
import { Col, Grid, Row } from "components/Containers";
import { colors, margins } from "style";
import {
  TERM_SHEET_INPUT_TYPES,
  TermSheetCurrencyConverter,
  TermSheetScenarios,
  TermSheetRow,
  TermSheetScenarioAddButton,
  TermSheetMemberAddButton,
} from "components/TermSheets";
import { useCurrencyConversions, useTermSheet, useTermSheetScenarios, useToast } from "hooks";
import { TermSheetHeaderRow, TermSheetLocationRow, TermSheetMemberRow } from "components/TermSheets/TermSheetRow";
import Tabs from "components/Tabs";
import { CURRENCY_NAMES } from "constants/index";
import { ARCHETYPES } from "constants";
import { CURRENCIES } from "constants";
import NoResults from "components/Messages/NoResults";
import Card from "components/Card";
import { DatePicker } from "components/Form";
import { BILLING_ACCOUNT_INTERVALS } from "constants";
import { TERM_SHEET_SCENARIOS } from "constants/index";
import { PrimaryLink } from "components/Links";

/**
 * TermSheet
 *
 * @param {String}    teamId
 * @param {String}    marginSize
 * @param {String}    selectedDate
 * @param {Number}    selectedViewType
 * @param {Function}  setSelectedDate
 * @param {Function}  setRefetchEvents
 * @param {Function}  setSelectedViewType
 */
const TermSheet = ({
  teamId,
  selectedDate,
  setSelectedDate,
  selectedViewType,
  setSelectedViewType,
  marginSize,
  setRefetchEvents,
  ...props
}) => {
  const { toast } = useToast();
  const {
    data,
    loading,
    refetch: refetchTermSheet,
    getBuyingAmount,
    getConvertedBuyingAmount,
    getConvertedSellingAmount,
    getConvertedMarginAmount,
    getMarginPercent,
    getTotalMarginPercent,
  } = useTermSheet({ teamId, selectedDate });
  const {
    data: scenarios,
    loading: loadingScenarios,
    handleUpdateScenario,
  } = useTermSheetScenarios({ teamId, state: TERM_SHEET_SCENARIOS.active });
  const [teamMembers, setTeamMembers] = useState(data?.teamMembers);
  const [totals, setTotals] = useState({ buyingQuantity: 0 });
  const [changes, setChanges] = useState({});
  const [columns, setColumns] = useState(data?.teamMembers);
  const [billingCurrency, setBillingCurrency] = useState();
  const [billingAccountId, setBillingAccountId] = useState();
  const [billingInterval, setBillingInterval] = useState();
  const [convertToCurrency, setConvertToCurrency] = useState();
  const [conversionRate, setConversionRate] = useState(1);
  const [scenario, setScenario] = useState();
  const [requireSave, setRequireSave] = useState(false);
  const [originalMembers, setOriginalMembers] = useState(0);
  const [showTable, setShowTable] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [hasScenarios, setHasScenarios] = useState(false);
  const prevSelectedViewType = usePrevious(selectedViewType);
  const scenarioTypeTranslation = { WEEKLY_TEAM_SCENARIO: "weekly", MONTHLY_TEAM_SCENARIO: "monthly" };

  const { data: currencyData, loading: currencyLoading } = useCurrencyConversions({
    from: billingCurrency,
    to: convertToCurrency,
  });

  const isTimeTraveling = (date) => {
    const today = new Date();
    return date !== today.toISOString().split("T")[0];
  };

  /**
   * @description Handles the change of a specific value in the term sheet layout. It only updates the value if it has changed.
   *
   * @param {string} keyName - The name of the key to be changed.
   * @param {string} profileId - The ID of the profile associated with the value.
   * @param {any} newValue - The new value to be set.
   */
  const handleChange = (keyName, profileId, newValue) => {
    const teamMembers = scenario?.termSheetTeamState?.teamMembers;
    const oldValue = teamMembers?.find((member) => member?.profile?.id === profileId)?.[keyName];

    if (oldValue !== newValue) {
      setRequireSave(true);
      setChanges((prev) => ({
        ...prev,
        [profileId]: {
          ...prev[profileId],
          [keyName]: newValue,
        },
      }));
    }
  };

  /**
   * @description Track a set of original members
   */
  useEffect(() => {
    const originalTeamMembers = scenario?.termSheetTeamState?.teamMembers || data?.teamMembers;
    const mbrs = new Set(originalTeamMembers?.map((member) => member?.profile?.id));

    setOriginalMembers(mbrs);
  }, [scenario?.termSheetTeamState?.teamMembers, data?.teamMembers]);

  /**
   * @description Remove change from TermSheet
   *
   * @param {String}  keyName
   * @param {String}  profileId
   */
  const handleRevert = (keyName, profileId) => {
    // eslint-disable-next-line no-unused-vars
    const { [keyName]: _, ...rest } = changes[profileId];

    setChanges((prev) => ({ ...prev, [profileId]: rest }));
    setRequireSave(true);
  };

  /**
   * @description Auto-save changes
   */
  useEffect(() => {
    if (requireSave) {
      handleUpdateScenario(scenario?.id, changes);
      setRequireSave(false);
    }
  }, [changes, requireSave, scenario?.id]);

  /**
   * @description A member is to be removed from team
   *
   * @param {String}  profileId
   * @param {Boolean} shouldRemove
   */
  const handleRemove = (profileId, shouldRemove) => {
    const isOriginalMember = originalMembers.has(profileId);

    // eslint-disable-next-line no-unused-vars
    const { [profileId]: _, ...rest } = changes;

    if (shouldRemove && isOriginalMember) {
      setChanges((prev) => ({
        ...prev,
        [profileId]: { remove: shouldRemove, scheduledAt: prev[profileId]?.scheduledAt },
      }));
    } else {
      setChanges(rest);
    }

    setRequireSave(true);
    toast.success(<FormattedMessage id={"TermSheets.TermSheetLayout.RemoveSuccess"} />);
  };

  /**
   * @description A member is to be added to team
   *
   * @param {Object}  profile
   */
  const handleAdd = (profile) => {
    if (!profile) return;

    const exists = originalMembers.has(profile?.id) || changes?.[profile?.id]?.add;

    if (exists) {
      toast.error(<FormattedMessage id={"TermSheets.TermSheetLayout.AddDuplicate"} />);
    } else {
      setRequireSave(true);
      setChanges((prev) => ({
        ...prev,
        [profile?.id]: {
          add: true,
          contractType: "on-demand",
          archetype: ARCHETYPES[profile?.archetype?.toLowerCase()]?.secondaryId,
          buyingQuantity: 0,
          buyingRate: profile?.rate?.amount,
          buyingCurrency: CURRENCY_NAMES.usd,
          commissionPercent: 0,
          sellingBasePrice: 0,
          sellingCurrency: CURRENCY_NAMES.cad,
          sellingDiscount: 0,
          profile,
        },
      }));
      toast.success(<FormattedMessage id={"TermSheets.TermSheetLayout.AddSuccess"} />);
    }
  };

  /**
   * @description Handles tab change. Refresh the term sheet and events when the selected tab is 0 (actual)
   */
  useEffect(() => {
    if (prevSelectedViewType === 1 && selectedViewType === 0) {
      refetchTermSheet();
      setRefetchEvents(true);
    }
  }, [selectedViewType]);

  useEffect(() => {
    setBillingAccountId(data?.billingAccountId);
    setBillingCurrency(data?.billingAccountCurrency);
    setBillingInterval(data?.billingInterval);
  }, [data]);

  /**
   * @description Set the number of columns based on team member count
   */
  useEffect(() => {
    setColumns(teamMembers && teamMembers?.length + 2); // added +2 for the empty column and "Total" column
  }, [teamMembers]);

  /**
   * @description Set team members based on scenario or current data. Set changes (if any).
   */
  useEffect(() => {
    setTeamMembers(
      scenario?.termSheetTeamState?.teamMembers ? scenario?.termSheetTeamState?.teamMembers : data?.teamMembers
    );
    setChanges(scenario?.payload);
    setRequireSave(false);
  }, [scenario, data]);

  /**
   * @description Apply changes to term sheet
   */
  useEffect(() => {
    const originalTeamMembers = scenario?.termSheetTeamState?.teamMembers || data?.teamMembers;

    if (!changes || selectedViewType === 0) {
      setTeamMembers(data?.teamMembers);
    } else {
      // Merge by first filtering out members who were added to avoid duplicate keys
      setTeamMembers([
        ...originalTeamMembers
          ?.filter((member) => !member?.add)
          ?.map((member) => ({ ...member, ...changes[member?.profile?.id] })),
        ...Object.values(changes).filter((member) => member?.add),
      ]);
    }
  }, [changes, selectedViewType]);

  /**
   * @description Calculate row totals
   */
  useEffect(() => {
    // Don't include removed members in totals
    const filteredMembers = teamMembers?.filter((member) => !member.remove);

    setTotals({
      buyingQuantity: filteredMembers?.reduce(
        (accumulator, item) => accumulator + (parseInt(item?.buyingQuantity) || 0),
        0
      ),
      convertedBuyingAmount: filteredMembers?.reduce(
        (accumulator, item) => accumulator + getConvertedBuyingAmount({ ...item, conversionRate }),
        0
      ),
      sellingBasePrice: filteredMembers?.reduce(
        (accumulator, item) => accumulator + (parseFloat(item?.sellingBasePrice) || 0),
        0
      ),
      sellingDiscount: filteredMembers?.reduce(
        (accumulator, item) => accumulator + (parseFloat(item?.sellingDiscount) || 0),
        0
      ),
      sellingAmount: filteredMembers?.reduce(
        (accumulator, item) => accumulator + getConvertedSellingAmount({ ...item, conversionRate }),
        0
      ),
      marginAmount: filteredMembers?.reduce(
        (accumulator, item) => accumulator + getConvertedMarginAmount({ ...item, conversionRate }),
        0
      ),
      marginPercent: getTotalMarginPercent(filteredMembers),
    });
  }, [teamMembers, conversionRate]);

  /**
   * @description Fetch the currency conversion rate
   */
  useEffect(() => {
    if (currencyData) {
      setConversionRate(currencyData?.conversionRate);
    }
  }, [currencyData]);

  /**
   * @description Show table when scenario tab is selected and scenario is loaded
   */
  useEffect(() => {
    const hasSelectedScenario = selectedViewType === 1 && scenario;
    const isActualView = selectedViewType === 0;
    const hasTeamMembers = teamMembers?.length > 0;

    if (isActualView) {
      setBillingAccountId(data?.billingAccountId);
      setBillingCurrency(data?.billingAccountCurrency);
      setBillingInterval(data?.billingInterval);
      setScenario(null);
    } else if (hasSelectedScenario) {
      setBillingCurrency(scenario?.billingAccount?.currencyCode);
      setBillingInterval(scenarioTypeTranslation[scenario?.type]);
    }

    setShowTable(!loading && hasTeamMembers && (hasSelectedScenario || isActualView));
    setIsEmpty(!loading && !hasTeamMembers);
    setHasScenarios(scenarios?.length > 0);
  }, [loading, teamMembers, selectedViewType, scenario, loadingScenarios, scenarios]);

  /**
   * @description Set the scenario when there is only one scenario and the tab is switched to scenario view
   */
  useEffect(() => {
    if (selectedViewType === 1 && scenarios?.length === 1) {
      setScenario(scenarios[0]);
    }
  }, [selectedViewType, scenarios]);

  useEffect(() => {
    setBillingCurrency(scenario?.billingAccount?.currencyCode);
    setBillingInterval(scenarioTypeTranslation[scenario?.type]);
  }, [scenario?.billingAccount, scenario?.type]);

  return (
    <>
      <Header marginSize={marginSize}>
        <HeaderRow>
          <Tabs
            data={[
              <FormattedMessage key={1} id="TermSheets.TermSheetLayout.TabActual" />,
              <FormattedMessage key={2} id="TermSheets.TermSheetLayout.TabScenario" />,
            ]}
            marginSize={margins.none}
            selected={selectedViewType}
            onSelect={setSelectedViewType}
            css={css`
              margin-bottom: 0;
            `}
          />
          {selectedViewType === 0 && (
            <>
              <DatePicker value={selectedDate} onChange={(_name, value) => setSelectedDate(value)} />
              {isTimeTraveling(selectedDate.split("T")[0]) && (
                <div>
                  <PrimaryLink onClick={() => setSelectedDate(new Date().toISOString())}>
                    <FormattedMessage id="Squads.Squad.TermSheet.TravelBack.Title" />
                  </PrimaryLink>
                </div>
              )}
            </>
          )}
          <TermSheetCurrencyConverter
            billingCurrency={billingCurrency}
            billingInterval={billingInterval}
            onChange={(vals) => setConvertToCurrency(vals.currency)}
          />
        </HeaderRow>
        <TermSheetScenarios
          teamId={teamId}
          actualBillingAccountId={billingAccountId}
          actualBillingInterval={billingInterval}
          selectedViewType={selectedViewType}
          onChange={setScenario}
          onSchedule={() => {
            setSelectedViewType(0);
            setScenario(null);
            setRefetchEvents(true);
          }}
          onScenarioEdit={(scenario) => {
            setBillingCurrency(scenario?.billingAccount?.currencyCode);
            setBillingInterval(scenarioTypeTranslation[scenario?.type]);
          }}
          scenario={scenario}
          changes={changes}
          requireSave={requireSave}
          onSave={() => setRequireSave(false)}
          css={styles.scenarios(selectedViewType === 1)}
        />
      </Header>
      {showTable && (
        <Container cols={columns} gap="0" rowGap="0" marginSize={marginSize} {...props}>
          <TermSheetHeaderRow
            teamMembers={teamMembers}
            onRemove={handleRemove}
            onAdd={handleAdd}
            isScenario={scenario}
          />
          <TermSheetMemberRow marginSize={marginSize} teamMembers={teamMembers} />
          <TermSheetLocationRow marginSize={marginSize} teamMembers={teamMembers} />
          {TERM_SHEET_COLUMNS({
            getBuyingAmount,
            getConvertedBuyingAmount,
            getConvertedSellingAmount,
            getConvertedMarginAmount,
            getMarginPercent,
          }).map(({ keyName, inputType, disabled, convertCurrency, ...rest }) => (
            <TermSheetRow
              key={keyName}
              keyName={keyName}
              type={TERM_SHEET_INPUT_TYPES[inputType]}
              marginSize={marginSize}
              teamMembers={teamMembers}
              onChange={handleChange}
              onRevert={handleRevert}
              total={totals[keyName]}
              changes={changes}
              disabled={!scenario || disabled || selectedViewType === 0}
              totalCurrency={convertToCurrency}
              currency={convertCurrency ? convertToCurrency : billingCurrency}
              conversionRate={convertCurrency && conversionRate}
              currencyLoading={convertCurrency && currencyLoading}
              {...rest}
            />
          ))}
          {scenario && selectedViewType === 1 && (
            <TermSheetRow
              keyName={"scheduledAt"}
              type={TERM_SHEET_INPUT_TYPES.date}
              minDate={new Date()}
              marginSize={marginSize}
              teamMembers={teamMembers}
              changes={changes}
              onChange={handleChange}
              enabled
              hideTotal
            />
          )}
        </Container>
      )}
      {isEmpty && !loadingScenarios && (selectedViewType === 0 || !hasScenarios) && (
        <EmptyContainer marginSize={marginSize}>
          <Card>
            <NoResults
              title={`TermSheets.TermSheetLayout.${!hasScenarios ? `NoScenariosTitle` : `NoResultsTitle`}`}
              description={`TermSheets.TermSheetLayout.${!hasScenarios ? `NoScenariosResults` : `NoResults`}`}
            />
            {!hasScenarios && (
              <TermSheetScenarioAddButton
                data={{
                  teamId,
                  organizationBillingAccountId: billingAccountId,
                  type: BILLING_ACCOUNT_INTERVALS[billingAccountId],
                }}
                onComplete={(payload) => {
                  setSelectedViewType(1);
                  setScenario(payload);
                }}
              />
            )}
          </Card>
        </EmptyContainer>
      )}
      {scenario && (!teamMembers || teamMembers.length === 0) && selectedViewType === 1 && (
        <EmptyContainer marginSize={marginSize}>
          <Card>
            <NoResults
              title={`TermSheets.TermSheetLayout.NoMembersTitle`}
              description={`TermSheets.TermSheetLayout.NoMembers`}
            />
            <TermSheetMemberAddButton onAdd={handleAdd} asButton />
          </Card>
        </EmptyContainer>
      )}
      <Footer>
        <code>
          <pre>{JSON.stringify(changes)}</pre>
        </code>
      </Footer>
    </>
  );
};

const TERM_SHEET_COLUMNS = ({
  getBuyingAmount,
  getConvertedBuyingAmount,
  getConvertedSellingAmount,
  getConvertedMarginAmount,
  getMarginPercent,
}) => [
  {
    keyName: "archetype",
    inputType: TERM_SHEET_INPUT_TYPES.select,
    options: Object.keys(ARCHETYPES).map((key) => ({
      label: ARCHETYPES[key].name,
      value: ARCHETYPES[key].secondaryId,
    })),
    hideTotal: true,
  },
  {
    keyName: "buyingQuantity",
    inputType: TERM_SHEET_INPUT_TYPES.number,
  },
  {
    keyName: "buyingRate",
    inputType: TERM_SHEET_INPUT_TYPES.number,
    hideTotal: true,
  },
  {
    keyName: "buyingCurrency",
    inputType: TERM_SHEET_INPUT_TYPES.select,
    options: Object.keys(CURRENCIES).map((key) => ({ label: CURRENCIES[key].label, value: CURRENCIES[key].value })),
    hideTotal: true,
  },
  {
    keyName: "commissionPercent",
    inputType: TERM_SHEET_INPUT_TYPES.percent,
    hideTotal: true,
  },
  {
    keyName: "buyingAmount",
    inputType: TERM_SHEET_INPUT_TYPES.currency,
    valueFunction: getBuyingAmount,
    disabled: true,
    hideTotal: true,
  },
  {
    keyName: "convertedBuyingAmount",
    inputType: TERM_SHEET_INPUT_TYPES.currency,
    valueFunction: getConvertedBuyingAmount,
    disabled: true,
    convertCurrency: true,
  },
  {
    keyName: "sellingBasePrice",
    inputType: TERM_SHEET_INPUT_TYPES.currency,
    hideTotal: true,
  },
  {
    keyName: "sellingDiscount",
    inputType: TERM_SHEET_INPUT_TYPES.currency,
    hideTotal: true,
  },
  {
    keyName: "sellingAmount",
    inputType: TERM_SHEET_INPUT_TYPES.currency,
    valueFunction: getConvertedSellingAmount,
    disabled: true,
    convertCurrency: true,
  },
  {
    keyName: "marginAmount",
    inputType: TERM_SHEET_INPUT_TYPES.currency,
    valueFunction: getConvertedMarginAmount,
    disabled: true,
    convertCurrency: true,
  },
  {
    keyName: "marginPercent",
    inputType: TERM_SHEET_INPUT_TYPES.percent,
    valueFunction: getMarginPercent,
    disabled: true,
  },
];

const Container = styled(Grid)`
  border-top: 1px ${colors.grayAnatomyLight4} solid;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-radius: 0 0 0.6rem 0.6rem;
  padding-right: ${({ marginSize }) => marginSize};
  padding-left: calc(${({ marginSize }) => marginSize} - 1rem);
`;

const EmptyContainer = styled.div`
  margin: ${({ marginSize }) => `0 ${marginSize} 0 ${marginSize}`};
  text-align: center;

  button {
    width: 30rem;
    margin: 0 auto;
  }
`;

const Header = styled(Col)`
  margin: ${({ marginSize }) => `0 ${marginSize} 2rem ${marginSize}`};
  row-gap: 2rem;
`;

const HeaderRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
`;

const Footer = styled(Row)`
  justify-content: space-between;
  margin: ${({ marginSize }) => `0 ${marginSize} 2rem ${marginSize}`};
  align-items: flex-end;
  margin: 3rem 4rem 0 4rem;
  background: ${colors.grayAnatomyLight5};
  padding: 1rem;

  code {
    line-height: 1.5;
    font-size: 1.5rem;
    width: 100%;
  }

  pre {
    white-space: pre-wrap;
  }
`;

const styles = {
  scenarios: (isVisible) => css`
    border-top: 1px ${colors.grayAnatomyLight4} solid;
    padding-top: 2rem;
    display: ${isVisible ? "flex" : "none"};
  `,
};

TermSheet.propTypes = {
  teamId: PropTypes.string.isRequired,
  marginSize: PropTypes.string,
  selectedDate: PropTypes.string.isRequired,
  selectedViewType: PropTypes.number.isRequired,
  setSelectedViewType: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  setRefetchEvents: PropTypes.func.isRequired,
};

TermSheet.defaultProps = {
  teamId: PropTypes.string.isRequired,
  marginSize: margins.normal,
};

export default TermSheet;
