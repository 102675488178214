import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import Icon, { ICON_SIZE } from "components/Icon";

/**
 * Shows selectable boxes with title, body and icon
 *
 * @params {String}   name
 * @params {String}   value
 * @params {Array}    options
 * @params {Function} onChange
 */
const BoxSelector = ({ name, value, options, onChange }) => (
  <div css={styles.container}>
    {options.map((item, index) => (
      <BoxItem key={index} {...item} isSelected={value === item.value} onChange={(val) => onChange(name, val)} />
    ))}
  </div>
);

/**
 * The Box item
 *
 * @params {String}   title
 * @params {String}   body
 * @params {String}   icon
 * @params {String}   value
 * @params {Function} onChange
 * @params {Boolean}  isSelected
 */
const BoxItem = ({ title, body, icon, value, isSelected, onChange }) => (
  <div css={styles.box(isSelected)} onClick={() => onChange(value)}>
    <div css={styles.icon_container}>
      <Icon type={icon} size={ICON_SIZE.xxlarge} color={"inherit"} />
    </div>
    <div>
      <div css={styles.label}>{title}</div>
      {isSelected && <div css={styles.body}>{body}</div>}
    </div>
  </div>
);

const styles = {
  container: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyBase};
    line-height: normal;
    font-weight: normal;
  `,
  label: css`
    font-weight: 600;
    font-size: ${fontSize.normal};
  `,
  icon_container: css`
    flex: 0 0 8rem;
    text-align: center;
  `,
  box: (isSelected) => css`
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem 1rem 0;
    background: ${colors.grayAnatomyLight5};
    margin-bottom: 1rem;
    border-radius: 6px;
    transition: transform 0.3s ease;
    border: 1px transparent solid;
    cursor: pointer;
    color: ${colors.grayAnatomyBase};

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      color: ${colors.purpleRainBase};
      border: 1px ${colors.grayAnatomyLight4} solid;
      background: #fff;
      box-shadow: 0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.06);
      transform: scale(1.05);
    }

    ${isSelected &&
    `
      padding: 2rem 2.4rem 2rem 0;
      transform: scale(1) !important;
      background:#fff !important;
      border: 1px ${colors.purpleRainBase} solid !important;
    `}
  `,
  body: css`
    margin-top: 0.5rem;
    color: ${colors.grayAnatomyBase};
    font-size: ${fontSize.small};
  `,
};

BoxItem.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isSelected: PropTypes.bool,
};

BoxSelector.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.array.isRequired,
};

export default BoxSelector;
