import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { PieChart } from "react-minimal-pie-chart";
import { FormattedMessage } from "react-intl";
import { colors, fontSize } from "style";
import { DataTableProvider } from "components/DataTable";
import { PAGINATION_TYPE } from "components/Pagination";
import Card from "components/Card";
import { formatNumber, formatHours } from "utils";
import { RenderMemberList } from "components/DisplayBox";
import Tooltip from "components/Tooltip";

/**
 * EffortReportList
 *
 * @param {Array}    data
 * @param {Boolean}  loading
 * @param {Object}   error
 * @param {Number}   handleNextPage,
 * @param {Number}   handlePreviousPage,
 * @param {Number}   hasNextPage,
 * @param {Number}   hasPreviousPage
 * @param {Number}   resultsPerPage,
 */
const EffortReportList = ({
  data,
  loading,
  error,
  handleNextPage,
  handlePreviousPage,
  hasNextPage,
  hasPreviousPage,
  resultsPerPage,
}) => (
  <>
    <Card>
      <DataTableProvider
        data={data}
        error={error}
        loading={loading}
        columns={COLUMNS(loading)}
        selectable={false}
        resultsPerPage={resultsPerPage}
        paginationType={PAGINATION_TYPE.cursor}
        paginationProps={{
          handleNextPage,
          handlePreviousPage,
          hasNextPage,
          hasPreviousPage,
          resultsPerPage,
        }}
      />
    </Card>
  </>
);

const COLUMNS = (loading) => [
  {
    label: <FormattedMessage id="Squads.EffortReport.TableColumnTeamMember" />,
    width: "40%",
    cellProps: {
      css: style.root,
    },
    cell: ({ profile }) => <RenderMemberList data={profile} loading={loading} />,
  },
  {
    width: "20%",
    cellProps: {
      css: style.pie,
    },
    label: (
      <div css={style.pie_header}>
        <FormattedMessage id="Squads.EffortReport.TableColumnTimeAllocation" />
      </div>
    ),
    cell: (row) => (
      <PieChart
        style={{ height: `5rem` }}
        animate={true}
        data={[
          {
            title: <FormattedMessage id="Squads.EffortReport.TableColumnProduction" />,
            value: row?.data?.percentages?.production,
            color: colors.blueVelvetBase,
          },
          {
            title: <FormattedMessage id="Squads.EffortReport.TableColumnPlanning" />,
            value: row?.data?.percentages?.planning,
            color: colors.orangeCountyBase,
          },
          {
            title: <FormattedMessage id="Squads.EffortReport.TableColumnCommunication" />,
            value: row?.data?.percentages?.communication,
            color: colors.pinkPantherBase,
          },
          {
            title: <FormattedMessage id="Squads.EffortReport.TableColumnReveiw" />,
            value: row?.data?.percentages?.review,
            color: colors.purpleRainBase,
          },
        ]}
      />
    ),
  },
  {
    label: (
      <div css={[style.label, style.label_production]}>
        <>
          <Tooltip tooltipId="Squads.EffortReport.TableColumnProduction.Tooltip">
            <FormattedMessage id="Squads.EffortReport.TableColumnProduction" />
          </Tooltip>
        </>
      </div>
    ),
    width: "12%",
    cellProps: {
      css: [style.td_highlight, style.td],
    },
    cell: (row) => (
      <TimeDisplay minutes={row?.data?.minutes?.production} percent={row?.data?.percentages?.production} />
    ),
  },
  {
    label: (
      <div css={[style.label, style.label_planning]}>
        <>
          <Tooltip tooltipId="Squads.EffortReport.TableColumnPlanning.Tooltip">
            <FormattedMessage id="Squads.EffortReport.TableColumnPlanning" />
          </Tooltip>
        </>
      </div>
    ),
    width: "10%",
    cellProps: {
      css: style.td,
    },
    cell: (row) => <TimeDisplay minutes={row?.data?.minutes?.planning} percent={row?.data?.percentages?.planning} />,
  },
  {
    label: (
      <div css={[style.label, style.label_communication]}>
        <>
          <Tooltip tooltipId="Squads.EffortReport.TableColumnCommunication.Tooltip">
            <FormattedMessage id="Squads.EffortReport.TableColumnCommunication" />
          </Tooltip>
        </>
      </div>
    ),
    cellProps: {
      css: [style.td_highlight, style.td],
    },
    width: "15%",
    cell: (row) => (
      <TimeDisplay minutes={row?.data?.minutes?.communication} percent={row?.data?.percentages?.communication} />
    ),
  },
  {
    label: (
      <div css={[style.label, style.label_review]}>
        <>
          <Tooltip tooltipId="Squads.EffortReport.TableColumnReveiw.Tooltip">
            <FormattedMessage id="Squads.EffortReport.TableColumnReveiw" />
          </Tooltip>
        </>
      </div>
    ),
    cellProps: {
      css: style.td,
    },
    width: "10%",
    cell: (row) => <TimeDisplay minutes={row?.data?.minutes?.review} percent={row?.data?.percentages?.review} />,
  },
];

/**
 *
 * @param {Number} hours
 * @param {Number} percent
 */
const TimeDisplay = ({ minutes, percent }) => {
  if (!minutes) {
    return <div css={style.value_empty}>—</div>;
  }

  return (
    <div css={style.value_container}>
      <div css={style.value_hours}>{formatHours(minutes)}</div>
      <div css={style.value_percent}>{formatNumber(percent, "%", 0)}</div>
    </div>
  );
};

const style = {
  label: css`
    font-size: 1.2rem;
    line-height: 2;
    width: 100%;
    display: block;
    padding-left: 1rem;
    margin-left: -1rem;
  `,
  value_container: css`
    display: flex;
    flex-direction: column;
    line-height: 1.25;
  `,
  value_hours: css`
    font-weight: 600;
    font-size: ${fontSize.small};
  `,
  value_percent: css`
    font-size: ${fontSize.xsmall};
    color: ${colors.grayAnatomyLight6};
  `,
  value_empty: css`
    color: ${colors.grayAnatomyLight3};
  `,
  root: css`
    padding: 1rem 0 0 0;
  `,
  pie: css`
    border-left: 1px ${colors.grayAnatomyLight3} dashed;
    text-align: center;
  `,
  pie_header: css`
    text-align: center;
  `,
  td: css``,
  td_highlight: css`
    background: ${colors.grayAnatomyLight5};
  `,
  label_production: css`
    border-left: 3px ${colors.blueVelvetBase} solid;
  `,
  label_planning: css`
    border-left: 3px ${colors.orangeCountyBase} solid;
  `,
  label_communication: css`
    border-left: 3px ${colors.pinkPantherBase} solid;
  `,
  label_review: css`
    border-left: 3px ${colors.purpleRainBase} solid;
  `,
};

TimeDisplay.propTypes = {
  minutes: PropTypes.number,
  percent: PropTypes.number,
};

EffortReportList.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
  handleNextPage: PropTypes.func,
  handlePreviousPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  hasPreviousPage: PropTypes.bool,
  resultsPerPage: PropTypes.number,
};

EffortReportList.defaultProps = {
  resultsPerPage: 10,
};

export default EffortReportList;
