import React, { useContext } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ThemeContext, fontSize, colors, breakpoints } from "style";
import { TextLoader } from "components/Loader";

/**
 * Heading 3
 */
const StyledH3 = styled.h3`
  margin: 0;
  color: ${colors.purpleRainDark2};
  font-size: ${fontSize.normal};
  font-weight: 500;

  ${(props) => props.themeStyle}

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${fontSize.small};
  }
`;

const H3 = ({ loading, ...props }) => {
  const theme = useContext(ThemeContext);

  if (loading) {
    return (
      <StyledH3 themeStyle={theme.h1} loading={loading} {...props}>
        <TextLoader css={styles.loader} />
      </StyledH3>
    );
  } else {
    return <StyledH3 themeStyle={theme.h1} {...props} />;
  }
};

H3.propTypes = {
  loading: PropTypes.bool,
};

const styles = {
  loader: css`
    width: 50rem;
    mac-width: 100%;
    height: 2.5rem;
    background: ${colors.grayAnatomyLight4};
  `,
};

export default H3;
