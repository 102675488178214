export { default as SquadInsights } from "./SquadInsights";
export { default as LabelForCommentMetric } from "./LabelForCommentMetric";
export { default as LabelForOutlierMetric } from "./LabelForOutlierMetric";
export { default as LabelForEvaluationMetric } from "./LabelForEvaluationMetric";
export { default as LabelForOutlierInverseMetric } from "./LabelForOutlierInverseMetric";
export { default as SquadInsightItem } from "./SquadInsightItem";

const getEntityName = (insight) => (insight.entity === "TEAM" ? insight.team?.name : insight.profile?.name);

export { getEntityName };
