import React from "react";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { CLIENT } from "constants/index";
import { LOGOUT } from "graphql/mutations";
import { useToast } from "hooks";

export default function useLogout({ messageId = "useLogout.Success" } = {}) {
  const { toast } = useToast();
  const [logout] = useMutation(LOGOUT);

  const handleLogout = (callback) => {
    logout({
      onCompleted: () => {
        if (messageId) {
          toast.success(<FormattedMessage id={messageId} />);
        }
        localStorage.removeItem("token");
        localStorage.removeItem("client");
        localStorage.removeItem("uid");
        localStorage.removeItem("expiry");
        CLIENT.cache.reset();

        if (callback) callback();
      },
    });
  };

  return {
    handleLogout,
  };
}
