import { gql } from "@apollo/client";

const GET_INSIGHTS = gql`
  query insightCharts {
    insightCharts {
      insightsTimeChartLink
      insightsCommitChartLink
    }
  }
`;

export { GET_INSIGHTS };
