import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import Tooltip from "components/Tooltip";

/**
 * Shows a radio button group
 *
 * @param {String}   name
 * @param {Any}      value
 * @param {Array}    options
 * @param {String}   position
 * @param {String}   direction
 * @param {Function} onChange
 */

const Radio = ({ name, value, options, direction, size, onChange }) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(name, e.target.value);
    }
  };

  const option = (item, index) => (
    <div key={`radio-${name}-${index}`} css={styles.item_container}>
      <StyledRadio
        key={index}
        id={`radio-${name}-${index}`}
        value={item.value}
        name={name}
        onChange={handleChange}
        type="radio"
        checked={item.value === value}
        size={size}
      />
      <label htmlFor={`radio-${name}-${index}`} css={styles.label(size || item.size)}>
        {item.label || item.value}
      </label>
    </div>
  );

  return (
    <div css={styles.container(direction, size)}>
      {options.map((item, index) =>
        item.description ? (
          <Tooltip key={`radio-${name}-${index}`} tooltipId={item.description} hideIcon={true}>
            {option(item, index)}
          </Tooltip>
        ) : (
          option(item, index)
        )
      )}
    </div>
  );
};

/**
 * CheckBox
 */
const StyledRadio = styled.input`
  width: ${({ size }) => (size === RADIO_SIZE.small ? `1.5rem` : `2rem`)};
  height: ${({ size }) => (size === RADIO_SIZE.small ? `1.5rem` : `2rem`)};
  border-radius: 100%;
  border: solid 1px ${colors.grayAnatomyLight3};
  background: #fff;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  appearance: none;
  color-adjust: exact;

  &:hover:not(:disabled) {
    border-color: ${colors.purpleRainBase};
  }

  &:checked {
    ${(props) =>
      props.inTable &&
      `
      background: ${colors.purpleRainDark1};
      border-color: ${colors.purpleRainDark1};
    `}

    &:after {
      content: " ";
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 100%;
      background: ${(props) => (props.inTable ? `#fff` : colors.purpleRainBase)};
    }
  }
`;

const styles = {
  container: (direction, size) => css`
    order: 1;

    ${direction === RADIO_DIRECTION.horizontal &&
    `
      display: flex;
      gap: ${size === RADIO_SIZE.small ? `1rem` : `2rem`};
    `}
  `,
  item_container: css`
    display: flex;
    align-items: center;
  `,
  label: (size) => css`
    margin-left: ${size === RADIO_SIZE.small ? `0.5rem` : `1rem`};
    font-size: ${fontSize.small};
    font-size: ${size === RADIO_SIZE.small && fontSize.xxsmall};
    font-size: ${size === RADIO_SIZE.xlarge && fontSize.xlarge};
    font-weight: 400;
  `,
};

const RADIO_DIRECTION = {
  horizontal: "horizontal",
  vertical: "vertical",
};

const RADIO_SIZE = {
  small: "small",
  large: "large",
  xlarge: "xlarge",
};

Radio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  direction: PropTypes.string,
  size: PropTypes.string,
};

export { RADIO_DIRECTION, RADIO_SIZE };

export default Radio;
