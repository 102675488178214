import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { formatCurrency } from "utils";
import RenderMemberGroupList from "components/DisplayBox/Renderers/RenderMemberGroupList";
import { RecommendedSquadDetails } from "components/Squads";
import { TERM_SHEET_TYPES } from "constants/index";

/**
 * RecommendedSquad
 *
 * @params {Object}   squad
 * @params {Boolean}  loading
 */
const RecommendedSquad = ({ squad, loading }) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <RenderMemberGroupList
        title={squad?.name}
        subtitle={
          <FormattedMessage
            id="Squads.RecommendedSquads.Price"
            values={{
              type: TERM_SHEET_TYPES[squad?.type],
              price: <b>{formatCurrency(squad?.rate, squad?.currency)}</b>,
            }}
          />
        }
        users={squad?.assignments.map((a) => ({ ...a.profile, ...a }))}
        thumbCount={collapse ? 0 : squad?.assignments.length}
        thumbLimit={collapse ? 0 : squad?.assignments.length}
        loading={loading}
      />
      <RecommendedSquadDetails collapse={collapse} loading={loading} squad={squad} handleToggle={setCollapse} />
    </>
  );
};

RecommendedSquad.propTypes = {
  squad: PropTypes.object,
  loading: PropTypes.bool,
};

export default RecommendedSquad;
