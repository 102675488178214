import React from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { formatCurrency } from "utils";
import { colors, fontSize } from "style";
import { Col, Row } from "components/Containers";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { RATE_FREQUENCIES } from "constants";
import Tooltip from "components/Tooltip";

/**
 * ProfileRateRange
 *
 * @param {String}  currency
 * @param {Number}  low
 * @param {Number}  high
 * @param {String}  currencyDisplay
 * @param {Number}  negotiatedRate
 * @param {String}  negotiatedRateCurrency
 * @param {Boolean} includesCommission
 * @param {String}  frequency
 * @param {Object}  assignedSellingRate
 */
const ProfileRateRange = ({
  currency,
  low,
  high,
  currencyDisplay,
  negotiatedRate,
  negotiatedRateCurrency,
  includesCommission,
  frequency,
  assignedSellingRate,
  ...props
}) => (
  <Col css={styles.outer_container}>
    <div css={styles.container(includesCommission)} {...props}>
      {low && high ? (
        <>
          <div css={styles.currency(includesCommission)}>{currency}</div>
          <div css={styles.rate(negotiatedRate)}>
            <FormattedMessage
              id="Profile.ProfileRateRange.Rate"
              values={{
                min: formatCurrency(low, currency, currencyDisplay, true),
                max: Math.round(high),
                frequency: (
                  <FormattedMessage
                    id={`Global.${String(frequency).toUpperCase() === RATE_FREQUENCIES.hourly ? `PerHour` : `PerDay`}`}
                  />
                ),
              }}
            />
          </div>
        </>
      ) : (
        <div css={styles.unavailable}>
          <FormattedMessage id="Teams.TeamSummary.PriceNotSet" />
        </div>
      )}
    </div>
    {negotiatedRate && (
      <Row css={[styles.point_container, styles.negotiated]}>
        <Icon type={ICON_TYPE.checkDotted} size={ICON_SIZE.large} color={colors.green} />
        <FormattedMessage
          id="Profile.ProfileRateRange.NegotiatedRate"
          values={{
            rate: <b>{formatCurrency(parseInt(negotiatedRate), negotiatedRateCurrency, "code", true)}</b>,
          }}
        />
      </Row>
    )}
    {includesCommission && (
      <Row css={[styles.point_container, styles.commission]}>
        <Icon type={ICON_TYPE.checkDotted} size={ICON_SIZE.large} color={"inherit"} />
        <span>
          <FormattedMessage id="Profile.ProfileRateRange.IncludesCommission" />
        </span>
      </Row>
    )}
    {assignedSellingRate && assignedSellingRate?.amount && (
      <Tooltip tooltipId="Profile.ProfileRateRange.AssignedRateInfo">
        <Row css={styles.point_container}>
          <Icon type={ICON_TYPE.clock} size={ICON_SIZE.large} color={"inherit"} />
          <span>
            <FormattedMessage
              id="Profile.ProfileRateRange.AssignedRate"
              values={{
                rate: (
                  <b>
                    {formatCurrency(parseInt(assignedSellingRate?.amount), assignedSellingRate?.currency, "code", true)}
                  </b>
                ),
                frequency: (
                  <FormattedMessage
                    id={`Global.${
                      String(assignedSellingRate?.frequency).toUpperCase() === RATE_FREQUENCIES.hourly
                        ? `PerHour`
                        : `PerDay`
                    }`}
                  />
                ),
              }}
            />
          </span>
        </Row>
      </Tooltip>
    )}
  </Col>
);

const styles = {
  point_container: css`
    font-size: ${fontSize.xxsmall};
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    color: ${colors.purpleRainDark2};
    border-radius: 0.5rem;

    b {
      font-weight: 600;
    }
  `,
  unavailable: css`
    padding: 0.25rem 0.5rem;
  `,
  container: (includesCommission) => css`
    border: 1px ${includesCommission ? colors.pinkPantherBase : colors.grayAnatomyLight2} solid;
    border-radius: 1.6rem;
    color: ${includesCommission ? colors.pinkPantherBase : colors.purpleRainDark2};
    background: ${includesCommission ? colors.pinkPantherLight1 : colors.grayAnatomyLight4};
    line-height: 1.33;
    display: inline-flex;
    font-size: 1.2rem;
    font-weight: 400;
    font-size: ${fontSize.xxsmall};
    box-shadow: 0px 1px 3px ${colors.grayAnatomyLight5};
  `,
  outer_container: css`
    justify-content: flex-start;
    gap: 0.5rem;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  negotiated_container: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  negotiated: css`
    font-size: ${fontSize.xxsmall};
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    text-align: center;
    justify-content: center;
    color: ${colors.green};=
    border-radius: 1rem;
    background: ${colors.lightGreen};
    padding: 0.25rem 0.5rem 0.1rem 0.25rem;
    position: relative;
    margin-bottom: 0.5rem;
    margin-left: -0.25rem;

    &:before {
      content: " ";
      position: absolute;
      top: -7px;
      left: calc(50% - 9px);
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 7px solid ${colors.lightGreen};
    }
  `,
  rate: (isNegotiated) => css`
    padding: 0.5rem 1rem 0.3rem 0.5rem;
    border-radius: 0 1.6rem 1.6rem 0;
    background: #fff;

    ${isNegotiated &&
    `
      text-decoration: line-through;
      opacity: 0.6;
    `}
  `,
  currency: (includesCommission) => css`
    border-right: 1px solid ${includesCommission ? colors.pinkPantherBase : colors.grayAnatomyLight4};
    padding: 0.5rem 0.5rem 0.3rem 1rem;
    font-weight: 500;
  `,
  commission: css`
    color: ${colors.pinkPantherBase};
  `,
};

ProfileRateRange.defaultProps = {
  currencyDisplay: "symbol",
  includesCommission: false,
  frequency: "hourly",
};

ProfileRateRange.propTypes = {
  currency: PropTypes.string,
  low: PropTypes.number,
  high: PropTypes.number,
  currencyDisplay: PropTypes.string,
  negotiatedRate: PropTypes.number,
  negotiatedRateCurrency: PropTypes.string,
  includesCommission: PropTypes.bool,
  frequency: PropTypes.string,
  assignedSellingRate: PropTypes.object,
};

export default ProfileRateRange;
