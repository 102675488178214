import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { toUcFirst } from "utils";
import { getEntityName } from "components/Squads/Insights";

/**
 * LabelForCommentMetric
 *
 * @param {Object}  insight
 * @param {String}  word
 */
const LabelForCommentMetric = ({ insight, word }) => {
  const { insightType, insightMetric, observationPeriod, observationTargetName, valueString } = insight;

  return (
    <FormattedMessage
      id="Squads.SquadInsights.Pulse"
      values={{
        entityName: getEntityName(insight),
        insightType: toUcFirst(insightType),
        word,
        valueString,
        insightMetric,
        observationPeriod,
        observationTargetName,
      }}
    />
  );
};

LabelForCommentMetric.propTypes = {
  insight: PropTypes.object,
  word: PropTypes.string,
};

export default LabelForCommentMetric;
