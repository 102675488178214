import React from "react";
import PropTypes from "prop-types";
import { capitalizeFirst } from "utils";
import { KeyValueText } from "components/ActivityTooltip";

/**
 * ReviewActivityTooltip
 *
 * @params {Object}      tooltipData
 */
const ReviewActivityTooltip = ({ tooltipData }) => {
  const {
    pullRequestTitle,
    repository,
    isMembersView,
    repositories,
    status,
    messageLength,
    count,
    pullRequestTitles,
    users,
  } = tooltipData;

  return isMembersView ? (
    <>
      <div>{capitalizeFirst(status)}</div>
      <div>
        <KeyValueText id="Message Length" value={messageLength} />
      </div>
      <div>
        <KeyValueText id="Repository" value={repository} />
      </div>
      <div>
        <KeyValueText id="Pull Request" value={pullRequestTitle} />
      </div>
    </>
  ) : (
    <>
      <div>
        <KeyValueText id="Count" value={count} />
      </div>
      <div>
        <ul>
          {pullRequestTitles.map((title) => (
            <li key={title}>{title}</li>
          ))}
        </ul>
      </div>
      <div>
        <KeyValueText id="Repositories" value={repositories.join(", ")} />
      </div>
      <div>
        <KeyValueText id="Users" value={users.join(", ")} />
      </div>
    </>
  );
};

ReviewActivityTooltip.propTypes = {
  tooltipData: PropTypes.object,
};

export default ReviewActivityTooltip;
