import { gql } from "@apollo/client";

const GET_TEAM_INSIGHTS = gql`
  query GetTeamInsights($teamId: ID!, $filters: TeamInsightFilter, $paginationInfo: PaginationInfo) {
    teamInsights(teamId: $teamId, filters: $filters, paginationInfo: $paginationInfo) {
      totalCount
      currentPage
      perPage
      currentOffset
      nodes {
        team {
          id
          uuid
          name
        }
        profile {
          id
          uuid
          name
        }
        insightType
        insightMetric
        timestamp
        entity
        observationPeriod
        valueType
        value
        valueDelta
        valueDeltaPercent
        valueString
        observationPeriodComparative
        sentiment
      }
    }
  }
`;

export { GET_TEAM_INSIGHTS };
