import React, { useCallback, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { startOfWeek, subWeeks, endOfWeek, format } from "date-fns";
import { useSquadActivity } from "hooks";
import { CodeTelemetryPeriodSelector, ScopedRepositoriesFilter } from "components/Squads/Engineering";
import Card from "components/Card";
import { Grid } from "components/Containers";
import { MessagesNoResults } from "components/Messages";
import PageTitle from "components/PageTitle";
import { SectionTitle } from "components/Layouts";
import { ChartDataProvider, HorizontalBar, LineArea, StackedClusteredVerticalBar } from "components/Charts";
import { generateWeekKeys } from "utils";

/**
 * CodeTelemetry
 */
const CodeTelemetry = () => {
  const { settings } = useSquadActivity();
  const { selectedSquad: data, loading, id } = useOutletContext();
  const today = new Date();
  const options = { weekStartsOn: 1 }; // Monday as the start of the week
  const beginningOfCurrentWeek = startOfWeek(today, options);
  const startDate = subWeeks(beginningOfCurrentWeek, 4);
  const endDate = endOfWeek(subWeeks(beginningOfCurrentWeek, 1), options);
  const defaultDateRange = [format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd")];
  const [gitRepositories, setGitRepositories] = useState();
  const [selectedGitRepositories, setSelectedGitRepositories] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(defaultDateRange);
  const [weekKeys, setWeekKeys] = useState([]);
  const filteredProfiles = []; // const [filteredProfiles, setFilteredProfiles] = useState([]);
  const intl = useIntl();

  const handleChange = (_name, value) => setSelectedDateRange(value);
  const handleRepositoriesChange = (_name, value) => setSelectedGitRepositories(value);

  const defaultChartProps = useCallback(
    () => ({
      teamId: id,
      loading,
      filteredProfiles,
      skip: loading || !id || weekKeys?.length === 0,
      variables: {
        teamId: id,
        filters: {
          weekKeys,
          repositories: selectedGitRepositories.map((repo) => repo.id),
        },
      },
    }),
    [id, loading, weekKeys, filteredProfiles, selectedGitRepositories]
  );

  const periodMinDate = (minMonth) => {
    const periodMinDate = new Date();
    periodMinDate.setMonth(periodMinDate.getMonth() - minMonth);
    periodMinDate.setDate(1);
    return periodMinDate;
  };

  useEffect(() => {
    if (data?.gitRepositories) {
      setGitRepositories(data?.gitRepositories);
    }
  }, [data?.gitRepositories]);

  useEffect(() => {
    if (gitRepositories && window.location.hash) {
      const id = window.location.hash.substring(1);
      const element = document.getElementById(id);

      if (element) element.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, [gitRepositories]);

  useEffect(() => {
    setWeekKeys(generateWeekKeys(selectedDateRange[0], selectedDateRange[1]));
  }, [selectedDateRange]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.Snapshot.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            subtitle={intl.formatMessage({ id: "Squads.Squad.Snapshots.Activity" })}
            actionBar={
              <>
                <ScopedRepositoriesFilter
                  id={id}
                  gitRepositories={gitRepositories}
                  onChange={handleRepositoriesChange}
                  value={selectedGitRepositories}
                  disabled={!gitRepositories}
                  loading={loading}
                />
                <CodeTelemetryPeriodSelector
                  value={selectedDateRange}
                  onChange={handleChange}
                  disabled={!gitRepositories}
                  minDate={periodMinDate(3)}
                />
              </>
            }
          />
        </Grid.col>
        {!loading && !gitRepositories && (
          <Grid.col start={1} end={13}>
            <Card>
              <MessagesNoResults title="Squads.NoReposTitle" description="Squads.NoReposDescription" />
            </Card>
          </Grid.col>
        )}
        {(loading || gitRepositories) && (
          <>
            {/*
            //Hide filter feature until its working for all contributors - not just member
            <Grid.col start={1} end={13}>
              <SquadMemberFilter teamId={id} onChange={setFilteredProfiles} selected={filteredProfiles} />
            </Grid.col>
            */}
            <Grid.col start={1} end={7}>
              <Card>
                <ChartDataProvider {...defaultChartProps()} {...settings.activeDays} renderer={HorizontalBar} />
              </Card>
            </Grid.col>
            <Grid.col start={7} end={13}>
              <Card>
                <ChartDataProvider {...defaultChartProps()} {...settings.codingCommits} renderer={HorizontalBar} />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                <ChartDataProvider {...defaultChartProps()} {...settings.aggregateComments} renderer={LineArea} />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <SectionTitle id="volume" title={<FormattedMessage id="Squads.Squad.Snapshots.Volume" />} />
            </Grid.col>
            <Grid.col start={1} end={7}>
              <Card>
                <ChartDataProvider {...defaultChartProps()} {...settings.totalCommits} renderer={HorizontalBar} />
              </Card>
            </Grid.col>
            <Grid.col start={7} end={13}>
              <Card>
                <ChartDataProvider {...defaultChartProps()} {...settings.pullRequestMerged} renderer={HorizontalBar} />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                <ChartDataProvider {...defaultChartProps()} {...settings.cumulativeLoc} renderer={LineArea} />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                <ChartDataProvider
                  {...defaultChartProps()}
                  {...settings.commitsBreakdown}
                  renderer={StackedClusteredVerticalBar}
                />
              </Card>
            </Grid.col>
            {filteredProfiles?.length === 0 && (
              <>
                <Grid.col start={1} end={13}>
                  <SectionTitle id="efficiency" title={<FormattedMessage id="Squads.Squad.Snapshots.Efficiency" />} />
                </Grid.col>
                <Grid.col start={1} end={7}>
                  <Card>
                    <ChartDataProvider
                      {...defaultChartProps()}
                      {...settings.oldestOpenPrs}
                      skip={loading || !id}
                      variables={{
                        teamId: id,
                        filters: { repositories: selectedGitRepositories.map((repo) => repo.id) },
                      }}
                      renderer={HorizontalBar}
                    />
                  </Card>
                </Grid.col>
                <Grid.col start={7} end={13}>
                  <Card>
                    <ChartDataProvider
                      {...defaultChartProps()}
                      {...settings.largestPullRequests}
                      renderer={HorizontalBar}
                    />
                  </Card>
                </Grid.col>
                <Grid.col start={1} end={7}>
                  <Card>
                    <ChartDataProvider
                      {...defaultChartProps()}
                      {...settings.pullRequestStatus}
                      renderer={StackedClusteredVerticalBar}
                    />
                  </Card>
                </Grid.col>
                <Grid.col start={7} end={13}>
                  <Card>
                    <ChartDataProvider {...defaultChartProps()} {...settings.openVsMerged} renderer={LineArea} />
                  </Card>
                </Grid.col>
                <Grid.col start={1} end={7}>
                  <Card>
                    <ChartDataProvider
                      {...defaultChartProps()}
                      {...settings.pullRequestMergeTime}
                      renderer={StackedClusteredVerticalBar}
                    />
                  </Card>
                </Grid.col>
                <Grid.col start={7} end={13}>
                  <Card>
                    <ChartDataProvider {...defaultChartProps()} {...settings.averageMergeTime} renderer={LineArea} />
                  </Card>
                </Grid.col>
                <Grid.col start={1} end={7}>
                  <Card>
                    <ChartDataProvider
                      {...defaultChartProps()}
                      {...settings.pullRequestSizeBreakdown}
                      renderer={StackedClusteredVerticalBar}
                    />
                  </Card>
                </Grid.col>
                <Grid.col start={7} end={13}>
                  <Card>
                    <ChartDataProvider {...defaultChartProps()} {...settings.locModified} renderer={LineArea} />
                  </Card>
                </Grid.col>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default CodeTelemetry;
