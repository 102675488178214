const ROLES = {
  developer: "developer",
  tech_lead: "tech_lead",
  squad_lead: "squad_lead",
  business_analyst: "business_analyst",
  designer: "designer",
  qa: "qa"
};

export default ROLES;
