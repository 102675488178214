import React from "react";
import { css } from "@emotion/react";
import Icon, { ICON_TYPE } from "components/Icon";
import { colors } from "style";

/**
 * CloseIcon
 */
const CloseIcon = (props) => (
  <div css={styles.icon_container} {...props}>
    <Icon type={ICON_TYPE.close} color={"inherit"} />
  </div>
);

const styles = {
  icon_container: css`
    background: ${colors.grayAnatomyDark};
    color: #fff;
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1rem;
    right: -1rem;
    transition: all 0.3s ease;
    cursor: pointer;

    > * {
      transition: all 0.3s ease;
    }

    &:hover {
      transform: scale(1.2);

      > * {
        transform: rotate(180deg);
      }
    }
  `,
};

export default CloseIcon;
