import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { LINKS } from "constants/index";
import { PageWrapper, Grid } from "components/Containers";
import PageTitle from "components/PageTitle";
import { ActivityLogLayout, ActivityPeriodSelector } from "components/ActivityLog";
import { useActivityLogs, useTeam } from "hooks";
import { getCurrentUTCDate } from "utils";
import { ChatWindow } from "components/Chat";
import { PrimaryLink } from "components/Links";

/**
 * Team Activity Logs Dashboard
 */
const DashboardActivityLog = () => {
  const [selectedDate, setSelectedDate] = useState(getCurrentUTCDate());

  const intl = useIntl();
  const { id } = useParams();
  const { data, days, loading, error } = useActivityLogs({ teamId: id, selectedDate });
  const { data: teamData } = useTeam({ id });

  const handleChange = (_name, value) => {
    setSelectedDate(value);
  };

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Dashboard.ActivityLog.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              backLink={
                <PrimaryLink to={LINKS.dashboard_home} routerEnabled>
                  <FormattedMessage id="Meta.Meta.Dashboard.Title" />
                </PrimaryLink>
              }
              title={<FormattedMessage id="Dashboard.ActivityLog.Title" />}
              actionBar={<ActivityPeriodSelector value={selectedDate} onChange={handleChange} />}
            />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <ActivityLogLayout data={data} days={days} loading={loading} error={error} />
          </Grid.col>
        </Grid>
      </PageWrapper>
      {/* TODO: Make chatbot visible after 7th Sep 2023 demo */}
      {false && <ChatWindow teamId={teamData?.id} />}
    </>
  );
};

export default DashboardActivityLog;
