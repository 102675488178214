import React, { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { chart_colors, colors } from "style";
import { initializeAmCharts } from "utils";
import { Spinner } from "components/Loader";
import { MessagesError, MessagesNoResults } from "components/Messages";

/**
 * LineArea
 *
 * @param {Array}   data
 * @param {String}  height
 * @param {Boolean} loading
 * @param {String}  error
 */
const LineArea = ({ data, loading, error, height, ...props }) => {
  const [rootId] = useState(`line-area-${Math.random()}`);

  useLayoutEffect(() => {
    if (!data?.data || loading) return;

    initializeAmCharts();

    let root = am5.Root.new(rootId);
    let customTheme = am5.Theme.new(root);

    customTheme.rule("AxisLabel", ["minor"]).setAll({
      fill: am5.color(0xff0000),
      fontSize: 11,
      dy: 1,
    });

    customTheme.rule("Grid", ["minor"]).setAll({
      strokeOpacity: 0.08,
    });

    customTheme.rule("Label").setAll({
      fontFamily: "Matter",
      fill: am5.color(colors.grayAnatomyBase),
      fontSize: 11,
      paddingTop: 10,
    });

    root.setThemes([am5themes_Animated.new(root), customTheme]);

    // Create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout,
        pinchZoomX: true,
        paddingRight: 10,
        paddingTop: 50,
        paddingBottom: 0,
        paddingLeft: 5,
      })
    );

    chart.zoomOutButton.set("forceHidden", true);

    chart.get("colors").set("colors", chart_colors.categorical);

    // Add cursor
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minorGridEnabled: true,
    });

    xRenderer.grid.template.set("location", 0.5);
    xRenderer.labels.template.setAll({
      location: 0.5,
      multiLocation: 0.5,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "bucket",
        renderer: xRenderer,
      })
    );

    xRenderer.grid.template.set("strokeDasharray", [3]);

    xAxis.data.setAll(data.data);

    let yRenderer = am5xy.AxisRendererY.new(root, {
      inversed: false,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        maxDeviation: 1,
        renderer: yRenderer,
      })
    );

    yRenderer.grid.template.set("strokeDasharray", [3]);

    // Add series
    function createSeries(name, field) {
      let series = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField: "bucket",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "[bold]{name}[/]\n{categoryX}: {valueY}",
          }),
        })
      );

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 4,
            stroke: root.interfaceColors.get("background"),
            strokeWidth: 2,
            fill: series.get("fill"),
          }),
        });
      });

      // create hover state for series and for mainContainer, so that when series is hovered,
      // the state would be passed down to the strokes which are in mainContainer.
      series.set("setStateOnChildren", true);
      series.states.create("hover", {});
      series.mainContainer.set("setStateOnChildren", true);
      series.mainContainer.states.create("hover", {});

      series.strokes.template.states.create("hover", {
        strokeWidth: 4,
      });

      series.strokes.template.setAll({
        strokeWidth: 2,
      });

      series.data.setAll(data.data);
      series.appear(1000);
    }

    data.series.forEach((series) => {
      createSeries(series.name, series.fieldName);
    });

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    // Make series change state when legend item is hovered
    legend.itemContainers.template.states.create("hover", {});

    legend.itemContainers.template.events.on("pointerover", function (e) {
      e.target.dataItem.dataContext.hover();
    });

    legend.itemContainers.template.events.on("pointerout", function (e) {
      e.target.dataItem.dataContext.unhover();
    });

    legend.data.setAll(chart.series.values);

    // Make stuff animate on load
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [rootId, data, loading]);

  return (
    <ChartContainer height={height}>
      <ChartCanvas id={rootId} height={height} shouldCenter={loading || (!loading && !data?.data)} {...props}>
        {loading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : !data?.data && !error ? (
          <MessagesNoResults title="Squads.NoActivityTitle" description="Squads.NoActivityDescription" />
        ) : error ? (
          <MessagesError />
        ) : null}
      </ChartCanvas>
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  width: 100%;
  padding: 0;
  overflow: visible;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1.5rem;
  margin-left: -1.5rem;
`;

const ChartCanvas = styled.div`
  width: 100%;
  height: ${(props) => props.height};
  margin-left: -5px;
  overflow: visible;
  ${(props) =>
    props.shouldCenter &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

LineArea.defaultProps = {
  height: `27rem`,
};

LineArea.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

export default LineArea;
