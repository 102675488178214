import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Icon, { ICON_POSITION, ICON_SIZE, ICON_TYPE, withIcon } from "components/Icon";
import { colors } from "style";
import { formatNumber } from "utils";
import { Select, TextInput } from "components/Form";
import DatePicker from "components/Form/DatePicker";
import { DataCell } from "components/TermSheets";
import { DATE_FORMAT_TYPE } from "constants";
import { BUTTON_SIZE } from "components/Buttons";

/**
 * TermSheetInput
 *
 * @param {String}    type
 * @param {String}    value
 * @param {Function}  onChange
 * @param {Boolean}   onRevert
 * @param {String}    name
 * @param {Boolean}   hasChanges
 * @param {Boolean}   disabled
 */
const TermSheetInput = ({ type, value, onChange, onRevert, name, hasChanges, disabled, ...props }) => {
  const [val, setVal] = useState(value);
  const [formattedVal, setFormattedVal] = useState(value);
  const [focus, setFocus] = useState(false);
  const formItemProps = { type, name, hasChanges, disabled, focus };

  const ClearComponent = () => {
    if (!disabled && hasChanges && !focus) {
      return (
        <ClearButton onClick={onRevert} size={BUTTON_SIZE.small}>
          <Icon type={ICON_TYPE.close} size={ICON_SIZE.normal} color="inherit" />
        </ClearButton>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    setVal(value);
  }, [value]);

  useEffect(() => {
    setFormattedVal(formatNumber(val, "", 2));
  }, [val]);

  switch (type) {
    /**
     * @description Date picker
     */
    case TERM_SHEET_INPUT_TYPES.date:
      return <DateP value={value} onChange={onChange} {...formItemProps} {...props} />;

    /**
     * @description Select box
     */
    case TERM_SHEET_INPUT_TYPES.select:
      return (
        <DataCell>
          <StyledSelect
            value={val}
            onChange={(n, v) => {
              if (v) {
                onChange(name, v);
              }
            }}
            {...formItemProps}
            {...props}
          />
          <ClearComponent />
        </DataCell>
      );

    /**
     * @description Plain text
     */
    case TERM_SHEET_INPUT_TYPES.text:
      return <Text>{value}</Text>;

    /**
     * @description Input
     */
    default:
      return (
        <DataCell isFocus={focus}>
          <Input
            value={focus ? val : formattedVal}
            onChange={(n, v) => setVal(v)}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              setFocus(false);
              onChange(name, val);
            }}
            {...formItemProps}
            {...props}
          />
          <ClearComponent />
        </DataCell>
      );
  }
};

/**
 * Input
 *
 * @param {String} type
 */
const Input = ({ type, ...props }) => {
  switch (type) {
    case TERM_SHEET_INPUT_TYPES.currency:
      return <CurrencyInput {...props} />;
    case TERM_SHEET_INPUT_TYPES.percent:
      return <PercentInput {...props} />;
    case TERM_SHEET_INPUT_TYPES.number:
      return <StyledInput {...props} />;
    default:
      return <TextInput {...props} />;
  }
};

/**
 * DateP
 *
 * @param {String} name
 * @param {String} value
 * @param {Function} onChange
 */
const DateP = ({ name, value, onChange, ...props }) => (
  <StyledDatePicker
    name={name}
    required={true}
    format={DATE_FORMAT_TYPE.ddMMyyyy}
    popperPlacement={"bottom-start"}
    transparent={true}
    onChange={onChange}
    value={value}
    {...props}
  />
);

/**
 * CurrencyInput
 *
 * @param {String}   value
 * @param {String}   toCurrency
 * @param {String}   currency
 * @param {String}   buyingCurrency
 */
const CurrencyInput = ({ currency, currencyLoading, ...props }) =>
  currencyLoading ? `...` : <CurrencyInputWithIcon type={currency} {...props} />;

const TERM_SHEET_INPUT_TYPES = {
  text: "text",
  select: "select",
  number: "number",
  currency: "currency",
  percent: "percent",
  date: "date",
};

const ClearButton = styled.button`
  border: 0;
  border-radius: 0;
  width: 1.25rem;
  height: 1.25rem;
  background: ${colors.green};
  color: ${colors.lightGreen};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 0.25rem;
  transition: transform 300ms ease;

  &:hover {
    background: ${colors.darkGreen};
  }
`;

const StyledSelect = styled(Select)`
  > div:first-of-type {
    border-width: 0;
    border-radius: 0;
    background: #fff;
    padding-left: 0.8rem;

    ${({ hasChanges }) =>
      hasChanges &&
      `
      color: ${colors.darkGreen};
      background: ${colors.lightGreen};
      border: 1px ${colors.green} solid;
    `}

    ${({ disabled }) =>
      disabled &&
      `
      color: ${colors.purpleRainDark6};
      background: ${colors.grayAnatomyLight4};
    `}
  }
`;

const StyledInput = styled(TextInput)`
  border-color: transparent;
  text-align: right;
  border-radius: 0;
  background: #fff;
  transition: none;

  ${({ removed }) =>
    removed &&
    `
    border-color: ${colors.grayAnatomyLight4};
    font-weight: 400;
  `}

  ${({ hasChanges }) =>
    hasChanges &&
    `
    color: ${colors.darkGreen};
    background: ${colors.lightGreen};
    border: 1px ${colors.green} solid;
  `}  
  
  ${({ removed }) =>
    !removed &&
    `
    &:hover {
      color: ${colors.purpleRainBase};
      border-color: transparent;
      background: ${colors.grayAnatomyLight5};
    }

    &:focus {
      color: ${colors.purpleRainBase};
      border-color: ${colors.purpleRainBase} !important;
      transition: box-shadow 300ms ease;
      background: #fff;
      box-shadow: none;
      z-index: 10;
    }

    &:disabled {
      border: 0 !important;
      color: ${colors.purpleRainDark6};
      font-weight: 600;
    }
  `}
`;

const Text = styled.div`
  text-align: left;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-weight: 500;
`;

const StyledDatePicker = styled(DatePicker)`
  border: 0;
`;

const CurrencyInputWithIcon = withIcon(StyledInput, {
  size: ICON_SIZE.small,
  position: ICON_POSITION.overlay,
});

const PercentInput = withIcon(StyledInput, {
  type: ICON_TYPE.percent,
  size: ICON_SIZE.small,
  position: ICON_POSITION.overlay,
});

TermSheetInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  onRevert: PropTypes.func,
  hasChanges: PropTypes.bool,
  disabled: PropTypes.bool,
};

DateP.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

CurrencyInput.propTypes = {
  currencyLoading: PropTypes.bool,
  currency: PropTypes.string,
};

Input.propTypes = {
  type: PropTypes.string,
};

export { TERM_SHEET_INPUT_TYPES, StyledInput };

export default TermSheetInput;
