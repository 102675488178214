import React, { useContext } from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import DisplayBox from "components/DisplayBox";
import { capitalizeFirst } from "utils";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { SIZE, TYPE } from "components/Form";
import { UPDATE_PROFILE } from "graphql/mutations";
import { GET_LANGUAGES } from "graphql/queries";
import { ThemeContext } from "style";
import { PAGES } from "constants/index";
import { useAuth } from "hooks";

/**
 * ProfileContact
 *
 * @params {Array}    data
 */
const ProfileContact = ({ data, ...props }) => {
  const languages = data?.languages?.map((language) => capitalizeFirst(language.name));
  const theme = useContext(ThemeContext);
  const { isClient, isShowcase } = useAuth();

  if (isClient || isShowcase) return null;

  return (
    <DisplayBox
      title={<FormattedMessage id="Profile.ProfileContact.Title" />}
      editButtonText={<FormattedMessage id="Profile.ProfileContact.AddInfo" />}
      numColumns={3}
      data={ADDITIONAL_INFO(data, languages, theme.isPublic)}
      parentKeyName="id"
      parentKeyValue={data?.id}
      updateId={data?.id}
      initialValues={{
        phoneNumber: data?.phoneNumber,
        languages: data?.languages.map((language) => ({
          id: language?.id,
          name: language?.name,
        })),
      }}
      {...props}
    />
  );
};

const ADDITIONAL_INFO = (data, languages, isPublic) => [
  {
    title: <FormattedMessage id="Profile.ProfileContact.LabelPhone" />,
    body: (
      <FormattedMessage
        id={data?.phoneNumber ? "Profile.ProfileContact.DataPhone" : "Global.NotProvided"}
        values={{
          phone: data?.phoneNumber,
        }}
      />
    ),
    hide: isPublic,
  },
  {
    title: <FormattedMessage id="Profile.ProfileContact.LabelLanguage" />,
    body: (
      <FormattedMessage
        id="Profile.ProfileContact.DataLanguage"
        values={{
          languages: languages?.join(", "),
        }}
      />
    ),
  },
];

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Phone",
        type: TYPE?.input,
        size: SIZE?.small,
        properties: {
          name: "phoneNumber",
        },
      },
      {
        label: "Languages",
        type: TYPE?.tags,
        size: SIZE?.large,
        properties: {
          name: "languages",
          queryName: GET_LANGUAGES,
          queryKeyName: "languages",
          required: true,
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Profile.ProfileContact.FormTitle" />,
  description: <FormattedMessage id="Profile.ProfileContact.FormDescription" />,
  numColumns: 4,
  mutationData: {
    mutationName: {
      add: UPDATE_PROFILE,
      update: UPDATE_PROFILE,
    },
  },
  page: PAGES.contactInfo,
};

ProfileContact.propTypes = {
  data: PropTypes.object,
};

export default withModalForm(ProfileContact, FORM_SETTINGS);
