import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { fontSize } from "style";
import { WhiteLink } from "components/Links";
import { useAuth } from "hooks";
import { LINKS } from "constants/index";

/**
 * FAQ Menu Item
 */
const MenuItemFaq = (props) => {
  const { isClient, isShowcase, isAdmin } = useAuth();
  const faqLink = isClient || isShowcase ? LINKS.client_faq : LINKS.member_faq;

  if (isAdmin) return null;

  return (
    <FAQ {...props}>
      <span>
        <FormattedMessage
          id="Nav.SideNav.LabelNeedHelp"
          values={{
            faq: (
              <>
                <WhiteLink href={faqLink} target="_blank">
                  <span css={styles.bold}>
                    <FormattedMessage id="Nav.SideNav.LabelFAQ" />
                  </span>
                </WhiteLink>
              </>
            ),
          }}
        />
      </span>
    </FAQ>
  );
};

const FAQ = styled.div`
  font-weight: 400;
  margin-bottom: 1rem;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 0.6rem;
  font-size: ${fontSize.xsmall};
`;

const styles = {
  bold: css`
    font-weight: 700;
  `,
};

export default MenuItemFaq;
