import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { useProfile } from "hooks";
import { colors, fontSize } from "style";
import { TextLoader } from "components/Loader";
import { H1 } from "components/Text";

/**
 * DashboardHeader
 *
 * @param {Number}  id
 */
const DashboardHeader = ({ id: profileId }) => {
  const { data: profileData, loading: profileLoading } = useProfile({ profileId });

  return (
    <>
      <H1 css={styles.title}>
        {!profileLoading ? (
          <FormattedMessage
            id="Missions.DashboardHeader.H1"
            values={{
              name: profileData?.firstName,
            }}
          />
        ) : (
          <StyledTextLoader />
        )}
      </H1>
      <TextWrapper>
        <Label>
          <FormattedMessage id="Dashboard.DashboardHeader.Title" />
        </Label>
      </TextWrapper>
    </>
  );
};

const styles = {
  title: css`
    margin-top: 5rem;
    font-size: 2.6rem;
    margin-left: 3rem;
    font-weight: 700;
  `,
  primary_link: css`
    font-size: ${fontSize.small};
    font-weight: 600;
    line-height: 1;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 0.4rem;
    padding-top: 1rem;
  `,
};

const StyledTextLoader = styled(TextLoader)`
  width: 60%;
`;

const Label = styled.div`
  font-weight: 400;
  line-height: 1.5;
  font-size: ${fontSize.medium};
  color: ${colors.purpleRainDark2};
  position: relative;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin-left: 3rem;
  margin-top: 0.5rem;
`;

DashboardHeader.propTypes = {
  id: PropTypes.string,
};

export default DashboardHeader;
