import React from "react";
import PropTypes from "prop-types";
import { colors } from "style";
import Tooltip from "components/Tooltip";

/**
 * MissionAccomplished17
 *
 * @param {String}   fill
 */
const MissionAccomplished17 = ({ fill, ...props }) => (
  <Tooltip tooltipId={"Badges.MissionAccomplished17"} hideIcon>
    <svg viewBox="0 0 137 150" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.8179 3.21539C70.3923 -1.0718 61.2436 -1.0718 53.8179 3.21539L12 27.359C4.57437 31.6462 0 39.5692 0 48.1436V96.4308C0 105.005 4.57437 112.928 12 117.215L53.8179 141.359C61.2436 145.646 70.3923 145.646 77.8179 141.359L119.636 117.215C127.061 112.928 131.636 105.005 131.636 96.4308V48.1436C131.636 39.5692 127.061 31.6462 119.636 27.359L77.8179 3.21539ZM82.6235 33.2873H48.0124C43.9178 33.2873 40.5803 36.651 40.7348 40.7244L40.7348 40.725C40.7657 41.5426 40.7966 42.3602 40.8429 43.1623H24.5263C22.4712 43.1623 20.8179 44.8133 20.8179 46.8654C20.8179 61.1533 25.9942 71.09 32.9473 77.8328C39.7922 84.483 48.136 87.8312 54.2856 89.5285C57.9013 90.5314 60.3735 93.5402 60.3735 96.5644C60.3735 99.7892 57.7468 102.412 54.5174 102.412H50.4846C47.7497 102.412 45.5402 104.619 45.5402 107.35C45.5402 110.081 47.7497 112.287 50.4846 112.287H80.1513C82.8862 112.287 85.0957 110.081 85.0957 107.35C85.0957 104.619 82.8862 102.412 80.1513 102.412H76.1185C72.8891 102.412 70.2624 99.7892 70.2624 96.5644C70.2624 93.5402 72.7192 90.516 76.3502 89.5285C82.5153 87.8312 90.8591 84.483 97.704 77.8328C104.642 71.09 109.818 61.1533 109.818 46.8654C109.818 44.8133 108.165 43.1623 106.11 43.1623H89.7929C89.8393 42.36 89.8702 41.5576 89.9011 40.7244C90.0556 36.651 86.7181 33.2873 82.6235 33.2873ZM28.3891 50.5685H41.4146C42.8207 64.4707 45.9264 73.7594 49.4339 79.9775C45.5865 78.2803 41.5846 75.8887 38.1235 72.525C33.179 67.7264 29.1617 60.7984 28.3891 50.5685ZM92.5278 72.525C89.0667 75.8887 85.0648 78.2803 81.2174 79.9775C84.7249 73.7594 87.8306 64.4707 89.2367 50.5685H102.278C101.49 60.7984 97.4723 67.7264 92.5433 72.525H92.5278ZM61.6138 76.2873H56.5279V52.2726L52.7339 54.0568V48.5607L56.4459 46.4689H61.6138V76.2873ZM78.0611 50.2219L72.7086 76.2873H67.6841L72.8931 50.9807H69.3248V54.4465H64.4644V46.4689H78.0611V50.2219Z"
        fill={fill || colors.pictonBlue}
      />
    </svg>
  </Tooltip>
);

MissionAccomplished17.propTypes = {
  fill: PropTypes.string,
};

export default MissionAccomplished17;
