import { gql } from "@apollo/client";

const ADD_PARTNER = gql`
  mutation AddPartner($input: AddPartnerInput!) {
    addPartner(input: $input) {
      clientMutationId
      partner {
        name
        website
        slug
        channel
        trusted
        canControlRate
        memberTrial
      }
    }
  }
`;

const UPDATE_PARTNER = gql`
  mutation UpdatePartner($input: UpdatePartnerInput!) {
    updatePartner(input: $input) {
      clientMutationId
      partner {
        id
        name
        website
        slug
        channel
        trusted
        canControlRate
        memberTrial
        location {
          id
          name
        }
      }
    }
  }
`;

const REMOVE_PARTNER = gql`
  mutation RemovePartner($input: RemovePartnerInput!) {
    removePartner(input: $input) {
      clientMutationId
      id
    }
  }
`;

const REMOVE_PARTNERS = gql`
  mutation RemovePartners($input: RemovePartnersInput!) {
    removePartners(input: $input) {
      clientMutationId
      ids
    }
  }
`;

const ARCHIVE_PARTNER = gql`
  mutation ArchivePartner($input: ArchivePartnerInput!) {
    archivePartner(input: $input) {
      partner {
        id
        status
      }
    }
  }
`;

const ACTIVATE_PARTNER = gql`
  mutation ActivatePartner($input: ActivatePartnerInput!) {
    activatePartner(input: $input) {
      partner {
        id
        status
      }
    }
  }
`;

export { ADD_PARTNER, UPDATE_PARTNER, REMOVE_PARTNER, REMOVE_PARTNERS, ARCHIVE_PARTNER, ACTIVATE_PARTNER };
