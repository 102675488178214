import React, { useState } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { MUTATION_TYPE } from "hooks";
import { ADD_USER } from "graphql/mutations";
import { LINKS, USER_TYPES } from "constants/index";
import Modal, { ModalForm, MODAL_SIZE } from "components/Modal";
import { CopyToClipboardInput, DECORATORS, SIZE, TYPE, Label } from "components/Form";
import { ICON_POSITION, ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * ShowcaseInviteModal
 *
 * @param {Boolean} show
 * @param {Function} onClose
 */
const ShowcaseInviteModal = ({ show, onClose, ...props }) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showcaseUser, setShowcaseUser] = useState();

  const handleComplete = (response) => {
    setShowcaseUser(response?.addUser?.user);
    setShowInfoModal(true);
  };

  const handleInfoModalOnClose = () => {
    setShowInfoModal(false);
    onClose();
  };

  const formProps = {
    initialValues: { skipInvite: true, role: USER_TYPES.showcase },
    mutationType: MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: ADD_USER,
      },
    },
    ...props,
  };

  return (
    <>
      {showcaseUser ? (
        <ShowcaseInviteInfoModal show={showInfoModal} onClose={handleInfoModalOnClose} showcaseUser={showcaseUser} />
      ) : (
        <ModalForm
          title={<FormattedMessage id="Showcase.ShowcaseInviteModal.Title" />}
          description={<FormattedMessage id="Showcase.ShowcaseInviteModal.Description" />}
          primaryButtonMessageId={"Global.Continue"}
          data={FORM_DATA}
          show={show}
          size={MODAL_SIZE.small}
          onClose={onClose}
          closeOnComplete={false}
          onCompleted={handleComplete}
          {...formProps}
          {...props}
        />
      )}
    </>
  );
};

/**
 * ShowcaseInviteInfoModal
 *
 * @param {Boolean} onClose
 * @param {Boolean} show
 * @param {Object}  showcaseUser
 */
const ShowcaseInviteInfoModal = ({ onClose, show, showcaseUser, ...props }) => (
  <Modal
    show={show}
    showSave={false}
    showCancel={true}
    onClose={onClose}
    size={MODAL_SIZE.small}
    secondaryButtonMessageId="Global.Close"
    title={<FormattedMessage id="Showcase.ShowcaseInviteModal.Title" />}
    {...props}
  >
    <>
      <FormattedMessage id={`Showcase.ShowcaseInviteModal.Success.Message`} />
      <div css={styles.margin}>
        <Label>
          <FormattedMessage id={`Showcase.ShowcaseInviteModal.Success.Label`} />
        </Label>
        <CopyToClipboardInput text={`${window.location.origin}${LINKS.showcase_magic_link(showcaseUser?.uuid)}`} />
      </div>
    </>
  </Modal>
);

const styles = {
  margin: css`
    margin: 3rem 0 -2rem 0;
  `,
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: <FormattedMessage id="Showcase.ShowcaseInviteModal.FirstName.Label" />,
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          autoFocus: true,
          name: "firstName",
          type: "text",
          required: true,
        },
      },
      {
        label: <FormattedMessage id="Showcase.ShowcaseInviteModal.LastName.Label" />,
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "lastName",
          type: "text",
          required: true,
        },
      },
      {
        label: <FormattedMessage id="Showcase.ShowcaseInviteModal.Email.Label" />,
        type: TYPE.input,
        size: SIZE.xlarge,
        customizedErrorMessage: (error) => {
          if (error.field === "email" && error.message === "has already been taken") {
            return <FormattedMessage id="Showcase.ShowcaseInviteModal.Email.Uniqueness.Error" />;
          }
        },
        properties: {
          name: "email",
          type: "text",
          required: true,
        },
        decorator: {
          type: DECORATORS.icon,
          props: {
            type: ICON_TYPE.email,
            size: ICON_SIZE.medium,
            position: ICON_POSITION.overlay,
          },
        },
      },
      {
        type: TYPE.hidden,
        properties: {
          name: "skipInvite",
        },
      },
      {
        type: TYPE.hidden,
        properties: {
          name: "role",
        },
      },
    ],
  },
];

ShowcaseInviteModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

ShowcaseInviteInfoModal.propTypes = {
  onClose: PropTypes.func,
  showcaseUser: PropTypes.object,
  show: PropTypes.bool,
};

export default ShowcaseInviteModal;
