/** @jsx jsx */
import styled from "@emotion/styled";
import { colors, fontSize } from "style";
import { withIcon, ICON_TYPE, ICON_SIZE } from "components/Icon";

/**
 * Primary
 */
const EditLink = styled.a`
  font-size: ${fontSize.small};
  font-weight: 600;
  background: none;
  color: ${colors.purpleRainBase};
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  &:hover:not(:disabled) {
    color: ${colors.purpleRainDark1};
  }
`;

export default withIcon(EditLink, {
  type: ICON_TYPE.plus,
  size: ICON_SIZE.large,
  color: "inherit",
});
