import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import Modal, { MODAL_SIZE } from "components/Modal";
import { Col } from "components/Containers";
import { H4 } from "components/Text";
import { ENGINEERING_LEVELS } from "constants";

/**
 * EngineeringLevelsModal
 *
 * @param {Object}    data
 * @param {Boolean}   show
 * @param {Function}  setShow
 */
const EngineeringLevelsModal = ({ data, show, setShow, ...props }) => {
  const { engineeringLevel } = data;
  const { uuid } = engineeringLevel || {};

  return (
    <Modal
      show={show}
      showSave={false}
      showCancel={true}
      onClose={() => setShow(false)}
      size={MODAL_SIZE.medium}
      secondaryButtonMessageId="Global.Close"
      title={<FormattedMessage id="EngineeringLevels.EngineeringLevelsModal.Title" />}
      {...props}
    >
      <InnerBody uuid={uuid} />
    </Modal>
  );
};

/**
 * InnerBody
 *
 * @param {String} uuid
 */
const InnerBody = ({ uuid }) => {
  useEffect(() => {
    const element = document.getElementById(uuid);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [uuid]);

  return (
    <div css={styles.container}>
      <Col gap="2rem">
        {ENGINEERING_LEVELS.map(({ id }) => (
          <BodyContent key={id} level={id} />
        ))}
      </Col>
    </div>
  );
};

/**
 * BodyContent
 *
 * @param {String} level
 */
const BodyContent = ({ level }) => {
  switch (level) {
    // Distinguished - E6
    case "9a0eba0b-74f8-4cf9-8a11-7e82548dda98":
      return (
        <Col id="9a0eba0b-74f8-4cf9-8a11-7e82548dda98" gap="1rem">
          <a name="9a0eba0b-74f8-4cf9-8a11-7e82548dda98" />
          <H4>Distinguished - E6</H4>
          <p>You would be considered a Distinguished Software Engineer if at least 4 of the following apply to you:</p>
          <ul>
            <li>
              You have 20+ years of hands-on experience writing code that is production ready, and is easily testable
              and understood by other developers, accounting for edge cases and errors
            </li>
            <li>You have 10+ years of experience leading a development team</li>
            <li>
              You have taken ownership of technical strategy organization-wide or across Business Units / Group of
              Products / Systems
            </li>
            <li>
              You have made groundbreaking contributions to the field of software engineering, shaping the
              industry&apos;s understanding and practices
            </li>
            <li>
              You have driven technical innovation at a global scale, impacting multiple organizations and industries
            </li>
            <li>
              You act as a thought leader and influencer in software engineering, speaking at conferences and publishing
              research papers
            </li>
            <li>
              You advise and collaborate with industry leaders, shaping the future of software technology and practices
            </li>
          </ul>
        </Col>
      );

    // Principal - E5
    case "fdb6e82f-f637-4a12-8153-405ce4c94903":
      return (
        <Col id="fdb6e82f-f637-4a12-8153-405ce4c94903" gap="1rem">
          <a name="fdb6e82f-f637-4a12-8153-405ce4c94903" />
          <H4>Principal - E5</H4>
          <p>You would be considered a Principal Software Engineer if at least 5 of the following apply to you:</p>
          <ul>
            <li>
              You have 10+ years of hands-on experience writing code that is production ready, and is easily testable
              and understood by other developers, accounting for edge cases and errors
            </li>
            <li>You have 5+ years of experience leading a development team</li>
            <li>
              You have taken technical ownership of a full product / possibly multiple products in the same Business
              Unit
            </li>
            <li>
              You have led multiple projects or initiatives, setting technical direction and guiding other engineers
            </li>
            <li>You have contributed to the organization&apos;s technical strategy and long-term vision</li>
            <li>
              You have mentored and coached senior and mid-level engineers, developing a strong engineering culture
            </li>
            <li>You have collaborated with other teams or departments to foster cross-functional alignment</li>
            <li>
              You have played a key role in resolving complex technical issues and providing technical expertise across
              the organization
            </li>
            <li>
              You have been recognized as an effective communicator that understands business motivations and can direct
              a group to establish a cohesive set of actions
            </li>
            <li>
              You have been pivotal in bringing together all the stakeholders in engineering and have provided the
              guardrails to prevent scope creep and project overruns
            </li>
            <li>
              You have a good grasp of software design patterns and have experience with scalable/high traffic software
            </li>
          </ul>
        </Col>
      );

    // Staff - E4
    case "23975b4a-6177-46ad-b087-cfe04d91bea9":
      return (
        <Col id="23975b4a-6177-46ad-b087-cfe04d91bea9" gap="1rem">
          <a name="23975b4a-6177-46ad-b087-cfe04d91bea9" />
          <H4>Staff - E4</H4>
          <p>You would be considered a Staff Software Engineer if at least 4 of the following apply to you:</p>
          <ul>
            <li>
              You have 7+ years of hands-on experience writing code that is production ready, and is easily testable and
              understood by other developers, accounting for edge cases and errors
            </li>
            <li>You have 3+ years of experience leading a development team</li>
            <li>
              You have taken ownership of multiple Epics / Module / Systems within a Product, or across similar products
              (i.e. shared authentication / SSO)
            </li>
            <li>You have led the solution design and implementation of complex software projects</li>
            <li>You have mentored and supported mid-level and junior engineers, fostering their technical growth</li>
            <li>You have participated in architectural decisions and driven technical excellence within the team</li>
            <li>You have taken ownership of critical technical challenges and proposed innovative solutions</li>
            <li>
              You have collaborated with product managers and stakeholders to align technical solutions with business
              goals (Technical feasibility)
            </li>
            <li>You can automate systems and look for improvements in the developer experience and workflow</li>
            <li>You have been recognized as an effective communicator</li>
          </ul>
        </Col>
      );

    // Senior - E3
    case "29cb521f-6fd8-4465-bb68-244228b772c8":
      return (
        <Col id="29cb521f-6fd8-4465-bb68-244228b772c8" gap="1rem">
          <a name="29cb521f-6fd8-4465-bb68-244228b772c8" />
          <H4>Senior - E3</H4>
          <p>You would be considered a Senior Software Engineer if at least 3 of the following apply to you:</p>
          <ul>
            <li>
              You have 5 - 9 years of hands-on experience writing code that is often production ready, and is easily
              testable and understood by other developers, accounting for edge cases and errors
            </li>
            <li>
              You have taken ownership of an Epic (a group of User Stories) / Entire Module / System or a larger piece
              of functionality within a Product
            </li>
            <li>You have led the system design and implementation of specific modules</li>
            <li>You have mentored and supported mid-level and junior engineers, fostering their technical growth</li>
            <li>You have participated in architectural decisions and drive technical excellence within the team</li>
            <li>You have taken ownership of critical technical challenges and propose innovative solutions</li>
          </ul>
        </Col>
      );

    // Intermediate - E2
    case "ae18ed16-aba3-46fd-a729-9868e558ed52":
      return (
        <Col id="ae18ed16-aba3-46fd-a729-9868e558ed52" gap="1rem">
          <a name="ae18ed16-aba3-46fd-a729-9868e558ed52" />
          <H4>Intermediate - E2</H4>
          <p>You would be considered an Intermediate Software Engineer if at least 3 of the following apply to you:</p>
          <ul>
            <li>
              You have 3 - 6 years of hands-on experience writing code, and you write code with testability,
              readability, edge cases, and errors in mind
            </li>
            <li>You have taken ownership of a limited piece of functionality / small module within a product</li>
            <li>
              You develop and maintain software applications and systems under the guidance of more experienced
              engineers
            </li>
            <li>You have collaborated within cross-functional teams and stakeholders to deliver projects</li>
            <li>
              You find you are starting to gain expertise in specific technologies, programming languages, and
              development methodologies
            </li>
            <li>You participate in code reviews and provide constructive feedback to peers</li>
            <li>You contribute to technical discussions and problem-solving within the team.</li>
          </ul>
        </Col>
      );
  }
};

const styles = {
  container: css`
    line-height: normal;
  `,
};

EngineeringLevelsModal.propTypes = {
  data: PropTypes.object,
  show: PropTypes.bool,
  setShow: PropTypes.func,
};

BodyContent.propTypes = {
  level: PropTypes.string,
};

InnerBody.propTypes = {
  uuid: PropTypes.string,
};

export default EngineeringLevelsModal;
