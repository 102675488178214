import React from "react";
import { css } from "@emotion/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import Form, { SIZE, TYPE } from "components/Form";
import { Col } from "components/Containers";
import { colors, fontSize, margins } from "style";
import { LINKS } from "constants/index";
import { PrimaryLink } from "components/Links";
import { LOGIN } from "graphql/mutations";
import { MUTATION_TYPE } from "hooks";
import { isLoggedIn } from "graphql/variables";

/**
 * LoginForm
 */
const LoginForm = () => {
  const intl = useIntl();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const redirectUrl = query.get("redirect");

  /**
   * handle login complete
   */
  const handleComplete = (data) => {
    const credentials = data?.userLogin?.credentials;
    const redirectTo = data?.userLogin?.redirectTo;

    if (credentials) {
      const { accessToken, uid, client, expiry } = credentials;

      localStorage.setItem("token", accessToken);
      localStorage.setItem("client", client);
      localStorage.setItem("uid", uid);
      localStorage.setItem("expiry", expiry);
      isLoggedIn(true);
      window.open(redirectTo || "/", "_self");
    }
  };

  return (
    <Col>
      <span css={styles.title}>
        <FormattedMessage id="Login.LoginForm.Title" />
      </span>
      <span css={styles.subtitle}>
        <FormattedMessage id="Login.LoginForm.Subtitle" />
      </span>
      <Form
        data={FORM_DATA}
        saveButton={{
          show: true,
          labelId: intl.formatMessage({ id: "Login.LoginForm.Button" }),
          props: {
            css: styles.button,
          },
        }}
        mutationData={{
          mutationName: {
            add: LOGIN,
          },
          unWrapParams: true,
        }}
        mutationType={MUTATION_TYPE.add}
        initialValues={{ redirectUrl }}
        onCompleted={handleComplete}
        marginSize={margins.none}
      />
      <span css={styles.already_member}>
        <FormattedMessage
          id="Login.LoginForm.SignupLabel"
          values={{
            link: (
              <PrimaryLink href={LINKS.signup} routerEnabled>
                <FormattedMessage id="Login.LoginForm.SignupLink" />
              </PrimaryLink>
            ),
          }}
        />
      </span>
    </Col>
  );
};

const styles = {
  title: css`
    color: ${colors.black};
    font-size: ${fontSize.xxlarge};
    margin-bottom: 1.5rem;
    font-weight: 700;
  `,
  subtitle: css`
    font-size: ${fontSize.normal};
    color: ${colors.grayAnatomyLight1};
    margin-bottom: 3rem;
    font-weight: 500;
  `,
  already_member: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyLight1};
    margin: 3rem auto 0 auto;
  `,
  button: css`
    width: 100%;
  `,
  form_container: css`
    width: 100%;
  `,
};

const FORM_DATA = [
  {
    style: styles.form_container,
    items: [
      {
        label: "Email",
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "email",
          autoFocus: true,
          required: true,
          type: "email",
        },
      },
      {
        label: "Password",
        type: TYPE.input,
        size: SIZE.xlarge,
        hint: (
          <PrimaryLink href={LINKS.forgot_password} routerEnabled>
            <FormattedMessage id="Login.LoginForm.ForgotLink" />
          </PrimaryLink>
        ),
        properties: {
          name: "password",
          required: true,
          type: "password",
        },
      },
      {
        type: TYPE.hidden,
        properties: {
          name: "redirectUrl",
        },
      },
    ],
  },
];

LoginForm.propTypes = {};

export default LoginForm;
