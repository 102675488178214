import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

/**
 * Link
 *
 * @params {String}     to
 * @params {String}     href
 * @params {Boolean}    routerEnabled
 * @params {Boolean}    isActive
 * @params {Element}    children
 */
const Link = ({ to, href, routerEnabled, isActive, children, ...props }) => {
  const passProps = Object.assign({}, props);
  delete passProps.hasIcon;

  const handleClick = (e) => e.stopPropagation();

  return routerEnabled ? (
    <NavLink to={to || href} onClick={handleClick} {...passProps}>
      {children}
    </NavLink>
  ) : (
    <a href={to || href} className={isActive ? "active" : ""} onClick={handleClick} {...passProps}>
      {children}
    </a>
  );
};

Link.propTypes = {
  routerEnabled: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  isActive: PropTypes.bool,
  children: PropTypes.any,
  style: PropTypes.any,
};

export default Link;
