import React from "react";
import PropTypes from "prop-types";
import Offcanvas from "react-bootstrap/Offcanvas";

/**
 * SlideOut
 *
 * @param {Element} children
 * @param {String} title
 * @param {Boolean} show
 * @param {Function} onClose
 */
const SlideOut = ({ children, title, show, onClose, ...props }) => (
  <Offcanvas show={show} onHide={onClose} placement="end" backdrop={false} enforceFocus={false} {...props}>
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>{title}</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>{children}</Offcanvas.Body>
  </Offcanvas>
);

SlideOut.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SlideOut;
