import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { AdvancedButton } from "components/Buttons";
import { MissionForm } from "components/Missions";
import { ICON_TYPE } from "components/Icon";
import { useAuth } from "hooks";

/**
 * MissionAdvancedOptions
 *
 * @params {Object}   data
 * @params {Boolean}  loading
 */
const MissionAdvancedOptions = ({ data, loading }) => {
  const [showForm, setShowForm] = useState({});
  const { isAdmin } = useAuth();

  if (!isAdmin) return null;

  const toggleForm = (key, show) => setShowForm({ ...showForm, ...{ [key]: show } });

  /**
   * Edit Mission role
   */

  const editOption = [
    {
      label: <FormattedMessage id={`Missions.MissionAdvancedOptions.Edit`} />,
      icon: ICON_TYPE.edit,
      onClick: () => toggleForm("mission", true),
    },
  ];

  return (
    <>
      <AdvancedButton options={editOption} disabled={loading} />
      <MissionForm show={showForm.mission} onClose={() => toggleForm("mission", false)} data={data} isEditing isModal />
    </>
  );
};

MissionAdvancedOptions.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
};

export default MissionAdvancedOptions;
