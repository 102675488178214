import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import { TextLoader } from "components/Loader";
import Modal, { withModalForm, MODAL_SIZE } from "components/Modal";
import { SIZE, TYPE } from "components/Form";
import { UPLOAD_RESUME } from "graphql/mutations";
import { withIcon, ICON_TYPE, ICON_SIZE } from "components/Icon";
import { PrimaryLink } from "components/Links";
import { EditButton } from "components/Buttons";
import { useAuth } from "hooks";

/**
 * ProfileResume
 *
 * @param {Object}   data
 * @param {Boolean}  loading
 * @param {Function} onEdit
 * @param {Boolean}  isEditable
 * @param {Boolean}  isEmpty
 */
const ProfileResume = ({ data, loading, onEdit, isEditable, isEmpty }) => {
  const { isClient, isShowcase } = useAuth();

  if (isClient || isShowcase) return null;

  if (loading) {
    return (
      <div css={styles.container}>
        <PlaceHolder />
      </div>
    );
  } else if (!isEditable && isEmpty) {
    return null;
  }

  const [showResume, setShowResume] = useState(false);
  const { resumeUrl, id } = data;
  const updateResume = () => onEdit({ resume: resumeUrl }, id);

  if (isEmpty && isEditable) {
    return (
      <div css={styles.container}>
        <EditButton onClick={updateResume}>
          <FormattedMessage id="Profile.ProfileWorkExperience.AddResume" />
        </EditButton>
      </div>
    );
  }

  return (
    <div css={styles.container}>
      <ViewResumeLink css={styles.link} onClick={() => setShowResume(true)}>
        <FormattedMessage id="Profile.ProfileWorkExperience.ViewResume" />
      </ViewResumeLink>
      {isEditable && (
        <UpdateResumeLink css={styles.link} onClick={updateResume}>
          <FormattedMessage id="Profile.ProfileWorkExperience.UpdateResume" />
        </UpdateResumeLink>
      )}
      <Modal
        show={showResume}
        size={MODAL_SIZE.large}
        title={<FormattedMessage id="Profile.ProfileWorkExperience.Resume" />}
        onClose={() => setShowResume(false)}
        showSave={false}
        showCancel={true}
        secondaryButtonMessageId="Global.Close"
      >
        <iframe src={`${resumeUrl}#toolbar=0`} width="100%" height={500} />
      </Modal>
    </div>
  );
};

const PlaceHolder = () => <TextLoader css={styles.link} />;

const ViewResumeLink = withIcon(PrimaryLink, {
  type: ICON_TYPE.file,
  size: ICON_SIZE.xlarge,
  color: "inherit",
});

const UpdateResumeLink = withIcon(PrimaryLink, {
  type: ICON_TYPE.upload,
  size: ICON_SIZE.xlarge,
  color: "inherit",
});

const styles = {
  container: css`
    margin: 0;
    display: flex;
    gap: 2rem;
  `,
  link: css`
    font-size: ${fontSize.small};
    font-weight: 600;
    color: ${colors.purpleRainBase};
    display: flex;
    gap: 0.4rem;
    align-items: center;
    cursor: pointer;
    margin-top: 1.5rem;
  `,
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        type: TYPE.resume,
        size: SIZE.xlarge,
        properties: {
          name: "resume",
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Profile.ProfileWorkExperience.Resume" />,
  description: <FormattedMessage id="Profile.ProfileWorkExperience.ResumeDescription" />,
  mutationData: {
    mutationName: {
      add: UPLOAD_RESUME,
      update: UPLOAD_RESUME,
    },
  },
};

ProfileResume.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  isEditable: PropTypes.bool,
  isEmpty: PropTypes.bool,
};

export default withModalForm(ProfileResume, FORM_SETTINGS);
