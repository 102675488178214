/** @jsx jsx */
import styled from "@emotion/styled";
import { LAYOUT_DIRECTION } from "components/Form";

/**
 * Col
 */
const Col = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction === LAYOUT_DIRECTION.horizontal ? "row" : "column")};
  gap: ${({ gap }) => gap || `0`};
`;

export default Col;
