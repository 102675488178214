import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

/**
 * User Archetype
 *
 * @params {Object}   data
 */
const UserArchetype = ({ data }) => {
  if (!data?.archetype) return null;

  return <FormattedMessage id={`User.Archetype_${String(data?.archetype).toLowerCase()}`} />;
};

UserArchetype.propTypes = {
  data: PropTypes.object,
};

export default UserArchetype;
