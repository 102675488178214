import React from "react";
import { FormattedMessage } from "react-intl";
import { MOVE_PROFILE_TO_VETTING } from "graphql/mutations";
import { useModalConfirmation, useToast, useMutateData, MUTATION_TYPE } from "hooks";
import { GET_PROFILE } from "graphql/queries";

/**
 * useProfileVetting
 *
 * @params {String}   profileId
 */
export default function useProfileVetting({ profileId }) {
  const { toast } = useToast();
  const { show } = useModalConfirmation();
  const { handleAdd } = useMutateData(
    {
      mutationName: {
        add: MOVE_PROFILE_TO_VETTING,
      },
      refetchQueries: [GET_PROFILE],
      refetchAfterMutate: true,
      unWrapParams: true,
    },
    MUTATION_TYPE.add
  );

  const handleMoveToVetting = async () => {
    const response = await show(
      <FormattedMessage id={"Candidate.CandidateProgress.ProfileVetting.Title"} />,
      <FormattedMessage id="Candidate.CandidateProgress.RejectedCandidate.Modal.Description" />,
      {
        primaryButtonMessageId: "Global.Confirm",
      }
    );

    if (response) {
      handleAdd({
        params: {
          input: {
            id: profileId,
          },
        },
        onCompleted: () => {
          toast.success(<FormattedMessage id="Candidate.CandidateProgress.ProfileVetting.Success" />);
        },
        onError: () => {
          toast.error(<FormattedMessage id="Candidate.CandidateProgress.ProfileVetting.Error" />);
        },
      });
    }
  };

  return {
    handleMoveToVetting,
  };
}
