const EXTERNAL_LINK_TYPES = [
  {
    label: "Behance",
    value: "BEHANCE",
  },
  {
    label: "BitBucket",
    value: "BITBUCKET",
  },
  {
    label: "CodePen",
    value: "CODEPEN",
  },
  {
    label: "DEV.to",
    value: "DEV",
  },
  {
    label: "Dribbble",
    value: "DRIBBBLE",
  },
  {
    label: "GitHub",
    value: "GITHUB",
  },
  {
    label: "GitLab",
    value: "GITLAB",
  },
  {
    label: "JSFiddle",
    value: "JSFIDDLE",
  },
  {
    label: "Kaggle",
    value: "KAGGLE",
  },
  {
    label: "LinkedIn",
    value: "LINKEDIN",
  },
  {
    label: "Medium",
    value: "MEDIUM",
  },
  {
    label: "Stack Overflow",
    value: "STACK_OVERFLOW",
  },
  {
    label: "YouTube",
    value: "YOUTUBE",
  },
  {
    label: "Custom Link",
    value: "CUSTOM",
  },
  {
    label: "Twitter",
    value: "TWITTER",
  },
  {
    label: "Calendly",
    value: "CALENDLY",
  },
];

export default EXTERNAL_LINK_TYPES;

export const EXTERNAL_LINK_VALUES = Object.fromEntries(EXTERNAL_LINK_TYPES.map((item) => [item.label, item.value]));
