/** @jsx jsx */
import styled from "@emotion/styled";

/**
 * TableHead
 */
const TableHead = styled.thead`
  vertical-align: bottom;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
`;

export default TableHead;
