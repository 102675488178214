import { GET_MISSION } from "graphql/queries";
import { useQueryData } from "hooks";

export default function useMission({ id, skip }) {
  const { loading, data, error } = useQueryData({
    queryName: GET_MISSION,
    keyName: "mission",
    skip: !id || skip,
    variables: { id },
  });
  return {
    loading,
    data: data?.mission,
    error,
  };
}
