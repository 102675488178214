import { gql } from "@apollo/client";

const ACCEPT_TIME_CARD = gql`
  mutation AcceptTimeCard($input: AcceptTimeCardInput!) {
    acceptTimeCard(input: $input) {
      accepted {
        id
        status
      }
      failedToAccept {
        id
        status
      }
    }
  }
`;

const REOPEN_TIME_CARD = gql`
  mutation ReopenTimeCard($input: ReopenTimeCardInput!) {
    reopenTimeCard(input: $input) {
      timeCard {
        id
        notes
        status
        deelLastSyncAt
      }
    }
  }
`;

const SUBMIT_TIME_CARD = gql`
  mutation SubmitTimeCard($input: SubmitTimeCardInput!) {
    submitTimeCard(input: $input) {
      timeCard {
        id
        monthPeriod
        notes
        period
        status
        submitted
        submittedAt
      }
    }
  }
`;

const SEND_TIME_CARD_TO_DEEL = gql`
  mutation SendTimeCardToDeel($input: SendTimeCardToDeelInput!) {
    sendTimeCardToDeel(input: $input) {
      timeCard {
        id
        deelLastSyncAt
      }
    }
  }
`;

export { ACCEPT_TIME_CARD, REOPEN_TIME_CARD, SUBMIT_TIME_CARD, SEND_TIME_CARD_TO_DEEL };
