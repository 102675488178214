import { gql } from "@apollo/client";

const CLIENT_SIGNUP = gql`
  mutation clientSignUp($input: ClientSignUpInput!) {
    clientSignUp(input: $input) {
      user {
        id
        email
      }
    }
  }
`;

export { CLIENT_SIGNUP };
