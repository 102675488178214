import { useState, useCallback, useEffect } from "react";
import { PAGINATION_TYPE } from "components/Pagination";
import { GET_TEAMS } from "graphql/queries";
import { useQueryData, useAuth } from "hooks";

export default function useTeams({
  profileId,
  filters,
  organizationId,
  currentPage = 1,
  resultsPerPage = 10,
  readUrlPagination = false,
  paginationType,
  variables: variablesProp = {},
}) {
  const { profileId: authProfileId } = useAuth();
  const [page, setPage] = useState(currentPage);
  const [perPage, setPerPage] = useState(resultsPerPage);
  const [storedTotalCount, setStoredTotalCount] = useState(0);
  const isCursorPagination = paginationType !== PAGINATION_TYPE.classic;

  const handlePageChange = useCallback((newPage, newPerPage) => {
    setPage(newPage);
    setPerPage(newPerPage);
  }, []);

  const variables = {
    profileId: profileId || authProfileId,
    filters,
    organizationId,
    ...(isCursorPagination && {
      first: perPage,
      last: null,
      after: null,
    }),
    ...(!isCursorPagination && {
      paginationInfo: {
        perPage,
        page,
      },
    }),
    ...variablesProp,
  };

  const {
    loading,
    loadingMore,
    data,
    error,
    refetch,
    handleNextPage: onNextPage,
  } = useQueryData({
    queryName: GET_TEAMS,
    keyName: "teams",
    resultsPerPage: perPage,
    paginationType,
    variables,
    isCursorPagination,
    readUrlPagination,
  });

  const updatedTotalCount = data?.teams?.totalCount;

  useEffect(() => {
    if (typeof updatedTotalCount !== "undefined") {
      setStoredTotalCount(updatedTotalCount);
    }
  }, [updatedTotalCount]);

  const hasNextPage = data?.teams?.pageInfo?.hasNextPage;
  const hasPreviousPage = page > 1;

  const handleNextPage = () => {
    if (hasNextPage) {
      if (isCursorPagination) {
        onNextPage();
      } else {
        setPage(page + 1);
      }
    }
  };

  const handlePreviousPage = () => {
    if (hasPreviousPage) {
      setPage(page - 1);
    }
  };

  return {
    loading,
    loadingMore,
    data: data?.teams?.nodes,
    error,
    hasNextPage,
    hasPreviousPage,
    handleNextPage,
    handlePreviousPage,
    handlePageChange,
    currentPage: data?.teams?.currentPage,
    resultsPerPage: data?.teams?.perPage,
    totalCount: storedTotalCount,
    refetch,
  };
}
