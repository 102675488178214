import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import { FormattedMessage } from "react-intl";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { PrimaryButton } from "components/Buttons";

/**
 * Show a generic "no results" message
 *
 * @param {String}  title
 * @param {String}  description
 * @param {Object}  button
 * @param {Object}  icon
 */
const NoResults = ({ title, description, button, icon }) => (
  <div data-no-results css={styles.container}>
    <div css={styles.icon_container(icon.background, icon.container_size)}>
      <Icon type={icon.type} size={icon.size} color={icon.color} />
    </div>
    <div css={styles.title}>
      <FormattedMessage id={title} />
    </div>
    <FormattedMessage id={description} />
    {button && (
      <div css={styles.button_container}>
        <PrimaryButton {...button.props}>
          <FormattedMessage id={button.title} />
        </PrimaryButton>
      </div>
    )}
  </div>
);

const styles = {
  container: css`
    font-size: ${fontSize.small};
    width: 100%;
    text-align: center;
    color: ${colors.grayAnatomyLight1};
    padding: 2rem 0;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    line-height: normal;
  `,
  button_container: css`
    margin-top: 1rem;
  `,
  title: css`
    font-size: ${fontSize.normal};
    font-weight: 600;
    color: ${colors.grayAnatomyDark};
  `,
  icon_container: (bg, size) => css`
    width: ${size};
    height: ${size};
    background: ${bg};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  `,
};

NoResults.defaultProps = {
  title: `Global.TitleNoResults`,
  description: `Global.DescriptionNoResults`,
  icon: {
    color: colors.black,
    background: colors.lightYellow,
    type: ICON_TYPE.warning,
    size: ICON_SIZE.large,
    container_size: `4rem`,
  },
};

NoResults.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.shape({
    title: PropTypes.string,
    props: PropTypes.object,
  }),
  icon: PropTypes.shape({
    color: PropTypes.string,
    background: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    container_size: PropTypes.string,
  }),
};

export default NoResults;
