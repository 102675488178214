import { gql } from "@apollo/client";
import {
  CORE_PROFILE_FIELDS,
  PROFILE_ID_FIELDS,
  CANDIDATE_PROGRESS_FIELDS,
  DISCIPLINE_FIELDS,
  LANGUAGE_FIELDS,
  EDUCATION_FIELDS,
  INTERESTS_FIELDS,
  BADGES_FIELDS,
  ENGINEERING_LEVEL_FIELDS,
  EXTERNAL_LINKS_FIELDS,
  OLD_LOCATION_FIELDS,
  RATE_FIELDS,
  YEARLY_RATE_FIELDS,
  SKILLS_FIELDS,
  TAGS_FIELDS,
  WORK_EXPERIENCES_FIELDS,
  WORK_SCHEDULE_FIELDS,
  VACATIONS_FIELDS,
  USER_FIELDS,
} from "graphql/fragments";

const GET_PROFILE = gql`
  ${CORE_PROFILE_FIELDS}
  ${PROFILE_ID_FIELDS}
  ${CANDIDATE_PROGRESS_FIELDS}
  ${DISCIPLINE_FIELDS}
  ${LANGUAGE_FIELDS}
  ${EDUCATION_FIELDS}
  ${EXTERNAL_LINKS_FIELDS}
  ${OLD_LOCATION_FIELDS}
  ${RATE_FIELDS}
  ${YEARLY_RATE_FIELDS}
  ${SKILLS_FIELDS}
  ${TAGS_FIELDS}
  ${WORK_EXPERIENCES_FIELDS}
  ${WORK_SCHEDULE_FIELDS}
  ${VACATIONS_FIELDS}
  ${USER_FIELDS}
  ${INTERESTS_FIELDS}
  ${BADGES_FIELDS}
  ${ENGINEERING_LEVEL_FIELDS}
  query GetProfile($id: ID!) {
    profile(id: $id) {
      ...ProfileIdFields
      ...ProfileFields
      ...CandidateProgressFields
      ...DisciplineFields
      ...LanguageFields
      ...EducationFields
      ...ExternalLinksFields
      ...OldLocationFields
      ...RateFields
      ...YearlyRateFields
      ...SkillsFields
      ...TagsFields
      ...WorkExperiencesFields
      ...WorkScheduleFields
      ...VacationsFields
      ...UserFields
      ...InterestsFields
      ...BadgesFields
      ...EngineeringLevelFields
    }
  }
`;

export { GET_PROFILE };
