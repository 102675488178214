import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { EditIcon } from "components/Icon";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { SIZE, TYPE } from "components/Form";
import { UPLOAD_AVATAR } from "graphql/mutations";
import { EditButton } from "components/Buttons";
import { UserPhoto } from "components/User";
import { PAGES } from "constants/index";
import { margins } from "style";

/**
 * ProfilePic
 *
 * @param {Object}   data
 * @param {Boolean}  loading
 * @param {Boolean}  isEditable
 * @param {Function} onEdit
 * @param {Boolean}  isEmpty
 * @param {String}   marginSize
 * @param {String}   width
 * @param {String}   height
 */
const ProfilePic = ({ data, loading, isEditable, onEdit, isEmpty, marginSize, width, height }) => {
  const handleOnEdit = () => onEdit({ avatar: data?.avatarUrl }, data?.id);
  const Edit = () => (isEditable ? <EditIcon css={styles.edit_icon(marginSize)} onClick={handleOnEdit} /> : null);

  if (isEmpty && isEditable) {
    return (
      <EditButton css={styles.button(width, height)} onClick={handleOnEdit}>
        <FormattedMessage id="Profile.ProfilePic.AddPhoto" />
      </EditButton>
    );
  }

  return (
    <div css={styles.profile_image_wrapper(width, height)}>
      <UserPhoto data={data} loading={loading} width={width} height={height} showInterviews />
      <Edit />
    </div>
  );
};

const styles = {
  profile_image_wrapper: (width, height) => css`
    width: ${width};
    height: ${height};
    position: relative;
    margin: 0 auto;
  `,
  edit_icon: (marginSize) => css`
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: inherit;
    right: calc(-${marginSize} - 2rem);
  `,
  button: (width, height) => css`
    width: ${width};
    height: ${height};
    text-align: center;
    justify-content: center;
  `,
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        type: TYPE.photo,
        size: SIZE.xlarge,
        properties: {
          name: "avatar",
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Profile.ProfilePic.FormTitle" />,
  description: <FormattedMessage id="Profile.ProfilePic.FormDescription" />,
  mutationData: {
    mutationName: {
      add: UPLOAD_AVATAR,
      update: UPLOAD_AVATAR,
    },
  },
  page: PAGES.profilePhoto,
};

ProfilePic.defaultProps = {
  marginSize: margins.normal,
  width: `18rem`,
  height: `18rem`,
};

ProfilePic.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  isEditable: PropTypes.bool,
  onEdit: PropTypes.func,
  isEmpty: PropTypes.bool,
  marginSize: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default withModalForm(ProfilePic, FORM_SETTINGS);
