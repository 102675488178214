import { gql } from "@apollo/client";

const termSheetFields = `
  assignments {
    profile {
      avatarUrl
      firstName
      lastName
      slug
      uuid
    }
    role
    hours
    rate
  }
  name
  rate
  currency
  type
`;

const TERM_SHEET_FIELDS = gql`
  fragment TermSheetFields on TermSheet {
    ${termSheetFields}
  }
`;

export { TERM_SHEET_FIELDS };
