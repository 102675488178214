import { GET_TEAM } from "graphql/queries";

const MOCK_GET_TEAM = {
  request: {
    query: GET_TEAM(false),
    variables: {
      id: "2",
      organization: true,
      assignments: true,
      limit: null,
      orderBy: {},
      first: null,
      last: null,
      after: null,
    },
  },
  result: {
    data: {
      team: {
        id: "2",
        slug: "retail-0f9af809",
        name: "Retail",
        methodology: "agile",
        description: "<div class=\"trix-content\">\n  \n</div>\n",
        projectDuration: "1 month",
        activeSince: "2022-09-27",
        status: "active",
        teamType: "team",
        projectType: "Web Development",
        size: 6,
        skills: [
          "JavaScript",
          "Ruby"
        ],
        activeDays: 108,
        __typename: "Team",
        organization: {
          id: "3",
          slug: "smitham-marvin-065aecd2",
          name: "Smitham-Marvin",
          primaryContactPhone: "392-742-7934",
          primaryContactEmail: "omar.howell@gsquad.io",
          __typename: "Organization"
        },
        assignments: [
        {
          id: "12",
          slug: "lakia-legros-db831627",
          firstName: "Lakia",
          lastName: "Legros",
          avatarUrl: "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhhVGRsZGpJM1ltcGxabnA0Wm5RemJ6RjZjemQ0WmprM1oyNXpiUVk2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpT1dsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpFek15NXFjR2NpT3lCbWFXeGxibUZ0WlNvOVZWUkdMVGduSnpFek15NXFjR2NHT3daVU9oRmpiMjUwWlc1MFgzUjVjR1ZKSWc5cGJXRm5aUzlxY0dWbkJqc0dWRG9SYzJWeWRtbGpaVjl1WVcxbE9ncHNiMk5oYkE9PSIsImV4cCI6IjIwMjMtMDEtMTNUMTA6NDc6MDIuMTQ4WiIsInB1ciI6ImJsb2Jfa2V5In19--5d6b7263fc12ca95e979e79c758346369fe3457f/133.jpg",
          archetype: "SQUAD_LEAD",
          profileId: "d2da72f8-6c20-4355-9715-370f4dcb658c",
          profile: {
            id: "131",
            email: "lakia.legros@gsquad.io",
            phoneNumber: "1-999-483-3550",
            status: "ARCHIVED",
            slug: "lakia-legros-db831627",
            capacityStatus: "BOOKED",
            teamsCount: 3,
            rateLastUpdatedAt: null,
            availabilityLastUpdatedAt: null,
            eligibleForVetting: false,
            archetype: "SQUAD_LEAD",
            avatarUrl: "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhhVGRsZGpJM1ltcGxabnA0Wm5RemJ6RjZjemQ0WmprM1oyNXpiUVk2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpT1dsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpFek15NXFjR2NpT3lCbWFXeGxibUZ0WlNvOVZWUkdMVGduSnpFek15NXFjR2NHT3daVU9oRmpiMjUwWlc1MFgzUjVjR1ZKSWc5cGJXRm5aUzlxY0dWbkJqc0dWRG9SYzJWeWRtbGpaVjl1WVcxbE9ncHNiMk5oYkE9PSIsImV4cCI6IjIwMjMtMDEtMTNUMTA6NDc6MDIuNDEwWiIsInB1ciI6ImJsb2Jfa2V5In19--fe39857d6e1a8c0ffa19f8595ba6099ce49937a6/133.jpg",
            resumeUrl: null,
            description: "Shut up! Shut up! Shut up!",
            firstName: "Lakia",
            lastName: "Legros",
            memberSince: null,
            capacity: 40,
            name: "Lakia Legros",
            yearsExperience: 20,
            bachelorDegrre: null,
            location: null,
            timezone: {
              name: "(UTC+00:00) Europe - London",
              value: "Europe_London",
              __typename: "Timezones"
            },
            __typename: "Profile",
            skills: [
              {
                category: "skill",
                enabled: true,
                name: "Adobe Photoshop",
                id: "11",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Azure Service Bus",
                id: "112",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "ImageMagick",
                id: "339",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Mixpanel",
                id: "436",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Next.js",
                id: "463",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Shopify",
                id: "605",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Tornado",
                id: "666",
                __typename: "Taggable"
              }
            ],
            tags: [],
            oldLocation: {
              city: null,
              country: "Canada",
              countryFlag: "🇨🇦",
              state: null,
              __typename: "Location"
            }
          },
          __typename: "Assignment"
        },
        {
          id: "9",
          slug: "rebbeca-lueilwitz-00565780",
          firstName: "Rebbeca",
          lastName: "Lueilwitz",
          avatarUrl: "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhNekpqZVc1c1ptbGhaVzFtYUdwMGRHaHVOR05tTVhBM2R6ZGhiUVk2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpT1dsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpFek1pNXFjR2NpT3lCbWFXeGxibUZ0WlNvOVZWUkdMVGduSnpFek1pNXFjR2NHT3daVU9oRmpiMjUwWlc1MFgzUjVjR1ZKSWc5cGJXRm5aUzlxY0dWbkJqc0dWRG9SYzJWeWRtbGpaVjl1WVcxbE9ncHNiMk5oYkE9PSIsImV4cCI6IjIwMjMtMDEtMTNUMTA6NDc6MDIuMTk3WiIsInB1ciI6ImJsb2Jfa2V5In19--f3b5c08b3121b98f1fcf76e2a3015f04b9b46715/132.jpg",
          archetype: "SQUAD_LEAD",
          profileId: "cf0fb6f5-0a68-41e5-9ed1-99a05bebe225",
          profile: {
            id: "130",
            email: "rebbeca.lueilwitz@gsquad.io",
            phoneNumber: "1-547-330-4679",
            status: "ARCHIVED",
            slug: "rebbeca-lueilwitz-00565780",
            capacityStatus: "BOOKED",
            teamsCount: 1,
            rateLastUpdatedAt: null,
            availabilityLastUpdatedAt: null,
            eligibleForVetting: false,
            archetype: "SQUAD_LEAD",
            avatarUrl: "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhNekpqZVc1c1ptbGhaVzFtYUdwMGRHaHVOR05tTVhBM2R6ZGhiUVk2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpT1dsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpFek1pNXFjR2NpT3lCbWFXeGxibUZ0WlNvOVZWUkdMVGduSnpFek1pNXFjR2NHT3daVU9oRmpiMjUwWlc1MFgzUjVjR1ZKSWc5cGJXRm5aUzlxY0dWbkJqc0dWRG9SYzJWeWRtbGpaVjl1WVcxbE9ncHNiMk5oYkE9PSIsImV4cCI6IjIwMjMtMDEtMTNUMTA6NDc6MDIuNTAxWiIsInB1ciI6ImJsb2Jfa2V5In19--02beb01ce4f14c47237d60d2ac56bbf882534547/132.jpg",
            resumeUrl: null,
            description: "Dear God! This parachute is a knapsack!",
            firstName: "Rebbeca",
            lastName: "Lueilwitz",
            memberSince: null,
            capacity: 40,
            name: "Rebbeca Lueilwitz",
            yearsExperience: 15,
            bachelorDegree: null,
            location: null,
            timezone: {
              name: "(UTC+05:00) Asia - Karachi",
              value: "Asia_Karachi",
              __typename: "Timezones"
            },
            __typename: "Profile",
            skills: [
              {
                category: "skill",
                enabled: true,
                name: "Facebook Ads",
                id: "244",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Flat UI",
                id: "256",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Spring Boot",
                id: "630",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Swift",
                id: "649",
                __typename: "Taggable"
              }
            ],
            tags: [],
            oldLocation: {
              city: null,
              country: "United Kingdom of Great Britain and Northern Ireland",
              countryFlag: "🇬🇧",
              state: null,
              __typename: "Location"
            }
          },
          __typename: "Assignment"
        },
        {
          id: "11",
          slug: "young-lueilwitz-34cbcdc6",
          firstName: "Young",
          lastName: "Lueilwitz",
          avatarUrl: "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhlbXByYVRGMWFHTmtPSEJxTURWMlpYWnRZemd3T1hWaE16Qmhid1k2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpTjJsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpNMkxtcHdaeUk3SUdacGJHVnVZVzFsS2oxVlZFWXRPQ2NuTXpZdWFuQm5CanNHVkRvUlkyOXVkR1Z1ZEY5MGVYQmxTU0lQYVcxaFoyVXZhbkJsWndZN0JsUTZFWE5sY25acFkyVmZibUZ0WlRvS2JHOWpZV3c9IiwiZXhwIjoiMjAyMy0wMS0xM1QxMDo0NzowMi4yNDNaIiwicHVyIjoiYmxvYl9rZXkifX0=--b3f31c710a60e4cc19f81bc1bc3c0112dae36390/36.jpg",
          archetype: "TECH_LEAD",
          profileId: "14e9a9a4-ab55-40b9-9c91-dcd934703c5b",
          profile: {
            id: "34",
            email: "young.lueilwitz@gsquad.io",
            phoneNumber: "207-065-1547",
            status: "ACTIVE",
            slug: "young-lueilwitz-34cbcdc6",
            capacityStatus: "AVAILABLE",
            teamsCount: 1,
            rateLastUpdatedAt: null,
            availabilityLastUpdatedAt: null,
            eligibleForVetting: false,
            archetype: "TECH_LEAD",
            avatarUrl: "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhlbXByYVRGMWFHTmtPSEJxTURWMlpYWnRZemd3T1hWaE16Qmhid1k2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpTjJsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpNMkxtcHdaeUk3SUdacGJHVnVZVzFsS2oxVlZFWXRPQ2NuTXpZdWFuQm5CanNHVkRvUlkyOXVkR1Z1ZEY5MGVYQmxTU0lQYVcxaFoyVXZhbkJsWndZN0JsUTZFWE5sY25acFkyVmZibUZ0WlRvS2JHOWpZV3c9IiwiZXhwIjoiMjAyMy0wMS0xM1QxMDo0NzowMi41NDZaIiwicHVyIjoiYmxvYl9rZXkifX0=--318dd48742d1af34e6514694acaf684055da05f2/36.jpg",
            resumeUrl: null,
            description: "Your collective dating record reads like a who’s who of human crap.",
            firstName: "Young",
            lastName: "Lueilwitz",
            memberSince: "2022-09-27T11:28:54Z",
            capacity: 40,
            name: "Young Lueilwitz",
            yearsExperience: 6,
            bachelorDegree: null,
            location: null,
            timezone: {
              name: "(UTC+05:00) Asia - Karachi",
              value: "Asia_Karachi",
              __typename: "Timezones"
            },
            __typename: "Profile",
            skills: [
              {
                category: "skill",
                enabled: true,
                name: "DNS Made Easy",
                id: "208",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Microsoft SQL Server",
                id: "433",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Retrofit",
                id: "570",
                __typename: "Taggable"
              }
            ],
            tags: [],
            oldLocation: {
              city: null,
              country: "Pakistan",
              countryFlag: "🇵🇰",
              state: null,
              __typename: "Location"
            }
          },
          __typename: "Assignment"
        },
        {
          id: "8",
          slug: "deana-kulas-552aeb9b",
          firstName: "Deana",
          lastName: "Kulas",
          avatarUrl: "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhiWEJsTURjME9XUm5OblUwYTI5aGJERTRNVEl4Y3pkdlozUTRaQVk2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpT1dsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpFd09TNXFjR2NpT3lCbWFXeGxibUZ0WlNvOVZWUkdMVGduSnpFd09TNXFjR2NHT3daVU9oRmpiMjUwWlc1MFgzUjVjR1ZKSWc5cGJXRm5aUzlxY0dWbkJqc0dWRG9SYzJWeWRtbGpaVjl1WVcxbE9ncHNiMk5oYkE9PSIsImV4cCI6IjIwMjMtMDEtMTNUMTA6NDc6MDIuMjc1WiIsInB1ciI6ImJsb2Jfa2V5In19--e7231b572804032647b0be055591f7e889c1749b/109.jpg",
          archetype: "CONTRIBUTOR",
          profileId: "16782a34-bdb9-4ba8-abc8-deadbd53afa0",
          profile: {
            id: "107",
            email: "deana.kulas@gsquad.io",
            phoneNumber: "(254) 475-7174",
            status: "ASSESSING",
            slug: "deana-kulas-552aeb9b",
            capacityStatus: "BOOKED",
            teamsCount: 2,
            rateLastUpdatedAt: null,
            availabilityLastUpdatedAt: null,
            eligibleForVetting: false,
            archetype: "CONTRIBUTOR",
            avatarUrl: "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhiWEJsTURjME9XUm5OblUwYTI5aGJERTRNVEl4Y3pkdlozUTRaQVk2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpT1dsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpFd09TNXFjR2NpT3lCbWFXeGxibUZ0WlNvOVZWUkdMVGduSnpFd09TNXFjR2NHT3daVU9oRmpiMjUwWlc1MFgzUjVjR1ZKSWc5cGJXRm5aUzlxY0dWbkJqc0dWRG9SYzJWeWRtbGpaVjl1WVcxbE9ncHNiMk5oYkE9PSIsImV4cCI6IjIwMjMtMDEtMTNUMTA6NDc6MDIuNTg2WiIsInB1ciI6ImJsb2Jfa2V5In19--923320beddf30d0d2ec9fd7a4ee1ec2cbfed12c7/109.jpg",
            resumeUrl: null,
            description: "No, homo habilis was erect. Australopithecus was never fully erect.",
            firstName: "Deana",
            lastName: "Kulas",
            memberSince: null,
            capacity: 40,
            name: "Deana Kulas",
            yearsExperience: 20,
            bachelorDegree: null,
            location: null,
            timezone: {
              name: "(UTC-05:00) America - New York",
              value: "America_New_York",
              __typename: "Timezones"
            },
            __typename: "Profile",
            skills: [
              {
                category: "skill",
                enabled: true,
                name: "Amazon DynamoDB",
                id: "32",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Amazon EC2",
                id: "34",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Autoprefixer",
                id: "88",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Facebook Ads",
                id: "244",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Swiftype",
                id: "651",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "TestNG",
                id: "663",
                __typename: "Taggable"
              }
            ],
            tags: [],
            oldLocation: {
              city: null,
              country: "United Kingdom of Great Britain and Northern Ireland",
              countryFlag: "🇬🇧",
              state: null,
              __typename: "Location"
            }
          },
          __typename: "Assignment"
        },
        {
          id: "10",
          slug: "effie-leannon-de065b2c",
          firstName: "Effie",
          lastName: "Leannon",
          avatarUrl: "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhhelpuY0cxa1pYWm9OemhqWVdwa2JUVmhOV1I2ZG1acGNUSjJkUVk2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpT1dsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpFd01pNXFjR2NpT3lCbWFXeGxibUZ0WlNvOVZWUkdMVGduSnpFd01pNXFjR2NHT3daVU9oRmpiMjUwWlc1MFgzUjVjR1ZKSWc5cGJXRm5aUzlxY0dWbkJqc0dWRG9SYzJWeWRtbGpaVjl1WVcxbE9ncHNiMk5oYkE9PSIsImV4cCI6IjIwMjMtMDEtMTNUMTA6NDc6MDIuMzA5WiIsInB1ciI6ImJsb2Jfa2V5In19--1dc67182621e98a7672c3c604bc5173989eae47c/102.jpg",
          archetype: "CONTRIBUTOR",
          profileId: "83c027b0-b452-495f-b9ea-912428a6b3d8",
          profile: {
            id: "100",
            email: "effie.leannon@gsquad.io",
            phoneNumber: "(367) 500-6135",
            status: "ASSESSING",
            slug: "effie-leannon-de065b2c",
            capacityStatus: "BOOKED",
            teamsCount: 1,
            rateLastUpdatedAt: null,
            availabilityLastUpdatedAt: null,
            eligibleForVetting: false,
            archetype: "CONTRIBUTOR",
            avatarUrl: "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhhelpuY0cxa1pYWm9OemhqWVdwa2JUVmhOV1I2ZG1acGNUSjJkUVk2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpT1dsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpFd01pNXFjR2NpT3lCbWFXeGxibUZ0WlNvOVZWUkdMVGduSnpFd01pNXFjR2NHT3daVU9oRmpiMjUwWlc1MFgzUjVjR1ZKSWc5cGJXRm5aUzlxY0dWbkJqc0dWRG9SYzJWeWRtbGpaVjl1WVcxbE9ncHNiMk5oYkE9PSIsImV4cCI6IjIwMjMtMDEtMTNUMTA6NDc6MDIuNjI2WiIsInB1ciI6ImJsb2Jfa2V5In19--391d5f1a1e2542a448fdc6e3222bfecdb8a9bb51/102.jpg",
            resumeUrl: null,
            description: "I can handle this. Handle is my middle name. Actually, handle is the middle of my first name.",
            firstName: "Effie",
            lastName: "Leannon",
            memberSince: null,
            capacity: 40,
            name: "Effie Leannon",
            yearsExperience: 20,
            bachelorDegree: null,
            location: null,
            timezone: {
              name: "(UTC+10:00) Australia - Melbourne",
              value: "Australia_Melbourne",
              __typename: "Timezones"
            },
            __typename: "Profile",
            skills: [
              {
                category: "skill",
                enabled: true,
                name: "GNU Bash",
                id: "273",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Karma",
                id: "372",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Perl",
                id: "505",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "Postmark",
                id: "523",
                __typename: "Taggable"
              },
              {
                category: "skill",
                enabled: true,
                name: "hapi",
                id: "318",
                __typename: "Taggable"
              }
            ],
            tags: [],
            oldLocation: {
              city: null,
              country: "United Kingdom of Great Britain and Northern Ireland",
              countryFlag: "🇬🇧",
              state: null,
              __typename: "Location"
            }
          },
          __typename: "Assignment"
        },
        {
          id: "7",
          slug: "raleigh-mills-a323b2c5",
          firstName: "Raleigh",
          lastName: "Mills",
          avatarUrl: "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhaM0pxY21SeWQzQnBZemx5WkdFMWJHUm5lVzFvZWpKMU9EQjZhZ1k2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpT1dsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpFeU55NXFjR2NpT3lCbWFXeGxibUZ0WlNvOVZWUkdMVGduSnpFeU55NXFjR2NHT3daVU9oRmpiMjUwWlc1MFgzUjVjR1ZKSWc5cGJXRm5aUzlxY0dWbkJqc0dWRG9SYzJWeWRtbGpaVjl1WVcxbE9ncHNiMk5oYkE9PSIsImV4cCI6IjIwMjMtMDEtMTNUMTA6NDc6MDIuMzU1WiIsInB1ciI6ImJsb2Jfa2V5In19--3c2ad2529d4a490dc2137120d196e53000e7a9bb/127.jpg",
          archetype: "CONTRIBUTOR",
          profileId: "144c3a7f-c0ca-44e0-be17-bb727322aef4",
          profile: {
            id: "125",
            email: "raleigh.mills@gsquad.io",
            phoneNumber: "342.444.3012",
            status: "REJECTED",
            slug: "raleigh-mills-a323b2c5",
            capacityStatus: "AVAILABLE",
            teamsCount: 1,
            rateLastUpdatedAt: null,
            availabilityLastUpdatedAt: null,
            eligibleForVetting: false,
            archetype: "CONTRIBUTOR",
            avatarUrl: "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhaM0pxY21SeWQzQnBZemx5WkdFMWJHUm5lVzFvZWpKMU9EQjZhZ1k2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpT1dsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpFeU55NXFjR2NpT3lCbWFXeGxibUZ0WlNvOVZWUkdMVGduSnpFeU55NXFjR2NHT3daVU9oRmpiMjUwWlc1MFgzUjVjR1ZKSWc5cGJXRm5aUzlxY0dWbkJqc0dWRG9SYzJWeWRtbGpaVjl1WVcxbE9ncHNiMk5oYkE9PSIsImV4cCI6IjIwMjMtMDEtMTNUMTA6NDc6MDIuNjY5WiIsInB1ciI6ImJsb2Jfa2V5In19--a9cd0b97e0cf30bff06dd72ccbf55404dd4ad839/127.jpg",
            resumeUrl: null,
            description: "It’s a moo point. It’s like a cow’s opinion; it doesn’t matter. It’s moo.",
            firstName: "Raleigh",
            lastName: "Mills",
            memberSince: null,
            capacity: 40,
            name: "Raleigh Mills",
            yearsExperience: 15,
            bachelorDegree: null,
            location: null,
            timezone: {
              name: "(UTC+01:00) Europe - Paris",
              value: "Europe_Paris",
              __typename: "Timezones"
            },
            __typename: "Profile",
            skills: [],
            tags: [],
            oldLocation: {
              city: null,
              country: "Pakistan",
              countryFlag: "🇵🇰",
              state: null,
              __typename: "Location"
            }
          },
          __typename: "Assignment"
        }
        ]
      }
    }
  },
}

export { MOCK_GET_TEAM };
