import { useEffect, useState } from "react";
import { useQueryData } from "hooks";
import { GET_TEAM_ACTIVITY_LOGS } from "graphql/queries/unifiedTeamActivityLogs";

/**
 * useTeamActivityLogs
 *
 * @param {String}  teamId
 * @param {String}  selectedDate
 */
export default function useTeamActivityLogs({ teamId, selectedDate }) {
  const [data, setData] = useState([]);
  const [days, setDays] = useState([]);

  const {
    loading,
    data: response,
    error,
  } = useQueryData({
    queryName: GET_TEAM_ACTIVITY_LOGS,
    keyName: "unifiedTeamActivityLogs",
    skip: !teamId,
    variables: { teamId, filters: { selectedDate } },
  });

  useEffect(() => {
    if (response) {
      setData(response.unifiedTeamActivityLogs?.activityLogs);
    }
  }, [response]);

  useEffect(() => {
    if (response) {
      setDays(extractDays(response.unifiedTeamActivityLogs?.activityLogs) || []);
    }
  }, [response]);

  return {
    loading,
    data,
    days,
    error,
  };
}

// extract dates from the array of nested objects (BE structure)
// and convert them to short format i.e Fri 9
// to make it UTC we need to pass timeZone: 'UTC' to toLocaleDateString
// which gives 'date day' i.e '12 Mon' so reversed it to 'Mon 12'
const extractDays = (data) =>
  data?.map(({ date }) => {
    const d = new Date(date);
    const options = { weekday: "short", day: "numeric", timeZone: "UTC" };
    const utcDate = d.toLocaleDateString("en-US", options);
    return utcDate.split(" ").reverse().join(" ");
  });
