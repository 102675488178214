import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors } from "style";
import Link from "./Link";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";

/**
 * DestructableLink
 *
 * @params {Boolean}   withMoreIcon
 */
const DestructableLink = ({ withDeleteIcon, ...props }) => {
  const LinkChild = () => <span className="link-text">{props.children}</span>;

  if (withDeleteIcon) {
    return (
      <span css={styles.container}>
        <Link css={styles.link} {...props}>
          <div css={styles.icon} className="link-icon">
            <Icon type={ICON_TYPE.trash} size={ICON_SIZE.small} color={"inherit"} />
          </div>
          <LinkChild />
        </Link>
      </span>
    );
  } else {
    return (
      <Link css={styles.link} {...props}>
        <LinkChild />
      </Link>
    );
  }
};

const styles = {
  container: css`
    display: inline-flex;
  `,
  link: css`
    display: inline-flex;
    align-items: center;
    color: ${colors.error};
    cursor: pointer;
    font-weight: 600;
    position: relative;
    gap:0.5rem;
    &:hover {
      .link-text {
        &:after {
          border-bottom: 1px ${colors.error} solid;
        }
      }
    }
    .link-text {
      position: relative;
      display: inline-flex;
      align-items: center;
      &:after {
        content: " ";
        position: absolute;
        width: 100%;
        bottom: -0.25rem;
        height: 1px;
        display: block;
        transition: transform 0.3s ease;
        border-bottom: 1px transparent solid;
      }
    }
  `,
};

DestructableLink.propTypes = {
  withDeleteIcon: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default DestructableLink;
