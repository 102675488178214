import { gql } from "@apollo/client";

const rateFields = `
  amount
  currency
  frequency
  symbol
`;

const APPLICANT_RATE_FIELDS = gql`
  fragment ApplicantRateFields on MissionRoleApplicant {
    rate {
      ${rateFields}
    }
  }
`;

export { APPLICANT_RATE_FIELDS };
