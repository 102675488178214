import React, { Fragment, useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import Card from "components/Card";
import { breakpoints, colors, fontSize, margins } from "style";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import useTeamBuilder from "hooks/useTeamBuilder";
import { UserArchetype, UserFullName } from "components/User";
import { Grid, Row } from "components/Containers";
import { TextLoader } from "components/Loader";
import { formatCurrency, formatNumber, getYearsExperience } from "utils";
import Tags from "components/Tags";
import { TAG_COLORS, TAG_SIZE } from "components/Tags/Tag";
import { ProfileModal } from "components/Profile";
import { LINKS, ARCHETYPES } from "constants";
import { BUTTON_SIZE, DestructableButton } from "components/Buttons";
import { useViewport } from "hooks";
import { WhiteLink } from "components/Links";

/**
 * TeamSummary
 */
const TeamSummary = () => {
  const { isMobile } = useViewport();
  const [expanded, setExpanded] = useState(!isMobile); // Expanded by default on desktop
  const { listProfiles, estimationData, loadingEstimateData, handleRemoveProfile, removing } = useTeamBuilder({});
  const handleToggle = () => setExpanded(!expanded);
  const [loading, setLoading] = useState(true);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [sortedProfiles, setSortedProfiles] = useState([]);

  const handleRowClick = (id) => {
    setSelectedProfileId(id);
    setShowProfileModal(true);
  };

  useEffect(() => {
    setLoading(loadingEstimateData);
  }, [estimationData, loadingEstimateData]);

  // Sort profiles by archetype (Squad Lead, Tech Lead, Contributor)
  useEffect(() => {
    const sortedListProfiles = Object.assign([], listProfiles);

    sortedListProfiles.sort((a, b) => {
      const order = [ARCHETYPES.squad_lead.id, ARCHETYPES.tech_lead.id, ARCHETYPES.contributor.id];
      return order.indexOf(a.archetype) - order.indexOf(b.archetype);
    });

    setSortedProfiles(sortedListProfiles);
  }, [listProfiles]);

  return (
    <>
      <StyledCard noMargin>
        <div css={styles.container}>
          <div css={styles.cell}>
            <span css={styles.label}>
              <FormattedMessage id="Teams.TeamSummary.LabelTeam" />
            </span>
            <span css={styles.value}>
              {loading ? (
                <TextLoader width="8rem" color="rgba(255,255,255,0.2)" />
              ) : !estimationData ? (
                `—`
              ) : (
                <FormattedMessage
                  id="Teams.TeamSummary.MemberCount"
                  values={{
                    count: formatNumber(listProfiles.length),
                  }}
                />
              )}
            </span>
          </div>
          <div css={styles.cell}>
            <span css={styles.label}>
              <FormattedMessage id="Teams.TeamSummary.LabelAverageMonthlyPrice" />
            </span>
            <span css={styles.value}>
              {loading ? (
                <TextLoader width="8rem" color="rgba(255,255,255,0.2)" />
              ) : !estimationData ? (
                `—`
              ) : (
                <FormattedMessage
                  id="Teams.TeamSummary.AverageMonthlyPrice"
                  values={{
                    min: formatCurrency(estimationData?.monthlyPrice?.low, estimationData?.currency, "code", true),
                    max: formatCurrency(estimationData?.monthlyPrice?.high, estimationData?.currency, "code", true),
                  }}
                />
              )}
            </span>
          </div>
          <div css={styles.cell}>
            <span css={styles.label}>
              <FormattedMessage id="Teams.TeamSummary.LabelAverageHourlyRate" />
            </span>
            <span css={styles.value}>
              {loading ? (
                <TextLoader width="8rem" color="rgba(255,255,255,0.2)" />
              ) : !estimationData ? (
                `—`
              ) : (
                <FormattedMessage
                  id="Teams.TeamSummary.AverageHourlyRate"
                  values={{
                    rate: formatCurrency(estimationData?.blendedHourlyRate, estimationData?.currency, "code", true),
                  }}
                />
              )}
            </span>
          </div>
          <div css={styles.cell}>
            <span css={styles.label}>
              <FormattedMessage id="Teams.TeamSummary.LabelAverageExperience" />
            </span>
            <span css={styles.value}>
              {loading ? (
                <TextLoader width="8rem" color="rgba(255,255,255,0.2)" />
              ) : !estimationData ? (
                `—`
              ) : (
                <FormattedMessage
                  id="Teams.TeamSummary.AverageExperience"
                  values={{
                    years: formatNumber(estimationData?.averageExperienceYears),
                  }}
                />
              )}
            </span>
          </div>
        </div>
        {estimationData && (
          <div css={styles.hidden_container(expanded)}>
            <Grid css={styles.table}>
              <Grid.col css={[styles.grid_cell, styles.header_cell, styles.name_container]}>
                <FormattedMessage id="Teams.TeamSummary.TableHeaderMember" />
              </Grid.col>
              <Grid.col css={[styles.grid_cell, styles.header_cell, styles.rate_container]}>
                <FormattedMessage id="Teams.TeamSummary.TableHeaderArchetype" />
              </Grid.col>
              <Grid.col css={[styles.grid_cell, styles.header_cell, styles.rate_container]}>
                <FormattedMessage id="Teams.TeamSummary.TableHeaderHourlyRate" />
              </Grid.col>
              <Grid.col css={[styles.grid_cell, styles.header_cell, styles.rate_container]}>
                <FormattedMessage id="Teams.TeamSummary.TableHeaderYearsOfExp" />
              </Grid.col>
              <Grid.col css={[styles.grid_cell, styles.header_cell, styles.rate_container]}>
                <FormattedMessage id="Teams.TeamSummary.TableHeaderHoursPerWeek" />
              </Grid.col>
              <Grid.col css={[styles.grid_cell, styles.header_cell, styles.rate_container]}>
                <FormattedMessage id="Teams.TeamSummary.TableHeaderMonthlyRate" />
              </Grid.col>
              <Grid.col css={[styles.grid_cell, styles.header_cell, styles.skills_container]}>
                <FormattedMessage id="Teams.TeamSummary.TableHeaderTopSkills" />
              </Grid.col>
              <Grid.col css={[styles.grid_cell, styles.header_cell, styles.cta_container]} />
              {sortedProfiles.map((profile) => (
                <Fragment key={`col-${profile.id}`}>
                  <Grid.col css={[styles.grid_cell, styles.name_container]}>
                    <UserFullName
                      data={profile}
                      withPhoto
                      onDark
                      onClick={() => handleRowClick(profile.id)}
                      photoProps={{
                        width: `3rem`,
                        height: `3rem`,
                        borderRadius: `100%`,
                      }}
                    />
                    <span css={styles.mobile_cta}>
                      <DestructableButton
                        onClick={() => handleRemoveProfile(profile.id)}
                        size={BUTTON_SIZE.small}
                        disabled={removing}
                      >
                        <Icon type={ICON_TYPE.trash} size={ICON_SIZE.small} color="inherit" />
                      </DestructableButton>
                    </span>
                  </Grid.col>
                  <Grid.col css={[styles.grid_cell, styles.rate_container]}>
                    <span css={styles.mobile_label}>
                      <FormattedMessage id="Teams.TeamSummary.TableHeaderArchetype" />
                    </span>
                    <UserArchetype data={profile} />
                  </Grid.col>
                  <Grid.col css={[styles.grid_cell, styles.rate_container]}>
                    <span css={styles.mobile_label}>
                      <FormattedMessage id="Teams.TeamSummary.TableHeaderHourlyRate" />
                    </span>
                    {estimationData?.breakdown[profile.id] && (
                      <Row>
                        <Icon type={ICON_TYPE.coin} size={ICON_SIZE.small} color="#fff" />
                        <span>
                          <FormattedMessage
                            id="Teams.TeamSummary.MemberHourlyRate"
                            values={{
                              low: formatCurrency(
                                estimationData?.breakdown[profile.id].hourly?.low,
                                estimationData?.currency,
                                "symbol",
                                true
                              ),
                              high: formatCurrency(
                                estimationData?.breakdown[profile.id].hourly?.high,
                                estimationData?.currency,
                                "symbol",
                                true
                              ),
                            }}
                          />
                        </span>
                      </Row>
                    )}
                  </Grid.col>
                  <Grid.col css={[styles.grid_cell, styles.rate_container]}>
                    <span css={styles.mobile_label}>
                      <FormattedMessage id="Teams.TeamSummary.TableHeaderYearsOfExp" />
                    </span>
                    {getYearsExperience(profile.workExperiences)}
                  </Grid.col>
                  <Grid.col css={[styles.grid_cell, styles.rate_container]}>
                    <span css={styles.mobile_label}>
                      <FormattedMessage id="Teams.TeamSummary.TableHeaderHoursPerWeek" />
                    </span>
                    <FormattedMessage id={`Teams.TeamSummary.MemberHoursPerWeek_${profile?.archetype}`} />
                  </Grid.col>
                  <Grid.col css={[styles.grid_cell, styles.rate_container]}>
                    <span css={styles.mobile_label}>
                      <FormattedMessage id="Teams.TeamSummary.TableHeaderMonthlyRate" />
                    </span>
                    {estimationData?.breakdown[profile.id] && (
                      <Row>
                        <Icon type={ICON_TYPE.coin} size={ICON_SIZE.small} color="#fff" />
                        <span>
                          <FormattedMessage
                            id="Teams.TeamSummary.MemberMonthlyRate"
                            values={{
                              low: formatCurrency(
                                estimationData?.breakdown[profile.id].monthly?.low,
                                estimationData?.currency,
                                "symbol",
                                true
                              ),
                              high: formatCurrency(
                                estimationData?.breakdown[profile.id].monthly?.high,
                                estimationData?.currency,
                                "symbol",
                                true
                              ),
                            }}
                          />
                        </span>
                      </Row>
                    )}
                  </Grid.col>
                  <Grid.col css={[styles.grid_cell, styles.skills_container]}>
                    <Tags data={profile.skills} {...TAG_COLORS.purple} limit={3} size={TAG_SIZE.small} />
                  </Grid.col>
                  <Grid.col css={[styles.grid_cell, styles.cta_container]}>
                    <DestructableButton
                      onClick={() => handleRemoveProfile(profile.id)}
                      size={BUTTON_SIZE.small}
                      disabled={removing}
                    >
                      <Icon type={ICON_TYPE.trash} size={ICON_SIZE.small} color="inherit" />
                    </DestructableButton>
                  </Grid.col>
                </Fragment>
              ))}
            </Grid>
          </div>
        )}
        {estimationData && (
          <div css={styles.footer}>
            <div css={styles.expand_container(expanded)} onClick={handleToggle}>
              <Icon type={ICON_TYPE.chevronDown} size={ICON_SIZE.small} color={colors.white} />
            </div>
            <div css={styles.book_container}>
              <FormattedMessage
                id="Teams.TeamSummary.CTAPrefix"
                values={{
                  cta: (
                    <WhiteLink to={LINKS.book_meeting_link} withUnderline routerEnabled>
                      <FormattedMessage id="Banner.BannerShowcaseBookMeeting.CTA" />
                    </WhiteLink>
                  ),
                }}
              />
            </div>
          </div>
        )}
      </StyledCard>
      {selectedProfileId && (
        <ProfileModal
          id={selectedProfileId}
          show={showProfileModal}
          handleClose={() => setShowProfileModal(false)}
          handlePrimary={() => {
            setShowProfileModal(false);
            window.open(LINKS.public_profile(selectedProfileId), "_blank");
          }}
        />
      )}
    </>
  );
};

const StyledCard = styled(Card)`
  height: 100%;
  padding: 0;
  cursor: pointer;
  z-index: 1;
  background: ${colors.purpleRainBase};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0 0rem 0;
  gap: 1rem;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0 !important;
  }
`;

const styles = {
  table: css`
    grid-auto-rows: 1fr;
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    grid-template-columns: repeat(17, minmax(0, 1fr));
    width: 100%;
  `,
  footer: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
  `,
  book_container: css`
    text-align: center;
    padding: 1rem 0 1rem 0;
    color: ${colors.white};
    width: 100%;
    border-radius: 0 0 0.6rem 0.6rem;
    background: rgba(0, 0, 0, 0.1);
  `,
  grid_cell: css`
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
    color: #fff;
    padding: 1rem 1rem;

    @media (max-width: ${breakpoints.tablet}) {
      justify-content: space-between;
      padding: 1rem 2rem;
    }
  `,
  header_cell: css`
    font-weight: 600;
    height: 4rem;
    background: none;

    @media (max-width: ${breakpoints.tablet}) {
      display: none;
    }
  `,
  name_container: css`
    grid-column: span 3;
    padding-left: 3rem;

    @media (max-width: ${breakpoints.tablet}) {
      padding-left: 2rem;
    }
  `,
  rate_container: css`
    grid-column: span 2;
  `,
  skills_container: css`
    grid-column: span 3;
  `,
  cta_container: css`
    text-align: right;
    grid-column: span 1;
    padding-right: 3rem;
    justify-content: flex-end;

    @media (max-width: ${breakpoints.tablet}) {
      display: none;
    }
  `,
  mobile_cta: css`
    display: none;

    @media (max-width: ${breakpoints.tablet}) {
      display: inline-block;
    }
  `,
  container: css`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 ${margins.normal};
    display: flex;
    color: #fff;
    width: 100%;
    margin-bottom: 2rem;

    @media (max-width: ${breakpoints.tablet}) {
      padding: 1rem 2rem 1rem 2rem;
      margin-bottom: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  `,
  hidden_container: (expanded) => css`
    display: ${expanded ? "flex" : "none"};
    flex-direction: column;
    align-items: center;
    padding: 0 ${margins.normal};
    padding: 0rem 0rem;
    margin-bottom: -2rem;
    width: 100%;
    border: 1px rgba(255, 255, 255, 0.1) solid;
  `,
  thumb: css`
    border: 1px #fff solid;
    border-radius: 100%;
  `,
  cell: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
    text-align: center;

    @media (max-width: ${breakpoints.tablet}) {
      width: 100%;
      justify-content: space-between;
      line-height: 2.4rem;
      flex-direction: row;
    }
  `,
  mobile_label: css`
    display: none;
    font-weight: 500;

    @media (max-width: ${breakpoints.tablet}) {
      display: inline-block;
    }
  `,
  label: css`
    font-weight: 500;
    font-size: ${fontSize.small};

    @media (max-width: ${breakpoints.tablet}) {
      font-size: ${fontSize.small};
    }
  `,
  value: css`
    font-size: ${fontSize.medium};
    font-weight: 300;

    @media (max-width: ${breakpoints.tablet}) {
      font-size: ${fontSize.small};
    }
  `,
  expand_container: (expanded) => css`
    background: ${expanded ? `rgba(0, 0, 0, 0.1)` : `none`};
    padding: 0.25rem 0;
    width: 100%;
    border-radius: 0 0 0.6rem 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    transform: rotate(${expanded ? "180deg" : "0deg"});

    @media (max-width: ${breakpoints.tablet}) {
      margin-top: ${expanded ? `1rem` : `-1rem`};
    }
  `,
};

TeamSummary.propTypes = {};

export default TeamSummary;
