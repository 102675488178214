import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { SquadIcon } from "components/Squads";
import { Col } from "components/Containers";
import { UserPhotoList } from "components/User";
import { colors, fontSize } from "style";
import { PrimaryButton } from "components/Buttons";
import { LINKS } from "constants";
import Card from "components/Card";
import { Link } from "components/Links";
import { useAuth, useMission } from "hooks";
import { TextLoader } from "components/Loader";
import { shortenText } from "utils";
import { OrganizationLink } from "components/Organization";

/**
 * SquadCard
 *
 * @param {Object} data
 */
const SquadCard = ({ data }) => {
  const { isAdmin } = useAuth();
  const { data: mission, loading } = useMission({ id: data?.missionId });

  if (!data) return null;

  return (
    <Card>
      <Col gap="2rem" css={styles.container}>
        <Col gap="0.5rem" css={styles.container}>
          <SquadIcon name={data?.name} organizationName={data?.organization?.name} size="8rem" />
          <div css={styles.title}>{data?.name}</div>
          <div css={styles.subtitle}>
            <OrganizationLink data={data?.organization} withOverlay={isAdmin} />
          </div>
          <div css={styles.description}>
            {loading && <TextLoader width={`20rem`} />}
            {shortenText(mission?.description, 120)}
          </div>
          <UserPhotoList
            users={data?.assignments}
            thumbCount={data?.assignments?.length}
            thumbLimit={5}
            thumbSize="3rem"
            thumbBorderRadius="100%"
          />
        </Col>
        <Link to={LINKS.squad_profile(data?.slug)} css={styles.button} routerEnabled>
          <PrimaryButton css={styles.button}>
            <FormattedMessage id="Squads.SquadCard.cta" />
          </PrimaryButton>
        </Link>
      </Col>
    </Card>
  );
};

const styles = {
  container: css`
    align-items: center;
    text-align: center;
    line-height: normal;
  `,
  title: css`
    font-size: ${fontSize.medium};
    font-weight: 500;
  `,
  subtitle: css`
    font-size: ${fontSize.small};
  `,
  description: css`
    font-size: ${fontSize.xsmall};
    color: ${colors.grayAnatomyBase};
    margin: 1rem 0 0 0;
  `,
  button: css`
    display: block;
    width: 100%;
  `,
};

SquadCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SquadCard;
