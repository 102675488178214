import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useAuth } from "hooks";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { Row } from "components/Containers";

/**
 * MissionOrganizationName
 *
 * @param {Object}   data
 * @param {String}   iconSize
 * @param {Boolean}  isLoading
 * @param {Boolean}  hideConfidentialIcon
 */
const MissionOrganizationName = ({ data, iconSize, isLoading, hideConfidentialIcon }) => {
  const { isAdmin, isClient } = useAuth();
  const authorizedUser = isAdmin || isClient;
  const isOrganizationConfidential = !authorizedUser && data?.isConfidential;
  const showConfidentialIcon = !hideConfidentialIcon && authorizedUser && data?.isConfidential;

  const messageValues = {
    name: data?.name,
    icon: showConfidentialIcon && <Icon type={ICON_TYPE.eyeBlocked} size={iconSize} color="inherit" />,
  };

  if (isLoading) return null;

  return (
    <Row gap="0.5rem" css={styles.container}>
      <FormattedMessage
        id={`Missions.MissionOrganizationName.Name${isOrganizationConfidential ? "Confidential" : ""}`}
        values={messageValues}
      />
    </Row>
  );
};

const styles = {
  container: css`
    display: inline-flex;
  `,
};

MissionOrganizationName.defaultProps = {
  iconSize: ICON_SIZE.small,
  disableLink: false,
  hideConfidentialIcon: false,
};

MissionOrganizationName.propTypes = {
  data: PropTypes.object,
  iconSize: PropTypes.string,
  isLoading: PropTypes.bool,
  disableLink: PropTypes.bool,
  hideConfidentialIcon: PropTypes.bool,
};

export default MissionOrganizationName;
