import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import { Grid, PageWrapper } from "components/Containers";
import { NetworkList } from "components/Network";
import { BannerShowcaseSignup, Banner, BookMeetingMemberBanner } from "components/Banner";
import PageTitle from "components/PageTitle";
import { SearchWithFilters } from "components/Search";
import { LINKS } from "constants";
import { useList } from "hooks";
import { LIST_TYPES } from "constants";
import { PrimaryLink } from "components/Links";
import Disclaimer from "components/Disclaimer";
import Card from "components/Card";
import NoResults from "components/Messages/NoResults";

/**
 * FeaturedTeamProfiles
 */
const FeaturedTeamProfiles = () => {
  const intl = useIntl();
  const { slug } = useParams();
  const [staticFilters, setStaticFilters] = useState([]);
  const variables = { filters: { type: LIST_TYPES.featuredTeam, slug } };
  const { data, loading } = useList({ variables });
  const selectedList = data?.[0];

  useEffect(() => {
    if (selectedList?.profiles?.length > 0) {
      const profileIds = selectedList?.profiles?.map((profile) => `${profile.id}`) || [];
      setStaticFilters([{ key: "profile_id.keyword", operator: "or", categoricalValues: profileIds }]);
    }
  }, [selectedList?.profiles]);

  return (
    <PageWrapper>
      <BannerShowcaseSignup
        css={css`
          margin: 4rem 0 1rem 0;
        `}
      />
      <Helmet>
        <title>
          {intl
            .formatMessage({ id: "Meta.Missions.FeaturedTeam.Title" })
            .replace("{name}", selectedList?.title || "...")}
        </title>
      </Helmet>
      <Banner keyName="showFeaturedTeamsBanner">
        <FormattedMessage
          id="Nav.SideNav.LabelFeaturedTeams.Tooltip"
          values={{
            intro: (
              <b>
                <FormattedMessage id="Nav.SideNav.LabelFeaturedTeams.Tooltip.Intro" />
              </b>
            ),
          }}
        />
      </Banner>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            loading={loading}
            backLink={
              <PrimaryLink to={LINKS.featured_teams} disabled={loading} routerEnabled>
                <FormattedMessage id="Global.GoBack" />
              </PrimaryLink>
            }
            title={selectedList?.title}
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          {!loading && selectedList?.profiles?.length === 0 && (
            <Card>
              <NoResults />
            </Card>
          )}
          {staticFilters.length > 0 && (
            <SearchWithFilters search={false} renderer={NetworkList} staticFilters={staticFilters} />
          )}
        </Grid.col>
        <Grid.col start={1} end={13}>
          <BookMeetingMemberBanner />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Disclaimer />
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default FeaturedTeamProfiles;
