import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { colors, fontSize, margins } from "style";
import { H3 } from "components/Text";
import { Header } from "components/DisplayBox";
import { Row } from "components/Containers";
import { TextLoader } from "components/Loader";
import { MessagesError } from "components/Messages";

/**
 * ListTable
 *
 * @param {Array}    data
 * @param {Boolean}  loading
 * @param {Object}   error
 * @param {String}   marginSize
 * @param {String}   labelKey
 * @param {String}   valueKey
 * @param {Any}      title
 * @param {Any}      secondaryTitle
 * @param {Boolean}  singleColumn
 */
const ListTable = ({
  data,
  loading,
  error,
  marginSize,
  labelKey,
  valueKey,
  title,
  secondaryTitle,
  singleColumn,
  descriptionKey,
  ...props
}) => (
  <>
    {(title || secondaryTitle) && (
      <Header marginSize={marginSize} loading={loading}>
        {title && <H3>{title}</H3>}
        {secondaryTitle}
      </Header>
    )}
    {error && <MessagesError />}
    {loading &&
      Array.from(Array(3).keys()).map((key) => (
        <Row key={key} css={styles.row} {...props}>
          <TextLoader />
        </Row>
      ))}
    {singleColumn
      ? data?.map((row, index) => (
          <Row key={index} css={styles.row} {...props}>
            <div css={styles.single_container}>
              <div css={styles.label}>{row[labelKey]}</div>
              <div css={styles.value}>{row[valueKey]}</div>
            </div>
          </Row>
        ))
      : data?.map((row, index) => (
          <Row key={index} css={styles.row} {...props}>
            <div css={styles.single_container}>
              {row[labelKey] && <div css={styles.label}>{row[labelKey]}</div>}
              {row[descriptionKey] && <div css={styles.description}>{row[descriptionKey]}</div>}
            </div>
            <div css={styles.value}>{row[valueKey]}</div>
          </Row>
        ))}
  </>
);

const styles = {
  row: css`
    width: 100%;
    justify-content: space-between;
    min-height: 4.7rem;
    padding: 1.5rem 0;
    box-sizing: border-box;
    border-bottom: 1px ${colors.grayAnatomyLight4} solid;

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  `,
  label: css`
    font-size: ${fontSize.small};
    font-weight: 500;
    text-align: left;
  `,
  value: css`
    font-size: ${fontSize.small};
    white-space: pre-wrap;
    color: ${colors.grayAnatomyLight6};
    text-align: right;
  `,
  description: css`
    width: 100%;
    text-align: left;
    margin-top: 0.5rem;
    line-height: normal;
  `,
  single_container: css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `,
};

ListTable.defaultProps = {
  marginSize: margins.normal,
  labelKey: "label",
  valueKey: "value",
  descriptionKey: "description",
};

ListTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
  marginSize: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  title: PropTypes.any,
  secondaryTitle: PropTypes.any,
  singleColumn: PropTypes.bool,
  descriptionKey: PropTypes.string,
};

export default ListTable;
