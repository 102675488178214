/** @jsx jsx */
import styled from "@emotion/styled";
import { breakpoints, colors, margins } from "style";

/**
 * Card
 */
const Card = styled.div`
  display: flex;
  padding: 2rem ${margins.normal} 2rem ${margins.normal};
  border-radius: ${({ isAdmin }) => (isAdmin ? `0 0 1rem 1rem` : `1rem`)};
  background: #fff;
  border: none;
  position: relative;
  flex-direction: column;
  min-width: 0;
  background-clip: border-box;
  border: 1px ${colors.grayAnatomyLight4} solid;
  border-top-color: ${({ isAdmin }) => (isAdmin ? colors.peach : colors.grayAnatomyLight4)};
  border-top-width: ${({ isAdmin }) => (isAdmin ? `1px` : `1px`)};
  position: relative;
  ${(props) => props.fixedHeight && `height:100%; margin-bottom:0;`}
  ${(props) => props.noPadding && `padding: 0;`}
  ${(props) => props.noMargin && `margin: 0;`}
  ${(props) => props.smallPadding && `padding: 1.5rem 3rem 1.5rem 3rem;`}

  ${({ isAdmin }) =>
    isAdmin &&
    `
    &:after {
      content: 'admin';
      position: absolute;
      top: 0;
      right: 0;
      background: #ffe4e5;
      border-bottom-left-radius: 0.5rem;
      border-top-right-radius: 0rem;
      color: ${colors.peach};
      font-size: 1rem;
      padding: 0.35rem 1rem;
    }
  `}

  @media (max-width: ${breakpoints.portrait}) {
    ${(props) => !props.noPadding && `padding: ${margins.small} !important;`}
    ${(props) => props.smallPadding && `padding: 1.5rem 2.5rem 1.5rem 2.5rem !important;`}
  }

  @media (max-width: ${breakpoints.mobile}) {
    ${(props) => !props.noPadding && `padding: ${margins.xsmall} !important;`}
    ${(props) => props.smallPadding && `padding: 1.5rem 3rem 1.5rem 3rem !important;`}
  }
`;

const CardMenu = styled.div`
  position: absolute;
  top: 2rem;
  right: 3rem;
`;

export { CardMenu };
export { default as CardPostTitle } from "./CardPostTitle";
export { default as CardList } from "./CardList";

export default Card;
