import { GET_ME } from "graphql/queries";

const MOCK_GET_ME = [
  {
    request: {
      query: GET_ME,
    },
    result: {
      data: {
        me: {
          id: "1",
          uid: "test@mail.com",
          profileId: "136",
          __typename: "Me",
          profileUuid: "amina-lueilwitz-c9795ede",
          profileSlug: "amina-lueilwitz-c9795ede",
          firstName: "Admin",
          lastName: "Mission",
          fullName: "Admin Mission",
          isImpersonating: false,
          preferences: {},
          permissions: [
            {
              action: "CAN_EDIT_RATE",
              canPerformAction: true,
            },
          ],
          hasPartner: false,
          memberSince: null,
          isPrimaryContact: null,
          organization: null,
          isLoading: false,
          isAuthenticated: true,
          needsToSignTerms: true,
          teams: [],
          profile: {
            id: 1,
            email: "test@mail.com",
            phoneNumber: "(714) 184-3719",
            candidate: false,
            status: "ACTIVE",
            slug: "stan2-heathcote-5177bb19",
            capacityStatus: "AVAILABLE",
            teamsCount: 1,
            rateLastUpdatedAt: null,
            availabilityLastUpdatedAt: null,
            eligibleForVetting: false,
            currentTime: null,
            isOnline: null,
            nextAvailability: null,
            partner: null,
            archetype: "CONTRIBUTOR",
            avatarUrl:
              "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhNblI0WVdSeGJXRndlbkJyZVRCdmFYTXpPVzg0TTNobmRqSjBkQVk2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpUFdsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWtSaGRtbGtMbXB3WnlJN0lHWnBiR1Z1WVcxbEtqMVZWRVl0T0NjblJHRjJhV1F1YW5CbkJqc0dWRG9SWTI5dWRHVnVkRjkwZVhCbFNTSVBhVzFoWjJVdmFuQmxad1k3QmxRNkVYTmxjblpwWTJWZmJtRnRaVG9LYkc5allXdz0iLCJleHAiOiIyMDIzLTA0LTE5VDEzOjU1OjMzLjc3N1oiLCJwdXIiOiJibG9iX2tleSJ9fQ==--daae16f3812b4a4ed751363e9eca72db59f2043e/David.jpg",
            resumeUrl: null,
            description:
              "You know what’s weird? Donald Duck never wore pants. But whenever he’s getting out of the shower, he always puts a towel around his waist. I mean, what is that about?",
            firstName: "Admin",
            lastName: "Mission",
            name: "Admin Mission",
            memberSince: "2023-04-19T12:00:09Z",
            capacity: 40,
            yearsExperience: 3,
            bachelorDegree: null,
            location: null,
            timezone: {
              name: "(UTC-05:00) America - New York",
              value: "America_New_York",
              __typename: "Timezones",
            },
            __typename: "Profile",
          },
          roles: [
            {
              name: "ADMIN",
            },
          ],
          featureFlags: [
            {
              name: "EM_DASHBOARD",
              enabled: true,
            },
          ],
          abilities: [
            {
              subject: "LibraryItem",
              actions: ["read", "edit", "create", "update", "destroy"],
              conditions: {},
            },
            {
              subject: "Mission",
              actions: ["read", "edit", "create", "update", "destroy"],
              conditions: {},
            },
            {
              subject: "MissionRole",
              actions: ["read", "edit", "create", "update", "destroy"],
              conditions: {},
            },
            {
              subject: "Partner",
              actions: ["read", "edit", "create", "update", "destroy"],
              conditions: {},
            },
            {
              subject: "Profile",
              actions: ["read", "edit", "create", "update", "destroy"],
              conditions: {},
            },
            {
              subject: "ProfileEducation",
              actions: ["read", "edit", "create", "update", "destroy"],
              conditions: {},
            },
            {
              subject: "ProfileLink",
              actions: ["read", "edit", "create", "update", "destroy"],
              conditions: {},
            },
            {
              subject: "ProfileVacation",
              actions: ["read", "edit", "create", "update", "destroy"],
              conditions: {},
            },
            {
              subject: "ProfileWorkExperience",
              actions: ["read", "edit", "create", "update", "destroy"],
              conditions: {},
            },
            {
              subject: "Team",
              actions: ["read", "edit", "create", "update", "destroy"],
              conditions: {},
            },
          ],
        },
      },
    },
  },
  {
    request: {
      query: GET_ME,
    },
    result: {
      data: {
        me: {
          id: "83",
          uid: "stan.heathcote@gsquad.io",
          profileId: "81",
          profileUuid: "4e1e3085-ed86-4ba0-b26c-6e43fdeb4b82",
          profileSlug: "stan2-heathcote-5177bb19",
          firstName: "Stan2",
          lastName: "Heathcote",
          fullName: "Stan2 Heathcote",
          hasPartner: false,
          memberSince: "2023-04-19",
          isPrimaryContact: null,
          isImpersonating: true,
          preferences: {
            homepage_tour_visible: true,
            mission_board_tour_visible: true,
          },
          permissions: [
            {
              action: "CAN_EDIT_RATE",
              canPerformAction: true,
            },
          ],
          profile: {
            id: "81",
            email: "stan.heathcote@gsquad.io",
            phoneNumber: "(714) 184-3719",
            candidate: false,
            status: "ACTIVE",
            slug: "stan2-heathcote-5177bb19",
            capacityStatus: "AVAILABLE",
            teamsCount: 1,
            rateLastUpdatedAt: null,
            availabilityLastUpdatedAt: null,
            eligibleForVetting: false,
            currentTime: null,
            isOnline: null,
            nextAvailability: null,
            partner: null,
            archetype: "CONTRIBUTOR",
            avatarUrl:
              "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhNblI0WVdSeGJXRndlbkJyZVRCdmFYTXpPVzg0TTNobmRqSjBkQVk2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpUFdsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWtSaGRtbGtMbXB3WnlJN0lHWnBiR1Z1WVcxbEtqMVZWRVl0T0NjblJHRjJhV1F1YW5CbkJqc0dWRG9SWTI5dWRHVnVkRjkwZVhCbFNTSVBhVzFoWjJVdmFuQmxad1k3QmxRNkVYTmxjblpwWTJWZmJtRnRaVG9LYkc5allXdz0iLCJleHAiOiIyMDIzLTA0LTE5VDEzOjU1OjMzLjc3N1oiLCJwdXIiOiJibG9iX2tleSJ9fQ==--daae16f3812b4a4ed751363e9eca72db59f2043e/David.jpg",
            resumeUrl: null,
            description:
              "You know what’s weird? Donald Duck never wore pants. But whenever he’s getting out of the shower, he always puts a towel around his waist. I mean, what is that about?",
            firstName: "Stan2",
            lastName: "Heathcote",
            memberSince: "2023-04-19T12:00:09Z",
            capacity: 40,
            name: "Stan2 Heathcote",
            yearsExperience: 3,
            bachelorDegree: null,
            location: null,
            timezone: {
              name: "(UTC-05:00) America - New York",
              value: "America_New_York",
              __typename: "Timezones",
            },
            __typename: "Profile",
          },
          featureFlags: [
            {
              name: "EM_DASHBOARD",
              enabled: true,
              __typename: "FeatureFlag",
            },
          ],
          needsToSignTerms: true,
          organization: null,
          teams: [
            {
              name: "Consulting",
              slug: "consulting-e113ad3f",
              organization: {
                slug: "jerde-zemlak-f8653d9e",
                __typename: "Organization",
              },
              __typename: "Team",
            },
          ],
          roles: [
            {
              name: "FREELANCER",
              __typename: "Role",
            },
          ],
          __typename: "Me",
        },
      },
    },
  },
];

export { MOCK_GET_ME };
