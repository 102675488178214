import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "components/Buttons";
import { useAuth } from "hooks";
import { PromptForm } from "components/Prompts";

/**
 * PromptAddButton
 */
const PromptAddButton = (props) => {
  const [showForm, setShowForm] = useState(false);
  const { isAdmin } = useAuth();

  if (!isAdmin) return null;

  return (
    <>
      <PrimaryButton onClick={() => setShowForm(true)}>
        <FormattedMessage id="Prompt.PromptAddButton.Button" />
      </PrimaryButton>
      <PromptForm
        show={showForm}
        onClose={() => setShowForm(false)}
        isEditing={false}
        {...props}
      />
    </>
  );
};

export default PromptAddButton;
