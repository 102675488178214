import React, { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { DataTableProvider } from "components/DataTable";
import Tooltip from "components/Tooltip";
import { formatDate, formatHours, getSecondsFromClock, isFutureDate, shortenText } from "utils";
import { useTimeSlip } from "hooks";
import { ACTIVITY_TYPES } from "constants/index";
import { TimeSlipInput, NotesInput } from "components/TimeSheet";
import { TimeSheetReducer, TIME_SHEET_ACTIONS } from "reducers";

/**
 * TimeSheetList
 *
 * @param {String}   selectedMonth
 * @param {Boolean}  disabled
 * @param {String}   profileId
 * @param {String}   selectedTeam
 * @param {Function} onTotalUpdated
 */
const TimeSheetList = ({ selectedMonth, disabled, profileId, selectedTeam, onTotalUpdated }) => {
  const [state, dispatch] = useReducer(TimeSheetReducer, []);
  const [footerData, setFooterData] = useState([]);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const {
    data,
    teams,
    loading,
    updateTime: updateTimeSlip,
    error: timeSlipError,
    getTotalHours,
  } = useTimeSlip({ profileId, selectedMonth, teamSlug: selectedTeam });

  useEffect(() => {
    setIsLoading(loading || !selectedMonth || !state);
  }, [loading, selectedMonth, state]);

  useEffect(() => {
    setError(timeSlipError);
  }, [timeSlipError]);

  useEffect(() => {
    dispatch({
      type: TIME_SHEET_ACTIONS.init,
      payload: data,
    });

    return () => {
      dispatch({
        type: TIME_SHEET_ACTIONS.init,
        payload: [],
      });
    };
  }, [data, selectedTeam]);

  useEffect(() => {
    setFooterData(createFooter(teams, selectedTeam, state, getTotalHours, onTotalUpdated));
  }, [state, teams, selectedTeam]);

  return (
    <DataTableProvider
      data={!isLoading ? state : null}
      error={!isLoading && error}
      loading={isLoading}
      columns={COLUMNS(dispatch, updateTimeSlip, disabled)}
      selectable={false}
      footer={footerData}
      resultsPerPage={30}
      paginationType={false}
    />
  );
};

function createFooter(teams, selectedTeam, state, getTotalHours, onTotalUpdated) {
  const data = [];
  const hourFormat = (value) => `${formatHours(parseInt(value) / 60)}`;
  let cummulativeTotal = 0;

  teams?.map((i, index) => {
    if (i.name === "All" || (selectedTeam !== "0" && i.id !== selectedTeam)) return;

    const current = state?.filter((item) => item.teamName === i.name);
    const communication = getTotalHours(current, "communication");
    const production = getTotalHours(current, "production");
    const review = getTotalHours(current, "review");
    const planning = getTotalHours(current, "planning");
    const total = communication + production + review + planning;
    const average = parseInt((current && current[0]?.averageCapacity) || 0);
    const averagePercentage = ((total / 60) * 100) / (average * 60);

    cummulativeTotal += total;

    return data.push({
      name: i?.name,
      columns: [
        {
          label: (
            <b>
              {index === 1 || selectedTeam !== "0" ? (
                <FormattedMessage id="TimeSheet.TimeSheetList.FooterTotalCell" />
              ) : (
                ""
              )}
            </b>
          ),
        },
        { label: <b>{shortenText(i?.name, 18)}</b> },
        {
          label: hourFormat(communication),
          detail: `${(communication > 0 ? (communication * 100) / total : 0).toFixed(1)}%`,
        },
        { label: hourFormat(production), detail: `${(production > 0 ? (production * 100) / total : 0).toFixed(1)}%` },
        { label: hourFormat(review), detail: `${(review > 0 ? (review * 100) / total : 0).toFixed(1)}%` },
        { label: hourFormat(planning), detail: `${(planning > 0 ? (planning * 100) / total : 0).toFixed(1)}%` },
        {
          label: <b>{hourFormat(total)} hours</b>,
          detail: `${averagePercentage?.toFixed(1)}% of ${average.toFixed()} budgeted hours`,
          incomplete: averagePercentage?.toFixed() !== "100",
        },
        {},
      ],
    });
  });

  if (typeof onTotalUpdated === "function") {
    onTotalUpdated(cummulativeTotal);
  }

  return data;
}

const EDITABLE_KEYS = Object.keys(ACTIVITY_TYPES);

/**
 * Default TimeSheet Columns
 *
 * @param {Function} dispatch
 */
const COLUMNS = (dispatch, updateTimeSlip, disabled) => [
  {
    label: <FormattedMessage id="TimeSheet.TimeSheetList.ColumnDate" />,
    width: "15%",
    disabled: (row) => isFutureDate(row.assignmentDate),
    cell: (row, previousRow) =>
      row.assignmentDate !== previousRow?.assignmentDate && formatDate(row.assignmentDate, "MMM D, ddd"),
  },
  {
    label: <FormattedMessage id="TimeSheet.TimeSheetList.ColumnMission" />,
    width: "10%",
    disabled: (row) => isFutureDate(row.assignmentDate),
    cell: (row) => shortenText(row.teamName, 18),
  },
  ...EDITABLE_COLUMNS(dispatch, updateTimeSlip, disabled),
  {
    label: <FormattedMessage id="TimeSheet.TimeSheetList.ColumnTotal" />,
    width: "10%",
    disabled: (row) => isFutureDate(row.assignmentDate),
    cell: (row) => (
      <b>
        {formatHours(
          EDITABLE_KEYS.reduce(
            (partialSum, a) => partialSum + (row[a]?.timeLogged ? parseInt(row[a]?.timeLogged) / 60 : 0),
            0
          )
        )}
      </b>
    ),
  },
  {
    label: <FormattedMessage id="TimeSheet.TimeSheetList.ColumnNotes" />,
    width: "25%",
    cell: (row) =>
      disabled || isFutureDate(row.assignmentDate) ? (
        row?.description
      ) : (
        <NotesInput
          payload={{
            assignmentId: row.assignmentId,
            activityType: ACTIVITY_TYPES.communication,
            activityDate: row.assignmentDate,
            description: row?.description,
          }}
          onUpdate={(e, payload) => {
            updateTimeSlip({
              ...payload,
              ...{ description: e.target.value },
            });
            dispatch({
              type: TIME_SHEET_ACTIONS.update,
              payload: {
                ...payload,
                ...{ description: e.target.value },
              },
            });
          }}
        />
      ),
  },
];

/**
 * Editable TimeSheet Columns
 *
 * @param {Function} dispatch
 */
const EDITABLE_COLUMNS = (dispatch, updateTimeSlip, disabled) =>
  EDITABLE_KEYS.map((activityType, index) => ({
    label: (
      <Tooltip tooltipId={`TimeSheet.TimeSheetList.EditableColumn.Tooltip_${activityType}`}>
        <FormattedMessage id={`TimeSheet.TimeSheetList.EditableColumn_${activityType}`} />
      </Tooltip>
    ),
    width: "10%",
    cell: (row) =>
      disabled || isFutureDate(row.assignmentDate) ? (
        row[activityType]?.timeLogged ? (
          formatHours(parseInt(row[activityType]?.timeLogged) / 60)
        ) : (
          ""
        )
      ) : (
        <TimeSlipInput
          key={index}
          payload={{
            assignmentId: row.assignmentId,
            activityType: ACTIVITY_TYPES[activityType], // Need to use the uppercase object key
            activityDate: row.assignmentDate,
            timeLogged: row[activityType]?.timeLogged,
          }}
          onUpdate={(value, payload) => {
            updateTimeSlip({
              ...payload,
              ...{ timeLogged: value },
            });
            dispatch({
              type: TIME_SHEET_ACTIONS.update,
              payload: {
                ...payload,
                ...{
                  activityType, // Need to use the lowercase object key
                  timeLogged: getSecondsFromClock(value),
                },
              },
            });
          }}
          isInvisible
        />
      ),
  }));

TimeSheetList.propTypes = {
  selectedMonth: PropTypes.string,
  disabled: PropTypes.bool,
  profileId: PropTypes.string,
  selectedTeam: PropTypes.string,
  onTotalUpdated: PropTypes.func,
};

TimeSheetList.defaultProps = {
  selectedTeam: "0",
};

export default TimeSheetList;
