import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Editor, editorDefaultValue } from "components/Form";
import { PostButton } from "components/Buttons";
import { UserPhoto } from "components/User";
import { useComments } from "hooks";
import { COMMENTABLE_TYPES, DIRECTION } from "constants/index";
import { Row } from "components/Containers";

/**
 * NotesInputBox
 *
 * @param {Function} onChange
 * @param {Object}   user
 * @param {String}   commentableId
 * @param {String}   commentableType
 * @param {Number}   resultsPerPage
 * @param {Number}   createdAt
 * @param {Object}   initialValue
 * @param {Object}   replyUser
 */
const NotesInputBox = ({
  onChange,
  user,
  commentableId,
  commentableType,
  resultsPerPage,
  createdAt,
  initialValue,
  replyUser,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);
  const [hasText, setHasText] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [showFormattingOptions, setShowFormattingOptions] = useState(false);
  const handleChange = (name, value) => setValue(value);
  const { handleAddComment, adding } = useComments({ resultsPerPage, createdAt });
  const editor = useRef(null);
  const resetEditor = () => editor.current.reset();
  const addMention = (item) => editor.current.addMention(item);

  useEffect(() => {
    if (replyUser) {
      const {
        user: { firstName, lastName, id },
      } = replyUser;
      // Timeout prevents race condition
      setTimeout(
        () =>
          addMention({
            id,
            name: `${firstName} ${lastName}`,
            __typename: `User`,
          }),
        100
      );
    }
  }, [replyUser]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setHasText(value.some((item) => item.children.some((child) => child.text !== "")));
  }, [value]);

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(value);
    }
  }, [value]);

  const handleSubmit = () => {
    setIsReadOnly(true);
    handleAddComment(commentableId, commentableType, value, () => {
      setValue(initialValue);
      resetEditor();
      setIsReadOnly(false);
    });
  };

  return (
    <Container>
      <PhotoContainer>
        <UserPhoto data={user} width={`3rem`} height={`3rem`} borderRadius={`100%`} />
      </PhotoContainer>
      <EditorContainer>
        <Editor
          ref={editor}
          name="post"
          value={value}
          onChange={handleChange}
          readOnly={isReadOnly || adding}
          onFocus={setShowFormattingOptions}
          showFormattingOptions={hasText || showFormattingOptions}
          {...props}
        />
      </EditorContainer>
      <StyledButton onClick={handleSubmit} disabled={adding || !hasText} />
    </Container>
  );
};

const Container = styled(Row)`
  width: 100%;
`;

const StyledButton = styled(PostButton)`
  margin-top: auto;
  width: 4rem;
  height: calc(4rem + 4px);
`;

const PhotoContainer = styled.div`
  margin-top: 0.7rem;
  margin-bottom: auto;
`;

const EditorContainer = styled.div`
  flex: 1;
`;

NotesInputBox.defaultProps = {
  initialValue: editorDefaultValue,
  commentableType: COMMENTABLE_TYPES.profile,
  resultsPerPage: 10,
  createdAt: DIRECTION.descending,
};

NotesInputBox.propTypes = {
  onChange: PropTypes.func,
  user: PropTypes.object.isRequired,
  commentableId: PropTypes.string,
  commentableType: PropTypes.string,
  initialValue: PropTypes.object,
  resultsPerPage: PropTypes.number,
  replyUser: PropTypes.object,
  createdAt: PropTypes.string,
};

export default NotesInputBox;
