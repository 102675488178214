import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { css } from "@emotion/react";
import { useParams } from "react-router-dom";
import { useTeamIframes } from "hooks";
import { PAGES } from "constants/index";
import PageTitle from "components/PageTitle";
import { Spinner } from "components/Loader";
import { MessagesNoResults } from "components/Messages";
import { PageWrapper, Grid } from "components/Containers";

/**
 * MissionReport
 */
const MissionReport = () => {
  const { id } = useParams();
  const { iFrameUrl } = useTeamIframes({ id, component: PAGES.mission_report });
  const intl = useIntl();
  const [loading, setLoading] = useState(true);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.MissionReport.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle subtitle={<FormattedMessage id="Squads.MissionReport.Title" />} />
          </Grid.col>
          <Grid.col col start={1} end={13}>
            {iFrameUrl ? (
              <>
                {loading && <Spinner size="2rem" />}
                <iframe onLoad={() => setLoading(false)} src={iFrameUrl} css={styles.iframe(loading)} />
              </>
            ) : (
              <MessagesNoResults
                description="Squads.Squad.MissionReport.NoResults.Description"
                title="Squads.Squad.NoResults.Title"
              />
            )}
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

const styles = {
  iframe: (loading) => css`
    width: 100%;
    height: 1500px;
    padding: 0;
    display: ${loading ? "none" : "block"};
  `,
};

export default MissionReport;
