import React from "react";
import { PageWrapper } from "components/Containers";
import NoResults from "components/Messages/NoResults";
import Card from "components/Card";

/**
 * Home
 */
const MobileMessage = () => (
  <PageWrapper>
    <Card>
      <NoResults
        title="This page is not available on mobile devices."
        description="To enjoy the full experience, please visit this page on a desktop or laptop computer."
      />
    </Card>
  </PageWrapper>
);

export default MobileMessage;
