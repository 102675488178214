const TERMSHEET_EVENTS = {
  teamStart: "platform:backend:team_event:team_start",
  teamPause: "platform:backend:team_event:team_pause",
  teamResume: "platform:backend:team_event:team_resume",
  teamEnd: "platform:backend:team_event:team_end",
  orgChange: "platform:backend:team_event:team_organization_change",
  teamBillingAccountChange: "platform:backend:team_event:team_billing_account_change",
  teamBillingIntervalChange: "platform:backend:team_event:team_billing_interval_change",
  memberOnboarding: "platform:backend:team_event:team_member_onboarding",
  memberOffboarding: "platform:backend:team_event:team_member_offboarding",
  memberArchetypeChange: "platform:backend:team_event:team_member_archetype_change",
  memberContractChange: "platform:backend:team_event:team_member_contract_type_change",
  memberBuyingQuantityChange: "platform:backend:team_event:team_member_buying_quantity_change",
  memberBuyingRateChange: "platform:backend:team_event:team_member_buying_rate_change",
  memberCommissionPercentChange: "platform:backend:team_event:team_member_commission_percent_change",
  memberSellingBasePriceChange: "platform:backend:team_event:team_member_selling_base_price_change",
  memberSellingDiscountChange: "platform:backend:team_event:team_member_selling_discount_change",
};

export default TERMSHEET_EVENTS;
