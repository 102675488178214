import React, { useState, memo } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import PageTitle from "components/PageTitle";
import { Grid, PageWrapper } from "components/Containers";
import { DatePicker } from "components/Form";
import { PulseTable, SquadDynamicsTable } from "components/Squads/Pulse";
import { DATE_FORMAT_TYPE } from "constants/index";

/**
 * Pulse Page
 */
const Pulse = () => {
  const intl = useIntl();
  const { id: teamId } = useParams();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const startDate = `${currentYear - 1}-${String(currentMonth).padStart(2, "0")}-01`;
  const endOfLastMonth = new Date(currentYear, currentMonth - 1, 0);
  const endDate = `${endOfLastMonth.getFullYear()}-${String(endOfLastMonth.getMonth() + 1).padStart(2, "0")}-${String(
    endOfLastMonth.getDate()
  ).padStart(2, "0")}`;

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.Pulse.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <PulseSection startDate={startDate} endDate={endDate} teamId={teamId} />
        <SquadDynamicsSection currentYear={currentYear} currentMonth={currentMonth} teamId={teamId} />
      </PageWrapper>
    </>
  );
};

const PulseSection = memo(({ startDate, endDate, teamId }) => {
  const [pulseDateRange, setPulseDateRange] = useState([startDate, endDate]);

  return (
    <Grid>
      <Grid.col start={1} end={13}>
        <PageTitle
          subtitle={<FormattedMessage id="Squads.Pulse.Title" />}
          actionBar={
            <DatePicker
              value={pulseDateRange}
              onChange={(_, range) => setPulseDateRange(range)}
              maxDate={new Date(endDate)}
              enableMonthRangePicker
              enableDateCycle
            />
          }
        />
      </Grid.col>
      <Grid.col start={1} end={13}>
        <PulseTable dateRange={pulseDateRange} teamId={teamId} />
      </Grid.col>
    </Grid>
  );
});

PulseSection.displayName = "PulseSection";

PulseSection.propTypes = {
  startDate: PropTypes.date,
  endDate: PropTypes.date,
  teamId: PropTypes.string,
};

const SquadDynamicsSection = memo(({ currentYear, currentMonth, teamId }) => {
  const [squadDynamicsDate, setSquadDynamicsDate] = useState(
    `${currentYear}-${String(currentMonth - 1).padStart(2, "0")}-01`
  );

  return (
    <Grid>
      <Grid.col start={1} end={13}>
        <PageTitle
          subtitle={<FormattedMessage id="Squads.Pulse.SquadDynamics" />}
          actionBar={
            <DatePicker
              value={squadDynamicsDate}
              onChange={(_, date) => setSquadDynamicsDate(date)}
              maxDate={new Date()}
              format={DATE_FORMAT_TYPE.MMyyyy}
              enableMonthPicker
              enableDateCycle
              showMonthYearPicker
            />
          }
        />
      </Grid.col>
      <Grid.col start={1} end={13}>
        <SquadDynamicsTable date={squadDynamicsDate} teamId={teamId} />
      </Grid.col>
    </Grid>
  );
});

SquadDynamicsSection.displayName = "SquadDynamicsSection";

SquadDynamicsSection.propTypes = {
  currentYear: PropTypes.string,
  currentMonth: PropTypes.string,
  teamId: PropTypes.string,
};

export default Pulse;
