import { gql } from "@apollo/client";

const commentFields = `
  id
  message
  messageJson
  commentableId
  commentableType
  topics {
    id
    name
  }
  createdAt
  updatedAt
  isDeleted
`;

const COMMENT_FIELDS = gql`
  fragment CommentFields on Comment {
    ${commentFields}
  }
`;

export { COMMENT_FIELDS };
