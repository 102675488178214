import { gql } from "@apollo/client";

const GET_TIME_CARD_REPORT = gql`
  query timeCardReportForMonth($filters: TimeCardReportFilter!) {
    timeCardReportForMonth(filters: $filters) {
      filterMetadata {
        organizations {
          id
          name
        }
        teams {
          id
          name
        }
        partners {
          id
          name
        }
        isOverAllocatedTime
        timeCardStatus
      } 
      report {
        profile {
          id
          uuid
          firstName
          lastName
          email
          avatarUrl
          memberSince
          slug
          deelContractId
          deelContractUrl
        }
        id
        timeCard {
          id
          status
          deelLastSyncAt
        }
        daysOff
        lastActivityDate
        timeAllocatedMinutes
        timeAllocatedFormatted
        timeDifferenceFormatted
        timeDifferenceMinutes
        timeLoggedMinutes
        timeLoggedFormatted
        breakdown {
          team {
            id
            name
            slug
            missionId
          }
          organization {
            name
            slug
          }
          deelStatus
          assignmentId
          timeLoggedMinutes
          timeLoggedFormatted
          timeAllocatedMinutes
          timeAllocatedFormatted
          timeDifferenceMinutes
          timeDifferenceFormatted
          lastActivityDate
          rate
          currency
        }
      }
    }
  }
`;

export { GET_TIME_CARD_REPORT };
