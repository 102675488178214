import { GET_TEAM_IFRAME_URL } from "graphql/queries";
import { useQueryData } from "hooks";

export default function useTeamIframes({ id, component }) {
  const { data, loading } = useQueryData({
    queryName: GET_TEAM_IFRAME_URL,
    variables: {
      teamId: id,
      component: component,
    },
  });

  return {
    iFrameUrl: data?.teamIframeUrl?.iframeUrl,
    loading: loading,
  };
}
