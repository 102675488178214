import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { REJECT_PROFILE } from "graphql/mutations";
import { PrimaryButton } from "components/Buttons";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { TYPE } from "components/Form";
import { useProfile } from "hooks";
import { MEMBER_STATUS, REJECTED_REASON } from "constants/index";
import { fontSize, colors } from "style";

/**
 * CandidateRejectButton
 *
 * @params {String}     profileId
 * @params {Function}   onAdd
 */
const CandidateRejectButton = ({ profileId, onAdd, ...props }) => {
  const [status, setStatus] = useState();
  const [rejectedReason, setRejectedReason] = useState();
  const [rejectedDescription, setRejectedDescription] = useState();
  const handleAdd = () => onAdd("id", profileId);
  const { data } = useProfile({ profileId });

  useEffect(() => {
    setStatus(data?.profile?.status);
    setRejectedReason(data?.profile?.rejectedReason);
    setRejectedDescription(data?.profile?.rejectedDescription);
  }, [data?.profile]);

  /**
   * Automatically rejected by system
   */
  if (rejectedReason && rejectedReason === REJECTED_REASON.failed_code_challenge) {
    return (
      <div css={styles.rejected_message}>
        <FormattedMessage
          id="Candidate.CandidateRejectButton.MessageFailedChallenge"
          values={{
            date: moment(data?.profile?.rejectedAt).format("MMMM Do, YYYY, h:mm a"),
          }}
        />
      </div>
    );

    /**
     * Manually rejected by admin
     */
  } else if (!status || status === MEMBER_STATUS.rejected) {
    return (
      <div css={styles.rejected_message}>
        <FormattedMessage
          id="Candidate.CandidateRejectButton.Message"
          values={{
            date: moment(data?.profile?.rejectedAt).format("MMMM Do, YYYY, h:mm a"),
            name: `${data?.profile?.rejectedBy?.firstName} ${data?.profile?.rejectedBy?.lastName}`,
            message: (
              <div>
                <div css={styles.rejected_label}>
                  <FormattedMessage id="Candidate.CandidateRejectButton.LabelReason" />
                </div>
                <div css={styles.rejected_body}>
                  <FormattedMessage id={`Candidate.CandidateRejectButton.Reason_${rejectedReason}`} />
                </div>
                {rejectedDescription && (
                  <>
                    <div css={styles.rejected_label}>
                      <FormattedMessage id="Candidate.CandidateRejectButton.LabelMessage" />
                    </div>
                    <div css={styles.rejected_body}>{rejectedDescription}</div>
                  </>
                )}
              </div>
            ),
          }}
        />
      </div>
    );
  } else {
    return (
      <PrimaryButton variant="red" css={styles.button} onClick={handleAdd} {...props}>
        <FormattedMessage id="Candidate.CandidateRejectButton.CTA" />
      </PrimaryButton>
    );
  }
};

const styles = {
  button: css`
    width: 100%;
  `,
  rejected_message: css`
    text-align: left;
    font-size: ${fontSize.small};
    color: ${colors.red};
    font-weight: 600;
    line-height: normal;
    width: 100%;
    background: ${colors.grayAnatomyLight5};
    padding: 2rem;
  `,
  rejected_label: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyBase};
    font-weight: 600;
    margin-top: 1rem;
    border-bottom: 1px ${colors.grayAnatomyLight3} solid;
    padding-bottom: 0.5rem;
  `,
  rejected_body: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyBase};
    margin-top: 0.5rem;
    font-weight: 400;
  `,
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Reason",
        type: TYPE.select,
        properties: {
          name: "rejectedReason",
          required: true,
          options: [
            {
              label: "Not enough experience",
              value: "NOT_ENOUGH_EXPERIENCE",
            },
            {
              label: "Lacking communication skills",
              value: "LACKING_COMMUNICATION_SKILLS",
            },
            {
              label: "Incomplete profile",
              value: "INCOMPLETE_PROFILE",
            },
            {
              label: "Poor connection",
              value: "POOR_CONNECTION",
            },
            {
              label: "Other reason(s)",
              value: "OTHERS",
            },
          ],
        },
      },
      {
        label: "Message",
        type: TYPE.textarea,
        properties: {
          name: "rejectedDescription",
          placeholder: "Optionally, add a brief message",
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Reject Member" />,
  mutationData: {
    mutationName: {
      add: REJECT_PROFILE,
    },
  },
};

CandidateRejectButton.propTypes = {
  profileId: PropTypes.string,
  onAdd: PropTypes.func,
};

export default withModalForm(CandidateRejectButton, FORM_SETTINGS);
