import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { Row } from "components/Containers";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { colors, fontSize } from "style";
import { formatDate } from "utils";

/**
 * OnboardedOffboardedDates
 *
 * @param {String} onboardedDate
 * @param {String} offboardedDate
 * @param {String} selectedDate
 */
const OnboardedOffboardedDates = ({ onboardedDate, offboardedDate, selectedDate }) => {
  const [showOnboardingDate, setShowOnboardingDate] = useState(false);
  const [showOffboardingDate, setShowOffboardingDate] = useState(false);

  useEffect(() => {
    function shouldShowDate(date, comparisonDate) {
      return (
        date &&
        date.split("-")[0] === comparisonDate.split("-")[0] &&
        date.split("-")[1] === comparisonDate.split("-")[1]
      );
    }

    setShowOnboardingDate(shouldShowDate(onboardedDate, selectedDate));
    setShowOffboardingDate(shouldShowDate(offboardedDate, selectedDate));
  }, [onboardedDate, offboardedDate, selectedDate]);

  return (
    <Row css={styles.container}>
      {showOnboardingDate && (
        <Row css={styles.item}>
          <Row css={styles.icon_container(colors.green)}>
            <Icon type={ICON_TYPE.timerStart} size={ICON_SIZE.small} color="#fff" />
          </Row>
          <span>{formatDate(onboardedDate)}</span>
        </Row>
      )}
      {showOffboardingDate && (
        <Row css={styles.item}>
          <Row css={styles.icon_container(colors.red)}>
            <Icon type={ICON_TYPE.timerStop} size={ICON_SIZE.small} color="#fff" />
          </Row>
          <span>{formatDate(offboardedDate)}</span>
        </Row>
      )}
    </Row>
  );
};

const styles = {
  container: css`
    font-size: ${fontSize.xsmall};
    font-weight: 500;
  `,
  icon_container: (color) => css`
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
    background: ${color};
    border-radius: 100%;
  `,
  item: css`
    gap: 0.5rem;
  `,
};

OnboardedOffboardedDates.propTypes = {
  onboardedDate: PropTypes.string,
  offboardedDate: PropTypes.string,
  selectedDate: PropTypes.string.isRequired,
};

export default OnboardedOffboardedDates;
