import React from "react";
import PropTypes from "prop-types";
import { TIMELINE_EVENTS } from "constants/index";
//import { CodeChallenge } from "components/Timeline/TimelineEvents";
import { CandidateArchived } from "components/Candidate";
import Card from "components/Card";

/**
 * TimelineEventBody
 *
 * @param {Object} data
 */
const TimelineEventBody = ({ data }) => {
  const { key, payload, user } = data;

  switch (key) {
    /*
    FIX ME - Likely need to parse `platform:backend:challenge_response:updated`
    case "platform:backend:challenge_response:completed":
      return (
        <CodeChallenge data={payload?.resourceContext} user={user} />
      );
    */
    // Profile-related events
    case TIMELINE_EVENTS.profileUpdated:
      // Profile archived
      if (payload?.archivedSince?.slice(-1)) {
        return (
          <Card>
            <CandidateArchived
              data={{
                archivedReason: String(payload?.archiveReason?.slice(-1)).toUpperCase(),
                archivedDescription: payload?.archivedDescription?.slice(-1),
                archivedAt: payload?.archivedSince?.slice(-1),
                archivedBy: user,
              }}
              hideTitle={true}
            />
          </Card>
        );
      }
      return null;

    default:
      return null;
  }
};

TimelineEventBody.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TimelineEventBody;
