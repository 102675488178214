import ROLES from "./roles";

const DISCIPLINES = [
  {
    name: "Front-End Developer",
    uuid: "33bc6a5f-d5bd-44cb-b7dd-b512a0a722f5",
    roles: [ROLES.developer, ROLES.tech_lead],
  },
  {
    name: "Back-End Developer",
    uuid: "f9c534a3-39a2-4d05-8067-28d6ef4d791a",
    roles: [ROLES.developer, ROLES.tech_lead],
  },
  {
    name: "Mobile Developer",
    uuid: "15caee8c-5953-48ef-82e1-a03de6b942e9",
    roles: [ROLES.developer, ROLES.tech_lead],
  },
  {
    name: "AI Developer",
    uuid: "07485cf0-70cd-41c7-8612-372350d8e1b7",
    roles: [ROLES.developer, ROLES.tech_lead],
  },
  {
    name: "DevOps Engineer",
    uuid: "c1f0c50f-2f29-4449-ab0b-50ff8e4d1a2d",
    roles: [ROLES.developer, ROLES.tech_lead],
  },
  {
    name: "Data Engineer",
    uuid: "28831edd-f4d6-4f83-b82c-2886dcaa06e1",
    roles: [ROLES.developer, ROLES.tech_lead],
  },
  {
    name: "Project Manager",
    uuid: "caa38946-4cad-4e78-bfcf-9fdd71ee3c78",
    roles: [ROLES.squad_lead, ROLES.business_analyst],
  },
  {
    name: "Product Manager",
    uuid: "ecf44ad7-230a-423c-ac8f-90648291a6dd",
    roles: [ROLES.squad_lead, ROLES.business_analyst],
  },
  {
    name: "Business Analyst",
    uuid: "e8ecbc40-41d3-4486-ac10-70c951f6b60d",
  },
  {
    name: "Quality Assurance Engineer",
    uuid: "dd68c25a-414c-4ee1-a65a-0d8478026aa2",
    roles: [ROLES.qa],
  },
  {
    name: "UI/UX Designer",
    uuid: "334e854c-ead3-41d7-be1d-d221aa7bf634",
    roles: [ROLES.designer],
  },
  {
    name: "Data Scientist",
    uuid: "51c2e3df-d1cc-4140-9839-a8f7c4433f7a",
    roles: [ROLES.developer, ROLES.tech_lead],
  },
];

export default DISCIPLINES;
