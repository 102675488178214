const INSIGHT_TYPES = {
  all: "ALL",
  performance: "PERFORMANCE",
  performanceScore: "PERFORMANCE_SCORE",
  pulse: "PULSE",
};

const INSIGHT_TYPES_OPTIONS = [
  {
    name: "Performance",
    value: INSIGHT_TYPES.performance,
  },
  {
    name: "Performance Score",
    value: INSIGHT_TYPES.performanceScore,
  },
  {
    name: "Pulse",
    value: INSIGHT_TYPES.pulse,
  },
];

export { INSIGHT_TYPES_OPTIONS };

export default INSIGHT_TYPES;
