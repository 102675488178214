import React, { useEffect } from "react";
import { useTracking } from "hooks";
import { PAGES, TRACKING_PAGE_VIEWS } from "constants/index";

/**
 * ClientCommunity
 */
const ClientCommunity = () => {
  const { trackPage } = useTracking();

  useEffect(() => {
    trackPage(TRACKING_PAGE_VIEWS[PAGES.clientCommunity].page_name, TRACKING_PAGE_VIEWS[PAGES.clientCommunity]);
  }, []);

  return (
    <>
      <iframe src={`https://missioncommunity.super.site/`} width="100%" style={{ height: "100vh" }} />
    </>
  );
};

export default ClientCommunity;
