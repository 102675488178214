import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import Modal from "components/Modal";

/**
 * ModalConfirmationProvider
 *
 * @params {Node}   children
 */
const ModalConfirmationProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const [modalProps, setModalProps] = useState();
  const resolver = useRef();

  const handleShow = (title, body, modalProps) => {
    setTitle(title);
    setBody(body);
    setShow(true);
    setModalProps(modalProps);

    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  };

  const modalContext = {
    show: handleShow,
  };

  const handleSubmit = () => {
    resolver.current && resolver.current(true);
    setShow(false);
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    setShow(false);
  };

  return (
    <ModalConfirmationContext.Provider value={modalContext}>
      {children}
      <Modal show={show} title={title} onClose={handleCancel} onSubmit={handleSubmit} canSubmit={true} {...modalProps}>
        <div css={styles.description}>{body}</div>
      </Modal>
    </ModalConfirmationContext.Provider>
  );
};

const styles = {
  description: css`
    font-size: ${fontSize.small};
    line-height: 2.4rem;
    color: ${colors.grayAnatomyBase};
    margin-bottom: 4rem;
  `,
};

ModalConfirmationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const ModalConfirmationContext = React.createContext({});

export { ModalConfirmationContext };

export default ModalConfirmationProvider;
