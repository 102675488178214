import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import Form, { SIZE, TYPE } from "components/Form";
import { USER_REGISTER } from "graphql/mutations";
import { GET_PROFILE } from "graphql/queries";
import { MUTATION_TYPE } from "hooks";
import { validatePassword } from "utils";
import { PrimaryLink } from "components/Links";
import { LINKED_IN_PREFIX } from "constants/index";
import LINKS from "constants/links";
import { margins } from "style";

/**
 * FormEmail
 *
 * @params {Function}   onChange
 * @params {Function}   onCancel
 * @params {Function}   onCompleted
 * @params {Object}     values
 */
const FormEmail = ({ onChange, onCancel, onCompleted, values }) => {
  const [initialValues] = useState(values);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const passwordResult = validatePassword(values.password);

  useEffect(() => {
    const pw = values?.password;
    const pwConfirm = values?.passwordConfirmation;

    setPasswordsMatch(!pwConfirm || pwConfirm?.length === 0 || pwConfirm === pw);
  }, [values]);

  return (
    <Form
      data={FORM_DATA(passwordsMatch, !values?.password?.length || passwordResult.isValid)}
      initialValues={initialValues}
      onChange={onChange}
      onCompleted={onCompleted}
      marginSize={margins.none}
      mutationType={MUTATION_TYPE.add}
      mutationData={{
        mutationName: {
          add: USER_REGISTER,
        },
        refetchQueries: [GET_PROFILE],
        refetchAfterMutate: false,
        unWrapParams: true,
      }}
      cancelButton={{
        show: true,
        labelId: "Global.GoBack",
        onClick: onCancel,
      }}
      saveButton={{
        show: true,
        labelId: "Global.Complete",
        disabled:
          !values?.firstName ||
          !values?.lastName ||
          !values?.email ||
          !values?.password ||
          !values?.passwordConfirmation ||
          !passwordsMatch ||
          !passwordResult.isValid,
      }}
    />
  );
};

const FORM_DATA = (passwordsMatch, isValidPassword) => [
  {
    style: css`
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
    `,
    items: [
      {
        label: <FormattedMessage id="Signup.FormEmail.FormLabelTitle" />,
        type: TYPE.h4,
        size: SIZE.xlarge,
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.FormEmail.FormLabelFirstName" />,
        properties: {
          name: "firstName",
          placeholder: "First name",
          autoFocus: true,
          required: true,
          autoComplete: "given-name",
        },
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.FormEmail.FormLabelLastName" />,
        properties: {
          name: "lastName",
          placeholder: "Last name",
          required: true,
          autoComplete: "family-name",
        },
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.FormEmail.FormLabelLinkedIn" />,
        properties: {
          type: "url",
          name: "linkedinUrl",
          placeholder: LINKED_IN_PREFIX,
        },
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.FormEmail.FormLabelEmail" />,
        properties: {
          name: "email",
          placeholder: "Enter email...",
          required: true,
          autoComplete: "email",
        },
      },
      {
        type: TYPE.password,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.FormEmail.FormLabelPassword" />,
        hasError: !isValidPassword,
        properties: {
          name: "password",
          type: "password",
          autoComplete: "new-password",
          required: true,
        },
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.FormEmail.FormLabelPasswordConfirm" />,
        hasError: !passwordsMatch,
        errorMessage: "Passwords do not match",
        properties: {
          name: "passwordConfirmation",
          type: "password",
          autoComplete: "new-password",
          required: true,
        },
      },
      {
        type: TYPE.checkbox,
        size: SIZE.xlarge,
        properties: {
          name: "privacyPolicy",
          type: "checkbox",
          required: true,
          label: (
            <FormattedMessage
              id="Signup.FormEmail.PrivacyMsg"
              values={{
                link: (
                  <PrimaryLink href="/legal/privacy-policy" target="_blank">
                    <FormattedMessage id="Signup.FormEmail.PrivacyLink" />
                  </PrimaryLink>
                ),
              }}
            />
          ),
        },
      },
      {
        type: TYPE.checkbox,
        size: SIZE.xlarge,
        properties: {
          name: "termsService",
          type: "checkbox",
          required: true,
          label: (
            <FormattedMessage
              id="Signup.FormEmail.TermsAndConditionsMsg"
              values={{
                link: (
                  <PrimaryLink href={LINKS.terms_and_conditions} target="_blank">
                    <FormattedMessage id="Signup.FormEmail.TermsAndConditionsLink" />
                  </PrimaryLink>
                ),
              }}
            />
          ),
        },
      },
    ],
  },
];

FormEmail.propTypes = {
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onCompleted: PropTypes.func,
  values: PropTypes.object,
};

export default FormEmail;
