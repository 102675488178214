const INSIGHT_METRICS_OPTIONS = [
  {
    name: "Active Days",
    value: "ACTIVE_DAYS",
  },
  {
    name: "Activities per Active Day (Average)",
    value: "ACTIVITIES_PER_ACTIVE_DAY_AVERAGE",
  },
  {
    name: "Activity Score",
    value: "ACTIVITY_SCORE",
  },
  {
    name: "Code Reviews",
    value: "CODE_REVIEWS",
  },
  {
    name: "Code Reviews per Active Day (Average)",
    value: "CODE_REVIEWS_PER_ACTIVE_DAY_AVERAGE",
  },
  {
    name: "Comment Size (Average)",
    value: "COMMENT_SIZE_AVERAGE",
  },
  {
    name: "Comments",
    value: "COMMENTS",
  },
  {
    name: "Comments per Active Day (Average)",
    value: "COMMENTS_PER_ACTIVE_DAY_AVERAGE",
  },
  {
    name: "Comments per Pull Request (Average)",
    value: "COMMENTS_PER_PULL_REQUEST_AVERAGE",
  },
  {
    name: "Commits",
    value: "COMMITS",
  },
  {
    name: "Commits per Active Day (Average)",
    value: "COMMITS_PER_ACTIVE_DAY_AVERAGE",
  },
  {
    name: "Efficiency Score",
    value: "EFFICIENCY_SCORE",
  },
  {
    name: "Hours to Pull Request Merged (Average - as Author)",
    value: "HOURS_TO_PULL_REQUEST_MERGED_AVERAGE_AS_AUTHOR",
  },
  {
    name: "Hours to Pull Request Merged (Average - as Merger)",
    value: "HOURS_TO_PULL_REQUEST_MERGED_AVERAGE_AS_MERGER",
  },
  {
    name: "Hours to Pull Request Review (Average)",
    value: "HOURS_TO_PULL_REQUEST_REVIEW_AVERAGE",
  },
  {
    name: "Lines of Code Added",
    value: "LINES_OF_CODE_ADDED",
  },
  {
    name: "Lines of Code Modified",
    value: "LINES_OF_CODE_MODIFIED",
  },
  {
    name: "Lines of Code Modified per Commit (Average)",
    value: "LINES_OF_CODE_MODIFIED_PER_COMMIT_AVERAGE",
  },
  {
    name: "Lines of Code Removed",
    value: "LINES_OF_CODE_REMOVED",
  },
  {
    name: "Negative Comment Added",
    value: "NEGATIVE_COMMENT_ADDED",
  },
  {
    name: "Negative Mission Feedback Given",
    value: "NEGATIVE_MISSION_FEEDBACK_GIVEN",
  },
  {
    name: "Negative Team Member Feedback Given",
    value: "NEGATIVE_TEAM_MEMBER_FEEDBACK_GIVEN",
  },
  {
    name: "Negative Team Member Feedback Received",
    value: "NEGATIVE_TEAM_MEMBER_FEEDBACK_RECEIVED",
  },
  {
    name: "Overall Score",
    value: "OVERALL_SCORE",
  },
  {
    name: "Pull Request Lines of Code Modified",
    value: "PULL_REQUEST_LINES_OF_CODE_MODIFIED",
  },
  {
    name: "Pull Request Reviews Before Merge (Average)",
    value: "PULL_REQUEST_REVIEWS_BEFORE_MERGE_AVERAGE",
  },
  {
    name: "Pull Request Size (Average)",
    value: "PULL_REQUEST_SIZE_AVERAGE",
  },
  {
    name: "Pull Requests",
    value: "PULL_REQUESTS",
  },
  {
    name: "Pull Requests Merged (as Merger)",
    value: "PULL_REQUESTS_MERGED_AS_MERGER",
  },
  {
    name: "Pull Requests Merged (as author)",
    value: "PULL_REQUESTS_MERGED_AS_AUTHOR",
  },
  {
    name: "Pull Requests per Active Day (Average)",
    value: "PULL_REQUESTS_PER_ACTIVE_DAY_AVERAGE",
  },
  {
    name: "Volume Score",
    value: "VOLUME_SCORE",
  },
];

export default INSIGHT_METRICS_OPTIONS;
