import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Toggle } from "components/Form";
import { Row } from "components/Containers";
import { TextLoader } from "components/Loader";
import { fontSize, colors } from "style";
import { useMissions } from "hooks";

/**
 * ProposalDraftButton
 *
 * @params {Object}   data
 * @params {Boolean}  loading
 */
const ProposalDraftButton = ({ data, loading }) => {
  const { handleAssessmentDraftedApproved, updating } = useMissions({});
  const [isDraft, setIsDraft] = useState(data?.assessmentDrafted);

  useEffect(() => {
    setIsDraft(data?.assessmentDrafted);
  }, [data?.assessmentDrafted]);

  useEffect(() => {
    if (data?.assessmentDrafted !== isDraft) {
      handleAssessmentDraftedApproved({
        id: data?.uuid,
        assessmentDrafted: isDraft,
      });
    }
  }, [isDraft]);

  const handleChange = () => setIsDraft(!isDraft);

  if (loading) {
    return (
      <Row>
        <TextLoader />
      </Row>
    );
  }

  return (
    <Row>
      <span css={styles.label}>{!loading && <FormattedMessage id={"Missions.AssessmentDraftButton.Label"} />}</span>
      <Toggle value={isDraft} onChange={handleChange} disabled={loading || updating} />
    </Row>
  );
};

const styles = {
  label: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyLight6};
    font-weight: 500;
  `,
};

ProposalDraftButton.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default ProposalDraftButton;
