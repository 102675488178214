import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import { Grid, PageWrapper } from "components/Containers";
import { Banner, BannerShowcaseSignup } from "components/Banner";
import { useAuth, useTracking } from "hooks";
import { css } from "@emotion/react";
import { PAGES, TRACKING_PAGE_VIEWS } from "constants/index";
import { ApplicantListLayout } from "components/Missions/Applicants";
import PageTitle from "components/PageTitle";

/**
 * Applicants Page
 */
const Applicants = () => {
  const { isShowcase } = useAuth();
  const { trackPage } = useTracking();
  const intl = useIntl();

  useEffect(() => {
    trackPage(TRACKING_PAGE_VIEWS[PAGES.showcaseApplicants].page_name, TRACKING_PAGE_VIEWS[PAGES.showcaseApplicants]);
  }, []);

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Applicants.Title" })}</title>
      </Helmet>
      <BannerShowcaseSignup
        css={css`
          margin: 4rem 0 1rem 0;
        `}
      />
      {isShowcase && (
        <Banner keyName="showClientApplicantsBanner">
          <FormattedMessage
            id="Applicants.Banner.Title1"
            values={{
              intro: (
                <b>
                  <FormattedMessage id="Applicants.Banner.Title2" />
                </b>
              ),
            }}
          />
        </Banner>
      )}
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle title={<FormattedMessage id={`Missions.Applicants.H1`} />} />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <ApplicantListLayout />
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default Applicants;
