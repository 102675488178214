import { TRACKING_PAGE_VIEWS } from "constants/index";

/**
 * Segment IO tracking integration
 */
export default function useTracking() {
  const trackPage = (pageView, data) => {
    // eslint-disable-next-line no-undef
    if (typeof window.analyticsClient !== "undefined") {
      // eslint-disable-next-line no-undef
      delete data["page_name"];
      return window.analyticsClient.page(`${pageView}`, data);
    }
  };
  const trackEvent = (name, data) => {
    // eslint-disable-next-line no-undef
    if (typeof window.analyticsClient !== "undefined") {
      // eslint-disable-next-line no-undef
      return window.analyticsClient.track(`${name}`, data);
    }
  };
  const identifyEvent = (data) => {
    return window.analyticsClient.identify(data);
  }
  const trackablePage = (page) => Object.keys(TRACKING_PAGE_VIEWS).indexOf(page) !== -1;

  return {
    trackEvent,
    trackPage,
    trackablePage,
    identifyEvent,
  };
}

export const TRACKING_EVENTS = {
  videoEnded: "video_ended",
  videoOnPlay: "video_on_play",
};
