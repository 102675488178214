import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { convertToPercent, formatDate } from "utils";
import { colors } from "style";
import { GET_MISSION_ROLE_APPLICANTS } from "graphql/queries";
import { DataTableProvider } from "components/DataTable";
import Tooltip from "components/Tooltip";
import Score from "components/Score";
import { ScoreDisplay } from "components/Missions/Applicants";
import { RoleLink } from "components/Missions/Roles";
import { OrganizationLink } from "components/Organization";
import { MissionLink } from "components/Missions";
import { ActionButton } from "components/Buttons";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { PreferredRateTag } from "components/Profile";
import { TAG_SIZE } from "components/Tags";

/**
 * ApplicationList
 *
 * @param {String}    profileId
 * @param {String}    excludingRoleId
 * @param {Function}  setApplication
 */
const ApplicationList = ({ profileId, excludingRoleId, setApplication }) => {
  if (!profileId || !excludingRoleId) return null;

  return (
    <>
      <DataTableProvider
        keyName="missionRoleApplicants"
        queryName={GET_MISSION_ROLE_APPLICANTS}
        variables={{
          filters: {
            profileId,
            excludingMissionRoleId: excludingRoleId,
          },
        }}
        columnData={COLUMNS(setApplication)}
        disablePagination
      />
    </>
  );
};

const COLUMNS = (onRowClick) => [
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnAppliedAt" />,
    cell: (row) => <div>{formatDate(row.appliedAt)}</div>,
  },
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnRoleMissionOrg" />,
    cell: (row) => <RoleLink mission={row.missionRole.mission} missionRole={row.missionRole} />,
  },
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnMission" />,
    cell: (row) => <MissionLink data={row.missionRole.mission} />,
  },
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnOrganization" />,
    cell: (row) => <OrganizationLink data={row.organization} />,
  },
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnApplicationRate" />,
    cell: (row) => <PreferredRateTag rate={row.rate} size={TAG_SIZE.small} />,
    width: "15%",
  },
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.MatchScore" />,
    cell: (row) => (
      <Tooltip tooltipId="Global.Text" values={{ text: <ScoreDisplay subScores={row.subScores} /> }}>
        <Score score={Number(row.matchScore) * 100}>{convertToPercent(row.matchScore)}</Score>
      </Tooltip>
    ),
  },
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.RateScore" />,
    cell: (row) => <Score score={Number(row.rateScore) * 100}>{convertToPercent(row.rateScore)}</Score>,
  },
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.State" />,
    cell: (row) => <div>{row.state}</div>,
  },
  {
    cell: (row) => (
      <ActionButton onClick={() => onRowClick(row)}>
        <Icon type={ICON_TYPE.eye} size={ICON_SIZE.large} color={colors.purpleRainBase} />
      </ActionButton>
    ),
  },
];

ApplicationList.propTypes = {
  profileId: PropTypes.string.isRequired,
  excludingRoleId: PropTypes.string.isRequired,
  setApplication: PropTypes.func,
};

export default ApplicationList;
