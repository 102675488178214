import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { AdvancedButton } from "components/Buttons";
import { ICON_TYPE } from "components/Icon";
import { useAuth, useComments } from "hooks";
import { COMMENTABLE_TYPES } from "constants/index";

/**
 * NoteAdvancedOptions
 *
 * @param {Object}    data
 * @param {Function}  onEdit
 */
const NoteAdvancedOptions = ({ data, onEdit, isComment }) => {
  const { id, isAdmin } = useAuth();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { handleDeleteComment } = useComments({});

  useEffect(() => {
    setIsDisabled(!data);
    setIsVisible(id === data?.user?.id || isAdmin);
  }, [data, id]);

  const editOption = {
    label: <FormattedMessage id={`${isComment ? "Comments" : "Notes"}.NoteAdvancedOptions.Edit`} />,
    icon: ICON_TYPE.edit,
    onClick: () => onEdit(true),
  };

  const removeOption = {
    label: <FormattedMessage id={`${isComment ? "Comments" : "Notes"}.NoteAdvancedOptions.Remove`} />,
    icon: ICON_TYPE.trash,
    onClick: () => handleDeleteComment(data?.id, COMMENTABLE_TYPES[isComment ? "comment" : "profile"]),
  };

  if (!isVisible) {
    return null;
  }

  return <AdvancedButton options={[editOption, removeOption]} disabled={isDisabled} isTransparent />;
};

NoteAdvancedOptions.propTypes = {
  data: PropTypes.object,
  onEdit: PropTypes.func,
  isComment: PropTypes.bool,
};

export default NoteAdvancedOptions;
