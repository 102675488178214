const PARTNER_CHANNELS = [
  {
    label: "Inbound",
    value: "inbound",
  },
  {
    label: "Partner",
    value: "partner",
  },
  {
    label: "External Recruiter",
    value: "external_recruiter",
  },
  {
    label: "Referral",
    value: "referral",
  },
  {
    label: "Internal Recruiter",
    value: "internal_recruiter",
  },
  {
    label: "Regional Manager",
    value: "regional_manager",
  },
  {
    label: "Social Media",
    value: "social_media",
  },
  {
    label: "Paid Marketing",
    value: "paid_marketing",
  },
  {
    label: "Marketing Partner",
    value: "marketing_partner",
  },
  {
    label: "Content Marketing",
    value: "content_marketing",
  },
];

export default PARTNER_CHANNELS;
