/* eslint-disable react/display-name */
import { colors } from "style";
import styled from "@emotion/styled";

/**
 * Tag
 */
const Code = styled.pre`
  background: ${colors.grayAnatomyLight5};
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  border: 1px ${colors.grayAnatomyLight3} solid;
  margin: 0;
`;

export default Code;
