import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { colors } from "style";
import MissionLogo from "assets/images/white-mission-logo.png";
import Image from "components/Image";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * ChatIcon
 *
 * @param {Function}  onClick
 * @param {Boolean}   isOpen
 */
const ChatIcon = ({ onClick, isOpen, ...props }) => (
  <Container onClick={onClick} isOpen={isOpen} {...props}>
    <Logo url={MissionLogo} alt="Mission Logo" isOpen={isOpen} />
    <StyledIcon type={ICON_TYPE.chevronDown} size={ICON_SIZE.xlarge} color={colors.white} isOpen={isOpen} />
  </Container>
);

const Container = styled.div`
  width: 6rem;
  height: 6rem;
  background: ${colors.black};
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    transform: scale(1.1);
  }
  ${({ isOpen }) =>
    isOpen &&
    `
    transform: scale(1.1) !important;
  `}
`;

const Logo = styled(Image)`
  width: 3rem;
  transition: all 0.4s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.75rem;
  margin-left: -1.5rem;

  ${({ isOpen }) =>
    isOpen &&
    `
    transform: rotate(90deg);
    opacity:0;
  `}
`;

const StyledIcon = styled(Icon)`
  transition: all 0.4s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1.1rem;
  margin-left: -1.15rem;
  transform: rotate(-90deg);
  opacity: 0;

  ${({ isOpen }) =>
    isOpen &&
    `
    transform: rotate(0deg);
    opacity:1;
  `}
`;

ChatIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default ChatIcon;
