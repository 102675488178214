import { getYearFromDate } from "utils";

/**
 * Get the year range from a start and end date
 *
 * @param {string} startDate
 * @param {string} endDate
 *
 * @returns {string}
 */
export default function getYearRange(startDate, endDate) {
  const startYear = getYearFromDate(startDate);
  const endYear = getYearFromDate(endDate);

  if (startYear === endYear) {
    return startYear;
  } else {
    return `${startYear}-${endYear}`;
  }
}
