import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";

/**
 * Image
 *
 * @param {String}     url
 * @param {String}     alt
 * @param {Function}   onError
 * @param {Function}   onLoad
 */
const Image = ({ url, alt, onError, onLoad, ...props }) => {
  return url ? (
    <img src={url} onError={onError} onLoad={onLoad} alt={alt} {...props} />
  ) : (
    <div css={styles.event_mage}>
      <div>{alt && alt?.split(" ")?.[0]?.[0]}</div>
      <div>{alt && alt?.split(" ")?.[1]?.[0]}</div>
    </div>
  );
};

Image.propTypes = {
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alt: PropTypes.string,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
};

const styles = {
  event_mage: css`
    display: flex;
    font-size: 20px;
  `,
};

export default Image;
