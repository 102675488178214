import React from "react";
import { css } from "@emotion/react";
import { useAuth } from "hooks";
import Card from "components/Card";
import { FormattedMessage } from "react-intl";
import { fontSize, gradients } from "style";
import { BookMeetingButton } from "components/Profile";

/**
 * BookMeetingMemberBanner
 *
 */
const BookMeetingMemberBanner = () => {
  const { isClient, isShowcase } = useAuth();
  const isClientOrShowcase = isClient || isShowcase;

  if (!isClientOrShowcase) return null;

  return (
    <Card css={styles.container}>
      <div css={styles.title}>
        <FormattedMessage id="Banner.BannerShowcaseTalentBookMeeting.title" />
      </div>
      <div>
        <FormattedMessage id="Banner.BannerShowcaseTalentBookMeeting.description" />
      </div>
      <div css={styles.book_meeting_container}>
        <BookMeetingButton />
      </div>
    </Card>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: ${fontSize.normal};
    background: ${gradients.redLinearGradient};
    color: white;
    padding: 3rem;
  `,
  title: css`
    font-size: ${fontSize.xlarge};
    font-weight: 600;
  `,
  book_meeting_container: css`
    max-width: 40rem;
    margin: 1rem auto 0 auto;
  `,
};

export default BookMeetingMemberBanner;
