import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { MUTATION_TYPE, useToast } from "hooks";
import { UPDATE_PROFILE } from "graphql/mutations";
import { MODAL_SIZE, ModalForm } from "components/Modal";
import { METHOD, SIZE, TYPE } from "components/Form";
import { css } from "@emotion/react";
import { GET_ENGINEERING_LEVELS } from "graphql/queries";

/**
 *
 * @param {Object}    data
 * @param {Boolean}   show
 * @param {Function}  onClose
 */
const ProfileEngineeringLevelForm = ({ data, show, onClose, ...props }) => {
  const { toast } = useToast();

  const handleComplete = () => {
    onClose(false);
    toast.success(<FormattedMessage id={"Profile.ProfileEngineeringLevel.Success"} />);
  };

  const handleError = () => {
    onClose(false);
    toast.error(<FormattedMessage id={"Profile.ProfileEngineeringLevel.Error"} />);
  };

  const formProps = {
    mutationType: MUTATION_TYPE.update,
    updateId: data?.profile?.id,
    mutationData: {
      mutationName: {
        update: UPDATE_PROFILE,
      },
    },
  };

  return (
    <ModalForm
      show={show}
      size={MODAL_SIZE.medium}
      data={FORM_DATA}
      title={<FormattedMessage id="Profile.ProfileEngineeringLevel.AddEngineeringLevel" />}
      method={METHOD.post}
      onCompleted={handleComplete}
      onError={handleError}
      onClose={() => onClose(false)}
      initialValues={{
        engineeringLevelId: data?.profile?.engineeringLevel?.uuid,
      }}
      {...formProps}
      {...props}
    />
  );
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
    `,
    items: [
      {
        label: "Engineering Level",
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "engineeringLevelId",
          queryName: GET_ENGINEERING_LEVELS,
          queryKeyName: "engineeringLevels",
          labelKey: "name",
          secondaryLabelKey: "description",
          valueKey: "uuid",
          minLength: 0,
          autoFocus: true,
          defaultOpen: true,
          isClearable: true,
        },
      },
    ],
  },
];

ProfileEngineeringLevelForm.propTypes = {
  data: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ProfileEngineeringLevelForm;
