import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { gradients } from "style";

/**
 * GradientLabel
 */
const GradientLabel = ({ text, ...props }) => (
  <div css={styles.beta_logo} {...props}>
    {text}
  </div>
);

const styles = {
  beta_logo: css`
    background: ${gradients.missionLinearGradient};
    padding: 0.4rem 0.8rem;
    border-radius: 1.6rem;
    display: inline-block;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 500;
  `,
};

GradientLabel.propTypes = {
  text: PropTypes.string,
};

GradientLabel.defaultProps = {
  text: "Beta",
};

export default GradientLabel;
