import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

/**
 * ColorIcon
 *
 * @params {String}   iconType
 */
const SocialIcon = ({ type, ...props }) => (
  <Span css={styles[type]} {...props}>
    {Array.from(Array(ICON_MAP[type]).keys()).map((key) => (
      <Span className={`path${key + 1}`} key={key}></Span>
    ))}
  </Span>
);

const Span = styled.span`
  font-family: "mission-icon";
  font-size: 2.8rem;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 100%;
  position: relative;

  span {
    position: absolute;
  }
`;

const ICON_MAP = {
  behance: 2,
  bitbucket: 2,
  codepen: 2,
  custom: 2,
  dev: 2,
  dribbble: 2,
  gitlab: 8,
  jsfiddle: 2,
  kaggle: 2,
  medium: 2,
  stack_overflow: 3,
  youtube: 2,
  facebook: 2,
  github: 2,
  linkedin: 2,
  twitter: 2,
  calendly: 2,
};

const styles = {
  behance: css`
    .path1:before {
      content: "\\e93a";
      color: rgb(23, 105, 255);
    }
    .path2:before {
      content: "\\e93b";
      color: rgb(255, 255, 255);
    }
  `,

  bitbucket: css`
    .path1:before {
      content: "\\e93c";
      color: rgb(32, 80, 129);
    }
    .path2:before {
      content: "\\e93d";
      color: rgb(255, 255, 255);
    }
  `,

  codepen: css`
    .path1:before {
      content: "\\e93e";
      color: rgb(0, 0, 0);
    }
    .path2:before {
      content: "\\e93f";
      color: rgb(204, 204, 204);
    }
  `,

  custom: css`
    .path1:before {
      content: "\\e940";
      color: rgb(85, 76, 197);
    }
    .path2:before {
      content: "\\e941";
      color: rgb(255, 255, 255);
    }
  `,

  dev: css`
    .path1:before {
      content: "\\e942";
      color: rgb(0, 0, 0);
    }
    .path2:before {
      content: "\\e943";
      color: rgb(255, 255, 255);
    }
  `,

  dribbble: css`
    .path1:before {
      content: "\\e944";
      color: rgb(220, 63, 122);
    }
    .path2:before {
      content: "\\e945";
      color: rgb(255, 255, 255);
    }
  `,

  gitlab: css`
    .path1:before {
      content: "\\e946";
      color: rgb(247, 247, 249);
    }
    .path2:before {
      content: "\\e947";
      color: rgb(226, 67, 41);
    }
    .path3:before {
      content: "\\e948";
      color: rgb(252, 109, 38);
    }
    .path4:before {
      content: "\\e949";
      color: rgb(252, 163, 38);
    }
    .path5:before {
      content: "\\e94a";
      color: rgb(226, 67, 41);
    }
    .path6:before {
      content: "\\e94b";
      color: rgb(252, 109, 38);
    }
    .path7:before {
      content: "\\e94c";
      color: rgb(252, 163, 38);
    }
    .path8:before {
      content: "\\e94d";
      color: rgb(226, 67, 41);
    }
  `,

  jsfiddle: css`
    .path1:before {
      content: "\\e94e";
      color: rgb(70, 121, 164);
    }
    .path2:before {
      content: "\\e94f";
      color: rgb(255, 255, 255);
    }
  `,

  kaggle: css`
    .path1:before {
      content: "\\e950";
      color: rgb(32, 190, 255);
    }
    .path2:before {
      content: "\\e951";
      color: rgb(255, 255, 255);
    }
  `,

  medium: css`
    .path1:before {
      content: "\\e952";
      color: rgb(0, 0, 0);
    }
    .path2:before {
      content: "\\e953";
      color: rgb(255, 255, 255);
    }
  `,

  stack_overflow: css`
    .path1:before {
      content: "\\e954";
      color: rgb(247, 247, 249);
    }
    .path2:before {
      content: "\\e955";
      color: rgb(188, 187, 187);
    }
    .path3:before {
      content: "\\e956";
      color: rgb(244, 128, 36);
    }
  `,

  youtube: css`
    .path1:before {
      content: "\\e957";
      color: rgb(247, 247, 249);
    }
    .path2:before {
      content: "\\e958";
      color: rgb(255, 0, 0);
    }
  `,

  facebook: css`
    .path1:before {
      content: "\\e931";
      color: rgb(59, 89, 152);
    }

    .path2:before {
      content: "\\e932";
      color: rgb(255, 255, 255);
    }
  `,

  github: css`
    .path1:before {
      content: "\\e933";
      color: rgb(64, 120, 192);
    }

    .path2:before {
      content: "\\e934";
      color: rgb(255, 255, 255);
    }
  `,

  linkedin: css`
    .path1:before {
      content: "\\e935";
      color: rgb(0, 119, 181);
    }

    .path2:before {
      content: "\\e936";
      color: rgb(255, 255, 255);
    }
  `,

  twitter: css`
    .path1:before {
      content: "\\e937";
      color: rgb(29, 161, 242);
    }

    .path2:before {
      content: "\\e938";
      color: rgb(255, 255, 255);
    }
  `,

  calendly: css`
  .path1:before {
    content: "\\e90d";
    color: rgb(0, 107, 255);
  }

  .path2:before {
    content: "\\e90d";
    color: rgb(0, 107, 255);
  }
`,
};

const SOCIAL_ICON_TYPE = {
  behance: "behance",
  bitbucket: "bitbucket",
  codepen: "codepen",
  custom: "custom",
  dev: "dev",
  dribbble: "dribbble",
  gitlab: "gitlab",
  jsfiddle: "jsfiddle",
  kaggle: "kaggle",
  medium: "medium",
  stack_overflow: "stack_overflow",
  youtube: "youtube",
  facebook: "facebook",
  github: "github",
  linkedin: "linkedin",
  twitter: "twitter",
  calendly: "calendly"
};

SocialIcon.defaultProps = {
  type: SOCIAL_ICON_TYPE.facebook,
};

SocialIcon.propTypes = {
  type: PropTypes.string,
};

export { SOCIAL_ICON_TYPE };

export default SocialIcon;
