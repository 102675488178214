import React from "react";
import { css } from "@emotion/react";
import { H3 } from "components/Text";
import { fontSize, colors } from "style";

/**
 * Terms and Conditions Content
 */
const TermsAndConditionsContent = () => (
  <>
    <p css={styles.small}>Effective July 1, 2024</p>
    <p>
      <b>These Terms and Conditions</b> (the “<b>Terms and Conditions</b>” or the “<b>Agreement</b>”) are a legal
      agreement between you (the “<b>Contractor</b>” or “<b>you</b>”) and 9371-6793 Quebec Inc. d.b.a Mission.Dev, a
      corporation incorporated under the laws of Quebec (Canada), with its principal place of business located at 8 
      Laurier West Avenue, Montreal (QC) H2T 2N3, Canada (“<b>Mission</b>” or “<b>we</b>”) (each a “<b>Party</b>”
      and collectively, the “<b>Parties</b>”) setting forth, among other things, the terms and conditions for the
      provision by Contractor of the Services (as defined hereinafter) to Mission and its clients.
    </p>
    <br />
    <H3>Mission:</H3>
    <br />
    <ul style={{ listStyleType: "decimal" }}>
      <li>
        <p>
          Has a global network of independent contractors (the “<b>Mission Network</b>”) who are given service
          assignments by Mission to render project-specific technology services to clients of Mission (“
          <b>Mission Clients</b>”);
        </p>
      </li>
      <li>
        <p>
          Wishes to retain the Contractor as an independent contractor to perform services for Mission, and the
          Contractor wishes to be so retained by Mission, on the terms and conditions set forth in these Terms and
          Conditions; and
        </p>
      </li>
      <li>
        <p>
          Also wishes to retain the Contractor as member of the Mission Network on the terms and conditions set forth in
          this Master Agreement to render software development services to Mission Clients on a project-by-project basis
          (“<b>Missions</b>”), with the services for each such Mission to be rendered by the Contractor pursuant to a
          separate and distinct (i) general services assignment agreements (each, a “<b>General Services Assignment</b>
          ”); and/or (ii) Mission assignment agreements (each, a “<b>Mission Assignment</b>”), each of which will
          incorporate the terms of this Agreement.
        </p>
      </li>
    </ul>
    <br />
    <div css={styles.centeredText}>
      <H3 style={{ color: colors.red }}>PLEASE READ ALL THE TERMS AND CONDITIONS CAREFULLY</H3>
    </div>
    <br />
    <ul style={{ listStyleType: "square" }}>
      <li>
        <p>
          <b>Your Agreement.</b> BY CLICKING THE CHECK BOX INDICATING YOU THAT YOU HAVE READ, UNDERSTOOD AND ACCEPT
          THESE TERMS AND CONDITIONS, YOU ACCEPT AND AGREE TO BE BOUND BY THE PROVISIONS OF THESE TERMS AND CONDITIONS
          WITHOUT ANY RESERVATIONS, MODIFICATIONS, ADDITIONS OR DELETIONS.
        </p>
      </li>
      <li>
        <p>
          <b>Amendments.</b> WE MAY FROM TIME TO TIME MODIFY THE TERMS AND CONDITIONS AND WILL POST A COPY OF THE
          AMENDED TERMS AND CONDITIONS AT THE FOLLOWING LINK <b>https://app.mission.dev/legal/terms-and-conditions</b>,
          SO WE ENCOURAGE YOU TO REVIEW THEM PERIODICALLY. If we make any substantial changes to the Terms and
          Conditions, we will notify you by email. If you wish to terminate this Agreement because you do not agree with
          the Terms and Conditions as amended, you may do so as described below in the Section “Term and Termination”.
          You will be deemed to have accepted the Terms and Conditions as amended if you continue to provide the
          Services after you have been notified by email of such substantial changes.
        </p>
      </li>
    </ul>

    <ul style={{ listStyleType: "decimal" }}>
      <br />
      <li>
        <H3>TERM AND TERMINATION</H3>
        <br />
        <ul style={{ listStyleType: "lower-alpha" }}>
          <li>
            The term of this Agreement shall be for the period commencing on the day on which the Contractor agrees to
            the Terms and Conditions and ending when terminated in accordance with the provisions contained herein (the
            “<b>Term</b>”). All General Service Assignments and Mission Assignments (each, an “<b>Assignment</b>”) shall
            terminate automatically upon the termination of the Agreement.
          </li>
          <li>
            The term of a given Assignment shall be for the period beginning on the Assignment Effective Date (as set
            forth in a given Assignment) and ending on upon completion of the Services (as defined hereinafter) set out
            therein (the “<b>Assignment Term</b>”), unless terminated earlier in accordance with the terms of this
            Agreement.
          </li>
          <li>
            This Agreement and any Assignment may be terminated at any time and for any reason during the Term by:
            <ul style={{ listStyleType: "upper-roman" }}>
              <li>
                immediately by Mission, at any time and for any reason during the Term, upon written notice to the
                Contractor;
              </li>
              <li>the Contractor providing Mission with 15 days prior written notice;</li>
              <li>at any time as mutually agreed upon by the Parties.</li>
            </ul>
          </li>
          <li>
            Notwithstanding the foregoing, the first two weeks of each Assignment Term (the “<b>Mission Onboarding Period</b>”) 
            constitute an onboarding period . Either Party may terminate an Assignment during a Mission Onboarding Period, 
            immediately for any reason during the applicable Onboarding Period, by providing written notice of termination to the 
            other Party. If the Assignment is terminated by either Party during the applicable Onboarding Period, and only in the 
            case the Contractor is not able to demonstrate any proof of work during the applicable Onboarding Period, the Contractor 
            will not be entitled to payment of any Fees (as defined hereinafter) incurred for any Services (as defined hereinafter) 
            performed during the applicable Onboarding Period.
          </li>
          <li>
            With greater certainty, Mission may terminate this Agreement and any or all Assignments, remove the member from their list
            of members, and from the Mission Community, at any time without prior notice, for fraud, including suspicion of fraud, dishonesty,
            wilful neglect, misconduct, or any material breach of the terms of this Agreement or of an Assignment by the Contractor, subject
            only to Mission fully complying with any applicable minimum requirements under applicable legislation. If any of these events occur,
            the Contractor shall forfeit any right to payment of Fees for Services rendered from the last invoicing period up to the date of termination.
          </li>
          <li>
            Upon termination of this Agreement and/or an Assignment for any reason whatsoever:
            <ul style={{ listStyleType: "upper-roman" }}>
              <br />
              <li>
                the Contractor shall have no further claims against Mission for damages of any nature whatsoever under
                common law, civil law or contract; and
              </li>
              <li>Mission shall only be responsible for the payment of;</li>
              <ul style={{ listStyleType: "upper-alpha" }}>
                <br />
                <li>
                  any reasonable expenditures properly incurred by the Contractor under this Agreement and any
                  Assignment then in effect up to the effective date of termination; and
                </li>
                <li>
                  for payment of any Fees accrued under this Agreement and any Assignment then in effect up to the
                  effective date of termination or payment in lieu of Fees; and
                </li>
              </ul>
              <li>
                the Contractor shall be under a duty to mitigate all damages that the Contractor may claim or assert an
                entitlement to in respect of the termination of this Agreement or any Assignment.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <br />
      <li>
        <H3>SERVICES TO BE PROVIDED</H3>
        <br />
        <ul style={{ listStyleType: "upper-alpha" }}>
          <li>
            Mission hereby retains the Contractor to perform certain general services for Mission, including without
            limitation participating in content creation, leading technical interviews with Mission’s Network candidates
            and providing technical expertise to Mission as requested by Mission from time to time (the “
            <b>General Services</b>”). The Parties shall be required to enter into a General Services Assignment
            substantially in the form set forth in Exhibit 1 hereto for each General Service Mission. Each General
            Services Assignment shall (i) set forth the General Services to be provided by the Contractor; (ii)
            automatically incorporate the terms of this Agreement; and (iii) be a separate and distinct agreement from
            any other Assignment. If there is a contradiction between a provision of this Agreement and a provision of a
            General Services Assignment, the provision in this Agreement will take precedence unless the General
            Services Assignment specifically states otherwise.
          </li>
          <li>
            Mission hereby also retains the Contractor to perform the services for Mission's Clients pursuant to each
            Mission Assignment that is entered into between Mission and the Contractor from time to time during the Term
            (collectively, the “<b>Mission Assignment Services</b>” and together with the General Services, the “<b>Services</b>”).
            The Parties shall be required to enter into a Mission Assignment substantially in the form set forth in Exhibit 2 hereto
            for each Mission Assignment. Each Mission Assignment shall (i) set forth the Mission Assignment Services to be provided by
            the Contractor and the Mission Client who shall receive such Mission Assignment Services, amongst other things; (ii) automatically
            incorporate the terms of this Agreement; and (iii) be a separate and distinct agreement from any other Assignment.  If there is a
            contradiction between a provision of this Agreement and a provision of a Mission Assignment, the provision in this Agreement will
            take precedence unless the Mission Assignment specifically states otherwise.
          </li>
          <li>
            You represent and warrants to Mission that: (i) you have the necessary technical skills, knowledge and
            expertise to ensure delivery of the best quality of services; (ii) you shall perform the Services with
            reasonable care, technical skill and diligence, and in a profession manner that complies with industry
            standards, best practices and all applicable legislation, regulations rules and standards (including without
            limitation any applicable privacy legislation); (iii) you have the full right, power and authority to enter
            into this Agreement and that you entering into this Agreement and providing the Services shall not result in
            a breach of or constitute a default under any agreement, restrictive covenants (such as non-disclosure or
            non-competition obligations) or instrument to which you are a party; (iv) none of the Services or
            developments that you provide, or that provided on your behalf under this Agreement will infringe on or
            otherwise violate any third party rights, including any intellectual property rights; (v) you have obtained
            and will maintain all licenses and permits required in order to perform the Services; and (vi) you will not
            reverse engineer, decompile or disassemble Mission’s proprietary software platform or attempt to gain
            unauthorized access to Mission’s proprietary software platform or Mission’s systems or networks.
          </li>
          <li>
            With respect to the General Services, the Contractor shall receive instructions from Stephane Rossi, CEO
            (the “<b>Administrator</b>”), anyone that has been explicitly designated by the
            Administrator or any other person designated in a General Services Assignment. The Contractor acknowledges
            and agrees that Mission is entitled to change the Administrator at any time without providing any prior
            notice to the Contractor.
          </li>
          <li>
            With respect to the Mission Assignment Services, the Contractor will report its activities and receive instructions about
            the project deliverables from <b>Mission's Client</b>. During the execution of the Mission Assignment Services,
            the Contractor will receive support from the “<b>Squad Lead</b>” or the “<b>Customer Success Executive</b>”, as applicable and as set forth in a given Mission Assignment,
            as their “<b>Main Point of Contact</b>”. The Contractor acknowledges and agrees that Mission is entitled to change
            the “Main Point of Contact” set forth in a given Mission Assignment at any time during the Assignment Term 
            without providing prior notice to the Contractor. On the Mission Assignment start date, the Main Point of Contact will point out the name and contact the Contractor should address.
          </li>
          <li>
            In the case where the Contractor is an entity other than an individual, the Contractor may designate
            employees or independent contractors of the Contractor to render the Services, provided that such
            individuals must be evaluated and approved by Mission prior to that individual performing any Services (the
            “<b>Designees</b>”). If a Designee cannot perform any part of the Services for which Mission has approved
            them as a Designee, such tasks cannot be delegated to or performed by anyone else without Mission’s prior
            evaluation of an alternative Designee and approval thereof. Designees shall render the Services primarily at
            such places as Mission and the Contractor may mutually agree. The Parties agree that during the Term, the
            Designees will remain solely the employees or independent contractors of the Contractor and shall not be
            employees or independent contractors of Mission, and that the Contractor shall remain liable for the failure
            of the Designees to comply with the terms of this Agreement and any Assignment. For the purposes of this
            Agreement and any Assignment, all references to the “<b>Contractor</b>” shall also include and bind the
            Designees and for the sake of clarity, the Contractor shall be responsible for any breach of this Agreement
            or any Assignment entered into hereunder by its Designees
          </li>
          <li>
            The Contractor shall not have the authority or discretion to enter into any agreement, contract or
            understanding that legally binds Mission or otherwise assume, create or incur any obligations or liabilities
            on behalf of Mission, except as expressly provided for in this Agreement, without first obtaining the prior
            written consent of Mission.
          </li>
          <li>
            If, during the execution of the Services, the Contractor or their Designee needs to be absent and is not able
            to provide Services during the defined working hours as set forth in each active Mission Assignment, the Contractor
            is responsible for arranging a clear and detailed transition plan for work to be executed to minimize work disruption
            during their absence and must provide prior notice to their Main Point of Contact at Mission or to Mission's Client.
          </li>
        </ul>
      </li>
      <br />
      <li>
        <H3>FEES & PAYMENT TERMS</H3>
        <br />
        <ul style={{ listStyleType: "lower-alpha" }}>
          <li>
            In consideration of the Services rendered, Mission shall pay the Contractor the fees in the amount and
            manner set out in the applicable Assignment (the “<b>Fees</b>”).
          </li>
          <li>
            Fees may be increased by the Contractor upon providing one (1) month's notice to their Customer Success
            Executive at Mission. Mission reserves the right to terminate each active Mission Assignment Agreement
            with the Contractor, without notice, if the proposed Fees are not aligned with the budget agreed upon
            with Mission's Clients as set out in the applicable Assignment.
          </li>
          <li>
            For Canadian residents only, the Fees do not include sales tax, or other applicable duties as may be
            required by law. Any sales tax or duties required by law must be charged by the Contractor to Mission in
            addition to the Fees.
          </li>
          <li>
            Payment of the Fees to the Contractor for services rendered under each Assignment Agreement is contingent
            upon and subject to receipt of payment by Mission from Mission's Client for such services. In the event  Mission's
            Clients fail to make payment to Mission for any reason, Mission shall have no obligation to make payment to the
            Contractor for those services, and the Contractor hereby waives any claim against Mission related to such non-payment.
            Mission hereby undertakes to employ all requisite efforts to secure timely payment from the relevant Clients as specified in the Assignment Agreement.
          </li>
          <li>
            Invoices for the Services shall be prepared and provided by the Contractor in accordance with Mission’s
            invoicing policy, available at: <b>https://library.mission.dev/invoicing-guide</b>.
          </li>
          <li>
            Contractor hereby appoints Deel Inc., a Delaware registered company having its headquarters at 425 1st St,
            San Francisco California, 94105-4621, United States (“<b>Deel</b>”) as Contractor’s limited payment agent
            for the purpose of facilitating the receipt of payments made by Mission for Services provided in connection
            with this Agreement and any Assignment hereunder, using a payment method supported by Deel or by one of
            Deel’s payment service providers, and the disbursement of those payments to Contractor. Contactor represents
            and warrants that they have carefully read, understood and accepted the Deel platform terms and conditions
            available on Deel’s website at: <a href="https://www.deel.com/terms-of-service">Terms of Service | Deel</a>{" "}
            (the “<b>Deel Terms and Conditions</b>”). In the event of any conflict between this Agreement and the Deel
            Terms and Conditions, this Agreement will govern. Whenever requested to do so by Mission, you shall execute
            any and all instruments to give effect to the terms of this Section (the “<b>Deel Documents</b>
            ”).
          </li>
          <li>
            If necessary with the provision of the General Services under a given General Services Assignment, Mission
            will reimburse the Contractor for reasonable expenses legitimately incurred by the Contractor provided that
            the Administrator has pre-approved such expenses and the Contractor furnishes receipts to Mission for all
            such expenses. The pre-approval written statement and the proof of expenses sent in writing by email to:{" "}
            <a href="accounting@mission.dev">accounting@mission.dev</a>.
          </li>
          <li>
            If necessary with the provision of the Mission Assignment Services under a given Mission Assignment (ie. requested by the
            Mission Client and/or the Squad Lead to perform the Mission Assignment Services), Mission will reimburse the Contractor
            for reasonable expenses legitimately incurred by the Contractor provided that their Customer Success Executive
            at Mission has pre-approved such expenses and the Contractor furnishes receipts to Mission for all such
            expenses. The pre-approval written statement and the proof of expenses sent in writing by email to:
            <a href="accounting@mission.dev">accounting@mission.dev</a>.
          </li>
          <li>
            If a Contractor fails to submit their timesheet on the last day of the month as set out in the Mission invoicing process,
            Mission has the right to delay Contractor payment until the following payment cycle.
          </li>
          <li>
            If a Contractor submits a number of hours exceeding their working hours set out in the applicable Assignment terms,
            Mission cannot guarantee that a Contractor will be paid for the hours exceeded. Additionally, a contractor will be paid
            and only paid for the hours they log in their timesheet and submit on or before the last business day of a given month.
          </li>
        </ul>
      </li>
      <br />
      <li>
        <H3>INDEPENDENT CONTRACTOR</H3>
        <br />
        <p>
          The Contractor is and shall remain at all times an independent contractor and is not and shall not represent
          the Contractor to be an agent, joint venturer, partner, officer, director or employee of Mission. Nothing
          contained in this Agreement or in any Assignment is intended to create nor shall be construed as creating an
          employment relationship between the Contractor (or the Designees) and Mission. The Contractor has sole
          responsibility, as an independent contractor, to comply with all laws, rules and regulations relating to the
          provision of Services, including without limitation, requirements under applicable legislation for deducting
          any and all applicable taxes, deductions, premiums, and amounts owing with respect to those Fees paid by
          Mission. The Contractor further agrees to indemnify and hold Mission, its directors, officers, agents and
          employees harmless from and against any and all liabilities, claims, demands, suits, losses, fines,
          surcharges, damages, costs and expenses in respect of any failure on the part of Mission to (i) withhold any
          taxes, premiums, payments, benefit overpayments, levies or other amounts from all or any part of the Fees or
          other amounts paid to the Contractor during the Term; or (ii) comply with requirements to make payments under
          applicable legislation.
        </p>
      </li>
      <br />
      <li>
        <H3>NON-SOLICITATION</H3>
        <br />
        <p>
          During the Term and for a period of 12 months following the termination of this Agreement for whatever reason,
          the Contractor shall not, either directly or indirectly:
        </p>
        <ul style={{ listStyleType: "lower-alpha" }}>
          <li>
            discuss hiring, offer to hire, hire or recruit (or attempt to do any of the foregoing) any of the employees
            or independent contractors of Mission or any of its subsidiaries (including other members of the Mission
            Network) or encourage any of them to terminate their relationship with Mission or any of its subsidiaries,
            as the case may be;
          </li>
          <li>
            solicit, approach, contact, call upon or canvass (or attempt to do any of the foregoing), in connection with
            any undertaking that is in whole or in part competitive with the business of Mission or its subsidiaries (a
            “<b>Competitive Business</b>”), any customer or client of Mission or any of its subsidiaries to whom, at any
            time within the 12 month period prior to the termination of this Agreement, the Contractor (including any
            Designee), in the course of performing the Services had direct contact; or
          </li>
          <li>
            solicit, approach, contact, call upon or canvass (or attempt to do any of the foregoing) any Prospective
            Client in connection with a Competitive Business. For the purposes of this Agreement, “
            <b>Prospective Client</b>” means any person or entity that is not a client of Mission or any of its
            subsidiaries but for whom (i) there was an active proposal outstanding by Mission or any of its subsidiaries
            or on Mission’s or any of its subsidiaries’ behalf within the 12-month period prior to the termination of
            this Agreement and (ii) the Contractor was involved in the preparation or presentation of such proposal.
          </li>
        </ul>
      </li>
      <br />
      <li>
        <H3>CONFIDENTIALITY OF INFORMATION AND OWNERSHIP OF PROPRIETARY PROPERTY</H3>
        <br />
        <p>
          The Confidentiality of Information and Ownership of Proprietary Property Agreement, included as Schedule A
          hereto forms an integral part of this Agreement, and by agreeing to be bound by these Terms and Conditions the
          Contractor agrees and accepts to be bound by the terms set forth therein. Furthermore, the Contractor agrees
          that it shall cause any Designees, approved by Mission in accordance with the terms of Section 2(f) of this
          Agreement, to execute a Confidentiality of Information and Ownership of Proprietary Property Agreement
          substantially in the form of the agreement included as Schedule A hereto, promptly upon request by Mission.
        </p>
      </li>
      <br />
      <li>
        <H3>PRIVACY</H3>
        <br />
        <p>
          By agreeing to become a member of the Mission Network, you hereby and acknowledge that you are subject to
          Mission’s privacy policy available at: <b>https://app.mission.dev/legal/privacy-policy</b>, the terms of which
          are incorporated herein by reference (the “<b>Privacy Policy</b>”).
        </p>
        <br />
        <p>
          For greater certainty, you hereby agree and acknowledge that Mission (including through third-party service
          providers) is permitted to conduct background checks (including ID verification checks or criminal record
          checks) on you to determine if you should be included in the Mission Network and reserves the right to
          terminate this Agreement and any Mission Assignments hereunder immediately at any time if it is not satisfied
          with the results of such background checks. Mission or Mission’s third-party service providers (including
          Certn) may collect your personal data in order to conduct such background checks. Such personal data may
          include the following (without limitation):
        </p>
        <br />
        <ul>
          <li>Full legal name (including middle name)</li>
          <li>Postal address</li>
          <li>Phone number</li>
          <li>Email</li>
          <li>Date of birth</li>
          <li>ID (national ID or passport)</li>
          <li>ID number</li>
          <li>Profile picture</li>
          <li>The expiry date of the ID</li>
          <li>Criminal record</li>
        </ul>
        <br />
        <p>
          Further, in the case where Mission collects such personal data directly you hereby consent to the transfer of
          such personal data by Mission to Certn or any other third-party service providers who provide background check
          services to Mission.
        </p>
        <br />
        <p>
          Mission’s collection, use and disclosure of such personal data shall be limited to the purposes described in
          this Section and be subject to the terms of the Privacy Policy.
        </p>
      </li>
      <br />
      <li>
        <H3>COOPERATION</H3>
        <br />
        <p>
          Whenever requested to do so by Mission, you shall execute any and all instruments and do such acts that
          Mission shall request to comply with any requirements set forth by Mission’s customers, including with respect
          to the safety, privacy, or confidentiality of such customer’s business, operations, or IT infrastructure.
        </p>
        <br />
        <p>
          In the event Mission is required or compelled to disclose information to any governmental authority for
          regulatory compliance purposes, you hereby agree to share any such information with Mission promptly. Mission
          or Mission’s third-party service providers (including Deel) may collect your information (including personal
          data) in order to comply with governmental requirements. Such information (including personal data) may
          include the following (without limitation):
        </p>
        <br />
        <ul>
          <li>Full legal name</li>
          <li>Email</li>
          <li>Country of fiscal residence</li>
          <li>ID (national ID or passport)</li>
          <li>(Mexico) Proof of Federal Taxpayer Registry number</li>
          <li>
            (Mexico) Certificate of good standing issued by the Mexican Internal Revenue Service (Service Tax
            Administration)
          </li>
          <li>(Argentina) Proof of tax ID including tax ID number</li>
          <li>(UAE - Dubai) License to work as an independently as a freelancer OR NOC from employer or sponsor</li>
          <li>(UAE - Dubai) Residential visa to prove residency in the UAE</li>
          <li>
            (UAE - Dubai) Letter from contractor stating they are entitled perform the contract with no legal
            restrictions on their personal capacity.
          </li>
          <li>(India) Proof of GST and income tax registration</li>
          <li>(Pakistan) Proof of registration under income tax laws</li>
          <li>(Pakistan) Proof of annual returns payment</li>
          <li>(Brazil) Proof of CNPJ associated with his/her name</li>
          <li>(Brazil) Articles of Association</li>
          <li>(France) Declaration of fiscal regularity (Attestation de régularité fiscale)</li>
          <li>(Canada) for incorporated contractors: Proof of registration of the company</li>
          <li>(Dominican Republic) Proof of RNC</li>
          <li>(Dominican Republic) Proof of authorized NCF number</li>
        </ul>
        <br />
        <p>
          Further, in the case where Mission collects such personal data directly you hereby consent to the transfer of
          such personal data by Mission to (i) any relevant government authority; or (ii) Deel or any other third-party
          service providers who provide regulatory compliance services to Mission.
        </p>
        <br />
        <p>
          Mission’s collection, use and disclosure of such personal data shall be limited to the purposes described in
          this Section and be subject to the terms of the Privacy Policy.
        </p>
      </li>
      <br />
      <li>
        <H3>HEALTH AND SAFETY, DAMAGE TO PROPERTY</H3>
        <br />
        <p>
          The Contractor shall comply with applicable health and safety laws and all rules and procedures put in place
          from time to time by Mission or Mission Clients, and hereby agrees to indemnify and hold harmless Mission, its
          directors, officers, agents and employees from and against any and all claims, demands, suits, losses, fines,
          surcharges, damages, costs and expenses arising out of the Contractor’s failure to comply with such laws. The
          Contractor further agrees to indemnify and hold Mission, its directors, officers, agents and employees
          harmless from and against any and all liabilities, claims, demands, suits, losses, fines, surcharges, damages,
          costs and expenses relating to the injury or death of any person, damage to or destruction of any property,
          which is directly or indirectly caused by any act or omission on the part of the Contractor (or any Designees)
          engaged in providing Services.
        </p>
      </li>
      <br />
      <li>
        <H3>INSURANCE</H3>
        <br />
        <p>
          The Contractor is responsible for obtaining, and maintaining for the duration of the Term, their own general
          liability and property damage insurance against loss or damage resulting from the Contractor’s (and any
          Designees’) actions in connection with the performance of the Services, which insurance coverage shall be
          consistent with the level of risk exposure associated with the Services. The Contractor acknowledges that
          failure to obtain such insurance exposes the Contractor to risk of financial loss resulting from liability of
          the Contractor for loss or damage caused by the Contractor (or any Designee) in connection with this Agreement
          or the Services. Upon request by Mission, the Contractor shall promptly provide evidence of its insurance
          coverage to Mission.
        </p>
      </li>
      <br />
      <li>
        <H3>SEVERABILITY</H3>
        <br />
        <p>
          In the event that any covenant, provision or restriction contained in this Agreement or an Assignment is found
          to be void or unenforceable (in whole or in part) by a court of competent jurisdiction, it shall not affect or
          impair the validity of any other covenant, provisions or restrictions contained herein or therein, nor shall
          it affect the validity or enforceability of such covenants, provisions or restrictions in any other
          jurisdiction or in regard to other circumstances. Any covenants, provisions or restrictions found to be void
          or unenforceable are declared to be separate and distinct, and the remaining covenants, provisions and
          restrictions shall remain in full force and effect.
        </p>
      </li>
      <br />
      <li>
        <H3>ENUREMENT</H3>
        <br />
        <p>
          This Agreement and each Assignment shall enure to the benefit of and be binding upon the Parties and their
          respective successors and assigns, including without limitation, the Contractor’s heirs, executors,
          administrators and personal representatives.
        </p>
      </li>
      <br />
      <li>
        <H3>ASSIGNMENT</H3>
        <br />
        <p>
          The Contractor may not assign any of the Contractor’s rights or delegate any of the Contractor’s duties or
          responsibilities under this Agreement, any Assignment or any Deel Document, without Mission’s prior written
          consent. Mission may, without the consent of the Contractor, assign its rights, duties and obligations under
          this Agreement to an affiliate or to a purchaser of all, or substantially all of the assets of Mission.
        </p>
      </li>
      <br />
      <li>
        <H3>ENTIRE AGREEMENT</H3>
        <br />
        <p>
          This Agreement, (including any Exhibits or Schedules hereto) together with any Assignments entered into under
          this Agreement or any Deel Documents between Mission and the Contractor, constitute the entire agreement
          between the Parties and supersedes and replaces any and all other representations, understandings,
          negotiations and previous agreements (including contractor agreements), written or oral, express or implied.
        </p>
      </li>
      <br />
      <li>
        <H3>NOTICES</H3>
        <br />
        <p>
          All notices, requests, demands or other communications required or permitted by the terms of this Agreement or
          any Assignment will be given in writing and delivered to the Parties as follows:
        </p>
        <br />
        <ul>
          <li>to Mission by email to: Member Experience Department at members@mission.dev; and</li>
          <li>
            to Contractor at the contact details provided by the Contractor during the registration process to become a
            part of the Mission Network.
          </li>
        </ul>
        <br />
        <p>or to such other addresses as either Party may from time to time notify the other.</p>
      </li>
      <br />
      <li>
        <H3>GOVERNING LAW AND JURISDICTION</H3>
        <br />
        <p>
          This Agreement and all Assignments shall be interpreted and construed in accordance with the laws of the
          Province of Quebec and the laws of Canada applicable therein without regard to the conflict of laws rules
          applicable therein. Each of the Parties hereby attorns to the exclusive jurisdiction of the courts of the
          Province of Quebec with respect to any dispute arising from this Agreement or any Assignment. The foregoing
          choice of jurisdiction and venue shall not prevent Mission from seeking injunctive relief with respect to a
          violation of intellectual property rights, confidentiality obligations or enforcement or recognition of any
          award or order in any appropriate jurisdiction.
        </p>
      </li>
      <br />
      <li>
        <H3>SURVIVAL</H3>
        <br />
        <p>
          The following provisions shall survive the expiration or termination of this Agreement: 4, 5, 6, 7-9, 11-18;
          as will those other provisions of this Agreement that, by the nature of their terms, would apply after
          expiration or termination of this Agreement.
        </p>
      </li>
      <br />
      <li>
        <H3>LANGUAGE</H3>
        <br />
        <p>
          The Parties have expressly required that this agreement and documents ancillary hereto be drafted in the
          English language.{" "}
          <i>
            Les parties à la présente ont expressément exigé que le présent accord et les documents afférents soient
            rédigés en langue anglaise
          </i>
          .
        </p>
      </li>
    </ul>
    <br />
    <div css={styles.centeredText} style={{ textDecoration: "underline" }}>
      <H3>SCHEDULE A</H3>
    </div>
    <div css={styles.centeredText}>
      <H3>CONFIDENTIALITY OF INFORMATION</H3>
    </div>
    <div css={styles.centeredText}>
      <H3>AND OWNERSHIP OF PROPRIETARY PROPERTY AGREEMENT</H3>
    </div>
    <br />
    <p>
      THIS CONFIDENTIALITY OF INFORMATION AND OWNERSHIP OF PROPRIETARY PROPERTY AGREEMENT (the “CIOPP”) is entered into
      as of the Effective Date of the Terms and Conditions between Mission and the Contractor and is considered an
      integral part of such Agreement.
    </p>
    <p>BACKGROUND:</p>
    <ul style={{ listStyleType: "upper-alpha" }}>
      <li>
        Mission may give, has given and will give the Contractor access to proprietary or confidential information of
        Mission and its affiliates and subsidiaries (if any) (the “<b>Company Group</b>”), including information that,
        by its nature or by the nature of its disclosure, would reasonably be considered to be proprietary or
        confidential to the Company Group (which information is collectively referred to in this Agreement as “
        <b>Confidential Information</b>”). For greater certainty, Confidential Information includes all employee,
        customer or client personal information, customer and client information, technical data, unpublished know-how,
        techniques, records, formulae, processes, sketches, photographs, plans, drawings, specifications, samples,
        reports, manuals, documents, prototypes, hardware, software and other equipment, working materials, findings,
        inventions and ideas, whether patentable or not, whether they be trade secrets or not and whether they be in
        written, graphic, oral, electronic or any other form, that are now or hereafter owned, licensed or otherwise
        acquired by the Company Group, and shall include all such similar information of any customer of the Company
        Group.
      </li>
      <li>
        The Contractor may develop, conceive, generate, contribute to or use, in the course of engagement with Mission,
        alone and/or jointly with others, tangible and intangible property relating to actual or anticipated business
        and research and development of the Company Group, or that is suggested by or result from work performed for or
        on behalf of the Company Group, in any fields, which property includes software, hardware, know-how, designs,
        techniques, documentation and other material, including property created by the Contractor (whether within the
        scope of the Contractor’s engagement with Mission or not) and used in the course of the Contractor’s engagement
        with Mission, and for all of the preceding, regardless of the form or media in or on which it is stored, some or
        all of which property may be protected by patents, copyrights, trade secrets, trade-marks, industrial designs or
        mask works or any civil law, common law or statutory right anywhere in the world (which tangible and intangible
        property is collectively referred to in this CIOPP as “<b>Proprietary Property</b>”).
      </li>
    </ul>
    <p>
      <b>NOW, THEREFORE,</b> in consideration of the Contractor’s engagement with Mission and other good and valuable
      consideration, the receipt and sufficiency of which is acknowledged by the Contractor agrees as follows:
    </p>
    <ul style={{ listStyleType: "decimal" }}>
      <li>
        The Contractor, both during and after engagement with Mission, shall not disclose or use (or permit the use of)
        any Proprietary Property or Confidential Information, directly or indirectly, except in the course of carrying
        out authorized activities on behalf of Mission or except as expressly authorized by Mission in writing. The
        Contractor may, however, use or disclose Confidential Information that:
      </li>
      <br />
      <ul style={{ listStyleType: "lower-alpha" }}>
        <li>is or becomes public, other than through a breach of this CIOPP; or</li>
        <li>
          is known to the Contractor prior to engagement by Mission and with respect to which the Contractor does not
          have any obligation of confidentiality; or
        </li>
        <li>
          is required to be disclosed, or the disclosure of which to regulators is protected, by law, whether under an
          order of a court or government tribunal, statutory provision or other legal process, provided that, where such
          disclosure is required of the Contractor, the Contractor informs Mission of such requirement as soon as the
          Contractor becomes aware of the requirement and in sufficient time to allow Mission to take such steps as are
          lawfully available to Mission to avoid or limit such disclosure by the Contractor.
        </li>
      </ul>
      <li>
        The Contractor, both during and after engagement with Mission, shall not disclose or use (or permit the use of),
        directly or indirectly, any trade secrets, confidential information or proprietary property of a third party
        obtained by the Contractor during the course of or as result of engagement with Mission, except as expressly
        authorized by Mission or such third party in writing.
      </li>
      <li>
        All right, title and interest in and to Proprietary Property (including the Proprietary Property described in
        paragraph 6 below), as between the Contractor and Mission, belongs to Mission, and the Contractor has no rights
        in any such Proprietary Property. For greater certainty, all right, title and interest (including any
        intellectual property rights) in and to all Proprietary Property that the Contractor may acquire in the course
        of engagement with Mission are hereby assigned to Mission. The Contractor agrees to make full disclosure to
        Mission of and to properly document each development of any Proprietary Property, and to provide written
        documentation describing such Proprietary Property to Mission, promptly after its creation. At the request and
        expense of Mission, both during and after engagement with Mission, the Contractor shall do all acts necessary
        and sign all documentation necessary in order to assign all right, title and interest in and to the Proprietary
        Property to Mission and to enable Mission to register patents, copyrights, trade-marks, mask works, industrial
        designs and such other protections as Mission deems advisable anywhere in the world. The Contractor irrevocably
        designates and appoints Mission and its duly authorized officers and agents as the Contractor’s agent and
        attorney-in-fact, to act for and in the Contractor’s behalf and stead to execute and file any such instruments
        and papers and to do all other lawfully permitted acts to further the application for, prosecution, issuance,
        maintenance or transfer of patents, copyrights, trade-marks, mask works, industrial designs and such other
        protections related to the Proprietary Property. This power of attorney is coupled with an interest and shall
        not be affected by the Contractor’s subsequent incapacity or death.
      </li>
      <li>
        If, during and in the course of engagement with Mission, the Contractor develops any Proprietary Property that
        is protected by copyright, the Contractor hereby waives unconditionally any “moral rights” the Contractor may
        have in such Proprietary Property.
      </li>
      <li>
        If any part of the services or Proprietary Property or information provided hereunder is based on, incorporates,
        or is an improvement or derivative of, or cannot be reasonably and fully made, used, reproduced, distributed and
        otherwise exploited without using or violating technology or intellectual property rights owned by or licensed
        to the Contractor (or any person involved in the services) and not assigned hereunder, the Contractor hereby
        grants Mission and its successors a perpetual, irrevocable, worldwide, royalty-free, non-exclusive,
        sublicensable right and licenses to exploit and exercise all such technology and intellectual property rights in
        support of Mission’s exercise or exploitation of the services, Proprietary Property, other work or information
        performed or provided hereunder, or any assigned rights (including any modifications, improvements and
        derivatives of any of them).
      </li>
      <li>
        The Contractor, both during and after engagement with Mission, shall not make any unauthorized use of Mission’s
        or of any Mission Client’s computer systems, communications networks, databases or files. The Contractor shall
        adhere to all Mission policies or Mission Client policies (as applicable) regarding the use of such computer
        systems, communications networks, databases or files.
      </li>
      <li>
        All notes, data, tapes, reference items, sketches, drawings, memoranda, records, documentation and other
        material regardless of the form or media in or on which it is stored, that is in or comes into the possession or
        control of the Contractor, and that is in any way obtained, conceived, developed, generated or contributed to by
        the Contractor, alone and/or jointly with others in the course of or as a result of the Contractor’s engagement
        with Mission, is and remains Confidential Information and/or Proprietary Property within the meaning of this
        CIOPP.
      </li>
      <li>
        The Contractor shall return or destroy, as directed by Mission, Confidential Information or Proprietary Property
        to Mission upon request by Mission at any time, and upon the cessation of engagement with Mission, regardless of
        how that cessation occurs. Such return or destruction shall include all originals and all copies of the
        Confidential Information and Proprietary Property, in whatever medium or form, that is then in the control or
        possession of the Contractor. Upon request by Mission, the Contractor shall certify, by way of affidavit or
        statutory declaration, that all such Confidential Information and Proprietary Property has been returned or
        destroyed, as applicable. Both during and after engagement with Mission, the Contractor shall not make or retain
        copies of the Confidential Information or Proprietary Property in the Contractor’s possession or control, except
        for the purpose of carrying out authorized activities on behalf of Mission or except as expressly authorized by
        Mission in writing. For information stored in electronic form:
      </li>
      <br />
      <ul>
        <li>
          the Contractor shall be deemed to have returned it when the Contractor transmits an electronic copy to Mission
          and thereafter destroys it per (b) below; and
        </li>
        <li>
          the Contractor shall be deemed to have destroyed it when the Contractor performs a commercially reasonable
          “delete” function with respect to all of its copies of information, notwithstanding that such information may
          be forensically recoverable or restored from backups (provided always that if, at any time, the Contractor
          performs or permits such recovery or restoration, the Contractor shall treat such recovered or restored
          information as Confidential Information hereunder at all times).
        </li>
      </ul>
      <li>
        The Contractor shall not use unauthorized software on Mission’s or any Mission Client’s equipment during the
        course of engagement with Mission. Furthermore, the Contractor shall not incorporate into or link with the
        Confidential Information or Proprietary Property, any third-party intellectual property (including third party
        software, images, works or materials, third party patents or trade secrets, and open source software) without
        first (a) disclosing same to Mission together with the license therefor from such third party, and (b) receiving
        authorization from Mission for such incorporation or linkage.
      </li>
      <li>
        During the Contractor’s engagement with Mission, the Contractor shall not make use of or in any manner
        communicate to Mission any confidential information of any third party (including former employers of the
        Contractor) that may be in or may come into the Contractor’s possession or control, other than confidential
        information disclosed to the Contractor in his, her or its capacity as a representative of Mission.
      </li>
      <li>
        The Contractor shall, if requested from time to time by Mission, execute such further agreements as to
        confidentiality and proprietary rights as Mission requires to protect confidential information or proprietary
        property whether of the Company Group or of a Mission Client.
      </li>
      <li>
        Regardless of any changes in role, responsibilities, compensation or otherwise, including cessation of the
        Contractor’s engagement with Mission (regardless of how that cessation occurs), the Contractor shall continue to
        be subject to the terms and conditions of this CIOPP and any other(s) executed pursuant to paragraph 10 above.
      </li>
      <li>
        The Contractor’s sole and exclusive remedy for any breach of this CIOPP by Mission is limited to monetary
        damages, and the Contractor shall not make any claim in respect of any rights to or interest in any Confidential
        Information or Proprietary Property. The Contractor hereby waives, relinquishes and conveys to Mission any and
        all claims of any nature whatsoever, which the Contractor now or hereafter has for infringement of any
        proprietary rights assigned to Mission. The Contractor acknowledges that it would be difficult to compute the
        monetary loss to Mission arising from a breach or threatened breach of this CIOPP by the Contractor and that,
        accordingly, Mission shall be entitled to specific performance, injunctive or other equitable relief in addition
        to or instead of monetary damages, without the necessity of establishing that monetary damages would be
        inadequate.
      </li>
      <li>
        The Contractor’s engagement with Mission is subject to the terms and conditions of this CIOPP. This CIOPP shall
        enure to the benefit of Mission and its successors and assigns and be binding on the Contractor and the
        Contractor’s heirs, attorneys, guardians, estate trustees, executors, trustees and permitted assigns.
      </li>
      <li>
        This CIOPP shall be interpreted and construed in accordance with the laws of the Province of Quebec and the laws
        of Canada applicable therein without regard to the conflict of laws rules applicable therein. The Parties attorn
        to the exclusive jurisdiction of the courts of the Province of Quebec with respect to any dispute arising from
        this CIOPP.
      </li>
      <li>
        If any provision of this CIOPP is held by a court of competent jurisdiction to be invalid or unenforceable, that
        provision is to be deleted and the other provisions remain in effect and are valid and enforceable to the
        fullest extent permitted by law.
      </li>
      <li>
        The Contractor and Mission each intend and agree that in this CIOPP, the term “engagement” shall be deemed to
        include any period of time prior to the Contractor’s execution of this CIOPP and/or prior to the formal
        execution of any independent contractor agreement or contract for services relating to the engagement, during
        which period of time and in connection with or in contemplation of such engagement, the Contractor provided
        services to or performed work of any kind for the Company Group or for the benefit of the Company Group.
      </li>
      <li>
        The Contractor confirms that he or she had the opportunity to confer with an independent legal advisor if he or
        she so wished, in advance of signing this CIOPP. The Contractor further confirms that he or she has read this
        CIOPP and the Contractor accepts and agrees to be bound by its terms.
      </li>
      <li>
        The headings herein are for convenience only and do not interpret this CIOPP, the words “including” or
        “include”, when following any general statement or term, is not to be construed as limiting the general
        statement or term to the specific items or matters set forth or to similar items or matters, but rather as
        permitting the general statement or term to refer to all other items or matters that could reasonably fall
        within its broadest possible scope, and the word “or” does not imply an exclusive relationship between the
        matters being connected.
      </li>
      <li>
        The Contractor hereby authorizes Mission to notify the Contractor’s future employers (or other necessary third
        parties) of the terms of this CIOPP and the Contractor’s responsibilities hereunder.
      </li>
      <li>
        In the case where the Contractor is an entity other than an individual, the Contractor is responsible for
        ensuring that its directors, officers, shareholders, employees, agents, advisors, consultants and contractors
        (collectively, the “<b>Representatives</b>”) comply with all terms in this CIOPP. The Contractor represents and
        warrants that it owns all rights and title to all Proprietary Property generated by the Representatives, that
        the Contractor will take all necessary steps to require and cause the Representatives to execute assignments of
        all such Proprietary Property to the Contractor and to unconditionally waive in writing any moral rights that
        they may have in such Proprietary Property. The Contractor shall only disclose the Proprietary Property or
        Confidential Information to its Representatives on a need-to-know basis in connection with the delivery or
        performance of the terms of the master independent contractor agreement between Mission and the Contractor. The
        Contractor shall ensure that its Representatives are aware of and comply with the Contractor’s obligations under
        this CIOPP. Without limitation, in the case of disclosure of Confidential Information or Proprietary Property to
        any of the Representatives, such Representatives shall be informed at the time of disclosure of the confidential
        and proprietary nature of the Confidential Information and Proprietary Property and the Contractor shall ensure
        that such Representatives are under confidentiality obligations in favour of Mission that are substantially
        similar to the ones contained in this CIOPP. The Contractor fully indemnifies and will save harmless Mission
        from any damages, claims, demands, losses, liabilities and costs, including legal costs, incurred by the Company
        Group in respect of any breach of the Contractor’s obligations pursuant to this paragraph 20.
      </li>
      <li>
        This CIOPP, and the agreements and other documents required to be delivered pursuant to this CIOPP, constitute
        the entire agreement between Mission and the Contractor and set out all the covenants, promises, warranties,
        representations, conditions and agreements between Mission and the Contractor in connection with the subject
        matter of this CIOPP and supersede all prior agreements, understandings, negotiations and discussions with
        respect to the subject matter hereof, whether oral or written, pre-contractual or otherwise.
      </li>
      <li>
        The parties hereto have expressly required that this agreement and documents ancillary hereto be drafted in the
        English language.
        <i>
          {" "}
          Les parties à la présente ont expressément exigé que le présent accord et les documents afférents soient
          rédigés en langue anglaise.
        </i>
      </li>
    </ul>
    <br />
    <br />
  </>
);

const styles = {
  small: css`
    color: ${colors.grayAnatomyLight1};
    font-size: ${fontSize.small};
  `,
  centeredText: css`
    display: flex;
    justify-content: center;
  `,
};

export default TermsAndConditionsContent;
