import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useProfile, useAuth } from "hooks";
import DisplayBox from "components/DisplayBox";
import RenderIconWithText, { RENDER_SIZE } from "components/DisplayBox/Renderers/RenderIconWithText";
import { ICON_SIZE, ICON_TYPE, StatusIcon } from "components/Icon";
import { colors, fontSize, margins } from "style";
import { MEMBER_STATUS, VETTING_STEPS } from "constants/index";
import { formatDate } from "utils";
import {
  CandidateSubmitButton,
  CandidateApproveButton,
  CandidateRejectButton,
  CandidateAssignmentSelector,
} from "components/Candidate";
import { Col, Row } from "components/Containers";
import { ProfileStatusUpdateButton } from "components/Profile";
import { Toggle } from "components/Form";
import { TOGGLE_SIZE } from "components/Form/Toggle";
import Tooltip from "components/Tooltip";

/**
 * CandidateProgress
 *
 * @param {String}     profileId
 * @param {Boolean}    isAdmin
 * @param {Boolean}    isActiveMember
 * @param {Boolean}    isArchived
 */
const CandidateProgress = ({ profileId, isAdmin, isActiveMember, isArchived, ...props }) => {
  const { profileId: authProfileId, isRecruiter } = useAuth();
  const showApproveRejectButtons = isAdmin && !isActiveMember && !isArchived;
  const showStatusUpdateButton = isAdmin && !isActiveMember && !isArchived;

  const {
    isProfileComplete,
    isCodeChallengeComplete,
    isInterviewComplete,
    vettingConfiguration,
    updateProfile,
    data: profileData,
    loading: profileDataLoading,
  } = useProfile({ profileId });

  const updateVettingConfiguration = (step, value) => {
    const newVettingConfiguration = vettingConfiguration.map((config) => ({
      name: config.name,
      required: config.name === step ? value : config.required,
    }));

    updateProfile(profileId, { vettingConfiguration: newVettingConfiguration });
  };

  return (
    <Col gap="1rem">
      <DisplayBox
        title={<FormattedMessage id="Profile.CandidateProgress.Title" />}
        numColumns={1}
        rowGap={0}
        marginSize={margins.none}
        data={vettingConfigurationData({
          vettingConfiguration,
          isAdmin,
          isActiveMember,
          isProfileComplete,
          isCodeChallengeComplete,
          isInterviewComplete,
          canUpdateVetting: isAdmin || isRecruiter,
          updateVettingConfiguration,
        })}
        itemRenderer={({ isComplete, ...text }) => (
          <RenderIconWithText
            size={RENDER_SIZE.small}
            icon={
              <StatusIcon
                type={ICON_TYPE.line}
                size={ICON_SIZE.large}
                color={colors.grayAnatomyLight2}
                isComplete={isComplete}
              />
            }
            text={text}
          />
        )}
        {...props}
      />
      {authProfileId === profileId && <CandidateSubmitButton />}
      {showApproveRejectButtons && (
        <>
          <CandidateApproveButton profileId={profileId} />
          <CandidateRejectButton profileId={profileId} />
          {profileData?.profile?.eligibleForVetting && (
            <CandidateAssignmentSelector profileData={profileData} profileDataLoading={profileDataLoading} />
          )}
        </>
      )}
      {isAdmin && isActiveMember && (
        <div css={styles.approved}>
          <FormattedMessage
            id="Candidate.CandidateProgress.ApprovedDetails"
            values={{
              name: profileData?.profile?.approvedBy?.name,
              date: formatDate(profileData?.profile?.memberSince, "MMMM Do, YYYY"),
            }}
          />
        </div>
      )}
      {showStatusUpdateButton && <ProfileStatusUpdateButton profile={profileData.profile} />}
    </Col>
  );
};

const vettingStepCompleted = ({ step, isProfileComplete, isCodeChallengeComplete, isInterviewComplete }) => {
  switch (step) {
    case VETTING_STEPS.codeChallenge:
      return isCodeChallengeComplete;
    case VETTING_STEPS.videoInterview:
      return isInterviewComplete;
    case VETTING_STEPS.profileCompletion:
      return isProfileComplete;
    default:
      return false;
  }
};

const vettingConfigurationData = ({
  vettingConfiguration,
  isAdmin,
  isActiveMember,
  canUpdateVetting,
  updateVettingConfiguration,
  ...props
}) => {
  const [data, setData] = useState(vettingConfiguration);

  useEffect(() => {
    setData(vettingConfiguration);
  }, [vettingConfiguration]);

  const handleUpdate = (name, value, index) => {
    setData(data.map((item, i) => (i === index ? { ...item, required: value } : item)));
    updateVettingConfiguration(name, value);
  };

  return data?.map((config, index) => ({
    title: (
      <Row>
        <span css={styles.label(!canUpdateVetting || config.required)}>
          <FormattedMessage id={`Candidate.CandidateProgress.Step.${config.name}.Title${isAdmin ? `Admin` : ``}`} />
        </span>
        {!config.required && !canUpdateVetting && (
          <span css={styles.optional}>
            <FormattedMessage id={`Global.Optional`} />
          </span>
        )}
      </Row>
    ),
    isRequired: config.required,
    isComplete: vettingStepCompleted({ step: config.name, ...props }),
    details: canUpdateVetting && (
      <Tooltip
        tooltipId={`Candidate.CandidateProgress.ToggleTooltip${config.required ? `Disable` : `Enable`}${
          isActiveMember ? `ReadOnly` : ``
        }`}
        hideIcon
      >
        <Toggle
          name={config.name}
          onChange={(n, v) => handleUpdate(n, v, index)}
          value={config.required}
          size={TOGGLE_SIZE.small}
          timeOut={0}
          disabled={isActiveMember}
        />
      </Tooltip>
    ),
  }));
};

const useRender = (profileId) => {
  const { data, loading, error } = useProfile({ profileId });

  if (loading || error) return false;

  return ![MEMBER_STATUS.archived].includes(data?.profile?.status);
};

const styles = {
  label: (required) => css`
    font-weight: ${required ? `500` : `400`};
    color: ${required ? colors.purpleRainDark2 : colors.grayAnatomyLight1};
  `,
  approved: css`
    margin-top: 1rem;
    background: ${colors.lightGreen};
    color: ${colors.darkGreen};
    font-size: ${fontSize.xxsmall};
    text-align: center;
    padding: 1rem;
    border-radius: 0.6rem;
  `,
  optional: css`
    font-size: ${fontSize.xxsmall};
    color: ${colors.grayAnatomyLight1};
    margin-left: auto;
    font-weight: 400;
  `,
};

CandidateProgress.propTypes = {
  profileId: PropTypes.string,
  isAdmin: PropTypes.bool,
  isActiveMember: PropTypes.bool,
  isArchived: PropTypes.bool,
};

export { useRender };

export default CandidateProgress;
