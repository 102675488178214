import { gql } from "@apollo/client";
import {
  DISCIPLINE_FIELDS,
  BADGES_FIELDS,
  SKILLS_FIELDS,
  LOCATION_FIELDS,
  PROFILE_AVAILABILITY_FIELDS,
  RATE_FIELDS,
  PROFILE_ID_FIELDS,
  PROFILE_BASIC_FIELDS,
  WORK_EXPERIENCES_FIELDS,
} from "graphql/fragments";

const GET_LIST = gql`
  ${DISCIPLINE_FIELDS}
  ${SKILLS_FIELDS}
  ${LOCATION_FIELDS}
  ${WORK_EXPERIENCES_FIELDS}
  ${RATE_FIELDS}
  ${PROFILE_ID_FIELDS}
  ${PROFILE_BASIC_FIELDS}
  ${PROFILE_AVAILABILITY_FIELDS}
  ${BADGES_FIELDS}
  query list(
    $paginationInfo: PaginationInfo
    $filters: ListFilter
    $includeUser: Boolean = false
    $includeProfiles: Boolean = true
    $includeProfileIds: Boolean = true
    $includeBadgesFields: Boolean = true
    $includeProfileBasicFields: Boolean = true
    $includeProfileAvailabilityFields: Boolean = true
    $includeDisciplineFields: Boolean = true
    $includeSkillsFields: Boolean = true
    $includeLocationFields: Boolean = true
    $includeRateFields: Boolean = true
    $includeWorkExperiencesFields: Boolean = true
    $includeProfileList: Boolean = true
  ) {
    list(filters: $filters, paginationInfo: $paginationInfo) {
      nodes {
        id
        title
        type
        slug
        createdAt
        profileLists @include(if: $includeProfileList) {
          profile {
            id
          }
          id
          negotiatedRate
          note
        }
        user @include(if: $includeUser) {
          id
          name
          uuid
          role
        }
        profiles @include(if: $includeProfiles) {
          ...ProfileIdFields @include(if: $includeProfileIds)
          ...BadgesFields @include(if: $includeBadgesFields)
          ...ProfileBasicFields @include(if: $includeProfileBasicFields)
          ...ProfileAvailabilityFields @include(if: $includeProfileAvailabilityFields)
          ...DisciplineFields @include(if: $includeDisciplineFields)
          ...SkillsFields @include(if: $includeSkillsFields)
          ...LocationFields @include(if: $includeLocationFields)
          ...RateFields @include(if: $includeRateFields)
          ...WorkExperiencesFields @include(if: $includeWorkExperiencesFields)
        }
      }
      totalCount
      currentPage
      currentOffset
      perPage
    }
  }
`;

const GET_PRICE_ESTIMATION = gql`
  query listPriceEstimation($filters: PriceEstimationFilter, $listId: ID!) {
    listPriceEstimatation(filters: $filters, listId: $listId) {
      sellingPriceEstimate {
        averageExperienceYears
        blendedHourlyRate
        breakdown
        currency
        missingProfiles
        monthlyPrice {
          low
          high
        }
        topSkills
      }
    }
  }
`;

const PUBLIC_LIST = gql`
  ${DISCIPLINE_FIELDS}
  ${SKILLS_FIELDS}
  ${LOCATION_FIELDS}
  ${WORK_EXPERIENCES_FIELDS}
  ${PROFILE_ID_FIELDS}
  ${PROFILE_BASIC_FIELDS}
  ${PROFILE_AVAILABILITY_FIELDS}
  query publicList($id: ID!, $paginationInfo: PaginationInfo) {
    publicList(id: $id) {
      slug
      title
      profiles(paginationInfo: $paginationInfo) {
        currentPage
        currentOffset
        perPage
        totalCount
        nodes {
          ...ProfileIdFields
          ...ProfileBasicFields
          ...ProfileAvailabilityFields
          ...DisciplineFields
          ...SkillsFields
          ...LocationFields
          ...WorkExperiencesFields
          sellingPriceEstimate
          profileList(listId: $id) {
            id
            negotiatedRate
            negotiatedRateCurrency
            note
          }
        }
      }
    }
  }
`;

const UPDATE_LIST = gql`
  mutation updateList($input: UpdateListInput!) {
    updateList(input: $input) {
      list {
        id
        title
        type
        slug
        createdAt
        user {
          id
          name
        }
      }
    }
  }
`;

export { GET_LIST, GET_PRICE_ESTIMATION, UPDATE_LIST, PUBLIC_LIST };
