/**
 * Forces input field to return a string with numeric values only and one single comma or colon
 *
 * @param {string} str
 *
 */
export default function restrictTimeSlipInput(str) {
  if (!str) {
    return;
  }

  str = str
    .replace(",", ".") // Replaces every comma with a dot
    .replace(/[^\d.:]/g, "") // Removes every non numeric char except dots and colon
    .replace(/\./, "x") // Replaces first dot with an x
    .replace(/\./g, "") // Removes every dot
    .replace(/x/, ".") // Replaces x char with a dot
    .replace(/:/, "x") // Replaces first colon char with an x
    .replace(/:/g, "") // Removes rest of colon chars
    .replace(/x/, ":"); // Replaces unique x char with a colon

  return str;
}
