import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { Sparklines, SparklinesBars, SparklinesCurve, SparklinesLine } from "react-sparklines";
import { fontSize, colors } from "style";

/**
 * SparkLine
 *
 * @param {Object} data
 * @param {String} type
 * @param {Boolean} loading
 * @param {Number} strokeWidth
 * @param {String} fill
 */
const SparkLine = ({ data, type, loading, strokeWidth, fill, ...props }) => {
  const lineProps = {
    color: colors.purpleRainBase,
    style: {
      strokeWidth,
      fill,
    },
  };

  if (loading) {
    return <LoadingSparkline data={[50, 75, 50, 75, 50, 75, 50]} {...props} />;
  } else if (!data) {
    return <ErrorMessage />;
  }

  return (
    <div css={styles.container}>
      <Sparklines data={data} {...props}>
        {type === SPARKLINE_TYPES.curve ? (
          <SparklinesCurve {...lineProps} />
        ) : type === SPARKLINE_TYPES.bars ? (
          <SparklinesBars {...lineProps} />
        ) : (
          <SparklinesLine {...lineProps} />
        )}
      </Sparklines>
    </div>
  );
};

const SPARKLINE_TYPES = {
  line: "line",
  curve: "curve",
  bars: "bars",
};

const LoadingSparkline = ({ data, ...props }) => (
  <div css={styles.container}>
    <Sparklines data={data} {...props}>
      <SparklinesLine
        color={colors.grayAnatomyLight2}
        style={{
          strokeWidth: 0.25,
        }}
      />
    </Sparklines>
  </div>
);

const ErrorMessage = () => (
  <div css={styles.empty}>
    <FormattedMessage id="Charts.SparkLine.Error" />
  </div>
);

const styles = {
  container: css`
    position: relative;
  `,
  overlay: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: ${fontSize.medium};
    color: ${colors.purpleRainDark2};
  `,
};

SparkLine.propTypes = {
  data: PropTypes.array,
  type: PropTypes.oneOf(Object.values(SPARKLINE_TYPES)),
  loading: PropTypes.bool,
  strokeWidth: PropTypes.number,
  fill: PropTypes.string,
};

LoadingSparkline.propTypes = {
  data: PropTypes.array,
};

SparkLine.defaultProps = {
  type: SPARKLINE_TYPES.curve,
  strokeWidth: 1,
  fill: colors.purpleRainLight6,
};

export { SPARKLINE_TYPES };

export default SparkLine;
