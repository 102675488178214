import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import { StyledTag } from "components/Tags";
import { formatNumber } from "utils";

/**
 * TagTicker
 *
 * @params {Number}   trend
 */
const TagTicker = ({ trend, ...props }) => {
  if (trend === null) return null;

  return (
    <StyledTag css={styles.container(trend < 0)} {...props}>
      {`${trend > 0 ? `+` : ``}${formatNumber(trend, '%')}`}
    </StyledTag>
  );
}

const styles = {
  container: isNegative => css`
    background: ${isNegative ? colors.red : colors.green};
    font-weight: 500;
    padding: 0.1rem 0.5rem 0 0.5rem;
    border-radius: 1.4rem;
    min-width: 5.5rem;
    height: 2rem;
    text-align: center;
    justify-content: center;
    font-size: ${fontSize.xxsmall};
  `,
};

TagTicker.propTypes = {
  trend: PropTypes.number.isRequired,
};

export default TagTicker;
