import { gql } from "@apollo/client";
import { EXTENDED_MISSION_FIELDS, CORE_ASSESSMENT_FIELDS, CORE_MISSION_ROLE_FIELDS } from "graphql/fragments";

const GET_MISSION = gql`
  ${EXTENDED_MISSION_FIELDS}
  ${CORE_MISSION_ROLE_FIELDS}
  ${CORE_ASSESSMENT_FIELDS}
  query GetMission($id: ID!) {
    mission(id: $id) {
      ...ExtendedMissionFields
      roles {
        ...MissionRoleFields
        missionRoleApplicant {
          id
          appliedAt
        }
      }
      assessments {
        ...CoreAssessmentFields
      }
    }
  }
`;

export { GET_MISSION };
