import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useToast } from "hooks";
import { Select } from "components/Form";
import { GET_ADMINS } from "graphql/queries";
import { UPDATE_PROFILE_ASSIGNEE } from "graphql/mutations";

/**
 * CandidateAssignmentSelector
 *
 * @param {Object}    profileData
 * @param {Boolean}   profileDataLoading
 */
const CandidateAssignmentSelector = ({ profileData, profileDataLoading }) => {
  const { toast } = useToast();
  const [assignee, { loading: saving }] = useMutation(UPDATE_PROFILE_ASSIGNEE);
  const [selection, setSelection] = useState(profileData?.profile?.assignedTo?.id);

  useEffect(() => {
    const assignedToId = profileData?.profile?.assignedTo?.id;

    setSelection(assignedToId);
  }, [profileData?.profile?.assignedTo?.id]);

  useEffect(() => {
    if (saving) return;

    const assignedToId = profileData?.profile?.assignedTo?.id;
    const profileId = profileData?.profile?.id;

    if (assignedToId != selection) {
      assignee({
        variables: {
          input: {
            profileId,
            assignedToId: selection,
          },
        },
        onCompleted: () => toast.success(<FormattedMessage id="Candidate.CandidateAssignmentSelector.Success" />),
        onError: () => toast.error(<FormattedMessage id="Candidate.CandidateAssignmentSelector.Error" />),
      });
    }
  }, [selection, profileData?.profile, saving]);

  return (
    <Select
      queryKeyName={"admins"}
      queryName={GET_ADMINS}
      queryVariables={{ id: "admins" }}
      placeholder="Unassigned"
      labelKey="name"
      valueKey="id"
      onChange={(name, val) => setSelection(val)}
      value={selection}
      loading={saving || profileDataLoading}
      isClearable
      singleValuePrefix={<FormattedMessage id={"Form.Select.SingleValuePrefix"} />}
      disabled={profileDataLoading || saving}
    />
  );
};

CandidateAssignmentSelector.propTypes = {
  profileData: PropTypes.object,
  profileDataLoading: PropTypes.bool,
};

export default CandidateAssignmentSelector;
