import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { DataTableProvider } from "components/DataTable";
import { usePrompt } from "hooks";
import Status from "components/Status";
import { PromptForm } from "components/Prompts";
import { shortenText } from "utils";
import { GET_PROMPTS } from "graphql/queries";
import { ICON_TYPE } from "components/Icon";

/**
 * PromptsList
 *
 * @param {Number}    resultsPerPage
 */
const PromptsList = ({ resultsPerPage }) => {
  const { handleRemovePrompt, paginationType } = usePrompt();
  const [showForm, setShowForm] = useState(false);
  const [selected, setSelected] = useState({});
  const datatable = useRef(null);

  const handleUpdate = (payload) => {
    const isActive = payload?.updatePrompt?.prompt?.isActive;
    const wasActive = selected?.isActive;

    if (isActive !== wasActive) {
      datatable.current.refetch();
    }
  };

  const rowOptions = (row) => [
    {
      label: <FormattedMessage id="Prompt.PromptAdvancedOptions.Edit" />,
      icon: ICON_TYPE.edit,
      onClick: () => {
        setSelected(row);
        setShowForm(true);
      },
    },
    {
      label: <FormattedMessage id="Prompt.PromptAdvancedOptions.Remove" />,
      icon: ICON_TYPE.trash,
      onClick: () => handleRemovePrompt(row.id),
    },
  ];

  return (
    <>
      <DataTableProvider
        ref={datatable}
        columns={COLUMN_DATA}
        resultsPerPage={resultsPerPage}
        keyName={"prompts"}
        queryName={GET_PROMPTS}
        paginationType={paginationType}
        selectable={false}
        rowOptions={rowOptions}
        paginationProps={{
          countMessage: "Global.Prompts.Count",
        }}
      />
      <PromptForm
        show={showForm}
        onClose={() => setShowForm(false)}
        isEditing={true}
        data={selected}
        onComplete={handleUpdate}
      />
    </>
  );
};

const COLUMN_DATA = [
  {
    label: "Title",
    cell: (row) => row?.title,
    width: 150,
  },
  {
    label: "Content",
    cell: (row) => shortenText(row?.content, 100),
    width: 150,
  },
  {
    label: "Status",
    width: 150,
    cell: ({ isActive }) => (
      <div>
        <Status enabled={isActive}>
          <FormattedMessage id={`Prompt.PromptList.${isActive ? "Active" : "InActive"}`} />
        </Status>
      </div>
    ),
  },
];

PromptsList.defaultProps = {
  resultsPerPage: 10,
};

PromptsList.propTypes = {
  resultsPerPage: PropTypes.number,
};

export default PromptsList;
