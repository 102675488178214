import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Outlet, useLocation } from "react-router-dom";
import Image from "components/Image";
import MissionLogo from "assets/images/gradient-mission-logo.png";
import { breakpoints, colors, gradients } from "style";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { useAuth } from "hooks";
import { ShowcaseVideo } from "components/Tutorials";
import { ShowcaseInviteButton } from "components/Showcase";
import { MenuItemAccount, MenuItemFaq, MenuItemsByUserType } from "./MenuItems";
import SideNavLink from "components/Nav/SideNav/SideNavLink";
import SideNavTooltip from "components/Nav/SideNav/SideNavTooltip";
import SideNavExpander from "components/Nav/SideNav/SideNavExpander";
import Impersonating from "components/Impersonating";

/**
 * Side navigation
 */
const SideNav = (props) => {
  const { isShowcase } = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();

  /**
   * @description Close the menu on route change
   */
  useEffect(() => {
    setShowMenu(false);
  }, [pathname]);

  return (
    <>
      <Nav style={{ "--mobile-nav-display": showMenu ? "flex" : "none" }} {...props}>
        <div>
          <LogoContainer>
            <Logo url={MissionLogo} alt="Mission Logo" />
          </LogoContainer>
        </div>
        <MenuItemsByUserType />
        <Spacer />
        <StyledShowcaseButton isShowcase={isShowcase} />
        <ShowcaseVideo isShowcase={isShowcase} />
        <MenuItemFaq />
        <MenuItemAccount />
        <MobileCloseMenu onClick={() => setShowMenu(false)}>
          <Icon type={ICON_TYPE.close} size={ICON_SIZE.xlarge} color={"inherit"} />
        </MobileCloseMenu>
      </Nav>
      <MobileNav>
        <MobileMenu>
          <Icon
            type={ICON_TYPE.listLines}
            size={ICON_SIZE.xlarge}
            color={"inherit"}
            onClick={() => setShowMenu(!showMenu)}
          />
        </MobileMenu>
        <div>
          <Logo url={MissionLogo} alt="Mission Logo" />
        </div>
      </MobileNav>
      <ChildrenContainer>
        <Outlet />
      </ChildrenContainer>
      <Impersonating />
    </>
  );
};

const NAV_WIDTH = `25rem`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const StyledShowcaseButton = styled(ShowcaseInviteButton)`
  margin-bottom: 2rem;
`;

const LogoContainer = styled.div`
  margin-bottom: 2rem;
  margin-top: 1rem;
  width: 5rem;
  height: 5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
`;

const Logo = styled(Image)`
  width: 3rem;
`;

const Nav = styled.nav`
  width: ${NAV_WIDTH};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: ${gradients.sideBarGradient};
  padding: 3rem;
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  gap: 0rem;
  overflow-y: auto;
  z-index: 11;

  @media (max-width: ${breakpoints.tablet}) {
    display: var(--mobile-nav-display, "none");
    top: 0;
    left: 0;
    width: 100%;
    max-width: 45rem;
  }
`;

const MobileNav = styled.nav`
  display: none;
  width: 100%;
  background: ${gradients.sideBarGradient};
  padding: 1rem 3rem;
  height: 5rem;
  position: sticky;
  gap: 1rem;
  color: ${colors.white};
  top: 0;
  z-index: 10;

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;
    padding: 0 2rem;
  }
`;

const MobileMenu = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MobileCloseMenu = styled.nav`
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: none;

  @media (max-width: ${breakpoints.tablet}) {
    display: block;
  }
`;

const ChildrenContainer = styled.div`
  margin-left: ${NAV_WIDTH};

  @media (max-width: ${breakpoints.tablet}) {
    margin-left: 0;
  }
`;

export { SideNavLink, SideNavTooltip, SideNavExpander, NAV_WIDTH };

export default SideNav;
