import { gql } from "@apollo/client";

const teamMetricsCoreFields = `
  score
  scoreDelta
  scoreDeltaPercent
  sparkline
  week
  weekDate
  scoreBreakdown {
    metric
    score
    weight
  }
`;

const TEAM_METRICS_CORE_FIELDS = gql`
  fragment TeamMetricsFields on EngineeringMetricsScore {
    ${teamMetricsCoreFields}
  }
`;

export { TEAM_METRICS_CORE_FIELDS };
