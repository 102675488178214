import { gql } from "@apollo/client";
import { ASSESSMENT_FIELDS, QUESTION_FIELDS } from "graphql/fragments";

const GET_MISSION_REQUIREMENTS = gql`
  ${QUESTION_FIELDS}
  ${ASSESSMENT_FIELDS}
  query assessmentFetch($id: ID!, $allowedId: ID = null, $resourceType: String, $resourceId: String) {
    assessmentFetch(id: $id, allowedId: $allowedId) {
      ...AssessmentFields
      answers(resourceType: $resourceType, resourceId: $resourceId)
      questions(resourceType: $resourceType, resourceId: $resourceId) {
        ...QuestionFields
        children {
          ...QuestionFields
        }
      }
    }
  }
`;

export { GET_MISSION_REQUIREMENTS };
