import { gql } from "@apollo/client";

const GET_PLATFORM_ACCESS_USERS = gql`
  query platformAccessUsers(
    $filters: UserFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
    $paginationInfo: PaginationInfo
  ) {
    platformAccessUsers(
      filters: $filters
      first: $first
      last: $last
      after: $after
      before: $before
      paginationInfo: $paginationInfo
    ) {
      totalCount
      currentPage
      perPage
      nodes {
        id
        uuid
        firstName
        lastName
        name
        email
        signInCount
        partner
        deactivated
        organization {
          name
          id
        }
        linkedPartner {
          name
          id
        }
        role
        referrer
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export { GET_PLATFORM_ACCESS_USERS };
