import { colors, fontSize } from "style";
import styled from "@emotion/styled";

/**
 * Status
 */
const Status = styled.span`
  font-weight: 500;
  font-size: ${fontSize.xsmall};
  color: ${(props) => (props.enabled ? colors.darkGreen : colors.darkRed)};
  background: ${(props) => (props.enabled ? colors.lightGreen : colors.lightRed)};
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  white-space: nowrap;
  
  &:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: -0.1rem;
    margin-right: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    background: ${(props) => (props.enabled ? colors.green : colors.error)};
    border-radius: 100%;
    font-weight: 500;
  }
`;

export default Status;
