import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import DisplayBox from "components/DisplayBox";
import Tags from "components/Tags";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { SIZE, TYPE } from "components/Form";
import { UPDATE_PROFILE } from "graphql/mutations";
import { GET_SKILLS } from "graphql/queries";
import { PAGES } from "constants/index";

/**
 * ProfileSkills
 *
 * @params {Array}    data
 */
const ProfileSkills = ({ data, ...props }) => {
  return (
    <DisplayBox
      title={<FormattedMessage id="Profile.ProfileBio.TitleSkills" />}
      editButtonText={<FormattedMessage id="Profile.ProfileBio.AddSkills" />}
      numColumns={1}
      data={SKILLS_DATA(data)}
      parentKeyName="id"
      parentKeyValue={data?.id}
      updateId={data?.id}
      initialValues={{
        skills: data?.skills?.map((item) => ({
          name: item.name,
          id: item.id,
        })),
      }}
      {...props}
    />
  );
};

const SKILLS_DATA = (data) => [
  {
    title: null,
    body: data?.skills?.length > 0 && <Tags data={data?.skills} />,
  },
];

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Skills",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "skills",
          placeholder: "Type a skill...",
          queryName: GET_SKILLS,
          queryKeyName: "skills",
          autoFocus: true,
          required: true,
          minLength: 1,
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Profile.ProfileSkills.FormTitle" />,
  description: <FormattedMessage id="Profile.ProfileSkills.FormDescription" />,
  mutationData: {
    mutationName: {
      add: UPDATE_PROFILE,
      update: UPDATE_PROFILE,
    },
  },
  page: PAGES.topSkills,
};

ProfileSkills.propTypes = {
  data: PropTypes.object,
};

export { FORM_SETTINGS };

export default withModalForm(ProfileSkills, FORM_SETTINGS);
