/**
 * removeTags - Function to remove HTML tags from a string
 *
 * @param {String} str
 * @returns {String}
 */
export default function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  return str.replace(/(<([^>]+)>)/gi, "");
}
