import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { colors } from "style";
import { UserPhoto } from "components/User";
import { formatNumber } from "utils";

/**
 * Shows a list with X thumbs on each row
 *
 * @param {Array}      users
 * @param {Number}     thumbCount
 * @param {Number}     thumbLimit
 * @param {String}     thumbSize
 * @param {Boolean}    loading
 * @param {Function}   onThumbClick
 */
const UserPhotoList = ({ users, thumbCount, thumbLimit, thumbSize, loading, onThumbClick }) => {
  const iconCount = thumbCount > thumbLimit ? thumbLimit : thumbCount;
  const showCount = thumbCount > thumbLimit;
  const totalCount = thumbCount - thumbLimit;

  if (loading) {
    return <Placeholder loading={loading} thumbSize={thumbSize} thumbCount={thumbCount} />;
  }

  if (!users || users.length === 0) {
    return null;
  }

  return (
    <div css={styles.users_container(thumbSize, iconCount + (showCount ? 1 : 0))}>
      {showCount && <div css={styles.count(thumbSize)}>+{formatNumber(totalCount)}</div>}
      {users
        ?.slice(0, thumbLimit)
        ?.reverse()
        ?.map((user, i) => (
          <div
            onClick={() => onThumbClick(user)}
            css={styles.user_photo_container(showCount ? i + 1 : i, thumbSize)}
            key={user?.id || i}
          >
            <UserPhoto data={user} width={thumbSize} height={thumbSize} />
          </div>
        ))}
    </div>
  );
};

/**
 * Placeholder
 *
 * @param {Boolean} loading
 * @param {String} thumbSize
 * @param {Number} thumbCount
 */
const Placeholder = ({ loading, thumbSize, thumbCount }) => (
  <div css={styles.users_container(thumbSize, thumbCount)}>
    {[0, 1, 3]?.map((x, y) => (
      <div key={x} css={styles.user_photo_container(y, thumbSize)}>
        <UserPhoto loading={loading} width={thumbSize} height={thumbSize} />
      </div>
    ))}
  </div>
);

const styles = {
  users_container: (thumbSize, count) => css`
    display: flex;
    position: relative;
    justify-content: center;
    font-size: ${thumbSize};
    width: calc((${count} * ${thumbSize}) - (${count} * (${thumbSize} / 4)) + (${thumbSize} / 4));
    height: ${thumbSize};

    img,
    .initials {
      border-radius: 100%;
      border: 2px #fff solid;
    }
  `,
  user_photo_container: (index, thumbSize) => css`
    border-radius: 100%;
    right: calc((${thumbSize} * ${index}) - (${index} * (${thumbSize} / 4)));
    overflow: hidden;
    display: block;
    cursor: pointer;
    position: absolute;
  `,
  count: (thumbSize) => css`
    width: ${thumbSize};
    height: ${thumbSize};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.3em;
    font-weight: 500;
    line-height: 1;
    background-color: ${colors.grayAnatomyLight4};
    color: ${colors.grayAnatomyLight1};
    box-sizing: border-box;
    border: 2px #fff solid;
    position: absolute;
    right: 0;
  `,
};

Placeholder.propTypes = {
  thumbSize: PropTypes.string,
  loading: PropTypes.bool,
  thumbCount: PropTypes.number,
};

UserPhotoList.defaultProps = {
  thumbSize: "4rem",
  thumbCount: 3,
};

UserPhotoList.propTypes = {
  users: PropTypes.array,
  thumbCount: PropTypes.number,
  thumbLimit: PropTypes.number,
  thumbSize: PropTypes.string,
  loading: PropTypes.bool,
  onThumbClick: PropTypes.func,
};

UserPhotoList.defaultProps = {
  thumbSize: "4rem",
  thumbLimit: 4,
  onThumbClick: () => {},
};

export default UserPhotoList;
