import React from "react";
import PropTypes from "prop-types";
import { SQUAD_METRIC_TYPES } from "constants/index";
import { MetricsCell } from "components/Dashboard";
import { useTeamMetricsScores } from "hooks";

/**
 * SquadMetrics
 *
 * @param {String}   teamId
 */
const SquadMetricsActivity = ({ teamId, ...props }) => {
  const { data } = useTeamMetricsScores({ teamId });

  return (
    <MetricsCell
      key={SQUAD_METRIC_TYPES.activity}
      category={SQUAD_METRIC_TYPES.activity}
      decimalsUpto={0}
      {...props}
      {...data?.performanceActivity}
    />
  );
};

SquadMetricsActivity.propTypes = {
  teamId: PropTypes.string.isRequired,
};

/**
 * Volume
 *
 * @param {String}   teamId
 */
const SquadMetricsVolume = ({ teamId, ...props }) => {
  const { data } = useTeamMetricsScores({ teamId });

  return (
    <MetricsCell
      key={SQUAD_METRIC_TYPES.volume}
      category={SQUAD_METRIC_TYPES.volume}
      decimalsUpto={0}
      {...props}
      {...data?.performanceVolume}
    />
  );
};

SquadMetricsVolume.propTypes = {
  teamId: PropTypes.string.isRequired,
};

/**
 * Efficiency
 *
 * @param {String}   teamId
 */
const SquadMetricsEfficiency = ({ teamId, ...props }) => {
  const { data } = useTeamMetricsScores({ teamId });

  return (
    <MetricsCell
      key={SQUAD_METRIC_TYPES.efficiency}
      category={SQUAD_METRIC_TYPES.efficiency}
      decimalsUpto={0}
      {...props}
      {...data?.performanceEfficiency}
    />
  );
};

SquadMetricsEfficiency.propTypes = {
  teamId: PropTypes.string.isRequired,
};

/**
 * Pulse
 *
 * @param {String}   teamId
 */
const SquadMetricsPulse = ({ teamId, ...props }) => {
  const { data } = useTeamMetricsScores({ teamId });

  return (
    <MetricsCell
      key={SQUAD_METRIC_TYPES.pulse}
      category={SQUAD_METRIC_TYPES.pulse}
      decimalsUpto={0}
      {...props}
      {...data?.pulse}
    />
  );
};

SquadMetricsPulse.propTypes = {
  teamId: PropTypes.string.isRequired,
};

/**
 * Team Dynamics
 *
 * @param {String}   teamId
 */
const SquadMetricsTeamDynamics = ({ teamId, ...props }) => {
  const { data } = useTeamMetricsScores({ teamId });

  return (
    <MetricsCell
      key={SQUAD_METRIC_TYPES.teamDynamics}
      category={SQUAD_METRIC_TYPES.teamDynamics}
      decimalsUpto={0}
      {...props}
      {...data?.dynamics}
    />
  );
};

SquadMetricsTeamDynamics.propTypes = {
  teamId: PropTypes.string.isRequired,
};

/**
 * Client Satisfaction
 *
 * @param {String}   teamId
 */
const SquadMetricsClientSatisfaction = ({ teamId, ...props }) => {
  const { data } = useTeamMetricsScores({ teamId });

  return (
    <MetricsCell
      key={SQUAD_METRIC_TYPES.clientSatisfaction}
      category={SQUAD_METRIC_TYPES.clientSatisfaction}
      decimalsUpto={0}
      {...props}
      {...data?.clientSatisfaction}
    />
  );
};

SquadMetricsClientSatisfaction.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export {
  SquadMetricsActivity,
  SquadMetricsVolume,
  SquadMetricsEfficiency,
  SquadMetricsPulse,
  SquadMetricsTeamDynamics,
  SquadMetricsClientSatisfaction,
};
