import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { PageWrapper, Grid } from "components/Containers";
import Card from "components/Card";
import { EventsList } from "components/Events";
import { TimeSheetSummary } from "components/Missions/TimeSheets";
import { MissionBoardSummary, MissionContacts } from "components/Missions";
import { ProfileAvailabilityToggle, ProfileSummary } from "components/Profile";
import { LibrarySummary } from "components/Library";
import { SquadsHighlight } from "components/Squads";
import { WelcomeTour } from "components/Tutorials";
import { useAuth } from "hooks";
import { DashboardSummaryCard } from "components/Dashboard";

/**
 * Home
 */
const Home = () => {
  const { profileId } = useAuth();
  const intl = useIntl();

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Home.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <MissionBoardSummary id={profileId} />
        </Grid.col>
        <Grid.col start={1} end={9}>
          <Grid>
            <Grid.col start={1} end={13}>
              <WelcomeTour />
            </Grid.col>
            <Grid.col start={1} end={13}>
              <DashboardSummaryCard profileId={profileId} />
            </Grid.col>
            <Grid.col start={1} end={13}>
              <TimeSheetSummary />
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                <EventsList />
              </Card>
            </Grid.col>
          </Grid>
        </Grid.col>
        <Grid.col start={9} end={13}>
          <Grid>
            <Grid.col start={1} end={13}>
              <Card>
                <ProfileSummary id={profileId} />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <ProfileAvailabilityToggle id={profileId} />
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                <MissionContacts />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                <SquadsHighlight profileId={profileId} title={<FormattedMessage id="Home.MissionsHighlightTitle" />} />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <LibrarySummary />
            </Grid.col>
          </Grid>
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default Home;
