import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { AdvancedButton } from "components/Buttons";
import { ClientFeedbackForm } from "components/Client";
import { ICON_TYPE } from "components/Icon";
import { useAuth, useClient } from "hooks";

/**
 * ClientFeedbackAdvancedOptions
 *
 * @params {String}  updateId
 * @params {Object}  data
 */
const ClientFeedbackAdvancedOptions = ({ updateId, data }) => {
  const { id } = useParams();
  const [showForm, setShowForm] = useState(false);
  const { handleRemoveClientFeedback } = useClient({});
  const { isAdmin } = useAuth();

  if (!isAdmin) return null;

  const editOption = {
    label: <FormattedMessage id={`Client.ClientFeedbackAdvancedOptions.Edit`} />,
    icon: ICON_TYPE.edit,
    onClick: () => setShowForm(true),
  };

  const removeOption = {
    label: <FormattedMessage id={`Client.ClientFeedbackAdvancedOptions.Remove`} />,
    icon: ICON_TYPE.trash,
    onClick: () => handleRemoveClientFeedback(updateId),
  };

  return (
    <>
      <AdvancedButton options={[editOption, removeOption]} />
      <ClientFeedbackForm
        show={showForm}
        onClose={() => setShowForm(false)}
        updateId={updateId}
        isEditing={true}
        data={data}
        teamId={id}
      />
    </>
  );
};

ClientFeedbackAdvancedOptions.defaultProps = {
  selection: [],
};

ClientFeedbackAdvancedOptions.propTypes = {
  updateId: PropTypes.string,
  data: PropTypes.object,
  selection: PropTypes.array,
};

export default ClientFeedbackAdvancedOptions;
