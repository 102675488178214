import React from "react";
import { useAuth } from "hooks";
import { Grid, PageWrapper } from "components/Containers";
import { DashboardHeader, DashboardTeamsActivityLogs } from "components/Dashboard";

/**
 * DashboardActivityLogs
 */
const DashboardActivityLogs = () => {
  const { profileId } = useAuth();

  return (
    <PageWrapper>
      <DashboardHeader id={profileId} />
      <Grid topMargin>
        <Grid.col start={1} end={13}>
          <DashboardTeamsActivityLogs resultsPerPage={20} />
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default DashboardActivityLogs;
