import React from "react";
import PropTypes from "prop-types";
import { BADGE_TYPES } from "components/Badges";
import { css } from "@emotion/react";
import { colors } from "style";
import { Row } from "components/Containers";

/**
 * UserBadges
 *
 * @param {Object} badges
 * @param {String} size
 * @param {Number} limit
 */
const UserBadges = ({ badges, size, limit }) => {
  if (!badges || badges.length === 0) return null;

  const remainingBadges = limit ? badges.length - limit : 0;
  const badgesToRender = limit ? badges.slice(0, limit) : badges;

  return (
    <Row gap="0.5rem">
      {badgesToRender.map(({ name }) => (
        <UserBadge key={name} name={name} size={size} />
      ))}
      {remainingBadges > 0 && <div css={styles.count(size)}>+{remainingBadges}</div>}
    </Row>
  );
};

/**
 * UserBadge
 *
 * @param {String} name
 * @param {String} size
 */
const UserBadge = ({ name, size }) => {
  if (!BADGE_TYPES[name]) return null;

  const Component = BADGE_TYPES[name];

  return <Component width={size} height={size} />;
};

const styles = {
  count: (size) => css`
    width: ${size};
    height: ${size};
    display: flex;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    background-color: ${colors.grayAnatomyLight4};
    color: ${colors.purpleRainDark2};
    box-sizing: border-box;
    z-index: 0;
  `,
};

UserBadges.propTypes = {
  badges: PropTypes.array,
  size: PropTypes.string,
  limit: PropTypes.number,
};

UserBadge.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
};

export default UserBadges;
