import { gql } from "@apollo/client";
import { REQUIREMENTS_FIELDS, TERM_SHEET_FIELDS } from "graphql/fragments";

const GET_MISSION_PROPOSAL = gql`
  ${REQUIREMENTS_FIELDS}
  ${TERM_SHEET_FIELDS}
  query GetMissionProposal($id: ID!) {
    missionProposal(id: $id) {
      name
      description
      assessmentDrafted
      assessments {
        ...RequirementsFields
      }
      termSheets {
        ...TermSheetFields
      }
    }
  }
`;

export { GET_MISSION_PROPOSAL };
