import { gql } from "@apollo/client";

const GET_LOCATIONS = gql`
  query locations($filters: LocationFilter) {
    locations(filters: $filters) {
      nodes {
        id
        name
        code
        type
      }
    }
  }
`;

export { GET_LOCATIONS };
