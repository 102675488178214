import { GET_TEAM_SCORE } from "graphql/queries";

const MOCK_GET_TEAM_SCORE = {
  request: {
    query: GET_TEAM_SCORE,
    variables: {
      orderBy: {},
      first: null,
      last: null,
      after: null,
      teamId: "4",
    },
  },
  result: {
    data: {
      teamScoreFetch: {
        week: "2022-49",
        weekDate: "2022-12-05",
        score: 73,
        scoreDelta: -18,
        scoreDeltaPercent: -19.78,
        categoryScores: [
          {
            category: "TEAM_DYNAMICS",
            score: 5,
            scoreDelta: -25,
            scoreDeltaPercent: -83.33,
            sparkline: [74, 31, 18, 96, 91, 61, 24, 2, 72, 2, 5],
            __typename: "TeamCategoryScore",
          },
          {
            category: "ENGINEERING_PERFORMANCE",
            score: 84,
            scoreDelta: 9,
            scoreDeltaPercent: 12,
            sparkline: [51, 21, 36, 80, 91, 26, 64, 53, 84],
            __typename: "TeamCategoryScore",
          },
          {
            category: "PRODUCT_DELIVERY",
            score: 41,
            scoreDelta: -43,
            scoreDeltaPercent: -51.19,
            sparkline: [99, 65, 90, 90, 67, 82, 46, 41],
            __typename: "TeamCategoryScore",
          },
          {
            category: "CLIENT_SATISFACTION",
            score: 54,
            scoreDelta: -32,
            scoreDeltaPercent: -37.21,
            sparkline: [61, 6, 62, 40, 54],
            __typename: "TeamCategoryScore",
          },
        ],
        team: {
          id: "4",
          name: "Administration",
          __typename: "Team",
        },
        sparkline: [
          47, 61, 8, 24, 34, 27, 2, 84, 77, 63, 31, 36, 59, 44, 31, 63, 50, 19, 19, 71, 31, 8, 42, 63, 12, 87, 71, 20,
          0, 80, 27, 97, 73,
        ],
        __typename: "TeamEngineeringMetrics",
      },
    },
  },
};

export { MOCK_GET_TEAM_SCORE };
