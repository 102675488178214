/**
 * Shortens a string (ie: This is about...)
 *
 * @param {string} str the string to be shorten
 * @param {number} n shorten it to what point
 * @param {boolean} useWordBoundary use a word boundary or not
 *
 * @returns {string} the shorten string
 */
export default function shortenText(str, n, useWordBoundary = true, fromCenter = false) {
  if (!str) {
    return;
  }

  if (str.length <= n) {
    return str;
  }

  if (fromCenter) {
    var center = Math.floor(str.length / 2);
    var begin = center - Math.floor(n / 2);
    var end = center + Math.floor(n / 2);

    return str.substr(0, begin) + "…" + str.substr(end);
  } else {
    var subString = str.substr(0, n);

    return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(" ")) || subString : subString) + "…";
  }
}
