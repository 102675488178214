const CHALLENGES_TYPES = [
  {
    name: "Coderbyte",
    value: "CODERBYTE",
    default: true,
  },
  {
    name: "Codility",
    value: "CODILITY",
  },
];

const DEFAULT_CHALLENGE = CHALLENGES_TYPES.find((challenge) => challenge.default).value;

export { DEFAULT_CHALLENGE };

export default CHALLENGES_TYPES;
