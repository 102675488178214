import moment from "moment";

/**
 * Returns years experience since a start date
 *
 * @param {Object} data object that contains of startDates
 *
 * @returns {string}
 */
export default function getYearsExperience(data) {
  let yearsExperience = 0;

  data?.forEach((item) => {
    let yearsSinceStartDate = moment().diff(item.startDate, "years");

    if (yearsSinceStartDate > yearsExperience) {
      yearsExperience = yearsSinceStartDate;
    }
  });

  if (yearsExperience < 1) {
    return `< 1 year`;
  } else {
    return `${yearsExperience}+ years`;
  }
}
