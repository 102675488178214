/**
 * Capitalizes the first letter of a string
 *
 * @param {string} str the string to be capitalized
 *
 * @returns {string}
 */
export default function capitalizeFirst(str) {
  if (!str) {
    return;
  }

  str = str.toLowerCase();

  return str[0].toUpperCase() + str.substring(1);
}
