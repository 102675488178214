import React from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { colors, fontSize, breakpoints } from "style";
import { PrimaryLink } from "components/Links";
import { LINKS } from "constants/index";

/**
 * Signup Footer
 */
const SignupFooter = () => (
  <div css={styles.footer}>
    <FormattedMessage
      id="Signup.SignupFooter"
      values={{
        privacy: (
          <PrimaryLink href={LINKS.privacy} target="_blank">
            <FormattedMessage id="Signup.FormEmail.PrivacyLink" />
          </PrimaryLink>
        ),
        terms: (
          <PrimaryLink href={LINKS.terms_and_conditions} target="_blank">
            <FormattedMessage id="Signup.FormEmail.TermsAndConditionsLink" />
          </PrimaryLink>
        ),
      }}
    />
  </div>
);

const styles = {
  footer: css`
    position: absolute;
    bottom: 2.5rem;
    left: 2.5rem;
    color: ${colors.grayAnatomyLight1};
    font-size: ${fontSize.small};

    @media (max-width: ${breakpoints.mobile}) {
      position: static;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-left: 4rem;
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
  `,
};

export default SignupFooter;
