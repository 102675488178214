import { GET_PROFILE_TIME_SHEETS } from "graphql/queries";
import { useQueryData } from "hooks";

export default function useTimeSheet() {
  const { loading, data } = useQueryData({
    queryName: GET_PROFILE_TIME_SHEETS,
    keyName: "profileTimeSheets",
    variables: {
      orderBy: {
        monthPeriod: "desc",
      },
    },
  });

  return {
    loading,
    data: data?.profileTimeSheets?.nodes,
  };
}
