import React from "react";
import { Outlet } from "react-router-dom";
import { PageWrapper } from "components/Containers";
import { useAuth } from "hooks";
import PageTitle from "components/PageTitle";
import { NavList } from "components/Nav";

/**
 * Home
 */
const MissionsWrapper = () => {
  const { isAdmin } = useAuth();

  return (
    <>
      {isAdmin && (
        <PageTitle
          title={"Missions"}
          actionBar={
            <NavList
              pages={[
                {
                  label: "Missions",
                  to: `/missions`,
                  isActive: true,
                },
                {
                  label: "Applicants",
                  to: `/missions/applicants`,
                  isActive: true,
                },
                {
                  label: "Board",
                  to: `/missions/board`,
                  isActive: true,
                },
              ]}
              routerEnabled
              end
            />
          }
          asHeader
        />
      )}
      <PageWrapper>
        <Outlet />
      </PageWrapper>
    </>
  );
};

export default MissionsWrapper;
