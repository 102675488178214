import { GET_PROFILE_COMPLETION } from "graphql/queries";

const MOCK_GET_PROFILE_COMPLETION = {
  request: {
    query: GET_PROFILE_COMPLETION,
    variables: {
      orderBy: {},
      first: null,
      last: null,
      after: null,
    },
  },
  result: {
    data: {
      profileCompletion: {
        bio: {
          isComplete: false,
          isEmpty: false,
          isLoading: true,
          titleMessageId: "Profile.ProfileDescription.FormTitle",
        },
        photo: {
          isComplete: false,
          isEmpty: false,
          isLoading: true,
          titleMessageId: "Profile.ProfilePic.FormTitle",
        },
        disciplines: {
          isComplete: false,
          isEmpty: false,
          isLoading: true,
          titleMessageId: "Profile.ProfileDisciplines.FormTitle",
        },
        skills: {
          isComplete: false,
          isEmpty: false,
          isLoading: true,
          titleMessageId: "Profile.ProfileSkills.FormTitle",
        },
        info: {
          isComplete: false,
          isEmpty: false,
          isLoading: true,
          titleMessageId: "Profile.ProfileContact.FormTitle",
        },
        workExperiences: {
          isComplete: false,
          isEmpty: false,
          isLoading: true,
          titleMessageId: "Profile.ProfileWorkExperience.Title",
        },
        resume: {
          isComplete: false,
          isEmpty: false,
          isLoading: true,
          titleMessageId: "Profile.ProfileWorkExperience.Resume",
        },
        education: {
          isComplete: false,
          isEmpty: false,
          isLoading: true,
          titleMessageId: "Profile.ProfileEducation.FormTitle",
        },
      },
    },
  },
};

export { MOCK_GET_PROFILE_COMPLETION };
