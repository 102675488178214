import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useQueryData, useProfile } from "hooks";
import { GET_MISSION_BOARD_SUMMARY } from "graphql/queries";
import { MessagesError } from "components/Messages";
import { PrimaryLink } from "components/Links";
import { LINKS } from "constants/index";
import PageTitle from "components/PageTitle";
import styled from "@emotion/styled";
import { breakpoints, fontSize } from "style";

/**
 * MissionBoardSummary
 *
 * @param {Number}   id
 * @param {String}   name
 */
const MissionBoardSummary = ({ id: profileId, name, ...props }) => {
  const { data: profileData, loading: profileLoading } = useProfile({ profileId });
  const { loading, data, error } = useQueryData({
    queryName: GET_MISSION_BOARD_SUMMARY,
    keyName: "profile",
    variables: {
      id: profileId,
    },
  });

  if ((!loading && !data) || error) {
    return <MessagesError messageId="Missions.MissionBoardSummary.Error" />;
  }

  return (
    <StyledPageTitle
      loading={profileLoading || loading}
      title={
        <Title>
          <FormattedMessage
            id="Missions.MissionBoardSummary.H1"
            values={{
              name: name || profileData?.profile?.firstName,
            }}
          />
        </Title>
      }
      subtitle={
        <FormattedMessage
          id="Missions.MissionBoardSummary.OpenMissions"
          values={{
            roles: (
              <PrimaryLink to={LINKS.mission_board} withUnderline routerEnabled>
                {`${data?.missionBoardSummaryFetch.openMissionRolesCount} open roles`}
              </PrimaryLink>
            ),
          }}
        />
      }
      {...props}
    />
  );
};

const StyledPageTitle = styled(PageTitle)`
  margin-bottom: 2rem;
`;

const Title = styled.span`
  font-size: ${fontSize.xxxxlarge};
  font-weight: 700;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${fontSize.xxlarge};
  }
`;

MissionBoardSummary.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

export default MissionBoardSummary;
