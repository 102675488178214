import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "components/Buttons";
import { AddOrganizationForm } from "components/Organizations";

/**
 * AddOrganizationButton
 */
const AddOrganizationButton = (props) => {
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      <PrimaryButton onClick={() => setShowForm(true)}>
        <FormattedMessage id={`Organization.AddOrganizationButton.Button`} />
      </PrimaryButton>
      <AddOrganizationForm show={showForm} onClose={() => setShowForm(false)} {...props} />
    </>
  );
};

export default AddOrganizationButton;
