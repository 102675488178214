import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeaderCell } from "components/Table";
import { CheckBox } from "components/Form";
import { TableLoader } from "components/Loader";
import { MessagesError, MessagesNoResults } from "components/Messages";
import { DataTableCellContent, DataTableFooter } from "components/DataTable";
import { ExpandIcon } from "components/Icon";
import { getNestedFieldInObject } from "utils";
import { margins } from "style";
import { AdvancedButton } from "components/Buttons";

/**
 * Data Table
 *
 * @param {Array}     data                       The data to populate the table
 * @param {Boolean}   loading                    The data loading state
 * @param {Object}    error                      The error data
 * @param {Array}     columns                    Array that returns column data for table
 * @param {Boolean}   selectable                 Defines whether row is selectable
 * @param {Function}  onSelect                   Function handler when a row is selected
 * @param {Array}     selectionInitialState      Define which rows are selected on mount
 * @param {Boolean}   expandable                 Defines whether row is expandable
 * @param {String}    expandableKeyName          Key name to expand row (field must be an array)
 * @param {Array}     expandableColumns          Array that returns column data for expanded row
 * @param {Function}  expandableRow              Function that returns a component used in place of expanded row
 * @param {Boolean}   clearable                  Defines whether table is clearable
 * @param {Number}    clearCacheValue            Changing value will clear table if clearable set to true
 * @param {Number}    resultsPerPage             Number of results per page
 * @param {Function}  onClick                    Function handler when a row is clicked
 * @param {Array}     footer                     Array that returns column data for the footer
 * @param {Boolean}   hideHeader                 Hide the table head
 * @param {String}    marginSize                 Sets the horizontal margin size
 * @param {Function}  rowOptions                 Function that returns an array of options for each row
 * @param {Function}  headerOptions              Function that returns an array of options for the header
 */
const DataTable = ({
  data: dataProp,
  loading,
  error: errorProp,
  columns,
  selectable,
  onSelect,
  selectionInitialState,
  emptyMessage,
  expandable,
  expandableKeyName,
  expandableColumns,
  expandableRow,
  clearable,
  clearCacheValue,
  resultsPerPage,
  onClick,
  footer,
  hideHeader,
  marginSize,
  rowOptions,
  headerOptions,
  filterFunction,
  ...props
}) => {
  const [expanded, setExpanded] = useState([]);
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState(selectionInitialState);
  const [allDataIds, setAllDataIds] = useState([]);
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const [allItemsExpanded, setAllItemsExpanded] = useState(false);
  const [data, setData] = useState([]);
  const columnCount =
    columns.length + (selectable ? 1 : 0) + (expandable ? 1 : 0) + (rowOptions || headerOptions ? 1 : 0);
  const isExpanded = (id, index) => expanded.indexOf(id || index) !== -1;
  const handleRowClick = (rowData) => typeof onClick === "function" && onClick(rowData);
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const OptionsComponent = rowOptions?.component;

  useEffect(() => {
    if (selectionInitialState.length) {
      setSelected(selectionInitialState);
    }
  }, [selectionInitialState]);

  useEffect(() => {
    setAllDataIds(data?.map((obj) => obj?.id));
  }, [data]);

  useEffect(() => {
    if (!allDataIds || allDataIds.length === 0) {
      setAllItemsSelected(false);
      return;
    }

    setAllItemsSelected(allDataIds?.every((i) => selected?.includes(i)));
  }, [allDataIds, selected]);

  useEffect(() => {
    setAllItemsExpanded(allDataIds?.every((i) => expanded?.includes(i)));
  }, [allDataIds, expanded]);

  const handleSelection = (id) => {
    setSelected(isSelected(id) ? selected.filter((i) => i !== id) : [...selected, ...[id]]);
  };

  const handleSelectAll = () => {
    if (allItemsSelected) {
      return setSelected(selected.filter((i) => !allDataIds.includes(i)));
    }
    setSelected([...selected, ...allDataIds]);
  };

  const handleExpand = (id, index) => {
    const key = id !== null ? id : index;
    setExpanded(isExpanded(id, index) ? expanded.filter((i) => i !== key) : [...expanded, key]);
  };

  const handleExpandAll = () => {
    if (allItemsExpanded) {
      return setExpanded(expanded.filter((i) => !allDataIds.includes(i) && !data.some((_item, index) => index === i)));
    }
    setExpanded([...expanded, ...allDataIds, ...data.map((_, index) => index)]);
  };

  const handleOptionsCellClick = (e) => e.stopPropagation();

  /**
   * @description When data changes, remove any selected items that no longer exist
   */
  useEffect(() => {
    if (clearable) {
      setSelected([]);
    }
  }, [data]);

  /**
   * @description Clear selection when changing tabs if clearable flag is true
   */
  useEffect(() => {
    setSelected([]);
  }, [clearCacheValue]);

  /**
   * @description Callback function when selected
   */
  useEffect(() => {
    if (typeof onSelect === "function") {
      onSelect(selected);
    }
  }, [onSelect, selected, data]);

  /**
   * @description Filter data based on filter function
   */
  useEffect(() => {
    setData(typeof filterFunction === "function" ? filterFunction(dataProp) : dataProp);
    setError(errorProp);
  }, [dataProp, filterFunction]);

  return (
    <>
      <Table selectable {...props}>
        {!hideHeader && (
          <TableHead>
            <TableRow>
              {selectable && (
                <TableHeaderCell scope="col" width={70} marginSize={marginSize}>
                  <CheckBox
                    name="check-all"
                    onChange={handleSelectAll}
                    value={allItemsSelected}
                    disabled={!allDataIds || allDataIds.length === 0}
                    noMargin
                  />
                </TableHeaderCell>
              )}
              {expandable && (
                <TableHeaderCell scope="col" width={selectable ? 20 : 60} expander>
                  <ExpandIcon isExpanded={allItemsExpanded} onClick={handleExpandAll} />
                </TableHeaderCell>
              )}
              {columns.map((columnData, index) => (
                <TableHeaderCell key={index} width={columnData.width || `auto`} scope="col" marginSize={marginSize}>
                  {columnData.label}
                </TableHeaderCell>
              ))}
              {(headerOptions || rowOptions) && (
                <TableHeaderCell width={80} scope="col" marginSize={marginSize}>
                  {headerOptions && (
                    <AdvancedButton
                      disabled={selected?.length === 0 || loading}
                      loading={loading}
                      options={headerOptions(selected, data)}
                    />
                  )}
                </TableHeaderCell>
              )}
            </TableRow>
          </TableHead>
        )}
        {(error || (!loading && (!data || data.length === 0))) && (
          <TableBody>
            <TableRow colSpan={100}>
              <TableCell colSpan={columnCount}>
                {error ? <MessagesError /> : <MessagesNoResults {...emptyMessage} />}
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        {(data || loading) && (
          <TableBody>
            <TableLoader loading={loading} numRows={resultsPerPage} numColumns={columnCount} />
            {!loading &&
              data?.map((rowData, indexRow) => (
                <Fragment key={rowData.id || indexRow}>
                  <TableRow
                    onClick={() => handleRowClick(rowData)}
                    className={typeof onClick === "function" || selectable ? `actionable` : ``}
                    expandable={expandable && isExpanded(rowData.id, indexRow)}
                  >
                    {selectable && (
                      <TableCell selected={isSelected(rowData.id)} marginSize={marginSize}>
                        <CheckBox
                          name={`check-${rowData.id}`}
                          onChange={() => {
                            handleSelection(rowData.id);
                          }}
                          value={isSelected(rowData.id)}
                          inTable
                          noMargin
                        />
                      </TableCell>
                    )}
                    {expandable && (
                      <TableCell selected={isSelected(rowData.id)} marginSize={marginSize} expander>
                        <ExpandIcon
                          isExpanded={isExpanded(rowData.id, indexRow)}
                          onClick={() => handleExpand(rowData.id, indexRow)}
                          color={isSelected(rowData.id) && `#fff`}
                        />
                      </TableCell>
                    )}
                    {columns.map((columnData, index) => (
                      <TableCell
                        key={index}
                        selected={isSelected(rowData.id)}
                        loading={loading}
                        marginSize={marginSize}
                        width={columnData.width || `auto`}
                        {...columnData.cellProps}
                      >
                        <DataTableCellContent
                          colData={columnData}
                          rowData={rowData}
                          previousRowData={data[indexRow - 1]}
                        />
                      </TableCell>
                    ))}
                    {(rowOptions || headerOptions) && (
                      <TableCell
                        selected={isSelected(rowData.id)}
                        marginSize={marginSize}
                        alignRight
                        onClick={handleOptionsCellClick}
                      >
                        {rowOptions && typeof rowOptions === "function" ? (
                          <AdvancedButton options={rowOptions(rowData)} />
                        ) : rowOptions && typeof rowOptions !== "function" ? (
                          <OptionsComponent data={rowData} {...rowOptions.defaultArgs} />
                        ) : null}
                      </TableCell>
                    )}
                  </TableRow>
                  {isExpanded(rowData.id, indexRow) &&
                    getNestedFieldInObject(rowData, expandableKeyName)?.map((expandedData, index) => (
                      <TableRow expanded key={expandedData.id || index}>
                        <TableCell colSpan={selectable ? 2 : 1} />
                        {expandableColumns?.map((columnData, index) => (
                          <TableCell key={index} {...columnData.cellProps}>
                            <DataTableCellContent colData={columnData} rowData={expandedData} />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  {isExpanded(rowData.id, indexRow) && typeof expandableRow === "function" && (
                    <TableRow expanded>
                      <TableCell colSpan={columnCount}>{expandableRow(rowData, columnCount)}</TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))}
          </TableBody>
        )}
        {!error && !loading && data && <DataTableFooter data={footer} marginSize={marginSize} />}
      </Table>
    </>
  );
};

DataTable.defaultProps = {
  selectionInitialState: [],
  clearable: true,
  loading: false,
  hideHeader: false,
  selectable: false,
  marginSize: margins.normal,
};

DataTable.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  footer: PropTypes.array,
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  emptyMessage: PropTypes.object,
  selectable: PropTypes.bool,
  expandable: PropTypes.bool,
  expandableKeyName: PropTypes.string,
  expandableRow: PropTypes.func,
  expandableColumns: PropTypes.array,
  onSelect: PropTypes.func,
  resultsPerPage: PropTypes.number,
  onClick: PropTypes.func,
  selectionInitialState: PropTypes.array,
  clearable: PropTypes.bool,
  clearCacheValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideHeader: PropTypes.bool,
  marginSize: PropTypes.string,
  rowOptions: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.element)]),
  headerOptions: PropTypes.func,
  filterFunction: PropTypes.func,
};

export default DataTable;
