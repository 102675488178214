import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import { useAuth, useTeams } from "hooks";
import { PAGINATION_TYPE } from "components/Pagination";
import { MessagesError } from "components/Messages";
import { Grid } from "components/Containers";
import { SquadActivityLogLayout } from "components/Squads";
import { Spinner } from "components/Loader";
import Card from "components/Card";

/**
 * DashboardActivityLogs
 *
 * @param {Number} resultsPerPage
 */
const DashboardTeamsActivityLogs = ({ resultsPerPage }) => {
  const { isAdmin } = useAuth();
  const { ref, inView } = useInView();
  const { loading, loadingMore, data, error, hasNextPage, handleNextPage } = useTeams({
    resultsPerPage,
    paginationType: PAGINATION_TYPE.append,
    variables: {
      sort: "created_at",
      sortDirection: PAGINATION_TYPE.descending,
      limit: null,
      assignments: false,
      timeSheetSummary: false,
      organization: false,
    },
  });

  useEffect(() => {
    if (inView && hasNextPage && !loading && !loadingMore) {
      handleNextPage();
    }
  }, [inView]);

  if (!isAdmin) return null;

  if (error) return <MessagesError />;

  return (
    <>
      <Grid>
        {data?.map((team) => (
          <Grid.col start={1} end={13} key={team.id}>
            <Card>
              <SquadActivityLogLayout teamId={team.id} slug={team.slug} titleId={team.name} />
            </Card>
          </Grid.col>
        ))}
        <div ref={ref} />
      </Grid>
      {(loading || loadingMore) && <Spinner margin="1rem auto" />}
    </>
  );
};

DashboardTeamsActivityLogs.propTypes = {
  resultsPerPage: PropTypes.number,
};

export default DashboardTeamsActivityLogs;
