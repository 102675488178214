import { sanitize } from "dompurify";

/**
 * Create a markup object from a string sanitized.
 *
 * @param {string} dirty
 *
 * @returns {Object} The markup object
 */

export default function createMarkup(dirty) {
  return { __html: sanitize(dirty) };
}
