const CURRENCIES = [
  { value: "USD", label: "USD" },
  { value: "CAD", label: "CAD" },
  { value: "EUR", label: "EUR" },
];

const CURRENCY_NAMES = {
  usd: "USD",
  cad: "CAD",
  ur: "EUR",
};

export { CURRENCY_NAMES };

export default CURRENCIES;
