import React from "react";
import PropTypes from "prop-types";
import TextLoader from "../Loader/TextLoader";
import { TableRow, TableCell } from "components/Table";

/**
 * Shows a row loader
 *
 * @param {Boolean}  loading
 * @param {Number}   numRows
 * @param {Number}   numColumns
 */
const TableLoader = ({ loading, numRows, numColumns, ...props }) =>
  loading &&
  Array.from(Array(numRows).keys()).map((key) => (
    <TableRow key={key} data-loader>
      <TableCell colSpan={numColumns} alignMiddle {...props}>
        <TextLoader />
      </TableCell>
    </TableRow>
  ));

TableLoader.defaultProps = {
  numRows: 3,
  numColumns: 3,
};

TableLoader.propTypes = {
  loading: PropTypes.bool,
  numRows: PropTypes.number,
  numColumns: PropTypes.number,
};

export default TableLoader;
