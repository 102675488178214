import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Banner } from "components/Banner";
import { useAuth } from "hooks";
import { margins } from "style";

/**
 * BannerShowcasePage
 *
 * @param {String}  textId
 * @param {Object}  values
 * @param {String}  marginSize
 */
const BannerShowcasePage = ({ textId, values, marginSize, ...props }) => {
  const { isClient, isShowcase } = useAuth();
  const isClientOrShowcase = isClient || isShowcase;

  if (!isClientOrShowcase) return null;

  return (
    <Banner isRemovable={false} {...props} css={{ marginTop: marginSize, lineHeight: 1.3 }}>
      <FormattedMessage id={textId} values={values} />
    </Banner>
  );
};

BannerShowcasePage.defaultProps = {
  marginSize: margins.normal,
};

BannerShowcasePage.propTypes = {
  textId: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  marginSize: PropTypes.string,
};

export default BannerShowcasePage;
