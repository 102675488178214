import React from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { snakeToCapitalized } from "utils";
import { fontSize } from "style";

/**
 * ScoreDisplay
 *
 * @params {Object}    subScores
 * @params {String}    fontSize
 */
const ScoreDisplay = ({ subScores, fontSize }) => {
  if (!subScores) return <FormattedMessage id="Global.NA" />;

  return Object.entries(subScores).map(([key, value]) => (
    <div css={styles.tooltip(fontSize)} key={key}>
      {snakeToCapitalized(key)}: {(value.score * 100).toFixed(0)}% {value.weight && <span>({value.weight}x)</span>}
    </div>
  ));
};

const styles = {
  tooltip: (fontSize) => css`
    text-align: left;
    font-size: ${fontSize};
  `,
};

ScoreDisplay.propTypes = {
  subScores: PropTypes.object,
  fontSize: PropTypes.string,
};

ScoreDisplay.defaultProps = {
  fontSize: fontSize.xxsmall,
};

export default ScoreDisplay;
