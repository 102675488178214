import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { colors } from "style";
import { SearchModal } from "components/Search";
import { PrimaryButton } from "components/Buttons";

/**
 * TermSheetMemberAddButton
 *
 * @param {Boolean} onAdd
 * @param {Boolean} asButton
 */
const TermSheetMemberAddButton = ({ onAdd, asButton, ...props }) => {
  const [showForm, setShowForm] = useState(false);

  const handleItemClick = (payload) => {
    onAdd?.(payload);
    setShowForm(false);
  };

  const ButtonSelectMember = ({ data }) => (
    <PrimaryButton variant="green" onClick={() => handleItemClick(data)}> 
      <FormattedMessage id="TermSheets.TermSheetMemberAddButton.CTA" />
    </PrimaryButton>
  );

  return (
    <>
      {asButton ? (
        <PrimaryButton onClick={() => setShowForm(true)} {...props}>
          <FormattedMessage id="TermSheets.TermSheetMemberAddButton.Button" />
        </PrimaryButton>
      ) : (
        <AddIcon onClick={() => setShowForm(true)} {...props} />
      )}
      <SearchModal
        show={showForm}
        onClose={() => setShowForm(false)}
        actions={[ButtonSelectMember]}
        isCardView={false}
        onItemClick={handleItemClick}
        initialValues={{
          sort: "member_at",
          sortDirection: "desc",
          status: "Member",
        }}
        title={
          <FormattedMessage id="TermSheets.TermSheetMemberAddButton.Title" />
        }
        info={
          <FormattedMessage id="TermSheets.TermSheetMemberAddButton.Info" />
        }
      />
    </>
  );
};

const AddIcon = styled.div`
  background: ${colors.grayAnatomyLight1};
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${colors.green};
    transform: scale(1.1);
  }

  &:after {
    content: " ";
    height: 2px;
    width: 1.25rem;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 0.35rem;
    background: #fff;
    transition: all 0.3s ease;
  }

  &:before {
    content: " ";
    height: 2px;
    width: 1.25rem;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 0.35rem;
    background: #fff;
    transition: all 0.3s ease;
    transform: rotate(90deg);
  }
`;

TermSheetMemberAddButton.propTypes = {
  onAdd: PropTypes.bool,
  asButton: PropTypes.bool,
};

export default TermSheetMemberAddButton;