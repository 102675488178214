import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { formatCurrency, formatDate } from "utils";
import { TYPE, METHOD } from "components/Form";
import { ACCEPT_TIME_CARD, REOPEN_TIME_CARD, SEND_TIME_CARD_TO_DEEL } from "graphql/mutations";
import { useToast } from "hooks";
import { AdvancedButton } from "components/Buttons";
import Icon, { ICON_TYPE } from "components/Icon";
import { TIME_CARD_STATUS, PAGES } from "constants/index";
import { useModalConfirmation } from "hooks";
import { UserFullName } from "components/User";
import { Row, Col } from "components/Containers";
import { colors } from "style";

/**
 * TimeCardAdvancedOptions
 *
 * @param {Object}    data
 * @param {Array}     listData
 * @param {Boolean}   isBatch
 * @param {Array}     selection
 * @param {Boolean}   disabled
 * @param {String}    month
 * @param {String}    year
 */
const TimeCardAdvancedOptions = ({ data, isBatch, listData, selection, disabled, month, year }) => {
  const [showForm, setShowForm] = useState(false);
  const [options, setOptions] = useState([]);
  const { show } = useModalConfirmation();
  const { toast } = useToast();
  const [isDisabled, setIsDisabled] = useState(false);
  const [handleReopen, { loading: reopenTimeCardLoading }] = useMutation(REOPEN_TIME_CARD);
  const [handleAccept, { loading: acceptTimeCardLoading }] = useMutation(ACCEPT_TIME_CARD);
  const [handleSendToDeel, { loading: sendToDeelLoading }] = useMutation(SEND_TIME_CARD_TO_DEEL);

  useEffect(() => {
    setIsDisabled((selection?.length === 0 && isBatch) || options.length === 0);
  }, [selection, isBatch, options]);

  useEffect(() => {
    const getOptions = (status, deelLastSyncAt) => {
      let options = [];

      if (status === TIME_CARD_STATUS.review || isBatch) {
        options.push(acceptOption);
      }

      if (status === TIME_CARD_STATUS.review) {
        options.push(reopenOption);
      }

      if (status === TIME_CARD_STATUS.accepted && !isBatch) {
        options.push(reopenOption, deelLastSyncAt ? openDeelContract : deelAcceptOption);
      }

      return options;
    };

    setOptions(getOptions(data?.timeCardReport?.status, data?.timeCardReport?.deelLastSyncAt));
  }, [data, selection, isBatch]);

  const handleConfirmationModal = async (id) => {
    const values = {
      name: <b>{`${data?.firstName} ${data?.lastName}`}</b>,
      count: <b>{`${data?.timeCardReport?.timeSheetsTotalPerTeam?.length}`}</b>,
      timespan: <b>{`${formatDate(data?.timeCardReport?.lastEntry, "MMM, YYYY")}`}</b>,
    };

    const response = await show(
      <FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.DeelTaskConfirmation"} />,
      <>
        <FormattedMessage id="TimeCard.TimeCardTable.TimeCardAdvanceOptions.DeelConfirmation" values={values} />
        <div css={styles.line_items_container}>
          {data.timeCardReport.timeSheetsTotalPerTeam.map((team) => (
            <>
              <div>
                <FormattedMessage
                  key={team.id}
                  id="TimeCard.TimeCardTable.TimeCardAdvanceOptions.DeelConfirmationLineItem"
                  values={{
                    team: <b>{team?.team?.name}</b>,
                    hours: <b>{team?.totalLoggedHours.toFixed(2)}</b>,
                    rate: <b>{formatCurrency(team?.rate?.amount, team?.rate?.currency?.isoCode)}</b>,
                    total: (
                      <b>
                        {formatCurrency(team?.totalLoggedHours * team?.rate?.amount, team?.rate?.currency?.isoCode)}
                      </b>
                    ),
                  }}
                />
              </div>
            </>
          ))}
        </div>
      </>,
      {
        showCancel: true,
        showSave: true,
        primaryButtonMessageId: "Global.Yes",
        size: "md",
      }
    );

    if (response) {
      handleSendTimeCardToDeel(id);
    }
  };

  const handleSendTimeCardToDeel = (id) => {
    handleSendToDeel({
      variables: {
        month,
        year,
        input: {
          id: id,
        },
      },
      onCompleted: () => {
        toast.success(<FormattedMessage id={"TimeCard.TimeCardsTable.TimeCardAdvanceOptions.Deel.Success"} />);
      },
      onError: () => {
        toast.error(<FormattedMessage id={"TimeCard.TimeCardsTable.TimeCardAdvanceOptions.Deel.Error"} />);
      },
    });
  };

  const handleAcceptTimeCard = (id) => {
    handleAccept({
      variables: {
        input: {
          ids: isBatch ? selection : [id],
        },
      },
      onCompleted: (result) => {
        const { accepted, failedToAccept } = result.acceptTimeCard;

        if (isBatch) {
          const failed = failedToAccept?.map((item) => item.id);
          const acc = accepted?.map((item) => item.id);
          const failedTimeSheets = listData?.filter((item) => failed.indexOf(item.id) !== -1);
          const successTimeSheets = listData?.filter((item) => acc.indexOf(item.id) !== -1);

          handleApproveBatch(failedTimeSheets, successTimeSheets);
        } else if (accepted.length > 0) {
          toast.success(<FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.Success"} />);
        } else {
          toast.error(<FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.ApproveError"} />);
        }
      },
      onError: () => {
        toast.error(<FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.Error"} />);
      },
    });
  };

  const handleApproveBatch = async (failedTimeSheets, successTimeSheets) => {
    await show(
      <FormattedMessage id="TimeCard.TimeCardTable.TimeCardAdvanceOptions.ApproveBatchResultTitle" />,
      <Col gap="0.5rem">
        <div>
          <b>
            <FormattedMessage id="TimeCard.TimeCardTable.TimeCardAdvanceOptions.ApproveBatchResultSubtitle" />
          </b>
        </div>
        {successTimeSheets.length > 0 && (
          <>
            <p>
              <FormattedMessage id="TimeCard.TimeCardTable.TimeCardAdvanceOptions.ApproveBatchResultSuccess" />
            </p>
            {successTimeSheets.map((item) => (
              <Row key={item.id}>
                <Icon type={ICON_TYPE.checkFilled} color={colors.green} />
                <UserFullName data={item} />
              </Row>
            ))}
          </>
        )}
        {failedTimeSheets.length > 0 && (
          <>
            <p>
              <FormattedMessage id="TimeCard.TimeCardTable.TimeCardAdvanceOptions.ApproveBatchResultFail" />
            </p>
            {failedTimeSheets.map((item) => (
              <Row key={item.id}>
                <Icon type={ICON_TYPE.unavailable} color={colors.red} />
                <UserFullName data={item} />
              </Row>
            ))}
          </>
        )}
      </Col>,
      {
        showCancel: true,
        showSave: false,
        secondaryButtonMessageId: "Global.Close",
        size: MODAL_SIZE.small,
      }
    );
  };

  const handleReopenTimeCard = (id, note) => {
    handleReopen({
      variables: {
        input: {
          id,
          notes: note,
        },
      },
      onCompleted: () => {
        setShowForm(false),
          toast.success(
            <FormattedMessage
              id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.Success"}
              values={{
                timeCardCount: 1,
              }}
            />
          );
      },
      onError: () => {
        setShowForm(false),
          toast.error(<FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.Error"} />);
      },
    });
  };

  const handleSubmit = (formData) => {
    const { message } = formData;
    handleReopenTimeCard(data?.timeCardReport?.id, message);
  };

  const handleDeelNavigation = (url) => {
    window.open(url, "_blank");
  };

  /**
   * Sets timeCard to "Accepted"
   */
  const acceptOption = {
    label: <FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.ApproveButton"} />,
    icon: ICON_TYPE.checkmark,
    onClick: () => handleAcceptTimeCard(data?.timeCardReport?.id),
  };

  /**
   * Sets timeCard to "Accepted" and then to "Sent to Deel"
   */
  const deelAcceptOption = {
    label: <FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.DeelApproveButton"} />,
    icon: ICON_TYPE.dollarBill,
    disabled: !data?.deelContractId,
    onClick: () => handleConfirmationModal(data?.timeCardReport?.id),
  };

  /**
   * Opens contract overview page on Deel
   */
  const openDeelContract = {
    label: <FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.OpenDeelContract"} />,
    icon: ICON_TYPE.share,
    onClick: () => handleDeelNavigation(data?.deelContractUrl),
  };

  /**
   * Sets timeCard to "Open"
   */
  const reopenOption = {
    label: <FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.ReopenButton"} />,
    icon: ICON_TYPE.rotate,
    onClick: () => setShowForm(true),
  };

  return (
    <>
      <ModalForm
        show={showForm}
        size={MODAL_SIZE.medium}
        onClose={() => setShowForm(false)}
        data={FORM_DATA}
        title={<FormattedMessage id="TimeCard.TimeCardsTable.ReopenButton.Title" />}
        onSubmit={handleSubmit}
        method={METHOD.post}
        loading={acceptTimeCardLoading || reopenTimeCardLoading || sendToDeelLoading}
        page={PAGES.reopenTimecards}
      />
      <AdvancedButton
        options={options}
        loading={acceptTimeCardLoading || reopenTimeCardLoading || sendToDeelLoading}
        disabled={disabled || isDisabled}
      />
    </>
  );
};

const styles = {
  line_items_container: css`
    margin-top: 2rem;
  `,
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Message",
        type: TYPE.textarea,
        properties: {
          name: "message",
          placeholder: "Optionally, add a brief message",
          autoFocus: true,
        },
      },
    ],
  },
];

TimeCardAdvancedOptions.defaultProps = {
  isBatch: false,
};

TimeCardAdvancedOptions.propTypes = {
  data: PropTypes.object,
  listData: PropTypes.array,
  isBatch: PropTypes.bool,
  selection: PropTypes.array,
  disabled: PropTypes.bool,
  month: PropTypes.string,
  year: PropTypes.string,
};

export default TimeCardAdvancedOptions;
