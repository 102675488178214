import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useAuth } from "hooks";
import { LINKS } from "constants/index";
import { PrimaryButton } from "components/Buttons";

/**
 * ProfileViewButton
 *
 * @param {String} slug
 */
const ProfileViewButton = ({ slug, ...props }) => {
  const { isAdmin, isRecruiter } = useAuth();

  if (!isAdmin && !isRecruiter) return null;

  const handleClick = () => window.open(LINKS.private_profile(slug), "_blank");

  return (
    <PrimaryButton onClick={() => handleClick()} disabled={!slug} {...props}>
      <FormattedMessage id="Profile.ProfileModal.ButtonViewProfile" />
    </PrimaryButton>
  );
};

ProfileViewButton.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default ProfileViewButton;
