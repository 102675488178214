import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { useOutletContext } from "react-router";
import PageTitle from "components/PageTitle";
import { ActivityLogLayout, ActivityPeriodSelector } from "components/ActivityLog";
import { useActivityLogs, useAuth, useTracking } from "hooks";
import { ChatWindow } from "components/Chat";
import { getCurrentUTCDate } from "utils";
import { PAGES, TEAM_TYPES, TRACKING_PAGE_VIEWS } from "constants/index";
import { Grid } from "components/Containers";
import { MessagesNoResults } from "components/Messages";
import Card from "components/Card";
import { ScopedRepositoriesFilter } from "components/Squads/Engineering";

/**
 * ActivityLog
 */
const ActivityLog = (props) => {
  const { isShowcase } = useAuth();
  const [selectedDate, setSelectedDate] = useState(getCurrentUTCDate());
  const [selectedGitRepositories, setSelectedGitRepositories] = useState();
  const { selectedSquad: teamData, loading: teamLoading, id } = useOutletContext();
  const { data, days, loading, error } = useActivityLogs({
    teamId: id,
    selectedDate,
    repositories: selectedGitRepositories,
  });
  const { trackPage } = useTracking();
  const teamMembersData = data?.filter((item) => !!item.assignment && !!item.profile);
  const guestContributorsData = data?.filter((item) => !item.assignment || !item.profile);
  const intl = useIntl();
  const isExternalTeam = teamData?.teamType === TEAM_TYPES.external;
  const handleChange = (_name, value) => setSelectedDate(value);
  const handleRepositoriesChange = (_name, value) => setSelectedGitRepositories(value);

  useEffect(() => {
    if (isShowcase) {
      trackPage(TRACKING_PAGE_VIEWS[PAGES.squadActivityLog].page_name, TRACKING_PAGE_VIEWS[PAGES.squadActivityLog]);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.ActivityLog.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            subtitle={<FormattedMessage id="Dashboard.ActivityLog.Title" />}
            actionBar={
              <>
                <ScopedRepositoriesFilter
                  id={id}
                  gitRepositories={teamData?.gitRepositories}
                  onChange={handleRepositoriesChange}
                  disabled={!teamData?.gitRepositories}
                  value={selectedGitRepositories}
                  loading={loading}
                />
                <ActivityPeriodSelector value={selectedDate} onChange={handleChange} enableDateCycle />
              </>
            }
            {...props}
          />
        </Grid.col>
        {((data && data.length > 0) || loading) && (
          <>
            {!isExternalTeam && (teamMembersData.length > 0 || loading) && (
              <Grid.col start={1} end={13}>
                <ActivityLogLayout
                  data={teamMembersData}
                  days={days}
                  loading={loading || teamLoading}
                  error={error}
                  isGuest={false}
                  showFilter={false}
                />
              </Grid.col>
            )}
            {guestContributorsData.length > 0 && (
              <Grid.col start={1} end={13}>
                <ActivityLogLayout
                  titleId="Squads.Squad.Engineering.ActivityLog.CardTitleGuests"
                  data={guestContributorsData}
                  days={days}
                  loading={loading || teamLoading}
                  error={error}
                  headerTitleId="Dashboard.ActivityLog.ActivityLogLayout.ColumnHeaderGuestContributor"
                  isGuest={true}
                  showFilter={!isExternalTeam}
                  hideHeader={isExternalTeam}
                />
              </Grid.col>
            )}
          </>
        )}
        {!loading && (!data || data.length === 0) && (
          <Grid.col start={1} end={13}>
            <Card>
              <MessagesNoResults
                title={!teamData?.gitRepositories ? "Squads.NoReposTitle" : "Squads.NoActivityTitle"}
                description={!teamData?.gitRepositories ? "Squads.NoReposDescription" : "Squads.NoActivityDescription"}
              />
            </Card>
          </Grid.col>
        )}
      </Grid>
      {/* To disable chat bot for now */}
      {false && !isShowcase && !teamLoading && <ChatWindow teamId={teamData?.id} />}
    </>
  );
};

export default ActivityLog;
