import React from "react";
import PropTypes from "prop-types";
import { fontSize } from "style";
import { FormattedMessage } from "react-intl";
import Tooltip from "components/Tooltip";

/**
 * SideNavTooltip
 *
 * @param {String}  textId
 */
const SideNavTooltip = ({ textId, ...props }) => (
  <Tooltip
    tooltipId={`${textId}.Tooltip`}
    values={{
      intro: (
        <b>
          <FormattedMessage id={`${textId}.Tooltip.Intro`} />
        </b>
      ),
    }}
    hideIcon
    fontSize={fontSize.xxsmall}
    {...props}
  >
    <FormattedMessage id={textId} {...props} />
  </Tooltip>
);

SideNavTooltip.propTypes = {
  textId: PropTypes.string.isRequired,
};

export default SideNavTooltip;
