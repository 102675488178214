import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";

/**
 * Show a generic error message
 *
 * @params {Element}  children
 */
const FormError = ({ children }) => (
  <div data-error css={style}>
    {children}
  </div>
);

const style = css`
  font-size: ${fontSize.small};
  color: ${colors.error};
  font-weight: 500;
  margin-bottom: 2rem;
`;

FormError.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormError;
