import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useToast } from "hooks";
import { CopyToClipboardInput, Label, SIZE, TYPE } from "components/Form";
import { UPDATE_CHALLENGE } from "graphql/mutations";
import { MUTATION_TYPE } from "hooks";
import { GET_DISCIPLINES, GET_SKILLS, GET_CHALLENGES, GET_CHALLENGE_TYPES } from "graphql/queries";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { CODE_CHALLENGE_TYPES } from "constants/index";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { Row } from "components/Containers";
import { formatDate } from "utils";
import { TextInput } from "components/Form/TextInput";

/**
 * CodeChallengeForm
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Object}    data
 * @param {String}    challengeId
 * @param {Boolean}   loading
 * @param {Boolean}   isEditing
 */
const CodeChallengeForm = ({ show, onClose, data, challengeId, loading, isEditing }) => {
  const { toast } = useToast();

  if (isEditing && loading) return null;

  const initialValues = {
    icon: data?.icon,
    name: data?.name,
    published: data?.published,
    skills: data?.skills?.map((item) => ({
      name: item.name,
      id: item.id,
    })),
    disciplines: data?.disciplines?.map((item) => ({
      name: item.name,
      id: item.id,
    })),
    description: data.description,
    types: data?.types?.map(({ name, id }) => ({ name, id })),
  };

  const formProps = {
    initialValues,
    updateId: challengeId,
    mutationType: isEditing ? MUTATION_TYPE.update : MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: UPDATE_CHALLENGE,
        update: UPDATE_CHALLENGE,
      },
      refetchQueries: [GET_CHALLENGES],
      refetchAfterMutate: true,
    },
  };

  const handleComplete = () => {
    onClose();
    toast.success(
      <FormattedMessage id={`CodeChallenges.CodeChallengeForm.${isEditing ? `EditSuccess` : `CreateSuccess`}`} />
    );
  };

  const handleError = () => {
    onClose();
    toast.error(
      <FormattedMessage id={`CodeChallenges.CodeChallengeForm.${isEditing ? `EditError` : `CreateError`}`} />
    );
  };

  return (
    <ModalForm
      data={COLUMN_DATA}
      show={show}
      size={MODAL_SIZE.medium}
      onClose={onClose}
      title={<FormattedMessage id={`CodeChallenges.CodeChallengeForm.${isEditing ? `Edit` : `Create`}FormTitle`} />}
      description={
        <>
          <FormattedMessage id={`CodeChallenges.CodeChallengeForm.${isEditing ? `Edit` : `Create`}FormDescription1`} />
          <div css={styles.challenge_url}>
            <Label>
              <FormattedMessage id="CodeChallenges.CodeChallengeForm.CodeChallengeURL.Label" />
            </Label>
            <CopyToClipboardInput text={data?.testUrl} />
          </div>

          <div css={styles.challenge_url}>
            <Label>
              <FormattedMessage id="CodeChallenges.CodeChallengeForm.PrivateTitle.Label" />
            </Label>
            <TextInput type="text" value={data?.privateName} disabled={true} />
          </div>
        </>
      }
      onCompleted={handleComplete}
      onError={handleError}
      footer={
        <FormattedMessage
          id={`CodeChallenges.CodeChallengeForm.Footer`}
          values={{
            date: <b>{formatDate(data?.createdAt)}</b>,
          }}
        />
      }
      {...formProps}
    />
  );
};

const styles = {
  challenge_url: css`
    margin: 3rem 0 -2rem 0;
  `,
};

const COLUMN_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Public Title",
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "name",
          type: "text",
          maxLength: 255,
          required: true,
          autoFocus: true,
        },
      },
      {
        label: "Icon",
        type: TYPE.select,
        size: SIZE.medium,
        properties: {
          name: "icon",
          required: false,
          options: Object.keys(CODE_CHALLENGE_TYPES).map((item) => ({
            label: (
              <Row>
                <Icon type={ICON_TYPE[item]} size={ICON_SIZE.medium} color="inherit" />
                {CODE_CHALLENGE_TYPES[item]}
              </Row>
            ),
            value: item,
          })),
        },
      },
      {
        label: "Status",
        type: TYPE.select,
        size: SIZE.medium,
        properties: {
          name: "published",
          required: true,
          options: [
            {
              label: "Enabled",
              value: true,
            },
            {
              label: "Disabled",
              value: false,
            },
          ],
        },
      },
      {
        label: "Description",
        type: TYPE.textarea,
        size: SIZE.xlarge,
        properties: {
          name: "description",
          type: "text",
          maxLength: 800,
          required: false,
        },
      },
      {
        label: "Disciplines",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "disciplines",
          placeholder: "Type a discipline...",
          queryName: GET_DISCIPLINES,
          queryKeyName: "disciplines",
          minLength: 0,
          required: false,
        },
      },
      {
        label: "Skills",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "skills",
          placeholder: "Type a skill...",
          queryName: GET_SKILLS,
          queryKeyName: "skills",
          required: true,
          minLength: 1,
        },
      },
      {
        label: "Categories",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "types",
          placeholder: "Select category",
          required: true,
          queryName: GET_CHALLENGE_TYPES,
          queryKeyName: "challengeTypes",
          minLength: 0,
        },
      },
    ],
  },
];

CodeChallengeForm.defaultProps = {
  show: false,
  isEditing: false,
};

CodeChallengeForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
  challengeId: PropTypes.string,
  loading: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default CodeChallengeForm;
