import { gql } from "@apollo/client";

const GET_TEAM_IFRAME_URL = gql`
  query teamIframeUrl($teamId: ID!, $component: TeamComponentEnum!) {
    teamIframeUrl(teamId: $teamId, component: $component) {
      iframeUrl
    }
  }
`;

export { GET_TEAM_IFRAME_URL };
