import React, { createContext, useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { GET_ME } from "graphql/queries";
import { SET_PREFERENCES } from "graphql/mutations";
import { MEMBER_STATUS } from "constants/index";
import useQueryData from "hooks/useQueryData";
import { LINKS, ERROR_CODES } from "constants/index";

const DEFAULT_PROPS = {
  isLoading: true,
  isAuthenticated: false,
};

const AuthContext = createContext(DEFAULT_PROPS);

export function AuthProvider() {
  const [handleSetPreferences] = useMutation(SET_PREFERENCES, { refetchQueries: [GET_ME] });
  const { loading, error, data } = useQueryData({ queryName: GET_ME, keyName: "me" });
  const [me, setMe] = useState(DEFAULT_PROPS);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isValidToken, setIsValidToken] = useState(getToday() < getExpiry());
  const getRole = (str, roles) => roles?.some((role) => role.name === str);
  const isShowcase = getRole("SHOWCASE", data?.me?.roles);

  /**
   * Check if token is still valid every 5 seconds
   */
  useEffect(() => {
    const checkAuth = setInterval(() => setIsValidToken(getToday() < getExpiry()), 5000);
    return () => clearInterval(checkAuth);
  }, [getToday, getExpiry]);

  /**
   * Check error for authentication error
   */
  useEffect(() => {
    if (error) {
      const graphQLErrors = error?.graphQLErrors;

      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          switch (err?.extensions?.code) {
            case ERROR_CODES.authenticationError:
              setIsAuthenticated(false);
              return;
          }
        }
      }
    }
  }, [error]);

  /**
   * Set me object
   */
  useEffect(() => {
    const getPermissions = (str, permissions) =>
      permissions?.some((perm) => perm.action === str && perm.canPerformAction);
    const canViewEditRate = getPermissions("CAN_EDIT_RATE", data?.me?.permissions);
    const canViewUnpublishedRoles = getPermissions("CAN_VIEW_UNPUBLISHED_ROLES", data?.me?.permissions);
    const canViewEditFullTimeEmployment = getPermissions("CAN_EDIT_FULL_TIME_EMPLOYMENT", data?.me?.permissions);
    const isFreelancer = getRole("FREELANCER", data?.me?.roles);
    const isAdmin = getRole("ADMIN", data?.me?.roles);
    const isRecruiter = getRole("RECRUITER", data?.me?.roles);
    const isPartner = getRole("PARTNER", data?.me?.roles);
    const isClient = getRole("CLIENT", data?.me?.roles);
    const isSignalClient = getRole("SIGNAL", data?.me?.roles);
    const isCandidate = data?.me?.profile?.candidate;
    const isMember = data?.me?.profile?.memberSince && data?.me?.profile?.status === MEMBER_STATUS.active;
    const isEligibleForVetting = data?.me?.profile?.eligibleForVetting;
    const notificationSettings = data?.me?.notificationSettings;
    const canApplyForRolesOnBehalf = isPartner;
    const memberRole = isMember
      ? "member"
      : isCandidate
      ? "candidate"
      : String(data?.me?.roles.map((role) => role?.name).join(",")).toLowerCase();

    const updatePreferences = async ({ keyName, keyValue, id, preferences }) => {
      setMe((prev) => ({
        ...prev,
        ...{
          preferences: {
            ...preferences,
            [keyName]: keyValue,
          },
        },
      }));

      await handleSetPreferences({
        variables: {
          input: {
            id,
            params: {
              preferences: {
                ...preferences,
                [keyName]: keyValue,
              },
            },
          },
        },
      });
    };

    setMe({
      ...data?.me,
      memberRole,
      isAdmin,
      isRecruiter,
      isPartner,
      isCandidate,
      isFreelancer,
      isMember,
      isShowcase,
      isEligibleForVetting,
      isClient,
      isSignalClient,
      canViewEditRate,
      canViewUnpublishedRoles,
      notificationSettings,
      canViewEditFullTimeEmployment,
      canApplyForRolesOnBehalf,
      updatePreferences: (payload) =>
        updatePreferences({
          ...payload,
          id: data?.me?.id,
          preferences: data?.me?.preferences,
        }),
      isLoading: loading,
      isAuthenticated: !error,
    });
  }, [data, loading, handleSetPreferences]);

  if (me.isLoading || me.error) return null;

  /**
   * When not authenticated, redirect to login page
   */
  if ((!isAuthenticated || !isValidToken) && !isShowcase) {
    return <Navigate to={LINKS.login} />;

    /**
     * Render children when authenticated
     */
  } else {
    return (
      <AuthContext.Provider value={me}>
        <Outlet data={[me]} />
      </AuthContext.Provider>
    );
  }
}

const getToday = () => Math.floor(Date.now() / 1000);
const getExpiry = () => parseInt(localStorage.getItem("expiry"));

export function useAuth() {
  return useContext(AuthContext);
}
