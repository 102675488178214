import React from "react";
import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQueryData } from "hooks";
import { GET_MISSION } from "graphql/queries";

/**
 * Home
 */
const Mission = () => {
  const { id } = useParams();
  const { loading, data, error } = useQueryData({
    queryName: GET_MISSION,
    keyName: "mission",
    variables: {
      id,
    },
  });

  return <Outlet context={[data, loading, error]} />;
};

export default Mission;
