import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { MUTATION_TYPE, useAssessment, useToast } from "hooks";
import { QuestionsList } from "components/Assessment";
import { Header } from "components/DisplayBox";
import { MODAL_SIZE, ModalForm } from "components/Modal";
import { SIZE, TYPE } from "components/Form";
import { ASSESSMENT_CONFIGS, QUESTION_TYPES } from "constants/index";
import { ADD_MISSION_ROLE_ASSESSMENT_QUESTION, UPDATE_MISSION_ROLE_ASSESSMENT_QUESTION } from "graphql/mutations";
import { ICON_TYPE } from "components/Icon";
import { H3 } from "components/Text";
import { EditLink } from "components/Links";
import { EditButton } from "components/Buttons";

/**
 * RoleQuestionnaire
 *
 * @param {Object}     missionRole
 * @param {Function}   refetch
 * @param {Boolean}    loading
 */
const RoleQuestionnaire = ({ missionRole, refetch, loading: loadingProp, ...props }) => {
  const { toast } = useToast();
  const [showForm, setShowForm] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const { data, loading, handleRemoveQuestion } = useAssessment({
    id: missionRole?.assessmentId,
    resourceType: "open",
  });
  const answerTypeOptions = Object.keys(QUESTION_TYPES)?.map((value) => ({ label: QUESTION_TYPES?.[value], value }));
  const questionsExists = !!data?.questions?.length;
  const assessmentId = missionRole?.assessmentId;
  const isEdit = selectedQuestion?.id;

  const handleClose = () => {
    setShowForm(false);
    setSelectedQuestion({});
  };

  const handleEdit = (question) => {
    setSelectedQuestion(question);
    setShowForm(true);
  };

  const handleComplete = () => {
    if (!missionRole?.assessmentId) {
      refetch();
    }
    handleClose();
    toast.success(<FormattedMessage id={`RoleQuestionnaire.AddQuestionForm.${isEdit ? "Update" : "Create"}Success`} />);
  };

  const handleError = () => {
    toast.error(<FormattedMessage id={`RoleQuestionnaire.AddQuestionForm.${isEdit ? "Update" : "Create"}Error`} />);
  };

  const initialValues = {
    ...(isEdit
      ? {
          answerType: selectedQuestion?.type,
          wording: selectedQuestion?.wording,
          properties: {
            charactersLimit: selectedQuestion?.properties?.charactersLimit?.toString(),
            required: selectedQuestion?.properties?.required,
          },
          assessmentId: missionRole?.assessmentId,
        }
      : {
          ...ASSESSMENT_CONFIGS.missionRole,
          answerType: "textarea",
          assessmentableId: missionRole?.id,
          properties: {
            charactersLimit: "100",
            required: true,
          },
        }),
  };

  const formProps = {
    initialValues,
    mutationType: isEdit ? MUTATION_TYPE.update : MUTATION_TYPE.add,
    updateId: selectedQuestion?.id,
    mutationData: {
      mutationName: {
        add: ADD_MISSION_ROLE_ASSESSMENT_QUESTION,
        update: UPDATE_MISSION_ROLE_ASSESSMENT_QUESTION,
      },
    },
  };

  const rowOptions = (question) => [
    {
      label: <FormattedMessage id="Global.Edit" />,
      icon: ICON_TYPE.edit,
      onClick: () => handleEdit(question),
    },
    {
      label: <FormattedMessage id="Global.Remove" />,
      icon: ICON_TYPE.trash,
      onClick: () => handleRemoveQuestion(assessmentId, question?.id),
    },
  ];

  return (
    <>
      <Header {...props}>
        <H3>
          <FormattedMessage id="Missions.MissionRole.Questionnaire" />
        </H3>
        {questionsExists && (
          <EditLink onClick={() => setShowForm(true)}>
            <FormattedMessage id="RoleQuestionnaire.EditButtonText" />
          </EditLink>
        )}
      </Header>
      {questionsExists ? (
        <QuestionsList
          loading={loading || loadingProp}
          data={data?.questions}
          disablePagination
          rowOptions={rowOptions}
        />
      ) : (
        <EditButton onClick={() => setShowForm(true)}>
          <FormattedMessage id="RoleQuestionnaire.EditButtonText" />
        </EditButton>
      )}
      {showForm && (
        <ModalForm
          data={FORM_DATA(answerTypeOptions)}
          show={showForm}
          onClose={handleClose}
          onCompleted={handleComplete}
          onError={handleError}
          size={MODAL_SIZE.medium}
          numColumns={3}
          title={<FormattedMessage id={`RoleCodeChallenges.${isEdit ? "Edit" : "Add"}QuestionForm.Title`} />}
          {...formProps}
        />
      )}
    </>
  );
};

const FORM_DATA = (answerTypeOptions) => [
  {
    items: [
      {
        label: "Question Text",
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "wording",
          required: true,
        },
      },
      {
        label: "Type",
        type: TYPE.select,
        size: SIZE.small,
        disabled: true,
        properties: {
          options: answerTypeOptions,
          name: "answerType",
          required: true,
        },
      },
      {
        label: "Chars Limit",
        type: TYPE.input,
        size: SIZE.small,
        parentObjectKey: "properties",
        properties: {
          type: "number",
          name: "charactersLimit",
          required: true,
        },
      },
      {
        label: "Required",
        type: TYPE.select,
        size: SIZE.small,
        parentObjectKey: "properties",
        properties: {
          name: "required",
          required: true,
          options: [
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ],
        },
      },
    ],
  },
];

RoleQuestionnaire.defaultProps = {
  refetch: () => {},
};

RoleQuestionnaire.propTypes = {
  missionRole: PropTypes.object,
  refetch: PropTypes.func,
  loading: PropTypes.bool,
};

export default RoleQuestionnaire;
