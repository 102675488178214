import React, { useRef, useEffect, useCallback } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useAuth, useComments } from "hooks";
import { COMMENTABLE_TYPES, DIRECTION } from "constants/index";
import { NoteBody, NotesInputBox } from "components/Notes";
import { Spinner } from "components/Loader";
import SlideOut from "components/SlideOut";
import { colors } from "style";
import Card, { CardPostTitle } from "components/Card";
import { MessagesNoResults } from "components/Messages";

/**
 * Comments
 *
 * @param {String} commentableId
 * @param {String} commentableType
 * @param {Boolean} loading
 * @param {Boolean} show
 * @param {Function} onLoaded
 * @param {Function} onShow
 * @param {Number} resultsPerPage
 */
const Comments = ({ commentableId, commentableType, loading, show, resultsPerPage, onShow, onLoaded, ...props }) => {
  const me = useAuth();
  const messagesEnd = useRef(null);
  const { loading: commentsLoading, data: commentsData } = useComments({
    commentableId,
    commentableType,
    createdAt: DIRECTION.ascending,
    resultsPerPage,
  });

  const handleScrollIntoView = useCallback(() => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messagesEnd]);

  const setMessagesEnd = (node) => {
    messagesEnd.current = node;
    handleScrollIntoView();
  };

  useEffect(() => {
    handleScrollIntoView();
  }, [commentsData, messagesEnd, handleScrollIntoView]);

  useEffect(() => {
    if (!commentsLoading && commentsData?.length > 0) {
      onLoaded?.(commentsData?.length);
    }
  }, [commentsLoading, commentsData]);

  return (
    <>
      <SlideOut title="Comments" show={show} onClose={() => onShow(false)}>
        {(loading || commentsLoading) && <Spinner />}
        {commentsData?.length === 0 && !commentsLoading && (
          <Card>
            <MessagesNoResults title={`Comments.Empty.Title`} description={`Comments.Empty.Description`} />
          </Card>
        )}
        {commentsData?.length > 0 && (
          <div css={styles.comment_container}>
            {commentsData?.map((comment, index) => (
              <div key={comment.id} css={styles.comment}>
                <CardPostTitle user={comment.user} date={comment.createdAt} />
                <NoteBody data={comment} isComment allowReply={false} isLast={index === commentsData.length - 1} />
              </div>
            ))}
            <div ref={setMessagesEnd} />
          </div>
        )}
        <div css={styles.input_container}>
          <NotesInputBox
            user={me}
            commentableId={commentableId}
            commentableType={commentableType}
            placeholder={<FormattedMessage id={`Notes.NoteComments.Placeholder`} />}
            createdAt={DIRECTION.ascending}
            resultsPerPage={resultsPerPage}
            showFormattingOptions={false}
            {...props}
          />
        </div>
      </SlideOut>
    </>
  );
};

const styles = {
  input_container: css`
    border-top: 1px solid ${colors.grayAnatomyLight5};
    width: 100%;
    padding-top: 2rem;
    margin-top: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 2rem;
    padding: 1rem 2rem;
    background: #fff;
    box-shadow: 0px -2px 5px ${colors.grayAnatomyLight5};
  `,
  body: css`
    margin: 0;
    padding: 0;
    border: 0;
    box-shadow: none;
  `,
  container: css`
    height: 100%;
    align-items: flex-start;
  `,
  comment_container: css`
    width: calc(100% + 4rem);
    overflow-y: auto;
    min-height: 100%;
    margin-left: -2rem;
    padding: 2rem 2rem 4rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  `,
};

Comments.defaultProps = {
  resultsPerPage: 100,
};

Comments.propTypes = {
  commentableId: PropTypes.string,
  commentableType: PropTypes.oneOf(Object.values(COMMENTABLE_TYPES)),
  loading: PropTypes.bool,
  show: PropTypes.bool,
  onLoaded: PropTypes.func,
  resultsPerPage: PropTypes.number,
  onShow: PropTypes.func,
};

export default Comments;
