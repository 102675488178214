import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { css } from "@emotion/react";
import { useTeamIframes } from "hooks";
import { useParams } from "react-router-dom";
import { PAGES } from "constants/index";
import PageTitle from "components/PageTitle";
import { Spinner } from "components/Loader";
import { MessagesNoResults } from "components/Messages";
import { PageWrapper, Grid } from "components/Containers";

/**
 * DailyReport
 */
const DailyReport = () => {
  const { id } = useParams();
  const { iFrameUrl } = useTeamIframes({ id, component: PAGES.daily_report });
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const iframeRef = useRef(null);

  const handleResize = () => {
    const iframe = iframeRef?.current;
    const main = iframe?.contentDocument?.querySelector(".squad__profile-main");

    if (iframe && main) {
      main.style.width = `${iframe.clientWidth}px`;
    }
  };

  useEffect(handleResize, [loading]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.DailyReport.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle subtitle={<FormattedMessage id="Squads.DailyReport.Title" />} />
          </Grid.col>
          <Grid.col start={1} end={13}>
            {iFrameUrl ? (
              <>
                {loading && <Spinner size="2rem" />}
                <iframe ref={iframeRef} onLoad={() => setLoading(false)} src={iFrameUrl} css={styles.iframe(loading)} />
              </>
            ) : (
              <MessagesNoResults
                description="Squads.Squad.DailyReport.NoResults.Description"
                title="Squads.Squad.NoResults.Title"
              />
            )}
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

const styles = {
  iframe: (loading) => css`
    width: 100%;
    height: 1500px;
    padding: 0;
    display: ${loading ? "none" : "block"};
  `,
};

export default DailyReport;
