import { gql } from "@apollo/client";

const organizationDataFields = `
  id
  name
  slug
  industries {
    id
    name
  }
  users {
    id
    firstName
    lastName
    email
    name
  }
  noOfEngineers
  noOfEmployees
  country
  state
  city
  location
  primaryContactTitle
  primaryContactPhone
  primaryContactId
  organizationBilling{
    id
    name
    currencyCode
  }
  logoUrl
`;

const ORGANIZATION_FIELDS = gql`
  fragment OrganizationDataFields on Organization {
    ${organizationDataFields}
  }
`;

export { ORGANIZATION_FIELDS };
