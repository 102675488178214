import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { AdvancedButton } from "components/Buttons";
import { ICON_TYPE } from "components/Icon";
import { UserUpdateForm } from "components/Settings/Users";
import { useAuth, useOrganization } from "hooks";
import { LINKS } from "constants/index";

/**
 * ContactAdvanceOption
 * @param {Object}    data - contact data
 * @param {Function}  refetch - refetch organization query
 */
const ContactAdvanceOption = ({ data, refetch, ...props }) => {
  const { handlePrimaryChange, updating } = useOrganization({ id: data?.organization?.id });
  const [showForm, setShowForm] = useState(false);
  const { isAdmin } = useAuth();

  if (!isAdmin) return null;

  const options = [
    {
      label: <FormattedMessage id="Organization.OrganizationContact.ContactAdvanceOption.EditClient" />,
      icon: ICON_TYPE.edit,
      onClick: () => setShowForm(true),
    },
    !data?.primaryContact && {
      label: <FormattedMessage id="Organization.OrganizationContact.ContactAdvanceOption.MarkAsPrimary" />,
      icon: ICON_TYPE.star,
      onClick: () => handlePrimaryChange(data),
    },
    {
      label: (
        <FormattedMessage
          id={"Organization.OrganizationContact.ContactAdvanceOption.BrowseAs"}
          values={{ name: data?.name }}
        />
      ),
      icon: ICON_TYPE.user,
      onClick: () => window.open(LINKS.start_impersonating(data?.id), "_self"),
    },
  ].filter(Boolean);

  return (
    <>
      <AdvancedButton options={options} loading={updating} />
      <UserUpdateForm
        show={showForm}
        onClose={() => setShowForm(false)}
        isEditing={true}
        userData={{ ...data, type: data?.role }}
        refetch={refetch}
        {...props}
      />
    </>
  );
};

ContactAdvanceOption.propTypes = {
  data: PropTypes.object.isRequired,
  refetch: PropTypes.func,
};

export default ContactAdvanceOption;
