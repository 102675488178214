/**
 * Filter an array of values by a function
 *
 * @param {Array}     arr
 * @param {String}    key
 * @param {Function}  func
 *
 * @returns {Array} the filtered aray
 */

export default function filterByFunction(arr, key, func) {
  return arr.filter((val) => func(val[key]));
}
