import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { fontSize, colors } from "style";
import Card from "components/Card";
import { UserPhotoList } from "components/User";
import { TextLoader } from "components/Loader";
import { formatNumber } from "utils";

/**
 * TeamCard
 *
 * @param {Object}      data
 * @param {Function}    onClick
 * @param {Array}       actions
 */
const TeamCard = ({ data, onClick, actions, loading, ...props }) => {
  if (loading) {
    return <PlaceHolder />;
  }

  return (
    <StyledCard onClick={() => onClick(data)}>
      <div css={styles.thumbnails_container}>
        {data.profiles.length > 0 ? (
          <UserPhotoList
            key={data.id}
            users={data.profiles}
            thumbCount={data.profiles.length}
            thumbLimit={3}
            thumbSize="5rem"
            thumbBorderRadius="100%"
            {...props}
          />
        ) : (
          <div css={styles.no_members} />
        )}
      </div>
      <div css={styles.title}>{data.title}</div>
      <div css={styles.members}>
        <FormattedMessage
          id="Teams.TeamCard.Members"
          values={{
            count: data.profiles.length,
            members: formatNumber(data.profiles.length),
          }}
        />
      </div>
      {actions?.length > 0 && (
        <div css={styles.button_container} className="actions">
          {actions.map(({ component: Component, args }, index) => (
            <Component key={index} {...args} />
          ))}
        </div>
      )}
    </StyledCard>
  );
};

const PlaceHolder = () => (
  <StyledCard>
    <div css={styles.thumbnails_container}>
      <UserPhotoList thumbCount={3} thumbLimit={3} loading={true} thumbSize="5rem" />
    </div>
    <div css={styles.title}>
      <TextLoader width="70%" />
    </div>
    <div css={styles.members}>
      <TextLoader width="50%" />
    </div>
  </StyledCard>
);

const styles = {
  thumbnails_container: css`
    display: flex;
    gap: 1.25rem;
    margin: 2rem 1rem 1rem 1rem;
    z-index: 0;
    justify-content: center;
  `,
  no_members: css`
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    background: ${colors.grayAnatomyLight5};
  `,
  title: css`
    font-size: ${fontSize.normal};
    font-weight: 600;
  `,
  members: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyLight1};
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  `,
  badge: css`
    font-size: ${fontSize.xsmall};
    color: ${colors.grayAnatomyLight1};
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 15px;
    font-weight: bold;
    margin-top: 7.5rem;
    align-self: flex-start;
  `,
  location: css`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
  `,
  country: css`
    font-size: ${fontSize.xxsmall};
    color: ${colors.grayAnatomyLight1};
    font-weight: 500;
  `,
  button_container: css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0 0 0.9rem 0.9rem;
    background: #fff;
    padding: 1rem;
    gap: 0.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-weight: 500;
    color: ${colors.purpleRainBase};
    font-size: ${fontSize.small};

    > * {
      width: 100%;
    }
  `,
};

TeamCard.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  actions: PropTypes.array,
  loading: PropTypes.bool,
};

const StyledCard = styled(Card)`
  padding: 2rem 2rem 0rem 2rem;
  text-align: center;
  margin: 0;
  min-height: 20rem;
  justify-content: center;
  cursor: pointer;

  .actions {
    opacity: 0;
    z-index: 0;
  }

  &:hover {
    border-color: ${colors.purpleRainBase};
    box-shadow: 0 0 0 5px ${colors.purpleRainLight3};

    .actions {
      opacity: 1;
    }
  }
`;

export default TeamCard;
