import React, { useState } from "react";
import PropTypes from "prop-types";
import Card, { CardMenu, CardPostTitle } from "components/Card";
import { NoteBody, NoteComments, NoteTags, NoteAdvancedOptions } from "components/Notes";

/**
 * Note Card
 *
 * @param {Object}  data
 * @param {Object}  user
 * @param {Boolean} isLoading
 */
const NoteCard = ({ data, user, isLoading, ...props }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Card {...props}>
      <CardMenu>
        <NoteAdvancedOptions data={data} onEdit={setIsEditing} />
      </CardMenu>
      <CardPostTitle user={data?.user} date={data?.createdAt} />
      <NoteBody data={data} isEditing={isEditing} onEdit={setIsEditing} />
      <NoteTags data={data} />
      {!isLoading && <NoteComments commentableId={data?.id} user={user} />}
    </Card>
  );
};

NoteCard.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

export default NoteCard;
