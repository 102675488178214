import React from "react";
import { css } from "@emotion/react";
import { colors } from "style";
import PrimaryLink from "./Primary";

/**
 * SecondaryLink
 *
 */
const SecondaryLink = ({ children, ...props }) => (
  <PrimaryLink css={styles.link} {...props}>
    {children}
  </PrimaryLink>
);


const styles = {
  link: css`
    color: ${colors.grayAnatomyDark};
    font-weight:500;
    
  `,
};

export default SecondaryLink;
