import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { BILLING_ACCOUNT_INTERVALS, TERM_SHEET_SCENARIOS } from "constants/index";
import { Row } from "components/Containers";
import { Select } from "components/Form";
import { useModalConfirmation, useTermSheetScenarios } from "hooks";
import TermSheetScenarioAddButton from "./TermSheetScenarioAddButton";
import TermSheetScenarioAdvancedOptions from "./TermSheetScenarioAdvancedOptions";
import { colors } from "style";
import { TermSheetScenarioScheduleButton } from ".";

/**
 * TermSheetScenarios
 *
 * @param {Object}    teamId
 * @param {Object}    scenario
 * @param {String}    actualBillingAccountId
 * @param {String}    actualBillingInterval
 * @param {Function}  onChange
 * @param {Function}  onScenarioEdit
 * @param {Function}  onSchedule
 * @param {Object}    changes
 * @param {Boolean}   requireSave
 */
const TermSheetScenarios = ({
  teamId,
  scenario,
  actualBillingAccountId,
  actualBillingInterval,
  onChange,
  onScenarioEdit,
  onSchedule,
  changes,
  requireSave,
  ...props
}) => {
  const { data, loading } = useTermSheetScenarios({ teamId, state: TERM_SHEET_SCENARIOS.active });
  const [selectedScenario, setSelectedScenario] = useState(scenario);
  const { show } = useModalConfirmation();

  /**
   * @description Change the selected scenario - check for unsaved changes
   *
   * @param {String} name
   * @param {String} val
   */
  const handleChangeScenario = useCallback(
    async (name, val) => {
      const response = !requireSave
        ? true
        : await show(
            <FormattedMessage id={"TermSheets.TermSheetScenario.PromptSaveTitle"} />,
            <FormattedMessage
              id="TermSheets.TermSheetScenario.PromptSaveDescription"
              values={{
                prompt: (
                  <p>
                    <b>
                      <FormattedMessage id="TermSheets.TermSheetScenario.PromptSaveConfirm" />
                    </b>
                  </p>
                ),
              }}
            />,
            {
              primaryButtonMessageId: "Global.Yes",
            }
          );

      if (response) {
        onChange(data?.find(({ id }) => id === val));
      }
    },
    [data, requireSave]
  );

  useEffect(() => {
    setSelectedScenario(scenario);
  }, [scenario]);

  return (
    <StyledRow {...props}>
      <StyledSelect
        options={data?.map(({ formattedName, id }) => ({ label: formattedName, value: id }))}
        onChange={handleChangeScenario}
        value={selectedScenario?.id}
        loading={loading}
        disabled={loading}
        isClearable={true}
        placeholder="Select scenario"
      />
      <TermSheetScenarioAdvancedOptions
        disabled={!selectedScenario}
        onRemove={() => setSelectedScenario(false)}
        loading={loading}
        teamId={teamId}
        onComplete={(scenario) => {
          setSelectedScenario(scenario);
          onScenarioEdit?.(scenario);
        }}
        data={{
          id: selectedScenario?.id,
          name: selectedScenario?.name,
          type: selectedScenario?.type,
          organizationBillingAccountId: selectedScenario?.billingAccount?.id,
          teamId,
        }}
      />
      <Divider />
      <TermSheetScenarioAddButton
        data={{
          teamId,
          organizationBillingAccountId: actualBillingAccountId,
          type: BILLING_ACCOUNT_INTERVALS[actualBillingInterval],
        }}
        onComplete={onChange}
      />
      <RightRow>
        <TermSheetScenarioScheduleButton
          selectedScenario={selectedScenario}
          teamId={teamId}
          changes={changes}
          onComplete={onSchedule}
        />
      </RightRow>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  flex: 1;
`;

const StyledSelect = styled(Select)`
  width: 30rem;
  order: 0;
`;

const Divider = styled.div`
  width: 1px;
  background: ${colors.grayAnatomyLight3};
  height: 3rem;
  margin: 0 1rem 0 0rem;
`;

const RightRow = styled.div`
  margin-left: auto;
`;

TermSheetScenarios.propTypes = {
  teamId: PropTypes.object.isRequired,
  scenario: PropTypes.object,
  actualBillingAccountId: PropTypes.string.isRequired,
  actualBillingInterval: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onScenarioEdit: PropTypes.func,
  onSchedule: PropTypes.func,
  changes: PropTypes.object,
  requireSave: PropTypes.bool,
};

export default TermSheetScenarios;
