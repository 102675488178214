import React from 'react'
import { render as rtlRender } from '@testing-library/react'
import { IntlProvider } from "react-intl";
import locales from "locales/data.json";
import { configure } from 'enzyme';
import Adapter from '@wojtekmaj/enzyme-adapter-react-17';

configure({ adapter: new Adapter() });

/**
 * Wrap providers and export libraries
 */
function render(ui, { locale = "en", ...renderOptions } = {}) {
  function Wrapper({ children }) {
    return (
      <IntlProvider
        locale={locale}
        messages={locales[locale]}>
        {children}
      </IntlProvider>
    );
  }
  return rtlRender( ui, { wrapper: Wrapper, ...renderOptions })
}

function setStubs() {
  // Prevent media elements from returning from triggering error: 
  // Not implemented: HTMLMediaElement.prototype
  window.HTMLMediaElement.prototype.load = () => { };
  window.HTMLMediaElement.prototype.play = () => { };
  window.HTMLMediaElement.prototype.pause = () => { };
  window.HTMLMediaElement.prototype.addTextTrack = () => { };  
}

export { shallow } from 'enzyme'
export * from '@testing-library/react'
export * from '@testing-library/jest-dom';
export * from 'react-dom/test-utils';
export { render, setStubs }
