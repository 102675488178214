import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { PrimaryLink } from "components/Links";
import { colors } from "style";

/**
 * DataTable Cell Content
 *
 * @params {Object}   rowData
 * @params {Object}   colData
 * @params {Object}   previousRowData
 */
const DataTableCellContent = ({ rowData, colData, previousRowData }) => {
  const isDisabledRow = colData.disabled && colData.disabled(rowData);

  if (typeof colData.onClick === "function") {
    return (
      <span>
        <PrimaryLink onClick={() => colData.onClick(rowData)}>{colData.cell(rowData)}</PrimaryLink>
      </span>
    );
  } else {
    return <span css={styles.cell(isDisabledRow)}>{colData.cell(rowData, previousRowData)}</span>;
  }
};

const styles = {
  cell: (isDisabled) => css`
    color: ${isDisabled ? colors.grayAnatomyLight3 : "inherit"};
  `,
};

DataTableCellContent.propTypes = {
  rowData: PropTypes.object.isRequired,
  colData: PropTypes.object.isRequired,
  previousRowData: PropTypes.object,
};

export default DataTableCellContent;
