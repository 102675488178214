const NO_OF_SOFTWARE_ENGINEERS = [
  { value: '1 to 4', label: '1 to 4' },
  { value: '5 to 9', label: '5 to 9' },
  { value: '10 to 19', label: '10 to 19' },
  { value: '20 to 29', label: '20 to 29' },
  { value: '30 to 39', label: '30 to 39' },
  { value: '40 to 50', label: '40 to 50' },
  { value: '50 or more', label: '50 or more' },
];

export default NO_OF_SOFTWARE_ENGINEERS;
