import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useToast, MUTATION_TYPE } from "hooks";
import { convertUrlToFileObject } from "utils";
import { DECORATORS, SIZE, TYPE } from "components/Form";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { GET_EVENTS, GET_TIME_ZONES } from "graphql/queries";
import { ICON_TYPE, ICON_SIZE, ICON_POSITION } from "components/Icon";
import { ADD_MISSION_EVENT, UPDATE_MISSION_EVENT } from "graphql/mutations";

/**
 * Event Form
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Object}    data
 * @param {Boolean}   loading
 * @param {Boolean}   isEditing
 */
const EventForm = ({ show, onClose, data, loading, isEditing }) => {
  const { toast } = useToast();
  const [image, setImage] = useState();

  if (isEditing && loading) return null;

  useEffect(() => {
    if (data?.displayImageUrl && data?.fileName) {
      convertUrlToFileObject(data?.displayImageUrl, data?.fileName).then((res) => {
        setImage(res);
      });
    }
  }, [data?.displayImageUrl, data?.fileName]);

  const handleComplete = () => {
    onClose();
    toast.success(<FormattedMessage id={`Events.EventForm.${isEditing ? `EditSuccess` : `CreateSuccess`}`} />);
  };

  const handleError = () => {
    toast.error(<FormattedMessage id={`Events.EventForm.${isEditing ? `EditError` : `CreateError`}`} />);
  };

  return (
    <ModalForm
      data={FORM_DATA}
      show={show}
      size={MODAL_SIZE.medium}
      onClose={onClose}
      title={<FormattedMessage id={`Events.EventForm.${isEditing ? `Edit` : `Create`}FormTitle`} />}
      description={<FormattedMessage id="Events.EventForm.FormDescription" />}
      onCompleted={handleComplete}
      onError={handleError}
      initialValues={{
        displayImageUrl: image,
        name: data?.name,
        title: data?.title,
        url: data?.url,
        recordingLink: data?.recordingLink,
        startsAt: data?.startsAt,
        timezone: data?.timezone?.value,
        description: data?.description,
        hidden: data?.hidden,
      }}
      updateId={isEditing ? data?.id : null}
      mutationType={isEditing ? MUTATION_TYPE.update : MUTATION_TYPE.add}
      mutationData={{
        mutationName: {
          add: ADD_MISSION_EVENT,
          update: UPDATE_MISSION_EVENT,
        },
        refetchAfterMutate: true,
        refetchQueries: [GET_EVENTS],
      }}
    />
  );
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,

    items: [
      {
        label: "Name",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "name",
          type: "text",
          maxLength: 100,
          required: true,
        },
      },
      {
        label: "Title",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "title",
          maxLength: 100,
        },
      },
      {
        label: "URL",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "url",
          maxLength: 100,
        },
      },
      {
        label: "Recording Link",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "recordingLink",
          maxLength: 100,
        },
      },
      {
        label: "Start at",
        type: TYPE.datePicker,
        size: SIZE.medium,
        properties: {
          name: "startsAt",
          required: true,
        },
      },
      {
        label: "Time zone",
        type: TYPE.select,
        size: SIZE.medium,
        properties: {
          name: "timezone",
          queryName: GET_TIME_ZONES,
          queryKeyName: "timezones",
          labelKey: "name",
          required: true,
        },
        decorator: {
          type: DECORATORS.icon,
          props: {
            type: ICON_TYPE.pin,
            size: ICON_SIZE.medium,
            position: ICON_POSITION.overlay,
          },
        },
      },
      {
        label: "Description",
        type: TYPE.textarea,
        size: SIZE.xlarge,
        properties: {
          name: "description",
          maxLength: 3000,
          required: true,
        },
      },
      {
        label: "Status",
        type: TYPE.select,
        size: SIZE.medium,
        properties: {
          name: "hidden",
          required: true,
          options: [
            {
              label: "Enabled",
              value: false,
            },
            {
              label: "Disabled",
              value: true,
            },
          ],
        },
      },
      {
        type: TYPE.photo,
        size: SIZE.xlarge,
        properties: {
          name: "displayImageUrl",
        },
      },
    ],
  },
];

EventForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
  loading: PropTypes.bool,
  isModal: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default EventForm;
