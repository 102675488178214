import { gql } from "@apollo/client";
import { ORGANIZATION_FIELDS, BASIC_MISSION_FIELDS } from "graphql/fragments";

const GET_ORGANIZATION = gql`
  ${ORGANIZATION_FIELDS}
  ${BASIC_MISSION_FIELDS}
  query GetOrganization($id: ID!, $includeMissions: Boolean = false) {
    organization(id: $id) {
      ...OrganizationDataFields
      missions @include(if: $includeMissions) {
        ...BasicMissionFields
      }
    }
  }
`;

export { GET_ORGANIZATION };
