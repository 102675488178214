import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { PAGE_WRAPPER_MAX_WIDTH, PAGE_WRAPPER_SIZE } from "components/Containers/PageWrapper";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { breakpoints, fontSize, gradients } from "style";
import TextInputWithOnChange from "components/Form/TextInput";

/**
 * SearchInput
 *
 * @param {Element}   children
 * @param {Function}  onChange
 * @param {String}    initialValue
 */
const SearchInput = ({ children, onChange, initialValue, ...props }) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (name, val) => setValue(val);

  const handleSubmit = (event) => {
    event.preventDefault();
    onChange(value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div css={styles.container} {...props}>
        <div css={styles.inner_container}>
          {children}
          <div css={styles.search_container}>
            <Icon type={ICON_TYPE.search} size={ICON_SIZE.xlarge} />
            <StyledTextInput
              type="search"
              placeholder="ie: Stripe API, Healthcare, Solidity Smart Contract, etc..."
              onChange={handleChange}
              value={value}
              autoFocus
            />
          </div>
        </div>
      </div>
    </form>
  );
};

const StyledTextInput = styled(TextInputWithOnChange)`
  padding: 0;
  border: 0;
  background: transparent;
  font-size: ${fontSize.normal};
  height: 6rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 2rem 0 5rem;
  flex: 1;
  box-shadow: none !important;

  @media (max-width: ${breakpoints.mobile}) {
    height: 5rem;
    font-size: ${fontSize.small};
  }
`;

const styles = {
  container: css`
    width: 100%;
    background: ${gradients.lightBluePinkGradient};
  `,
  inner_container: css`
    padding: 4rem ${PAGE_WRAPPER_SIZE} 0 ${PAGE_WRAPPER_SIZE};
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    max-width: ${PAGE_WRAPPER_MAX_WIDTH};
    margin: 0 auto 6rem auto;

    @media (max-width: ${breakpoints.mobile}) {
      padding: 2rem 2rem;
      margin-bottom: 4rem;
    }
  `,
  search_container: css`
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.06);
    border-radius: 0.6rem;
    background: #fff;
    height: 6rem;
    width: 68rem;
    max-width: 100%;
    position: relative;
    top: 3rem;
    margin: -3rem auto 0 auto;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    position: relative;

    @media (max-width: ${breakpoints.mobile}) {
      padding: 2rem 2rem;
      margin: -4.5rem auto 0 auto;
      top: 4.5rem;
      height: 5rem;
    }
  `,
};

SearchInput.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
};

export default SearchInput;
