import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { breakpoints, colors, margins } from "style";
import { PAGE_WRAPPER_MAX_WIDTH, PAGE_WRAPPER_SIZE } from "components/Containers/PageWrapper";

/**
 * Subnavigation
 *
 * @param {Element}   children
 */
const SubNav = ({ children, ...props }) => (
  <div css={styles.container} {...props}>
    <div css={styles.inner_container}>
      <div css={styles.offset}>{children}</div>
    </div>
  </div>
);

const styles = {
  container: css`
    background: ${colors.grayAnatomyLight4};
    padding: 2rem 0;
  `,
  inner_container: css`
    max-width: ${PAGE_WRAPPER_MAX_WIDTH};
    margin: 0 auto;
    padding: 0 ${PAGE_WRAPPER_SIZE} 0 ${PAGE_WRAPPER_SIZE};
  `,
  offset: css`
    margin: 0 ${margins.normal};

    @media (max-width: ${breakpoints.portrait}) {
      margin: 0 2.5rem;
    }

    @media (max-width: ${breakpoints.mobile}) {
      margin: 0 2rem;
    }
  `,
};

SubNav.propTypes = {
  children: PropTypes.any.isRequired,
};

export default SubNav;
