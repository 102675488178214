import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { css } from "@emotion/react";
import { NarrowContainer } from "components/Containers";
import { H1 } from "components/Text";
import { fontSize } from "style";
import Card from "components/Card";
import { TermsAndConditionsContent } from "components/Legal";

/**
 * Terms and Conditions
 */
const TermsAndConditions = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "Meta.Legal.TermsAndConditions.Title" })}
        </title>
      </Helmet>
      <NarrowContainer>
        <Card css={styles.container}>
          <H1>
            <FormattedMessage id="TermsAndConditions.H1" />
          </H1>
          <TermsAndConditionsContent />
        </Card>
      </NarrowContainer>
    </>
  );
}

const styles = {
  container: css`
    font-size: ${fontSize.normal};
    line-height: 1.5;
    padding: 1rem 3rem;

    h1,
    h2 {
      margin-left: 0;
      margin-bottom: 1rem;
      margin-top: 2rem;
    }

    p {
      margin-bottom: 1rem;
    }

    b {
      font-weight: 600;
    }
  `,
};

export default TermsAndConditions;
