import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Modal, { MODAL_SIZE } from "components/Modal";
import { css } from "@emotion/react";
import { fontSize } from "style";

/**
 * ConfirmationModal
 *
 * @params {boolean}   show
 * @params {node}      children
 * @params {func}      onClose
 * @params {func}      onSubmit
 */
const ConfirmationModal = ({ show, onClose, onSubmit, children }) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      onSubmit={onSubmit}
      canSubmit={true}
      showCancel={true}
      showSave={true}
      size={MODAL_SIZE.medium}
      primaryButtonMessageId="Global.Yes"
      title={<FormattedMessage id="Global.Confirmation" />}
    >
      <div css={styles.text}>{children}</div>
    </Modal>
  );
};

const styles = {
  text: css`
    font-size: ${fontSize.small};
    line-height: 1.33;
  `,
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ConfirmationModal;
