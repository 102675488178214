import { GET_INSIGHTS } from "graphql/queries";

const MOCK_GET_INSIGHTS = {
  request: {
    query: GET_INSIGHTS,
    variables: {
      orderBy: {},
      first: null,
      last: null,
      after: null,
    },
  },
  result: {
    data: {
      insightCharts: {
        insightsTimeChartLink:
          "https://insights.mission.dev/charts/platform/profile/yaseen-ahmed-a48b3956/chart/profile_time_logged",
        insightsCommitChartLink: null,
        __typename: "InsightChart",
      },
    },
  },
};

export { MOCK_GET_INSIGHTS };
