import { gql } from "@apollo/client";

const GET_TIME_ZONES = gql`
  query GetTimezones {
    timezones {
      nodes {
        name
        value
      }
    }
  }
`;

export { GET_TIME_ZONES };
