import React from "react";
import PropTypes from "prop-types";
import { HelmetProvider } from "react-helmet-async";
import { ApolloProvider } from "@apollo/client";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { Global } from "@emotion/react";
import { CLIENT, LOCALE } from "../constants";
import locales from "locales/data.json";
import Routes from "routes";
import { themes, ThemeContext } from "style";
import globalStyles from "style/global.css";
import Toast from "components/Toast";
import { ModalConfirmationProvider } from "components/Modal";
import Tracker from "components/Tracker";

/**
 * App With Router
 *
 * TODO: Move to root folder
 */
const AppWithRouter = ({ theme }) => (
  <BrowserRouter>
    <ThemeContext.Provider value={theme}>
      <HelmetProvider>
        <ApolloProvider client={CLIENT}>
          <IntlProvider locale={LOCALE} messages={locales[LOCALE]}>
            <ModalConfirmationProvider>
              <Global styles={globalStyles} />
              <Routes />
              <Toast />
              <Tracker />
            </ModalConfirmationProvider>
          </IntlProvider>
        </ApolloProvider>
      </HelmetProvider>
    </ThemeContext.Provider>
  </BrowserRouter>
);

AppWithRouter.defaultProps = {
  theme: themes.app,
};

AppWithRouter.propTypes = {
  theme: PropTypes.object,
};

export default AppWithRouter;
