import { gql } from "@apollo/client";
import { CORE_PROFILE_FIELDS } from "graphql/fragments";

const GET_PROFILE_EVENTS = gql`
  ${CORE_PROFILE_FIELDS}
  query profileEvents(
    $profileId: ID!
    $filters: ProfileEventFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    profileEvents(
      profileId: $profileId
      filters: $filters
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      nodes {
        createdAt
        uuid
        key
        payload
        type
        impersonator {
          id
          firstName
          lastName
          name
        }
        user {
          id
          profile {
            ...ProfileFields
          }
          firstName
          lastName
          name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export { GET_PROFILE_EVENTS };
