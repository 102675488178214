import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors } from "style";
import Link from "./Link";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";

/**
 * Primary
 *
 * @params {Boolean}   withMoreIcon
 * @params {Boolean}   withUnderline
 */
const PrimaryLink = ({ withMoreIcon, withUnderline, ...props }) => {
  const LinkChild = () => <span className="link-text">{props.children}</span>;

  if (withMoreIcon) {
    return (
      <span css={styles.container}>
        <Link css={styles.link(withUnderline)} {...props}>
          <LinkChild />
          <div css={styles.icon} className="link-icon">
            <Icon type={ICON_TYPE.chevronDown} size={ICON_SIZE.small} color={"inherit"} />
          </div>
        </Link>
      </span>
    );
  } else {
    return (
      <Link css={styles.link(withUnderline)} {...props}>
        <LinkChild />
      </Link>
    );
  }
};

const styles = {
  container: css`
    display: inline-flex;
  `,
  icon: css`
    margin-left: 0.15rem;
    transform: rotate(-90deg) translatey(0.5rem);
    transition: all 0.3s ease;
  `,
  link: (withUnderline) => css`
    display: inline-flex;
    align-items: center;
    color: ${colors.purpleRainBase};
    cursor: pointer;
    font-weight: 600;
    position: relative;
    transition: all 0.3s ease;

    &:hover {
      color: ${colors.purpleRainDark1};

      .link-text {
        &:after {
          ${withUnderline &&
          `
            border-bottom: 1px ${colors.purpleRainLight1} solid;
          `}
        }
      }

      .link-icon {
        transform: rotate(-90deg) translatey(0.75rem);
      }
    }

    .link-text {
      position: relative;
      display: inline-flex;
      align-items: center;
      width: 100%;

      &:after {
        content: " ";
        position: absolute;
        width: 100%;
        bottom: -0.25rem;
        height: 1px;
        display: block;
        transition: transform 0.3s ease;
        ${withUnderline &&
        `
          border-bottom: 1px ${colors.purpleRainLight2} solid;
        `}
      }
    }
  `,
};

PrimaryLink.propTypes = {
  withMoreIcon: PropTypes.bool,
  withUnderline: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default PrimaryLink;
