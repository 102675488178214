/** @jsx jsx */
import styled from "@emotion/styled";
import { colors, fontSize } from "style";
import { BUTTON_SIZE } from "components/Buttons";

/**
 * Secondary
 */
const SecondaryButton = styled.button`
  height: ${(props) => props.size || BUTTON_SIZE.medium};
  min-height: ${(props) => props.size || BUTTON_SIZE.medium};
  font-size: ${fontSize.small};
  font-weight: 600;
  color: ${colors.purpleRainBase};
  background: #fff;
  border-radius: 0.6rem;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.2rem;
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;
  white-space: nowrap;
  gap: 0.5rem;

  ${(props) =>
    props.size === BUTTON_SIZE.small &&
    `
    padding: 0 1rem;
    font-size: ${fontSize.xsmall};
    font-weight:500;
    border-radius: 0.5rem;
  `}

  &:hover:not(:disabled) {
    box-shadow: 0 1px 6px 0 rgba(56, 51, 148, 0.3);
  }

  &:disabled {
    color: ${colors.grayAnatomyLight2};
    box-shadow: none;
  }

  &:focus {
    box-shadow: 0px 0px 0px 3px ${colors.purpleRainLight3};
  }
`;

export default SecondaryButton;
