import { gql } from "@apollo/client";
import { ORGANIZATION_FIELDS } from "graphql/fragments";

const ADD_ORGANIZATION = gql`
  mutation AddOrganization($input: AddOrganizationInput!) {
    addOrganization(input: $input) {
      organization {
        id
        name
        slug
      }
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  ${ORGANIZATION_FIELDS}
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        ...OrganizationDataFields
      }
    }
  }
`;

const ADD_ORGANIZATION_BILLING = gql`
  mutation AddOrganizationBilling($input: AddOrganizationBillingInput!) {
    addOrganizationBilling(input: $input) {
      organization {
        id
        name
        currencyCode
      }
    }
  }
`;

const UPDATE_ORGANIZATION_BILLING = gql`
  mutation UpdateOrganizationBilling($input: UpdateOrganizationBillingInput!) {
    updateOrganizationBilling(input: $input) {
      organization {
        id
        name
        currencyCode
      }
    }
  }
`;

const ADD_ORGANIZATION_CONTACT = gql`
  mutation AddOrganizationContact($input: AddOrganizationContactInput!) {
    addOrganizationContact(input: $input) {
      organization {
        id
        name
      }
    }
  }
`;

const UPDATE_ORGANIZATION_CONTACT = gql`
  mutation UpdateOrganizationContact($input: UpdateOrganizationContactInput!) {
    updateOrganizationContact(input: $input) {
      organization {
        id
        name
        users {
          id
          firstName
          lastName
          email
          name
        }
        primaryContactTitle
        primaryContactPhone
        primaryContactId
      }
    }
  }
`;

const UPLOAD_LOGO = gql`
  mutation UpdateOrganizationLogo($input: UpdateOrganizationLogoInput!) {
    updateOrganizationLogo(input: $input) {
      organization {
        id
        logoUrl
      }
    }
  }
`;

const ARCHIVE_ORGANIZATION = gql`
  mutation ArchiveOrganization($input: ArchiveOrganizationInput!) {
    archiveOrganization(input: $input) {
      organization {
        id
        status
      }
    }
  }
`;

const RESTORE_ORGANIZATION = gql`
  mutation RestoreOrganization($input: RestoreOrganizationInput!) {
    restoreOrganization(input: $input) {
      organization {
        id
        status
      }
    }
  }
`;

export {
  ADD_ORGANIZATION,
  ADD_ORGANIZATION_BILLING,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_BILLING,
  UPLOAD_LOGO,
  ADD_ORGANIZATION_CONTACT,
  UPDATE_ORGANIZATION_CONTACT,
  ARCHIVE_ORGANIZATION,
  RESTORE_ORGANIZATION,
};
