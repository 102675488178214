import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Label, Select } from "components/Form";
import { Row } from "components/Containers";
import Icon, { ICON_TYPE } from "components/Icon";

/**
 * TermSheetCurrencyConverter
 *
 * @param {String}   billingCurrency
 * @param {String}   billingInterval
 * @param {Function} onChange
 */
const TermSheetCurrencyConverter = ({ billingCurrency, billingInterval, onChange }) => {
  const [values, setValues] = useState({
    sellingCurrency: billingCurrency,
    currency: billingCurrency,
    interval: billingInterval,
  });

  const handleChange = (n, v) => setValues((prev) => ({ ...prev, [n]: v }));

  useEffect(() => {
    onChange(values);
  }, [values]);

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      sellingCurrency: billingCurrency,
      currency: billingCurrency,
      interval: billingInterval,
    }));
  }, [billingCurrency, billingInterval]);

  return (
    <Row>
      <StyledLabel>
        <FormattedMessage id="TermSheets.TermSheetCurrencyConverter.Label" />
      </StyledLabel>
      <StyledValue>{values.sellingCurrency}</StyledValue>
      <StyledLabel>
        <Icon type={ICON_TYPE.arrowRight} />
      </StyledLabel>
      <StyledSelect name="currency" options={options.currency} onChange={handleChange} value={values.currency} />
      <StyledLabel>
        <Icon type={ICON_TYPE.clock} />
      </StyledLabel>
      <StyledSelect
        name="interval"
        disabled={true}
        options={options.interval}
        onChange={handleChange}
        value={values.interval}
      />
    </Row>
  );
};

const StyledSelect = styled(Select)`
  width: 11rem;
  order: 0;
`;

const StyledLabel = styled(Label)`
  margin: 0;
`;

const StyledValue = styled.span`
  font-weight: 300;
`;

const options = {
  interval: [
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
  ],
  currency: [
    { label: "CAD", value: "CAD" },
    { label: "USD", value: "USD" },
  ],
};

TermSheetCurrencyConverter.propTypes = {
  billingCurrency: PropTypes.string,
  billingInterval: PropTypes.string,
  onChange: PropTypes.func,
};

export default TermSheetCurrencyConverter;
