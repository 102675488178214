import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { PageWrapper, Grid } from "components/Containers";
import Card from "components/Card";
import { EventsList } from "components/Events";
import { MissionContacts } from "components/Missions";
import { SquadsHighlight } from "components/Squads";
import { useAuth, useTracking } from "hooks";
import { OrganizationCard } from "components/Organization";
import { ShowcaseSignUpCard } from "components/Showcase";
import PageTitle from "components/PageTitle";
import { fontSize } from "style";
import { LINKS, PAGES, TRACKING_PAGE_VIEWS } from "constants/index";

/**
 * ClientHome
 */
const ClientHome = () => {
  const { isClient, isShowcase, ...me } = useAuth();
  const navigate = useNavigate();
  const intl = useIntl();
  const { trackPage } = useTracking();

  useEffect(() => {
    if (me.isShowcase) {
      trackPage(TRACKING_PAGE_VIEWS[PAGES.showcaseHome].page_name, TRACKING_PAGE_VIEWS[PAGES.showcaseHome]);
    }
  }, [me]);

  useEffect(() => {
    if (isClient || isShowcase) {
      navigate(LINKS.client_search);
    }
  }, [isClient, isShowcase]);

  if (isClient || isShowcase) return null;

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Client.Home.Title" })}</title>
      </Helmet>
      <StyledPageTitle
        title={
          <Title>
            <FormattedMessage id="Missions.MissionBoardSummary.H1" values={{ name: me.firstName }} />
          </Title>
        }
      />
      <Grid>
        <Grid.col start={1} end={9}>
          <Card>
            <EventsList />
          </Card>
        </Grid.col>
        <Grid.col start={9} end={13}>
          <Card>{me.isShowcase ? <ShowcaseSignUpCard /> : <OrganizationCard data={me} />}</Card>
          <Card>
            <MissionContacts />
          </Card>
          <Card>
            <SquadsHighlight title={<FormattedMessage id="Home.MissionsHighlightTitle" />} />
          </Card>
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

const StyledPageTitle = styled(PageTitle)`
  margin-bottom: 2rem;
`;

const Title = styled.span`
  font-size: ${fontSize.xxxxlarge};
  font-weight: 700;
`;

export default ClientHome;
