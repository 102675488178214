import toast from "react-hot-toast";

/**
 * Toast messages hook
 */
export default function useToast() {
  //const notify = (msg) => toast(msg);

  return {
    toast,
  };
}
