import moment from "moment";

/**
 * Get the year from a string date
 *
 * @param {string} str
 *
 * @returns {string}
 */
export default function getYearFromDate(str) {
  if (!str) {
    return;
  }

  return str && String(moment(str).format("YYYY"));
}
