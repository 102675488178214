import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import { Grid, PageWrapper } from "components/Containers";
import PageTitle from "components/PageTitle";
import { CreateListModal, ListsList } from "components/Lists";
import Card from "components/Card";
import { PrimaryButton } from "components/Buttons";

/**
 * Lists
 */
const Lists = () => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const intl = useIntl();

  const handleListCreateComplete = () => {
    setShowCreateForm(false);
    toast.success(<FormattedMessage id={"Lists.ListCreateSuccess"} />);
  };

  const handleListCreateOnClose = () => {
    setShowCreateForm(false);
  };

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Lists.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            title={<FormattedMessage id="Lists.H1" />}
            actionBar={
              <PrimaryButton onClick={() => setShowCreateForm(true)}>
                <FormattedMessage id="Lists.ListCreate" />
              </PrimaryButton>
            }
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <ListsList />
          </Card>
        </Grid.col>
      </Grid>
      <CreateListModal show={showCreateForm} onClose={handleListCreateOnClose} onComplete={handleListCreateComplete} />
    </PageWrapper>
  );
};

export default Lists;
