const USER_ACTIVATION_STATUSES = [
  {
    name: "Active",
    value: false,
  },
  {
    name: "Inactive",
    value: true,
  },
];

export default USER_ACTIVATION_STATUSES;
