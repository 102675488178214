import { useEffect, useState } from "react";
import { CONVERT_TO_CURRENCY } from "graphql/queries";
import { useQueryData } from "hooks";

export default function useCurrencyConversions({ from, to, amount = 1 }) {
  const [response, setResponse] = useState({});
  const { loading, data, error } = useQueryData({
    queryName: CONVERT_TO_CURRENCY,
    keyName: "currencyConversions",
    skip: !from || !to || !amount || from === to,
    variables: { from, to, amount },
  });

  useEffect(() => {
    if (data?.currencyConversions) {
      setResponse(data.currencyConversions);
    } else {
      setResponse({ conversionRate: 1 });
    }
  }, [data]);

  return {
    loading,
    data: response,
    error,
  };
}
