import { gql } from "@apollo/client";

const termSheetTeamStateFields = `
  billingAccountCurrency
  billingAccountId
  billingAccountName
  billingInterval
  endedAt
  hasEnded
  hasStarted
  id
  uuid
  isPaused
  lastModifiedAt
  lastPausedAt
  lastResumedAt
  name
  slug
  startedAt
`;

const teamMembersAssignmentFields = `
  contractType
  buyingQuantity
  buyingRate
  buyingCurrency
  commissionPercent
  sellingBasePrice
  sellingCurrency
  sellingDiscount
`;

const TEAM_MEMBERS_ASSIGNMENT_FIELDS = gql`
  fragment TeamMembersAssignmentFields on TermSheetTeamStateTeamMember {
    ${teamMembersAssignmentFields}
  }
`;

const TERM_SHEET_TEAM_STATE_FIELDS = gql`
  fragment TermSheetTeamStateFields on TermSheetTeamState {
    ${termSheetTeamStateFields}
  }
`;

export { TEAM_MEMBERS_ASSIGNMENT_FIELDS, TERM_SHEET_TEAM_STATE_FIELDS };
