import React from "react";
import PropTypes from "prop-types";
import DisplayBox from "components/DisplayBox";
import Tags from "components/Tags";
import { FormattedMessage } from "react-intl";

/**
 * RoleLocations
 *
 * @param {Array}    data
 */
const RoleLocations = ({ data, ...props }) => <DisplayBox numColumns={1} data={LOCATIONS_DATA(data)} {...props} />;

const LOCATIONS_DATA = (data) => [
  {
    title: null,
    body: data?.remoteAnywhere ? (
      <FormattedMessage id="Missions.Roles.RoleLocations.RemoteAnywhere" />
    ) : data?.locations?.length > 0 ? (
      <Tags data={data?.locations} />
    ) : (
      <FormattedMessage id="Global.NotProvided" />
    ),
  },
];

RoleLocations.propTypes = {
  data: PropTypes.object,
};

export default RoleLocations;
