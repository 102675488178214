import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { fontSize, colors } from "style";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * TrendTicker
 *
 * @param {Number} trend
 * @param {Boolean} hideText
 */
const TrendTicker = ({ trend, hideText, ...props }) => {
  if (!trend || Math.abs(parseFloat(trend)) < 10) return null; // +/- 10% is the min value to show the trend

  return (
    <div css={styles.container(trend > 0)} {...props}>
      <div css={styles.icon_trend(trend > 0)}>
        <Icon type={ICON_TYPE.arrowRight} size={ICON_SIZE.medium} color="#fff" />
      </div>
      {!hideText && <FormattedMessage id={`Dashboard.TrendTicker.${trend >= 0 ? `Improved` : `Declined`}`} />}
    </div>
  );
};

const styles = {
  container: (isPositive) => css`
    font-weight: 500;
    color: ${isPositive ? colors.green : colors.red};
    font-size: ${fontSize.xxxsmall};
    gap: 0.35rem;
    display: flex;
    align-items: center;
    border-radius: 1rem;
    padding: 0.1rem 0.75rem 0.2rem 0.35rem;
    position: relative;
  `,
  icon_trend: (isPositive) => css`
    position: relative;
    transform: rotate(${isPositive ? `-45deg` : `45deg`});
    background: ${isPositive ? colors.green : colors.red};
    border-radius: 100%;
    width: 1.6rem;
    height: 1.6rem;

    > * {
      top: 0px !important;
    }
  `,
};

TrendTicker.propTypes = {
  trend: PropTypes.number,
  hideText: PropTypes.bool,
};

export default TrendTicker;
