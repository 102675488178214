import { gql } from "@apollo/client";

const minimalMissionRoleFields = `
  slug
  name
  mission {
    uuid
    name
  }
`;

const commonMissionRoleFields = `
  uuid
  name
  assessmentId
  description
  descriptionJson
  disciplines {
    id
    name
  }
  primarySkills {
    id
    name
  }
  secondarySkills {
    id
    name
  }
  locations {
    id
    name
  }
  challenges {
    id
    name
  }
`;

const coreMissionRoleFields = `
  id
  archetype
  slug
  startDate
  engagementType
  engagementTime
  applicantApplied
  targetRate
  targetRateCurrency
  isVisible
  published
  remoteAnywhere
  applicantsCount
  applicantsProfileIds
  assignedTo {
    id
    name
  }
  timezone {
    name
    value
  }
`;

const CORE_MISSION_ROLE_FIELDS = gql`
  fragment MissionRoleFields on MissionRole {
    ${coreMissionRoleFields}
    ${commonMissionRoleFields}
  }
`;

const PUBLIC_MISSION_ROLE_FIELDS = gql`
  fragment PublicMissionRoleFields on PublicMissionRole {
    ${commonMissionRoleFields}
  }
`;

const MINIMAL_MISSION_ROLE_FIELDS = gql`
  fragment MissionRoleFields on MissionRole {
    ${minimalMissionRoleFields}
  }
`;

export { CORE_MISSION_ROLE_FIELDS, PUBLIC_MISSION_ROLE_FIELDS, MINIMAL_MISSION_ROLE_FIELDS };
