import React from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router";
import { useIntl } from "react-intl";
import { LINKS } from "constants/index";
import { useAuth } from "hooks";
import PageTitle from "components/PageTitle";
import { PrimaryLink } from "components/Links";
import { NavList } from "components/Nav";

/**
 * Home
 */
const Home = () => {
  const { isAdmin, isClient } = useAuth();
  const intl = useIntl();

  const navItems = [
    {
      label: intl.formatMessage({ id: "Overview" }),
      to: LINKS.dashboard_home,
    },
    {
      label: intl.formatMessage({ id: "Weekly Activity" }),
      to: LINKS.dashboard_activity_logs,
    },
  ];

  if (!isAdmin && !isClient) return null;

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Dashboard.Title" })}</title>
      </Helmet>
      <PageTitle
        backLink={
          <PrimaryLink to={LINKS.network} routerEnabled>
            {intl.formatMessage({ id: "Network" })}
          </PrimaryLink>
        }
        title={intl.formatMessage({ id: "Dashboard" })}
        actionBar={<NavList pages={navItems} />}
        asHeader
      />
      <Outlet />
    </>
  );
};

export default Home;
