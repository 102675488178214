import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import { useList } from "hooks";
import { LINKS } from "constants/index";
import Card from "components/Card";
import { Grid, PageWrapper } from "components/Containers";
import NoResults from "components/Messages/NoResults";
import { SearchWithFilters } from "components/Search";
import { NetworkList } from "components/Network";
import PageTitle from "components/PageTitle";
import { PrimaryLink } from "components/Links";
import { ListNetworkSearchAdvancedOptions, ListShareButton } from "components/Lists";

/**
 * List
 */
const List = () => {
  const { slug } = useParams();

  const [profiles, setProfiles] = useState([]);
  const [list, setList] = useState({});
  const [staticFilters, setStaticFilters] = useState([]);
  const { data, loading } = useList({
    variables: {
      filters: {
        slug: slug,
      },
      includeProfileIds: true,
      includeBadgesFields: false,
      includeProfileBasicFields: false,
      includeProfileAvailabilityFields: false,
      includeDisciplineFields: false,
      includeSkillsFields: false,
      includeLocationFields: false,
      includeRateFields: false,
      includeWorkExperiencesFields: false,
      includeUser: true,
    },
  });

  useEffect(() => {
    if (data && data[0]) {
      const list = data[0];
      setList(list);
      setProfiles(list?.profiles);
    }
  }, [data]);

  useEffect(() => {
    if (profiles.length > 0) {
      const profileIds = profiles.map((profile) => `${profile.id}`);
      setStaticFilters([{ key: "profile_id.keyword", operator: "or", categoricalValues: profileIds }]);
    }
  }, [profiles]);

  return (
    <PageWrapper>
      <Helmet>
        <title>{list?.title}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            title={list?.title}
            backLink={
              <PrimaryLink to={LINKS.lists} routerEnabled>
                <FormattedMessage id="Lists.H1" />
              </PrimaryLink>
            }
            actionBar={<ListShareButton data={list} />}
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          {!loading && profiles.length === 0 && (
            <Card>
              <NoResults />
            </Card>
          )}
          {staticFilters.length > 0 && (
            <SearchWithFilters
              staticFilters={staticFilters}
              search={false}
              hideSearch={false}
              hideSort={false}
              isCardView={false}
              renderer={NetworkList}
              rendererProps={{ rowOptions: { component: ListNetworkSearchAdvancedOptions, defaultArgs: { list } } }}
              additionalQueryVariables={{
                includeProfileList: true,
                listId: list?.id,
              }}
              initialValues={{
                sort: "member_at",
                sortDirection: "desc",
              }}
            />
          )}
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default List;
