import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { LINKS, LIST_TYPES, USER_TYPES } from "constants/index";
import Modal, { MODAL_SIZE } from "components/Modal";
import { CopyToClipboardInput, Label } from "components/Form";

/**
 * ListShareModal
 *
 * @param {Boolean} onClose
 * @param {Boolean} show
 * @param {Object}  data
 */
const ListShareModal = ({ onClose, show, data, ...props }) => {
  const isFavoriteList = data?.type === LIST_TYPES.favorite;
  const isClientList = data?.user?.role?.toLowerCase() === USER_TYPES.client.toLowerCase();

  return (
    <Modal
      show={show}
      showSave={false}
      showCancel={true}
      onClose={onClose}
      size={MODAL_SIZE.medium}
      secondaryButtonMessageId="Global.Close"
      title={<FormattedMessage id="List.ShareListModal.Text" />}
      {...props}
    >
      <>
        <div css={styles.margin}>
          <Label>
            <FormattedMessage id="List.PublicShareLink.Text" />
          </Label>
          <CopyToClipboardInput text={`${window.location.origin}${LINKS.public_list(data?.slug)}`} />
        </div>

        {isFavoriteList && (
          <div css={styles.showcaseMargin}>
            <Label>
              <FormattedMessage id="List.ShareFavoriteLink.Text" />
            </Label>
            <CopyToClipboardInput
              text={
                isClientList
                  ? `${window.location.origin}${LINKS.favorite(data?.slug)}`
                  : `${window.location.origin}${LINKS.showcase_user_favorite_list(data?.user?.uuid, data?.slug)}`
              }
            />
          </div>
        )}
      </>
    </Modal>
  );
};

const styles = {
  margin: css`
    margin: 1rem 0 -2rem 0;
  `,
  showcaseMargin: css`
    margin: 3rem 0 -2rem 0;
  `,
};

ListShareModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
};

export default ListShareModal;
