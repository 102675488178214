import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "style";
import { PostButton } from "components/Buttons";
import TextInput from "components/Form/TextInput";

/**
 * ChatInput
 *
 * @param {Function} onSubmit
 */
const ChatInput = ({ onSubmit, ...props }) => {
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(value);
    setValue("");
    return false;
  };

  return (
    <form onSubmit={handleSubmit} css={styles.form}>
      <div css={styles.container(isFocused)} {...props}>
        <div css={styles.input}>
          <StyledTextArea
            value={value}
            onChange={(name, value) => setValue(value)}
            rows={1}
            placeholder="Type a message..."
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </div>
        <PostButton disabled={!value} />
      </div>
    </form>
  );
};

const styles = {
  form: css`
    margin-top: auto;
  `,
  container: (isFocused) => css`
    background: ${isFocused ? colors.grayAnatomyLight7 : colors.white};
    border-top: 1px ${colors.grayAnatomyLight4} solid;
    padding: 2rem 2rem 2rem 2rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
    margin-top: auto;
  `,
  input: css`
    margin-right: auto;
    color: ${colors.grayAnatomyLight1};
    flex: 1;
  `,
};

const StyledTextArea = styled(TextInput)`
  background: none;
  padding: 0;
  border: 0;
  margin: 0;
  height: auto;
  min-height: 4rem;
  height: 4rem;
  appearance: none !important;
  outline: none !important;
  width: 100%;
  line-height: auto;

  &:focus {
    box-shadow: none;
    border: 0;
  }
`;

ChatInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ChatInput;
