const LIST_TYPES = {
  favorite: "FAVORITE_LIST",
  featuredTeam: "FEATURED_TEAM_LIST",
  general: "GENERAL_LIST",
  teamBuilder: "TEAM_BUILDER_LIST",
};

const LIST_TYPES_HUMANIZED = {
  [LIST_TYPES.favorite]: "Favorites",
  [LIST_TYPES.featuredTeam]: "Featured Team",
  [LIST_TYPES.general]: "General",
  [LIST_TYPES.teamBuilder]: "Team Builder",
};

export default LIST_TYPES;
export { LIST_TYPES_HUMANIZED };
