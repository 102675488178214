import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { fontSize, colors } from "style";
import { SparkLine } from "components/Charts";
import { TrendTicker } from "components/Dashboard";
import { CURSOR_TYPES } from "components/DisplayBox";
import { TEAM_CATEGORY_SCORES } from "constants/index";
import { formatNumber } from "utils";
import { TextLoader } from "components/Loader";
import Tooltip from "components/Tooltip";
import { Row } from "components/Containers";

/**
 * MetricsCard
 *
 * @param {Number}    score
 * @param {Number}    scoreDeltaPercent
 * @param {Array}     sparkline
 * @param {Function}  onClick
 * @param {Number}    decimalsUpto
 * @param {Boolean}   loading
 * @param {String}    category
 * @param {Boolean}   isSignalClient
 */
const MetricsCard = ({
  score,
  scoreDeltaPercent,
  sparkline,
  onClick,
  decimalsUpto,
  loading,
  category,
  isSignalClient,
  ...props
}) => {
  const userType = isSignalClient ? "SignalClient" : "default";

  if (!textSettings[userType]?.[category] && !loading) return null;

  return (
    <div css={styles.container(onClick ? CURSOR_TYPES.pointer : CURSOR_TYPES.default)} onClick={onClick} {...props}>
      <Row css={styles.score}>
        {loading ? <TextLoader width={`4rem`} /> : formatNumber(score / 100.0, "", decimalsUpto, "percent")}
        {loading ? <TextLoader width={`10rem`} /> : <TrendTicker trend={scoreDeltaPercent} hideText />}
      </Row>
      <div>
        <SparkLine data={sparkline} min={0} max={100} loading={loading} fill={`none`} />
      </div>
    </div>
  );
};

const styles = {
  container: (cursorType) => css`
    padding: 1rem 0rem 0rem 0rem;
    text-align: left;
    font-size: ${fontSize.xxsmall};
    flex: 1;
    border-right: 1px ${colors.grayAnatomyLight5} solid;
    cursor: ${cursorType};

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }

    &:hover {
      .title {
        text-decoration: underline;
      }
    }
  `,
  title: css`
    font-weight: 500;
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyLight1};
    margin-bottom: 1rem;
  `,
  footer: css`
    margin-top: 0.5rem;
  `,
  score: css`
    font-size: ${fontSize.small};
    font-weight: 400;
    gap: 0.25rem;
  `,
};

MetricsCard.propTypes = {
  score: PropTypes.number,
  scoreDeltaPercent: PropTypes.number,
  sparkline: PropTypes.array,
  onClick: PropTypes.func,
  decimalsUpto: PropTypes.number,
  loading: PropTypes.bool,
  category: PropTypes.string,
  isSignalClient: PropTypes.bool,
};

MetricsCard.defaultProps = {
  decimalsUpto: 2,
  comingSoon: false,
};

/**
 * MetricCardTitle
 *
 * @param {Object}    titleValues
 * @param {String}    category
 * @param {Boolean}   isSignalClient
 */
const MetricCardTitle = ({ titleValues, category, isSignalClient, ...props }) => {
  const userType = isSignalClient ? "SignalClient" : "default";

  if (!textSettings[userType]?.[category]) return null;

  const tooltipId = textSettings[userType]?.[category]?.tooltip;

  const textWithTooltip = tooltipId ? (
    <Tooltip tooltipId={tooltipId} {...props}>
      <span className="title">
        <FormattedMessage id={textSettings[userType]?.[category]?.title} />
      </span>
    </Tooltip>
  ) : (
    <span className="title">
      <FormattedMessage id={textSettings[userType]?.[category]?.title} />
    </span>
  );

  return titleValues?.beta ? (
    <span className="title">
      <FormattedMessage
        id={`Dashboard.MetricsCard.TitleType_${category}Beta`}
        values={{
          textWithTooltip,
          ...titleValues,
        }}
      />
    </span>
  ) : (
    textWithTooltip
  );
};

const textSettings = {
  SignalClient: {
    PERFORMANCE: {
      title: "Dashboard.MetricsCard.TitleType_PERFORMANCE_SignalClient",
      tooltip: "Dashboard.MetricsCard.TitleType.Tooltip_PERFORMANCE_SignalClient",
    },
    PULSE: {
      title: "Dashboard.MetricsCard.TitleType_PULSE_SignalClient",
      tooltip: "Dashboard.MetricsCard.TitleType.Tooltip_PULSE_SignalClient",
    },
    COMMUNICATION: {
      title: "Dashboard.MetricsCard.TitleType_COMMUNICATION_SignalClient",
      tooltip: "Dashboard.MetricsCard.TitleType.Tooltip_COMMUNICATION_SignalClient",
    },
  },
  default: {
    ACTIVITY: {
      title: "Dashboard.MetricsCard.TitleType_ACTIVITY",
    },
    VOLUME: {
      title: "Dashboard.MetricsCard.TitleType_VOLUME",
    },
    EFFICIENCY: {
      title: "Dashboard.MetricsCard.TitleType_EFFICIENCY",
    },
    CLIENT_SATISFACTION: {
      title: "Dashboard.MetricsCard.TitleType_CLIENT_SATISFACTION",
    },
    PERFORMANCE: {
      title: "Dashboard.MetricsCard.TitleType_PERFORMANCE",
      tooltip: "Dashboard.MetricsCard.TitleType.Tooltip_PERFORMANCE",
    },
    PULSE: {
      title: "Dashboard.MetricsCard.TitleType_PULSE",
      tooltip: "Dashboard.MetricsCard.TitleType.Tooltip_PULSE",
    },
    COMMUNICATION: {
      title: "Dashboard.MetricsCard.TitleType_COMMUNICATION",
      tooltip: "Dashboard.MetricsCard.TitleType.Tooltip_COMMUNICATION",
    },
    STAKEHOLDER_SATISFACTION: {
      title: "Dashboard.MetricsCard.TitleType_STAKEHOLDER_SATISFACTION",
      tooltip: "Dashboard.MetricsCard.TitleType.Tooltip_STAKEHOLDER_SATISFACTION",
    },
    TEAM_DYNAMICS: {
      title: "Dashboard.MetricsCard.TitleType_TEAM_DYNAMICS",
    },
  },
};

MetricCardTitle.propTypes = {
  titleValues: PropTypes.object,
  category: PropTypes.oneOf(Object.values(TEAM_CATEGORY_SCORES)),
  isSignalClient: PropTypes.bool,
};

export default MetricsCard;
