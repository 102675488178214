import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import Card from "components/Card";
import PageTitle from "components/PageTitle";
import { PageWrapper } from "components/Containers";
import { Grid } from "components/Containers";
import { CodeChallengesList } from "components/CodeChallenges";

/**
 * CodeChallenges
 */
const CodeChallenges = () => {
  const intl = useIntl();

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Settings.CodeChallenges.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle subtitle={<FormattedMessage id="Settings.CodeChallenges.H1" />} />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <CodeChallengesList />
          </Card>
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default CodeChallenges;
