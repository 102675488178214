import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import PageTitle from "components/PageTitle";
import { Grid, PageWrapper } from "components/Containers";
import { EventAddButton, MissionEventList } from "components/Events";

/**
 * Mission Events
 */
const MissionEvents = () => {
  const intl = useIntl();

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.MissionEvents.Home.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle subtitle={<FormattedMessage id={`Events.MissionEvents.Title`} />} actionBar={<EventAddButton />} />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <MissionEventList />
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default MissionEvents;
