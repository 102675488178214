import React, { useEffect, useState } from "react";
import useToast from "hooks/useToast";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { ADD_MEMBER_TO_PROFILE_LIST } from "graphql/mutations";
import { REMOVE_PROFILE_FROM_LIST } from "graphql/mutations/removeProfileFromList";
import { GET_LIST, GET_PRICE_ESTIMATION } from "graphql/queries";
import useQueryData from "hooks/useQueryData";

const TEAM_LIST_TYPE = "TEAM_BUILDER_LIST";

/**
 * useTeamBuilder
 * @returns Object
 */
export default function useTeamBuilder({ profileId }) {
  const { toast } = useToast();
  const [listId, setListId] = useState();
  const [listProfileIds, setListProfileIds] = useState([]);
  const [listProfiles, setListProfiles] = useState([]);
  const [isProfileAdded, setIsProfileAdded] = useState(false);
  const [addProfile, { loading: adding }] = useMutation(ADD_MEMBER_TO_PROFILE_LIST);
  const [removeProfile, { loading: removing }] = useMutation(REMOVE_PROFILE_FROM_LIST);
  const { data } = useQueryData({ queryName: GET_LIST, variables: { filters: { type: TEAM_LIST_TYPE } } });
  const {
    data: estimationData,
    loading: loadingEstimateData,
    refetch,
  } = useQueryData({
    queryName: GET_PRICE_ESTIMATION,
    variables: { listId },
    skip: !listId,
  });

  useEffect(() => {
    const list = data?.list?.nodes?.find((list) => list.id);

    if (list) {
      const ids = list.profiles.map((profile) => profile.id);

      setListProfiles(list.profiles.map((profile) => profile));
      setListProfileIds(ids);
      setListId(list.id);
      setIsProfileAdded(ids.includes(profileId));
    }
  }, [data, profileId]);

  useEffect(() => {
    setIsProfileAdded(listProfileIds?.includes(profileId));
  }, [listProfileIds, profileId]);

  const handleAddProfile = (id) => {
    addProfile({
      variables: {
        input: {
          profileId: id,
          listIds: [listId],
        },
      },
      onCompleted: () => {
        refetch();
        toast.success(<FormattedMessage id="useTeamBuilder.AddSuccess" />);
      },
      onError: () => toast.error(<FormattedMessage id="useTeamBuilder.AddError" />),
    });
  };

  const handleRemoveProfile = (id) => {
    removeProfile({
      variables: {
        input: {
          profileId: id,
          listId,
        },
      },
      onCompleted: () => {
        refetch();
        toast.success(<FormattedMessage id="useTeamBuilder.RemoveSuccess" />);
      },
      onError: () => toast.error(<FormattedMessage id="useTeamBuilder.RemoveError" />),
    });
  };

  return {
    handleAddProfile,
    handleRemoveProfile,
    isProfileAdded,
    listProfiles,
    listProfileIds,
    adding,
    removing,
    data,
    loadingEstimateData,
    estimationData: estimationData?.listPriceEstimatation?.sellingPriceEstimate,
  };
}
