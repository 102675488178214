import { gql } from "@apollo/client";

const UPDATE_CONNECTION = gql`
  mutation UpdateConnection($input: UpdateConnectionInput!) {
    updateConnection(input: $input) {
      connection {
        id
        enabled
      }
    }
  }
`;

export { UPDATE_CONNECTION };
