import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { SHOWCASE_SIGNUP } from "graphql/mutations";
import { MUTATION_TYPE } from "hooks";
import { colors, fontSize, margins } from "style";
import Form, { SIZE, TYPE } from "components/Form";
import { SignupComplete } from "components/Signup";
import { Row } from "components/Containers";

/**
 * ShowcaseSignupForm
 *
 * @param {Object}  values
 */
const ShowcaseSignupForm = ({ values }) => {
  const [formValues, setFormValues] = useState(values || {});
  const [signupCompleted, setSignupCompleted] = useState(false);

  if (signupCompleted) {
    return <SignupComplete msgId="Signup.Showcase.SignupComplete.Body" />;
  }

  const handleFieldChange = (name, value) => {
    const updatedValues = { ...formValues, [name]: value };
    setFormValues(updatedValues);
  };

  return (
    <>
      <Row css={styles.container}>
        <span css={styles.title}>
          <FormattedMessage id="Signup.Showcase.Title" />
        </span>
      </Row>
      <Form
        data={FORM_DATA(formValues, handleFieldChange)}
        values={formValues}
        mutationType={MUTATION_TYPE.add}
        mutationData={{
          mutationName: {
            add: SHOWCASE_SIGNUP,
          },
          unWrapParams: false,
        }}
        marginSize={margins.none}
        saveButton={{
          show: true,
          labelId: "Global.Continue",
        }}
        onCompleted={() => setSignupCompleted(true)}
        onError={() => {}}
      />
      <Row>
        <span css={styles.subtitle}>
          <FormattedMessage id="Signup.Showcase.FormLabelInfo" />
        </span>
      </Row>
    </>
  );
};

const FORM_DATA = (formValues, handleFieldChange) => [
  {
    items: [
      {
        label: <FormattedMessage id="Signup.Showcase.FormLabelTitle" />,
        type: TYPE.h4,
        size: SIZE.xlarge,
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.Showcase.FormLabelFirstName" />,
        properties: {
          name: "firstName",
          placeholder: "First Name",
          autoFocus: true,
          required: true,
          value: formValues.firstName || "",
          onChange: (e) => handleFieldChange("firstName", e.target.value),
        },
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.Showcase.FormLabelLastName" />,
        properties: {
          name: "lastName",
          placeholder: "Last Name",
          required: true,
          value: formValues.lastName || "",
          onChange: (e) => handleFieldChange("lastName", e.target.value),
        },
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.Showcase.FormLabelEmail" />,
        properties: {
          type: "email",
          name: "email",
          placeholder: "Enter email",
          required: true,
          value: formValues.email || "",
          onChange: (e) => handleFieldChange("email", e.target.value),
        },
      },
    ],
  },
];

const styles = {
  container: css`
    justify-content: space-between;
    margin-bottom: 1.5rem;
  `,
  title: css`
    color: ${colors.black};
    font-size: ${fontSize.xxlarge};
    font-weight: 700;
  `,
  subtitle: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyLight1};
    margin-top: 2rem;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  `,
};

ShowcaseSignupForm.propTypes = {
  values: PropTypes.object,
};

export default ShowcaseSignupForm;
