import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { Grid, PageWrapper } from "components/Containers";
import Card from "components/Card";
import PageTitle from "components/PageTitle";
import { PartnersList, PartnerAddButton } from "components/Partners";
import { PAGES, TRACKING_PAGE_VIEWS } from "constants/index";
import { useTracking } from "hooks";

/**
 * Partners Page
 */
const Partners = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const { trackPage, trackablePage } = useTracking();
  const [page, setPage] = useState(PAGES.partners);
  const intl = useIntl();

  const handleEdit = (rowData) => {
    setData(rowData);
    setShowModal(true);
    setPage(PAGES.partnerDetails);
  };

  useEffect(() => {
    if (page && trackablePage(page)) {
      trackPage(TRACKING_PAGE_VIEWS[page].page_name, TRACKING_PAGE_VIEWS[page]);
    }
  }, [page]);

  // Needs to use React router so we can remove <App /> container
  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Partners.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            title={<FormattedMessage id={`Partners.H1`} />}
            actionBar={<PartnerAddButton data={data} showModal={showModal} onHideModal={() => setShowModal(false)} />}
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <PartnersList onClick={handleEdit} />
          </Card>
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default Partners;
