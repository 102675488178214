import React from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";

/**
 * DropDownToggle
 *
 * @params {Array}  options
 */
const DropDownToggle = ({ children, ...props }) => <Dropdown.Toggle {...props}>{children}</Dropdown.Toggle>;

DropDownToggle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DropDownToggle;
