import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useToast } from "hooks";
import { MUTATION_TYPE } from "hooks";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { SIZE, TYPE } from "components/Form";
import { ADD_CLIENT_FEEDBACK, UPDATE_CLIENT_FEEDBACK } from "graphql/mutations";
import { GET_CLIENT_FEEDBACKS } from "graphql/queries";

/**
 * ClientFeedbackForm
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Object}    data
 * @param {String}    teamId
 * @param {String}    updateId
 * @param {Boolean}   loading
 * @param {Boolean}   isEditing
 */
const ClientFeedbackForm = ({ show, onClose, data, updateId, teamId, loading, isEditing }) => {
  const { toast } = useToast();
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues({
      teamId: teamId,
      submittedAt: data?.submittedAt,
      nps: data?.nps,
      csat: data?.csat,
      healthScore: data?.healthScore,
      churnRisk: data?.churnRisk,
      onboardingScore: data?.onboardingScore,
      postMortemScore: data?.postMortemScore,
      notes: data?.notes,
    });
  }, [data]);

  if (isEditing && loading) return null;

  const formProps = {
    initialValues,
    updateId,
    mutationType: isEditing ? MUTATION_TYPE.update : MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: ADD_CLIENT_FEEDBACK,
        update: UPDATE_CLIENT_FEEDBACK,
      },
      refetchQueries: [GET_CLIENT_FEEDBACKS],
      refetchAfterMutate: true,
    },
  };

  const handleComplete = () => {
    onClose();
    toast.success(<FormattedMessage id={`Client.ClientFeedbackForm.${isEditing ? `EditSuccess` : `CreateSuccess`}`} />);
  };

  return (
    <ModalForm
      data={FORM_DATA}
      show={show}
      size={MODAL_SIZE.large}
      onClose={onClose}
      title={<FormattedMessage id={`Client.ClientFeedbackForm.${isEditing ? `Edit` : `Create`}FormTitle`} />}
      description={
        <FormattedMessage id={`Client.ClientFeedbackForm.${isEditing ? `Edit` : `Create`}FormDescription`} />
      }
      onCompleted={handleComplete}
      {...formProps}
    />
  );
};

const FORM_DATA = [
  {
    items: [
      {
        label: "Date",
        type: TYPE.datePicker,
        size: SIZE.medium,
        properties: {
          name: "submittedAt",
          required: true,
        },
      },
      {
        label: "Onboarding Score",
        type: TYPE.select,
        size: SIZE.medium,
        properties: {
          name: "onboardingScore",
          required: false,
          isClearable: true,
          options: [1, 2, 3, 4, 5].map((item) => ({ label: item, value: item })),
        },
      },
      {
        label: "Post Mortem Score",
        type: TYPE.select,
        size: SIZE.medium,
        properties: {
          name: "postMortemScore",
          required: false,
          isClearable: true,
          options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => ({ label: item, value: item })),
        },
      },
      {
        label: "NPS",
        type: TYPE.select,
        size: SIZE.medium,
        properties: {
          name: "nps",
          required: false,
          isClearable: true,
          options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => ({ label: item, value: item })),
        },
      },
      {
        label: "CSAT",
        type: TYPE.select,
        size: SIZE.medium,
        properties: {
          name: "csat",
          required: false,
          isClearable: true,
          options: [1, 2, 3, 4, 5].map((item) => ({ label: item, value: item })),
        },
      },
      {
        label: "Health Score",
        type: TYPE.select,
        size: SIZE.medium,
        properties: {
          name: "healthScore",
          required: false,
          isClearable: true,
          options: [1, 2, 3, 4, 5].map((item) => ({ label: item, value: item })),
        },
      },
      {
        label: "Churn Risk",
        type: TYPE.select,
        size: SIZE.medium,
        properties: {
          name: "churnRisk",
          required: false,
          isClearable: true,
          options: ["LOW", "MEDIUM", "HIGH"].map((item) => ({ label: item, value: item })),
        },
      },
      {
        label: "Comments",
        type: TYPE.textarea,
        size: SIZE.xlarge,
        properties: {
          name: "notes",
          maxLength: 500,
          required: false,
          placeholder: "Add comments...",
        },
      },
    ],
  },
];

ClientFeedbackForm.defaultProps = {
  show: false,
  isEditing: false,
};

ClientFeedbackForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
  updateId: PropTypes.string,
  teamId: PropTypes.string,
  loading: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default ClientFeedbackForm;
