import { GET_ACTIVITY_LOGS } from "graphql/queries";
import { useEffect, useState } from "react";
import { useQueryData } from "hooks";

/**
 * useActivityLogs
 *
 * @param {String}  teamId
 * @param {String}  selectedDate
 */
export default function useActivityLogs({ teamId, selectedDate, repositories }) {
  const [data, setData] = useState([]);
  const [days, setDays] = useState([]);

  const {
    loading,
    data: response,
    error,
  } = useQueryData({
    queryName: GET_ACTIVITY_LOGS,
    keyName: "teamActivityLogs",
    skip: !teamId,
    variables: { teamId, filters: { selectedDate, repositories: repositories?.map((repo) => repo.id) } },
  });

  useEffect(() => {
    if (response) {
      setData(response.teamActivityLogs);
    }
  }, [response]);

  useEffect(() => {
    if (data) {
      setDays(extractDays(data) || []);
    }
  }, [data]);

  return {
    loading,
    data,
    days,
    error,
  };
}

// extract dates from the array of nested objects (BE structure)
// and convert them to short format i.e Fri 9
// to make it UTC we need to pass timeZone: 'UTC' to toLocaleDateString
// which gives 'date day' i.e '12 Mon' so reversed it to 'Mon 12'
const extractDays = (data) =>
  data[0]?.activityLogs.map(({ date }) => {
    const d = new Date(date);
    const options = { weekday: "short", day: "numeric", timeZone: "UTC" };
    const utcDate = d.toLocaleDateString("en-US", options);
    return utcDate.split(" ").reverse().join(" ");
  });
