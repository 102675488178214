import { gql } from "@apollo/client";

const GET_MISSION_BOARD_SUMMARY = gql`
  query MisssionBoardSummaryFetch {
    missionBoardSummaryFetch {
      openMissionRolesCount
      openMissionsCount
    }
  }
`;

export { GET_MISSION_BOARD_SUMMARY };
