import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { formatDate } from "utils";
import { CODE_CHALLENGE_STATUS } from "constants/index";
import DataTable from "components/DataTable";
import Status from "components/Status";
import { PrimaryLink } from "components/Links";
import { CodeChallengeDetailsModal } from "components/CodeChallenges";

/**
 * ApplicationChallenges
 *
 * @param {Array}   challenges
 * @param {Boolean} loading
 */
const ApplicationChallenges = ({ challenges, loading, ...props }) => {
  const [challenge, setChallenge] = useState();

  return (
    <>
      <DataTable
        data={challenges}
        loading={loading}
        emptyMessage={{
          title: "Missions.Applicants.ApplicationChallenges.LabelEmptyTitle",
          description: "Missions.Applicants.ApplicationChallenges.LabelEmptyDescription",
        }}
        columns={[
          {
            label: <FormattedMessage id="Missions.Applicants.ApplicationChallenges.LabelName" />,
            cell: (row) => <div>{row.challenge.name}</div>,
          },
          {
            label: <FormattedMessage id="Missions.Applicants.ApplicationChallenges.LabelPassed" />,
            cell: (row) => (
              <Status enabled={row.status == CODE_CHALLENGE_STATUS.passed}>
                <FormattedMessage id={`Global.${row.status == CODE_CHALLENGE_STATUS.passed ? "Yes" : "No"}`} />
              </Status>
            ),
          },
          {
            label: <FormattedMessage id="Missions.Applicants.ApplicationChallenges.LabelScore" />,
            cell: (row) => <div>{row.score}</div>,
          },
          {
            label: <FormattedMessage id="Missions.Applicants.ApplicationChallenges.LabelMaxScore" />,
            cell: (row) => <div>{row.maxScore}</div>,
          },
          {
            label: <FormattedMessage id="Missions.Applicants.ApplicationChallenges.LabelCompletedOn" />,
            cell: (row) => <div>{formatDate(row.completedAt, "MMM DD, YYYY", true)}</div>,
          },
          {
            cell: (row) => (
              <PrimaryLink onClick={() => setChallenge(row)}>
                <FormattedMessage id="Global.ShowMore" />
              </PrimaryLink>
            ),
          },
        ]}
        {...props}
      />
      {challenge && (
        <CodeChallengeDetailsModal
          show={!!challenge}
          onClose={() => setChallenge(null)}
          challengeResponse={challenge}
        />
      )}
    </>
  );
};

ApplicationChallenges.propTypes = {
  challenges: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

export default ApplicationChallenges;
