import { gql } from "@apollo/client";
import { PROFILE_ENTITY_FIELDS } from "graphql/fragments";

const GET_PULSE = gql`
  ${PROFILE_ENTITY_FIELDS}
  query pulse($teamId: ID!, $filters: PulseFilter!) {
    pulse(teamId: $teamId, filters: $filters) {
      assessor {
        ...ProfileEntityFields
      }
      assessee {
        id
        name
      }
      feedback
      openFeedback
      month
      year
      questionText
    }
  }
`;

export { GET_PULSE };
