import React, { useState, useRef } from "react";
import { css } from "@emotion/react";
import { colors, fontSize, gradients } from "style";

/**
 * WithText - currently only works with input
 *
 * Will position text at beginning of input
 */
const WithText = (WrappedComponent, { text }) => (props) => {
  const [isFocused, setFocus] = useState(false);
  const inputRef = useRef();
  const inputFocus = () => inputRef.current.focus();

  return (
    <div css={styles.container(isFocused)} onClick={inputFocus}>
      <div css={styles.text(isFocused)}>
        {text}
      </div>
      <WrappedComponent
        hasText={true}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        inputRef={inputRef}
        {...props}
      />
    </div>
  );
}

const styles = {
  container: isFocused => css`
    position:relative;
    order:1;
    display:flex;
    border: 1px solid ${colors.grayAnatomyLight3};
    border-radius: 0.6rem;
    overflow:hidden;
    height:4rem;
    box-sizing:border-box;

    ${isFocused && `
      box-shadow: none;
      border-image-source: ${gradients.missionCircularGradient};
      border-width: 2px;
      border-image-slice: 1;
      background-image: linear-gradient(to bottom, #fff, #fff), ${gradients.missionCircularGradient};
      background-origin: border-box;
      background-clip: padding-box, border-box;
      border-color: transparent !important;

      & + label {
        color: ${colors.purpleRainBase};
      }
    `}

    &:hover:not(:disabled) {
      border-color: ${colors.purpleRainBase};

      & + label {
        color: ${colors.purpleRainBase};
      }
    }
  `,
  text: isFocused => css`
    display:flex;
    align-items:center;
    padding: 1rem;
    font-size: ${fontSize.small};
    color: ${isFocused ? colors.purpleRainBase : colors.grayAnatomyLight1};
    background: ${colors.grayAnatomyLight5};
    border-radius: 0.5rem 0 0 0.5rem;
  `,
};

export default WithText;
