import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { ICON_SIZE, withIcon } from "components/Icon";
import { WhiteLink } from "components/Links";
import { colors } from "style";

/**
 * LinkType
 *
 * @param {String}   type
 * @param {Element}  children
 */
const LinkType = ({ type, children, ...props }) => {
  const DecoratedLink = withIcon(WhiteLink, {
    type,
    size: ICON_SIZE.large,
    color: colors.grayAnatomyLight1,
  });

  return <DecoratedLink {...props}>{children}</DecoratedLink>;
};

/**
 * NavLink
 */
const NavLink = styled(LinkType)`
  padding: 0.75rem 1.5rem;
  border-radius: 0.3rem;
  transition: background 0.3s ease;
  display: block;
  margin: 0 -1rem;
  font-weight: 500;

  &:hover {
    background: rgba(255, 255, 255, 0.07);
  }

  &.active {
    color: #fff;
    background: ${colors.purpleRainBase};
  }

  &[disabled] {
    opacity: 0.2;

    &:hover {
      background: none;
    }
  }

  @media (max-height: 850px) {
    padding: 0.5rem 1.5rem;
  }
`;

LinkType.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default NavLink;
