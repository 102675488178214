import { GET_TEAMS } from "graphql/queries";
import { useQueryData, useAuth } from "hooks";

export default function useTimeSheetSummary() {
  const { profileId } = useAuth();
  const { loading, data } = useQueryData({
    queryName: GET_TEAMS,
    keyName: "teams",
    variables: {
      profileId,
      timeSheetSummary: true,
    },
  });

  return {
    loading: loading,
    data: data?.teams?.nodes,
  };
}
