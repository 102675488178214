/**
 * User Full Name
 *
 * @param {Object}   data
 * @param {Boolean}  withLastInitial
 */
export default function getFullName(data, withLastInitial = false) {
  if (!data) return "";

  return `${data.firstName} ${withLastInitial && data?.lastName ? `${Array.from(data.lastName)[0]}.` : data.lastName}`;
}
