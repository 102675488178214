import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import Card from "components/Card";
import PageTitle from "components/PageTitle";
import { SquadsList } from "components/Squads";
import { PrimaryButton } from "components/Buttons";
import { Grid, PageWrapper } from "components/Containers";

/**
 * Squads Page
 */
const Squads = () => {
  const [showForm, setShowForm] = useState(false);
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.Home.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              title={<FormattedMessage id={`Squads.H1`} />}
              actionBar={
                <PrimaryButton onClick={() => setShowForm(true)}>
                  <FormattedMessage id="Squads.CreateButton.Title" />
                </PrimaryButton>
              }
            />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <Card>
              <SquadsList showForm={showForm} setShowForm={setShowForm} />
            </Card>
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default Squads;
