import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { formatDate } from "utils";
import ListTable from "components/ListTable";
import { ARCHIVE_REASON } from "constants/index";
import { colors, margins } from "style";
import { Row } from "components/Containers";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { PrimaryButton } from "components/Buttons";
import { useProfile } from "hooks";

/**
 * CandidateArchived
 *
 * @param {Object}     data
 * @param {Boolean}    loading
 * @param {String}     marginSize
 * @param {Boolean}    hideTitle
 */
const CandidateArchived = ({ data, loading, marginSize, hideTitle, ...props }) => {
  const [archivedReason, setArchivedReason] = useState();
  const { handleRestore } = useProfile({ profileId: data?.id });

  useEffect(() => {
    const reason = ARCHIVE_REASON.find((reason) => reason.value === data?.archivedReason);

    if (reason) {
      setArchivedReason(reason.label);
    }
  }, [data?.archivedReason]);

  if (!data) return null;

  return (
    <>
      <ListTable
        data={ROWS(data, archivedReason)}
        loading={loading}
        title={
          !hideTitle && (
            <Row>
              <Icon type={ICON_TYPE.archive} size={ICON_SIZE.xlarge} color={colors.red} />
              <FormattedMessage id="Profile.CandidateArchived.Title" />
            </Row>
          )
        }
        marginSize={marginSize}
        {...props}
      />
      {data?.archivedDescription && (
        <div css={styles.description}>
          <span css={styles.label}>
            <FormattedMessage id="Profile.CandidateArchived.Notes" />
          </span>
          {data?.archivedDescription}
        </div>
      )}
      <div css={styles.container}>
        <PrimaryButton css={styles.restore} onClick={handleRestore}>
          <FormattedMessage id="Profile.CandidateArchived.Restore" />
        </PrimaryButton>
      </div>
    </>
  );
};

const ROWS = ({ archivedAt, archivedBy }, archivedReason) => [
  {
    label: <FormattedMessage id="Profile.CandidateArchived.Reason" />,
    value: archivedReason,
  },
  {
    label: <FormattedMessage id="Profile.CandidateArchived.By" />,
    value: archivedBy ? `${archivedBy?.firstName} ${archivedBy?.lastName}` : "-",
  },
  {
    label: <FormattedMessage id="Profile.CandidateArchived.Date" />,
    value: archivedAt && formatDate(archivedAt),
  },
];

const styles = {
  container: css`
    margin: 2rem 0 0 0;
  `,
  label: css`
    font-weight: 500;
    color: ${colors.purpleRainDark2};
  `,
  description: css`
    border-top: 1px ${colors.grayAnatomyLight3} solid;
    padding-top: 1rem;
    line-height: 1.45;
    color: ${colors.grayAnatomyLight6};
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `,
  restore: css`
    width: 100%;
  `,
};

CandidateArchived.defaultProps = {
  marginSize: margins.normal,
};

CandidateArchived.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  marginSize: PropTypes.string,
  hideTitle: PropTypes.bool,
};

export default CandidateArchived;
