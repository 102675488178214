/* eslint-disable react/display-name */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from "react";
import Icon from "components/Icon";
import { css } from "@emotion/react";
import { colors } from "style";
import { ICON_SIZE } from "components/Icon";

/**
 * WithIcon - adds an icon to any form element
 *
 * overlay position will put icon overlayed with position absolute
 * ...otherwise icon is wrapped within component
 */
const WithIcon =
  (WrappedComponent, { position, order = 3, ...rest }) =>
  ({ type, ...props }) => {
    switch (position) {
      case "overlay":
        return (
          <div css={styles.container(order)}>
            <div css={styles.icon}>
              <Icon type={type} color={colors.grayAnatomyLight2} {...rest} />
            </div>
            <WrappedComponent hasIcon={true} {...props} />
          </div>
        );
      case "icon-right":
        return (
          <WrappedComponent hasIcon={true} {...props}>
            {props.children}
            <span css={styles.to_right}>
              <Icon type={type} {...rest} />
            </span>
          </WrappedComponent>
        );
      default:
        return (
          <WrappedComponent hasIcon={true} {...props}>
            <span css={styles.inline}>
              <Icon type={type} {...rest} />
            </span>
            {props.children}
          </WrappedComponent>
        );
    }
  };

const styles = {
  container: (order) => css`
    position: relative;
    order: ${order};
    width: 100%;
  `,
  inline: css`
    padding-right: 1rem;
    display: inline-flex;
    width: 3rem;
  `,
  to_right: css`
    padding-right: 0.3rem;
  `,
  icon: css`
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 9;
    line-height: 4rem;
    height: 4rem;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
  `,
};

WithIcon.defaultProps = {
  position: null,
  color: colors.grayAnatomyLight1,
  size: ICON_SIZE.small,
  order: 3,
};

export default WithIcon;
