/**
 * Get current date
 *
 * @param {String}  date
 *
 * @returns {String} The current UTC date
 *
 */
export default function getCurrentUTCDate(date = new Date()) {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const currentDate = `${year}-${month}-${day}`;

  return currentDate;
}
