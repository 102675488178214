import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { WhiteLogo } from "components/Logo";
import { HeaderGradient } from "components/Header";
import Menu from "components/Menu";

/**
 * Shows a gradient header for public facing pages
 *
 * @params {Element}   children
 */
const HeaderPublicGradient = ({ children }) => (
  <HeaderGradient>
    <div css={styles.auth_header}>
      <Menu />
    </div>
    <div css={styles.logo_container}>
      <WhiteLogo width={`22rem`} />
    </div>
    {children}
  </HeaderGradient>
);

const styles = {
  logo_container: css`
    width: 100%;
    align-items: center;
    margin-top: 2.4rem;
    margin-bottom: 8rem;
    display: flex;
    justify-content: center;
  `,
  auth_header: css`
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
  `,
};

HeaderPublicGradient.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeaderPublicGradient;
