import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { H4 } from "components/Text";
import { Label } from "components/Form";
import { TYPE } from "components/Form";
import { colors, fontSize } from "style";
import Icon, { ICON_TYPE } from "components/Icon";
import { Row } from "components/Containers";

/**
 * FormLabel
 *
 * @param {String}   type
 * @param {String}   label
 * @param {String}   description
 * @param {String}   name
 * @param {Boolean}  required
 * @param {Boolean}  requiredAll
 * @param {Any}      hint
 * @param {Boolean}  hideOptionalLabel
 * @param {Boolean}  isExpanded
 * @param {Boolean}  isExpandable
 */
const FormLabel = ({
  type,
  label,
  description,
  name,
  required,
  requiredAll,
  hint,
  hideOptionalLabel,
  isExpanded,
  isExpandable,
  ...props
}) => {
  if (!label && !description) return null;

  const isH4 = type === TYPE.h4;
  const isLabel = type === TYPE.label;
  const isP = type === TYPE.p;

  return (
    <>
      {isH4 ? (
        <H4 css={styles.heading} {...props}>
          {label}
        </H4>
      ) : isP ? (
        <p css={{ display: "contents" }}>{description}</p>
      ) : (
        <StyledLabel htmlFor={name} isExpandable={isExpandable} {...props}>
          <span>{label}</span>
          <Row>
            {!!hint && <span css={styles.hint(isExpanded)}>{hint}</span>}{" "}
            {!required && !requiredAll && !hideOptionalLabel && !isLabel && !isH4 && (
              <span css={styles.optional(isExpanded)}>
                <FormattedMessage id="Global.Optional" />
              </span>
            )}
            {isExpandable && <Icon type={isExpanded ? ICON_TYPE.line : ICON_TYPE.plus} color="inherit" />}
          </Row>
        </StyledLabel>
      )}
      {description && !isP && <p css={styles.description}>{description}</p>}
    </>
  );
};

const styles = {
  description: css`
    margin-right: 4rem;
    color: ${colors.grayAnatomyBase};
    line-height: 1.3;
    margin-bottom: 1rem;
    margin-top: -0.25rem;
  `,
  heading: css``,
  optional: (isExpanded) => css`
    font-size: ${fontSize.xxsmall};
    color: ${colors.grayAnatomyLight2};
    opacity: ${isExpanded ? 1 : 0};
    transition: opacity 0.3s ease;
  `,
  hint: (isExpanded) => css`
    font-size: ${fontSize.xxsmall};
    color: ${colors.grayAnatomyLight1};
    opacity: ${isExpanded ? 1 : 0};
    transition: opacity 0.3s ease;
  `,
};

const StyledLabel = styled(Label)`
  margin-top: 0.75rem;
  user-select: none;

  ${({ isChild }) =>
    isChild &&
    `
      font-weight:400;
      margin: 0 !important;
      font-size: ${fontSize.xxsmall};
    `
  }

  ${({ isExpandable }) =>
    isExpandable &&
    `
    cursor: pointer;

    &:hover {
      color: ${colors.purpleRainBase};
    }

    ~ * {
      display:none;
    }
  `}
`;

FormLabel.propTypes = {
  type: PropTypes.any.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  hasError: PropTypes.bool,
  required: PropTypes.bool,
  requiredAll: PropTypes.bool,
  hint: PropTypes.any,
  hideOptionalLabel: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isExpandable: PropTypes.bool,
};

export default FormLabel;
