import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import Tooltip from "components/Tooltip";
import { chart_colors, colors } from "style";

/**
 * FeedbackCell
 *
 * @param {Object}  data
 */
const FeedbackCell = ({ data }) => {
  const { label, value, description } = data;
  const { text, color } = value;

  const ValueCell = () => <div css={styles.cell_container(color)}>{text}</div>;

  return description || label ? (
    <Tooltip tooltipId={description || label} hideIcon>
      <ValueCell />
    </Tooltip>
  ) : (
    <ValueCell />
  );
};

const styles = {
  table: css`
    td,
    td:last-of-type {
      border: 0;
      padding: 0rem;
      height: 4rem;
    }
  `,
  cell: css`
    text-align: center;
  `,
  cell_container: (value) => css`
    background: #fff;
    height: 4rem;
    line-height: 4rem;
    border-bottom: 1px rgba(255, 255, 255, 0.2) solid;
    border-right: 1px rgba(255, 255, 255, 0.2) solid;

    // Background color for 'Does not Apply' option
    ${value === 0 && `background: ${colors.grayAnatomyLight4};`}

    ${value &&
    `
      background: ${chart_colors.intensity[value - 1]};
    `}
  `,
  disclaimer: css`
    font-style: italic;
    margin-top: 2rem;
    color: ${colors.grayAnatomyBase};
  `,
  headerDynamicCell: css`
    text-align: center;
  `,
};

FeedbackCell.propTypes = {
  data: PropTypes.object,
};

export default FeedbackCell;
