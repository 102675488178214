import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useToast } from "hooks";
import { FormattedMessage } from "react-intl";
import { AdvancedButton } from "components/Buttons";
import { ICON_TYPE } from "components/Icon";
import { useMutation } from "@apollo/client";
import { REMOVE_PROFILE_FROM_LIST } from "graphql/mutations";
import { ProfileListForm } from "components/Lists";

/**
 * ListNetworkSearchAdvancedOptions
 * @param {Object} data
 * @param {Object} list
 */
const ListNetworkSearchAdvancedOptions = ({ data, list, ...props }) => {
  const profile = data;
  const { toast } = useToast();
  const [profileList, setProfileList] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [removeProfile] = useMutation(REMOVE_PROFILE_FROM_LIST);

  useEffect(() => {
    if (list.profileLists) {
      setProfileList(list.profileLists.find((profileList) => profileList.profile.id === profile.id));
    }
  }, [profileList]);

  const removeOption = {
    label: <FormattedMessage id="Lists.RemoveProfile" />,
    icon: ICON_TYPE.trash,
    onClick: () => {
      removeProfile({
        variables: {
          input: {
            profileId: profile.id,
            listId: list.id,
          },
        },
        onCompleted: () => {
          toast.success(<FormattedMessage id={"Profile.RemovedFromProfileList"} />);
        },
        onError: () => toast.error(<FormattedMessage id={"Profile.ErrorOnRemoveFromProfileList"} />),
      });
    },
  };

  const editNegotiatedRateOption = {
    label: <FormattedMessage id="Lists.EditNegotiatedRate" />,
    icon: ICON_TYPE.edit,
    onClick: () => {
      setShowEditModal(true);
    },
  };

  const options = [removeOption, editNegotiatedRateOption];

  return (
    <>
      <AdvancedButton options={options} {...props} />
      <ProfileListForm
        show={showEditModal}
        setProfileList={setProfileList}
        onClose={() => setShowEditModal(false)}
        profileListData={profileList}
      />
    </>
  );
};

ListNetworkSearchAdvancedOptions.propTypes = {
  data: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
};

export default ListNetworkSearchAdvancedOptions;
