import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { H1 } from "components/Text";
import { useProfile } from "hooks";
import { breakpoints, colors, fontSize } from "style";
import { TextLoader } from "components/Loader";
import { margins } from "style";

/**
 * CandidateTitle
 *
 * @param {String} profileId
 */
const CandidateTitle = ({ profileId }) => {
  const { data, simplifiedVetting, loading } = useProfile({ profileId });

  return (
    <div css={styles.container}>
      <H1 css={styles.h1} loading={loading}>
        <FormattedMessage
          id={simplifiedVetting ? "Home.HomeTitle.TitleTrustedPartner" : "Home.HomeTitle.Title"}
          values={{
            firstName: data?.profile?.firstName,
          }}
        />
      </H1>
      <div css={styles.body}>
        {loading ? (
          <TextLoader />
        ) : (
          <FormattedMessage id={simplifiedVetting ? "Home.HomeTitle.BodyTrustedPartner" : "Home.HomeTitle.Body"} />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css`
    max-width: 55rem;
    margin-bottom: 2rem;
    margin-left: ${margins.normal};

    @media (max-width: ${breakpoints.portrait}) {
      margin-left: 2rem;
    }
  `,
  h1: css`
    color: white;
    font-family: "Termina";
    font-weight: 300;
  `,
  body: css`
    font-size: ${fontSize.normal};
    margin: 0;
    color: ${colors.purpleRainLight2};
    margin-top: 2rem;
    line-height: 1.5;
  `,
};

CandidateTitle.propTypes = {
  profileId: PropTypes.string.isRequired,
};

export default CandidateTitle;
