import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { LoginContainer } from "components/Login";
import { SignupForms } from "components/ClientSignup";
import image from "assets/images/ActivityLog-650x1250.jpg";

/**
 * SignalSignup
 */
const SignalSignup = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Signup.Title" })}</title>
      </Helmet>
      <LoginContainer
        banner={{ type: "image", title: intl.formatMessage({ id: "Signup.Banner.Signal.Title" }), cover: image }}
      >
        <SignupForms role="signal" titleId="Signup.Signal.Title" showSteps={false} />
      </LoginContainer>
    </>
  );
};

export default SignalSignup;
