import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { MissionsList, MissionAddButton } from "components/Missions";
import Card from "components/Card";
import PageTitle from "components/PageTitle";
import { Grid } from "components/Containers";

/**
 * Missions Page
 */
const Missions = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Home.Title" })}</title>
      </Helmet>
      <PageTitle subtitle={<FormattedMessage id={`Missions.H1`} />} actionBar={<MissionAddButton isModal />} />
      <Grid topMargin>
        <Grid.col start={1} end={13}>
          <Card>
            <MissionsList />
          </Card>
        </Grid.col>
      </Grid>
    </>
  );
};

export default Missions;
