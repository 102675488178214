import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { colors, fontSize } from "style";
import { NoteComment, NotesInputBox } from "components/Notes";
import { COMMENTABLE_TYPES, DIRECTION } from "constants/index";
import { useComments } from "hooks";
import { H5 } from "components/Text";
import { Spinner } from "components/Loader";
import { Row } from "components/Containers";
import { PrimaryLink } from "components/Links";

/**
 * Note Card
 *
 * @param {String}  commentableId
 * @param {Object}  user
 * @param {Object}  resultsPerPage
 */
const NoteComments = ({ commentableId, user, resultsPerPage, ...props }) => {
  const [replyUser, setReplyUser] = useState(false);
  const { loading, loadingMore, data, hasNextPage, handleNextPage } = useComments({
    commentableId,
    commentableType: COMMENTABLE_TYPES.comment,
    createdAt: DIRECTION.ascending,
    resultsPerPage,
  });

  return (
    <>
      <Row>
        <H5>
          <FormattedMessage id="Notes.NoteComments.Title" />
        </H5>
        {(loading || loadingMore) && <Spinner margin={`-0.75rem 0 0 0`} size={`1rem`} />}
      </Row>
      {!loading && data?.length > 0 && (
        <div css={styles.container} {...props}>
          {data?.map((comment, i) => (
            <NoteComment key={comment.id || i} comment={comment} setReplyUser={setReplyUser} />
          ))}
          {hasNextPage && (
            <div css={styles.more_container}>
              <MoreLink onClick={handleNextPage}>
                <FormattedMessage id="Notes.NoteComments.ShowMore" />
              </MoreLink>
            </div>
          )}
        </div>
      )}
      <div css={styles.add_comment_container}>
        <StyledNotesInput
          user={user}
          placeholder={<FormattedMessage id={`Notes.NoteComments.Placeholder`} />}
          commentableId={commentableId}
          commentableType={COMMENTABLE_TYPES.comment}
          replyUser={replyUser}
          createdAt={DIRECTION.ascending}
          resultsPerPage={resultsPerPage}
          showFormattingOptions={false}
        />
      </div>
    </>
  );
};

const styles = {
  container: css`
    margin: 0rem -3rem 0rem -3rem;
    border-top: 1px ${colors.grayAnatomyLight5} solid;
    border-radius: 0 0 0.6rem 0.6rem;
    position: relative;
    padding-bottom: 2rem;
  `,
  more_container: css`
    margin-top: 3rem;
    margin-left: 3rem;
  `,
  add_comment_container: css`
    margin-top: 1px;
    border-top: 1px ${colors.grayAnatomyLight5} solid;
    margin-left: -3rem;
    width: calc(100% + 6rem);
    padding: 2rem 3rem 0 3rem;
  `,
};

const MoreLink = styled(PrimaryLink)`
  font-size: ${fontSize.xsmall};
`;

const StyledNotesInput = styled(NotesInputBox)``;

NoteComments.defaultProps = {
  resultsPerPage: 10,
};

NoteComments.propTypes = {
  commentableId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  resultsPerPage: PropTypes.number,
};

export default NoteComments;
