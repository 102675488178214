import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useAuth, useComments } from "hooks";
import { TagsInput } from "components/Form";
import { PrimaryLink } from "components/Links";
import { Row } from "components/Containers";
import { PrimaryButton, SecondaryButton, BUTTON_SIZE } from "components/Buttons";
import { GET_TOPICS } from "graphql/queries";
import Tags from "components/Tags";

/**
 * NoteTags
 *
 * @param {Object}    data
 */
const NoteTags = ({ data }) => {
  const { id } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const { handleUpdateTags, updating } = useComments({});
  const [tags, setTags] = useState([]);
  const handleUpdateComplete = () => setIsEditing(false);

  const handleCancel = () => {
    setTags(data?.topics);
    setIsEditing(false);
  };

  useEffect(() => {
    setTags(data?.topics);
  }, [data?.topics]);

  useEffect(() => {
    setCanEdit(id === data?.user?.id);
  }, [data, id]);

  return (
    <div css={styles.container}>
      {!isEditing && (
        <Row>
          {tags && <Tags data={tags} />}
          {canEdit && (
            <PrimaryLink onClick={() => setIsEditing(true)}>
              <FormattedMessage id="Notes.NoteTags.Add" />
            </PrimaryLink>
          )}
        </Row>
      )}
      {isEditing && canEdit && (
        <Row>
          <TagsInput
            id={data?.id}
            value={tags}
            onChange={(name, val) => setTags(val)}
            placeholder="Add topic tags..."
            labelKey="name"
            valueKey="id"
            queryName={GET_TOPICS}
            queryKeyName="topics"
            autoFocus={true}
            defaultOpen={true}
            minLength={0}
          />
          <Row css={styles.edit_buttons}>
            <PrimaryButton
              size={BUTTON_SIZE.medium}
              onClick={() => handleUpdateTags(tags, data?.id, handleUpdateComplete)}
              disabled={updating}
            >
              <FormattedMessage id="Global.Save" />
            </PrimaryButton>
            <SecondaryButton size={BUTTON_SIZE.medium} onClick={handleCancel} disabled={updating}>
              <FormattedMessage id="Global.Cancel" />
            </SecondaryButton>
          </Row>
        </Row>
      )}
    </div>
  );
};

const styles = {
  container: css`
    margin-top: 1rem;
    margin-bottom: 1rem;
  `,
  edit_buttons: css`
    order: 2;
    margin-left: 1rem;
  `,
};

NoteTags.propTypes = {
  data: PropTypes.object,
};

export default NoteTags;
