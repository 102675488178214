import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { H3, Paragraph } from "components/Text";
import { Header } from "components/DisplayBox";
import { Editor } from "components/Form";
import { useAuth } from "hooks";
import { OrganizationLink } from "components/Organization";

/**
 * MissionAboutClient
 *
 * @param {Object}   mission
 * @param {Boolean}  isLoading
 */
const MissionAboutClient = ({ mission, isLoading }) => {
  const { isAdmin } = useAuth();
  const isOrganizationConfidential = !isAdmin && mission?.isOrganizationConfidential;

  return (
    <>
      <Header>
        <H3>
          <FormattedMessage
            id="Missions.MissionAboutClient"
            values={{
              name: (
                <OrganizationLink
                  data={{ ...mission?.organization, isConfidential: isOrganizationConfidential }}
                  withOverlay={isAdmin}
                  placement="auto"
                />
              ),
            }}
          />
        </H3>
      </Header>
      <div css={styles.description}>
        {isOrganizationConfidential ? (
          <FormattedMessage id="Missions.Mission.Confidential.Description" />
        ) : mission?.companyDescriptionJson ? (
          <Editor value={mission?.companyDescriptionJson} css={styles.body} readOnly={true} />
        ) : (
          <Paragraph data={mission?.companyDescription} isLoading={isLoading} />
        )}
      </div>
    </>
  );
};

const styles = {
  description: css`
    line-height: normal;
  `,
  body: css`
    margin: 0;
    padding: 0;
    border: 0;
    box-shadow: none;
  `,
};

MissionAboutClient.propTypes = {
  mission: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default MissionAboutClient;
