import { GET_TEAM_HISTORICAL_SCORE } from "graphql/queries";
import { useQueryData } from "hooks";

export default function useTeamHistoricalScores({ teamId, weeks=26 }) {
  const { loading, data, error } =
    useQueryData({
      queryName: GET_TEAM_HISTORICAL_SCORE,
      keyName: "teamProfilesScoresFetch",
      variables: {
        teamId,
        historicalWeeks: weeks
      },
    });

  return {
    loading,
    data: data?.teamHistoricalScoresFetch,
    error,
  };
}
