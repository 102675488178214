import { gql } from "@apollo/client";

const ADD_CHATBOT_MESSAGE = gql`
  mutation generateEngineeringMetricsPrompt($input: GenerateEngineeringMetricsChatbotPromptInput!) {
    generateEngineeringMetricsPrompt(input: $input) {
      chatbotPrompts {
        role
        content
      }
    }
  }
`;

export { ADD_CHATBOT_MESSAGE };
