import { gql } from "@apollo/client";

const ADD_INTERVIEW = gql`
  mutation AddMissionInterview($input: AddMissionInterviewInput!) {
    addMissionInterview(input: $input) {
      missionInterview {
        id
      }
    }
  }
`;
export { ADD_INTERVIEW };
