import { gql } from "@apollo/client";

const profileEntityFields = `
  id
  slug
  name
  firstName
  lastName
`;

const PROFILE_ENTITY_FIELDS = gql`
  fragment ProfileEntityFields on Profile {
    ${profileEntityFields}
  }
`;

export { PROFILE_ENTITY_FIELDS };
