import { gql } from "@apollo/client";
import {
  CORE_PROFILE_FIELDS,
  OLD_LOCATION_FIELDS,
  PROFILE_ID_FIELDS,
  teamEffortReportDataFields,
} from "graphql/fragments";

const TEAM_EFFORT_REPORT = gql`
${PROFILE_ID_FIELDS}
${CORE_PROFILE_FIELDS}
${OLD_LOCATION_FIELDS}
  query teamEffortReportFetch($teamId: ID!, $filters: EffortReportFilter!) {
    teamEffortReportFetch(teamId: $teamId, filters: $filters) {
      datetime
      activeSince
      effortReportProfiles {
        profile {
          ...ProfileIdFields
          ...ProfileFields
          ...OldLocationFields
        }
        data {
          hours {
            ${teamEffortReportDataFields}
          }
          minutes {
            ${teamEffortReportDataFields}
          }
          percentages {
            ${teamEffortReportDataFields}
          }
        }
      }
      totalHours {
        ${teamEffortReportDataFields}
      }
      totalPercentages {
        ${teamEffortReportDataFields}
      }
    }
  }
`;

export { TEAM_EFFORT_REPORT };
