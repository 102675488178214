import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "components/Buttons";
import { MisssionPublicLink } from "components/Missions";
import { useAuth } from "hooks";

/**
 * ProposalAdvancedOptions
 *
 * @params {Object}   data
 * @params {Boolean}  loading
 */
const ProposalAdvancedOptions = ({ data, loading }) => {
  const [showModal, setShowModal] = useState(false);
  const { isAdmin } = useAuth();

  if (!isAdmin || loading) return null;

  return (
    <>
      <PrimaryButton onClick={() => setShowModal(true)}>
        <FormattedMessage id={`Mission.ViewProposal.Button`} />
      </PrimaryButton>
      <MisssionPublicLink show={showModal} onClose={() => setShowModal(false)} data={data} />
    </>
  );
};

ProposalAdvancedOptions.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
};

export default ProposalAdvancedOptions;
