import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import Link from "./Link";

/**
 * Navlink
 *
 * @param {String}     to
 * @param {Boolean}    routerEnabled
 * @param {Boolean}    isActive
 * @param {Element}    children
 * @param {Boolean}    onDark
 */
const StyledNavLink = ({ to, routerEnabled, isActive, children, onDark, ...props }) => (
  <Link to={to} routerEnabled={routerEnabled} isActive={isActive} css={styles.link(onDark)} {...props}>
    {children}
  </Link>
);

const styles = {
  link: (onDark) => css`
    font-size: ${onDark ? fontSize.normal : fontSize.small};
    color: ${onDark ? colors.white : colors.grayAnatomyBase};
    padding: 0 0 0.6rem 0;
    font-weight: normal;
    transition: all 0.2s;
    position: relative;

    &:hover {
      color: ${onDark ? colors.white : colors.purpleRainBase};
    }

    &.active {
      font-weight: 600;
      color: ${onDark ? colors.white : colors.purpleRainDark2};

      &:before {
        content: "";
        height: 2px;
        background-image: linear-gradient(to right, ${colors.purpleRainBase}, ${colors.pinkPantherBase});
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  `,
};

StyledNavLink.propTypes = {
  to: PropTypes.string,
  routerEnabled: PropTypes.bool,
  isActive: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onDark: PropTypes.bool,
};

StyledNavLink.defaultProps = {
  onDark: false,
};

export default StyledNavLink;
