import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Grid } from "components/Containers";
import { fontSize } from "style";

/**
 * ActivityLogHeader
 *
 * @param {Object}     days
 * @param {Function}   renderer
 * @param {Number}     cols
 * @param {String}     fontSize
 * @param {Boolean}    showMemberCell
 */
const ActivityLogHeader = ({ days, renderer, cols, showMemberCell, fontSize, ...props }) => {
  const Component = renderer;

  return (
    <>
      <HeaderGrid gap={`1px`} cols={cols} showMemberCell={showMemberCell} {...props}>
        {showMemberCell && <HeaderMemberCell start={1} end={4} fontSize={fontSize} />}
        {days.map((item, index) => (
          <HeaderDateCell key={`${index}`} fontSize={fontSize}>
            {renderer ? <Component item={item} /> : item}
          </HeaderDateCell>
        ))}
      </HeaderGrid>
    </>
  );
};

const HeaderGrid = styled(Grid)`
  grid-template-columns: repeat(${({ showMemberCell, cols }) => (showMemberCell ? cols + 3 : cols)}, minmax(0, 1fr));
`;

const HeaderMemberCell = styled.div`
  padding: 1rem;
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize};

  ${({ start, end }) =>
    (start || end) &&
    css`
      grid-column: ${start} / ${end};
    `}
`;

const HeaderDateCell = styled.div`
  padding: 1rem;
  font-weight: 600;
  padding: 0.8rem 0rem;
  font-size: ${({ fontSize }) => fontSize};
  text-align: center;
`;

ActivityLogHeader.propTypes = {
  days: PropTypes.array,
  renderer: PropTypes.func,
  fontSize: PropTypes.string,
  showMemberCell: PropTypes.bool,
  cols: PropTypes.number,
};

ActivityLogHeader.defaultProps = {
  fontSize: fontSize.small,
  cols: 7,
  showMemberCell: true,
};

export default ActivityLogHeader;
