import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { TERM_SHEET_SCENARIOS } from "constants/index";
import { AdvancedButton } from "components/Buttons";
import { ICON_TYPE } from "components/Icon";
import TermSheetScenarioForm from "./TermSheetScenarioForm";
import { useTermSheetScenarios } from "hooks";

/**
 * TermSheetScenarioAdvancedOptions
 *
 * @param {String}    teamId
 * @param {Object}    data
 * @param {Boolean}   loading
 * @param {Boolean}   disabled
 * @param {Function}  onRemove
 */
const TermSheetScenarioAdvancedOptions = ({ teamId, data, loading, disabled, onRemove, ...props }) => {
  const { handleRemoveScenario } = useTermSheetScenarios({ teamId, state: TERM_SHEET_SCENARIOS.active });
  const [showForm, setShowForm] = useState(false);

  const options = [
    {
      label: <FormattedMessage id="TermSheets.TermSheetScenarioAdvancedOoptions.Edit" />,
      icon: ICON_TYPE.edit,
      onClick: () => setShowForm(true),
    },
    {
      label: <FormattedMessage id="TermSheets.TermSheetScenarioAdvancedOoptions.Delete" />,
      icon: ICON_TYPE.trash,
      onClick: () => handleRemoveScenario(data?.id, data?.name).then(() => onRemove?.()),
    },
  ];

  return (
    <>
      <AdvancedButton options={options} disabled={disabled || loading} />
      <TermSheetScenarioForm show={showForm} onClose={() => setShowForm(false)} data={data} isEditing {...props} />
    </>
  );
};

TermSheetScenarioAdvancedOptions.propTypes = {
  teamId: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default TermSheetScenarioAdvancedOptions;
