import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { RENDER_SIZE, RenderIconWithText } from "components/DisplayBox";
import Icon, { ICON_TYPE } from "components/Icon";
import { formatDate } from "utils";
import { SENTIMENTS } from "constants";
import {
  LabelForCommentMetric,
  LabelForEvaluationMetric,
  LabelForOutlierInverseMetric,
  LabelForOutlierMetric,
  getEntityName,
} from "components/Squads/Insights";

/**
 * SquadInsightItem
 *
 * @param {Object}    insight
 * @param {Number}    index
 * @param {Boolean}   loading
 */
const SquadInsightItem = ({ insight, index, loading }) => (
  <RenderIconWithText
    size={RENDER_SIZE.small}
    hasLink={false}
    key={`insight-${index}`}
    loading={loading}
    icon={!loading && <Icon type={getIconType(insight)} />}
    text={
      !loading && {
        title: labelFor(insight),
        description: formatDate(insight.timestamp),
      }
    }
  />
);

/**
 * DefaultLabel
 *
 * @param {Object}  insight
 */
const DefaultLabel = ({ insight }) => {
  const { insightMetric, valueString, value } = insight;

  return (
    <FormattedMessage
      id="Squads.SquadInsights.DefaultInsight"
      values={{ entity: getEntityName(insight), insightMetric, value: valueString || value.toFixed() }}
    />
  );
};

DefaultLabel.propTypes = {
  insight: PropTypes.object,
};

const getIconType = ({ sentiment }) => {
  switch (sentiment) {
    case SENTIMENTS.negative:
      return ICON_TYPE.exclamation;
    case SENTIMENTS.neutral:
      return ICON_TYPE.attention;
    case SENTIMENTS.positive:
      return ICON_TYPE.thumbsUp;
    default:
      return null;
  }
};

const labelFor = (insight) => {
  const { insightMetric } = insight;
  const insightMetricKey = buildKey(insightMetric);
  const labelKey = Object.keys(LABEL_COMPONENTS_MAPPING).includes(insightMetricKey) ? insightMetricKey : "DEFAULT";

  return LABEL_COMPONENTS_MAPPING[labelKey]?.(insight);
};

// Mapper object to customize the content of insight against metric type
const LABEL_COMPONENTS_MAPPING = {
  ACTIVE_DAYS: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  ACTIVITIES_PER_ACTIVE_DAY_AVERAGE: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  ACTIVITY_SCORE: (insight) => <LabelForOutlierMetric insight={insight} word="is" />,
  CODE_REVIEWS: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  CODE_REVIEWS_PER_ACTIVE_DAY_AVERAGE: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  COMMENT_SIZE_AVERAGE: (insight) => <LabelForOutlierMetric insight={insight} word="is" />,
  COMMENTS: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  COMMENTS_PER_ACTIVE_DAY_AVERAGE: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  COMMENTS_PER_PULL_REQUEST_AVERAGE: (insight) => <LabelForOutlierInverseMetric insight={insight} word="are" />,
  COMMITS: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  COMMITS_PER_ACTIVE_DAY_AVERAGE: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  EFFICIENCY_SCORE: (insight) => <LabelForOutlierMetric insight={insight} word="is" />,
  HOURS_TO_PULL_REQUEST_MERGED_AVERAGE_AS_AUTHOR: (insight) => (
    <LabelForOutlierInverseMetric insight={insight} word="are" />
  ),
  HOURS_TO_PULL_REQUEST_MERGED_AVERAGE_AS_MERGER: (insight) => (
    <LabelForOutlierInverseMetric insight={insight} word="are" />
  ),
  HOURS_TO_PULL_REQUEST_REVIEW_AVERAGE: (insight) => <LabelForOutlierInverseMetric insight={insight} word="are" />,
  LINES_OF_CODE_ADDED: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  LINES_OF_CODE_MODIFIED: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  LINES_OF_CODE_MODIFIED_PER_COMMIT_AVERAGE: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  LINES_OF_CODE_REMOVED: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  NEGATIVE_COMMENT_ADDED: (insight) => <LabelForCommentMetric insight={insight} word="on" />,
  NEGATIVE_MISSION_FEEDBACK_GIVEN: (insight) => <LabelForEvaluationMetric insight={insight} word="to" />,
  NEGATIVE_TEAM_MEMBER_FEEDBACK_GIVEN: (insight) => <LabelForEvaluationMetric insight={insight} word="to" />,
  NEGATIVE_TEAM_MEMBER_FEEDBACK_RECEIVED: (insight) => <LabelForEvaluationMetric insight={insight} word="from" />,
  OVERALL_SCORE: (insight) => <LabelForOutlierMetric insight={insight} word="is" />,
  PULL_REQUEST_LINES_OF_CODE_MODIFIED: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  PULL_REQUEST_REVIEWS_BEFORE_MERGE_AVERAGE: (insight) => <LabelForOutlierInverseMetric insight={insight} word="are" />,
  PULL_REQUEST_SIZE_AVERAGE: (insight) => <LabelForOutlierInverseMetric insight={insight} word="is" />,
  PULL_REQUESTS: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  PULL_REQUESTS_MERGED_AS_MERGER: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  PULL_REQUESTS_MERGED_AS_AUTHOR: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  PULL_REQUESTS_PER_ACTIVE_DAY_AVERAGE: (insight) => <LabelForOutlierMetric insight={insight} word="are" />,
  VOLUME_SCORE: (insight) => <LabelForOutlierMetric insight={insight} word="is" />,
  DEFAULT: (insight) => <DefaultLabel insight={insight} />,
};

// buildKey function would help converting the insightMetric text to keys
// so they could be mapped to the object above
const buildKey = (str) =>
  String(str)
    .replace(/[(),-]/g, "")
    .toUpperCase()
    .replace(/ /g, "_")
    .replace(/__/g, "_");

SquadInsightItem.propTypes = {
  insight: PropTypes.object,
  index: PropTypes.number,
  loading: PropTypes.bool,
};

export default SquadInsightItem;
