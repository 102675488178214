import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { TextLoader } from "components/Loader";

/**
 * Paragraph
 *
 * @params {String}   data
 * @params {Boolean}  isLoading
 */
const Paragraph = ({ data, isLoading, ...props }) => {
  if (isLoading) {
    return (
      <P>
        <TextLoader
          css={css`
            width: 100%;
          `}
        />
        <TextLoader
          css={css`
            width: 100%;
          `}
        />
        <TextLoader
          css={css`
            width: 40%;
          `}
        />
      </P>
    );
  }

  return (
    <span {...props}>
      {data?.split("\n\n").map((paragraph, i) => (
        <P key={i} {...props}>
          {paragraph.split("\n").map((body, x) => (
            <span key={`${i}${x}`}>
              {body}
              <br />
            </span>
          ))}
        </P>
      ))}
    </span>
  );
};

const P = styled.p`
  margin-bottom: 0rem;
`;

Paragraph.propTypes = {
  data: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default Paragraph;
