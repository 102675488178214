import React from "react";
import { PrimaryButton, BUTTON_SIZE } from "components/Buttons";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";


const PostButton = (props) => (
  <PrimaryButton size={BUTTON_SIZE.medium} {...props}>
    <Icon type={ICON_TYPE.send} size={ICON_SIZE.medium} color="inherit" />
  </PrimaryButton>
);

export default PostButton;

