import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import Modal from "components/Modal";
import { withIcon, ICON_TYPE, ICON_SIZE } from "components/Icon";
import { LINKS } from "../../../constants";
import { PrimaryLink } from "components/Links";
import { fontSize } from "style";
import { CopyToClipboardInput } from "components/Form";
import { useAuth } from "hooks";

/**
 * ApplicantShareButton
 *
 * @params {string}   missionRoleSlug
 * @params {string}   applicantType
 * @params {number}   missionId
 * @params {number}   missionRoleId
 */
const ApplicantShareButton = ({ missionRoleSlug, applicantType, missionId, missionRoleId }) => {
  const { isAdmin } = useAuth();
  const [showModal, setShowModal] = useState(false);

  if (!missionId || !missionRoleId || !applicantType || !missionRoleSlug || !isAdmin) return null;

  const url = LINKS.applicant_public_link(missionRoleSlug, applicantType);
  const onClose = () => setShowModal(false);

  return (
    <>
      <ShareList onClick={() => setShowModal(true)} css={styles.share_link}>
        <FormattedMessage id="Missions.Applicants.ApplicantShareModal.Share" />
      </ShareList>
      <Modal
        show={showModal}
        onClose={onClose}
        showSave={false}
        showCancel={true}
        secondaryButtonMessageId="Global.Close"
        size={"md"}
        title={<FormattedMessage id="Missions.Applicants.ApplicantShareModal.Title" />}
      >
        <CopyToClipboardInput
          text={url}
          title={<FormattedMessage id="Missions.Applicants.ApplicantShareModal.LinkCopied" />}
        />
      </Modal>
    </>
  );
};

const ShareList = withIcon(PrimaryLink, {
  type: ICON_TYPE.share,
  size: ICON_SIZE.small,
  color: "inherit",
});

const styles = {
  share_link: css`
    font-size: ${fontSize.small};
  `,
};

ApplicantShareButton.propTypes = {
  missionRoleSlug: PropTypes.string,
  applicantType: PropTypes.string,
  missionId: PropTypes.number,
  missionRoleId: PropTypes.number,
};

export default ApplicantShareButton;
