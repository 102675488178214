import React, { useState } from "react";
import PropTypes from "prop-types";
import { LIST_TYPES } from "constants/index";
import { BrandedButton } from "components/Buttons";
import { FormattedMessage } from "react-intl";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { AddToListsModal } from "components/Lists";
import { useAuth } from "hooks";

/**
 * ListFavoriteButton
 *
 * @param {String}     id
 */
const ListFavoriteButton = ({ id }) => {
  const [showAddToListModal, setAddToShowModal] = useState(false);
  const { isSignalClient } = useAuth();

  if (isSignalClient) return null;

  return (
    <>
      <AddToListsModal
        profileId={id}
        listType={LIST_TYPES.favorite}
        show={showAddToListModal}
        handleClose={() => setAddToShowModal(false)}
        handlePrimary={() => {
          setAddToShowModal(false);
        }}
      />
      <BrandedButton onClick={() => setAddToShowModal(true)}>
        <FormattedMessage
          id={`Lists.ListFavoriteButton.Add`}
          values={{
            icon: <Icon type={ICON_TYPE.heart} size={ICON_SIZE.medium} color="inherit" />,
          }}
        />
      </BrandedButton>
    </>
  );
};

ListFavoriteButton.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ListFavoriteButton;
