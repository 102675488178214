import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { ARCHETYPES, ERROR_CODES } from "constants/index";
import { MessagesWarning } from "components/Messages";

/**
 * RoleApplyError - shows a message above the form
 *
 * @param {String}  reason
 * @param {Object}  role
 */
const RoleApplyError = ({ reason, role }) => {
  const allowedArchetypesPlural = role?.archetype?.map((item) => {
    const archArray = Object.values(ARCHETYPES);
    const match = archArray?.find((arch) => item === arch.id);
    return `${match?.name}'s`;
  });

  return (
    <MessagesWarning>
      <b>
        <FormattedMessage
          id="Missions.MissionRole.ApplyForm.ErrorHeader"
          values={{
            count: reason.length,
          }}
        />
      </b>
      <p>
        <ul>
          {reason.map((error) => {
            switch (error) {
              case ERROR_CODES.archetypeMismatch:
                return (
                  <li>
                    <FormattedMessage
                      id="Missions.MissionRole.ApplyForm.ErrorArchetypeMismatch"
                      values={{
                        archetypes: allowedArchetypesPlural.join(" and "),
                      }}
                    />
                  </li>
                );
              case ERROR_CODES.locationMismatch:
                return (
                  <li>
                    <FormattedMessage id="Missions.MissionRole.ApplyForm.ErrorLocationMismatch" />
                  </li>
                );

              default:
                return <FormattedMessage id="Missions.MissionRole.ApplyForm.ErrorGeneral" />;
            }
          })}
        </ul>
      </p>
    </MessagesWarning>
  );
};

RoleApplyError.propTypes = {
  reason: PropTypes.array,
  role: PropTypes.object,
};

export default RoleApplyError;
