import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { TEAM_EFFORT_REPORT } from "graphql/queries";
import { useAuth, useQueryData, useTracking } from "hooks";
import { EffortReportList } from "components/Squads/EffortReport";
import { PAGINATION_TYPE } from "components/Pagination";
import PageTitle from "components/PageTitle";
import { DATE_FORMAT_TYPE, TRACKING_PAGE_VIEWS, PAGES } from "constants/index";
import { MonthPicker } from "components/Form";
import { PageWrapper, Grid } from "components/Containers";

/**
 * Effort Report Page
 */
const EffortReport = () => {
  const { id } = useParams();
  const me = useAuth();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const intl = useIntl();
  const { trackPage } = useTracking();

  useEffect(() => {
    if (me.isShowcase) {
      trackPage(
        TRACKING_PAGE_VIEWS[PAGES.effortReportMission].page_name,
        TRACKING_PAGE_VIEWS[PAGES.effortReportMission]
      );
    }
  }, []);
  const {
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    loading,
    loadingMore,
    error,
    data,
    resultsPerPage,
    refetch,
  } = useQueryData({
    queryName: TEAM_EFFORT_REPORT,
    keyName: "teamEffortReportFetch",
    resultsPerPage: 10,
    paginationType: PAGINATION_TYPE.paginate,
    variables: {
      teamId: id,
      filters: {
        month: String(selectedDate?.getMonth() + 1),
        year: String(selectedDate?.getFullYear()),
      },
    },
  });

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.EffortReport.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              subtitle={<FormattedMessage id="Squads.EffortReport.Title" />}
              actionBar={
                <MonthPicker
                  initialValue={selectedDate}
                  dateFormat={DATE_FORMAT_TYPE.MMyyyy}
                  name="MonthPicker"
                  onChange={setSelectedDate}
                  minDate={new Date(data?.teamEffortReportFetch.activeSince)}
                  showMonthYearPicker
                  showFullMonthYearPicker
                />
              }
            />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <EffortReportList
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              loading={loading || loadingMore}
              error={error}
              data={data?.teamEffortReportFetch?.effortReportProfiles}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              resultsPerPage={resultsPerPage}
              selectedDate={selectedDate}
              handleSelectedDate={setSelectedDate}
              refresh={refetch}
            />
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default EffortReport;
