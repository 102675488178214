import React, { useState } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { MUTATION_TYPE, useMutateData } from "hooks";
import { CheckBox, LAYOUT_DIRECTION } from "components/Form";
import { TextLoader, Spinner } from "components/Loader";
import { DestructableLink, PrimaryLink } from "components/Links";
import { colors, fontSize } from "style";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { Col } from "components/Containers";

/**
 * Creates a group of checkboxes
 *
 * @param {Function}   onChange
 * @param {Boolean}    value
 * @param {String}     name
 * @param {Array}      options
 * @param {Boolean}    loading
 * @param {String}     valueKey
 * @param {String}     labelKey
 * @param {Function}   labelFunc
 * @param {String}     descriptionKey
 * @param {Object}     mutationData
 * @param {Number}     limit
 * @param {String}     direction
 */
const CheckBoxGroup = ({
  onChange,
  value,
  name,
  options,
  loading,
  valueKey,
  labelKey,
  labelFunc,
  descriptionKey,
  mutationData,
  limit,
  direction,
  ...props
}) => {
  const [localLimit, setLocalLimit] = useState(limit);
  const incrementLimitSize = 10;
  const { handleDelete, loading: deleting } = useMutateData(mutationData, MUTATION_TYPE.delete);

  const getCheckboxLabel = (item) => (typeof labelFunc === "function" ? labelFunc(item) : item[labelKey]);

  const onChangeLocal = (isChecked, selectedItem) => {
    onChange(
      name,
      isChecked
        ? [...value, { [labelKey]: getCheckboxLabel(selectedItem), [valueKey]: selectedItem[valueKey] }]
        : value?.filter((item) => item[valueKey] !== selectedItem[valueKey])
    );
  };

  const handleRemove = (e, id) => {
    e.preventDefault();
    handleDelete({ id });
  };

  if (loading) {
    return [0, 1, 2, 3, 4, 5, 6].map((item, index) => (
      <div css={styles.checkbox_container} key={`${item}-${index}`}>
        <CheckBox
          key={item}
          label={
            <TextLoader
              css={css`
                width: 15rem;
              `}
            />
          }
          value={false}
        />
      </div>
    ));
  }

  return (
    <div css={styles.container}>
      <Col gap={direction === LAYOUT_DIRECTION.horizontal ? "5rem" : "0.5rem"} direction={direction}>
        {options?.slice(0, localLimit).map((item, index) => {
          const selected = value?.filter((x) => x[valueKey] === item[valueKey]);
          const isSelected = selected?.length > 0;

          return (
            <div css={styles.checkbox_container} key={item[valueKey]}>
              <CheckBox
                id={`radio-${name}-${index}`}
                label={getCheckboxLabel(item)}
                description={item[descriptionKey]}
                value={isSelected}
                onChange={(n, isChecked) => onChangeLocal(isChecked, item)}
                name={name}
                {...props}
              />
              {item.isRemovable && (
                <span>
                  (
                  <DestructableLink onClick={(e) => handleRemove(e, item.id)} disabled={loading} withDeleteIcon>
                    <FormattedMessage id="Global.Remove" />
                  </DestructableLink>
                  )
                </span>
              )}
            </div>
          );
        })}
        {
          <div css={styles.secondary}>
            {deleting && <Spinner size={`2rem`} />}
            {options?.length > localLimit && (
              <MoreLink onClick={() => setLocalLimit(localLimit + incrementLimitSize)}>
                <FormattedMessage id="Global.ShowMore" />
                <Icon type={ICON_TYPE.chevronDown} size={ICON_SIZE.xsmall} color={"inherit"} />
              </MoreLink>
            )}
          </div>
        }
      </Col>
    </div>
  );
};

const styles = {
  container: css`
    order: 1;
  `,
  checkbox_container: css`
    order: 1;
    align-items: center;
    color: ${colors.grayAnatomyLight1};
    max-height: 15rem;
    overflow-y: auto;
  `,
  secondary: css`
    order: 2;
    text-align: center;
  `,
};

const MoreLink = styled(PrimaryLink)`
  margin-top: 0.5rem;
  font-size: ${fontSize.xsmall};
  border-top: 1px dashed ${colors.grayAnatomyLight3};
  display: block;
  padding-top: 0.5rem;

  .link-text {
    display: flex;
    flex-direction: column;
  }
`;

CheckBoxGroup.defaultProps = {
  value: [],
  valueKey: "id",
  labelkey: "label",
  mutationData: {},
  limit: 30,
};

CheckBoxGroup.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  labelFunc: PropTypes.func,
  descriptionKey: PropTypes.string,
  mutationData: PropTypes.object,
  limit: PropTypes.number,
  direction: PropTypes.string,
};

export default CheckBoxGroup;
