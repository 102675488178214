import { gql } from "@apollo/client";

const ADD_CHALLENGE_RESPONSE = gql`
  mutation AddChallengeResponse($input: AddChallengeResponseInput!) {
    addChallengeResponse(input: $input) {
      challengeResponse {
        type
        id
        profileId
        challengeId
        serviceId
        status
        score
        maxScore
        tasks
        testUrl
        sessionUrl
        reportUrl
        feedbackUrl
        startedAt
        completedAt
      }
    }
  }
`;

const UPDATE_CHALLENGE = gql`
  mutation UpdateChallenge($input: UpdateChallengeInput!) {
    updateChallenge(input: $input) {
      challenge {
        id
        name
        description
        published
        publishedAt
        icon
        skills {
          id
          name
        }
        disciplines {
          id
          name
        }
        types {
          id
          name
        }
      }
    }
  }
`;

const IMPORT_CODILITY_CHALLENGES = gql`
  mutation importCodilityChallenges($input: ImportCodilityChallengesInput!) {
    importCodilityChallenges(input: $input) {
      challenges {
        id
        name
        publishedAt
        skills {
          id
          name
        }
      }
    }
  }
`;

export { ADD_CHALLENGE_RESPONSE, UPDATE_CHALLENGE, IMPORT_CODILITY_CHALLENGES };
