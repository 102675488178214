const COUNTRIES = [
  { label: "Afghanistan", value: "AF", flag: "🇦🇫" },
  { label: "Albania", value: "AL", flag: "🇦🇱" },
  { label: "Algeria", value: "DZ", flag: "🇩🇿" },
  { label: "American Samoa", value: "AS", flag: "🇦🇸" },
  { label: "Andorra", value: "AD", flag: "🇦🇩" },
  { label: "Angola", value: "AO", flag: "🇦🇴" },
  { label: "Anguilla", value: "AI", flag: "🇦🇮" },
  { label: "Antarctica", value: "AQ", flag: "🇦🇶" },
  { label: "Antigua and Barbuda", value: "AG", flag: "🇦🇬" },
  { label: "Argentina", value: "AR", flag: "🇦🇷" },
  { label: "Armenia", value: "AM", flag: "🇦🇲" },
  { label: "Aruba", value: "AW", flag: "🇦🇼" },
  { label: "Australia", value: "AU", flag: "🇦🇺" },
  { label: "Austria", value: "AT", flag: "🇦🇹" },
  { label: "Azerbaijan", value: "AZ", flag: "🇦🇿" },
  { label: "Bahamas", value: "BS", flag: "🇧🇸" },
  { label: "Bahrain", value: "BH", flag: "🇧🇭" },
  { label: "Bangladesh", value: "BD", flag: "🇧🇩" },
  { label: "Barbados", value: "BB", flag: "🇧🇧" },
  { label: "Belarus", value: "BY", flag: "🇧🇾" },
  { label: "Belgium", value: "BE", flag: "🇧🇪" },
  { label: "Belize", value: "BZ", flag: "🇧🇿" },
  { label: "Benin", value: "BJ", flag: "🇧🇯" },
  { label: "Bermuda", value: "BM", flag: "🇧🇲" },
  { label: "Bhutan", value: "BT", flag: "🇧🇹" },
  { label: "Bolivia (Plurinational State of)", value: "BO", flag: "🇧🇴" },
  { label: "Bonaire, Sint Eustatius and Saba", value: "BQ", flag: "🇧🇶" },
  { label: "Bosnia and Herzegovina", value: "BA", flag: "🇧🇦" },
  { label: "Botswana", value: "BW", flag: "🇧🇼" },
  { label: "Bouvet Island", value: "BV", flag: "🇧🇻" },
  { label: "Brazil", value: "BR", flag: "🇧🇷" },
  { label: "British Indian Ocean Territory", value: "IO", flag: "🇮🇴" },
  { label: "Brunei Darussalam", value: "BN", flag: "🇧🇳" },
  { label: "Bulgaria", value: "BG", flag: "🇧🇬" },
  { label: "Burkina Faso", value: "BF", flag: "🇧🇫" },
  { label: "Burundi", value: "BI", flag: "🇧🇮" },
  { label: "Cabo Verde", value: "CV", flag: "🇨🇻" },
  { label: "Cambodia", value: "KH", flag: "🇰🇭" },
  { label: "Cameroon", value: "CM", flag: "🇨🇲" },
  { label: "Canada", value: "CA", flag: "🇨🇦" },
  { label: "Cayman Islands", value: "KY", flag: "🇰🇾" },
  { label: "Central African Republic", value: "CF", flag: "🇨🇫" },
  { label: "Chad", value: "TD", flag: "🇹🇩" },
  { label: "Chile", value: "CL", flag: "🇨🇱" },
  { label: "China", value: "CN", flag: "🇨🇳" },
  { label: "Christmas Island", value: "CX", flag: "🇨🇽" },
  { label: "Cocos (Keeling) Islands", value: "CC", flag: "🇨🇨" },
  { label: "Colombia", value: "CO", flag: "🇨🇴" },
  { label: "Comoros", value: "KM", flag: "🇰🇲" },
  { label: "Congo", value: "CG", flag: "🇨🇬" },
  { label: "Congo (Democratic Republic of the)", value: "CD", flag: "🇨🇩" },
  { label: "Cook Islands", value: "CK", flag: "🇨🇰" },
  { label: "Costa Rica", value: "CR", flag: "🇨🇷" },
  { label: "Croatia", value: "HR", flag: "🇭🇷" },
  { label: "Cuba", value: "CU", flag: "🇨🇺" },
  { label: "Curaçao", value: "CW", flag: "🇨🇼" },
  { label: "Cyprus", value: "CY", flag: "🇨🇾" },
  { label: "Czech Republic", value: "CZ", flag: "🇨🇿" },
  { label: "Côte d'Ivoire", value: "CI", flag: "🇨🇮" },
  { label: "Denmark", value: "DK", flag: "🇩🇰" },
  { label: "Djibouti", value: "DJ", flag: "🇩🇯" },
  { label: "Dominica", value: "DM", flag: "🇩🇲" },
  { label: "Dominican Republic", value: "DO", flag: "🇩🇴" },
  { label: "Ecuador", value: "EC", flag: "🇪🇨" },
  { label: "Egypt", value: "EG", flag: "🇪🇬" },
  { label: "El Salvador", value: "SV", flag: "🇸🇻" },
  { label: "Equatorial Guinea", value: "GQ", flag: "🇬🇶" },
  { label: "Eritrea", value: "ER", flag: "🇪🇷" },
  { label: "Estonia", value: "EE", flag: "🇪🇪" },
  { label: "Ethiopia", value: "ET", flag: "🇪🇹" },
  { label: "Falkland Islands (Malvinas)", value: "FK", flag: "🇫🇰" },
  { label: "Faroe Islands", value: "FO", flag: "🇫🇴" },
  { label: "Fiji", value: "FJ", flag: "🇫🇯" },
  { label: "Finland", value: "FI", flag: "🇫🇮" },
  { label: "France", value: "FR", flag: "🇫🇷" },
  { label: "French Guiana", value: "GF", flag: "🇬🇫" },
  { label: "French Polynesia", value: "PF", flag: "🇵🇫" },
  { label: "French Southern Territories", value: "TF", flag: "🇹🇫" },
  { label: "Gabon", value: "GA", flag: "🇬🇦" },
  { label: "Gambia", value: "GM", flag: "🇬🇲" },
  { label: "Georgia", value: "GE", flag: "🇬🇪" },
  { label: "Germany", value: "DE", flag: "🇩🇪" },
  { label: "Ghana", value: "GH", flag: "🇬🇭" },
  { label: "Gibraltar", value: "GI", flag: "🇬🇮" },
  { label: "Greece", value: "GR", flag: "🇬🇷" },
  { label: "Greenland", value: "GL", flag: "🇬🇱" },
  { label: "Grenada", value: "GD", flag: "🇬🇩" },
  { label: "Guadeloupe", value: "GP", flag: "🇬🇵" },
  { label: "Guam", value: "GU", flag: "🇬🇺" },
  { label: "Guatemala", value: "GT", flag: "🇬🇹" },
  { label: "Guernsey", value: "GG", flag: "🇬🇬" },
  { label: "Guinea", value: "GN", flag: "🇬🇳" },
  { label: "Guinea-Bissau", value: "GW", flag: "🇬🇼" },
  { label: "Guyana", value: "GY", flag: "🇬🇾" },
  { label: "Haiti", value: "HT", flag: "🇭🇹" },
  { label: "Heard Island and McDonald Islands", value: "HM", flag: "🇭🇲" },
  { label: "Holy See", value: "VA", flag: "🇻🇦" },
  { label: "Honduras", value: "HN", flag: "🇭🇳" },
  { label: "Hong Kong", value: "HK", flag: "🇭🇰" },
  { label: "Hungary", value: "HU", flag: "🇭🇺" },
  { label: "Iceland", value: "IS", flag: "🇮🇸" },
  { label: "India", value: "IN", flag: "🇮🇳" },
  { label: "Indonesia", value: "ID", flag: "🇮🇩" },
  { label: "Iran (Islamic Republic of)", value: "IR", flag: "🇮🇷" },
  { label: "Iraq", value: "IQ", flag: "🇮🇶" },
  { label: "Ireland", value: "IE", flag: "🇮🇪" },
  { label: "Isle of Man", value: "IM", flag: "🇮🇲" },
  { label: "Israel", value: "IL", flag: "🇮🇱" },
  { label: "Italy", value: "IT", flag: "🇮🇹" },
  { label: "Jamaica", value: "JM", flag: "🇯🇲" },
  { label: "Japan", value: "JP", flag: "🇯🇵" },
  { label: "Jersey", value: "JE", flag: "🇯🇪" },
  { label: "Jordan", value: "JO", flag: "🇯🇴" },
  { label: "Kazakhstan", value: "KZ", flag: "🇰🇿" },
  { label: "Kenya", value: "KE", flag: "🇰🇪" },
  { label: "Kiribati", value: "KI", flag: "🇰🇮" },
  { label: "Korea (Democratic People's Republic of)", value: "KP", flag: "🇰🇵" },
  { label: "Korea (Republic of)", value: "KR", flag: "🇰🇷" },
  { label: "Kuwait", value: "KW", flag: "🇰🇼" },
  { label: "Kyrgyzstan", value: "KG", flag: "🇰🇬" },
  { label: "Lao People's Democratic Republic", value: "LA", flag: "🇱🇦" },
  { label: "Latvia", value: "LV", flag: "🇱🇻" },
  { label: "Lebanon", value: "LB", flag: "🇱🇧" },
  { label: "Lesotho", value: "LS", flag: "🇱🇸" },
  { label: "Liberia", value: "LR", flag: "🇱🇷" },
  { label: "Libya", value: "LY", flag: "🇱🇾" },
  { label: "Liechtenstein", value: "LI", flag: "🇱🇮" },
  { label: "Lithuania", value: "LT", flag: "🇱🇹" },
  { label: "Luxembourg", value: "LU", flag: "🇱🇺" },
  { label: "Macao", value: "MO", flag: "🇲🇴" },
  { label: "Madagascar", value: "MG", flag: "🇲🇬" },
  { label: "Malawi", value: "MW", flag: "🇲🇼" },
  { label: "Malaysia", value: "MY", flag: "🇲🇾" },
  { label: "Maldives", value: "MV", flag: "🇲🇻" },
  { label: "Mali", value: "ML", flag: "🇲🇱" },
  { label: "Malta", value: "MT", flag: "🇲🇹" },
  { label: "Marshall Islands", value: "MH", flag: "🇲🇭" },
  { label: "Martinique", value: "MQ", flag: "🇲🇶" },
  { label: "Mauritania", value: "MR", flag: "🇲🇷" },
  { label: "Mauritius", value: "MU", flag: "🇲🇺" },
  { label: "Mayotte", value: "YT", flag: "🇾🇹" },
  { label: "Mexico", value: "MX", flag: "🇲🇽" },
  { label: "Micronesia (Federated States of)", value: "FM", flag: "🇫🇲" },
  { label: "Moldova (Republic of)", value: "MD", flag: "🇲🇩" },
  { label: "Monaco", value: "MC", flag: "🇲🇨" },
  { label: "Mongolia", value: "MN", flag: "🇲🇳" },
  { label: "Montenegro", value: "ME", flag: "🇲🇪" },
  { label: "Montserrat", value: "MS", flag: "🇲🇸" },
  { label: "Morocco", value: "MA", flag: "🇲🇦" },
  { label: "Mozambique", value: "MZ", flag: "🇲🇿" },
  { label: "Myanmar", value: "MM", flag: "🇲🇲" },
  { label: "Namibia", value: "NA", flag: "🇳🇦" },
  { label: "Nauru", value: "NR", flag: "🇳🇷" },
  { label: "Nepal", value: "NP", flag: "🇳🇵" },
  { label: "Netherlands", value: "NL", flag: "🇳🇱" },
  { label: "New Caledonia", value: "NC", flag: "🇳🇨" },
  { label: "New Zealand", value: "NZ", flag: "🇳🇿" },
  { label: "Nicaragua", value: "NI", flag: "🇳🇮" },
  { label: "Niger", value: "NE", flag: "🇳🇪" },
  { label: "Nigeria", value: "NG", flag: "🇳🇬" },
  { label: "Niue", value: "NU", flag: "🇳🇺" },
  { label: "Norfolk Island", value: "NF", flag: "🇳🇫" },
  { label: "North Macedonia", value: "MK", flag: "🇲🇰" },
  { label: "Northern Mariana Islands", value: "MP", flag: "🇲🇵" },
  { label: "Norway", value: "NO", flag: "🇳🇴" },
  { label: "Oman", value: "OM", flag: "🇴🇲" },
  { label: "Pakistan", value: "PK", flag: "🇵🇰" },
  { label: "Palau", value: "PW", flag: "🇵🇼" },
  { label: "Palestine, State of", value: "PS", flag: "🇵🇸" },
  { label: "Panama", value: "PA", flag: "🇵🇦" },
  { label: "Papua New Guinea", value: "PG", flag: "🇵🇬" },
  { label: "Paraguay", value: "PY", flag: "🇵🇾" },
  { label: "Peru", value: "PE", flag: "🇵🇪" },
  { label: "Philippines", value: "PH", flag: "🇵🇭" },
  { label: "Pitcairn", value: "PN", flag: "🇵🇳" },
  { label: "Poland", value: "PL", flag: "🇵🇱" },
  { label: "Portugal", value: "PT", flag: "🇵🇹" },
  { label: "Puerto Rico", value: "PR", flag: "🇵🇷" },
  { label: "Qatar", value: "QA", flag: "🇶🇦" },
  { label: "Romania", value: "RO", flag: "🇷🇴" },
  { label: "Russian Federation", value: "RU", flag: "🇷🇺" },
  { label: "Rwanda", value: "RW", flag: "🇷🇼" },
  { label: "Réunion", value: "RE", flag: "🇷🇪" },
  { label: "Saint Barthélemy", value: "BL", flag: "🇧🇱" },
  { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SH", flag: "🇸🇭" },
  { label: "Saint Kitts and Nevis", value: "KN", flag: "🇰🇳" },
  { label: "Saint Lucia", value: "LC", flag: "🇱🇨" },
  { label: "Saint Martin (French part)", value: "MF", flag: "🇲🇫" },
  { label: "Saint Pierre and Miquelon", value: "PM", flag: "🇵🇲" },
  { label: "Saint Vincent and the Grenadines", value: "VC", flag: "🇻🇨" },
  { label: "Samoa", value: "WS", flag: "🇼🇸" },
  { label: "San Marino", value: "SM", flag: "🇸🇲" },
  { label: "Sao Tome and Principe", value: "ST", flag: "🇸🇹" },
  { label: "Saudi Arabia", value: "SA", flag: "🇸🇦" },
  { label: "Senegal", value: "SN", flag: "🇸🇳" },
  { label: "Serbia", value: "RS", flag: "🇷🇸" },
  { label: "Seychelles", value: "SC", flag: "🇸🇨" },
  { label: "Sierra Leone", value: "SL", flag: "🇸🇱" },
  { label: "Singapore", value: "SG", flag: "🇸🇬" },
  { label: "Sint Maarten (Dutch part)", value: "SX", flag: "🇸🇽" },
  { label: "Slovakia", value: "SK", flag: "🇸🇰" },
  { label: "Slovenia", value: "SI", flag: "🇸🇮" },
  { label: "Solomon Islands", value: "SB", flag: "🇸🇧" },
  { label: "Somalia", value: "SO", flag: "🇸🇴" },
  { label: "South Africa", value: "ZA", flag: "🇿🇦" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS", flag: "🇬🇸" },
  { label: "South Sudan", value: "SS", flag: "🇸🇸" },
  { label: "Spain", value: "ES", flag: "🇪🇸" },
  { label: "Sri Lanka", value: "LK", flag: "🇱🇰" },
  { label: "Sudan", value: "SD", flag: "🇸🇩" },
  { label: "Suriname", value: "SR", flag: "🇸🇷" },
  { label: "Svalbard and Jan Mayen", value: "SJ", flag: "🇸🇯" },
  { label: "Sweden", value: "SE", flag: "🇸🇪" },
  { label: "Switzerland", value: "CH", flag: "🇨🇭" },
  { label: "Syrian Arab Republic", value: "SY", flag: "🇸🇾" },
  { label: "Taiwan, Province of China", value: "TW", flag: "🇹🇼" },
  { label: "Tajikistan", value: "TJ", flag: "🇹🇯" },
  { label: "Tanzania, United Republic of", value: "TZ", flag: "🇹🇿" },
  { label: "Thailand", value: "TH", flag: "🇹🇭" },
  { label: "Timor-Leste", value: "TL", flag: "🇹🇱" },
  { label: "Togo", value: "TG", flag: "🇹🇬" },
  { label: "Tokelau", value: "TK", flag: "🇹🇰" },
  { label: "Tonga", value: "TO", flag: "🇹🇴" },
  { label: "Trinidad and Tobago", value: "TT", flag: "🇹🇹" },
  { label: "Tunisia", value: "TN", flag: "🇹🇳" },
  { label: "Turkey", value: "TR", flag: "🇹🇷" },
  { label: "Turkmenistan", value: "TM", flag: "🇹🇲" },
  { label: "Turks and Caicos Islands", value: "TC", flag: "🇹🇨" },
  { label: "Tuvalu", value: "TV", flag: "🇹🇻" },
  { label: "Uganda", value: "UG", flag: "🇺🇬" },
  { label: "Ukraine", value: "UA", flag: "🇺🇦" },
  { label: "United Arab Emirates", value: "AE", flag: "🇦🇪" },
  { label: "United Kingdom of Great Britain and Northern Ireland", value: "GB", flag: "🇬🇧" },
  { label: "United States Minor Outlying Islands", value: "UM", flag: "🇺🇲" },
  { label: "United States of America", value: "US", flag: "🇺🇸" },
  { label: "Uruguay", value: "UY", flag: "🇺🇾" },
  { label: "Uzbekistan", value: "UZ", flag: "🇺🇿" },
  { label: "Vanuatu", value: "VU", flag: "🇻🇺" },
  { label: "Venezuela (Bolivarian Republic of)", value: "VE", flag: "🇻🇪" },
  { label: "Viet Nam", value: "VN", flag: "🇻🇳" },
  { label: "Virgin Islands (British)", value: "VG", flag: "🇻🇬" },
  { label: "Virgin Islands (U.S.)", value: "VI", flag: "🇻🇮" },
  { label: "Wallis and Futuna", value: "WF", flag: "🇼🇫" },
  { label: "Western Sahara", value: "EH", flag: "🇪🇭" },
  { label: "Yemen", value: "YE", flag: "🇾🇪" },
  { label: "Zambia", value: "ZM", flag: "🇿🇲" },
  { label: "Zimbabwe", value: "ZW", flag: "🇿🇼" },
  { label: "eSwatini", value: "SZ", flag: "🇸🇿" },
  { label: "Åland Islands", value: "AX", flag: "🇦🇽" },
];

export default COUNTRIES;
