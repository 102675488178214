import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { shortenText } from "utils";
import DisplayBox from "components/DisplayBox";
import RenderMemberGroupList from "components/DisplayBox/Renderers/RenderMemberGroupList";
import { LINKS } from "constants/index";
import { margins } from "style";

/**
 * MissionsHighlights
 *
 * @param {String}   loading
 * @param {Array}    data
 * @param {String}   emptyMsgId
 */
const MissionsHighlights = ({ loading, data, emptyMsgId, ...props }) => {
  const isEmpty = !loading && data?.length === 0;

  return (
    <DisplayBox
      title={<FormattedMessage id="Missions.MissionsHighlight.Title" />}
      marginSize={margins.normal}
      loading={loading}
      rowGap={0}
      numColumns={1}
      data={data}
      isEmpty={isEmpty}
      emptyMsg={<FormattedMessage id={`${emptyMsgId || "Missions.MissionsHighlight.EmptyMsg"}`} />}
      itemRenderer={(item, index, loading) => (
        <RenderMemberGroupList
          key={item.id}
          title={item.name}
          subtitle={shortenText(item.description, 70)}
          linkTo={LINKS.mission_edit(item.uuid)}
          loading={loading}
          index={index}
        />
      )}
      {...props}
    />
  );
};

MissionsHighlights.propTypes = {
  loading: PropTypes.string,
  data: PropTypes.string,
  emptyMsgId: PropTypes.string,
};

export default MissionsHighlights;
