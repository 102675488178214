/**
 * Return a single object from an array of objects based on a key/value match
 *
 * @param {Array}   arr
 * @param {String}  value
 * @param {String}  key
 *
 * @returns {string}
 */
export default function getObjectFromArray(arr, value, key = "id") {
  for (var i = 0; i < arr?.length; i++) {
    if (arr[i][key] === value) return arr[i];
  }

  return {};
}
