import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "components/Buttons";
import { useAuth } from "hooks";
import { OrganizationBillingForm } from "components/Organizations";

/**
 * AddBillingButton
 */
const AddBillingButton = (props) => {
  const [showForm, setShowForm] = useState(false);
  const { isAdmin } = useAuth();

  if (!isAdmin) return null;

  return (
    <>
      <PrimaryButton onClick={() => setShowForm(true)}>
        <FormattedMessage id={`Organization.OrganizationBillingInformation.AddBillingAccount`} />
      </PrimaryButton>
      <OrganizationBillingForm show={showForm} onClose={() => setShowForm(false)} isEditing={false} {...props} />
    </>
  );
};

export default AddBillingButton;
