import { Transforms } from "slate";

/**
 * insertMention
 *
 * @param {Object}  editor
 * @param {Object}  payload
 */
const insertMention = (editor, payload) => {
  const mention = {
    type: "mention",
    name: payload?.name,
    mention: payload,
    children: [{ text: `@${payload?.name}` }],
  };

  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

export default insertMention;
