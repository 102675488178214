import { gql } from "@apollo/client";

const GET_INDUSTRIES = gql`
  query GetIndustries($filters: TagFilter) {
    industries(filters: $filters) {
      nodes {
        name
        id
        uuid
      }
    }
  }
`;

export { GET_INDUSTRIES };
