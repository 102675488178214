import TRACKING_FLOW from "./trackingFlow";
import TRACKING_SECTIONS from "./trackingSections";

const TRACKING_PAGE_VIEWS = {
  home: {
    page_name: "Home",
    section: TRACKING_SECTIONS.dashboard,
    flow: TRACKING_FLOW.dashboard,
  },
  showcaseHome: {
    page_name: "Showcase User Homepage",
    section: TRACKING_SECTIONS.dashboard,
    flow: TRACKING_FLOW.dashboard,
  },
  dashboard: {
    page_name: "Dashboard",
    section: TRACKING_SECTIONS.dashboard,
    flow: TRACKING_FLOW.dashboard,
  },
  candidate: {
    page_name: "Candidates",
    section: TRACKING_SECTIONS.candidates,
    flow: TRACKING_FLOW.network,
  },
  missions: {
    page_name: "All Missions",
    section: TRACKING_SECTIONS.missionsOverview,
    flow: TRACKING_FLOW.mission,
  },
  applicants: {
    page_name: "Applicants",
    section: TRACKING_SECTIONS.missionsOverview,
    flow: TRACKING_FLOW.mission,
  },
  board: {
    page_name: "Mission Board",
    section: TRACKING_SECTIONS.boardOverview,
    flow: TRACKING_FLOW.missionBoard,
  },
  partners: {
    page_name: "Partners",
    section: TRACKING_SECTIONS.partnersOverview,
    flow: TRACKING_FLOW.partners,
  },
  partnerDetails: {
    page_name: "Partner Details",
    section: TRACKING_SECTIONS.partnersOverview,
    flow: TRACKING_FLOW.partners,
  },
  timecards: {
    page_name: "Time Cards",
    section: TRACKING_SECTIONS.timecardsOverview,
    flow: TRACKING_FLOW.timecard,
  },
  timesheet: {
    page_name: "Time Sheet",
    section: TRACKING_SECTIONS.timeSheet,
    flow: TRACKING_FLOW.timesheet,
  },
  rate: {
    page_name: "Rate & Availbility",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  timeline: {
    page_name: "Timeline",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  roleSelectionSignUp: {
    page_name: "Role Selection",
    section: TRACKING_SECTIONS.roleInformation,
    flow: TRACKING_FLOW.signup,
  },
  roleDetailsSignUp: {
    page_name: "Role Details",
    section: TRACKING_SECTIONS.roleInformation,
    flow: TRACKING_FLOW.signup,
  },
  personalDetailsSignUp: {
    page_name: "Personal Details",
    section: TRACKING_SECTIONS.personalInformation,
    flow: TRACKING_FLOW.signup,
  },
  eventDetails: {
    page_name: "Event Details",
    section: TRACKING_SECTIONS.events,
    flow: TRACKING_FLOW.dashboard,
  },
  squadDetail: {
    page_name: "Squad Page",
    section: TRACKING_SECTIONS.squadDetails,
    flow: TRACKING_FLOW.dashboard,
  },
  overAll: {
    page_name: "Overall",
    section: TRACKING_SECTIONS.squadPerformance,
    flow: TRACKING_FLOW.dashboard,
  },
  squadActivityLog: {
    page_name: "Squad Activity Log",
    section: TRACKING_SECTIONS.squadActivityLog,
    flow: TRACKING_FLOW.dashboard,
  },
  TEAM_DYNAMICS: {
    page_name: "Team Dynamic",
    section: TRACKING_SECTIONS.squadPerformance,
    flow: TRACKING_FLOW.dashboard,
  },
  ENGINEERING_PERFORMANCE: {
    page_name: "Engineering",
    section: TRACKING_SECTIONS.squadPerformance,
    flow: TRACKING_FLOW.dashboard,
  },
  PRODUCT_DELIVERY: {
    page_name: "Product Delivery",
    section: TRACKING_SECTIONS.squadPerformance,
    flow: TRACKING_FLOW.dashboard,
  },
  CLIENT_SATISFACTION: {
    page_name: "Client Satisfaction",
    section: TRACKING_SECTIONS.squadPerformance,
    flow: TRACKING_FLOW.dashboard,
  },
  clientSearch: {
    page_name: "Showcase/Client Search",
    section: TRACKING_SECTIONS.clientSearch,
    flow: TRACKING_FLOW.client,
  },
  showcaseBookMeeting: {
    page_name: "Showcase Book Meeting",
    section: TRACKING_SECTIONS.showcaseBookMeeting,
    flow: TRACKING_FLOW.showcase,
  },
  showcaseMissionBoard: {
    page_name: "Showcase Mission Board",
    section: TRACKING_SECTIONS.showcaseMissionBoard,
    flow: TRACKING_FLOW.showcase,
  },
  clientCommunity: {
    page_name: "Client Community",
    section: TRACKING_SECTIONS.clientCommunity,
    flow: TRACKING_FLOW.client,
  },
  showcaseTeamBuilder: {
    page_name: "Showcase Team Builder",
    section: TRACKING_SECTIONS.showcaseTeamBuilder,
    flow: TRACKING_FLOW.showcase,
  },
  showcaseApplicants: {
    page_name: "Showcase Applicants",
    section: TRACKING_SECTIONS.showcaseApplicants,
    flow: TRACKING_FLOW.showcase,
  },
  showcaseSignUp: {
    page_name: "Showcase SignUp",
    section: TRACKING_SECTIONS.showcaseSignUp,
    flow: TRACKING_FLOW.signup,
  },
  publicProfile: {
    page_name: "Public Profile",
    section: TRACKING_SECTIONS.publicProfile,
    flow: TRACKING_FLOW.profile,
  },
  profile: {
    page_name: "Profile",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  addShortBio: {
    page_name: "Add Short Bio",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  profileDiscipline: {
    page_name: "Disciplines",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  topSkills: {
    page_name: "Top Skills",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  contactInfo: {
    page_name: "Contact Info",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  profileWorkExperience: {
    page_name: "Experience",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  profileEducation: {
    page_name: "Education",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  externalLink: {
    page_name: "External Links",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  profilePhoto: {
    page_name: "Photo",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  rateCapacity: {
    page_name: "Rate & Capacity",
    section: TRACKING_SECTIONS.rateAndAvailability,
    flow: TRACKING_FLOW.profile,
  },
  workInterest: {
    page_name: "Area of Interests",
    section: TRACKING_SECTIONS.rateAndAvailability,
    flow: TRACKING_FLOW.profile,
  },
  workSchedule: {
    page_name: "Work Schedule",
    section: TRACKING_SECTIONS.rateAndAvailability,
    flow: TRACKING_FLOW.profile,
  },
  vacationDates: {
    page_name: "Vacation Dates",
    section: TRACKING_SECTIONS.rateAndAvailability,
    flow: TRACKING_FLOW.profile,
  },
  addToList: {
    page_name: "Add to List",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  profileInviteMember: {
    page_name: "Invite Member",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  archiveProfile: {
    page_name: "Archive Profile",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  ATS: {
    page_name: "ATS",
    section: TRACKING_SECTIONS.profileDetails,
    flow: TRACKING_FLOW.profile,
  },
  allMissions: {
    page_name: "All Missions",
    section: TRACKING_SECTIONS.missionsOverview,
    flow: TRACKING_FLOW.mission,
  },
  missionDetails: {
    page_name: "Mission Details",
    section: TRACKING_SECTIONS.missionDetails,
    flow: TRACKING_FLOW.mission,
  },
  newMission: {
    page_name: "New Mission",
    section: TRACKING_SECTIONS.missionDetails,
    flow: TRACKING_FLOW.mission,
  },
  missionInviteMember: {
    page_name: "Invite Member",
    section: TRACKING_SECTIONS.missionsOverview,
    flow: TRACKING_FLOW.mission,
  },
  profileMission: {
    page_name: "Profile",
    section: TRACKING_SECTIONS.missionsOverview,
    flow: TRACKING_FLOW.mission,
  },
  effortReportMission: {
    page_name: "Effort Report",
    section: TRACKING_SECTIONS.missionsOverview,
    flow: TRACKING_FLOW.mission,
  },
  missionEngineering: {
    page_name: "Engineering",
    section: TRACKING_SECTIONS.missionsOverview,
    flow: TRACKING_FLOW.mission,
  },
  missionReport: {
    page_name: "Mission Report",
    section: TRACKING_SECTIONS.missionsOverview,
    flow: TRACKING_FLOW.mission,
  },
  missionDailyReport: {
    page_name: "Daily Report",
    section: TRACKING_SECTIONS.missionsOverview,
    flow: TRACKING_FLOW.mission,
  },
  roleDetails: {
    page_name: "Role Details",
    section: TRACKING_SECTIONS.roleDetails,
    flow: TRACKING_FLOW.missionBoard,
  },
  editMissionRole: {
    page_name: "Edit Mission Role",
    section: TRACKING_SECTIONS.roleDetails,
    flow: TRACKING_FLOW.missionBoard,
  },
  missionBoardDetails: {
    page_name: "Mission Details",
    section: TRACKING_SECTIONS.missionDetails,
    flow: TRACKING_FLOW.missionBoard,
  },
  editMissionDetails: {
    page_name: "Edit Mission Details",
    section: TRACKING_SECTIONS.missionDetails,
    flow: TRACKING_FLOW.missionBoard,
  },
  addMissionRole: {
    page_name: "Add Mission Role",
    section: TRACKING_SECTIONS.missionDetails,
    flow: TRACKING_FLOW.missionBoard,
  },
  reopenTimecards: {
    page_name: "Reopen Time Cards",
    section: TRACKING_SECTIONS.timecardsOverview,
    flow: TRACKING_FLOW.timecard,
  },
};

export default TRACKING_PAGE_VIEWS;
