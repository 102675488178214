import { gql } from "@apollo/client";

const GET_PARTNERS = gql`
  query GetPartners(
    $filters: PartnerFilter
    $orderBy: PartnerOrderBy
    $first: Int
    $last: Int
    $after: String
    $before: String
    $paginationInfo: PaginationInfo
  ) {
    partners(
      filters: $filters
      orderBy: $orderBy
      first: $first
      last: $last
      after: $after
      before: $before
      paginationInfo: $paginationInfo
    ) {
      nodes {
        id
        name
        formattedName
        status
        website
        slug
        channel
        trusted
        canControlRate
        memberTrial
        location {
          id
          name
        }
      }
      totalCount
      currentPage
      currentOffset
      perPage
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

const GET_PARTNERS_FOR_SELECT = gql`
  query GetPartnersForSelect($filters: PartnerFilter) {
    partners(filters: $filters) {
      nodes {
        id
        name
      }
    }
  }
`;

export { GET_PARTNERS, GET_PARTNERS_FOR_SELECT };
