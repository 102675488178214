import React from "react";
import { FormattedMessage } from "react-intl";
import propTypes from "prop-types";
import { useAuth } from "hooks";
import { Banner } from "components/Banner";
import { BANNER_TYPE, CONNECTION_STATES, LINKS } from "constants/index";
import { GitHubInstallationLink, GithubSettingsLink } from "components/Squads/settings";
import { Spinner } from "components/Loader";
import Card from "components/Card";
import { Header } from "components/DisplayBox";
import { H3 } from "components/Text";
import { PrimaryLink } from "components/Links";

/**
 * SignalInstallation
 *
 * @param {object}  data
 * @param {object}  githubApplicationConnection
 * @param {boolean} loading
 */
const SignalInstallation = ({ data, githubApplicationConnection, loading }) => {
  return (
    <Card>
      <Header>
        <H3>
          <FormattedMessage id="Squads.Profile.SignalInstallation.Title" />
        </H3>
      </Header>
      {loading ? (
        <Spinner size={`1.5rem`} />
      ) : (
        <>
          {githubApplicationConnection?.status === CONNECTION_STATES.suspended && (
            <SuspendedConnection githubApplicationConnection={githubApplicationConnection} />
          )}
          {githubApplicationConnection?.status === CONNECTION_STATES.active &&
            !githubApplicationConnection?.enabled && (
              <DisabledConnection githubApplicationConnection={githubApplicationConnection} />
            )}
          {githubApplicationConnection?.status === CONNECTION_STATES.active && githubApplicationConnection?.enabled && (
            <ActiveConnection githubApplicationConnection={githubApplicationConnection} />
          )}
          {data && data.organization && !githubApplicationConnection && (
            <NoConnection organizationSlug={data.organization.slug} teamSlug={data.slug} />
          )}
          {data && !data.organization && <NoOrganization />}
        </>
      )}
    </Card>
  );
};

/**
 * SignalNotice
 *
 * @param {Object} teamData
 */
const SignalNotice = ({ teamData }) => {
  const githubApplicationConnection = teamData?.organization?.githubApplicationConnection;

  if (!githubApplicationConnection) {
    return <NoConnection organizationSlug={teamData?.organization?.slug} teamSlug={teamData?.slug} />;
  } else if (githubApplicationConnection?.status === CONNECTION_STATES.suspended) {
    return <SuspendedConnection githubApplicationConnection={githubApplicationConnection} />;
  } else {
    return null;
  }
};

/**
 * SuspendedConnection
 *
 * @param {Object} githubApplicationConnection
 */
const SuspendedConnection = ({ githubApplicationConnection }) => {
  return (
    <Banner
      type={BANNER_TYPE.warning}
      isClosable={false}
      cta={() => (
        <GithubSettingsLink
          installationId={githubApplicationConnection.installationId}
          githubOrganization={githubApplicationConnection.organizationSlug}
          messageId="Squads.Profile.SuspendedSignalBanner.CTA.Title"
        />
      )}
    >
      <FormattedMessage id="Squads.Profile.MessageRepoSuspended" />
    </Banner>
  );
};

/**
 * ActiveConnection
 *
 * @param {Object} githubApplicationConnection
 */
const ActiveConnection = ({ githubApplicationConnection }) => {
  return (
    <Banner
      type={BANNER_TYPE.success}
      isClosable={false}
      cta={() => (
        <GithubSettingsLink
          installationId={githubApplicationConnection.installationId}
          githubOrganization={githubApplicationConnection.organizationSlug}
          messageId="Squads.Profile.ActiveSignalBanner.CTA.Title"
        />
      )}
    >
      <FormattedMessage id="Squads.Profile.MessageRepoActive" />
    </Banner>
  );
};

/**
 * DisabledConnection
 */
const DisabledConnection = () => {
  return (
    <Banner type={BANNER_TYPE.warning} isClosable={false}>
      <FormattedMessage id="Squads.Profile.MessageRepoDisabled" />
    </Banner>
  );
};

/**
 * NoConnection
 *
 * @param {string} organizationSlug
 * @param {string} teamSlug
 */
const NoConnection = ({ organizationSlug, teamSlug }) => (
  <Banner
    type={BANNER_TYPE.warning}
    isClosable={false}
    cta={() => <GitHubInstallationLink organizationSlug={organizationSlug} teamSlug={teamSlug} />}
  >
    <FormattedMessage
      id="Squads.Profile.MessageRepoDescription"
      values={{
        privacyPolicy: (
          <PrimaryLink to={LINKS.privacy} target="_blank">
            <FormattedMessage id="LegalModal.TitlePrivacy" />
          </PrimaryLink>
        ),
      }}
    />
  </Banner>
);

/**
 * NoOrganization
 */
const NoOrganization = () => {
  return (
    <Banner type={BANNER_TYPE.warning} isClosable={false}>
      <FormattedMessage id="Squads.Profile.SignalInstallation.NoOrganization" />
    </Banner>
  );
};

const useRenderSignalNotice = ({ teamData, teamLoading }) => {
  if (teamLoading) return false;

  const { isClient, isSignalClient } = useAuth();
  const githubApplicationConnection = teamData?.organization?.githubApplicationConnection;
  const isConnectionActive = githubApplicationConnection?.status === CONNECTION_STATES.active;

  return !isConnectionActive && (isClient || isSignalClient);
};

SignalNotice.propTypes = {
  teamData: propTypes.object.isRequired,
};

ActiveConnection.propTypes = {
  githubApplicationConnection: propTypes.object.isRequired,
};

SuspendedConnection.propTypes = {
  githubApplicationConnection: propTypes.object.isRequired,
};

NoConnection.propTypes = {
  organizationSlug: propTypes.string,
  teamSlug: propTypes.string,
};

SignalInstallation.propTypes = {
  data: propTypes.object.isRequired,
  loading: propTypes.bool.isRequired,
  githubApplicationConnection: propTypes.object,
};

export { SignalNotice, useRenderSignalNotice };

export default SignalInstallation;
