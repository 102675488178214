import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation UserLogin($email: String!, $password: String!, $redirectUrl: String) {
    userLogin(email: $email, password: $password, redirectUrl: $redirectUrl) {
      user {
        id
        firstName
        lastName
        email
        profile {
          id
          memberSince
        }
      }
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
      redirectTo
    }
  }
`;

const REQUEST_PASSWORD_RESET = gql`
  mutation UserSendPasswordResetWithToken($email: String!, $redirectUrl: String!) {
    userSendPasswordResetWithToken(email: $email, redirectUrl: $redirectUrl) {
      message
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation UserUpdatePasswordWithToken(
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    userUpdatePasswordWithToken(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    ) {
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
    }
  }
`;

const LOGOUT = gql`
  mutation UserLogout {
    userLogout {
      user {
        id
      }
    }
  }
`;

export { LOGIN, REQUEST_PASSWORD_RESET, RESET_PASSWORD, LOGOUT };
