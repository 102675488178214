import React, { useState } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useList, useToast } from "hooks";
import { TeamCard } from "components/Teams";
import Modal, { MODAL_SIZE } from "components/Modal";
import { AddToListButton, CreateListModal } from "components/Lists";
import { Grid } from "components/Containers";
import { EditButton } from "components/Buttons";
import { FormattedMessage } from "react-intl";

/**
 * AddToListsModal
 *
 * @param {String}    profileId
 * @param {Boolean}   show
 * @param {Function}  handleClose
 * @param {Function}  handlePrimary
 */
const AddToListsModal = ({ profileId, listType, show, handleClose, ...props }) => {
  const { toast } = useToast();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const variables = { filters: { type: listType } };
  const { data: listData, loading } = useList({ profileId, variables });

  const handlePrimary = () => {
    setShowCreateForm(true);
  };

  const handleListCreateComplete = () => {
    toast.success(<FormattedMessage id={"Profile.AddedToProfileList"} />);
  };

  return (
    <>
      {showCreateForm ? (
        <CreateListModal
          profileId={profileId}
          listType={listType}
          show={show}
          onComplete={handleListCreateComplete}
          onClose={() => {
            setShowCreateForm(false);
            handleClose();
          }}
        />
      ) : (
        <Modal
          title={<FormattedMessage id="Lists.AddToListsModal.TitleAdd" />}
          info={<FormattedMessage id="Lists.AddToListsModal.Description" />}
          size={MODAL_SIZE.large}
          show={show}
          showSave={false}
          showCancel={true}
          secondaryButtonMessageId="Global.Close"
          canSubmit={false}
          onClose={handleClose}
          clickOutsideToClose={true}
          {...props}
        >
          <StyledGrid cols={3} rowGap="3rem" gap="3rem">
            {listData.map((list, index) => (
              <Grid.col key={list.id}>
                <TeamCard
                  data={list}
                  index={index}
                  loading={loading}
                  actions={[
                    { component: AddToListButton, args: { profileId, list: list, onCompleted: handleClose, listType } },
                  ]}
                  handlePrimary={handlePrimary}
                  onClick={() => {}}
                />
              </Grid.col>
            ))}
          </StyledGrid>
          <EditButton variant="green" onClick={handlePrimary}>
            <FormattedMessage id="Create List" />
          </EditButton>
        </Modal>
      )}
    </>
  );
};

AddToListsModal.propTypes = {
  profileId: PropTypes.string.isRequired,
  listType: PropTypes.string.isRequired,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  handlePrimary: PropTypes.func,
};

const StyledGrid = styled(Grid)`
  margin-bottom: 3rem;
`;

export default AddToListsModal;
