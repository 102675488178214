import { GET_PROFILE } from "graphql/queries";

const MOCK_GET_PROFILE = {
  request: {
    query: GET_PROFILE,
    variables: {
      id: "136",
      orderBy: {},
      first: null,
      last: null,
      after: null,
    },
  },
  result: {
    data: {
      profile: {
        id: "136",
        email: "amina.lueilwitz@gsquad.io",
        phoneNumber: "545.606.2241",
        status: "ACTIVE",
        slug: "amina-lueilwitz-c9795ede",
        capacityStatus: "AVAILABLE",
        teamsCount: 0,
        isOnline: false,
        rateLastUpdatedAt: null,
        availabilityLastUpdatedAt: null,
        eligibleForVetting: false,
        archetype: "SQUAD_LEAD",
        partner: null,
        candidate: false,
        currentTime: null,
        nextAvailability: null,
        avatarUrl:
          "http://localhost:3000/rails/active_storage/disk/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDVG9JYTJWNVNTSWhNVEJqWWpkeFoyUnNaMmd3ZDNveFpUQjBPV2N5Tlc0M01IY3paZ1k2QmtWVU9oQmthWE53YjNOcGRHbHZia2tpT1dsdWJHbHVaVHNnWm1sc1pXNWhiV1U5SWpFek9DNXFjR2NpT3lCbWFXeGxibUZ0WlNvOVZWUkdMVGduSnpFek9DNXFjR2NHT3daVU9oRmpiMjUwWlc1MFgzUjVjR1ZKSWc5cGJXRm5aUzlxY0dWbkJqc0dWRG9SYzJWeWRtbGpaVjl1WVcxbE9ncHNiMk5oYkE9PSIsImV4cCI6IjIwMjItMTEtMjhUMTU6NTk6MjYuNjgxWiIsInB1ciI6ImJsb2Jfa2V5In19--0165d8548ebe8c5f596614eb3cfe66f952213db8/138.jpg",
        resumeUrl: null,
        description: "Your teeth? Yeah, I saw them from outside.",
        firstName: "Amina",
        lastName: "Lueilwitz",
        memberSince: null,
        capacity: 40,
        name: "Amina Lueilwitz",
        yearsExperience: 6,
        bachelorDegree: null,
        location: null,
        timezone: {
          name: "(UTC-05:00) America - New York",
          value: "America_New_York",
          __typename: "Timezones",
        },
        __typename: "Profile",
        rejectedAt: null,
        submittedAt: null,
        rejectedDescription: null,
        rejectedReason: null,
        rejectedBy: null,
        approvedBy: null,
        assignedTo: null,
        archivedAt: null,
        archivedReason: null,
        archivedDescription: null,
        archivedBy: null,
        challengesCount: 3,
        passedChallengesCount: 1,
        completedInterviewsCount: 1,
        interviewsCount: 1,
        disciplines: [
          {
            category: "discipline",
            enabled: true,
            name: "Back-End Developer",
            id: "870",
            __typename: "Taggable",
          },
          {
            category: "discipline",
            enabled: true,
            name: "DevOps Engineer",
            id: "872",
            __typename: "Taggable",
          },
          {
            category: "discipline",
            enabled: true,
            name: "Front-End Developer",
            id: "869",
            __typename: "Taggable",
          },
          {
            category: "discipline",
            enabled: true,
            name: "Mobile Developer",
            id: "871",
            __typename: "Taggable",
          },
        ],
        languages: [
          {
            category: "language",
            enabled: true,
            name: "Francais",
            id: "866",
            __typename: "Taggable",
          },
          {
            category: "language",
            enabled: true,
            name: "anglais",
            id: "867",
            __typename: "Taggable",
          },
          {
            category: "language",
            enabled: true,
            name: "english",
            id: "868",
            __typename: "Taggable",
          },
        ],
        education: [],
        externalLinks: [],
        oldLocation: {
          city: null,
          country: "United Kingdom of Great Britain and Northern Ireland",
          countryFlag: "🇬🇧",
          state: null,
          __typename: "Location",
        },
        rate: {
          amount: 1,
          currency: null,
          frequency: "HOURLY",
          symbol: "$",
          __typename: "Rate",
        },
        skills: [
          {
            category: "skill",
            enabled: true,
            name: "Apache Impala",
            id: "213",
            __typename: "Taggable",
          },
          {
            category: "skill",
            enabled: true,
            name: "Cloud Functions for Firebase",
            id: "310",
            __typename: "Taggable",
          },
          {
            category: "skill",
            enabled: true,
            name: "Foundation",
            id: "407",
            __typename: "Taggable",
          },
          {
            category: "skill",
            enabled: true,
            name: "PHP",
            id: "659",
            __typename: "Taggable",
          },
        ],
        tags: [],
        workExperiences: [],
        workSchedule: [],
        user: {
          id: "138",
          __typename: "User",
        },
        interests: [],
        vacations: [],
      },
    },
  },
};

export { MOCK_GET_PROFILE };
