import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { LoginContainer } from "components/Login";
import { ShowcaseSignupForm } from "components/ShowcaseSignup";
import { CLOUDFRONT_URL, TRACKING_PAGE_VIEWS, PAGES } from "constants/index";
import { useTracking } from "hooks";

/**
 * Showcase Signup
 */
const ShowcaseSignup = () => {
  const intl = useIntl();
  const selectedBanner = banners[0];
  const { trackPage } = useTracking();

  useEffect(() => {
    trackPage(TRACKING_PAGE_VIEWS[PAGES.showcaseSignUp].page_name, TRACKING_PAGE_VIEWS[PAGES.showcaseSignUp]);
  }, []);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Signup.Title" })}</title>
      </Helmet>
      <LoginContainer banner={selectedBanner}>
        <ShowcaseSignupForm />
      </LoginContainer>
    </>
  );
};

const banners = [
  {
    type: "video",
    title: "Discover Mission, the new way to hire software engineers.",
    cover: `${CLOUDFRONT_URL}videos/demos/demo_talent_search.m4v`,
  },
];

export default ShowcaseSignup;
