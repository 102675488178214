import { gql } from "@apollo/client";

const SET_NOTIFICATION_SETTINGS = gql`
  mutation SetNotificationSettings($input: SetNotificationSettingsInput!) {
    setNotificationSettings(input: $input) {
      notificationSetting {
        name
        enabled
      }
    }
  }
`;

export { SET_NOTIFICATION_SETTINGS };
