import React from "react";
import { css } from "@emotion/react";
import moment from "moment";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useAuth, useProfile } from "hooks";
import { MEMBER_STATUS } from "constants/index";
import ListTable from "components/ListTable";
import Card from "components/Card";
import { margins } from "style";
import { ProfileStatusUpdateButton } from "components/Profile";

/**
 * CandidateApprovedBy
 *
 * @param {String} profileId
 * @param {String} marginSize
 */
const CandidateApprovedBy = ({ profileId, marginSize, ...props }) => {
  const { isAdmin, isRecruiter } = useAuth();
  const { data, loading } = useProfile({ profileId });
  const canAccess = isAdmin || isRecruiter;

  if (!canAccess || MEMBER_STATUS.active !== data?.profile?.status) {
    return null;
  }

  return (
    <Card isAdmin={isAdmin}>
      <ListTable
        data={!loading && TABLE_DATA(data)}
        loading={loading}
        title={<FormattedMessage id="Candidate.CandidateApprovedBy.Title" />}
        marginSize={marginSize}
        resultsPerPage={2}
        {...props}
      />
      {isAdmin && (
        <ProfileStatusUpdateButton
          css={css`
            margin-top: 1rem;
          `}
          profile={data?.profile}
        />
      )}
    </Card>
  );
};

const TABLE_DATA = (data) => [
  {
    label: <FormattedMessage id="Candidate.CandidateApprovedBy.MemberSince" />,
    value: moment(data?.profile?.memberSince).format("MMMM Do, YYYY"),
  },
  {
    label: <FormattedMessage id="Candidate.CandidateApprovedBy.ApprovedBy" />,
    value: data?.profile?.approvedBy?.firstName
      ? `${data?.profile?.approvedBy?.firstName} ${data?.profile?.approvedBy?.lastName}`
      : `-`,
  },
];

const useRender = (profileId) => {
  const { isAdmin, isRecruiter } = useAuth();
  const { data, loading, error } = useProfile({ profileId });
  const { profile } = data || {};

  if (loading || error) return false;

  return (isAdmin || isRecruiter) && MEMBER_STATUS.active === profile?.status;
};

CandidateApprovedBy.defaultProps = {
  marginSize: margins.normal,
};

CandidateApprovedBy.propTypes = {
  profileId: PropTypes.string,
  marginSize: PropTypes.string,
};

export { useRender };

export default CandidateApprovedBy;
