import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import Icon, { ICON_TYPE } from "components/Icon";
import { colors, fontSize } from "style";
import { Row } from "components/Containers";
import { formatDate, formatNumber } from "utils";

/**
 * SquadVacationDays
 *
 * @param {Array}     vacations
 * @param {Boolean}   isPastDays
 */
const UserVacations = ({ vacations, isPastDays }) => {
  const [filteredVacations, setFilteredVacations] = useState([]);

  useEffect(() => {
    const date = new Date();
    
    setFilteredVacations(
      vacations.filter((key) => (isPastDays ? new Date(key.endDate) < date : new Date(key.endDate) >= date))
    );
  }, [isPastDays]);

  return (
    <div css={styles.container}>
      {filteredVacations.length > 0 ? filteredVacations.map((v, index) => (
        <Row css={styles.days} key={`user-vacation-${index}`}>
          <Icon type={ICON_TYPE.calendar} />
          <FormattedMessage
            id="Squads.UserVacations.VacationDates"
            values={{
              startDate: formatDate(v.startDate,"MMMM Do"),
              endDate: formatDate(v.endDate, "MMMM Do"),
              endDateYear: formatDate(v.endDate, "YYYY"),
              totalDays: v.vacationDayCount,
            }}
          />
        </Row>
      )) : (
        <div css={styles.none}>
          <FormattedMessage
            id={`Squads.UserVacations.${isPastDays ? `NoPast` : `NoUpcoming`}`}
          />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction:column;
    gap:1rem;
  `,
  none: css`
    color: ${colors.grayAnatomyLight1};
  `,
  days: css`
    font-size: ${fontSize.small};
    gap:0.5rem;
  `
};

UserVacations.propTypes = {
  vacations: PropTypes.array,
  isPastDays: PropTypes.bool,
};

export default UserVacations;
