import { gql } from "@apollo/client";
import { CORE_PROFILE_FIELDS } from "graphql/fragments";

const GET_TEAM_PROFILES_SCORE = gql`
  ${CORE_PROFILE_FIELDS}
  query teamProfilesScoresFetch($teamId: ID!, $first: Int, $last: Int, $after: String, $before: String) {
    teamProfilesScoresFetch(teamId: $teamId, first: $first, last: $last, after: $after, before: $before) {
      nodes {
        profile {
          ...ProfileFields
        }
        week
        weekDate
        score
        scoreDelta
        scoreDeltaPercent
        categoryScores {
          category
          score
          scoreDelta
          scoreDeltaPercent
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          profile {
            id
          }
        }
      }
    }
  }
`;

const GET_TEAM_HISTORICAL_SCORE = gql`
  query teamHistoricalScoresFetch($teamId: ID!, $historicalWeeks: Int!) {
    teamHistoricalScoresFetch(teamId: $teamId, historicalWeeks: $historicalWeeks) {
      week
      weekDate
      score
      scoreDelta
      scoreDeltaPercent
      team {
        id
      }
      historicalScores {
        week
        weekDate
        teamDynamics
        engineeringPerformance
        clientSatisfaction
        productDelivery
        overall
      }
      categoryScores {
        category
        score
        scoreDelta
        scoreDeltaPercent
      }
    }
  }
`;

export { GET_TEAM_PROFILES_SCORE, GET_TEAM_HISTORICAL_SCORE };
