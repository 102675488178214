import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { colors } from "style";

/**
 * MentionTag
 *
 * @param {Object}  attributes
 * @param {Object}  children
 * @param {Object}  element
 */
const MentionTag = ({ attributes, children, element }) => (
  <StyledMentionTag {...attributes} contentEditable={false} data-cy={`mention-${element?.name?.replace(" ", "-")}`}>
    {children}@{element?.name}
  </StyledMentionTag>
);

const StyledMentionTag = styled.span`
  padding: 0rem 0.25rem;
  vertical-align: baseline;
  display: inline-block;
  border-radius: 0.3rem;
  background: ${colors.purpleRainLight5};
  color: ${colors.purpleRainBase};
  box-shadow: none;
  padding: 0rem 0.25rem;
  vertical-align: baseline;
  display: inline-block;
`;

MentionTag.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  element: PropTypes.object,
};

export default MentionTag;
