import React from "react";
import PropTypes from "prop-types";
import DisplayBox from "components/DisplayBox";
import Tags from "components/Tags";

/**
 * RoleSecondarySkills
 *
 * @params {Array}    data
 */
const RoleSecondarySkills = ({ data, ...props }) => {
  return <DisplayBox numColumns={1} data={SKILLS_DATA(data)} {...props} />;
};

const SKILLS_DATA = (data) => [
  {
    title: null,
    body: data?.secondarySkills?.length > 0 && <Tags data={data?.secondarySkills} />,
  },
];

RoleSecondarySkills.propTypes = {
  data: PropTypes.object,
};

export default RoleSecondarySkills;
