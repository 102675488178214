import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import PageTitle from "components/PageTitle";
import { PageWrapper, Grid } from "components/Containers";
import { SquadInsightsFilterMenu, SquadInsightsList } from "components/Squads";
import Card from "components/Card";

/**
 * Insights Page
 */
const Insights = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [formValues, setFormValues] = useState({});

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.Insights.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle subtitle={<FormattedMessage id="Squads.Insights.Title" />} />
          </Grid.col>
          <Grid.col start={1} end={10}>
            <Card>
              <SquadInsightsList teamId={id} formValues={formValues} />
            </Card>
          </Grid.col>
          <Grid.col start={10} end={13}>
            <Card>
              <SquadInsightsFilterMenu teamId={id} onChange={setFormValues} />
            </Card>
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default Insights;
