import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import DisplayBox, { RENDER_SIZE, RenderIconWithText } from "components/DisplayBox";
import { EventDetailsModal } from "components/Events";
import { useEvents, useTracking } from "hooks";
import { PAGINATION_TYPE, ShowMore } from "components/Pagination";
import Image from "components/Image";
import { PAGES, TRACKING_PAGE_VIEWS } from "constants/index";
import { margins } from "style";

/**
 * EventsList
 */
const EventsList = ({ marginSize, ...props }) => {
  const { hasNextPage, handleNextPage, loading, loadingMore, data } = useEvents({
    resultsPerPage: 2,
    paginationType: PAGINATION_TYPE.append,
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(false);
  const isEmpty = !loading && (!data || data?.length === 0);
  const { trackPage, trackablePage } = useTracking();

  const handleModalPopup = (eventData) => {
    if (PAGES.eventDetails && trackablePage(PAGES.eventDetails)) {
      trackPage(TRACKING_PAGE_VIEWS[PAGES.eventDetails].page_name, TRACKING_PAGE_VIEWS[PAGES.eventDetails]);
    }
    setSelectedEvent(eventData);
  };
  const handleCloseModal = () => setSelectedEvent(false);

  useEffect(() => {
    setShowModal(selectedEvent ? selectedEvent : false);
  }, [selectedEvent, setShowModal]);

  return (
    <>
      <DisplayBox
        id="events"
        title={<FormattedMessage id="Events.ListEvents.title" />}
        emptyMsg={<FormattedMessage id="Events.ListEvents.NoData" />}
        loading={loading || loadingMore}
        isEmpty={isEmpty}
        numColumns={1}
        rowGap={0}
        marginSize={marginSize}
        data={data}
        itemRenderer={(eventData) => (
          <RenderIconWithText
            size={RENDER_SIZE.large}
            hasLink={true}
            onClick={() => handleModalPopup(eventData)}
            key={eventData.id}
            icon={<Image css={styles.event_image} url={eventData.displayImageUrl} alt={eventData.name} />}
            text={{
              title: eventData.name,
              description: (
                <FormattedMessage
                  id="Events.ListEvents.dateTimezone"
                  values={{ date: eventData.startsAtWithTimezone }}
                />
              ),
              link: <FormattedMessage id="Events.ListEvents.eventDetails" />,
            }}
          />
        )}
        {...props}
      />
      <ShowMore
        loading={loading || loadingMore}
        hasNextPage={!loading && hasNextPage}
        onNextPage={handleNextPage}
        text={<FormattedMessage id="Events.ListEvents.ShowMore" />}
      />
      {selectedEvent && <EventDetailsModal show={showModal} onClose={handleCloseModal} data={selectedEvent} />}
    </>
  );
};

const styles = {
  event_image: css`
    width: ${RENDER_SIZE.large};
    height: ${RENDER_SIZE.large};
    object-fit: center;
    border-radius: 100%;
  `,
};

EventsList.defaultProps = {
  marginSize: margins.normal,
};

EventsList.propTypes = {
  marginSize: PropTypes.string,
};

export default EventsList;
