import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Card from "components/Card";
import { useTracking, useProfile } from "hooks";
import {
  ProfileDisciplines,
  ProfileSkills,
  ProfileContact,
  ProfileEducation,
  ProfileBio,
  ProfileWorkExperience,
} from "components/Profile";
import { MessagesError } from "components/Messages";
import ProfileResume from "./ProfileResume";
import { TRACKING_PAGE_VIEWS } from "constants/index";
import { Grid } from "components/Containers";

/**
 * Profile Box
 *
 * @param {Number}   id
 * @param {String}   keyName
 * @param {Boolean}  isPublicProfile
 * @param {Boolean}  isEditable
 */
const ProfileBox = ({ id, keyName, isEditable, isPublicProfile, ...props }) => {
  const { trackPage, trackablePage } = useTracking();
  const { loading, data, error, profileCompletion } = useProfile({ profileId: id, isPublicProfile });

  useEffect(() => {
    if (data && keyName && trackablePage(keyName)) {
      trackPage(TRACKING_PAGE_VIEWS[keyName].page_name, TRACKING_PAGE_VIEWS[keyName]);
    }
  }, [data]);

  if ((!loading && !data) || error) {
    return <MessagesError messageId="Profile.ProfileBox.Error" />;
  }

  return (
    <Grid>
      <Grid.col start={1} end={13}>
        <Card>
          <ProfileBio
            data={data && data[keyName]}
            loading={loading}
            profileCompletion={profileCompletion}
            isEditable={isEditable}
            {...props}
          />
          <ProfileDisciplines
            data={data && data[keyName]}
            loading={loading}
            isEmpty={profileCompletion.disciplines.isEmpty}
            isEditable={isEditable}
            {...props}
          />
          <ProfileSkills
            data={data && data[keyName]}
            loading={loading}
            isEmpty={profileCompletion.skills.isEmpty}
            isEditable={isEditable}
            {...props}
          />
          {!isPublicProfile && (
            <ProfileContact
              data={data && data[keyName]}
              loading={loading}
              isEmpty={profileCompletion.info.isEmpty}
              isEditable={isEditable}
              {...props}
            />
          )}
        </Card>
      </Grid.col>
      <Grid.col start={1} end={13}>
        <Card>
          <Grid rowGap="1rem">
            <Grid.col start={1} end={13}>
              <ProfileWorkExperience
                profileId={data && data[keyName]?.id}
                data={data && data[keyName]?.workExperiences}
                loading={loading}
                isEmpty={profileCompletion.workExperiences.isEmpty}
                isEditable={isEditable}
                {...props}
              />
            </Grid.col>
            {!isPublicProfile && (
              <Grid.col start={1} end={13}>
                <ProfileResume
                  data={data && data[keyName]}
                  loading={loading}
                  isEmpty={profileCompletion.resume.isEmpty}
                  isEditable={isEditable}
                  {...props}
                />
              </Grid.col>
            )}
          </Grid>
        </Card>
      </Grid.col>
      <Grid.col start={1} end={13}>
        <Card>
          <ProfileEducation
            profileId={data && data[keyName]?.id}
            data={data && data[keyName]?.education}
            loading={loading}
            isEmpty={profileCompletion.education.isEmpty}
            isEditable={isEditable}
            {...props}
          />
        </Card>
      </Grid.col>
    </Grid>
  );
};

ProfileBox.defaultProps = {
  keyName: "profile",
  isPublicProfile: false,
};

ProfileBox.propTypes = {
  id: PropTypes.string,
  keyName: PropTypes.string,
  isPublicProfile: PropTypes.bool,
  isEditable: PropTypes.bool,
};

export default ProfileBox;
