import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import { NoteCard } from "components/Notes";
import { useComments } from "hooks";
import { COMMENTABLE_TYPES } from "constants/index";
import { Spinner } from "components/Loader";
import { Grid } from "components/Containers";

/**
 * NotesList
 *
 * @param {String}    profileId
 * @param {Object}    user
 * @param {Number}    resultsPerPage
 */
const NotesList = ({ profileId: commentableId, user, resultsPerPage }) => {
  const { ref, inView } = useInView();
  const commentableType = COMMENTABLE_TYPES.profile;
  const params = { commentableId, commentableType, resultsPerPage };
  const { loading, loadingMore, data, hasNextPage, handleNextPage } = useComments(params);

  useEffect(() => {
    if (inView && hasNextPage && !loading && !loadingMore) {
      handleNextPage();
    }
  }, [inView]);

  return (
    <Grid>
      {data?.map((note) => (
        <Grid.col start={1} end={13} key={note?.id}>
          <NoteCard data={note} user={user} />
        </Grid.col>
      ))}
      {(loading || loadingMore) && (
        <Grid.col start={1} end={13}>
          <Spinner margin={`1rem auto`} />
        </Grid.col>
      )}
      <div ref={ref} />
    </Grid>
  );
};

NotesList.defaultProps = {
  resultsPerPage: 10,
};

NotesList.propTypes = {
  profileId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  filters: PropTypes.object,
  resultsPerPage: PropTypes.number,
};

export default NotesList;
