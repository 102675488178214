import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import Form, { SIZE, TYPE, DECORATORS } from "components/Form";
import { ICON_TYPE, ICON_SIZE, ICON_POSITION } from "components/Icon";
import { DISCIPLINES } from "constants/index";
import { YEARS_OF_EXPERIENCE, ROLES } from "constants/index";
import { margins } from "style";

/**
 * FormExperience
 *
 * @params {Function}   onChange
 * @params {Function}   onCancel
 * @params {Function}   onSubmit
 * @params {Object}     values
 */
const FormExperience = ({ onChange, onSubmit, onCancel, values }) => {
  const options = DISCIPLINES.filter((item) => item.roles?.includes(values?.role));
  const numDisciplines = options.length;
  const defaultDiscipline = [{ uuid: options[0]?.uuid, name: options[0]?.name }];
  const showTechDegree = values?.role === ROLES.developer || values?.role === ROLES.tech_lead;

  /**
   * When page loads preset disciplines if there is only a single value
   */
  const [initialValues] = useState(numDisciplines === 1 ? { ...values, disciplines: defaultDiscipline } : values);

  return (
    <Form
      data={FORM_DATA(options, showTechDegree)}
      initialValues={initialValues}
      onChange={onChange}
      onSubmit={onSubmit}
      marginSize={margins.none}
      cancelButton={{
        show: true,
        labelId: "Global.GoBack",
        onClick: () => {
          onCancel();
          onChange({ ...values, disciplines: [] }); // Nullify disciplines on Go Back
        },
      }}
      saveButton={{
        show: true,
        labelId: "Global.Continue",
        disabled:
          (!values?.bachelorDegree && showTechDegree) ||
          !values?.yearsExperience ||
          !values?.disciplines ||
          values?.disciplines?.length === 0,
      }}
    />
  );
};

const FORM_DATA = (options, showTechDegree) => [
  {
    style: css`
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
    `,
    // Hide the specialties if there is only 1 option
    items: [
      ...(showTechDegree ? OPTION_DEGREE : []),
      ...OPTION_YEARS,
      ...(options.length > 1 ? OPTION_SPECIALTIES(options) : []),
    ],
  },
];

const OPTION_DEGREE = [
  {
    label: <FormattedMessage id="Signup.FormExperience.FormLabelBachelorDegree" />,
    type: TYPE.h4,
    size: SIZE.xlarge,
  },
  {
    type: TYPE.select,
    size: SIZE.xlarge,
    properties: {
      name: "bachelorDegree",
      placeholder: "Select...",
      options: [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" },
      ],
    },
    decorator: {
      type: DECORATORS.icon,
      props: {
        type: ICON_TYPE.training,
        size: ICON_SIZE.medium,
        position: ICON_POSITION.overlay,
      },
    },
  },
];

const OPTION_YEARS = [
  {
    label: <FormattedMessage id="Signup.FormExperience.FormLabelYearsExperience" />,
    type: TYPE.h4,
    size: SIZE.xlarge,
  },
  {
    type: TYPE.select,
    size: SIZE.xlarge,
    properties: {
      name: "yearsExperience",
      placeholder: "Select...",
      options: YEARS_OF_EXPERIENCE,
    },
    decorator: {
      type: DECORATORS.icon,
      props: {
        type: ICON_TYPE.clock,
        size: ICON_SIZE.medium,
        position: ICON_POSITION.overlay,
      },
    },
  },
];

const OPTION_SPECIALTIES = (options) => [
  {
    label: <FormattedMessage id="Signup.FormExperience.FormLabelKind" />,
    type: TYPE.h4,
    size: SIZE.xlarge,
  },
  {
    type: TYPE.checkBoxGroup,
    size: SIZE.xlarge,
    properties: {
      name: "disciplines",
      options,
      valueKey: "uuid",
      labelKey: "name",
      descriptionKey: "description",
    },
  },
];

FormExperience.propTypes = {
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
};

export default FormExperience;
