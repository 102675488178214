import { GET_TERM_SHEET_TEAM_STATE } from "graphql/queries";
import { useQueryData } from "hooks";

export default function useTermSheet({ teamId, selectedDate }) {
  const keyName = "termSheetTeamState";
  const { loading, data, error, refetch, refetching } = useQueryData({
    queryName: GET_TERM_SHEET_TEAM_STATE,
    keyName,
    variables: {
      teamId,
      upto: selectedDate,
    },
    skip: Number.isInteger(parseInt(teamId)) === false,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  return {
    loading: loading || refetching,
    data: data && data[keyName],
    error,
    refetch,
    getBuyingRateCommission,
    getBuyingAmount,
    getConvertedBuyingAmount,
    getSellingAmount,
    getConvertedSellingAmount,
    getMarginAmount,
    getMarginPercent,
    getConvertedMarginAmount,
    getTotalMarginPercent,
  };
}

const getBuyingRateCommission = (member) =>
  parseFloat(member.buyingRate) * (parseFloat(member.commissionPercent) / 100.0);
const getBuyingAmount = (member) =>
  parseInt(member.buyingQuantity) * (parseFloat(member.buyingRate) + getBuyingRateCommission(member));
const getSellingAmount = (member) => parseFloat(member.sellingBasePrice) - parseFloat(member.sellingDiscount);
const getMarginAmount = (member) => getSellingAmount(member) - getBuyingAmount(member);
const getMarginPercent = (member) => (getMarginAmount(member) / getSellingAmount(member)) * 100;
const getConvertedBuyingAmount = (member) => getBuyingAmount(member) * member?.conversionRate;
const getConvertedSellingAmount = (member) => getSellingAmount(member) * member?.conversionRate;
const getConvertedMarginAmount = (member) => getMarginAmount(member) * member?.conversionRate;

const getTotalMarginPercent = (members) => {
  const sellingAmount = members?.reduce((accumulator, item) => accumulator + getSellingAmount(item), 0);
  const buyingAmount = members?.reduce((accumulator, item) => accumulator + getBuyingAmount(item), 0);
  const profit = sellingAmount - buyingAmount;

  return (profit / sellingAmount) * 100.0;
};
