import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { BUTTON_SIZE, PrimaryButton } from "components/Buttons";
import { LINKS } from "constants";
import { useAuth } from "hooks";

/**
 * TeamAddButton
 *
 * @param {String}      style
 */
const HireButton = ({ style }) => {
  const { isClient } = useAuth();
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.stopPropagation();
    navigate(LINKS.book_meeting_link);
  };

  if (!isClient) return null;

  return (
    <PrimaryButton
      variant="orangeCountyBase"
      css={[styles.button, style]}
      size={BUTTON_SIZE.medium}
      onClick={handleClick}
    >
      <FormattedMessage id="ClientSearch.ClientSearchProfileHire.CTA" />
    </PrimaryButton>
  );
};

const styles = {
  button: css`
    width: 100%;
  `,
};

HireButton.propTypes = {
  style: PropTypes.object,
};

export default HireButton;
