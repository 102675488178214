import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import Card from "components/Card";
import { Grid, PageWrapper } from "components/Containers";
import NoResults from "components/Messages/NoResults";
import { colors } from "style";
import { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { SquadFormAsClient } from "components/Squads";
import { useAuth } from "hooks";
import PageTitle from "components/PageTitle";
import { H3 } from "components/Text";
import { LINKS } from "constants";

/**
 * Squads Page
 */
const Squads = () => {
  const [showForm, setShowForm] = useState(false);
  const { organization, firstName, teams } = useAuth();
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    if (teams?.length > 0) {
      navigate(LINKS.squad_profile(teams[0].slug));
    }
  }, [teams]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.New.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              title={<FormattedMessage id="Squads.New.Title" values={{ name: firstName }} />}
              subtitle={
                <H3>
                  <FormattedMessage id="Squads.New.Description" />
                </H3>
              }
            />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <Card>
              <NoResults
                title="Squads.New.MessageTitle"
                description="Squads.New.MessageDescription"
                icon={{
                  color: colors.purpleRainBase,
                  background: colors.purpleRainLight3,
                  type: ICON_TYPE.mentorship,
                  size: ICON_SIZE.xxxlarge,
                  container_size: `8rem`,
                }}
                button={{
                  title: "Squads.New.MessageCta",
                  props: {
                    onClick: () => setShowForm(true),
                    variant: "green",
                  },
                }}
              />
            </Card>
          </Grid.col>
        </Grid>
      </PageWrapper>
      <SquadFormAsClient
        show={showForm}
        onClose={() => setShowForm(false)}
        onComplete={() => setShowForm(false)}
        organizationId={organization?.slug}
      />
    </>
  );
};

export default Squads;
