import React from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { colors, fontSize } from "style";

/**
 * Disclaimer
 */
const Disclaimer = () => (
  <div css={styles.disclaimer}>
    <FormattedMessage id="Client.Search.Disclaimer" />
  </div>
);

const styles = {
  disclaimer: css`
    color: ${colors.grayAnatomyLight1};
    font-size: ${fontSize.small};
    max-width: 50rem;
    margin: 0 auto;
    line-height: 2rem;
    text-align: center;
  `,
};

export default Disclaimer;
