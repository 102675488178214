import { gql } from "@apollo/client";

const GET_TOPICS = gql`
  query GetTopics($filters: TagFilter, $first: Int, $last: Int, $before: String, $after: String) {
    topics(filters: $filters, first: $first, last: $last, before: $before, after: $after) {
      nodes {
        id
        name
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export { GET_TOPICS };
