import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { FormattedMessage } from "react-intl";
import { PrimaryButton, SecondaryButton, DestructableButton } from "components/Buttons";
import { colors, fontSize, margins } from "style";

/**
 * Shows a Modal
 *
 * @params {Boolean}    show
 * @params {func}       onClose
 * @params {Element}    title
 * @params {Boolean}    show:
 * @params {Function}   onClose
 * @params {Function}   onSubmit
 * @params {Boolean}    showSave
 * @params {Boolean}    showSaveAsDestructable
 * @params {Boolean}    showCancel
 * @params {Boolean}    loading
 * @params {String}     size
 * @params {Boolean}    isDestructable
 * @params {Boolean}    canSubmit
 * @params {Element}    children
 * @params {String}     primaryButtonMessageId
 * @params {String}     secondaryButtonMessageId
 * @params {String}     footer
 * @params {Boolean}     disabled
 */
const ModalWindow = ({
  title,
  subTitle,
  info,
  show,
  onClose,
  onDestructive,
  onSubmit,
  showSave,
  showSaveAsDestructable,
  showCancel,
  loading,
  size = "sm",
  centered,
  isDestructable,
  canSubmit,
  children,
  primaryButtonMessageId,
  secondaryButtonMessageId,
  desctructableButtonMessageId,
  footer,
  disabled,
  clickOutsideToClose,
  ...props
}) => (
  <Modal
    show={show}
    {...(clickOutsideToClose ? {} : { backdrop: "static" })}
    onHide={onClose}
    size={size}
    centered={centered}
    {...props}
  >
    <Modal.Header closeButton>
      <Modal.Title>
        <div css={styles.header}>
          <div>{title}</div>
          <div css={styles.info}>{info}</div>
        </div>
        <div css={styles.subTitle}>{subTitle}</div>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer>
      <div css={styles.footer_container}>
        {isDestructable && (
          <DestructableButton onClick={onDestructive} disabled={loading}>
            <FormattedMessage id={desctructableButtonMessageId} />
          </DestructableButton>
        )}
        {footer && <div>{footer}</div>}
      </div>
      {showCancel && (
        <SecondaryButton onClick={onClose} disabled={loading} type="button">
          <FormattedMessage id={secondaryButtonMessageId} />
        </SecondaryButton>
      )}
      {showSave && (
        <PrimaryButton onClick={onSubmit} disabled={disabled || loading || !canSubmit} type="submit">
          <FormattedMessage id={primaryButtonMessageId} />
        </PrimaryButton>
      )}
      {showSaveAsDestructable && (
        <DestructableButton onClick={onSubmit} disabled={disabled || loading || !canSubmit}>
          <FormattedMessage id={primaryButtonMessageId} />
        </DestructableButton>
      )}
    </Modal.Footer>
  </Modal>
);

const styles = {
  footer_container: css`
    margin-right: auto;
    display: flex;
    gap: 1rem;
    color: ${colors.grayAnatomyLight1};
  `,
  header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  info: css`
    font-size: ${fontSize.normal};
    text-align: right;
    font-weight: 400;
  `,
  subTitle: css`
    font-size: ${fontSize.normal};
    font-weight: 400;
    margin-top: ${margins.xxsmall};
  `,
};

ModalWindow.defaultProps = {
  showSave: true,
  showSaveAsDestructable: false,
  showCancel: true,
  primaryButtonMessageId: `Global.Save`,
  secondaryButtonMessageId: `Global.Cancel`,
  desctructableButtonMessageId: `Global.Delete`,
  disabled: false,
  clickOutsideToClose: false,
  centered: true,
};

ModalWindow.propTypes = {
  title: PropTypes.element,
  subTitle: PropTypes.string,
  info: PropTypes.element,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onDestructive: PropTypes.func,
  showSave: PropTypes.bool,
  showSaveAsDestructable: PropTypes.bool,
  showCancel: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  children: PropTypes.any.isRequired,
  isDestructable: PropTypes.bool,
  canSubmit: PropTypes.bool,
  primaryButtonMessageId: PropTypes.string,
  secondaryButtonMessageId: PropTypes.string,
  desctructableButtonMessageId: PropTypes.string,
  footer: PropTypes.any,
  clickOutsideToClose: PropTypes.bool,
  centered: PropTypes.bool,
};

export default ModalWindow;
