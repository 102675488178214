import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { CheckBoxDropDown } from "components/Form";
import { shortenText } from "utils";

/**
 * ScopedRepositoriesFilter
 *
 * @param {String} id
 * @param {String} gitRepositories
 * @param {Array} value
 */
const ScopedRepositoriesFilter = ({ id, gitRepositories, value, ...props }) => {
  const [gitRepositoriesOptions, setGitRepositoriesOptions] = useState([]);

  useEffect(() => {
    if (gitRepositories) {
      setGitRepositoriesOptions(
        gitRepositories.split(",").map((repo) => ({
          name: shortenText(repo, 50, false, true),
          id: repo,
        }))
      );
    }
  }, [gitRepositories]);

  return (
    <CheckBoxDropDown
      id={id}
      label={
        <FormattedMessage
          id={`Squads.Engineering.ScopedRepositoriesFilter.Label${!value || value?.length === 0 ? `Empty` : ``}`}
          values={{
            count: parseInt(value?.length),
          }}
        />
      }
      labelKey="name"
      valueKey="id"
      value={value}
      options={gitRepositoriesOptions}
      disabled={gitRepositoriesOptions.length === 0}
      minLength={0}
      limit={9999}
      noWrap
      {...props}
    />
  );
};

ScopedRepositoriesFilter.propTypes = {
  id: PropTypes.string.isRequired,
  gitRepositories: PropTypes.string.isRequired,
  value: PropTypes.array,
};

export default ScopedRepositoriesFilter;
