import { gql } from "@apollo/client";

const GET_TEAM_ACTIVITY_LOGS = gql`
  query GetTeamActivityLogs($filters: ActivityLogsFilter!, $teamId: ID!) {
    unifiedTeamActivityLogs(teamId: $teamId, filters: $filters) {
      activityLogs {
        date
        activities {
          type
          count
          metaData {
            repositories
            pullRequestTitles
            titles
            totalDiffAdditions
            totalDiffDeletions
            totalDiffTotalChanges
            users
            data
          }
        }
      }
      metaData {
        minutesWorked
        weeklyCost
        costPerCommit
      }
    }
  }
`;

export { GET_TEAM_ACTIVITY_LOGS };
