import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useAuth, useProfile } from "hooks";
import { Grid } from "components/Containers";
import Card from "components/Card";
import {
  CandidateProfileStatus,
  CandidateProgress,
  CandidateTitle,
  CandidateCodeChallenge,
  CandidateInterview,
  CandidateStatus,
} from "components/Candidate";

/**
 * Home
 */
const Home = () => {
  const { profileId } = useAuth();
  const { loading } = useProfile({ profileId });
  const intl = useIntl();

  if (loading) return null;

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Candidate.Home.Title" })}</title>
      </Helmet>
      <Grid topMargin>
        <Grid.col start={1} end={9}>
          <Grid>
            <Grid.col start={1} end={13}>
              <CandidateTitle profileId={profileId} />
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                <CandidateCodeChallenge profileId={profileId} />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                <CandidateInterview profileId={profileId} />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                <CandidateProfileStatus />
              </Card>
            </Grid.col>
          </Grid>
        </Grid.col>
        <Grid.col start={9} end={13}>
          <Grid>
            <Grid.col start={1} end={13}>
              <Card>
                <CandidateProgress profileId={profileId} />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <CandidateStatus />
            </Grid.col>
          </Grid>
        </Grid.col>
      </Grid>
    </>
  );
};

export default Home;
