import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { colors, fontSize } from "style";
import {
  CommentActivityTooltip,
  CommitActivityTooltip,
  PullRequestActivityTooltip,
  ReviewActivityTooltip,
} from "components/ActivityTooltip";

/**
 * ActivityTooltip
 *
 * @param {Object}     tooltipData
 * @param {Object}     tooltipPosition
 */
export const ActivityTooltip = ({ tooltipData, tooltipPosition }) => {
  if (!tooltipData || JSON.stringify(tooltipData) == "{}") return null;

  const windowWidth = window.innerWidth;
  const { activityTypeIndex } = tooltipData;
  const Component = getActivityComponent(activityTypeIndex);
  const isNearEdge = (windowWidth - tooltipPosition?.clientX) < 500;
  
  return (
    <StyledToolTip {...tooltipPosition} isNearEdge={isNearEdge}>
      <Component tooltipData={tooltipData} />
    </StyledToolTip>
  );
};

const StyledToolTip = styled.div`
  position: absolute;
  white-space: pre-line;
  padding: 0;
  border-radius: 0.6rem;
  background: white;
  border: 1px solid ${colors.grayAnatomyLight3};
  color: ${colors.grayAnatomyDark1};
  pointer-events: none;
  z-index: 9;
  left: ${({ x, isNearEdge }) => isNearEdge ? `auto` : x}px;
  right: ${({ x, width, isNearEdge }) => isNearEdge ? width - x : `auto`}px;
  bottom: ${({ y, height }) => height - y}px;
  width: max-content;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  width: 350px;
  font-size: ${fontSize.xsmall};

  > * {
    padding: 1rem 1rem;
    border-top: 1px solid ${colors.grayAnatomyLight3};

    &:first-of-type {
      border-top: none;
    }
  }
`;

const getActivityComponent = (index) => {
  switch (index) {
    case 0: // Comments
      return CommentActivityTooltip;
    case 1: // Commits
      return CommitActivityTooltip;
    case 2: // Pull Requests
    case 3: // Merges
      return PullRequestActivityTooltip;
    case 4: // Reviews
      return ReviewActivityTooltip;
    default:
      return DefaultActivityTooltip;
  }
};

const ChangesText = ({ additions, deletions, changes }) => (
  <FormattedMessage
    id="ActivityTooltip.TooltipData.Changes"
    values={{
      additions: (
        <span css={styles.addition}>
          <FormattedMessage id="ActivityTooltip.TooltipData.Additions" values={{ additions }} />
        </span>
      ),
      deletions: (
        <span css={styles.deletion}>
          <FormattedMessage id="ActivityTooltip.TooltipData.Deletions" values={{ deletions }} />
        </span>
      ),
      changes: (
        <span css={styles.changes}>
          <FormattedMessage id="ActivityTooltip.TooltipData.TotalChanges" values={{ changes }} />
        </span>
      ),
    }}
  />
);

ChangesText.propTypes = {
  additions: PropTypes.number,
  deletions: PropTypes.number,
  changes: PropTypes.number,
};

const DefaultActivityTooltip = ({ tooltipData }) => {
  const { data } = tooltipData;

  return <FormattedMessage id="TooltipData.Default" values={{ value: data }} />;
};

DefaultActivityTooltip.propTypes = {
  tooltipData: PropTypes.object,
};

const KeyValueText = ({ id, value }) => (
  <FormattedMessage id="ActivityTooltip.TooltipData.KeyValue" values={{ id, value }} />
);

KeyValueText.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
};

const CommentsText = ({ values }) => {
  const { type } = values;

  return (
    <FormattedMessage
      id={`ActivityTooltip.TooltipData.${type ? "Comment" : "Comments"}`}
      values={{ type, ...values }}
    />
  );
};

CommentsText.propTypes = {
  values: PropTypes.object,
};

const styles = {
  addition: css`
    color: ${colors.green};
    background: ${colors.lightGreen};
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
    font-weight: 600;
  `,
  deletion: css`
    color: ${colors.red};
    background: ${colors.lightRed};
    margin-left: 0.5rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
    font-weight: 600;
  `,
  changes: css`
    color: ${colors.grayAnatomyBase};
    margin-left: 1rem;
  `,
};

ActivityTooltip.propTypes = {
  tooltipData: PropTypes.object,
  tooltipPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
};

export default ActivityTooltip;

export { ChangesText, KeyValueText, CommentsText };
