import React from "react";
import styled from "@emotion/styled";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { PageWrapper } from "components/Containers";
import { useAuth } from "hooks";
import PageTitle from "components/PageTitle";
import { fontSize } from "style";
import { H3 } from "components/Text";

/**
 * ClientHome
 */
const ClientHome = () => {
  const { isClient } = useAuth();
  const intl = useIntl();

  if (!isClient) return null;

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Client.Profile.Title" })}</title>
      </Helmet>
      <StyledPageTitle
        title={
          <Title>
            <FormattedMessage id="Client.Profile.H1" />
          </Title>
        }
      />
      <H3>This page is currently under development. Please check back soon.</H3>
    </PageWrapper>
  );
};

const StyledPageTitle = styled(PageTitle)`
  margin-bottom: 2rem;
`;

const Title = styled.span`
  font-size: ${fontSize.xxxxlarge};
  font-weight: 700;
`;

export default ClientHome;
