import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors } from "style";

/**
 * Show a generic error message
 *
 * @params {String}  children
 */
const Warning = ({ children, ...props }) => (
  <div data-warning css={style} {...props}>
    {children}
  </div>
);

const style = css`
  width: 100%;
  background: ${colors.orangeCountyLight4};
  color: ${colors.orangeCountyDark3};
  padding: 1rem;
  border-radius: 0.6rem;
  font-weight: 500;
  line-height: normal;
`;

Warning.propTypes = {
  children: PropTypes.any,
};

export default Warning;
