import React from "react";
import { useAuth } from "hooks";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { fontSize } from "style";
import { PrimaryButton, BUTTON_SIZE } from "components/Buttons";
import { Link } from "components/Links";
import { LINKS } from "constants/index";

/**
 * Showcase SignUpCard
 */
const ShowcaseSignUpCard = ({ ...props }) => {
  const me = useAuth();

  return (
    <div css={styles.container} {...props}>
      <div css={styles.name}>
        <FormattedMessage id="Showcase.ShowcaseSignUpCard.H1" />
      </div>
      <div css={styles.link}>
        <Link to={LINKS.showcase_signup_to_client(me.uuid)} withMoreIcon routerEnabled>
          <PrimaryButton size={BUTTON_SIZE.medium}>
            <FormattedMessage id="Showcase.ShowcaseSignUpCard.CTA" />
          </PrimaryButton>
        </Link>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 3rem;
  `,
  name: css`
    font-size: ${fontSize.medium};
    margin-top: 1rem;
    font-weight: 600;
    max-width: 80%;
    text-align: center;
  `,
  link: css`
    font-size: ${fontSize.small};
    margin-top: 1.5rem;
  `,
};

export default ShowcaseSignUpCard;
