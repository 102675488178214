import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { colors, gradients, fontSize } from "style";
import Icon, { ICON_TYPE } from "components/Icon";
import { usePrevious } from "utils";
import { DATE_FORMAT_TYPE } from "constants/index";
import "react-datepicker/dist/react-datepicker.css";

/**
 * MonthPicker
 *
 * @params {String}   format
 * @params {String}   name
 * @params {Function} onChange
 * @params {Object}   initialValue
 * @params {Boolean}  showMonthYearPicker
 * @params {Boolean}  showFullMonthYearPicker
 * @params {String}   minDate
 *
 */
const MonthPicker = ({
  format,
  name,
  onChange,
  initialValue,
  showMonthYearPicker,
  showFullMonthYearPicker,
  minDate,
}) => {
  const [startDate, setStartDate] = useState(initialValue);
  const prevValue = usePrevious(startDate);

  useEffect(() => {
    if (prevValue !== startDate) {
      onChange(startDate);
    }
  }, [startDate, onChange, prevValue]);

  return (
    <div css={styles.container}>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        dateFormat={format}
        name={name}
        scrollableYearDropdown={true}
        showYearDropdown={true}
        showMonthYearPicker={showMonthYearPicker}
        showFullMonthYearPicker={showFullMonthYearPicker}
        popperPlacement="bottom-start"
        maxDate={new Date()}
        minDate={minDate}
      />
      <div css={styles.iconContainer}>
        <Icon type={ICON_TYPE.calendar} />
      </div>
    </div>
  );
};

const styles = {
  container: css`
    position: relative;
    order: 1;

    .react-datepicker {
      border-radius: 0.6rem;
      background-color: #fff;
      box-shadow: 0 0 14px 0 rgb(0 0 0 / 50%);
      border: 0;
      padding-bottom: 1rem;
    }

    .react-datepicker-popper {
      position: absolute;
      z-index: 5;
    }

    .react-datepicker__triangle {
      visibility: hidden;
    }

    .react-datepicker__input-container {
      input {
        display: block;
        font-size: ${fontSize.small};
        background: #fff;
        font-weight: normal;
        height: 4rem;
        padding-left: 2.8rem;
        line-height: 4rem;
        border-radius: 0.6rem !important;
        border: 1px solid ${colors.grayAnatomyLight3};
        width: 100%;
        color: ${colors.secondary};
        order: 1;
        resize: none;
        appearance: none;
        background-clip: padding-box;
        outline: none;

        &:focus {
          box-shadow: none;
          border-image-source: ${gradients.missionCircularGradient};
          border-width: 2px;
          border-image-slice: 1;
          background-image: linear-gradient(to bottom, #fff, #fff), ${gradients.missionCircularGradient};
          background-origin: border-box;
          background-clip: padding-box, border-box;
          border-color: transparent !important;

          & + label {
            color: ${colors.purpleRainBase};
          }
        }

        &:hover {
          border-color: ${colors.purpleRainBase};

          & + label {
            color: ${colors.purpleRainBase};
          }
        }

        &:disabled {
          border: 1px solid ${colors.grayAnatomyLight3} !important;
          color: ${colors.grayAnatomyLight2} !important;
          background-color: ${colors.grayAnatomyLight5};
        }
      }
    }

    .react-datepicker__navigation {
      height: 4.5rem;
      background-color: ${colors.grayAnatomyLight5};
      color: ${colors.grayAnatomyLight1};
    }

    .react-datepicker__month-container {
      .react-datepicker__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: ${fontSize.small};
        background-color: ${colors.white};
        height: 5rem;
        border-bottom: 1px solid rgba(192, 207, 223, 0.5);
        line-height: 5rem;
      }
      .react-datepicker__monthPicker {
        .react-datepicker__month-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          .react-datepicker__month-text {
            text-align: center;
            line-height: 4rem;
            min-width: 10rem;
            min-height: 4rem;
            font-size: ${fontSize.small};
            outline: none;
            &:focus {
              box-shadow: none;
              border-image-source: ${gradients.missionCircularGradient};
              border-width: 2px;
              border-image-slice: 1;
              background-image: linear-gradient(to bottom, #fff, #fff), ${gradients.missionCircularGradient};
              background-origin: border-box;
              background-clip: padding-box, border-box;
              border-color: transparent !important;
              outline: none;

              & + label {
                color: ${colors.purpleRainBase};
              }
            }

            &:hover {
              border-color: ${colors.purpleRainBase};
              outline: none;
              & + label {
                color: ${colors.purpleRainBase};
              }
            }
          }
        }
      }
      .react-datepicker__month--selected {
        background-color: ${colors.purpleRainBase};
      }
    }
  `,
  iconContainer: css`
    position: absolute;
    left: 0.8rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 9;
  `,
};

MonthPicker.defaultProps = {
  format: DATE_FORMAT_TYPE.MMyyyy,
  showMonthYearPicker: false,
  showFullMonthYearPicker: false,
};

MonthPicker.propTypes = {
  format: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  initialValue: PropTypes.object,
  showMonthYearPicker: PropTypes.bool,
  showFullMonthYearPicker: PropTypes.bool,
  minDate: PropTypes.string,
};

export default MonthPicker;
