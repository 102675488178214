import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { GradientLabel } from "components/Labels";
import { SideNavLink } from "components/Nav/SideNav";

/**
 * SideNavExpander
 *
 * @param {Object}   data
 * @param {Boolean}  isExpanded
 * @param {Function} onToggle
 * @param {Array}    items
 * @param {String}   icon
 * @param {String}   gradientLabel
 */
const SideNavExpander = ({ data, isExpanded, onToggle, items, icon, gradientLabel }) => (
  <>
    <SideNavLink onClick={onToggle} type={icon} end>
      <div css={styles.label}>
        {data.name}
        {gradientLabel && <GradientLabel text={gradientLabel} />}
      </div>
    </SideNavLink>
    <span css={styles.nav_container}>
      {isExpanded &&
        items.map((item) => (
          <SideNavLink key={item.id} type={item.icon} to={item.link} routerEnabled isSubItem end>
            {item.title}
          </SideNavLink>
        ))}
    </span>
  </>
);

const styles = {
  nav_container: css`
    border-left: 1px dashed rgba(255, 255, 255, 0.2);
    margin: 0rem 0rem 0 1.4rem;

    a {
      padding: 0.5rem 0 0.5rem 1rem;
      margin-left: 0.5rem;
    }
  `,
  label: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
};

SideNavExpander.propTypes = {
  data: PropTypes.object,
  isExpanded: PropTypes.bool,
  onToggle: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  icon: PropTypes.string,
  gradientLabel: PropTypes.string,
};

export default SideNavExpander;
