const YEARS_OF_EXPERIENCE = [
  {
    label: "None",
    value: "0",
  },
  {
    label: "1 year",
    value: "1",
  },
  {
    label: "2 years",
    value: "2",
  },
  {
    label: "3 years",
    value: "3",
  },
  {
    label: "4 years",
    value: "4",
  },
  {
    label: "5 years",
    value: "5",
  },
  {
    label: "6 years",
    value: "6",
  },
  {
    label: "7 years",
    value: "7",
  },
  {
    label: "8 years",
    value: "8",
  },
  {
    label: "9 years",
    value: "9",
  },
  {
    label: "10+ years",
    value: "10",
  },
];

export default YEARS_OF_EXPERIENCE;
