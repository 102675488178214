import React from "react";
import PropTypes from "prop-types";

/**
 * InputHidden
 *
 * @params {String}   name
 *
 */
const InputHidden = ({ name }) => {
  return <input type="hidden" name={name} />;
};

InputHidden.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputHidden;
