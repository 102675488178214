/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { breakpoints, colors, fontSize, margins } from "style";
import { TextLoader } from "components/Loader";
import { TRACKING_PAGE_VIEWS } from "constants/index";
import { useTracking } from "hooks";

/**
 * Tabs
 *
 * @param {String}    name
 * @param {Array}     data
 * @param {Integer}   selected
 * @param {Function}  onSelect
 * @param {Boolean}   onDark
 * @param {Boolean}   loading
 * @param {Boolean}   withUrlParams
 * @param {Boolean}   hasMargin
 */
const Tabs = ({ name, data, selected, onSelect, onDark, loading, withUrlParams, hasMargin, ...props }) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { trackPage, trackablePage } = useTracking();

  useEffect(() => {
    if (data && data[selected] !== "undefined") {
      const page = data[selected]?.key;
      if (page && trackablePage(page)) {
        trackPage(TRACKING_PAGE_VIEWS[page].page_name, TRACKING_PAGE_VIEWS[page]);
      }
    }
  }, [selected]);

  /**
   * @description On location change, set the page
   */
  useEffect(() => {
    if (withUrlParams && searchParams.get(name)) {
      onSelect(parseInt(searchParams.get(name)));
    } else {
      onSelect(0);
    }
  }, [location, withUrlParams]);

  /**
   * handleClick
   *
   * @param {Number} index
   */
  const handleClick = (index) => {
    if (withUrlParams) {
      searchParams.set(name, index);
      setSearchParams(searchParams);
    } else {
      onSelect(index);
    }
  };

  return (
    <ul css={styles.container(hasMargin)} {...props}>
      {loading && (
        <li>
          <TextLoader width={`25rem`} />
        </li>
      )}
      {!loading &&
        data?.map((item, index) => (
          <li key={index} css={styles.tab_container} role="tab">
            <a css={styles.tab(selected === index, onDark)} onClick={() => handleClick(index)}>
              {item}
            </a>
          </li>
        ))}
    </ul>
  );
};

const styles = {
  container: hasMargin => css`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    font-size: ${fontSize.small};
    font-weight: 600;
    gap: 2.4rem;
    border-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    line-height: 3.5rem;

    ${hasMargin && `
      margin-left: ${margins.normal};

      @media (max-width: ${breakpoints.portrait}) {
        margin-left: ${margins.small};
      }

      @media (max-width: ${breakpoints.mobile}) {
        margin-left: ${margins.xsmall};
      }
    `}    
  `,
  tab_container: css`
    margin: 0;
    padding: 0;
    display: list-item;
    font-size: ${fontSize.small};
    font-weight: 600;
    list-style: none;
  `,
  tab: (isSelected, onDark) => css`
    font-weight: normal;
    font-size: ${fontSize.small};
    line-height: 1;
    color: ${onDark ? `#fff` : colors.purpleRainDark2};
    display: inline-block;
    padding: 0 0 0.8rem 0;
    position: relative;
    border: 0;
    background: none;
    margin-bottom: -1px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: ${onDark ? `rgba(255,255,255,0.8)` : colors.blueVelvetBase};
    }

    ${isSelected &&
    `
      font-weight: 600;
      color: ${onDark ? `#fff` : colors.purpleRainDark2};
      border-color: #dee2e6 #dee2e6 #fff;

      &:before {
        content: "";
        height: 2px;
        background-image: linear-gradient(to right, ${colors.purpleRainBase}, ${colors.pinkPantherBase});
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    `}
  `,
};

Tabs.defaultProps = {
  name: "tab",
  withUrlParams: false,
};

Tabs.propTypes = {
  name: PropTypes.string,
  data: PropTypes.array,
  selected: PropTypes.number,
  onSelect: PropTypes.func,
  onDark: PropTypes.bool,
  loading: PropTypes.bool,
  withUrlParams: PropTypes.bool,
  hasMargin: PropTypes.bool,
};

export default Tabs;
