import moment from "moment";

/**
 * Get the month from a string date
 *
 * @param {string} str
 *
 * @returns {string}
 */
export default function getMonthFromDate(str, format = "MMMM") {
  if (!str) {
    return;
  }

  return str && String(moment.utc(str).format(format));
}
