import styled from "@emotion/styled";
import { Grid } from "components/Containers";
import { colors } from "style";

const LabelCell = styled.div`
  background: #fff;
  height: 4rem;
  line-height: 4rem;
  font-weight: 600;
  min-width: max-content;
  padding: 0 1rem;
`;

const CellContainer = styled(Grid.col)`
  border-right: 1px solid ${colors.grayAnatomyLight4};
  border-bottom: 1px solid ${colors.grayAnatomyLight4};
  border-color: ${({ disabled }) => (disabled ? "#fff" : colors.grayAnatomyLight4)};
`;

const HeaderCell = styled.div`
  background: ${({ disabled }) => (disabled ? colors.grayAnatomyLight4 : `#fff`)};
  height: 4rem;
  font-weight: 600;
  padding: 0 1.5rem 0 1rem;
  text-align: ${({ alignRight }) => (alignRight ? `right` : `left`)};
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
`;

const DataCell = styled.div`
  height: 4rem;
  line-height: 4rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: text;
  position: relative;
  overflow: visible;
  box-shadow: 0px 0px 0px 4px transparent;

  ${(props) =>
    props.isFocus &&
    `
    transition: box-shadow 300ms ease;
    z-index:10;
    box-shadow: 0px 0px 0px 4px ${colors.purpleRainLight3};
  `}
`;

export { LabelCell, CellContainer, HeaderCell, DataCell };
