/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { CursorPaginate, ClassicPaginate, PAGINATION_TYPE } from "components/Pagination";

/**
 * Paginate
 *
 * @param {String}   type
 */
const Paginate = ({ type, ...props }) => {
  const isClassic = type === PAGINATION_TYPE.classic;
  const PaginationComponent = isClassic ? ClassicPaginate : CursorPaginate;

  return <PaginationComponent {...props} />;
};

Paginate.defaultProps = {
  type: "cursor",
};

Paginate.propTypes = {
  type: PropTypes.string,
};

export default Paginate;
