import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { shortenText } from "utils";
import { GET_PROFILES } from "graphql/queries";
import { MODAL_SIZE, ModalForm } from "components/Modal";
import { SIZE, TYPE } from "components/Form";
import { ApplyForm } from "components/Missions/Roles";
import { MessagesWarning } from "components/Messages";

/**
 * ProxyApplyForm
 *
 * @param {Object}    role
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Function}  onLoading
 */
const ProxyApplyForm = ({ role, show, onClose, onLoading, ...props }) => {
  const [showForm, setShowForm] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const applicantsProfileIds = role?.applicantsProfileIds || [];

  return (
    <>
      <ModalForm
        show={show}
        info={shortenText(role?.name, 35)}
        size={MODAL_SIZE.small}
        data={FORM_DATA}
        title={<FormattedMessage id="Missions.MissionRole.ApplyForm.Title" />}
        description={<FormattedMessage id="Missions.MissionRole.ApplyOnBehalfForm.Description" />}
        primaryButtonMessageId="Missions.MissionRole.ApplyOnBehalfForm.Submit"
        onClose={onClose}
        onSubmit={(payload) => {
          const [profileId, profileName] = payload.profile.split("::");
          const hasApplied = applicantsProfileIds.includes(profileId);

          setProfileData({ id: profileId, name: profileName, hasApplied: hasApplied });
          if (!hasApplied) {
            setShowForm(true);
            onClose();
          }
        }}
        disclaimer={
          profileData?.hasApplied && (
            <MessagesWarning css={styles.disclaimer}>
              <b>
                <FormattedMessage id="Missions.MissionRole.ApplyOnBehalfForm.Disclaimer" />
              </b>
            </MessagesWarning>
          )
        }
        {...props}
      />
      {showForm && profileData && (
        <ApplyForm
          onClose={() => setShowForm(false)}
          show={showForm}
          role={role}
          profileId={profileData.id}
          onLoading={onLoading}
          modalFormProps={{
            title: (
              <FormattedMessage id="Missions.MissionRole.ApplyOnBehalfForm.Title" values={{ name: profileData.name }} />
            ),
          }}
        />
      )}
    </>
  );
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Member",
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "profile",
          required: true,
          placeholder: "Type to search for a member",
          queryName: GET_PROFILES,
          queryKeyName: "profiles",
          queryFieldName: "profiles",
          labelKey: "name",
          valueKey: "idWithName",
          minLength: 0,
          isSearchable: true,
          querySearchFieldName: "search",
          noOptionsMessage: "No members found",
          queryVariables: {
            filters: { type: "MEMBER" },
            includeProfileFields: true,
            includeCandidateProgressFields: false,
            includeDisciplineFields: false,
            includeLanguageFields: false,
            includeEducationFields: false,
            includeExternalLinksFields: false,
            includeOldLocationFields: false,
            includeRateFields: false,
            includeSkillsFields: false,
            includeTagsFields: false,
            includeWorkExperiencesFields: false,
            includeWorkScheduleFields: false,
            includeVacationsFields: false,
            includeUserFields: false,
            includeInterestsFields: false,
          },
        },
      },
    ],
  },
];

const styles = {
  disclaimer: css`
    margin-top: 1rem;
  `,
};

ProxyApplyForm.propTypes = {
  role: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onLoading: PropTypes.func,
};

export default ProxyApplyForm;
