import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { formatDate } from "utils";
import ListTable from "components/ListTable";
import { useTermSheetEvents } from "hooks";
import { Row } from "components/Containers";
import { css } from "@emotion/react";
import { TermSheetEventDescription } from "components/TermSheets";
import { FormattedMessage } from "react-intl";
import { margins } from "style";
import { PrimaryLink } from "components/Links";

/**
 * TermSheetEvents
 *
 * @param {String}    teamId
 * @param {Boolean}   isPastEvents
 * @param {String}    marginSize
 * @param {String}    selectedDate
 * @param {Function}  setSelectedDate
 * @param {Boolean}   refetchEvents
 * @param {Function}  setRefetchEvents
 * @param {Boolean}   canTimeTravel
 */
const TermSheetEvents = ({
  teamId,
  isPastEvents,
  marginSize,
  selectedDate,
  setSelectedDate,
  refetchEvents,
  setRefetchEvents,
  canTimeTravel,
  ...props
}) => {
  const { data, loading, refetch } = useTermSheetEvents({ teamId, isPastEvents });

  const handleOnClick = (data) => {
    setSelectedDate(data?.createdAt);
  };

  useEffect(() => {
    if (refetchEvents) {
      refetch();
      setRefetchEvents(false);
    }
  }, [refetchEvents]);

  return (
    <ListTable
      marginSize={marginSize}
      loading={loading}
      data={data?.map((item) => ({
        label: (
          <RenderRow
            item={item}
            selectedDate={selectedDate}
            canTimeTravel={canTimeTravel}
            handleOnClick={handleOnClick}
          />
        ),
      }))}
      singleColumn
      {...props}
    />
  );
};

const RenderRow = ({ item, selectedDate, canTimeTravel, handleOnClick }) => (
  <Row>
    <div css={styles.date_container}>{formatDate(item.createdAt)}</div>
    <div css={styles.description}>
      <TermSheetEventDescription keyName={item.key} {...item} />
    </div>
    {canTimeTravel && (
      <div>
        <PrimaryLink onClick={() => handleOnClick(item)} disabled={selectedDate === item?.createdAt}>
          <FormattedMessage id="Squads.Squad.TermSheet.FutureEvents.Travel" />
        </PrimaryLink>
      </div>
    )}
  </Row>
);

const styles = {
  date_container: css`
    width: 15rem;
  `,
  description: css`
    font-weight: 400;
  `,
};

RenderRow.propTypes = {
  item: PropTypes.object.isRequired,
  selectedDate: PropTypes.string,
  canTimeTravel: PropTypes.bool,
  handleOnClick: PropTypes.func,
};

TermSheetEvents.propTypes = {
  teamId: PropTypes.string.isRequired,
  isPastEvents: PropTypes.bool,
  marginSize: PropTypes.string,
  setSelectedDate: PropTypes.func,
  selectedDate: PropTypes.string,
  refetchEvents: PropTypes.bool,
  setRefetchEvents: PropTypes.func,
  canTimeTravel: PropTypes.bool,
};

TermSheetEvents.defaultProps = {
  marginSize: margins.normal,
  isPastEvents: true,
};

export default TermSheetEvents;
