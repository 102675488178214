import React from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Spinner } from "components/Loader";
import { colors, fontSize } from "style";

/**
 * Load more data button
 *
 * @params {any}      text
 * @params {Boolean}  hasNextPage
 * @params {Function} onNextPage
 * @params {Boolean}  loading
 */
const ShowMore = ({ text, hasNextPage, onNextPage, loading, ...props }) => {
  if (!hasNextPage && !loading) return null;

  return (
    <div css={styles.container} {...props}>
      <button css={styles.button} onClick={onNextPage} disabled={loading}>
        {loading ? (
          <Spinner size={`1.5rem`} />
        ) : text ? (
          text
        ) : (
          <FormattedMessage id="DataTable.DataTableLoadMore.ButtonTitle" />
        )}
      </button>
    </div>
  );
};

const styles = {
  container: css`
    width: 100%;
    text-align: center;
    overflow: hidden;
  `,
  button: css`
    border: 0;
    width: 100%;
    text-align: center;
    background: #fff;
    padding-top: 2rem;
    color: ${colors.purpleRainBase};
    box-shadow: none;
    font-size: ${fontSize.xsmall};
    font-weight: 600;
    border: 0;
    border-top: 1px ${colors.grayAnatomyLight4} solid;
    border-radius: 0;
    border-radius: 1rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    &:hover {
      text-decoration: underline;
    }
  `,
};

ShowMore.propTypes = {
  text: PropTypes.any,
  hasNextPage: PropTypes.bool,
  onNextPage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default ShowMore;
