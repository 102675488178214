import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import debounce from "lodash.debounce";
import { useList } from "hooks";
import { formatDate } from "utils";
import { LINKS, LIST_TYPES_HUMANIZED, TAB_OPTIONS } from "constants/index";
import { DataTableProvider } from "components/DataTable";
import { PAGINATION_TYPE } from "components/Pagination";
import { UserPhotoList } from "components/User";
import { EditListModal, ListTitle } from "components/Lists";
import { Grid } from "components/Containers";
import { ICON_POSITION, ICON_SIZE, ICON_TYPE, withIcon } from "components/Icon";
import { TextInput } from "components/Form";
import { GET_LIST } from "graphql/queries";
import Tabs from "components/Tabs";

/**
 * ListsList
 *
 * @param {String}    resultsPerPage
 */
const ListsList = ({ resultsPerPage }) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedList, setSelectedList] = useState(false);
  const [keyword, setKeyword] = useState();
  const [debounceKeyword, setDebounceKeyword] = useState();
  const debouncedSetKeyword = useMemo(() => debounce((val) => setDebounceKeyword(val), 500), []);
  const tabOptionKeys = Object.keys(TAB_OPTIONS);
  const isSelectedTabArchived = (selectedTab) => tabOptionKeys[selectedTab] === TAB_OPTIONS.archived.toLowerCase();

  const variables = {
    filters: {
      isVisible: true,
      search: debounceKeyword,
      isArchived: isSelectedTabArchived(selectedTab),
    },
    includeProfiles: true,
    includeProfileIds: false,
    includeBadgesFields: false,
    includeProfileBasicFields: true,
    includeProfileAvailabilityFields: false,
    includeDisciplineFields: false,
    includeSkillsFields: false,
    includeLocationFields: false,
    includeRateFields: false,
    includeWorkExperiencesFields: false,
    includeUser: true,
  };

  const { archiveList } = useList({ variables, skipListQuery: true });

  const handleSearchInputChange = (val) => {
    setKeyword(val);
    debouncedSetKeyword(val);
  };

  const rowOptions = (row) => {
    if (isSelectedTabArchived(selectedTab)) return [];

    return [
      {
        label: <FormattedMessage id="Global.Edit" />,
        icon: ICON_TYPE.edit,
        onClick: () => setSelectedList(row),
      },
      {
        label: <FormattedMessage id="Lists.Archive" />,
        icon: ICON_TYPE.trash,
        onClick: () => archiveList(row),
      },
    ];
  };

  return (
    <>
      <Grid>
        <Grid.col start={1} end={7}>
          <Tabs
            data={Object.keys(TAB_OPTIONS).map((key) => TAB_OPTIONS[key])}
            selected={selectedTab}
            onSelect={setSelectedTab}
            withUrlParams
          />
        </Grid.col>
        <Grid.col start={7} end={13}>
          <SearchInput
            value={keyword}
            onChange={(name, val) => handleSearchInputChange(val)}
            type="search"
            placeholder="Search by title..."
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <DataTableProvider
            keyName={"list"}
            queryName={GET_LIST}
            variables={variables}
            resultsPerPage={resultsPerPage}
            columns={COLUMN_DATA(navigate)}
            paginationType={PAGINATION_TYPE.classic}
            selectable={false}
            rowOptions={rowOptions}
            paginationProps={{
              countMessage: "Global.Lists.Count",
            }}
          />
        </Grid.col>
      </Grid>
      {selectedList && (
        <EditListModal
          show={selectedList}
          onClose={() => setSelectedList(false)}
          isEditing={true}
          data={selectedList}
        />
      )}
    </>
  );
};

const SearchInput = withIcon(TextInput, {
  type: ICON_TYPE.search,
  size: ICON_SIZE.medium,
  position: ICON_POSITION.overlay,
  order: 0,
});

const COLUMN_DATA = (navigate) => [
  {
    label: <FormattedMessage id="Lists.ListsTable.ColumnName" />,
    cell: (row) => <ListTitle list={row} onClick={() => navigate(LINKS.list(row?.slug))} />,
    width: "30%",
  },
  {
    label: <FormattedMessage id="Lists.ListsTable.ColumnType" />,
    cell: (row) => LIST_TYPES_HUMANIZED[row?.type],
  },
  {
    label: <FormattedMessage id="Lists.ListsTable.ColumnCreatedOn" />,
    cell: (row) => formatDate(row?.createdAt),
  },
  {
    label: <FormattedMessage id="Lists.ListsTable.ColumnAssignedTo" />,
    cell: (row) => row?.user?.name,
  },
  {
    label: <FormattedMessage id="Lists.ListsTable.ColumnMembers" />,
    cell: (row) => (
      <UserPhotoList
        key={row?.id}
        users={row?.profiles}
        thumbCount={row?.profiles?.length}
        thumbLimit={5}
        thumbSize="4rem"
        thumbBorderRadius="100%"
      />
    ),
  },
];

ListsList.defaultProps = {
  resultsPerPage: 30,
};

ListsList.propTypes = {
  resultsPerPage: PropTypes.number,
};

export default ListsList;
