import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import styled from "@emotion/styled";
import { colors } from "style";
import { TextInput } from "components/Form";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";

/**
 * CopyToClipboardInput
 *
 * @params {string}   text
 * @params {node}     title
 * @params {number}   delay
 */
const CopyToClipboardInput = ({ text, title, delay }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const copyLinkTarget = useRef(null);

  const copyToClipBoard = () => {
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(text);
    setShowTooltip(!showTooltip);

    setTimeout(() => {
      setShowTooltip(false);
    }, delay);
  };

  return (
    <InputAddOn>
      <TextInput type="text" value={text} disabled={true} />
      <CopyButton ref={copyLinkTarget} onClick={() => copyToClipBoard()}>
        <Icon type={ICON_TYPE.copy} size={ICON_SIZE.small} color={colors.grayAnatomyLight1} />
      </CopyButton>
      <Overlay target={copyLinkTarget.current} placement="top" show={showTooltip}>
        <Tooltip id="button-tooltip">{title}</Tooltip>
      </Overlay>
    </InputAddOn>
  );
};

const CopyButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 4rem;
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  background-color: ${colors.grayAnatomyLight4};
  cursor: pointer;
  border: 1px solid ${colors.grayAnatomyLight3};
  border-left-width: 0;

  &:hover {
    background-color: ${colors.grayAnatomyLight3};
  }
`;

const InputAddOn = styled.div`
  display: flex;
  flex-direction: row-reverse;
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

CopyToClipboardInput.defaultProps = {
  title: <FormattedMessage id="Global.Copied" />,
  delay: 2000,
};

CopyToClipboardInput.propTypes = {
  text: PropTypes.string,
  title: PropTypes.node,
  delay: PropTypes.number,
};

export default CopyToClipboardInput;
