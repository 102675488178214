import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import Image from "components/Image";
import { ENGINEERING_LEVELS } from "constants";
import EngineeringLevel2 from "assets/images/engineering_level_2.svg";
import EngineeringLevel3 from "assets/images/engineering_level_3.svg";
import EngineeringLevel4 from "assets/images/engineering_level_4.svg";
import EngineeringLevel5 from "assets/images/engineering_level_5.svg";
import EngineeringLevel6 from "assets/images/engineering_level_6.svg";

/**
 * EngineeringLevelIcon
 *
 * @param {String}   uuid
 */
const EngineeringLevelIcon = ({ uuid, ...props }) => {
  const engineeringLevel = ENGINEERING_LEVELS.findIndex((level) => level.id === uuid);
  const iconLevels = [EngineeringLevel2, EngineeringLevel3, EngineeringLevel4, EngineeringLevel5, EngineeringLevel6];

  if (!engineeringLevel && engineeringLevel !== 0) return null;

  return (
    <div css={styles.container} {...props}>
      <Image url={iconLevels[engineeringLevel]} width="100%" height="100%" />
    </div>
  );
};

const styles = {
  container: css`
    width: 4rem;
    height: 4rem;
  `,
};

EngineeringLevelIcon.propTypes = {
  uuid: PropTypes.string,
};

export default EngineeringLevelIcon;
