/** @jsx jsx */
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "style";
 
const fade = keyframes`
  0% {
    opacity:0.5;
  }
  50% {
    opacity:1;
  }
  100% {
    opacity:0.5;
  }
`;

/**
 * Shows a text loader
 */
const TextLoader = styled.div`
  width: ${(props) => props.width || `100%`};
  height: 1rem;
  display: inline-block;
  background: ${(props) => props.color || colors.grayAnatomyLight5};
  animation: ${fade} 1s ease infinite;
  border-radius: 5rem;
  max-width: 100%;
`;

export default TextLoader;
