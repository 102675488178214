import React from "react";
import PropTypes from "prop-types";
import { colors } from "style";
import Tooltip from "components/Tooltip";

/**
 * MissionAccomplished14
 *
 * @param {String}   fill
 */
const MissionAccomplished14 = ({ fill, ...props }) => (
  <Tooltip tooltipId={"Badges.MissionAccomplished10"} hideIcon>
    <svg viewBox="0 0 145 150" fill="none" {...props}>
      <path
        d="M86 10.3923L127.818 34.5359C134.006 38.1086 137.818 44.7111 137.818 51.8564V100.144C137.818 107.289 134.006 113.891 127.818 117.464L86 141.608C79.812 145.18 72.188 145.18 66 141.608L24.1821 117.464C17.994 113.891 14.1821 107.289 14.1821 100.144V51.8564C14.1821 44.7111 17.994 38.1086 24.1821 34.5359L66 10.3923C72.188 6.81965 79.812 6.81965 86 10.3923Z"
        stroke={fill || colors.pinkPantherBase}
        strokeWidth="8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.1944 37H92.8056C96.9002 37 100.238 40.3637 100.083 44.4371C100.052 45.2703 100.021 46.0727 99.975 46.875H116.292C118.347 46.875 120 48.526 120 50.5781C120 64.866 114.824 74.8027 107.886 81.5455C101.041 88.1957 92.6974 91.5439 86.5323 93.2412C82.9012 94.2287 80.4444 97.2529 80.4444 100.277C80.4444 103.502 83.0712 106.125 86.3005 106.125H90.3333C93.0682 106.125 95.2778 108.331 95.2778 111.062C95.2778 113.794 93.0682 116 90.3333 116H60.6667C57.9318 116 55.7222 113.794 55.7222 111.062C55.7222 108.331 57.9318 106.125 60.6667 106.125H64.6995C67.9288 106.125 70.5556 103.502 70.5556 100.277C70.5556 97.2529 68.0833 94.2441 64.4677 93.2412C58.3181 91.5439 49.9743 88.1957 43.1293 81.5455C36.1762 74.8027 31 64.866 31 50.5781C31 48.526 32.6533 46.875 34.7083 46.875H51.025C50.9786 46.0727 50.9477 45.2549 50.9168 44.4371C50.7623 40.3637 54.0998 37 58.1944 37ZM51.5967 54.2812H38.5712C39.3438 64.5111 43.3611 71.4391 48.3056 76.2377C51.7667 79.6014 55.7686 81.993 59.616 83.6902C56.1085 77.4721 53.0028 68.1834 51.5967 54.2812ZM91.3995 83.6902C95.2469 81.993 99.2488 79.6014 102.71 76.2377H102.725C107.654 71.4391 111.672 64.5111 112.46 54.2812H99.4188C98.0127 68.1834 94.9069 77.4721 91.3995 83.6902ZM65.6436 80H70.7295V50.1816H65.5615L61.8496 52.2734V57.7695L65.6436 55.9854V80ZM80.1426 50.2021L73.9902 70.71V75.4268H83.3418V80.0205H88.2637V75.4268H89.9248V70.4639H88.2637V63.5732H83.3418V70.4639H79.5889L85.4746 50.2021H80.1426Z"
        fill={fill || colors.pinkPantherBase}
      />
    </svg>
  </Tooltip>
);

MissionAccomplished14.propTypes = {
  fill: PropTypes.string,
};

export default MissionAccomplished14;
