import React from "react";
import PropTypes from "prop-types";
import { Row } from "components/Containers";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import Code from "components/Code";
import isObject from "utils/isObject";
import { UserLocation } from "components/User";

/**
 * TimelineEventDataChange
 *
 * @param {Object} payload
 * @param {String} item
 */
const TimelineEventDataChange = ({ payload, item }) => {
  const value = payload[item];
  const isArray = Array.isArray(value);

  return (
    <Row gap="0.5rem">
      <Code>{item}</Code>
      {isArray ? (
        <>
          <span>{handleValueType(item, value[0])}</span>
          <Icon type={ICON_TYPE.arrowRight} size={ICON_SIZE} />
          <span>
            <b>{handleValueType(item, value[1])}</b>
          </span>
        </>
      ) : (
        <span>{value}</span>
      )}
    </Row>
  );
};

const handleValueType = (key, value) => {
  if (isObject(value)) {
    switch (key) {
      case "locationObject":
        return <UserLocation data={{ location: value }} hideFlag={true} />;
      default:
        return JSON.stringify(value, null, 2);
    }
  } else {
    return `${value}`;
  }
};

TimelineEventDataChange.propTypes = {
  payload: PropTypes.object.isRequired,
  item: PropTypes.string,
};

export default TimelineEventDataChange;
