import React, { useRef } from "react";
import { css } from "@emotion/react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Form, { SIZE, TYPE } from "components/Form";
import { useTeam } from "hooks";

/**
 * Engineering Metrics Form
 */
const EngineeringMetricsForm = () => {
  const { id } = useParams();
  const { loading, data, updateTeam } = useTeam({ id });
  const formData = useRef({});

  if (loading) return null;

  const initialValues = {
    ...data?.teamProfilesAccounts?.reduce((a, c) => {
      a[c?.profile?.id] = c?.accountNames;
      return a;
    }, {}),
  };

  const formProps = {
    initialValues,
    numColumns: 4,
  };

  const handleUpdateTeam = () => {
    const { ...fields } = formData.current;
    const teamProfileAccounts = [];
    for (let key in fields) {
      if (fields[key]) {
        const teamProfileAccount = { profileId: key, accountNames: fields[key] };
        const id = data?.teamProfilesAccounts.find((item) => item.profile.id == key)?.id;
        if (id) teamProfileAccount.id = id;
        teamProfileAccounts.push(teamProfileAccount);
      }
    }
    updateTeam(id, { teamProfileAccounts });
  };

  const handleChange = (values) => {
    formData.current = { ...values };
  };

  return <Form data={FORM_DATA(data, handleUpdateTeam)} onChange={(values) => handleChange(values)} {...formProps} />;
};

const FORM_DATA = (data, handleUpdateTeam) => [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: <FormattedMessage id="Squads.Squad.Settings.EngineeringMetrics.Form.ProfileAssociations" />,
        type: TYPE.h4,
        size: SIZE.xlarge,
      },
      ...data?.teamProfilesAccounts?.map((item) => ({
        label: item.profile.name,
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: item.profile.id,
          type: "text",
          maxLength: 100,
        },
      })),
      {
        type: TYPE.button,
        size: SIZE.xlarge,
        properties: {
          name: "submit",
          type: "submit",
          onClick: () => handleUpdateTeam(),
          children: <span>Update</span>,
        },
      },
    ],
  },
];

export default EngineeringMetricsForm;
