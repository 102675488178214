import React from "react";
import PropTypes from "prop-types";
import App from "App";
import { useParams } from "react-router-dom";
import { NarrowContainer } from "components/Containers";
import { ProfileBox } from "components/Profile";
import { HeaderPublicGradient } from "components/Header";
import { themes } from "style";

/**
 * Profile Page
 */
const PublicProfile = () => {
  const { id } = useParams();

  return (
    <App theme={themes.website}>
      <HeaderPublicGradient>
        <NarrowContainer>
          <ProfileBox id={id} isEditable={false} isPublicProfile={true} keyName="publicProfile" />
        </NarrowContainer>
      </HeaderPublicGradient>
    </App>
  );
};

PublicProfile.propTypes = {
  id: PropTypes.number,
};

export default PublicProfile;
