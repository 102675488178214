import React from "react";
import propTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { LINKS } from "constants/index";
import { PrimaryLink } from "components/Links";

/**
 * GithubSettingsLink
 * @param {String} installationId
 * @param {String} githubOrganization
 * @param {String} messageId
 */
const GithubSettingsLink = ({ installationId, githubOrganization, messageId }) => {
  return (
    <PrimaryLink
      target="_blank"
      css={css`
        margin-left: auto;
      `}
      to={LINKS.mission_github_app_settings(githubOrganization, installationId)}
      withMoreIcon
    >
      <FormattedMessage id={messageId} />
    </PrimaryLink>
  );
};

GithubSettingsLink.propTypes = {
  installationId: propTypes.string.isRequired,
  githubOrganization: propTypes.string.isRequired,
  messageId: propTypes.string.isRequired,
};

export default GithubSettingsLink;
