import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "components/Buttons";
import MissionInviteForm from 'components/Missions/MissionInviteForm'
import PropTypes from "prop-types";
import { PAGES } from "constants/index";

/**
 * Invite member CTA
 *
 * 
 */
const InviteMember = ({ ...props }) => {
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      <PrimaryButton onClick={() => setShowForm(true)} {...props}>
        <FormattedMessage id={`Missions.MissionInviteMember.CTA`} />
      </PrimaryButton>
      <MissionInviteForm show={showForm} onClose={setShowForm} page={PAGES.missionInviteMember}/>
    </>
  );
};

export default InviteMember;
