import { gql } from "@apollo/client";

const GET_TEAM_SHOWCASE_SCORE = gql`
  query teamShowcaseScoreFetch($teamId: ID!) {
    teamShowcaseScoreFetch(teamId: $teamId) {
      week
      weekDate
      score
      scoreDelta
      scoreDeltaPercent
      categoryScores {
        category
        score
        scoreDelta
        scoreDeltaPercent
        sparkline
      }
      team {
        id
        name
      }
      organization {
        id
        name
      }
      sparkline
    }
  }
`;

export { GET_TEAM_SHOWCASE_SCORE };
