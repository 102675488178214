import React from "react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useQueryData, useToast } from "hooks";
import { GET_CHALLENGES } from "graphql/queries";
import { UPDATE_CHALLENGE } from "graphql/mutations";
import { PAGINATION_TYPE } from "components/Pagination";
import { CODE_CHALLENGES_RESULTS_PER_PAGE } from "constants/index";

/**
 * useCodeChallenges
 *
 * @param {Number}   resultsPerPage
 * @param {Boolean}  skip
 * @param {Object}   filters
 * @param {String}   profileId
 */
export default function useCodeChallenges({
  resultsPerPage = CODE_CHALLENGES_RESULTS_PER_PAGE,
  paginationType = "classic",
  skip = false,
  filters,
  profileId,
}) {
  const { toast } = useToast();
  const keyName = "challenges";
  const [updateCodeChallenge, { loading: updating }] = useMutation(UPDATE_CHALLENGE, {
    refetchQueries: [GET_CHALLENGES],
  });

  const { hasNextPage, handleNextPage, hasPreviousPage, handlePreviousPage, loading, loadingMore, data, refetch } =
    useQueryData({
      queryName: GET_CHALLENGES,
      keyName,
      resultsPerPage,
      paginationType: PAGINATION_TYPE[paginationType],
      variables: {
        profileId,
        filters,
      },
      skip,
    });

  const handleUpdateCodeChallenge = (id, params) => {
    updateCodeChallenge({
      variables: {
        input: {
          id,
          params,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id={"useCodeChallenges.UpdateSuccess"} />),
      onError: () => toast.error(<FormattedMessage id={"useCodeChallenges.UpdateError"} />),
    });
  };

  return {
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    handleUpdateCodeChallenge,
    refetch,
    loading: loading || loadingMore,
    updating,
    paginationType,
    data: data?.challenges?.nodes,
    keyName,
    resultsPerPage: data?.challenges?.perPage || resultsPerPage,
  };
}
