const USER_TYPES = {
  admin: "ADMIN",
  freelancer: "FREELANCER",
  recruiter: "RECRUITER",
  partner: "PARTNER",
  showcase: "SHOWCASE",
  client: "CLIENT",
  signal: "SIGNAL",
};

const USER_TRANSITIONS = {
  showcaseToClient: "showcaseToClient",
};

export { USER_TRANSITIONS };

export default USER_TYPES;
