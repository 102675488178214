import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "components/Buttons";
import { ListShareModal } from "components/Lists";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * ShowcaseInviteButton
 *
 * @param {Object} data
 */
const ListShareButton = ({ data, ...props }) => {
  const [showShareModal, setShowShareModal] = useState(false);

  if (!data) {
    return null;
  }

  return (
    <>
      <PrimaryButton variant="green" {...props} onClick={() => setShowShareModal(true)}>
        <FormattedMessage id="List.ShareListButton.Text" />
        <Icon type={ICON_TYPE.share} size={ICON_SIZE.small} color="inherit" />
      </PrimaryButton>
      {showShareModal && <ListShareModal show={showShareModal} onClose={() => setShowShareModal(false)} data={data} />}
    </>
  );
};

ListShareButton.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ListShareButton;
