import { formatNameForChart, removeTags, sortByDate } from "utils";
import {
  GET_ACTIVE_DAYS_FOR_TEAM,
  GET_AVERAGE_WEEKLY_PR_MERGE_TIME_FOR_TEAM,
  GET_AVERAGE_WEEKLY_PR_SIZE_FOR_TEAM,
  GET_COMMITS_BREAKDOWN_FOR_TEAM,
  GET_COMMITS_FOR_TEAM,
  GET_COMMITS_PER_ACTIVE_DAY_FOR_TEAM,
  GET_CUMULATIVE_COMMENTS_FOR_TEAM,
  GET_CUMULATIVE_LOCS_FOR_TEAM,
  GET_CUMULATIVE_PULL_REQUEST_STATUSES_FOR_TEAM,
  GET_LARGEST_PULL_REQUESTS_FOR_TEAM,
  GET_OLDEST_OPEN_PULL_REQUESTS_FOR_TEAM,
  GET_PULL_REQUEST_MERGE_TIME_FOR_TEAM,
  GET_PULL_REQUEST_SIZE_BREAKDOWN_FOR_TEAM,
  GET_PULL_REQUEST_STATUS_BREAKDOWN_FOR_TEAM,
  GET_PULL_REQUESTS_MERGED_FOR_TEAM,
} from "graphql/queries";

/**
 * useSquadActivity
 */
export default function useSquadActivity() {
  const settings = {
    activeDays: {
      title: "useSquadActivity.TitleActiveDays",
      seriesField: "activeDays",
      seriesName: "Active Days",
      keyName: "activeDaysForTeam",
      queryName: GET_ACTIVE_DAYS_FOR_TEAM,
      formatData: (data, filterFunc) => ({
        data: data?.filter(filterFunc).map(({ profile, label, value }) => ({
          name: formatNameForChart(profile, label),
          tooltip: `${formatNameForChart(profile, label)}: `,
          activeDays: value,
          label: `${value} day(s)`,
        })),
      }),
    },
    codingCommits: {
      title: "useSquadActivity.TitleCommitsPerCodingDays",
      seriesField: "commits",
      seriesName: "Commits per Coding Days",
      keyName: "commitsPerActiveDayForTeam",
      queryName: GET_COMMITS_PER_ACTIVE_DAY_FOR_TEAM,
      formatData: (data, filterFunc) => ({
        data: data?.filter(filterFunc).map(({ profile, label, value }) => ({
          name: formatNameForChart(profile, label),
          tooltip: `${formatNameForChart(profile, label)}: `,
          commits: value,
          label: `${value} commit(s) per day`,
        })),
      }),
    },
    aggregateComments: {
      title: "useSquadActivity.TitleAggregateComments",
      keyName: "cumulativeCommentsForTeam",
      queryName: GET_CUMULATIVE_COMMENTS_FOR_TEAM,
      formatData: (data, filterFunc) => ({
        data: sortByDate(data?.data, "bucket", "asc"),
        series: data?.metadata
          .filter(filterFunc)
          .map(({ handle, profile }) => ({ name: formatNameForChart(profile, handle), fieldName: handle })),
      }),
    },
    totalCommits: {
      title: "useSquadActivity.TitleTotalCommits",
      seriesField: "totalCommits",
      seriesName: "Total Commits",
      keyName: "commitsForTeam",
      queryName: GET_COMMITS_FOR_TEAM,
      formatData: (data, filterFunc) => ({
        data: data?.filter(filterFunc).map(({ profile, label, value }) => ({
          name: formatNameForChart(profile, label),
          tooltip: `${formatNameForChart(profile, label)}: `,
          totalCommits: value,
          label: `${value} commit(s)`,
        })),
      }),
    },
    pullRequestMerged: {
      title: "useSquadActivity.TitlePullRequestsMerged",
      seriesField: "pullRequestsMerged",
      seriesName: "Top 10 Authors (Most Merged)",
      keyName: "pullRequestsMergedForTeam",
      queryName: GET_PULL_REQUESTS_MERGED_FOR_TEAM,
      formatData: (data, filterFunc) => ({
        data:
          data &&
          [...data]
            ?.sort((a, b) => b.value - a.value)
            .filter(filterFunc)
            .map(({ profile, label, value }) => ({
              name: formatNameForChart(profile, label),
              tooltip: `${formatNameForChart(profile, label)}: `,
              pullRequestsMerged: value,
              label: `${value} pull request(s)`,
            })),
      }),
    },
    cumulativeLoc: {
      title: "useSquadActivity.TitleAggregateLOC",
      keyName: "cumulativeLocsForTeam",
      queryName: GET_CUMULATIVE_LOCS_FOR_TEAM,
      formatData: (data, filterFunc) => ({
        data: sortByDate(data?.data, "bucket", "asc"),
        series: data?.metadata.filter(filterFunc).map(({ handle, profile }) => ({
          name: formatNameForChart(profile, handle),
          fieldName: handle,
        })),
      }),
    },
    commitsBreakdown: {
      title: "useSquadActivity.TitleCommitsBreakdown",
      keyName: "commitsBreakdownForTeamMembers",
      queryName: GET_COMMITS_BREAKDOWN_FOR_TEAM,
      formatData: (data, filterFunc) => ({
        data: sortByDate(data?.data, "bucket", "asc"),
        series: data?.metadata.filter(filterFunc).map(({ handle, profile }) => ({
          name: formatNameForChart(profile, handle),
          fieldName: removeTags(handle),
          stacked: true,
        })),
      }),
    },
    oldestOpenPrs: {
      title: "useSquadActivity.TitleOldestOpenPRs",
      keyName: "oldestOpenPullRequestsForTeam",
      seriesField: "openedSinceDays",
      seriesName: "Top 10 Oldest Open PRs",
      queryName: GET_OLDEST_OPEN_PULL_REQUESTS_FOR_TEAM,
      formatData: (data, filterFunc) => ({
        data: data?.filter(filterFunc).map((item) => ({
          tooltip: `#${removeTags(item?.label)}`,
          name: removeTags(item?.label),
          openedSinceDays: item?.value,
          label: `Open for ${item?.value} day(s)`,
        })),
      }),
    },
    largestPullRequests: {
      title: "useSquadActivity.TitleLargestPRs",
      seriesField: "loc",
      seriesName: "Top 10 Largest PRs (>500 LOC)",
      keyName: "largestPullRequestsForTeam",
      queryName: GET_LARGEST_PULL_REQUESTS_FOR_TEAM,
      formatData: (data, filterFunc) => ({
        data: data?.filter(filterFunc).map((item) => ({
          name: removeTags(item?.label),
          tooltip: `${item?.label}: `,
          loc: item?.value,
          label: `${item?.value} LOC(s)`,
        })),
      }),
    },
    pullRequestStatus: {
      title: "useSquadActivity.TitlePrBreakdown",
      keyName: "pullRequestStatusBreakdownForTeam",
      queryName: GET_PULL_REQUEST_STATUS_BREAKDOWN_FOR_TEAM,
      formatData: (data) => ({
        data: sortByDate(data?.data, "bucket", "asc"),
        series: [
          {
            name: "Merged",
            fieldName: "Merged",
            stacked: false,
          },
          {
            name: "Open",
            fieldName: "Open",
            stacked: true,
          },
          {
            name: "Closed",
            fieldName: "Closed",
            stacked: true,
          },
        ],
      }),
    },
    openVsMerged: {
      title: "useSquadActivity.TitleOpenvsMerged",
      keyName: "cumulativePullRequestStatusesForTeam",
      queryName: GET_CUMULATIVE_PULL_REQUEST_STATUSES_FOR_TEAM,
      formatData: (data) => ({
        data: sortByDate(data?.data, "bucket", "asc"),
        series: [
          {
            name: "Opened",
            fieldName: "Opened",
          },
          {
            name: "Merged",
            fieldName: "Merged",
          },
        ],
      }),
    },
    pullRequestMergeTime: {
      title: "useSquadActivity.TitlePrMergeTime",
      keyName: "pullRequestMergeTimeForTeam",
      queryName: GET_PULL_REQUEST_MERGE_TIME_FOR_TEAM,
      formatData: (data) => ({
        data: sortByDate(data?.data, "bucket", "asc"),
        series: [
          {
            name: "Under 1 day",
            fieldName: "Under 1 day",
            stacked: true,
          },
          {
            name: "Under 3 days",
            fieldName: "Under 3 days",
            stacked: true,
          },
          {
            name: "Under 7 days",
            fieldName: "Under 7 days",
            stacked: true,
          },
          {
            name: "Under 14 days",
            fieldName: "Under 14 days",
            stacked: true,
          },
          {
            name: "Under 21 days",
            fieldName: "Under 21 days",
            stacked: true,
          },
          {
            name: "Over 21 days",
            fieldName: "Over 21 days",
            stacked: true,
          },
        ],
      }),
    },
    averageMergeTime: {
      title: "useSquadActivity.TitleAverageMergeTime",
      keyName: "averageWeeklyPrMergeTimeForTeam",
      queryName: GET_AVERAGE_WEEKLY_PR_MERGE_TIME_FOR_TEAM,
      formatData: (data) => ({
        data: sortByDate(data?.data, "bucket", "asc")?.map((d) => ({
          ...d,
          bucket: `Week ${parseInt(String(d.bucket)?.split("-")[1])} (${String(d.bucket)?.split("-")[0]})`,
        })),
        series: [
          {
            name: "Hours",
            fieldName: "Hours",
          },
        ],
      }),
    },
    pullRequestSizeBreakdown: {
      title: "useSquadActivity.TitlePrSizeBreakdown",
      keyName: "pullRequestSizeBreakdownForTeam",
      queryName: GET_PULL_REQUEST_SIZE_BREAKDOWN_FOR_TEAM,
      formatData: (data) => ({
        data: sortByDate(data?.data, "bucket", "asc"),
        series: [
          {
            name: "Under 50 LOCs",
            fieldName: "Under 50 LOCs",
            stacked: true,
          },
          {
            name: "Under 100 LOCs",
            fieldName: "Under 100 LOCs",
            stacked: true,
          },
          {
            name: "Under 250 LOCs",
            fieldName: "Under 250 LOCs",
            stacked: true,
          },
          {
            name: "Under 500 LOCs",
            fieldName: "Under 500 LOCs",
            stacked: true,
          },
          {
            name: "Under 1000 LOCs",
            fieldName: "Under 1000 LOCs",
            stacked: true,
          },
          {
            name: "Over 1000 LOCs",
            fieldName: "Over 1000 LOCs",
            stacked: true,
          },
        ],
      }),
    },
    locModified: {
      title: "useSquadActivity.TitleLOCsModified",
      keyName: "averageWeeklyPrSizeForTeam",
      queryName: GET_AVERAGE_WEEKLY_PR_SIZE_FOR_TEAM,
      formatData: (data) => ({
        data: sortByDate(data?.data, "bucket", "asc")?.map((d) => ({
          ...d,
          bucket: `Week ${parseInt(String(d.bucket)?.split("-")[1])} (${String(d.bucket)?.split("-")[0]})`,
        })),
        series: [
          {
            name: "LOCs Modified",
            fieldName: "LOCs Modified",
          },
        ],
      }),
    },
  };

  return { settings };
}
