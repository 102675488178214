import { gql } from "@apollo/client";

const teamScoreSummaryFields = `
  week
  weekDate
  score
  scoreDelta
  scoreDeltaPercent
  sparkline
`;

const TEAM_SCORE_SUMMARY_FIELDS = gql`
  fragment TeamScoreSummaryFields on EngineeringMetricsScore {
    ${teamScoreSummaryFields}
  }
`;

export { TEAM_SCORE_SUMMARY_FIELDS };
