import { GET_TEAM_SCORE } from "graphql/queries";
import { useQueryData } from "hooks";

export default function useTeamScores(teamId) {
  const { loading, data, error } = useQueryData({
    queryName: GET_TEAM_SCORE,
    keyName: "teams",
    variables: {
      teamId
    },
  });

  return {
    loading,
    data: data?.teamScoreFetch,
    error
  };
}
