import React, { Suspense, lazy } from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { usePulse } from "hooks";
import { colors } from "style";
import DataTable from "components/DataTable";
import { Grid } from "components/Containers";
import Card from "components/Card";
import { getMonthsBetween } from "utils";
import { FeedbackCell } from "components/Squads/Pulse";

// TODO - Using conventional import like above causes an error and the app to crash.
// Lazy loading the ProfileLink component fixes the issue. Investigate why this is happening. - Asad
const ProfileLink = lazy(() => import("components/Profile/ProfileLink"));

/**
 * PulseTable
 *
 * @param {String}    teamId
 * @param {Array}     dateRange
 */
const PulseTable = ({ teamId, dateRange, ...props }) => {
  const [startDate, endDate] = dateRange;
  const { data, loading, error, questionText } = usePulse({
    teamId,
    startDate,
    endDate,
  });
  const months = getMonthsBetween(startDate, endDate);

  return (
    <Card>
      <Grid>
        <Grid.col start={1} end={13}>
          <DataTable
            css={styles.table}
            data={data}
            error={error}
            loading={loading}
            columns={COLUMN_DATA(months)}
            {...props}
          />
          {questionText && (
            <div css={styles.disclaimer}>
              <FormattedMessage id="PulseTable.Disclaimer.Text" values={{ question: questionText }} />
            </div>
          )}
        </Grid.col>
      </Grid>
    </Card>
  );
};

const COLUMN_DATA = (months) => [
  {
    label: <FormattedMessage id="PulseTable.Header.MemberName" />,
    cell: ({ profile }) => (
      <Suspense fallback={<div />}>
        <ProfileLink profile={profile} withOverlay />
      </Suspense>
    ),
    width: "12%",
  },
  ...months.map((month, i) => ({
    label: (
      <div css={styles.headerDynamicCell}>
        <FormattedMessage id="PulseTable.Header.Placeholder" values={{ placeholder: month }} />
      </div>
    ),
    cell: (data) => <div css={styles.headerDynamicCell}>{data[month] ? <FeedbackCell data={data[month]} /> : "-"}</div>,
    // For the last month of pulse table have some more width than others because of the right padding
    ...(i == 11 && { width: "10%" }),
  })),
];

const styles = {
  table: css`
    td,
    td:last-of-type {
      border: 0;
      padding: 0rem;
      height: 4rem;
    }
  `,
  disclaimer: css`
    font-style: italic;
    margin-top: 2rem;
    color: ${colors.grayAnatomyBase};
  `,
  headerDynamicCell: css`
    text-align: center;
  `,
};

PulseTable.propTypes = {
  teamId: PropTypes.string,
  dateRange: PropTypes.array,
};

export default PulseTable;
