import { gql } from "@apollo/client";
import { ASSESSMENT_FIELDS } from "graphql/fragments";

const UPDATE_MISSION_REQUIREMENTS = gql`
  ${ASSESSMENT_FIELDS}
  mutation UpdateAssessment($input: UpdateAssessmentInput!) {
    updateAssessment(input: $input) {
      assessment {
        ...AssessmentFields
      }
    }
  }
`;

const ADD_ASSESSMENT_OPTION = gql`
  mutation addAssessmentOption($input: AddAssessmentOptionInput!) {
    addAssessmentOption(input: $input) {
      assessmentOption {
        value
      }
    }
  }
`;

const REMOVE_ASSESSMENT_OPTION = gql`
  mutation removeAssessmentOption($input: RemoveAssessmentOptionInput!) {
    removeAssessmentOption(input: $input) {
      id
    }
  }
`;

export { UPDATE_MISSION_REQUIREMENTS, ADD_ASSESSMENT_OPTION, REMOVE_ASSESSMENT_OPTION };
