import { useRef, useEffect } from "react";

/**
 * Returns a memoized version of the provided value
 *
 */
export default function usePrevious(value) {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
