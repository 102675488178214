import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { Grid, PageWrapper } from "components/Containers";
import PageTitle from "components/PageTitle";
import Card from "components/Card";
import { ProfileSettingForm, ProfileNav } from "components/Profile";
import { useParams } from "react-router-dom";

/**
 * ProfileSettings
 */
const ProfileSettings = () => {
  const intl = useIntl();
  const { id } = useParams();

  return (
    <>
      <ProfileNav id={id} />
      <PageWrapper>
        <Helmet>
          {intl.formatMessage({
            id: "Meta.Profiles.Settings",
          })}
        </Helmet>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle subtitle={<FormattedMessage id="Profile.Setting.Title" />} />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <Card>
              <ProfileSettingForm />
            </Card>
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};
export default ProfileSettings;
