import { gql } from "@apollo/client";

const GET_EVENTS = gql`
  query GetEvents(
    $filters: MissionEventFilter
    $first: Int
    $last: Int
    $before: String
    $after: String
    $paginationInfo: PaginationInfo
  ) {
    missionEvents(
      filters: $filters
      first: $first
      last: $last
      before: $before
      after: $after
      paginationInfo: $paginationInfo
    ) {
      totalCount
      currentPage
      perPage
      nodes {
        id
        name
        title
        description
        url
        recordingLink
        displayImageUrl
        startsAt
        startsAtWithTimezone
        hidden
        fileName
        timezone {
          name
          value
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export { GET_EVENTS };
