import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { fontSize, colors } from "style";

/**
 * Signup Complete Message
 *
 * @params {Object}   formData
 * @params {String}   msgId
 */
const SignupComplete = ({ formData, msgId, ...props }) => (
  <div {...props} css={styles.container}>
    <div css={styles.title}>
      <FormattedMessage id="Signup.SignupComplete.Title" />
    </div>
    <div css={styles.body}>
      <FormattedMessage id={msgId} values={{ name: formData?.firstName }} />
    </div>
  </div>
);

const styles = {
  container: css`
    margin-left: 4rem;
  `,
  title: css`
    font-size: ${fontSize.xxlarge};
    font-weight: 700;
  `,
  body: css`
    margin-top: 1rem;
    font-size: ${fontSize.medium};
    color: ${colors.grayAnatomyLight1};
    line-height: 1.5;
  `,
};

SignupComplete.defaultProps = {
  msgId: "Signup.SignupComplete.Body",
};

SignupComplete.propTypes = {
  formData: PropTypes.object.isRequired,
  msgId: PropTypes.string,
};

export default SignupComplete;
