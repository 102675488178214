import { GET_PARTNERS } from "graphql/queries";

const MOCK_GET_PARTNERS = [
  {
    request: {
      query: GET_PARTNERS,
      variables: {
        orderBy: { name: "asc" },
        first: 50,
        last: null,
        after: null,
        paginationInfo: {
          perPage: 50,
          page: 1,
        },
        filters: {
          status: null,
          name: undefined,
        },
      },
    },
    result: {
      data: {
        partners: {
          nodes: [
            {
              id: "58",
              name: "Andrew",
              formattedName: "Andrew",
              status: "ACTIVE",
              website: "https://www.dsfsdf.com",
              slug: "123uysdfhwer",
              channel: "external_recruiter",
              trusted: true,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "60",
              name: "MyPartner",
              formattedName: "MyPartner",
              status: "ACTIVE",
              website: "https://www.thing.com",
              slug: "erkjsdf7sdf",
              channel: "external_recruiter",
              trusted: true,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "70",
              name: "Southville Solutions",
              formattedName: "Southville Solutions",
              status: "ACTIVE",
              website: "https://southvillesol.com",
              slug: "southville_solutions",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "71",
              name: "10 Hour Labs",
              formattedName: "10 Hour Labs",
              status: "ACTIVE",
              website: "https://10hourlabs.com",
              slug: "10_hour_labs",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "72",
              name: "Exo Mindset",
              formattedName: "Exo Mindset",
              status: "ACTIVE",
              website: "https://exomindset.co",
              slug: "exo_mindset",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "75",
              name: "sdflksdfk",
              formattedName: "sdflksdfk",
              status: "ACTIVE",
              website: "https://www.google.com",
              slug: "sdfsdfkj",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "79",
              name: "NewPartner",
              formattedName: "NewPartner",
              status: "ACTIVE",
              website: "https://www.google.com",
              slug: "new_partner",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "80",
              name: "NewPartner",
              formattedName: "NewPartner",
              status: "ACTIVE",
              website: "https://www.google.com",
              slug: "new_partnersdkjf3244",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "81",
              name: "dslfksdlfkdslfk",
              formattedName: "dslfksdlfkdslfk",
              status: "ACTIVE",
              website: "https://123.com",
              slug: "dsjfhsdjfhds734",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "82",
              name: "jdhsfjsdfh",
              formattedName: "jdhsfjsdfh",
              status: "ACTIVE",
              website: "https://www.123.com",
              slug: "sdkjfd7234jasf",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "83",
              name: "AndrewCompany",
              formattedName: "AndrewCompany",
              status: "ACTIVE",
              website: "https://www.andrewcompany.com",
              slug: "sdfkjsdf87324jsdf",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "84",
              name: "ertlekrtekrtq",
              formattedName: "ertlekrtekrtq",
              status: "ACTIVE",
              website: "https://www.google.com",
              slug: "sdfkjsdf7234",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "85",
              name: "MyPartner",
              formattedName: "MyPartner",
              status: "ACTIVE",
              website: "https://www.google.com",
              slug: "sdjasd8723",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "86",
              name: "HelloWorld",
              formattedName: "HelloWorld",
              status: "ACTIVE",
              website: "https://www.HelloWorld.com",
              slug: "hello_world",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "87",
              name: "HelloWorld2",
              formattedName: "HelloWorld2",
              status: "ACTIVE",
              website: "https://www.hellowworld2.com",
              slug: "hellow_wroldsad",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "88",
              name: "helloworld3",
              formattedName: "helloworld3",
              status: "ACTIVE",
              website: "https://123.com",
              slug: "123123sdfsdf34",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "89",
              name: "helloworld4",
              formattedName: "helloworld4",
              status: "ACTIVE",
              website: "https://www.9845hf.com",
              slug: "kjdfifsdfj",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "90",
              name: "hellowwolrd5",
              formattedName: "hellowwolrd5",
              status: "ACTIVE",
              website: "https://www.dsfsdfds.com",
              slug: "kjsdfs87hj3",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "91",
              name: "hellowworld6",
              formattedName: "hellowworld6",
              status: "ACTIVE",
              website: "https://www.dsfsdf.com",
              slug: "sdkjsd",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "92",
              name: "heellowwolrd7",
              formattedName: "heellowwolrd7",
              status: "ACTIVE",
              website: "https://www.123.com",
              slug: "sdfsdfsdfs",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
          ],
          totalCount: 22,
          currentPage: 1,
          currentOffset: 0,
          pageInfo: {
            endCursor: "MjU",
            hasNextPage: false,
            hasPreviousPage: false,
            startCursor: "MQ",
            __typename: "PageInfo",
          },
          edges: [
            {
              cursor: "Mw",
              node: {
                id: "70",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "NA",
              node: {
                id: "71",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "NQ",
              node: {
                id: "72",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "Ng",
              node: {
                id: "75",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "Nw",
              node: {
                id: "79",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "OA",
              node: {
                id: "80",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "OQ",
              node: {
                id: "81",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTA",
              node: {
                id: "82",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTE",
              node: {
                id: "83",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTI",
              node: {
                id: "84",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTM",
              node: {
                id: "85",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTQ",
              node: {
                id: "86",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTU",
              node: {
                id: "87",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTY",
              node: {
                id: "88",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTc",
              node: {
                id: "89",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTg",
              node: {
                id: "90",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTk",
              node: {
                id: "91",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MjA",
              node: {
                id: "92",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
          ],
          __typename: "PartnerConnection",
        },
      },
    },
  },
  {
    request: {
      query: GET_PARTNERS,
      variables: {
        orderBy: { name: "asc" },
        first: 50,
        last: null,
        after: null,
        paginationInfo: {
          perPage: 50,
          page: 1,
        },
        filters: {
          status: undefined,
          name: undefined,
        },
      },
    },
    result: {
      data: {
        partners: {
          nodes: [
            {
              id: "58",
              name: "Andrew",
              formattedName: "Andrew",
              status: "ACTIVE",
              website: "https://www.dsfsdf.com",
              slug: "123uysdfhwer",
              channel: "external_recruiter",
              trusted: true,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "60",
              name: "MyPartner",
              formattedName: "MyPartner",
              status: "ACTIVE",
              website: "https://www.thing.com",
              slug: "erkjsdf7sdf",
              channel: "external_recruiter",
              trusted: true,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "70",
              name: "Southville Solutions2",
              formattedName: "Southville Solutions2",
              status: "ACTIVE",
              website: "https://southvillesol.com",
              slug: "southville_solutions",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "71",
              name: "10 Hour Labs",
              formattedName: "10 Hour Labs",
              status: "ACTIVE",
              website: "https://10hourlabs.com",
              slug: "10_hour_labs",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "72",
              name: "Exo Mindset2",
              formattedName: "Exo Mindset2",
              status: "ACTIVE",
              website: "https://exomindset.co",
              slug: "exo_mindset",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "75",
              name: "sdflksdfk",
              formattedName: "sdflksdfk",
              status: "ACTIVE",
              website: "https://www.google.com",
              slug: "sdfsdfkj",
              trusted: false,
              canControlRate: true,
              channel: "external_recruiter",
              __typename: "Partner",
            },
            {
              id: "79",
              name: "NewPartner",
              formattedName: "NewPartner",
              status: "ACTIVE",
              website: "https://www.google.com",
              slug: "new_partner",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "80",
              name: "NewPartner",
              formattedName: "NewPartner",
              status: "ACTIVE",
              website: "https://www.google.com",
              slug: "new_partnersdkjf3244",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "81",
              name: "dslfksdlfkdslfk",
              formattedName: "dslfksdlfkdslfk",
              status: "ACTIVE",
              website: "https://123.com",
              slug: "dsjfhsdjfhds734",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "82",
              name: "jdhsfjsdfh",
              formattedName: "jdhsfjsdfh",
              status: "ACTIVE",
              website: "https://www.123.com",
              slug: "sdkjfd7234jasf",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "83",
              name: "AndrewCompany",
              formattedName: "AndrewCompany",
              status: "ACTIVE",
              website: "https://www.andrewcompany.com",
              slug: "sdfkjsdf87324jsdf",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "84",
              name: "ertlekrtekrtq",
              formattedName: "ertlekrtekrtq",
              status: "ACTIVE",
              website: "https://www.google.com",
              slug: "sdfkjsdf7234",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "85",
              name: "MyPartner",
              formattedName: "MyPartner",
              status: "ACTIVE",
              website: "https://www.google.com",
              slug: "sdjasd8723",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "86",
              name: "HelloWorld",
              formattedName: "HelloWorld",
              status: "ACTIVE",
              website: "https://www.HelloWorld.com",
              slug: "hello_world",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "87",
              name: "HelloWorld2",
              formattedName: "HelloWorld2",
              status: "ACTIVE",
              website: "https://www.hellowworld2.com",
              slug: "hellow_wroldsad",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "88",
              name: "helloworld3",
              formattedName: "helloworld3",
              status: "ACTIVE",
              website: "https://123.com",
              slug: "123123sdfsdf34",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "89",
              name: "helloworld4",
              formattedName: "helloworld4",
              status: "ACTIVE",
              website: "https://www.9845hf.com",
              slug: "kjdfifsdfj",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "90",
              name: "hellowwolrd5",
              formattedName: "hellowwolrd5",
              status: "ACTIVE",
              website: "https://www.dsfsdfds.com",
              slug: "kjsdfs87hj3",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "91",
              name: "hellowworld6",
              formattedName: "hellowworld6",
              status: "ACTIVE",
              website: "https://www.dsfsdf.com",
              slug: "sdkjsd",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
            {
              id: "92",
              name: "heellowwolrd7",
              formattedName: "heellowwolrd7",
              status: "ACTIVE",
              website: "https://www.123.com",
              slug: "sdfsdfsdfs",
              channel: "external_recruiter",
              trusted: false,
              canControlRate: true,
              __typename: "Partner",
            },
          ],
          pageInfo: {
            endCursor: "MjU",
            hasNextPage: false,
            hasPreviousPage: false,
            startCursor: "MQ",
            __typename: "PageInfo",
          },
          totalCount: 22,
          currentPage: 1,
          currentOffset: 0,
          edges: [
            {
              cursor: "MQ",
              node: {
                id: "58",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "Mg",
              node: {
                id: "60",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "Mw",
              node: {
                id: "70",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "NA",
              node: {
                id: "71",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "NQ",
              node: {
                id: "72",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "Ng",
              node: {
                id: "75",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "Nw",
              node: {
                id: "79",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "OA",
              node: {
                id: "80",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "OQ",
              node: {
                id: "81",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTA",
              node: {
                id: "82",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTE",
              node: {
                id: "83",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTI",
              node: {
                id: "84",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTM",
              node: {
                id: "85",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTQ",
              node: {
                id: "86",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTU",
              node: {
                id: "87",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTY",
              node: {
                id: "88",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTc",
              node: {
                id: "89",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTg",
              node: {
                id: "90",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MTk",
              node: {
                id: "91",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
            {
              cursor: "MjA",
              node: {
                id: "92",
                __typename: "Partner",
              },
              __typename: "PartnerEdge",
            },
          ],
          __typename: "PartnerConnection",
        },
      },
    },
  },
];

export { MOCK_GET_PARTNERS };
