import React from "react";
import { useAuth } from "hooks";
import { Grid, PageWrapper } from "components/Containers";
import { DashboardHeader, DashboardSummaryCard } from "components/Dashboard";
import { RecentApplicants } from "components/Missions/Applicants";
import { EventsList } from "components/Events";
import Card from "components/Card";
import Tabs from "components/Tabs";
import { FormattedMessage } from "react-intl";

/**
 * Overview
 */
const Overview = () => {
  const { profileId } = useAuth();
  const [selectedTab, setSelectedTab] = React.useState(0);

  return (
    <PageWrapper>
      <DashboardHeader id={profileId} />
      <Grid topMargin>
        <Grid.col start={1} end={9}>
          <Tabs
            selected={selectedTab}
            onSelect={setSelectedTab}
            data={[
              <FormattedMessage id="Dashboard.Overview.Tab1" key="tab1" />,
              <FormattedMessage id="Dashboard.Overview.Tab2" key="tab2" />,
            ]}
            hasMargin
          />
        </Grid.col>
        <Grid.col start={1} end={9}>
          <DashboardSummaryCard profileId={profileId} isCardView={selectedTab === 0} resultsPerPage={20} />
        </Grid.col>
        <Grid.col start={9} end={13} row={1}>
          <Grid>
            <Grid.col start={1} end={13}>
              <Card>
                <RecentApplicants />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                <EventsList />
              </Card>
            </Grid.col>
          </Grid>
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default Overview;
