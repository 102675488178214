import React, { useState } from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { colors, fontSize } from "style";
import { useAuth, useNotificationSettings, useToast } from "hooks";
import { Toggle } from "components/Form";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { Row } from "components/Containers";

/**
 * RolesSubscribeToggle
 */
const RolesSubscribeToggle = () => {
  const { isMember, id: userId } = useAuth();
  if (!isMember) return null;

  const { toast } = useToast();
  const { notificationSetting, handleNotificationSubscription, loading } = useNotificationSettings({
    name: "MISSION_ROLE_PUBLISHED",
  });

  const [isSubscribed, setIsSubscribed] = useState(notificationSetting?.enabled);

  const onComplete = (response) => {
    setIsSubscribed(!isSubscribed);
    const notificationSettings = response?.setNotificationSettings?.notificationSetting;

    toast.success(
      <FormattedMessage id={`Missions.Board.Subscribe${notificationSettings?.enabled ? `Enabled` : `Disabled`}`} />
    );
  };

  const handleChange = () => {
    handleNotificationSubscription({
      isSubscribed: !isSubscribed,
      userId,
      onComplete,
      onError,
    });
  };

  const onError = () => {
    toast.error(<FormattedMessage id="Missions.Board.SubscribeError" />);
  };

  return (
    <>
      <Row css={styles.label}>
        <Icon type={ICON_TYPE.email} size={ICON_SIZE.large} color="inherit" />

        <FormattedMessage id="Missions.Board.Subscribe" />
      </Row>
      <Toggle value={isSubscribed} onChange={() => handleChange()} disabled={loading} />
    </>
  );
};

const styles = {
  label: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyLight6};
    font-weight: 500;
  `,
};

export default RolesSubscribeToggle;
