import { gql } from "@apollo/client";

const ADD_PROMPT = gql`
  mutation addPrompt($input: AddPromptInput!) {
    addPrompt(input: $input) {
      prompt {
        id
        title
        content
        isActive
      }
    }
  }
`;

const UPDATE_PROMPT = gql`
  mutation updatePrompt($input: UpdatePromptInput!) {
    updatePrompt(input: $input) {
      prompt {
        id
        title
        content
        isActive
      }
    }
  }
`;

const REMOVE_PROMPT = gql`
  mutation removePrompt($input: RemovePromptInput!) {
    removePrompt(input: $input) {
      id
    }
  }
`;

export { ADD_PROMPT, UPDATE_PROMPT, REMOVE_PROMPT };
