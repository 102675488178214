/** @jsx jsx */
import styled from "@emotion/styled";

/**
 * TableBody
 */
const TableBody = styled.tbody`
  vertical-align: inherit;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
`;

export default TableBody;
