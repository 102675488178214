import React from "react";
import { css } from "@emotion/react";
import { H3 } from "components/Text";
import { fontSize, colors } from "style";
import { Table, TableHead, TableBody, TableRow, TableCell, TableHeaderCell } from "components/Table";
import { PrimaryLink } from "components/Links";

/**
 * Privacy Content
 */
const PrivacyContent = () => (
  <>
    <p css={styles.small}>Effective February 1st, 2024</p>

    <br />
    <H3>Welcome!</H3>

    <p>
      Welcome to Mission! We hope you will enjoy and appreciate using our “<b>Services</b>”, which may be visiting
      or using our website at{" "}
      <PrimaryLink href="https://mission.dev/" className="a" target="_blank">
        https://mission.dev/
      </PrimaryLink>
      , including all sub-domains or sub-pages, including the sub-domain{" "}
      <PrimaryLink href="https://app.mission.dev/" className="a" target="_blank">
        https://app.mission.dev/
      </PrimaryLink>{" "}
      which is where you can access the Mission “<b>Platform</b>” for managing your “<b>Squad</b>” of software
      engineers (“<b>Members</b>”) or if you are a Member, communicate with a Mission “<b>Customer</b>” or “
      <b>Partner</b>” and manage your work as part of a Squad, and various related functionalities.
    </p>

    <p>
      9371-6793 Quebec Inc. d.b.a Mission (the company that owns and operates the Services) takes your privacy and
      security of personal data very seriously. We have provided and will continue to provide a secure environment
      and this strict Privacy Policy (the “<b>Policy</b>”) that describes the limited ways your information is
      used and the limited access to such information. We ask that you read it carefully.
    </p>
    <br />
    <H3>Key Elements of this Policy</H3>

    <p>
      Here are the key elements of this Policy so you can know the important parts right away to make an informed
      decision about your consent for our collection, use and disclosure of your personal data. By submitting any
      personal data to us via any means, you consent to such collection, use and disclosure. You can find the
      details in the rest of the Policy.
    </p>

    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Personal data we collect from you but only with your consent</TableHeaderCell>
          <TableHeaderCell>What we do with it</TableHeaderCell>
          <TableHeaderCell>Third parties we share it with</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Candidate Information</TableCell>
          <TableCell>Determine whether you are eligible to become a Member</TableCell>
          <TableCell>
            Companies that provide the infrastructure for the Services, such as Amazon Web Services (AWS), and CRM
            software such as HubSpot
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Member Information</TableCell>
          <TableCell>Manage your Platform account and communicate with you about the Services</TableCell>
          <TableCell>
            Companies that provide the infrastructure for the Services, such as Amazon Web Services (AWS) and
            Heroku, and CRM software such as HubSpot
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Candidate and Member Video Information; Member Identification Information; Member Security Information
          </TableCell>
          <TableCell>
            Evaluate your qualifications to become a Member or to be placed in a Squad; verify your identity and
            conduct background checks as needed
          </TableCell>
          <TableCell>
            Customers, and companies that provide services performing identity verification and background checks
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Partner Information</TableCell>
          <TableCell>Communicate with you about the Services</TableCell>
          <TableCell>
            Companies that provide the infrastructure for the Services, such as Amazon Web Services (AWS), and CRM
            software such as HubSpot
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Customer Information</TableCell>
          <TableCell>Manage your Platform account and communicate with you about the Services</TableCell>
          <TableCell>
            Companies that provide the infrastructure for the Services, such as Amazon Web Services (AWS) and
            Heroku, and CRM software such as HubSpot
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>

    <br />
    <H3>Some Terms</H3>

    <p>Before we get started, here are a few terms we think you should know as you read this Policy.</p>

    <p>
      “<b>Data Protection Laws</b>” refers to the laws that are designed to protect your personal data and privacy
      in the place where you live. This includes: (1) the “<b>GDPR</b>”, the European data protection law which
      stands for “General Data Protection Regulation”, with the official name{" "}
      <i>Regulation (EU) 2016/679 of the European Parliament and of the Council</i>; (2) “<b>PIPEDA</b>” (
      <i>Personal Information Protection and Electronic Documents Act</i>), which is the Canadian Data Protection
      Law that applies to our activities in Canada; (3){" "}
      <i>An Act Respecting the Protection Of Personal Information in the Private Sector </i>
      (the “<b>Quebec Privacy Act</b>”) which applies to our activities in Quebec; and (4) the “<b>UK GDPR</b>”
      which applies to our activities in the United Kingdom; please note that when this Policy refers only to the
      “GDPR”, this includes the UK GDPR as applicable. Mission is committed to adhering to all these applicable
      Data Protection laws.
    </p>

    <p>
      “<b>Personal data</b>” – this is the information we collect from you or about you and which is defined in
      the GDPR as “any information relating to an identified or identifiable natural person.” It can be as simple
      as your name or your email, or something more complicated like an online identifier (usually a string of
      letters and / or numbers) that gets attached to you. Under PIPEDA and the Quebec Privacy Act, the equivalent
      concept is “personal information”, which is roughly the same. Any mention of “personal data” in this Policy
      shall also mean personal information.
    </p>
    
    <br />
    <H3>About Us and Contacting Us</H3>

    <p>
      9371-6793 Quebec Inc. d.b.a Mission is a registered corporation located in the Province of Quebec, Canada,
      with an office at the address listed below. Where this Policy refers to “Mission”, it may refer to 9371-6793
      Quebec Inc. and / or its affiliates, subsidiaries and parent companies and / or their respective officers,
      directors, employees, agents, partners, principals, representatives, successors and assigns (collectively “
      <b>Representatives</b>”), depending on the context. Any reference to “<b>we</b>”, “<b>our</b>”, or “
      <b>us</b>” in this Policy shall also refer to Mission. In this Policy, a Website visitor or Services user
      may be referred to as “<b>you</b>”.
    </p>

    <p>
      Under the GDPR, Mission is a “Data Controller”. That means we collect personal data directly from you and
      determine the purpose and means of “processing” that data. “Processing” is a broad term that means
      collection, use, storage, transfer or any other action related to your personal data; it is used in this
      Policy in that way.
    </p>

    <p>
      <b>Mission Privacy Officer</b>
      <br />
      <PrimaryLink href="mailto:privacy@mission.dev" className="s6" target="_blank">
        privacy@mission.dev
      </PrimaryLink>
    </p>

    <p>or:</p>

    <p>
      <b>Mission Privacy Officer</b>
      <br />
      2-8 Laurier West Avenue
      <br />
      Montreal, QC
      <br />
      Canada
      <br />
      H3T 2N3
      <br />
    </p>
    
    <br />
    <H3>Your Rights</H3>

    <p>
      You have the following rights regarding your personal data held by Mission, and other rights. Please note
      that not necessarily all of these rights may be available to you; this depends on the Data Protection Laws
      where you are located that apply to you. These rights may be exercised without affecting the price you pay
      for any of the Services, if any.
    </p>

    <ul id="l1">
      <li>
        <p>The right to withdraw at any time your consent for Mission to process your personal data;</p>
      </li>
      <li>
        <p>The right to have your personal data erased from Mission’s records;</p>
      </li>
      <li>
        <p>The right to access your personal data and any relevant information around its processing and use;</p>
      </li>
      <li>
        <p>
          The right to have a copy of your personal data given to you in an easy to read format so that you can
          transfer it to another data processor;
        </p>
      </li>
      <li>
        <p>
          The right to have your personal data corrected or updated if you believe it is inaccurate or out of
          date;
        </p>
      </li>
      <li>
        <p>The right to opt out of marketing communications we send you, at any time;</p>
      </li>
      <li>
        <p>
          The right to know whether Mission sells or shares your personal data (and if so, who gets it). Please
          refer to that information elsewhere in this Policy, though you can contact our Privacy Officer if you
          need additional information or clarifications;
        </p>
      </li>
      <li>
        <p>The right to demand that Mission not sell your Personal Data;</p>
      </li>
      <li>
        <p>
          The right to restrict the processing of your personal data if it is inaccurate or if our processing or
          use of it is against the law; and
        </p>
      </li>
      <li>
        <p>The right to refuse any marketing or advertising targeted at you by Mission.</p>
      </li>
    </ul>

    <p>
      If you wish to exercise any of these rights, please contact our Privacy Officer at the contact information
      above, or refer to the relevant sections further in this Policy.
    </p>

    <p>
      Please note that if you request the erasure of your personal data we shall do it to the extent feasible;
      however Mission reserves the right to retain some of your personal data for a reasonable time in order to
      satisfy certain legal obligations, protect our legal interests or under a legal procedure of any sort.
    </p>
    <p>
      Furthermore, deletion of your personal data will prevent you from using all or a portion of the Services.
    </p>
    <br />
    <H3>Personal Data Collected from You and What We Use It For</H3>

    <p>
      In the Table below, please find all the personal data we may collect from you directly, what we use it for,
      and the legal basis under the GDPR for us having and processing this personal data. Under PIPEDA and the
      Quebec Privacy Act, the legal basis is your informed consent, and by submitting this personal data you
      acknowledge having granted this consent to Mission.
    </p>

    <Table cellSpacing="0">
      <TableHead>
        <TableRow>
          <TableHeaderCell>Personal data category</TableHeaderCell>
          <TableHeaderCell>Personal data processed</TableHeaderCell>
          <TableHeaderCell>What we use it for (the “purpose” of processing)</TableHeaderCell>
          <TableHeaderCell>Legal basis for processing under the GDPR</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Contact Information</TableCell>
          <TableCell>Your email address</TableCell>
          <TableCell>To send you our newsletter or otherwise communicate with you about the Services</TableCell>
          <TableCell>
            Your consent in giving us this information or performance of a contract between you and us
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Candidate Information</TableCell>
          <TableCell>
            First and last name, email address, phone number, and various education and professional information
            to the extent this includes personal data
          </TableCell>
          <TableCell>Determine whether you are eligible to become a Member</TableCell>
          <TableCell>Your consent in giving us this information</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Candidate Video Information</TableCell>
          <TableCell>
            Interview recordings, test results, skills assessments and feedback collected through video
            recordings, to the extent this is considered personal data
          </TableCell>
          <TableCell>Determine whether you are eligible to become a Member</TableCell>
          <TableCell>Your consent in giving us this information</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Member Information</TableCell>
          <TableCell>
            First and last name, email address, phone number, various education and professional information to
            the extent this includes personal data, and any personal data included on your identification
            documents
          </TableCell>
          <TableCell>
            Match you with a Customer to make you a member of a Squad; communicate with you about the Services;
            create and manage your account on the Platform and enable logging-in to the Platform; verify your
            identity
          </TableCell>
          <TableCell>The performance of a contract between you and us</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Member Video Information</TableCell>
          <TableCell>
            Interview recordings, test results, skills assessments and feedback collected through video
            recordings, to the extent this is considered personal data
          </TableCell>
          <TableCell>Evaluate your qualifications to match you to a Squad</TableCell>
          <TableCell>The performance of a contract between you and us, and your consent</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Member Payment Information</TableCell>
          <TableCell>Bank account information</TableCell>
          <TableCell>Pay you for the work done as part of a Squad</TableCell>
          <TableCell>The performance of a contract between you and us</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Partner Information</TableCell>
          <TableCell>First and last name, email address, and phone number</TableCell>
          <TableCell>To communicate with you regarding the Services</TableCell>
          <TableCell>Your consent</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Partner Payment Information</TableCell>
          <TableCell>Bank account information</TableCell>
          <TableCell>Pay you for the work done by your employee Members as part of a Squad</TableCell>
          <TableCell>The performance of a contract between you and us</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Customer Information</TableCell>
          <TableCell>First and last name, email address, phone number and billing address</TableCell>
          <TableCell>
            Communicate with you about the Services; create and manage your account on the Platform and enable
            logging-in to the Platform; send you invoices
          </TableCell>
          <TableCell>Your consent or performance of a contract between you and us</TableCell>
        </TableRow>
      </TableBody>
    </Table>

    <p>
      Where you have provided personal data further to the contract between you and us, if you fail to provide
      such data or withdraw your consent to use such data, you will no longer be able to access certain portions
      of the Services, including the Platform.
    </p>
    <br />
    <H3>Personal Data Collected About You from Third Parties and What We Use It For</H3>
    <p>
      Sometimes we get personal data about you from third parties. This Table explains the details about this
      personal data – what it is, where it came from, what we do with it, and legal basis for us having and
      processing this personal data under the GDPR. Under PIPEDA and the Quebec Privacy Act, the legal basis is
      your informed consent. None of this data comes from publicly-available sources.
    </p>

    <Table cellSpacing="0">
      <TableHead>
        <TableRow>
          <TableHeaderCell>Personal data category</TableHeaderCell>
          <TableHeaderCell>Personal data processed</TableHeaderCell>
          <TableHeaderCell>Who we get the data from</TableHeaderCell>
          <TableHeaderCell>What we use it for (the “purpose” of processing)</TableHeaderCell>
          <TableHeaderCell>Legal basis for processing under the GDPR</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Member Information</TableCell>
          <TableCell>
            First and last name, email address, phone number, and various education and professional information
            to the extent this includes personal data
          </TableCell>
          <TableCell>Your employer who is our Partner</TableCell>
          <TableCell>To assign you to a Squad</TableCell>
          <TableCell>Performance of a contract between the Partner and us, and your consent</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Member Performance Information</TableCell>
          <TableCell>Your name and email</TableCell>
          <TableCell>Code repositories and other developer platforms such as GitHub</TableCell>
          <TableCell>To transfer performance reports to the Customer you are working for</TableCell>
          <TableCell>Performance of a contract between you and us, and your consent</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Member Identification Information</TableCell>
          <TableCell>Facial biometric data and identity documents necessary for verification</TableCell>
          <TableCell>
            Companies that provide identity verification and background check services such as{" "}
            <PrimaryLink href="https://certn.co/" target="_blank">
              Certn
            </PrimaryLink>
          </TableCell>
          <TableCell>Perform identity verification for fraud prevention purposes</TableCell>
          <TableCell>Performance of a contract between you and us, and your consent</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Member Security Information</TableCell>
          <TableCell>
            First and last name, email address, phone number, current address, previous addresses, social
            insurance or social security number, any other personal data needed to complete a background check
          </TableCell>
          <TableCell>
            Companies that provide identity verification and background check services such as{" "}
            <PrimaryLink href="https://certn.co/" target="_blank">
              Certn
            </PrimaryLink>
          </TableCell>
          <TableCell>
            Perform security and background checks, but only upon request of the Customer who is interested in
            having you as a Member of their Squad if required by the nature of their business
          </TableCell>
          <TableCell>Performance of a contract between you and us</TableCell>
        </TableRow>
      </TableBody>
    </Table>

    <p>
      To the extent that analytics or advertising identifiers are generated from third parties, these may be
      considered personal data collected from third parties, and you can find details about that further below in
      this Policy.
    </p>
    <br />
    <H3>Sensitive Personal Data</H3>

    <p>
      Under the GDPR and other Data Protection laws, biometric data is considered sensitive personal data. You can
      find details of our uses of such sensitive personal data elsewhere in this Policy. We shall treat such
      sensitive personal data with the higher level of security and safeguards required by the Data Protection
      Laws.
    </p>
    <br />
    <H3>Who We Transfer Your Personal Data To</H3>

    <p>
      We routinely share some of your personal data with certain types of third parties who are identified in the
      Table below along with what they do with it. Some of those third-party recipients may be based outside your
      home jurisdiction. If you are in the European Economic Area or the United Kingdom— please see the “Transfer
      of Your Personal Data Outside of the European Economic Area” further down in this Policy for more
      information including on how we safeguard your personal data when this occurs.
    </p>

    <p>
      We will share personal data with law enforcement or other public authorities if: (1) we are required by
      applicable law in response to lawful requests, including to meet national security or law enforcement
      requirements; (2) if we believe it is necessary in order to investigate, prevent, or take action regarding
      illegal activities, fraud, or situations involving potential threats to the safety of any person, or any
      violation of Mission’s Terms of Service; or (3) if we believe it is necessary to investigate, prevent, or
      take action regarding situations that involve abuse of the Services infrastructure or the Internet in
      general (such as voluminous spamming, denial of service attacks.
    </p>

    <p>
      We may also share personal data: (1) to a parent company, subsidiaries, joint ventures, or other companies
      under common control with Mission (in which case we will require such entities to honour this Policy); (2)
      if Mission merges with another entity, is subject to a corporate reorganization, sells or transfers all or
      part of its business, assets or shares (in which case we will require such entity to assume our obligations
      under this Policy, or inform you that you are covered by a new privacy policy).
    </p>

    <p>
      We will never share your personal data with other third parties except under these circumstances. We do not
      sell or rent your personal data to any third party for direct marketing purposes or any other purpose.
    </p>

    <Table cellSpacing="0">
      <TableHead>
        <TableRow>
          <TableHeaderCell>Personal data category</TableHeaderCell>
          <TableHeaderCell>Who we transfer it to</TableHeaderCell>
          <TableHeaderCell>What they do with it</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Contact Information</TableCell>
          <TableCell>
            <PrimaryLink href="https://iterable.com/" target="_blank">
              Iterable
            </PrimaryLink>{" "}
            <span>and other email providers</span>
          </TableCell>
          <TableCell>Send you the newsletter or other emails regarding the Services</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Contact Information</TableCell>
          <TableCell>
            <li>
              Companies that provide identity verification and background check services such as{" "}
              <PrimaryLink href="https://certn.co/" target="_blank">
                Certn
              </PrimaryLink>
            </li>
            <li>
              Companies that provide interview and technical challenge assessment services such as{" "}
              <PrimaryLink href="https://hireflix.com" target="_blank">
                Hireflix
              </PrimaryLink>{" "}
              or{" "}
              <PrimaryLink href="https://coderbyte.com/" target="_blank">
                Coderbyte
              </PrimaryLink>
            </li>
          </TableCell>
          <TableCell>
            Send you a link to complete the process of becoming a Member through verification checks and
            assessments
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Candidate Information</TableCell>
          <TableCell>
            <li>Companies that provide email services, such as Iterable</li>
            <li>
              Companies that provide CRM Services, such as{" "}
              <PrimaryLink href="https://www.hubspot.com/" target="_blank">
                HubSpot
              </PrimaryLink>
            </li>
            <li>
              Companies that provide the infrastructure for the Services, such as{" "}
              <PrimaryLink href="https://aws.amazon.com/" target="_blank">
                Amazon Web Services (AWS)
              </PrimaryLink>
            </li>
          </TableCell>
          <TableCell>
            Store it so that we may retrieve it to communicate with you about the Services and determine your
            eligibility to become a Member
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Member Information</TableCell>
          <TableCell>
            <li>Companies that provide email services, such as SendGrid</li>
            <li>Companies that provide CRM Services, such as HubSpot</li>
            <li>
              Companies that provide the infrastructure for the Services, such as Amazon Web Services (AWS) and
              <PrimaryLink href="https://www.heroku.com/" target="_blank">
                Heroku
              </PrimaryLink>
            </li>
          </TableCell>
          <TableCell>
            <li>Store it so that we may retrieve it to communicate with you</li>
            <li>Store it to facilitate your logging-in to the Platform</li>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Member Payment Information</TableCell>
          <TableCell>
            Our financial institution or third-party payment services such as{" "}
            <PrimaryLink href="https://www.letsdeel.com/" className="s7" target="_blank">
              Deel
            </PrimaryLink>
          </TableCell>
          <TableCell>Pay you for the work done as part of a Squad</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Candidate and Member Video Information</TableCell>
          <TableCell>Customers who are interested in having you as a Member of their Squad</TableCell>
          <TableCell>Determine your suitability for being a member of their Squad</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Summary reports of Member Identification Information and Member Security Information*
          </TableCell>
          <TableCell>Customers who are interested in having you as a Member of their Squad</TableCell>
          <TableCell>
            Determine your suitability for being a member of their Squad by confirming your identity and security
            status
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Partner Information</TableCell>
          <TableCell>
            <li>Companies that provide email services, such as SendGrid</li>
            <li>Companies that provide CRM Services, such as HubSpot</li>
          </TableCell>
          <TableCell>Store it so that we may retrieve it to communicate with you about the Services</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Partner Payment Information</TableCell>
          <TableCell>Our financial institution</TableCell>
          <TableCell>Pay you for the work done by your employee Members as part of a Squad</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Customer Information</TableCell>
          <TableCell>
            <li>Companies that provide email services, such as Iterable</li>
            <li>Companies that provide CRM Services, such as HubSpot</li>
            <li>
              Companies that provide the infrastructure for the Services, such as Amazon Web Services (AWS) and
              Heroku
            </li>
          </TableCell>
          <TableCell>
            <li>Store it so that we may retrieve it to communicate with you</li>
            <li>Store it to facilitate your logging-in to the Platform</li>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Customer Information</TableCell>
          <TableCell>
            <li>Companies that provide email services, such as Iterable</li>
            <li>Companies that provide CRM Services, such as HubSpot</li>
            <li>
              Companies that provide the infrastructure for the Services, such as Amazon Web Services (AWS) and
              Heroku
            </li>
          </TableCell>
          <TableCell>
            <li>Store it so that we may retrieve it to communicate with you</li>
            <li>Store it to facilitate your logging-in to the Platform</li>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Advertising identifiers</TableCell>
          <TableCell>Companies that provide online advertising networks, such as Google</TableCell>
          <TableCell>
            Show you ads for Mission and the Services when you are on the internet, as further detailed in the{" "}
            <i>Mission Advertising </i>
            section below
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Analytics identifiers and IP addresses</TableCell>
          <TableCell>
            Companies that provide data analytics, such as{" "}
            <PrimaryLink href="https://segment.com/" className="s7" target="_blank">
              Segment
            </PrimaryLink>
          </TableCell>
          <TableCell>
            Provide us with analytics as to how the Website and Platform are used, as further detailed in the{" "}
            <i>Limited Gathering of</i>
            Information section below
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>

    <p>
      *Please note the actual Member Identification Information and Member Security Information is not shared with
      Customers who are interested in having you join their squad; only the results of the identity or background
      check (as applicable) are shared with the Customer. Exceptionally, upon request the Customer may receive
      copies of a photo of your face (the facial biometric data) and identity document(s) on a report verifying
      your identity.
    </p>

    <H3>Mission Advertising and Opting Out</H3>

    <p>
      Mission is continuously evaluating and modifying our use of various advertising networks, which may change
      from time to time. In this section you will find all the advertising networks that Mission may currently use
      and instructions for opting out of them. If we do additional advertising in the future, this section will be
      updated. If you would like to know exactly what advertising services are used at any given time, please
      contact our Privacy Officer with the contact information further up in this Policy.
    </p>

    <p>
      Mission may use{" "}
      <PrimaryLink href="https://adwords.google.com/" className="a" target="_blank">
        Google AdWords and Display Network
      </PrimaryLink>{" "}
      and by visiting the Website you consent to this use. Specifically, Mission may use the remarketing features
      of interest-based advertising of Google AdWords that delivers you advertisements that will be of particular
      interest to you, based on your browsing and activity history interacting with the Website and Services.
      These advertisements will appear on third-party websites around the web. Google uses specific cookies to
      allow them to serve these ads around the web. You may prevent this type of advertising by deleting the
      appropriate Google cookie through your browser, though this may not be permanent. For a more permanent
      solution, you may opt out of such Google advertising by{" "}
      <PrimaryLink href="https://adssettings.google.com/" className="a" target="_blank">
        adjusting your Google ad settings
      </PrimaryLink>
      or using the{" "}
      <PrimaryLink href="http://optout.aboutads.info/" className="a" target="_blank">
        WebChoicesonline opt-out tool
      </PrimaryLink>
      .
    </p>

    <p>
      Mission also may advertise using{" "}
      <PrimaryLink
        href="https://business.linkedin.com/marketing-solutions/cx/17/06/advertise-on-linkedin?trk=sem_lms_gaw&src=go-pa&veh=LMS_NAMER_Core_USCA_Search_Google-Brand_DR-PRS_Broad_HeadTerms-Alpha_All_English_Core_378919961515__linked%20in%20advertising_c__kwd-8081142739_6458957165&mcid=6612464045041733652&cname=LMS_NAMER_Core_USCA_Search_Google-Brand_DR-PRS_Broad_HeadTerms-Alpha_All_English_Core&camid=6458957165&asid=77594803856&targetid=kwd-8081142739&crid=378919961515&placement=&dev=c&ends=1&gclid=CjwKCAiAi_D_BRApEiwASslbJ_iLrdWCcwulor7EYBTPZf1eI_04Vm7dxKVtvN-Xk30Ev8ArIo5cihoCGxMQAvD_BwE&gclsrc=aw.ds"
        target="_blank"
      >
        LinkedIn Advertising
      </PrimaryLink>
      , to display advertisements to you on LinkedIn that will be of interest to you, and by visiting or using the
      Website you consent to this use. LinkedIn may collect or receive information from our and use that
      information to provide measurement services and targeted ads. If you do not want to receive such LinkedIn
      advertisements, you can opt-out of such advertising by
      <PrimaryLink
        href="https://www.linkedin.com/help/linkedin/answer/90274/manage-your-linkedin-ads-settings"
        target="_blank"
      >
        following LinkedIn&apos;s instructions
      </PrimaryLink>{" "}
      or using the
      <PrimaryLink href="http://optout.aboutads.info/" target="_blank">
        WebChoices online opt-out tool
      </PrimaryLink>
      .
    </p>
    
    <br />
    <H3>Limited Gathering of Information for Statistical, Analytical and Security Purposes</H3>

    <p>
      Mission automatically collects certain information using “<b>Third-Party Analytics Programs</b>” such as
      <PrimaryLink href="https://www.hubspot.com/" className="a" target="_blank">
        HubSpot
      </PrimaryLink>
      ,
      <PrimaryLink href="https://analytics.google.com/" className="a" target="_blank">
        Google Analytics
      </PrimaryLink>
      , and
      <PrimaryLink href="https://segment.com/" className="a" target="_blank">
        Segment
      </PrimaryLink>
      to help us understand how our users use the Website and Platform, but none of this information identifies
      you personally, except via an alphanumeric string. For example, each time you visit the Website, we
      automatically collect (as applicable) your IP address, browser and computer or device type, access times,
      the web page from which you came, the web page(s) or content you access, and other related information. We
      use information collected in this manner only to better understand your needs and the needs of Website
      visitors and Services Users in the aggregate. Mission also makes use of information gathered for statistical
      purposes to keep track of the number of visits to the Website, the specific pages on the Website, and users
      with a view to introducing improvements to the Website and Platform.
    </p>

    <p>
      Your IP address and other relevant information we collect using the Third-Party Analytics Programs may be
      used in order to trace any fraudulent or criminal activity, or any activity in violation of the Terms of
      Service or other contractual agreement with us.
    </p>

    <br />
    <H3>Email Communications and Compliance with Anti-Spam Laws</H3>

    <p>
      Mission uses Iterable to manage our mailing list and send out our newsletter and to send out emails related
      to various Services functions (Iterable and any other email provider the “<b>Email Service Providers</b>”).
      Personal data is transferred to the Email Service Providers in order to manage the mailing list and for the
      emails to be sent out properly. Your contact information in the form of your email address is only used to
      send out emails; the Email Service Providers do not use this personal data for any other purpose, and will
      not transfer or sell your personal data to any other third party.
    </p>

    <p>
      You may unsubscribe from Mission’s mailing list at any time, by following the link at the bottom of all
      Mission emails. Other types of emails, such as transactional, relational, and other emails related to
      certain Platform functions will not have an opt-out option as they are necessary for the use of the
      Platform.
    </p>

    <p>
      Mission’s practices in regards to its email are designed to be compliant with anti-spam laws, including the
      American CAN-SPAM Act and the law unofficially called “CASL”, or Canada’s Anti-Spam Law (S.C. 2010, c. 23).
      If you believe you have received email in violation of these laws or any other anti-spam law, please contact
      us using the contact information further up in this Policy.
    </p>

    <H3>Tracking Technology (“Cookies” and Related Technologies)</H3>

    <p>
      Mission uses tracking technology (“cookies” and related technology such as tags, pixels and web beacons) in
      the Services and by interacting with the Services you agree to their use. Cookies are small text files
      placed on your computer or device when you visit a website or use an online service, in order to track use
      of the site or service and to improve the user experience by storing certain data on your computer or
      device.
    </p>

    <p>Specifically, we use cookies and related technologies for the following functions:</p>

    <ul>
      <li>
        <p>to enable your logging-in to the Platform;</p>
      </li>
      <li>
        <p>
          to provide general internal and user analytics on the Website and Platform and to conduct research to
          improve the content of the Website and Platform using analytics programs listed above in this Policy;
        </p>
      </li>
      <li>
        <p>
          to provide errors and crash reporting in the Platform using analytics programs listed above in this
          Policy; and
        </p>
      </li>
      <li>
        <p>to assist in identifying possible fraudulent activities.</p>
      </li>
    </ul>

    <p>
      Your browser or device can be set to refuse cookies or delete them after they have been stored. You can
      refer to your browser’s or device’s help section for instructions, but here are instructions for the most
      commonly-used browsers and operating systems:
    </p>

    <ul>
      <li>
        <p>
          <PrimaryLink
            href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=en"
            className="s10"
          >
            Google Chrome
          </PrimaryLink>
        </p>
      </li>
      <li>
        <p>
          <PrimaryLink
            href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
            className="s10"
          >
            Mozilla Firefox
          </PrimaryLink>
        </p>
      </li>
      <li>
        <p>
          <PrimaryLink
            href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
            className="s10"
          >
            Microsoft Edge
          </PrimaryLink>
        </p>
      </li>
      <li>
        <p>
          <PrimaryLink
            href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
            className="s10"
          >
            Microsoft Internet Explorer
          </PrimaryLink>
        </p>
      </li>
      <li>
        <p>
          <PrimaryLink href="https://www.opera.com/help/tutorials/security/privacy/" className="s10">
            Opera
          </PrimaryLink>
        </p>
      </li>
      <li>
        <p>
          <PrimaryLink href="https://support.apple.com/kb/ph21411?locale=en_US" className="s10">
            Apple Safari
          </PrimaryLink>
        </p>
      </li>
      <li>
        <p>
          <PrimaryLink href="https://support.apple.com/en-ca/HT201265">iOS</PrimaryLink>
        </p>
      </li>
      <li>
        <p>
          <PrimaryLink href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DAndroid&amp;hl=en">
            Android
          </PrimaryLink>
        </p>
      </li>
    </ul>

    <p>
      Please note that deleting or blocking certain cookies may reduce your user experience by requiring you to
      re-enter certain information, including information required to use the Platform. Furthermore, deleting
      certain cookies may prevent certain functions, or the entirety of the Platform, from working at all.
    </p>
    
    <br />
    <H3>How We Protect Your Personal Data</H3>

    <p>
      We have implemented very strict technical and organisational procedures for ensuring that, by default, only
      personal data which are necessary for each specific purpose of the processing are processed by us. These
      procedures prevent your personal data from being lost; or used or accessed in any unauthorised way.
    </p>

    <p>
      We also have procedures in place to deal with any suspected data security breach. We will notify you and any
      applicable supervisory authority of a suspected data security breach where the Data Protection Laws requires
      us to do so, and within the time frame required by the applicable Data Protection Law.
    </p>

    <p>
      Mission uses only industry best practices (physical, electronic and procedural) in keeping any data
      collected (including personal data) secure. In addition, we use third-party vendors and hosting partners to
      provide the necessary hardware, software, networking, storage, and related technology required to operate
      the Services, and these third parties have been selected for their high standards of security, both
      electronic and physical. For example, Mission uses vendors such as{" "}
      <PrimaryLink href="https://aws.amazon.com/" className="a" target="_blank">
        Amazon Web Services (AWS)
      </PrimaryLink>
      and{" "}
      <PrimaryLink href="https://www.heroku.com/" className="a" target="_blank">
        Heroku
      </PrimaryLink>
      , recognized leaders in secure data, for hosting of the Website, Platform and related data, and storage of
      data including personal data.
    </p>

    <p>
      All information, including personal data, is transferred with encryption using Secure Sockets Layer (“SSL”)
      or Transport Layer Security (“TLS”), robust security standards for Internet data transfer and transactions.
      You can use your browser to check Mission’s valid SSL security certificates on the Website and Platform.
    </p>
    
    <br />
    <H3>
      Transfer of Your Personal Data Outside of the European Economic Area (EEA) and the United Kingdom (U.K.)
    </H3>

    <p>
      For our European users, we endeavour to keep your personal data inside the EEA or the U.K. (as applicable).
      However, certain of our data processors (and Mission) are in other countries where your
    </p>
    <p>
      personal data may be transferred. However, these countries are limited to countries with particular
      circumstances that protect your data, specifically:
    </p>

    <ul>
      <li>
        <p>
          Canada. Canada has been determined to have an “adequate level of protection” for your personal data
          <PrimaryLink
            href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en"
            className="s1"
            target="_blank"
          >
            under European data protection law
          </PrimaryLink>
          .
        </p>
      </li>
      <li>
        <p>
          The United States. Your personal data is only transferred to companies in the United States that: have
          signed agreements with us or have informed us that they are GDPR-compliant; and (2) have concluded the{" "}
          <PrimaryLink
            href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
            className="s1"
            target="_blank"
          >
            Standard Contractual Clauses
          </PrimaryLink>
          for the transfer of personal data outside the EEA or the U.K.
        </p>
      </li>
    </ul>

    <p>That’s it! You have the right, however, to refuse to have your data transferred outside the EEA or the</p>
    <p>
      U.K. Please contact our privacy officer to make that request. Please note that making this request may
      prevent you from being able to use a portion or all of the Services.
    </p>
    
    <br />
    <H3>Supervisory Authorities and Complaints</H3>

    <p>
      If you are in the EEA or the U.K., under the GDPR you have the right to make a complaint to the appropriate
      supervisory authority. If you are not satisfied with the response received or the actions taken by our
      Privacy Officer, or if you would like to make a complaint directly about Mission’s data practices, we invite
      you to contact the supervisory authority in your country.
    </p>

    <p className="s11">
      If you are in the U.K., you should contact the Information Commissioner’s Office who is the supervisory
      authority. You can{" "}
      <PrimaryLink href="https://ico.org.uk/global/contact-us/" className="a" target="_blank">
        reach them in a variety of ways
      </PrimaryLink>
      , including by phone (0303 123 1113 in the UK) and mail (Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9
      5AF).
    </p>

    <p>
      If you are in France, you should contact the{" "}
      <i>Commission Nationale de l&#39;Informatique et des Libertés</i>
      which is the supervisory authority there. Their contact information{" "}
      <PrimaryLink href="https://www.cnil.fr/en/contact-cnil" className="s1" target="_blank">
        can be found here
      </PrimaryLink>
      .
    </p>

    <p className="s11">
      The full listing of all Data Protection Authorities (the supervisory authorities) across the EEA
      <PrimaryLink
        href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
        className="a"
        target="_blank"
      >
        can be found here
      </PrimaryLink>
      .
    </p>

    <p>
      If you are in Canada and you are not satisfied with the response received or the actions taken by our
      Privacy Officer, you can make a complaint to the Office of the Privacy Commissioner of Canada.
    </p>
    <p>
      Instructions on how to do so can be found
      <PrimaryLink
        href="https://www.priv.gc.ca/en/report-a-concern/file-a-formal-privacy-complaint/"
        className="a"
        target="_blank"
      >
        on their website
      </PrimaryLink>
      . In Quebec, you can make a complaint to the Commission d’accès à l’information du Québec with information
      found{" "}
      <PrimaryLink href="https://www.cai.gouv.qc.ca/a-propos/nous-joindre/" className="s1" target="_blank">
        on their website
      </PrimaryLink>
      .
    </p>

    <H3>Data Retention</H3>

    <p>
      Your personal data will only be kept for as long as it is necessary for the purpose needed for that
      processing. For example, we will retain your account information for as long as you need to have an account
      with us to use the Platform; or if you cease to be a Member, we will delete your Member Information.
    </p>

    <p>
      We may have to keep your data for a longer period of time to satisfy our requirements under any applicable
      law or to protect our legal interests.
    </p>
    
    <br />
    <H3>Automated Decision-Making</H3>
    <p>Mission does not use any automated decision-making processes in providing the Services.</p>
    
    <br />
    <H3>Children’s Privacy Statement</H3>

    <p>
      The Services are not intended for children under the age of 18. We do not knowingly collect any personal
      data from a child under 18. If we become aware that we have inadvertently received personal data from a
      person under the age of 18 through the Services, we will delete such information from our records.
    </p>

    <br />
    <H3>Changes to This Privacy Policy</H3>

    <p>
      The date at the top of this page indicates when this Policy was last updated. Every now and then, we will
      have to update this Policy, and we will update it no less than once every 12 months You can always find the
      most updated version at this URL, and we will always post a notice on our Website and Platform if we make
      big changes. If you have a Mission account to use the Platform, we will also email you to tell you the
      Policy has been updated, and what the important changes are.
    </p>

    <p>© 9371-6793 Quebec Inc. 2023</p>
  </>
);

const styles = {
  small: css`
    color: ${colors.grayAnatomyLight1};
    font-size: ${fontSize.small};
  `,
  centeredText: css`
    display: flex;
    justify-content: center;
  `,
};

export default PrivacyContent;
