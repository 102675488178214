import { gql } from "@apollo/client";
import { PROFILE_ENTITY_FIELDS } from "graphql/fragments";

const GET_SQUAD_DYNAMICS = gql`
  ${PROFILE_ENTITY_FIELDS}
  query teamDynamics($teamId: ID!, $filters: TeamDynamicsFilter!) {
    teamDynamics(teamId: $teamId, filters: $filters) {
      assessor {
        ...ProfileEntityFields
      }
      assessee {
        ...ProfileEntityFields
      }
      feedback
      questionText
    }
  }
`;

export { GET_SQUAD_DYNAMICS };
