const SQUAD_METRIC_TYPES = {
  activity: "ACTIVITY",
  volume: "VOLUME",
  efficiency: "EFFICIENCY",
  pulse: "PULSE",
  teamDynamics: "TEAM_DYNAMICS",
  clientSatisfaction: "CLIENT_SATISFACTION",
};

export default SQUAD_METRIC_TYPES;
