import { gql } from "@apollo/client";

const coreTeamAdminFields = `
  id
  slug
  name
  missionId
  methodology
  description
  projectDuration
  activeSince
  status
  teamType
  projectType
  projectStartDate
  activeDays
  size
  skills
  activeDays
  dailyReportEnabled
  dailyReportUuid
  effortEnabled
  effortToggleEnabled
  engineeringEnabled
  termSheetEnabled
  weeklyReportEnabled
  gitRepositories
  teamProfilesAccounts{
    id
    accountNames
    profile {
      id
      name
    }
  }
`;

const coreFieldsForLeads = `
  id
  slug
  name
  missionId
  methodology
  description
  projectDuration
  activeSince
  status
  teamType
  projectType
  size
  skills
  activeDays
  gitRepositories
  teamProfilesAccounts{
    id
    accountNames
    profile {
      id
      name
    }
  }
`;
const coreTeamMemeberFields = `
  id
  slug
  name
  missionId
  methodology
  description
  projectDuration
  activeSince
  status
  teamType
  projectType
  size
  skills
  activeDays
  gitRepositories
`;

const organizationFields = `
  id
  slug
  name
  primaryContactPhone
  primaryContactEmail
`;

const ASSIGNMENTS_TEAM_FIELDS = `
  id
  slug
  firstName
  lastName
  avatarUrl
  archetype
  profileId
  slug
  termSheetType
  averageCapacity
  sellingAmount
  sellingCurrency
  vacations(fromSquads: true) {
    id
    startDate
    endDate
    vacationDayCount
  }
`;

const CORE_TEAM_ADMIN_FIELDS = gql`
  fragment TeamAdminFields on Team {
    ${coreTeamAdminFields}
  }
`;

const CORE_TEAM_MEMBER_FIELDS = gql`
  fragment TeamMemberFields on Team {
    ${coreTeamMemeberFields}
  }
`;

const CORE_FIELDS_FOR_LEADS = gql`
  fragment TeamLeadFields on Team {
    ${coreFieldsForLeads}
  }
`;

const ORGANIZATION_TEAM_FIELDS = gql`
  fragment OrganizationTeamFields on Team {
    organization @include(if: $organization) {
      ${organizationFields}
      githubApplicationConnection {
        id
        status
        installationId
        organizationSlug
        repositories {
          fullName
          name
        }
      }
    }
  }
`;

export {
  CORE_TEAM_ADMIN_FIELDS,
  CORE_TEAM_MEMBER_FIELDS,
  ORGANIZATION_TEAM_FIELDS,
  ASSIGNMENTS_TEAM_FIELDS,
  CORE_FIELDS_FOR_LEADS,
};
