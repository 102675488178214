/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { FormattedMessage } from "react-intl";
import { breakpoints, colors, fontSize, margins } from "style";

/**
 * CursorPaginate
 *
 * @param {Number}    resultsPerPage
 * @param {Function}  onNextPage
 * @param {Function}  onPreviousPage
 * @param {Boolean}   hasNextPage
 * @param {Boolean}   hasPreviousPage
 * @param {Object}    pageInfo
 */
const CursorPaginate = ({ resultsPerPage, onNextPage, onPreviousPage, hasNextPage, hasPreviousPage, pageInfo }) => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const handleNextPage = () => {
    if (pageInfo) {
      setSearchParams((params) => {
        // Set params explicity to avoid overwriting other params
        params.set("after", pageInfo?.endCursor);
        params.set("first", resultsPerPage);
        params.set("before", "");
        params.set("last", "");
        return params;
      });
    }
    window.scrollTo({ top: 0 });
    onNextPage();
  };

  const handlePreviousPage = () => {
    if (pageInfo) {
      setSearchParams((params) => {
        // Set params explicity to avoid overwriting other params
        params.set("after", "");
        params.set("first", "");
        params.set("before", pageInfo?.startCursor);
        params.set("last", resultsPerPage);
        return params;
      });
    }
    window.scrollTo({ top: 0 });
    onPreviousPage();
  };

  return (
    <div css={styles.container}>
      <div>
        <button css={styles.button} onClick={handlePreviousPage} disabled={!hasPreviousPage}>
          <Icon type={ICON_TYPE.arrowLeft} size={ICON_SIZE.large} css={styles.icon(!hasPreviousPage)} />
        </button>
      </div>
      <div css={styles.text_container}>
        <FormattedMessage
          id="Global.Pagination.PerPage"
          values={{
            resultsPerPage: <b>{resultsPerPage}</b>,
          }}
        />
      </div>
      <div>
        <button css={styles.button} onClick={handleNextPage} disabled={!hasNextPage}>
          <Icon type={ICON_TYPE.arrowRight} size={ICON_SIZE.large} css={styles.icon(!hasNextPage)} />
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    justify-content: center;
    list-style: none;
    font-size: ${fontSize.small};
    font-weight: 600;
    gap: 2.5rem;
    align-items: center;
    margin-left: -${margins.normal};
    margin-right: -${margins.normal};
    padding: 0 ${margins.normal};
    border-top: 1px solid ${colors.grayAnatomyLight5};
    padding-top: 2rem;

    @media (max-width: ${breakpoints.portrait}) {
      padding: 0 2.5rem;
      margin-right: -2.5rem;
      margin-left: -2.5rem;
      padding-top: 2rem;
    }

    @media (max-width: ${breakpoints.mobile}) {
      padding: 0 2rem;
      margin-right: -2rem;
      margin-left: -2rem;
      padding-top: 2rem;
    }
  `,
  text_container: css`
    font-weight: 400;
  `,
  button: css`
    background: transparent;
    padding: 0;
    border: 0;
  `,
  icon: (isDisabled) => css`
    color: ${colors.purpleRainBase};

    &:hover {
      color: ${colors.purpleRainDark2};
    }

    ${isDisabled &&
    `
      color: ${colors.grayAnatomyLight3};
    `}
  `,
};

CursorPaginate.propTypes = {
  resultsPerPage: PropTypes.number.isRequired,
  onNextPage: PropTypes.func.isRequired,
  onPreviousPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool,
  hasPreviousPage: PropTypes.bool,
  pageInfo: PropTypes.object,
};

export default CursorPaginate;
