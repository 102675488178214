import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { H3 } from "components/Text";
import { RecommendedSquad } from "components/Squads";
import { Header } from "components/DisplayBox";
import { margins } from "style";

/**
 * RecommendedSquads
 *
 * @params {Object}    data
 * @params {String}    marginSize
 * @params {Boolean}    loading
 */
const RecommendedSquads = ({ data, marginSize, loading, ...props }) => {
  return (
    <>
      <Header marginSize={marginSize}>
        <H3>
          <FormattedMessage id="Squads.RecommendedSquads.Title" />
        </H3>
      </Header>
      <Body marginSize={marginSize} {...props}>
        {data?.map((termSheet, id) => (
          <RecommendedSquad key={id} squad={termSheetWithProfiles(termSheet)} loading={loading} />
        ))}
      </Body>
    </>
  );
};

const termSheetWithProfiles = (termSheet) => ({
  ...termSheet,
  assignments: termSheet.assignments.map((a) => ({ ...a, profile: { firstName: "", lastName: "", ...a.profile } })),
});

const Body = styled.div`
  padding: ${({ marginSize }) => (marginSize ? `0 ${marginSize} 0 ${marginSize}` : 0)};
`;

RecommendedSquads.defaultProps = {
  marginSize: margins.normal,
};

RecommendedSquads.propTypes = {
  data: PropTypes.object,
  marginSize: PropTypes.string,
  loading: PropTypes.bool,
};

export default RecommendedSquads;
