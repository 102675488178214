import { useEffect, useState } from "react";
import moment from "moment";
import { PAGINATION_TYPE } from "components/Pagination";
import { GET_PROFILE_EVENTS } from "graphql/queries";
import { useQueryData } from "hooks";

export default function useTimeline({ resultsPerPage = 1000, profileId, eventStartDate }) {
  const [dataByDate, setDataByDate] = useState([]);
  const { hasNextPage, handleNextPage, loading, loadingMore, data } = useQueryData({
    queryName: GET_PROFILE_EVENTS,
    keyName: "profileEvents",
    resultsPerPage,
    paginationType: PAGINATION_TYPE.append,
    variables: {
      profileId,
      filters: {
        startDate: eventStartDate,
      },
    },
  });

  useEffect(() => {
    if (data?.profileEvents?.nodes?.length > 0) {
      const groups = data?.profileEvents?.nodes?.reduce((groups, item) => {
        const date = moment(item.createdAt).format("YYYY-MM-DD");

        if (!groups[date]) {
          groups[date] = [];
        }

        const itemExists = groups[date].some((groupItem) => groupItem.uuid === item.uuid);

        if (!itemExists) {
          groups[date].push(item);
        }

        return groups;
      }, {});

      const groupByDate = Object.keys(groups).map((date) => {
        return {
          date,
          items: groups[date],
        };
      });

      setDataByDate(groupByDate);
    }
  }, [data?.profileEvents?.nodes]);

  useEffect(() => {
    if (loading && !data?.profileEvents?.nodes) {
      setDataByDate([]);
    }
  }, [loading, data?.profileEvents?.nodes]);

  return {
    hasNextPage,
    handleNextPage,
    loading,
    loadingMore,
    data: data?.profileEvents?.nodes,
    dataByDate,
  };
}
