import { gql } from "@apollo/client";

const GET_USERS = gql`
  query users($filters: UserFilter, $first: Int) {
    users(filters: $filters, first: $first) {
      nodes {
        id
        email
        name
      }
    }
  }
`;

export { GET_USERS };
