import { useLocation, useSearchParams } from "react-router-dom";
import { useState, useEffect, useRef, useCallback } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import { PAGINATION_TYPE } from "components/Pagination";

/**
 * useQueryData
 *
 * @param {String}   queryName
 * @param {Number}   resultsPerPage
 * @param {String}   keyName
 * @param {String}   paginationType
 * @param {Object}   variables
 * @param {Object}   fetchPolicy
 * @param {Object}   nextFetchPolicy
 * @param {Object}   notifyOnNetworkStatusChange
 * @param {Object}   skip
 * @param {Object}   readUrlPagination
 * @param {Object}   isCursorPagination
 * @param {Object}   disablePagination
 */
export default function useQueryData({
  queryName,
  resultsPerPage: perPageProp,
  keyName,
  paginationType,
  variables = {},
  fetchPolicy = "cache-first",
  nextFetchPolicy = "cache-first",
  notifyOnNetworkStatusChange = false,
  skip: skipProp = false,
  readUrlPagination = false,
  isCursorPagination = true,
  disablePagination = false,
} = {}) {
  const requestRef = useRef(false);
  const location = useLocation();
  const [searchParams] = useSearchParams({});
  const isClassicPagination = paginationType === PAGINATION_TYPE.classic;
  const [page, setPage] = useState(parseInt(searchParams.get("page")) || 1);
  const resultsPerPage = parseInt(searchParams.get("per_page")) || perPageProp;
  const [skip, setSkip] = useState(skipProp);
  const [stateVariables, setStateVariables] = useState(variables);
  const [loadingMore, setLoadingMore] = useState(false);

  /**
   * @description When variables change, reset page to 1
   */
  useEffect(() => {
    if (JSON.stringify(variables) !== JSON.stringify(stateVariables)) {
      setStateVariables(variables);
      setLoadingMore(true);
      setPage(1);
    }
  }, [variables]);

  /**
   * @description Skip variable must be in state to stay in sync with state variables
   */
  useEffect(() => {
    setSkip(skipProp);
  }, [skipProp]);

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const defaults = useCallback(
    () => ({
      ...(isCursorPagination && !disablePagination && {
        first: resultsPerPage || null,
        last: null,
        after: null,
      }),
      ...(isClassicPagination && !disablePagination && {
        paginationInfo: {
          perPage: resultsPerPage,
          page,
        },
      }),
      orderBy: {},
    }),
    [isCursorPagination, isClassicPagination, resultsPerPage, page, disablePagination]
  );

  /**
   * Default pagination variables
   */
  const [paginationVariables, setPaginationVariables] = useState({
    ...(isCursorPagination && !disablePagination && {
      after: null,
      before: null,
      first: resultsPerPage || null,
      last: null,
    }),
    ...(isClassicPagination && !disablePagination && {
      paginationInfo: {
        perPage: resultsPerPage,
        page,
      },
    }),
  });

  /**
   * Set pagination from search params
   */
  useEffect(() => {
    if (readUrlPagination && !disablePagination) {
      setPaginationVariables({
        ...(isCursorPagination && {
          after: searchParams.get("after") || null,
          before: searchParams.get("before") || null,
          first: searchParams.get("first") ? parseInt(searchParams.get("first")) : searchParams.get("last") ? null : 20,
          last: parseInt(searchParams.get("last")) || null,
        }),
      });
    }
  }, [readUrlPagination, disablePagination]);

  if (!queryName) {
    return {};
  }

  /**
   * Load data from GraphQL
   */
  const { loading, error, fetchMore, data, refetch, networkStatus, client } = useQuery(queryName, {
    variables: {
      ...defaults(),
      ...stateVariables,
      ...(readUrlPagination && !disablePagination ? paginationVariables : {}),
    },
    fetchPolicy,
    nextFetchPolicy,
    notifyOnNetworkStatusChange,
    skip,
  });

  /**
   * When the filters or search get change or the loading changes
   * check if loading more is still there then mark it false
   */
  useEffect(() => {
    if (loadingMore && !loading) {
      setLoadingMore(false);
    }
  }, [loading, stateVariables]);

  /**
   * @description update and set page params
   */
  /*
  // FIXME: This creates a new route every time the page changes which hijacks the browser navigation
  // Below this, I created a new useEffect that instead handles page change on location change
  useEffect(() => {
    if (isClassicPagination && page && resultsPerPage) {
      setSearchParams((params) => {
        params.set("page", page);
        params.set("per_page", resultsPerPage);

        return params;
      });
    }
  }, [loading, queryName, page, resultsPerPage]);
  */

  /**
   * @description On location change, get the page
   */
  useEffect(() => {
    handlePageChange(searchParams.get("page") ? parseInt(searchParams.get("page")) : 1);
  }, [location]);

  const hasPreviousPage = data && data[keyName]?.pageInfo?.hasPreviousPage;
  const hasNextPage = data && data[keyName]?.pageInfo?.hasNextPage;

  /**
   * Load `Previous` page
   */
  const handlePreviousPage = async () => {
    if (hasPreviousPage) {
      setLoadingMore(true);

      await fetchMore({
        variables: {
          after: null,
          before: data[keyName].pageInfo.startCursor,
          last: resultsPerPage,
          first: null,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult[keyName].edges;
          const nodes = fetchMoreResult[keyName].nodes;
          const pageInfo = fetchMoreResult[keyName].pageInfo;
          const currentOffset = fetchMoreResult[keyName].currentOffset;
          const totalCount = fetchMoreResult[keyName].totalCount;
          const edges = [...previousResult[keyName].edges, ...newEdges];
          const currentPage = fetchMoreResult[keyName].currentPage;

          return newEdges.length
            ? {
                [keyName]: {
                  __typename: previousResult[keyName].__typename,
                  edges,
                  nodes,
                  pageInfo,
                  totalCount,
                  currentOffset,
                  currentPage,
                },
              }
            : previousResult;
        },
      });

      setLoadingMore(false);
    }
  };

  /**
   * Load `Next` page
   */
  const handleNextPage = async () => {
    if (hasNextPage && !loadingMore && !requestRef?.current) {
      requestRef.current = true;
      setLoadingMore(true);

      await fetchMore({
        variables: {
          after: data[keyName].pageInfo.endCursor,
          before: null,
          first: resultsPerPage,
          last: null,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult[keyName].edges;
          const newNodes = fetchMoreResult[keyName].nodes;
          const pageInfo = fetchMoreResult[keyName].pageInfo;
          const currentOffset = fetchMoreResult[keyName].currentOffset;
          const currentPage = fetchMoreResult[keyName].currentPage;
          const totalCount = fetchMoreResult[keyName].totalCount;
          const perPage = fetchMoreResult[keyName].perPage;
          const edges = [...previousResult[keyName].edges, ...newEdges];
          const nodes =
            paginationType === PAGINATION_TYPE.append ? [...previousResult[keyName].nodes, ...newNodes] : newNodes;

          return newEdges.length
            ? {
                [keyName]: {
                  __typename: previousResult[keyName].__typename,
                  edges,
                  nodes,
                  pageInfo,
                  totalCount,
                  currentOffset,
                  currentPage,
                  perPage,
                },
              }
            : previousResult;
        },
      });

      requestRef.current = false;
      setLoadingMore(false);
    }
  };

  return {
    hasPreviousPage,
    hasNextPage,
    handlePreviousPage,
    handleNextPage,
    handlePageChange,
    refetch,
    client,
    loading: !!loading,
    loadingMore,
    pageInfo: data && data[keyName]?.pageInfo,
    totalCount: data && data[keyName]?.totalCount,
    currentPage: data && data[keyName]?.currentPage,
    perPage: data && data[keyName]?.perPage,
    refetching: networkStatus === NetworkStatus.refetch,
    networkStatus,
    error,
    data,
  };
}
