import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import Card from "components/Card";
import { ActivityLogBody, COLOR_TYPES, ActivityLogHeader } from "components/ActivityLog";
import { TEAM_ACTIVITY_TYPES } from "constants";
import { LegendItem } from "components/Legend";
import { Header } from "components/DisplayBox";
import { H3 } from "components/Text";
import { fontSize } from "style";
import { formatNumber } from "utils";

/**
 * AllTimeActivityLogLayout
 *
 * @param {Object}     data
 * @param {String}     titleId
 * @param {String}     headerTitleId
 * @param {Boolean}    hideHeader
 * @param {Boolean}    loading
 */
const AllTimeActivityLogLayout = ({ data, titleId, headerTitleId, hideHeader, loading, ...props }) => (
  <StyledCard {...props}>
    {!hideHeader && (
      <Header>
        <H3 loading={loading}>
          <FormattedMessage id={titleId} />
        </H3>
      </Header>
    )}
    <ActivityLogHeader
      days={Object.values(TEAM_ACTIVITY_TYPES).map((type, index) => ({
        name: <FormattedMessage id={`Dashboard.ActivityLog.ActivityLogLayout.Legend.AllTimeActivity.${type}`} />,
        color: COLOR_TYPES[index],
      }))}
      titleId={headerTitleId}
      renderer={LegendItem}
      cols={5}
    />
    <ActivityLogBody
      data={data}
      cols={5}
      renderer={Count}
      forceAspectRadio={false}
      showAll={true}
      loading={loading}
      {...props}
    />
  </StyledCard>
);

const Count = ({ value }) => <StyledCount>{!value || value === 0 ? `—` : formatNumber(value)}</StyledCount>;

Count.propTypes = {
  value: PropTypes.number,
};

const StyledCount = styled.div`
  font-size: ${fontSize.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 400;
`;

const StyledCard = styled(Card)`
  overflow: visible;
`;

AllTimeActivityLogLayout.defaultProps = {
  titleId: "Squads.Squad.Engineering.ActivityLog.CardTitle",
};

AllTimeActivityLogLayout.propTypes = {
  data: PropTypes.array,
  titleId: PropTypes.string,
  headerTitleId: PropTypes.string,
  hideHeader: PropTypes.bool,
  loading: PropTypes.bool,
};

export default AllTimeActivityLogLayout;
