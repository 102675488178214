import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import { Link } from "components/Links";
import { LINKS } from "constants/index";
import { TextLoader } from "components/Loader";
import { OrganizationPhoto } from "components/Organization";
import Card from "components/Card";
import { PrimaryButton } from "components/Buttons";
import { useOrganization } from "hooks";
import { formatNumber } from "utils";
import { MissionOrganizationName } from "components/Missions";

/**
 * OrganizationCard
 *
 * @param {Object}    data
 */
const OrganizationCard = ({ data: dataProp }) => {
  const hasAllData = (d) => d?.logoUrl && d?.name && d?.slug && d?.noOfEmployees && d?.noOfEngineers;
  const { data: organizationData, loading } = useOrganization({
    id: hasAllData(dataProp) ? false : dataProp?.id,
    includeMissions: false,
  });
  const data = organizationData || dataProp;

  return (
    <Card>
      <div css={styles.container}>
        <OrganizationPhoto
          id={data?.id}
          isEditable={false}
          url={data?.logoUrl}
          name={data?.name}
          loading={loading}
          width={`8rem`}
          height={`8rem`}
        />
        <div css={styles.name}>
          {loading ? (
            <TextLoader
              css={css`
                width: 25rem;
              `}
            />
          ) : (
            <MissionOrganizationName data={data} />
          )}
        </div>
        <div css={styles.subtitle}>
          {loading ? (
            <TextLoader
              css={css`
                width: 25rem;
              `}
            />
          ) : (
            `Organization`
          )}
        </div>
        <div css={styles.data_container}>
          <div css={styles.label}>
            <FormattedMessage id="Organization.OrganizationCard.NoEmployees" />
            <div css={styles.item}>{formatNumber(data.noOfEmployees)}</div>
          </div>
          <div css={styles.label}>
            <FormattedMessage id="Organization.OrganizationCard.NoEngineers" />
            <div css={styles.item}>{formatNumber(data.noOfEngineers)}</div>
          </div>
        </div>
        <div css={styles.link}>
          <Link to={LINKS.organization_profile(data?.slug)} routerEnabled>
            <PrimaryButton disabled={loading}>
              <FormattedMessage id="Organization.OrganizationCard.CTA" />
            </PrimaryButton>
          </Link>
        </div>
      </div>
    </Card>
  );
};

const styles = {
  container: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  `,
  name: css`
    font-size: ${fontSize.medium};
    margin-top: 1rem;
    font-weight: 600;
    max-width: 80%;
    text-align: center;
  `,
  subtitle: css`
    font-size: ${fontSize.small};
    margin-top: 0.5rem;
    color: ${colors.grayAnatomyLight1};
    max-width: 80%;
    text-align: center;
  `,
  link: css`
    font-size: ${fontSize.small};
    width: 100%;

    a {
      width: 100%;

      > * {
        width: 100%;
      }
    }
  `,
  label: css`
    font-size: ${fontSize.small};
    font-weight: 500;
    border-top: 1px ${colors.grayAnatomyLight4} solid;
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  item: css`
    font-weight: 400;
    text-align: left;
    color: ${colors.grayAnatomyLight1};
    line-height: normal;
  `,
  data_container: css`
    margin: 2rem 0;
    width: 100%;
    border-bottom: 1px ${colors.grayAnatomyLight4} solid;
  `,
};

OrganizationCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OrganizationCard;
