import { gql } from "@apollo/client";

const GET_ENGINEERING_LEVELS = gql`
  query engineeringLevels($filters: TagFilter) {
    engineeringLevels(filters: $filters) {
      nodes {
        name
        id
        uuid
        description
      }
    }
  }
`;

export { GET_ENGINEERING_LEVELS };
