import moment from "moment";

/**
 * Compares given moment object(date1) to the other given moment object(date2)
 *
 * @param {moment} date1 - The Date object.
 * @param {moment} date2 - The Moment object.
 *
 * @returns {Boolean} True if the provided date's month, year, and day match the current date, false otherwise.
 *
 */
const isDateSame = (date1, date2) =>
  moment(date1).isSame(date2, "day") && moment(date1).isSame(date2, "month") && moment(date1).isSame(date2, "year");

export default isDateSame;
