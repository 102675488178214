import styled from "@emotion/styled";
import PrimaryLink from "./Primary";

/**
 * WhiteLink
 */
const WhiteLink = styled(PrimaryLink)`
  color: rgba(255, 255, 255, 0.9);

  &:hover {
    color: #fff;

    .link-text:after {
      border-bottom-color: #fff;
    }
  }

  &.active {
    color:#e74b9e;
  }

  .link-text:after {
    border-bottom-color: rgba(255, 255, 255, 0.3);
  }
`;

export default WhiteLink;
