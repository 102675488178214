import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "components/Buttons";
import { capitalizeFirst } from "utils";
import DropDown, { DropDownToggle, DropDownMenu, DropDownItem } from "components/DropDown";
import { UserInviteForm } from "components/Settings/Users";
import { USER_FORM_CONFIGS, USER_TYPES } from "constants/index";

/**
 * UserInviteButton
 */
const UserInviteButton = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState(null);

  const handleSelectUserType = (userType) => {
    setSelectedUserType(userType);
    setShowForm(true);
  };

  return (
    <>
      <DropDown>
        <DropDownToggle as={PrimaryButton} id="dropdown-basic">
          <FormattedMessage id={`Settings.Users.UserInviteButton.Button`} />
        </DropDownToggle>
        <DropDownMenu>
          {Object.keys(USER_FORM_CONFIGS)
            .filter((userType) => Object.keys(USER_TYPES).includes(userType))
            .map((userType) => (
              <DropDownItem key={userType} onClick={() => handleSelectUserType(userType)}>
                <FormattedMessage
                  id={`Settings.Users.UserInviteButton.AddUserType`}
                  values={{ type: capitalizeFirst(userType) }}
                />
              </DropDownItem>
            ))}
        </DropDownMenu>
      </DropDown>
      {selectedUserType && (
        <UserInviteForm show={showForm} onClose={() => setShowForm(false)} userType={selectedUserType} {...props} />
      )}
    </>
  );
};

export default UserInviteButton;
