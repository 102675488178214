import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useToast, useFormData, MUTATION_TYPE } from "hooks";
import { ModalForm, MODAL_SIZE } from "components/Modal";
/**
 * ProposalForm
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Boolean}   loading
 * @param {Boolean}   isEditing
 * @param {String}    updateId
 * @param {Object}    mutationData
 * @param {Object}    queryData
 * @param {String}    title
 */
const ProposalForm = ({ show, onClose, loading, isEditing, updateId, mutationData, queryData, title }) => {
  const { toast } = useToast();

  if (isEditing && loading) return null;

  const { data, initialValues } = useFormData(queryData);

  const formProps = {
    initialValues,
    mutationData,
    queryData,
    updateId,
    mutationType: MUTATION_TYPE.update,
  };

  const handleComplete = () => {
    toast.success(<FormattedMessage id="Missions.MissionForm.Success" />);
  };

  return (
    <ModalForm
      data={data}
      show={show}
      size={MODAL_SIZE.large}
      onClose={onClose}
      title={title}
      onCompleted={handleComplete}
      {...formProps}
    />
  );
};

ProposalForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  isEditing: PropTypes.bool,
  updateId: PropTypes.string,
  mutationData: PropTypes.object,
  queryData: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export default ProposalForm;
