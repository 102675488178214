import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Row } from "components/Containers";
import { fontSize, margins } from "style";

/**
 * Legend
 *
 * @param {Array}  data
 * @param {String} labelKeyName
 * @param {String} colorKeyName
 * @param {String} marginSize
 */
const Legend = ({ data, marginSize, ...props }) => (
  <StyledLegend gap="2rem" marginSize={marginSize}>
    {data?.map((item, index) => <LegendItem key={index} item={item} {...props} /> )}
  </StyledLegend>
);

/**
 * LegendItem
 * 
 * @param {Object} item 
 * @param {String} colorKeyName
 * @param {String} labelKeyName
 * 
 * @returns 
 */
const LegendItem = ({ item, colorKeyName, labelKeyName }) => (
  <div css={styles.legend(item[colorKeyName])}>
    {item[labelKeyName]}
  </div>
);

const StyledLegend = styled(Row)`
  margin-bottom: 3rem;
  justify-content: flex-start;
  font-size: ${fontSize.xxsmall};
  font-weight: 500;
`;

const styles = {
  legend: (color) => css`
    &:before {
      content: "";
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      background: ${color};
      border-radius: 100%;
      position: relative;
      top: 0.3rem;
    }
  `,
};

Legend.propTypes = {
  marginSize: PropTypes.string,
  data: PropTypes.array,
  labelKeyName: PropTypes.string,
  colorKeyName: PropTypes.string,
};

Legend.defaultProps = {
  marginSize: margins.normal,
  labelKeyName: "name",
  colorKeyName: "color",
};

LegendItem.propTypes = {
  item: PropTypes.object,
  colorKeyName: PropTypes.string,
  labelKeyName: PropTypes.string,
};

LegendItem.defaultProps = {
  labelKeyName: "name",
  colorKeyName: "color",
};

export { LegendItem };

export default Legend;
