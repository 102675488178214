/** @jsx jsx */
import { jsx, css, keyframes } from "@emotion/react";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import PropTypes from "prop-types";
import { colors } from "style";

/**
 * Shows a loading spinner
 *
 * @param {Number}    size
 * @param {Boolean}   refreshing
 */
const Refresher = ({ size, refreshing }) => (
  <div css={styles.container} data-loader>
    <div css={styles.ring(size, refreshing)}>
      <Icon type={ICON_TYPE.refresh} size={ICON_SIZE.small} color="inherit" />
    </div>
  </div>
);

const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  ring: (size, refreshing) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: ${refreshing ? colors.white : colors.grayAnatomyBase};
    background: ${refreshing ? colors.green : colors.grayAnatomyLight4};
    border-radius: 100%;
    width: ${size};
    height: ${size};
    animation: ${refreshing ? animationRotate : `none`} 3s linear infinite;
  `,
};

const animationRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
`;

Refresher.defaultProps = {
  size: `2rem`,
  refreshing: true,
};

Refresher.propTypes = {
  size: PropTypes.string,
  refreshing: PropTypes.bool,
};

export default Refresher;
