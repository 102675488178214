import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { LoginContainer, LoginForm } from "components/Login";
import backgroundImage from "assets/images/Faith-2000x1340-c-center.jpg";

/**
 * Login
 */
const Login = () => {
  const intl = useIntl();
  const selectedBanner = banners[0];

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Login.Title" })}</title>
      </Helmet>
      <LoginContainer banner={selectedBanner}>
        <LoginForm />
      </LoginContainer>
    </>
  );
};

const banners = [
  {
    title: "Built to support careers, not gigs.",
    label: "Faith Adekogbe",
    description: "Frontend Developer",
    cover: `url(${backgroundImage})`,
  },
];

export default Login;
