/** @jsx jsx */
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "style";

const fade = keyframes`
  0% {
    opacity:0.5;
  }
  50% {
    opacity:1;
  }
  100% {
    opacity:0.5;
  }
`;

/**
 * Shows a Image loader
 */
const ImageLoader = styled.div`
  width: ${(props) => props.width || "6.4rem"};
  height: ${(props) => props.height || "6.4rem"};
  display: inline-block;
  background: ${colors.grayAnatomyLight5};
  animation: ${fade} 1s ease infinite;
  border-radius: ${(props) => props.radius || "100%"};
`;

export default ImageLoader;
