import { gql } from "@apollo/client";

const ADD_MISSION_EVENT = gql`
  mutation AddMissionEvent($input: AddMissionEventInput!) {
    addMissionEvent(input: $input) {
      missionEvent {
        id
        name
        title
        description
        url
        recordingLink
        displayImageUrl
        startsAt
        startsAtWithTimezone
        fileName
        hidden
        timezone {
          name
          value
        }
      }
    }
  }
`;

const UPDATE_MISSION_EVENT = gql`
  mutation UpdateMissionEvent($input: UpdateMissionEventInput!) {
    updateMissionEvent(input: $input) {
      missionEvent {
        id
        name
        title
        description
        url
        recordingLink
        displayImageUrl
        startsAt
        startsAtWithTimezone
        fileName
        hidden
        timezone {
          name
          value
        }
      }
    }
  }
`;

export { ADD_MISSION_EVENT, UPDATE_MISSION_EVENT };
