import { gql } from "@apollo/client";
import { TEAM_METRICS_CORE_FIELDS } from "graphql/fragments";

const GET_TEAM_METRICS = gql`
  ${TEAM_METRICS_CORE_FIELDS}
  query GetTeamMetricsScores($teamId: ID!, $weeks: Int, $minimumScore: Float) {
    teamMetricsScores(teamId: $teamId, weeks: $weeks, minimumScore: $minimumScore) {
      clientSatisfaction {
        ...TeamMetricsFields
      }
      dynamics {
        ...TeamMetricsFields
      }
      performanceActivity {
        ...TeamMetricsFields
      }
      performanceEfficiency {
        ...TeamMetricsFields
      }
      performanceVolume {
        ...TeamMetricsFields
      }
      pulse {
        ...TeamMetricsFields
      }
    }
  }
`;

export { GET_TEAM_METRICS };
