import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * ChatHeader
 *
 * @param {function} onClose
 */
const ChatHeader = ({ onClose, ...props }) => (
  <div css={styles.container} {...props}>
    Conversation
    <div onClick={onClose}>
      <Icon type={ICON_TYPE.close} size={ICON_SIZE.xxlarge} color={"inherit"} />
    </div>
  </div>
);

const styles = {
  container: css`
    font-weight: 600;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    font-size: ${fontSize.medium};
    border-bottom: 1px ${colors.grayAnatomyLight4} solid;
  `,
};

ChatHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ChatHeader;
