/**
 * Get the month from a string date
 *
 * @param {Array}   arr
 * @param {String}  keyName
 *
 * @returns {any}
 */

export default function getNestedFieldInObject(arr, keyName) {
  const bits = keyName.split(".");
  let key = arr;

  bits.forEach((element) => {
    key = key[element];
  });

  return key;
}
