const ARCHETYPES = {
  contributor: {
    name: "Contributor",
    id: "CONTRIBUTOR",
    secondaryId: "contributor",
  },
  tech_lead: {
    name: "Tech Lead",
    id: "TECH_LEAD",
    secondaryId: "techlead",
  },
  squad_lead: {
    name: "Squad Lead",
    id: "SQUAD_LEAD",
    secondaryId: "squadlead",
  },
};

export default ARCHETYPES;
