import React, { useRef } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { margins } from "style";
import Form, { SIZE, TYPE } from "components/Form";
import { MUTATION_TYPE } from "hooks";
import { UPDATE_APPLICANT } from "graphql/mutations";
import { convertFromPlainText, getPlainText } from "components/Form/Editor";

/**
 * ApplicationPrivateNotesForm
 *
 * @param {Object} data
 * @param {Boolean} loading
 */
const ApplicationPrivateNotesForm = ({ data, loading, ...props }) => {
  const form = useRef(null);
  const handleSave = () => form.current.triggerSubmitForm();

  return (
    <Form
      ref={form}
      data={FORM_DATA(handleSave)}
      initialValues={{
        privateNotesJson: data?.privateNotesJson ?? convertFromPlainText(data?.privateNotesJson),
      }}
      mutationType={MUTATION_TYPE.update}
      mutationData={{
        mutationName: { update: UPDATE_APPLICANT },
      }}
      loading={loading}
      marginSize={margins.none}
      updateId={data?.id}
      noPadding
      {...props}
    />
  );
};

const FORM_DATA = (onSave) => [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        type: TYPE.editor,
        size: SIZE.xlarge,
        onChange: (name, value, handleOnChange) => {
          handleOnChange("privateNotes", getPlainText(value));
          handleOnChange(name, value);
        },
        properties: {
          name: "privateNotesJson",
          placeholder: "Add private notes",
          showFormattingOptions: true,
          onBlur: onSave,
        },
      },
    ],
  },
];

ApplicationPrivateNotesForm.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default ApplicationPrivateNotesForm;
