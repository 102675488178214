import { gql } from "@apollo/client";

const ADD_MEMBER_TO_PROFILE_LIST = gql`
  mutation AddMemberToProfileList($input: AddMemberToProfileListInput!) {
    addMemberToProfileList(input: $input) {
      profileLists {
        id
        title
        profiles {
          id
          name
          email
          avatarUrl
        }
        priceEstimation {
          sellingPriceEstimate {
            averageExperienceYears
            blendedHourlyRate
            breakdown
            currency
            missingProfiles
            monthlyPrice {
              low
              high
            }
          }
        }
      }
    }
  }
`;

export { ADD_MEMBER_TO_PROFILE_LIST };
