import { useSearchParams } from "react-router-dom";

/**
 * @description Designed to make it easier to interact with URL params
 */
export default function useParamFunctions() {
  const [searchParams, setSearchParams] = useSearchParams({});

  /**
   * @description Parse a base64 string from the URL and return an object
   */
  const paramParse = (key) => {
    try {
      return searchParams.get(key) && atob(searchParams.get(key)) ? JSON.parse(atob(searchParams.get(key))) : {};
    } catch (error) {
      return {};
    }
  };

  /**
   * @description Encode an object into a base64 string
   */
  const paramEncode = (items) => (items ? btoa(JSON.stringify(items)) : "");

  /**
   * @description Set the params by key and payload
   *
   * @param {String}  key
   * @param {Object}  payload
   */
  const setParams = (key, payload, encode=true) => {
    const value = paramParse(key);
    
    if (value === payload) return;

    setSearchParams((params) => {
      params.set(key, encode ? paramEncode(payload) : payload);
      return params;
    });
  };

  return {
    setParams,
    paramParse,
    paramEncode,
  };
}
