import { GET_LIST } from "graphql/queries";

const MOCK_GET_LISTS = 
  {
    request: {
      query: GET_LIST,
      variables: {
        orderBy: {},
        first: undefined,
        last: null,
        after: null,
        filters: {search: undefined}
      }
    },
    result: {
      data: {
        list: {
          nodes: [
          {
            id: 1,
            title: "LibraryItem",
          },
          {
            id: 2,
            title: "Mission",
          },
          {
            id: 3,
            title: "MissionRole",
          },
          {
            id: 4,
            title: "Partner",
          },
          {
            id: 5,
            title: "ProfileEducation",
          },
          {
            id: 6,
            title: "Test",
          },
          {
            id: 7,
            title: "ProfileLink",
          },
          {
            id: 8,
            title: "ProfileVacation",
          },
          {
            id: 9,
            title: "Test",
          },
          {
            id: 10,
            title: "Team",
          },
        ]
        }
      },
    },
  };

export { MOCK_GET_LISTS };
