import React from "react";
import PropTypes from "prop-types";
import { useAuth, useProfile } from "hooks";
import { GET_PROFILE } from "graphql/queries";
import { MessagesError } from "components/Messages";
import { ProfileWorkingSchedule, ProfileDaysOff, ProfileInterests, AVAILABILITY_COMPONENTS } from "components/Profile";
import Card from "components/Card";
import { Grid } from "components/Containers";

/**
 * Profile Box
 *
 * @params {Number}   id
 * @params {Object}   queryName
 * @params {String}   keyName
 */
const ProfileRate = ({ id, keyName, ...props }) => {
  const { canViewEditRate, canViewEditFullTimeEmployment } = useAuth();
  const { loading, data, error } = useProfile({ profileId: id });

  if ((!loading && !data) || error) {
    return <MessagesError messageId="Profile.ProfileBox.Error" />;
  }

  const key = `Availability${canViewEditRate ? "Rate" : ""}${canViewEditFullTimeEmployment ? "Employment" : ""}`;
  const Component = AVAILABILITY_COMPONENTS[key];

  return (
    <Grid>
      <Grid.col start={1} end={13}>
        <Card>
          <Component data={data && data[keyName]} loading={loading} isEditable={true} {...props} />
        </Card>
      </Grid.col>
      <Grid.col start={1} end={13}>
        <Card>
          <ProfileInterests data={data && data[keyName]} loading={loading} isEditable={true} {...props} />
        </Card>
      </Grid.col>
      <Grid.col start={1} end={13}>
        <Card>
          <ProfileWorkingSchedule data={data && data[keyName]} loading={loading} isEditable={true} {...props} />
        </Card>
      </Grid.col>
      <Grid.col start={1} end={13}>
        <Card>
          <ProfileDaysOff data={data && data[keyName]} loading={loading} isEditable={true} profileId={id} {...props} />
        </Card>
      </Grid.col>
    </Grid>
  );
};

ProfileRate.defaultProps = {
  queryName: GET_PROFILE,
  keyName: "profile",
};

ProfileRate.propTypes = {
  id: PropTypes.string,
  queryName: PropTypes.object,
  keyName: PropTypes.string,
};

export default ProfileRate;
