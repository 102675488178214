import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { MODAL_SIZE } from "components/Modal";
import { SIZE, TYPE } from "components/Form";
import { GET_MISSIONS, GET_PROFILES } from "graphql/queries";
import { ADD_MISSION_ROLE_INVITATION } from "graphql/mutations";
import { ModalForm } from "components/Modal";
import { useToast } from "hooks";
import { useQueryData } from "hooks";

/**
 * Invite member CTA
 *
 * @params {Boolean}   show
 * @params {Function}  onClose
 */
const MissionInviteForm = ({ show, onClose, ...props }) => {
  const { toast } = useToast();
  const [inviteData, setInviteData] = useState(false);
  const [missionRoles, setMissionRoles] = useState(false);

  const variables = { filters: { published: true } };
  const { data } = useQueryData({
    queryName: GET_MISSIONS,
    keyName: "missions",
    variables,
  });
  const [handleInvitation, { loading }] = useMutation(ADD_MISSION_ROLE_INVITATION, {
    refetchQueries: ["missionRoleInvitations"],
  });

  useEffect(() => {
    const missionObject = data?.missions?.nodes?.filter((mission) => mission.id === inviteData.mission);

    if (missionObject && missionObject.length > 0) {
      setMissionRoles(missionObject[0].roles?.map(({ name, id }) => ({ name, value: id })));
    } else {
      setMissionRoles([]);
    }
  }, [inviteData, data]);

  /**
   * Reset form data
   */
  const handleReset = () => {
    setInviteData(false);
    setMissionRoles(false);
    onClose(false);
  };

  /**
   * Submit to API and close window on success
   */
  const handleSubmit = (formData) => {
    const { missionRoleId, profileIds } = formData;
    handleInvitation({
      variables: {
        input: {
          missionRoleId,
          profileIds: profileIds.map((item) => item.id),
        },
      },
      onCompleted: (res) => {
        const accepted = res?.addMissionRoleInvitations?.missionRoleInvitations || [];
        const rejected = res?.addMissionRoleInvitations?.profilesNotInvited?.map((i) => i.name) || [];
        const acceptedCount = accepted?.length;
        const rejectedCount = rejected?.length;
        const rejectedList = rejected.join(", ");

        const resultMsg = (
          <FormattedMessage
            id={`Mission.MissionInviteMember.${rejectedCount > 0 ? "Errors" : "Success"}`}
            values={{
              acceptedCount,
              rejectedList,
            }}
          />
        );

        handleReset();

        return rejectedCount > 0 ? toast.error(resultMsg) : toast.success(resultMsg);
      },
      onError: () => {
        onClose(false);
        toast.error(<FormattedMessage id={"Missions.MissionInviteMember.Error"} />);
      },
    });
  };

  return (
    <>
      <ModalForm
        show={show}
        size={MODAL_SIZE.medium}
        onClose={handleReset}
        data={FORM_DATA(missionRoles)}
        title={<FormattedMessage id="Missions.MissionInviteMember.FormTitle" />}
        description={<FormattedMessage id="Missions.MissionInviteMember.FormDescription" />}
        onSubmit={handleSubmit}
        onChange={(data) => setInviteData(data)}
        loading={loading}
        initialValues={
          inviteData || {
            profileIds: props?.profile && [
              { name: `${props?.profile?.firstName} ${props?.profile?.lastName}`, id: props?.profile?.id },
            ],
          }
        }
        page={props.page}
      />
    </>
  );
};

const FORM_DATA = (missionRoles) => [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Mission",
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "mission",
          autoFocus: true,
          required: true,
          queryName: GET_MISSIONS,
          queryKeyName: "missions",
          queryFieldName: "missions",
          queryVariables: { filters: { published: true } },
          refetchAfterMutate: false,
          labelKey: "name",
          valueKey: "id",
          noOptionsMessage: "No Missions found",
        },
      },
      {
        label: "Role",
        type: TYPE.select,
        size: SIZE.xlarge,
        options: missionRoles,
        properties: {
          name: "missionRoleId",
          required: true,
          options: missionRoles,
          labelKey: "name",
          valueKey: "value",
        },
      },
      {
        label: "Member",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "profileIds",
          placeholder: "Type a member name...",
          queryName: GET_PROFILES,
          queryKeyName: "profiles",
          required: true,
          labelKey: "name",
          valueKey: "id",
          searchQuery: handleSearch,
        },
      },
    ],
  },
];

const handleSearch = (search) => ({ filters: { type: "MEMBER", search } });

MissionInviteForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  profile: PropTypes.object,
  page: PropTypes.string,
};

export default MissionInviteForm;
