import React from "react";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { PAGINATION_TYPE } from "components/Pagination";
import { UPDATE_MISSION_EVENT } from "graphql/mutations";
import { GET_EVENTS } from "graphql/queries";
import { useQueryData, useToast } from "hooks";

export default function useEvents({ skip = false, resultsPerPage, paginationType }) {
  const { toast } = useToast();
  const keyName = "missionEvents";
  const { hasNextPage, handleNextPage, hasPreviousPage, handlePreviousPage, loading, loadingMore, data } = useQueryData(
    {
      queryName: GET_EVENTS,
      keyName,
      paginationType: PAGINATION_TYPE[paginationType],
      resultsPerPage,
      skip,
    }
  );
  const [updateMissionEvent, { loading: updating }] = useMutation(UPDATE_MISSION_EVENT);

  const handleUpdateMissionEvent = (id, params) => {
    updateMissionEvent({
      variables: {
        input: {
          id,
          params,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id={"useEvents.EditSuccess"} />),
      onError: () => toast.error(<FormattedMessage id={"useEvents.EditError"} />),
    });
  };

  return {
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    handleUpdateMissionEvent,
    loading: loading || updating,
    loadingMore,
    paginationType,
    data: data?.missionEvents?.nodes,
    keyName,
  };
}
