/* eslint-disable react/display-name */
import React, { useState } from "react";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { METHOD } from "components/Form";
import { useMutateData, MUTATION_TYPE } from "hooks";

/**
 * Add a modal form to any component
 */
const WithModalForm =
  (Component, formProps) =>
  ({ onHideModal, ...props }) => {
    const { isDestructable: isDestructableProp, ...rest } = formProps;
    const [isDestructableState, setIsDestructable] = useState(isDestructableProp);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({});
    const [isEditing, setIsEditing] = useState({});
    const [updateId, setUpdateId] = useState();
    const [params, setParams] = useState({});
    const [parentKeyName, setParentKeyName] = useState();
    const [parentKeyValue, setParentKeyValue] = useState();
    const { handleDelete } = useMutateData(formProps.mutationData, MUTATION_TYPE.delete);

    /**
     * handleEdit
     *
     * @param (Object)  item    The data payload
     * @param (Object)  id      The database id to update
     * @param (Object)  params  Additional params to pass to form
     */
    const handleEdit = (item, id, params) => {
      setIsDestructable(isDestructableProp);
      setData(item);
      setUpdateId(id);
      setIsEditing(true);
      setShowModal(true);
      setParams(params);
    };

    /**
     * handleAdd
     *
     * @param (Object)  aKeyName    The key name for the update (optional)
     * @param (Object)  aKeyValue   The key value for the update (optional)
     *
     * aKeyName and aKeyValue are used to add a new entity with associated key name/value
     * Example: Adding a new entity with associated profileId as the keyName
     */
    const handleAdd = (aKeyName = null, aKeyValue = null) => {
      setUpdateId(null);
      setIsDestructable(false);
      setData({});
      setParentKeyName(aKeyName);
      setParentKeyValue(aKeyValue);
      setIsEditing(false);
      setShowModal(true);
    };

    const handleDestructive = () => {
      handleDelete({
        id: updateId,
        onError: () => console.log("There was an error"),
        onCompleted: () => setShowModal(false)
      });
    };

    const handleOnClose = () => {
      if (onHideModal) onHideModal();
      setShowModal(false);
    };

    return (
      <>
        <Component {...props} onEdit={handleEdit} onAdd={handleAdd} />
        <ModalForm
          show={showModal}
          mutationType={isEditing ? MUTATION_TYPE.update : MUTATION_TYPE.add}
          size={MODAL_SIZE.medium}
          updateId={updateId}
          onClose={handleOnClose}
          initialValues={data}
          method={METHOD.post}
          params={params}
          parentKeyName={parentKeyName}
          parentKeyValue={parentKeyValue}
          onDestructive={handleDestructive}
          isDestructable={isDestructableState}
          {...rest}
        />
      </>
    );
  };

export default WithModalForm;
