import React from "react";
import PropTypes from "prop-types";
import { RoleCard } from "components/Missions/Roles";
import Overlay from "components/Overlay";
import { PrimaryLink } from "components/Links";
import { LINKS } from "constants";
import { shortenText } from "utils";

/**
 * RoleOverlay
 *
 * @param {Object} mission
 * @param {Object} missionRole
 * @param {Boolean} withOverlay
 */
const RoleOverlay = ({ mission, missionRole, withOverlay, ...props }) => {
  const Link = () => (
    <PrimaryLink to={LINKS.mission_role(mission?.uuid, missionRole?.slug)} routerEnabled>
      {shortenText(missionRole?.name, 80)}
    </PrimaryLink>
  );

  if (!withOverlay) {
    return <Link />;
  }

  return (
    <Overlay
      asCardOverlay
      overlay={<RoleCard data={{ ...missionRole, mission }} hideApplyButton />}
      trigger={<Link />}
      {...props}
    />
  );
};

RoleOverlay.propTypes = {
  mission: PropTypes.object.isRequired,
  missionRole: PropTypes.object.isRequired,
  withOverlay: PropTypes.bool,
};

export default RoleOverlay;
