import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import { usePublicList } from "hooks";
import { LINKS } from "constants/index";
import { Grid, PageWrapper } from "components/Containers";
import PageTitle from "components/PageTitle";
import Card from "components/Card";
import NoResults from "components/Messages/NoResults";
import { NetworkList } from "components/Network";
import { PrimaryLink } from "components/Links";
import { FormattedMessage } from "react-intl";

/**
 * PublicList
 */
const PublicList = () => {
  const perPage = 20;
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isCardView, setIsCardView] = useState(false);
  const { list, loading, handlePageChange } = usePublicList({ id, perPage });

  useEffect(() => {
    if (list?.profiles?.nodes) {
      setData({
        data: list.profiles.nodes,
        totalCount: list.profiles.totalCount,
        currentOffset: list.profiles.currentOffset,
        currentPage: list.profiles.currentPage,
      });
    }
  }, [list]);

  return (
    <PageWrapper>
      <Helmet>
        <title>{list?.title}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            title={list?.title}
            loading={loading}
            actionBar={
              <PrimaryLink onClick={() => setIsCardView(!isCardView)} withMoreIcon>
                <FormattedMessage id={`PublicList.ToggleView.${isCardView ? "CardView" : "ListView"}.Title`} />
              </PrimaryLink>
            }
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          {!loading && data?.profiles?.length === 0 ? (
            <Card>
              <NoResults />
            </Card>
          ) : (
            <NetworkList
              {...data}
              loading={loading}
              resultsPerPage={perPage}
              handlePageChange={handlePageChange}
              onItemClick={(profile) => window.open(LINKS.public_profile(profile.uuid), "_blank")}
              isCardView={isCardView}
              forceDisplayRate
            />
          )}
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default PublicList;
