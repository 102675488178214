import React from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { colors, fontSize } from "style";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * ProfileFlag
 *
 * @param {number} count
 */
const ProfileFlag = ({ count, ...props }) => {
  if (isNaN(count) || count == 0) return null;

  return (
    <div css={styles.container} {...props}>
      <div css={styles.icon_container}>
        <Icon type={ICON_TYPE.flag} size={ICON_SIZE.xlarge} color={colors.red} />
      </div>
      <span>
        <FormattedMessage
          id="Profile.ProfileFlag.Title"
          values={{
            count: count > 1 ? `x${count}` : ``,
          }}
        />
      </span>
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0rem;
    color: ${colors.grayAnatomyLight1};
    font-size: ${fontSize.small};
    font-weight: 500;
  `,
  icon_container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
  `,
};

ProfileFlag.propTypes = {
  count: PropTypes.number.isRequired,
};

export default ProfileFlag;
