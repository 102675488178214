const colors = {
  purpleRainBase: "#554cc5",
  purpleRainLight1: "#8882d8",
  purpleRainLight2: "#bebaf5",
  purpleRainLight3: "#e4e2ff",
  purpleRainLight4: "#8787a8", // Changed - use for secondary text blocks
  purpleRainLight5: "#eeedff",
  purpleRainLight6: "#736bd5",
  purpleRainDark1: "#383394",
  purpleRainDark2: "#26226b",
  purpleRainDark3: "#595677",
  purpleRainDark4: "#48455c",
  purpleRainDark5: "#343242",
  purpleRainDark6: "#2f2d50",
  grayAnatomyBase: "#69688f",
  grayAnatomyBase2: "#8988a8",
  grayAnatomyDark: "#363541",
  grayAnatomyLight1: "#a0a0b4",
  grayAnatomyLight2: "#d3d3db",
  grayAnatomyLight3: "#e5e5ec",
  grayAnatomyLight4: "#f0f0f4",
  grayAnatomyLight5: "#f7f7f9",
  grayAnatomyLight6: "#7878a1",
  grayAnatomyLight7: "#fcfcfc",
  blueVelvetBase: "#3BA2E7",
  blueVelvetLight2: "#D4ECFC",
  blueVelvetLight3: "#edfcff",
  blueVelvetLight4: "#f4f6fb",
  orangeCountyBase: "#ff7e75",
  orangeCountyLight1: "#ffbab5",
  orangeCountyLight3: "#ffbab5",
  orangeCountyLight4: "#ffeae8",
  orangeCountyDark2: "#b83025",
  orangeCountyDark3: "#c54c67",
  pinkPantherBase: "#e64b91",
  pinkPantherLight1: "#fae6ef",
  lightYellow: "#faf0cc",
  yellow: "#e7c85f",
  darkYellow: "#f9c900",
  red: "#d7526b",
  lightRed: "#fae5e5",
  mediumRed: "#cca5a5",
  darkRed: "#bb4141",
  error: "#FF2C2C",
  primary: "#FF2C2C", // We should delete this - need to check impact
  secondary: "#26226b",
  warning: "#FFB600",
  ivory: "#f6f6f6",
  pictonBlue: "#3ba3e7",
  lightgrey: "#F0EFEF",
  warmgrey: "#DDDFE2",
  green: "#22ad96",
  lightGreen: "#dcf5e5",
  mediumGreen: "#bce8e1",
  darkGreen: "#1c695c",
  mediumGrey: "#A8B0C0",
  greyBase: "#7F8A9C",
  greyLight: "#D8D8D8",
  darkGrey: "#ebebeb",
  white: "#FFF",
  black: "#000",
  electricblue: "#2788F2",
  skyblue: "#66AFFF",
  lightBlue: "#2887f2",
  lightTableBg: "#EFEFEF",
  lightTableBorder: "#dbdbdb",
  normalGry: "#DADEE3",
  greyBackground: "#222237",
  peach: "#EF6A70",
  darkPeach: "#E05A63",
  lightBrown: "#f9dda9",
  lightestBlue: "#bfe1fa",
  lightPink: "#ffbdbd",
  lightBrownGrey: "#c0cfdf",
  lightGrey: "#f5f5f5",
  cyan: "#2ac1b0",
  lightStone: "#f4f7fa",
  mediumStone: "#bfd7e7",
  darkStone: "#25638e",
};

const chart_colors = {
  sequential: [
    //"#554cc6",
    "#8e87e3",
    "#b3aef2",
    "#cfccf8",
    "#d0e6fd",
    "#aed1f6",
    "#6aa4df",
    "#3c85d0",
    "#29b2c3",
    "#6ccfda",
    "#b4e9ef",
    "#e5f9fb",
    "#fbcfe2",
    "#e9a8c4",
    "#dc6b9c",
    "#c63273",
  ],
  categorical: [
    "#554cc5",
    "#3c85d0",
    "#29b2c3",
    "#e96fa4",
    "#e74850",
    "#e7b549",
    "#80b550",
    "#cfccf8",
    "#b4e9ef",
    "#fab2b6",
    "#efd892",
  ],
  sequential_opaque: [
    "#554cc6",
    "#7369e9",
    "#9b7df5",
    "#d17cf8",
    "#e67cdb",
    "#d958c5",
    "#b33597",
    "#b3356a",
    "#e15768",
    "#e97281",
    "#e97d72",
    "#e6695c",
    "#d95b4e",
    "#cd593e",
  ],
  sequential_light: [
    "#c19ff0",
    "#b3aef2",
    "#cfccf8",
    "#d0e6fd",
    "#afdef6",
    "#8ecce1",
    "#7bcbd0",
    "#48becc",
    "#6ddad4",
    "#98d8cb",
    "#8ddeb9",
    "#72ce97",
    "#7ec07f",
    "#a6cb8d",
    "#c4d1a0",
    "#d9cf99",
  ],
  intensity: ["#f17d7d", "#f6c8b4", "#f0e7c5", "#90ddae", "#4dbe96"],
};

const gradients = {
  missionCircularGradient: `radial-gradient(circle at 7% 5%, ${colors.purpleRainLight1}, ${colors.orangeCountyBase} 53%, ${colors.purpleRainBase} 98%)`,
  missionLinearGradient: `linear-gradient(to right, ${colors.purpleRainBase}, ${colors.pinkPantherBase})`,
  redLinearGradient: `linear-gradient(0.25turn, ${colors.red} 0%, ${colors.orangeCountyBase} 98%)`,
  orangeLinearGradient: `linear-gradient(166deg, ${colors.orangeCountyBase} 0%, ${colors.red} 38%)`,
  errorBorderGradient: `linear-gradient(0.25turn, ${colors.orangeCountyBase}, ${colors.orangeCountyLight1})`,
  errorBackgroundGradient: `linear-gradient(0.25turn, ${colors.orangeCountyLight3}, #fff)`,
  sideBarGradient: `linear-gradient(345deg, rgb(48, 48, 73) 63.45%, #393667)`,
  lightBluePinkGradient: `linear-gradient(66deg, #e6f2fe, #f0ddfb);`,
};

export { colors, gradients, chart_colors };
