import { gql } from "@apollo/client";

const GET_PROMPTS = gql`
  query prompts($first: Int, $last: Int, $after: String, $before: String, $paginationInfo: PaginationInfo) {
    prompts(first: $first, last: $last, after: $after, before: $before, paginationInfo: $paginationInfo) {
      totalCount
      currentPage
      perPage
      nodes {
        id
        title
        content
        isActive
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export { GET_PROMPTS };
