import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { colors, fontSize } from "style";
import { Row } from "components/Containers";
import { Toggle } from "components/Form";
import { useConnection } from "hooks";

/**
 * SignalStatusButton
 *
 * @param {Boolean} enabledProp
 * @param {String}  connectionId
 */
const SignalStatusButton = ({ connectionId, isEnabled }) => {
  const [enabled, setEnabled] = useState(isEnabled);
  const { updateConnection, updating } = useConnection({ shouldSkip: true });

  useEffect(() => {
    if (enabled !== isEnabled) {
      updateConnection(connectionId, { enabled: enabled });
    }
  }, [enabled]);

  if (!connectionId) return null;

  return (
    <Row>
      <span css={styles.label}>{<FormattedMessage id="Active" />}</span>
      <Toggle value={enabled} onChange={() => setEnabled(!enabled)} disabled={updating} />
    </Row>
  );
};

const styles = {
  label: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyLight6};
    font-weight: 500;
  `,
};

SignalStatusButton.propTypes = {
  connectionId: PropTypes.string,
  isEnabled: PropTypes.boolean,
};

export default SignalStatusButton;
