import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { PrimaryLink } from "components/Links";
import { TIMELINE_EVENTS, RESOURCE_TYPES, LINKS, COMMENTABLE_TYPES } from "constants/index";
import { UserFullName } from "components/User";
import { Col, Row } from "components/Containers";
import { ExpandIcon, ICON_SIZE } from "components/Icon";
import TimelineEventDataChange from "./TimelineEventDataChange";
import styled from "@emotion/styled";
import { colors, fontSize } from "style";
import { RoleLink } from "components/Missions/Roles";

/**
 * TimelineEventTitle
 *
 * @param {String} keyName
 * @param {Object} user
 * @param {Object} impersonator
 * @param {Object} payload
 * @param {String} createdAt
 */
const TimelineEventTitle = ({ keyName, user: userProp, impersonator, payload, createdAt, profileId }) => {
  const { resourceId, resourceType, resourceContext } = payload;
  const [showDetails, setShowDetails] = useState(false);
  const userProfileId = parseInt(userProp?.profile?.id);
  const userAffectedOwnProfile = resourceType === RESOURCE_TYPES.profile && resourceId === userProfileId;
  const [titleKey, setKey] = useState(keyName);
  const {
    user,
    role,
    date,
    name,
    url,
    comment,
    note,
    description,
    acceptedBy,
    status,
    degreeName,
    schoolName,
    linkType,
    weekday,
    vacationEndDate,
    vacationStartDate,
    companyName,
    experienceRole,
    listName,
    profileFullName,
    timeCardMonthPeriod,
    timeCardStatus,
    activityDate,
    activityType,
    teamName,
    timeLogged,
    challengeName,
    interviewName,
    interviewerFullName,
  } = formatData(resourceType, resourceContext, resourceId, profileId, userProp, impersonator, createdAt, payload);
  const resourceKeys = ["resourceId", "resourceType", "resourceContext"];
  const payloadKeys = Object.keys(payload);

  useEffect(() => {
    setKey(getKey(keyName, payload));
  }, [keyName, payload]);

  return (
    <>
      <FormattedMessage
        id={`Timeline.TimelineEvent.${userAffectedOwnProfile ? "Member." : ""}${titleKey}`}
        values={{
          name,
          role,
          // FIXME: Missing uuid's for role and mission
          mission: <PrimaryLink to={LINKS.mission()}>{payload?.missionName}</PrimaryLink>,
          date,
          user,
          details: (
            <StyledRow gap="0">
              <ExpandIcon isExpanded={showDetails} size={ICON_SIZE.small} />
              <ShowMoreLink onClick={() => setShowDetails(!showDetails)}>
                {showDetails ? "Hide" : "Show"} details
              </ShowMoreLink>
            </StyledRow>
          ),
          url,
          note,
          comment,
          description,
          acceptedBy,
          status,
          degreeName,
          schoolName,
          linkType,
          weekday,
          vacationEndDate,
          vacationStartDate,
          companyName,
          experienceRole,
          listName,
          profileFullName,
          timeCardMonthPeriod,
          timeCardStatus,
          activityDate,
          activityType,
          teamName,
          timeLogged,
          challengeName,
          interviewName,
          interviewerFullName,
        }}
      />
      {showDetails && (
        <StyledCol gap="0.25rem">
          {payloadKeys
            .filter((item) => resourceKeys.indexOf(item) === -1)
            .map((item) => (
              <TimelineEventDataChange key={item} payload={payload} item={item} />
            ))}
        </StyledCol>
      )}
    </>
  );
};

const ShowMoreLink = styled.span`
  font-size: ${fontSize.xxsmall};
  color: ${colors.grayAnatomyLight1};
  cursor: pointer;
`;

const StyledRow = styled(Row)`
  display: inline-flex;
  margin-left: 1rem;
`;

const StyledCol = styled(Col)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  border-left: 2px ${colors.grayAnatomyLight5} solid;
`;

/**
 * Formats the data in resourceContext into React elements
 *
 * @param {String} resourceType
 * @param {Object} resourceContext
 * @param {Object} userProp
 * @param {Object} impersonator
 * @param {String} createdAt
 *
 * @returns Object
 */
function formatData(resourceType, resourceContext, resourceId, profileId, userProp, impersonator, createdAt, payload) {
  const {
    user,
    role,
    url,
    commentableType,
    description,
    acceptedBy,
    status,
    degreeName,
    schoolName,
    linkType,
    weekday,
    vacationEndDate,
    vacationStartDate,
    companyName,
    experienceRole,
    listName,
    profile,
    timeCardMonthPeriod,
    timeCardStatus,
    activityDate,
    activityType,
    teamName,
    timeLogged,
    challengeName,
    interviewName,
    interviewer,
  } = getDataByResourceType(resourceType, resourceContext, payload, userProp);
  const adminUser = {
    name: <FormattedMessage id="Global.MissionUsername" />,
  };

  return {
    user: impersonator?.id ? (
      <>
        <UserFullName data={user} />
        <span> (impersonated by {<UserFullName data={impersonator} />})</span>
      </>
    ) : (
      <UserFullName data={user} />
    ),
    role: <RoleLink mission={role?.mission} missionRole={role} withOverlay />,
    date: <b>{moment(createdAt).fromNow()}</b>,
    name: <UserFullName data={userProp?.profile || userProp || adminUser} />,
    url: <PrimaryLink to={url}>Calendly</PrimaryLink>,
    note: <PrimaryLink to={LINKS.note(profileId, resourceId)}>note</PrimaryLink>,
    comment: commentableType == COMMENTABLE_TYPES.comment && (
      <FormattedMessage
        id={`Timeline.TimelineEvent.CommentCreated.partial`}
        values={{
          comment: <PrimaryLink to={LINKS.note(profileId, resourceContext.commentable?.id)}>comment</PrimaryLink>,
        }}
      />
    ),
    acceptedBy: acceptedBy?.id && (
      <FormattedMessage
        id="Timeline.TimelineEvent.DeelTaskAcceptedBy"
        values={{ name: <UserFullName data={acceptedBy} /> }}
      />
    ),
    description: description && (
      <FormattedMessage id="Timeline.TimelineEvent.DeelTaskDescription" values={{ description }} />
    ),
    profileFullName: <UserFullName data={profile} />,
    interviewerFullName: <UserFullName data={interviewer} />,
    degreeName,
    schoolName,
    linkType,
    status,
    weekday,
    vacationEndDate,
    vacationStartDate,
    companyName,
    experienceRole,
    listName,
    timeCardMonthPeriod,
    timeCardStatus,
    activityDate,
    activityType,
    teamName,
    timeLogged,
    challengeName,
    interviewName,
  };
}

/**
 * Get the formatted message keyname for titles
 *
 * @param {*} kName
 * @param {*} payload
 *
 * @returns String
 */
const getKey = (kName, payload) => {
  switch (kName) {
    // Profile updates
    case TIMELINE_EVENTS.profileUpdated:
      // Profile archived
      if (payload?.archivedSince?.slice(-1)) {
        return "UserArchived";
        // Profile approved
      } else if (payload?.approvedById?.slice(-1)) {
        return "UserApproved";
      }
      return kName;

    case TIMELINE_EVENTS.missionRoleApplicantCreated:
      return `MissionRole:${payload?.state}`;

    case TIMELINE_EVENTS.missionRoleApplicantUpdated:
      return `MissionRole:${payload?.state.slice(-1)}`;

    case TIMELINE_EVENTS.missionRoleInvitationCreated:
      return `MissionRoleInvited`;

    case TIMELINE_EVENTS.missionInterviewCreated:
      return `MissionInterviewCreated`;

    case TIMELINE_EVENTS.missionInterviewUpdated:
      return `MissionInterviewUpdated`;

    case TIMELINE_EVENTS.missionInterviewDestroyed:
      return `MissionInterviewDestroyed`;

    case TIMELINE_EVENTS.commentCreated:
      return `CommentCreated`;

    case TIMELINE_EVENTS.commentUpdated:
      return `CommentUpdated`;

    case TIMELINE_EVENTS.commentDestroyed:
      return `CommentDestroyed`;

    case TIMELINE_EVENTS.deelTaskCreated:
      return `DeelTaskCreated`;

    case TIMELINE_EVENTS.deelTaskUpdated:
      return `DeelTaskUpdated`;

    case TIMELINE_EVENTS.profileEducationCreated:
      return `ProfileEducationCreated`;

    case TIMELINE_EVENTS.profileEducationUpdated:
      return `ProfileEducationUpdated`;

    case TIMELINE_EVENTS.profileEducationDestroyed:
      return `ProfileEducationDestroyed`;

    case TIMELINE_EVENTS.profileLinkCreated:
      return `ProfileLinkCreated`;

    case TIMELINE_EVENTS.profileLinkUpdated:
      return `ProfileLinkUpdated`;

    case TIMELINE_EVENTS.profileLinkDestroyed:
      return `ProfileLinkDestroyed`;

    case TIMELINE_EVENTS.profileScheduleCreated:
      return `ProfileScheduleCreated`;

    case TIMELINE_EVENTS.profileScheduleUpdated:
      return `ProfileScheduleUpdated`;

    case TIMELINE_EVENTS.profileScheduleDestroyed:
      return `ProfileScheduleDestroyed`;

    case TIMELINE_EVENTS.profileVacationCreated:
      return `ProfileVacationCreated`;

    case TIMELINE_EVENTS.profileVacationUpdated:
      return `ProfileVacationUpdated`;

    case TIMELINE_EVENTS.profileVacationDestroyed:
      return `ProfileVacationDestroyed`;

    case TIMELINE_EVENTS.profileWorkExperienceCreated:
      return `ProfileWorkExperienceCreated`;

    case TIMELINE_EVENTS.profileWorkExperienceUpdated:
      return `ProfileWorkExperienceUpdated`;

    case TIMELINE_EVENTS.profileWorkExperienceDestroyed:
      return `ProfileWorkExperienceDestroyed`;

    case TIMELINE_EVENTS.profileListCreated:
      return `ProfileListCreated`;

    case TIMELINE_EVENTS.profileListUpdated:
      return `ProfileListUpdated`;

    case TIMELINE_EVENTS.profileListDestroyed:
      return `ProfileListDestroyed`;

    case TIMELINE_EVENTS.timeCardCreated:
      return `TimeCardCreated`;

    case TIMELINE_EVENTS.timeCardUpdated:
      return `TimeCardUpdated`;

    case TIMELINE_EVENTS.timeCardDestroyed:
      return `TimeCardDestroyed`;

    case TIMELINE_EVENTS.timeSlipCreated:
      return `TimeSlipCreated`;

    case TIMELINE_EVENTS.timeSlipUpdated:
      return `TimeSlipUpdated`;

    case TIMELINE_EVENTS.timeSlipDestroyed:
      return `TimeSlipDestroyed`;

    case TIMELINE_EVENTS.coderbyteChallengeResponseCreated:
      return `CoderbyteChallengeResponseCreated`;

    case TIMELINE_EVENTS.coderbyteChallengeResponseUpdated:
      return `CoderbyteChallengeResponseUpdated`;

    case TIMELINE_EVENTS.coderbyteChallengeResponseDestroyed:
      return `CoderbyteChallengeResponseDestroyed`;

    case TIMELINE_EVENTS.interviewResponseCreated:
      return `InterviewResponseCreated`;

    case TIMELINE_EVENTS.interviewResponseUpdated:
      return `InterviewResponseUpdated`;

    case TIMELINE_EVENTS.interviewResponseDestroyed:
      return `InterviewResponseDestroyed`;

    case TIMELINE_EVENTS.assignmentCreated:
      return `AssignmentCreated`;

    case TIMELINE_EVENTS.assignmentUpdated:
      return `AssignmentUpdated`;

    case TIMELINE_EVENTS.assignmentDestroyed:
      return `AssignmentDestroyed`;

    default:
      return kName;
  }
};

/**
 * Gets data structure based on resource type
 *
 * @param {String} resourceType
 * @param {Object} resourceContext
 *
 * @returns Object
 */
function getDataByResourceType(resourceType, resourceContext, payload, userProp) {
  switch (resourceType) {
    case RESOURCE_TYPES.assignment:
      return {
        user: userProp?.profile ? userProp?.profile : userProp,
        teamName: resourceContext?.team?.name,
      };
    case RESOURCE_TYPES.profile:
      return {
        user: resourceContext,
      };
    case RESOURCE_TYPES.missionRoleApplicant:
      return {
        user: resourceContext?.profile,
        role: resourceContext?.missionRole,
      };
    case RESOURCE_TYPES.missionRoleInvitation:
      return {
        user: resourceContext?.profile,
        role: resourceContext?.missionRole,
      };
    case RESOURCE_TYPES.missionInterviewCreated:
      return {
        user: userProp?.profile ? userProp?.profile : userProp,
        interviewer: resourceContext?.interviewer,
        role: resourceContext?.missionRole,
        url: payload?.calendlyLink,
        date: payload?.createdAt,
      };
    case RESOURCE_TYPES.comment:
      return {
        user: resourceContext?.user,
        commentableType: resourceContext.commentable?.type,
      };
    case RESOURCE_TYPES.deelInvoicesTask:
      return {
        user: resourceContext?.assignment?.profile,
        acceptedBy: resourceContext?.acceptedBy,
        description: resourceContext?.description,
        status: resourceContext?.status,
      };
    case RESOURCE_TYPES.profileEducation:
      return {
        user: userProp?.profile ? userProp?.profile : userProp,
        schoolName: resourceContext?.schoolName,
      };
    case RESOURCE_TYPES.profileLink:
      return {
        user: userProp?.profile ? userProp?.profile : userProp,
        linkType: resourceContext?.linkType,
      };
    case RESOURCE_TYPES.profileSchedule:
      return {
        user: userProp?.profile ? userProp?.profile : userProp,
        weekday: resourceContext?.weekday,
      };
    case RESOURCE_TYPES.profileVacation:
      return {
        user: userProp?.profile ? userProp?.profile : userProp,
        vacationStartDate: resourceContext?.startDate,
        vacationEndDate: resourceContext?.endDate,
      };
    case RESOURCE_TYPES.profileWorkExperience:
      return {
        user: userProp?.profile ? userProp?.profile : userProp,
        companyName: resourceContext?.companyName,
        experienceRole: resourceContext?.role,
      };
    case RESOURCE_TYPES.profileList:
      return {
        user: userProp?.profile ? userProp?.profile : userProp,
        listName: resourceContext?.list?.title,
        profile: resourceContext?.profile,
      };
    case RESOURCE_TYPES.coderbyteChallengeResponse:
      return {
        user: userProp?.profile ? userProp?.profile : userProp,
        challengeName: resourceContext?.challenge?.name,
      };
    case RESOURCE_TYPES.interviewResponse:
      return {
        user: userProp?.profile ? userProp?.profile : userProp,
        interviewName: resourceContext?.interview?.name,
      };
    case RESOURCE_TYPES.timeCard: {
      let date = new Date(resourceContext?.monthPeriod);
      let formattedDate = `${date.toLocaleString("default", { month: "long" })}, ${date.getFullYear()}`;

      return {
        user: userProp?.profile ? userProp?.profile : userProp,
        timeCardMonthPeriod: formattedDate,
        timeCardStatus: resourceContext?.status,
      };
    }
    case RESOURCE_TYPES.timeSlip: {
      let monthPeriod = new Date(resourceContext?.timeCard?.monthPeriod);
      let formattedMonthPeriod = `${monthPeriod.toLocaleString("default", {
        month: "long",
      })}, ${monthPeriod.getFullYear()}`;

      return {
        user: userProp?.profile ? userProp?.profile : userProp,
        activityDate: resourceContext?.activityDate,
        activityType: resourceContext?.activityType,
        timeLogged: resourceContext?.timeLogged,
        teamName: resourceContext?.team?.name,
        timeCardMonthPeriod: formattedMonthPeriod,
        timeCardStatus: resourceContext?.timeCard?.status,
      };
    }

    default:
      return {};
  }
}

TimelineEventTitle.propTypes = {
  keyName: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  impersonator: PropTypes.object,
  payload: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  profileId: PropTypes.string,
};

export default TimelineEventTitle;
