import { colors, fontSize } from "style";
import styled from "@emotion/styled";

/**
 * Score
 */
const Score = styled.span`
  font-weight: 500;
  font-size: ${fontSize.xsmall};
  background: ${(props) => (props.score < 60 ? colors.grayAnatomyBase : colors.green)};
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  white-space: nowrap;
  width: 5rem;
  display: inline-block;
  text-align: center;
`;

export default Score;
