import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { useToast } from "hooks";
import { useMutation } from "@apollo/client";
import { GET_BADGES } from "graphql/queries";
import { UPDATE_PROFILE } from "graphql/mutations";
import { MODAL_SIZE, ModalForm } from "components/Modal";
import { METHOD, SIZE, TYPE } from "components/Form";
import { PAGES } from "constants/index";

const ProfileBadgesForm = ({ data, show, onClose }) => {
  const { toast } = useToast();

  const [updateProfile, { loading: updatingProfile }] = useMutation(UPDATE_PROFILE);

  const handleSubmit = (formData) => {
    const { badges } = formData;
    handleUpdateProfile(data?.profile?.id, badges);
  };

  const handleUpdateProfile = (id, badges) => {
    updateProfile({
      variables: {
        input: {
          id: id,
          params: {
            badges: badges,
          },
        },
      },
      onCompleted: () => {
        onClose(false);
        toast.success(<FormattedMessage id={"Profile.ProfileBadges.Success"} />);
      },
      onError: () => {
        onClose(false);
        toast.error(<FormattedMessage id={"Profile.ProfileBadges.Error"} />);
      },
    });
  };

  return (
    <ModalForm
      show={show}
      size={MODAL_SIZE.medium}
      onClose={() => onClose(false)}
      data={FORM_DATA}
      title={<FormattedMessage id="Profile.ProfileBadges.AddBadges" />}
      onSubmit={handleSubmit}
      loading={updatingProfile}
      method={METHOD.post}
      page={PAGES.badges}
      initialValues={{
        badges: data?.profile?.badges?.map((item) => ({
          name: item.name,
          id: item.id,
        })),
      }}
    />
  );
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
    `,
    items: [
      {
        label: "Badges",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "badges",
          queryName: GET_BADGES,
          placeholder: "Add badges...",
          queryKeyName: "badges",
          labelkey: "name",
          secondaryLabelKey: "description",
          minLength: 0,
          autoFocus: true,
          defaultOpen: true,
        },
      },
    ],
  },
];

ProfileBadgesForm.propTypes = {
  data: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ProfileBadgesForm;
