import { gql } from "@apollo/client";
import {
  CORE_MISSION_FIELDS,
  CORE_MISSION_ROLE_FIELDS
} from "graphql/fragments";

const GET_MISSION_ROLE = gql`
  ${CORE_MISSION_FIELDS}
  ${CORE_MISSION_ROLE_FIELDS}
  query GetMissionRole($slug: ID!) {
    missionRole(slug: $slug) {
      ...MissionRoleFields
      mission {
        ...MissionFields
      }
      missionRoleApplicant {
        message
        profile {
          id
          description
          archetype
        }
        state
        appliedAt
      }
      applicantApplied
    }
  }
`;

export { GET_MISSION_ROLE };
