import { useState, useCallback } from "react";
import { PUBLIC_LIST } from "graphql/queries";
import { useQuery } from "@apollo/client";

export default function usePublicList({ id, perPage }) {
  const [page, setPage] = useState(1);

  const { data, loading, error } = useQuery(PUBLIC_LIST, {
    variables: { id, paginationInfo: { page, perPage } },
    skip: !id,
  });

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  return {
    list: data?.publicList,
    loading,
    error,
    handlePageChange,
  };
}
