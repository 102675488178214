import { gql } from "@apollo/client";

const ARCHIVE_MEMBER_PROFILE = gql`
mutation ArchiveProfile($input: ArchiveProfileInput!) {
    archiveProfile(input: $input) {
      profile {
        id
      }
    }
  }
`;

export { ARCHIVE_MEMBER_PROFILE };
