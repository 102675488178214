/** @jsx jsx */
import styled from "@emotion/styled";
import { colors, fontSize } from "style";
import { BUTTON_SIZE } from "./Primary";

/**
 * Primary
 */
const DestructableButton = styled.button`
  height: ${(props) => props.size || BUTTON_SIZE.medium};
  min-height: ${(props) => props.size || BUTTON_SIZE.medium};
  font-size: ${fontSize.small};
  font-weight: 600;
  background: ${colors.red};
  color: #fff;
  border-radius: 0.6rem;
  border: solid 2px ${colors.red};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.2rem;
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;
  gap: 0.5rem;

  ${(props) =>
    props.size === BUTTON_SIZE.small &&
    `
    padding: 0 1rem;
    font-size: ${fontSize.xsmall};
    font-weight:500;
    border-radius: 0.5rem;
  `}

  &:hover:not(:disabled) {
    color: #fff;
  }

  &:disabled {
    color: ${colors.grayAnatomyLight2};
    background: ${colors.grayAnatomyLight1};
    border-color: ${colors.grayAnatomyLight1};
    box-shadown: none;
  }
`;

export default DestructableButton;
