import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { fontSize } from "style";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { PrimaryLink } from "components/Links";

/**
 * BackLink
 */
const BackLink = ({ children, ...props }) => (
  <PrimaryLink css={style} {...props}>
    <Icon size={ICON_SIZE.medium} type={ICON_TYPE.arrowLeft} color="inherit" />
    {children}
  </PrimaryLink>
);

const style = css`
  font-size: ${fontSize.small};
`;

BackLink.propTypes = {
  children: PropTypes.any,
};

export default BackLink;
