import { gql } from "@apollo/client";

const ADD_DAILY_REPORT_SETTINGS = gql`
  mutation AddDailyReportSettings($input: AddDailyReportSettingsInput!) {
    addDailyReportSettings(input: $input) {
      team {
        dailyReportUuid
      }
    }
  }
`;

const UPDATE_DAILY_REPORT_SETTINGS = gql`
  mutation UpdateDailyReportSettings($input: UpdateDailyReportSettingsInput!) {
    updateDailyReportSettings(input: $input) {
      team {
        dailyReportUuid
      }
    }
  }
`;

const CHANGE_DAILY_REPORT_STATUS = gql`
  mutation ChangeDailyReportStatus($input: ChangeDailyReportStatusInput!) {
    changeDailyReportStatus(input: $input) {
      team {
        dailyReportUuid
      }
    }
  }
`;

export { ADD_DAILY_REPORT_SETTINGS, UPDATE_DAILY_REPORT_SETTINGS, CHANGE_DAILY_REPORT_STATUS };
