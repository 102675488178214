import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "components/Containers";
import { Paginate } from "components/Pagination";
import Card from "components/Card";
import { MessagesNoResults } from "components/Messages";

/**
 * CardList
 *
 * @description Displays a list of cards with pagination, loading and empty message
 *
 * @param {Array}     data
 * @param {Boolean}   loading
 * @param {Number}    cols
 * @param {Function}  renderer
 * @param {Number}    resultsPerPage
 * @param {Boolean}   paginate
 * @param {Object}    rendererProps
 * @param {Object}    messageProps
 * @param {Object}    paginationProps
 * @param {Node}      children
 */
const CardList = ({
  data,
  loading,
  cols,
  renderer,
  resultsPerPage,
  paginate,
  rendererProps,
  messageProps,
  paginationProps,
  children,
  ...props
}) => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [hasItems, setHasItems] = useState(false);
  const RendererComponent = renderer;

  useEffect(() => {
    setIsEmpty(!loading && (!data || data.length === 0));
    setHasItems(data && data.length > 0);
  }, [data, loading]);

  return (
    <Grid cols={cols} rowGap="1rem" gap="1rem" {...props}>
      {children}
      {hasItems &&
        (!loading || (loading && !paginate)) &&
        data?.map((item, index) => (
          <Grid.col key={item.id || index}>
            <RendererComponent data={item} {...rendererProps} />
          </Grid.col>
        ))}
      {loading &&
        [...Array(resultsPerPage).keys()].map((item) => (
          <Grid.col key={item}>
            <RendererComponent loading={true} />
          </Grid.col>
        ))}
      {isEmpty && (
        <Grid.col start={1} end={cols + 1}>
          <Card>
            <MessagesNoResults {...messageProps} />
          </Card>
        </Grid.col>
      )}
      {paginate && (
        <Grid.col start={1} end={cols + 1}>
          <Paginate {...paginationProps} />
        </Grid.col>
      )}
    </Grid>
  );
};

CardList.defaultProps = {
  cols: 3,
  resultsPerPage: 6,
  rendererProps: {},
  messageProps: {},
};

CardList.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  cols: PropTypes.number,
  renderer: PropTypes.any.isRequired,
  resultsPerPage: PropTypes.number,
  paginate: PropTypes.bool,
  paginationProps: PropTypes.object,
  messageProps: PropTypes.object,
  rendererProps: PropTypes.object,
  children: PropTypes.node,
};

export default CardList;
