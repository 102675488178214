import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { MUTATION_TYPE, useToast } from "hooks";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { UPDATE_PROFILE_LIST } from "graphql/mutations";
import { DECORATORS, SIZE, TYPE } from "components/Form";
import { ICON_POSITION, ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * ProfileListForm
 *
 * @param {Boolean}  show
 * @param {Function} setProfileList
 * @param {Function} onClose
 * @param {Object}   profileListData
 */
const ProfileListForm = ({ show, setProfileList, onClose, profileListData, ...props }) => {
  const { toast } = useToast();

  const initialValues = {
    negotiatedRate: profileListData?.negotiatedRate,
    negotiatedRateCurrency: profileListData?.negotiatedRateCurrency,
    note: profileListData?.note,
  };

  const handleComplete = (profileList) => {
    setProfileList(profileList.updateProfileList.profileList);
    onClose();
    toast.success(<FormattedMessage id="Lists.ProfileListForm.Success" />);
  };

  const handleError = () => {
    toast.error(<FormattedMessage id="Lists.ProfileListForm.Error" />);
  };

  return (
    <ModalForm
      title={<FormattedMessage id="Lists.ProfileListForm.Title" />}
      data={FORM_DATA}
      initialValues={initialValues}
      updateId={profileListData?.id}
      mutationType={MUTATION_TYPE.update}
      mutationData={{
        mutationName: {
          update: UPDATE_PROFILE_LIST,
        },
      }}
      show={show}
      size={MODAL_SIZE.medium}
      onClose={onClose}
      onCompleted={handleComplete}
      onError={handleError}
      {...props}
    />
  );
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: <FormattedMessage id="Lists.ProfileListForm.NegotiatedRate" />,
        type: TYPE.input,
        size: SIZE.medium,
        valueFunc: (value) => parseFloat(value),
        properties: {
          type: "number",
          name: "negotiatedRate",
          required: false,
        },
        decorator: {
          type: DECORATORS.icon,
          props: {
            type: ICON_TYPE.dollarBill,
            size: ICON_SIZE.medium,
            position: ICON_POSITION.overlay,
          },
        },
      },
      {
        label: <FormattedMessage id="Lists.ProfileListForm.NegotiatedRateCurrency" />,
        type: TYPE.select,
        size: SIZE.medium,
        disabled: true,
        properties: {
          name: "negotiatedRateCurrency",
          options: [{ value: "USD", label: "USD" }],
          defaultValue: "USD",
        },
        decorator: {
          type: DECORATORS.icon,
          props: {
            type: ICON_TYPE.coin,
            size: ICON_SIZE.medium,
            position: ICON_POSITION.overlay,
          },
        },
      },
      {
        label: <FormattedMessage id="Lists.ProfileListForm.Notes" />,
        type: TYPE.textarea,
        size: SIZE.xlarge,
        properties: {
          name: "note",
          required: false,
        },
      },
    ],
  },
];

ProfileListForm.defaultProps = {
  show: false,
  setProfileList: () => {},
  onClose: () => {},
  profileListData: {},
};

ProfileListForm.propTypes = {
  show: PropTypes.bool.isRequired,
  setProfileList: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  profileListData: PropTypes.object.isRequired,
};

export default ProfileListForm;
