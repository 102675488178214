import { gql } from "@apollo/client";

const HIREFLIX_INVITE_CANDIDATE = gql`
  mutation addInterviewResponse($input: AddInterviewResponseInput!) {
    addInterviewResponse(input: $input) {
      interviewResponse {
        id
        uuid
        slug
        type
        serviceId
        status
        notes
        answered
        completed
        publicUrl
        thumbnailUrl
        answers
        answersCount
        startedAt
        completedAt
        approvedAt
        profileId
        interviewId
      }
    }
  }
`;

export { HIREFLIX_INVITE_CANDIDATE };
