import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import { useList } from "hooks";
import { LINKS, LIST_TYPES } from "constants/index";
import { Grid, PageWrapper } from "components/Containers";
import { BookMeetingMemberBanner } from "components/Banner";
import PageTitle from "components/PageTitle";
import { PrimaryLink } from "components/Links";
import Disclaimer from "components/Disclaimer";
import { FavoritesList } from "components/Favorites";

/**
 * Favorite
 */
const Favorite = () => {
  const { id } = useParams();
  const [selectedList, setSelectedList] = useState();
  const intl = useIntl();
  const navigate = useNavigate();
  const { data, loading } = useList({ variables: { filters: { type: LIST_TYPES.favorite, slug: id } } });

  useEffect(() => {
    if (data && data[0]) {
      setSelectedList(data[0]);
    }
  }, [data]);

  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>{intl.formatMessage({ id: "Meta.Favorites.Title" })}</title>
        </Helmet>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              title={selectedList?.title}
              backLink={
                <PrimaryLink onClick={() => navigate(LINKS.favorites)}>
                  <FormattedMessage id="Favorites.GoBack" />
                </PrimaryLink>
              }
            />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <FavoritesList data={data} loading={loading} selectedList={selectedList} listId={id} />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <BookMeetingMemberBanner />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <Disclaimer />
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default Favorite;
