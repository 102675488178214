import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useTermSheetScenarios, useToast } from "hooks";
import { SIZE, TYPE } from "components/Form";
import { MUTATION_TYPE } from "hooks";
import { TERM_SHEET_SCENARIOS } from "constants/index";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { ADD_TERM_SHEET_SCENARIO, UPDATE_TERM_SHEET_SCENARIO } from "graphql/mutations/termSheet";
import { GET_ORGANIZATION_BILLING_ACCOUNTS, GET_TERM_SHEET_SCENARIOS } from "graphql/queries";

/**
 * TermSheetScenarioForm
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Function}  onComplete
 * @param {Object}    data
 * @param {Boolean}   loading
 * @param {Boolean}   isEditing
 */
const TermSheetScenarioForm = ({ show, onClose, onComplete, data, loading, isEditing }) => {
  const { toast } = useToast();
  const { variables } = useTermSheetScenarios({ teamId: data?.teamId, state: TERM_SHEET_SCENARIOS.active });

  if (isEditing && loading) return null;

  const initialValues = {
    name: data?.name,
    type: data?.type,
    teamId: data?.teamId,
    organizationBillingAccountId: data?.organizationBillingAccountId,
  };

  const formProps = {
    initialValues,
    updateId: isEditing ? data?.id : null,
    mutationType: isEditing ? MUTATION_TYPE.update : MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: ADD_TERM_SHEET_SCENARIO,
        update: UPDATE_TERM_SHEET_SCENARIO,
      },
      refetchAfterMutate: true,
      refetchQueries: [
        {
          query: GET_TERM_SHEET_SCENARIOS,
          variables,
        },
      ],
    },
  };

  const handleComplete = (payload) => {
    toast.success(<FormattedMessage id="TermSheets.TermSheetScenarioForm.Success" />);

    if (typeof onComplete === "function") {
      if (payload?.addTeamScenario?.teamScenario) {
        onComplete(payload?.addTeamScenario?.teamScenario);
      }

      if (payload?.updateTeamScenario?.teamScenario) {
        onComplete(payload?.updateTeamScenario?.teamScenario);
      }
    }
  };

  return (
    <ModalForm
      data={FORM_DATA(data?.teamId)}
      show={show}
      size={MODAL_SIZE.small}
      onClose={onClose}
      title={<FormattedMessage id="TermSheets.TermSheetScenarioForm.FormTitle" />}
      description={<FormattedMessage id="TermSheets.TermSheetScenarioForm.FormDescription" />}
      onCompleted={handleComplete}
      {...formProps}
    />
  );
};

const FORM_DATA = (teamId) => [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Name",
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "name",
          type: "text",
          maxLength: 100,
          required: true,
          placeholder: "Name this scenario",
        },
      },
      {
        label: "Billing Account",
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "organizationBillingAccountId",
          required: true,
          queryName: GET_ORGANIZATION_BILLING_ACCOUNTS,
          queryKeyName: "organizationBillingAccounts",
          queryFieldName: "organizationBillingAccounts",
          queryVariables: { filters: { teamId: teamId } },
          labelKey: "name",
          valueKey: "id",
          placeholder: "Select a billing account",
          noOptionsMessage: "No billing accounts found",
        },
      },
      {
        label: "Period",
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "type",
          required: true,
          options: [
            { label: "Monthly", value: "MONTHLY_TEAM_SCENARIO" },
            { label: "Weekly", value: "WEEKLY_TEAM_SCENARIO" },
          ],
          placeholder: "Select a period",
        },
      },
      {
        type: TYPE.hidden,
        properties: {
          name: "teamId",
        },
      },
    ],
  },
];

TermSheetScenarioForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  data: PropTypes.object,
  loading: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default TermSheetScenarioForm;
