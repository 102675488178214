import React, { useEffect, useState } from "react";
import moment from "moment";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useProfile, useAuth } from "hooks";
import { colors, fontSize } from "style";
import { MEMBER_STATUS } from "constants/index";
import Card from "components/Card";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { Grid } from "components/Containers";

/**
 * CandidateStatus
 */
const CandidateStatus = ({ ...props }) => {
  const { profileId } = useAuth();
  const [status, setStatus] = useState();
  const { data } = useProfile({ profileId });

  useEffect(() => {
    setStatus(data?.profile?.status);
  }, [data?.profile]);

  if (!status || status === MEMBER_STATUS.sourced) {
    return null;
  }

  return (
    <Grid>
      {status === MEMBER_STATUS.reviewing && (
        <Grid.col start={1} end={13}>
          <Card css={styles.container} {...props}>
            <div css={styles.message}>
              <Icon type={ICON_TYPE.clock} size={ICON_SIZE.xxxlarge} color={colors.warning} />
              <div css={styles.title}>
                <FormattedMessage id="Candidate.CandidateStatus.TitleReviewing" />
              </div>
              <FormattedMessage id="Candidate.CandidateStatus.BodyReviewing" />
            </div>
          </Card>
        </Grid.col>
      )}
      {status === MEMBER_STATUS.rejected && (
        <Grid.col start={1} end={13}>
          <Card css={styles.container} {...props}>
            <div css={styles.message}>
              <Icon type={ICON_TYPE.close} size={ICON_SIZE.xxxlarge} color={colors.red} />
              <div css={styles.title}>
                <FormattedMessage id="Candidate.CandidateStatus.TitleRejected" />
              </div>
              <FormattedMessage
                id="Candidate.CandidateStatus.BodyRejected"
                values={{
                  date: moment(data?.profile?.rejectedAt).add(90, "days").format("MMMM Do, YYYY"),
                }}
              />
            </div>
          </Card>
        </Grid.col>
      )}
    </Grid>
  );
};

const styles = {
  container: css`
    padding: 3rem 5rem;
    text-align: center;
    font-size: ${fontSize.small};
    line-height: normal;
  `,
  title: css`
    color: ${colors.black};
    font-size: ${fontSize.normal};
    font-weight: 500;
    padding-bottom: 1rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px ${colors.grayAnatomyLight4} solid;
    width: 100%;
  `,
  message: css`
    color: ${colors.grayAnatomyLight1};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  `,
  error: css`
    color: ${colors.red};
  `,
};

export default CandidateStatus;
