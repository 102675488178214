import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, gradients, fontSize } from "style";
import DisplayBox, { Label } from "components/DisplayBox";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { Editor, SIZE, TYPE } from "components/Form";
import { Paragraph } from "components/Text";
import { UPDATE_WORK_EXPERIENCES, ADD_WORK_EXPERIENCES, REMOVE_WORK_EXPERIENCES } from "graphql/mutations";
import { GET_PROFILE, GET_SKILLS, GET_INDUSTRIES } from "graphql/queries";
import { getYearsExperience, sortByDate, convertDateToUTC } from "utils";
import Tags from "components/Tags";
import { ORGANIZATION_SIZE_OPTIONS, PAGES } from "constants/index";
import { Col, Grid, Row } from "components/Containers";
import { convertFromPlainText, getPlainText } from "components/Form/Editor";

/**
 * Work Experience
 *
 * @params {Array}    data
 * @params {String}   profileId
 * @params {Boolean}  isEditable
 */
const ProfileWorkExperience = ({ data, profileId, isEditable, ...props }) => {
  const sortedData = sortByDate(data, "startDate", "desc");
  return (
    <DisplayBox
      title={
        <Row>
          <FormattedMessage id="Profile.ProfileWorkExperience.Title" />
          <div css={styles.title}>
            <ExperienceTag experiences={data} />
          </div>
        </Row>
      }
      editButtonText={<FormattedMessage id="Profile.ProfileWorkExperience.AddWorkExperience" />}
      numColumns={1}
      data={EXPERIENCE_DATA(sortedData, isEditable)}
      parentKeyName="profileId"
      parentKeyValue={profileId}
      canAdd={isEditable}
      isEditable={isEditable}
      initialValues={sortedData?.map((i) => ({
        id: i.id,
        profileId: data?.id,
        companyName: i.companyName,
        role: i.role,
        startDate: i.startDate,
        endDate: i.endDate,
        description: i?.description,
        descriptionJson: i?.descriptionJson ?? convertFromPlainText(i?.description),
        currentJob: i.currentJob,
        organizationSize: i.organizationSize,
        industries: i.industries.map((item) => ({
          id: item.id,
          name: item.name,
        })),
        skills: i.skills.map((item) => ({
          id: item.id,
          name: item.name,
        })),
      }))}
      {...props}
    />
  );
};

/**
 * Work Experience BodyRenderer
 *
 * @param {Object}   item
 * @param {Boolean}  last
 */
const BodyRenderer = ({ item, last }) => (
  <Grid css={styles.container(last)}>
    <Grid.col start={1} end={13}>
      {item?.descriptionJson ? (
        <Editor value={item?.descriptionJson} css={styles.body} readOnly={true} />
      ) : (
        <Paragraph data={item?.description} />
      )}
    </Grid.col>
    <Grid.col start={1} end={13}>
      {item.industries?.length > 0 && (
        <Col gap="1rem">
          <Label>
            <FormattedMessage id="Profile.ProfileWorkExperience.TitleIndustry" />
          </Label>
          <Tags data={item.industries} />
        </Col>
      )}
    </Grid.col>
    <Grid.col start={1} end={13}>
      {item.skills?.length > 0 && (
        <Col gap="1rem">
          <Label>
            <FormattedMessage id="Profile.ProfileWorkExperience.TitleSkills" />
          </Label>
          <Tags data={item.skills} />
        </Col>
      )}
    </Grid.col>
  </Grid>
);

/**
 * Experience Tag
 *
 * @param {Object} experiences
 */
const ExperienceTag = ({ experiences }) => (
  <FormattedMessage
    id="Profile.ProfileWorkExperience.TitleWithYears"
    values={{
      years: <span css={styles.label}>{getYearsExperience(experiences)}</span>,
    }}
  />
);

ExperienceTag.propTypes = {
  experiences: PropTypes.object,
};

const EXPERIENCE_DATA = (data, isEditable) => {
  return data?.map((item, index) => {
    const startDate = item.startDate ? convertDateToUTC(item.startDate).getFullYear() : "";
    const endDate = item.endDate ? convertDateToUTC(item.endDate).getFullYear() : "";
    const isLast = index === data.length - 1;

    return {
      id: item.id,
      title: `${item.role} at ${item.companyName}`,
      subtitle: item.currentJob ? `${startDate} - Present` : `${startDate} - ${endDate}`,
      body: <BodyRenderer item={item} last={isLast} />,
      isEditable,
    };
  });
};

const FORM_DATA = [
  {
    items: [
      {
        label: "Company Name",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "companyName",
          type: "text",
          required: true,
          autoFocus: true,
        },
      },
      {
        label: "Role",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "role",
          type: "text",
          required: true,
        },
      },
      {
        type: TYPE.checkbox,
        size: SIZE.large,
        properties: {
          name: "currentJob",
          type: "checkbox",
          label: "I currently work here",
        },
      },
      {
        label: "Start Date",
        type: TYPE.datePicker,
        size: SIZE.medium,
        properties: {
          name: "startDate",
          required: true,
          yearDropdownItemNumber: 40,
        },
      },
      {
        label: "End Date",
        type: TYPE.datePicker,
        size: SIZE.medium,
        disabledCondition: {
          field: "currentJob",
          value: true,
        },
        properties: {
          name: "endDate",
          yearDropdownItemNumber: 40,
        },
      },
      {
        label: "Description",
        type: TYPE.editor,
        size: SIZE.xlarge,
        onChange: (name, value, handleOnChange) => {
          handleOnChange("description", getPlainText(value));
          handleOnChange(name, value);
        },
        properties: {
          name: "descriptionJson",
          maxLength: 3000,
          required: true,
          placeholder: "Describe this role for prospective squad members",
          showFormattingOptions: true,
        },
      },
      {
        label: "Industry",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "industries",
          queryName: GET_INDUSTRIES,
          queryKeyName: "industries",
          minLength: 0,
          required: true,
        },
      },
      {
        label: "Skills",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "skills",
          queryName: GET_SKILLS,
          queryKeyName: "skills",
          required: true,
          minLength: 1,
        },
      },
      {
        label: "Organization Size",
        type: TYPE.select,
        properties: {
          name: "organizationSize",
          required: true,
          options: ORGANIZATION_SIZE_OPTIONS,
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Profile.ProfileWorkExperience.Title" />,
  description: <FormattedMessage id="Profile.ProfileWorkExperience.FormDescription" />,
  isDestructable: true,
  mutationData: {
    mutationName: {
      update: UPDATE_WORK_EXPERIENCES,
      add: ADD_WORK_EXPERIENCES,
      delete: REMOVE_WORK_EXPERIENCES,
    },
    refetchQueries: [GET_PROFILE],
    refetchAfterMutate: true,
  },
  page: PAGES.profileWorkExperience,
};

const styles = {
  label: css`
    color: ${colors.grayAnatomyLight1};
    font-weight: 300;
    background: ${gradients.redLinearGradient};
    padding: 0.4rem 0.8rem;
    border-radius: 1.2rem;
    font-size: ${fontSize.xsmall};
    font-weight: 500;
    line-height: 1.33;
    color: #fff;
    position: relative;
  `,
  tags_block: css``,
  container: (last) => css`
    border-bottom: 1px solid ${colors.grayAnatomyLight4};
    padding-bottom: 2rem;
    ${last && `border-bottom-width:0;`}
  `,
  title: css`
    position: relative;
    top: -0.1rem;
  `,
  body: css`
    margin: 0;
    padding: 0;
    border: 0;
    box-shadow: none;
  `,
};

BodyRenderer.propTypes = {
  item: PropTypes.object,
  last: PropTypes.bool,
};

ProfileWorkExperience.propTypes = {
  data: PropTypes.array,
  profileId: PropTypes.string,
  isEditable: PropTypes.bool,
};

export default withModalForm(ProfileWorkExperience, FORM_SETTINGS);

export { ExperienceTag };
