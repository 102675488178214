import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { colors, fontSize } from "style";
import PropTypes from "prop-types";
import { Row } from "components/Containers";

/**
 * Toggle Component
 *
 * @param {Function}     onChange
 * @param {Boolean}      value
 * @param {String}       label
 * @param {String}       name
 * @param {Number}       timeOut
 */
const ToggleWithOnChange = ({ onChange, value: valueProp, label, name, timeOut, ...props }) => {
  const ranInt = Math.floor(Math.random() * 100);
  const formItemId = `label-${name}-${ranInt}`;
  const [value, setValue] = useState(valueProp);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleChange = (e) => {
    setValue(e.target.checked);
    setTimeout(() => onChange(name, e.target.checked), timeOut);
  };

  if (label) {
    return (
      <Row gap="0">
        <Toggle {...props} id={formItemId} onChange={handleChange} checked={value} name={name} />
        <Label isActive={value && !props.disabled} htmlFor={formItemId}>{label}</Label>
      </Row>
    );
  } else {
    return <Toggle {...props} onChange={handleChange} type="checkbox" checked={value} name={name} />;
  }
};

/**
 * Toggle
 *
 * @param {String} size
 */
const Toggle = ({ size, ...props }) => (
  <Container size={size}>
    <Input type="checkbox" size={size} {...props} />
    <Slider className="slider" size={size} />
  </Container>
);

/**
 * CheckBox
 */
const Container = styled.label`
  position: relative;
  display: inline-block;
  width: ${({ size }) => (size === TOGGLE_SIZE.small ? "2.4rem" : "5rem")};
  height: ${({ size }) => (size === TOGGLE_SIZE.small ? "1.5rem" : "3rem")};
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .slider:after,
  &:checked + .slider {
    background: ${colors.green} !important;
  }

  &:checked + .slider:after,
  &:checked + .slider:before {
    transform: translateX(${({ size }) => (size === TOGGLE_SIZE.small ? "0.9rem" : "2rem")});
  }

  &:disabled + .slider {
    cursor: not-allowed;
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.grayAnatomyLight2};
  border-radius: 34px;

  &:hover {
    background: ${colors.grayAnatomyLight1};
  }

  &:before {
    position: absolute;
    content: "";
    height: ${({ size }) => (size === TOGGLE_SIZE.small ? "1.1rem" : "2.6rem")};
    width: ${({ size }) => (size === TOGGLE_SIZE.small ? "1.1rem" : "2.6rem")};
    left: ${({ size }) => (size === TOGGLE_SIZE.small ? "0.2rem" : "0.2rem")};
    bottom: ${({ size }) => (size === TOGGLE_SIZE.small ? "0.2rem" : "0.2rem")};
    background-color: #fff;
    box-shadow: 3px 0px 6px 0px rgba(85, 76, 197, 0.15);
    border-radius: 100%;
  }
  
  input:disabled + & {
    cursor: not-allowed;
  }
`;

const Label = styled.label`
  margin-left: 1rem;
  color: ${colors.grayAnatomyLight1};
  font-size: ${fontSize.small};
  margin-top: 0.3rem;
  font-weight: 400;
  ${({ isActive }) => !isActive && `color: ${colors.grayAnatomyLight3};`}
`;

ToggleWithOnChange.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  timeOut: PropTypes.number,
};

ToggleWithOnChange.defaultProps = {
  timeOut: 250,
};

Toggle.propTypes = {
  size: PropTypes.string,
};

const TOGGLE_SIZE = {
  small: "small",
  large: "large",
};

export { Toggle, TOGGLE_SIZE };

export default ToggleWithOnChange;
