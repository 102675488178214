import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams, useOutletContext, Outlet } from "react-router-dom";
import { NavList, SubNav } from "components/Nav";
import { LINKS } from "constants";

/**
 * Settings
 */
const Settings = () => {
  const { teamPermissions, isSLTL, ...restContext } = useOutletContext();
  const [navItems, setNavItems] = useState([]);
  const { id } = useParams();
  const intl = useIntl();

  useEffect(() => {
    setNavItems(NAV_ITEMS(id, teamPermissions).filter((page) => page.isPermissible));
  }, [teamPermissions]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.Settings.Title" })}</title>
      </Helmet>
      <SubNav>
        <NavList pages={navItems} />
      </SubNav>
      <Outlet context={{ teamPermissions, isSLTL, ...restContext }} />
    </>
  );
};

const NAV_ITEMS = (id, teamPermissions) => [
  {
    label: <FormattedMessage id="Squads.SquadSubNav.Settings.General" />,
    to: LINKS.squad_general_settings(id),
    isPermissible: teamPermissions?.canAccessGeneralSettings,
    props: {
      routerEnabled: true,
      end: false,
      isActive: true,
      target: "_blank",
    },
  },
  {
    label: <FormattedMessage id="Squads.SquadSubNav.Settings.DailyReport" />,
    to: LINKS.squad_daily_report_settings(id),
    isPermissible: teamPermissions?.canAccessDailyReportSettings,
    props: {
      routerEnabled: true,
      end: false,
      isActive: true,
    },
  },
  {
    label: <FormattedMessage id="Squads.SquadSubNav.Settings.SignalInstallation" />,
    to: LINKS.squad_signal_installation_settings(id),
    isPermissible: teamPermissions?.canAccessSignalInstallation,
    props: {
      routerEnabled: true,
      end: false,
      isActive: true,
    },
  },
  {
    label: <FormattedMessage id="Squads.SquadSubNav.Settings.SignalSettings" />,
    to: LINKS.squad_engineering_metrics_settings(id),
    isPermissible: teamPermissions?.canAccessMetricsSettings,
    props: {
      routerEnabled: true,
      end: false,
      isActive: true,
    },
  },
];

export default Settings;
