const ASSESSMENT_CONFIGS = {
  missionRole: {
    assessmentableType: "MissionRole",
    category: "mission_role_assessment",
    assessmentKey: "assessment",
    assessmentType: "MissionRoleAssessment",
  },
};

const QUESTION_TYPES = {
  textarea: "Text Area",
};

export default ASSESSMENT_CONFIGS;

export { QUESTION_TYPES };
