import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { TimeSheetList } from "components/TimeSheet";
import Modal, { MODAL_SIZE } from "components/Modal";
import { ActionButton } from "components/Buttons";
import { formatDate } from "utils";
import { UserFullName } from "components/User";
import moment from "moment";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { colors } from "style";

/**
 *
 * @param {String} id
 * @param {String} month
 * @param {Object} data
 */
const TimeSheet = ({ id, month, data }) => {
  const [showForm, setShowForm] = useState(false);
  const [isoDate, setIsoDate] = useState();

  useEffect(() => {
    setIsoDate(moment(month).format());
  }, [month]);

  return (
    <>
      <Modal
        title={<FormattedMessage id="TimeCard.TimeCardDetails.Title" />}
        info={
          <FormattedMessage
            id="TimeCard.TimeCardDetails.Body"
            values={{
              user: <UserFullName data={data} />,
              date: formatDate(month, "MMM, YYYY"),
            }}
          />
        }
        size={MODAL_SIZE.large}
        show={showForm}
        showSave={false}
        showCancel={true}
        secondaryButtonMessageId="Global.Close"
        canSubmit={false}
        onSubmit={() => setShowForm(false)}
        onClose={() => setShowForm(false)}
      >
        <TimeSheetList profileId={id} selectedMonth={isoDate} disabled={true} />
      </Modal>
      <ActionButton onClick={() => setShowForm(true)}>
        <Icon type={ICON_TYPE.eye} size={ICON_SIZE.large} color={colors.purpleRainBase} />
      </ActionButton>
    </>
  );
};

TimeSheet.propTypes = {
  id: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default TimeSheet;
