import { gql } from "@apollo/client";
import { COMMENT_FIELDS } from "graphql/fragments";

const GET_COMMENTS = gql`
  ${COMMENT_FIELDS}
  query GetComments(
    $commentableId: ID!
    $commentableType: Commentable!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: CommentOrderBy
  ) {
    comments(
      commentableId: $commentableId
      commentableType: $commentableType
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
    ) {
      nodes {
        ...CommentFields
        user {
          id
          lastName
          firstName
          email
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export { GET_COMMENTS };
