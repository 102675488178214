import { gql } from "@apollo/client";

const GET_MISSION_CONTACTS = gql`
  query missionContacts($profileId: ID!, $first: Int, $last: Int, $after: String, $before: String) {
    missionContacts(profileId: $profileId, first: $first, last: $last, after: $after, before: $before) {
      nodes {
        id
        name
        firstName
        lastName
        archetype
        currentTime
        isOnline
        nextAvailability
        avatarUrl
        country
        countryFlag
        slug
        teams {
          name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export { GET_MISSION_CONTACTS };
