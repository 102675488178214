import { gql } from "@apollo/client";

const CONVERT_TO_CURRENCY = gql`
  query CurrencyConversions($from: CurrencyEnum!, $to: CurrencyEnum!, $amount: Float!) {
    currencyConversions(from: $from, to: $to, amount: $amount) {
      from
      to
      amount
      convertedAmount
      conversionRate
    }
  }
`;

export { CONVERT_TO_CURRENCY };
