import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import { EditableTag } from "components/Tags";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { useQueryData } from "hooks";
import "react-bootstrap-typeahead/css/Typeahead.css";

/**
 * TagsInput
 *
 * @params {Array}    value
 * @params {String}   name
 * @params {Boolean}  required
 * @params {Function} onChange
 * @params {Array}    options
 * @params {String}   valueKey
 * @params {String}   labelKey
 * @params {String}   secondaryLabelKey
 * @params {Object}   queryName
 * @params {String}   queryKeyName
 * @params {Function} searchQuery     -Callback function to format the search object (look at defaultProps for example)
 * @params {Boolean}  disabled
 */
const TagsInput = ({
  value,
  name,
  options,
  labelKey,
  valueKey,
  onChange,
  secondaryLabelKey,
  queryName,
  queryKeyName,
  searchQuery,
  queryVariables,
  parentId,
  disabled,
  ...props
}) => {
  const [variables, setVariables] = useState({});
  const [focus, setFocus] = useState(false);
  const { loading, data } = useQueryData({
    queryName,
    keyName: queryKeyName,
    variables: { ...variables, ...queryVariables },
  });

  useEffect(() => {
    onChange(name, []);
  }, [parentId]);

  useEffect(() => {
    if (value) {
      onChangeLocal(value);
    }
  }, [data, options]);

  const handleSearch = (keyword) => setVariables(searchQuery(keyword));

  const onChangeLocal = (val) => {
    const uniques = Array.from(new Set(val.map((a) => a[valueKey]))).map((id) => val.find((a) => a[valueKey] === id));

    onChange(
      name,
      uniques.map((item) => ({
        [labelKey]: item[labelKey],
        [valueKey]: item[valueKey],
      }))
    );
  };

  return (
    <div css={styles.tags_wrapper(focus)}>
      <AsyncTypeahead
        id={name}
        name={name}
        labelKey={labelKey}
        options={options ? options : data && data[queryKeyName]?.nodes}
        multiple={true}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        renderToken={(option, { onRemove, ...otherProps }, index) => (
          <EditableTag
            key={index}
            onRemove={onRemove}
            option={option}
            isEditable={true}
            {...otherProps}
            disabled={disabled}
          >
            {`${option[labelKey]}`}
          </EditableTag>
        )}
        isLoading={loading}
        onChange={onChangeLocal}
        selected={value}
        onSearch={handleSearch}
        renderMenu={(results, menuProps) => (
          <Menu {...menuProps}>
            {results.map((result, index) => (
              <MenuItem key={index} option={result} position={index}>
                <span css={styles.label(result[secondaryLabelKey])}>{result[labelKey]}</span>
                <span css={styles.description}>{secondaryLabelKey && result[secondaryLabelKey]}</span>
              </MenuItem>
            ))}
          </Menu>
        )}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

const styles = {
  tags_wrapper: (focus) => css`
    order: 1;
    flex: 1;

    ${focus === true &&
    `
    & + label {
      color: ${colors.purpleRainBase};
    }
    `}

    .rbt-input-wrapper {
      gap: 0.6rem;
    }

    .rbt-input-wrapper {
      input {
        &::placeholder {
          color: ${colors.grayAnatomyLight2};
        }
      }
    }

    .rbt-input-hint {
      font-size: ${fontSize.small};
      font-weight: normal;
      color: ${colors.grayAnatomyLight3} !important;
    }

    .rbt-input-multi.disabled {
      background: ${colors.grayAnatomyLight7};
    }

    .rbt-input {
      border: 1px solid ${colors.grayAnatomyLight3};
      width: 100%;
      min-height: 4rem;
      padding: 0.8rem 0.8rem 0.8rem 0.8rem;
      border-radius: 0.6rem;
      transition: all 0.3s ease;

      &.focus {
        border-color: ${colors.purpleRainBase};
        box-shadow: 0px 0px 0px 3px ${colors.purpleRainLight3};
      }
    }

    .rbt-input-main {
      font-size: ${fontSize.small};
      font-weight: normal;
      color: ${colors.secondary};
    }

    .rbt-menu.dropdown-menu {
      background-color: #fff;
      border-radius: 0.4rem;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
      border: 1px solid ${colors.grayAnatomyLight3};
      margin-top: 1rem;

      .dropdown-item {
        font-weight: normal;
        color: ${colors.secondary};
        font-size: ${fontSize.xsmall};
        padding: 0.8rem 1.6rem;
        width: 100%;
        user-select: none;

        &:hover {
          background: ${colors.grayAnatomyLight5};
        }
      }
    }
  `,
  input: css`
    border: 0;
    background: none;
    font-size: ${fontSize.small};
    font-weight: normal;
    height: 2.2rem;
    line-height: 2.2rem;
    color: ${colors.secondary};
    padding: 0;
    box-sizing: border-box;
    outline: none;
  `,
  description: css`
    display: block;
    color: ${colors.grayAnatomyLight1};
  `,
  label: (hasDescription) => css`
    display: block;
    ${hasDescription &&
    `
      font-weight: 600;
      margin-bottom:0.5rem;
    `}
  `,
};

TagsInput.propTypes = {
  value: PropTypes.array,
  options: PropTypes.array,
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  secondaryLabelKey: PropTypes.string,
  queryName: PropTypes.object,
  queryKeyName: PropTypes.string,
  queryVariables: PropTypes.object,
  searchQuery: PropTypes.func,
  parentId: PropTypes.string,
  disabled: PropTypes.bool,
};

TagsInput.defaultProps = {
  labelKey: "name",
  valueKey: "id",
  secondaryLabelKey: null,
  searchQuery: (name) => ({ filters: { name } }),
  parentId: null,
};

export default TagsInput;
