import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { PasswordResetForm, LoginContainer } from "components/Login";
import { useParams } from "react-router-dom";
import backgroundImage from "assets/images/Faith-2000x1340-c-center.jpg";

/**
 * Forgot Password
 */
const ResetPassword = () => {
  const intl = useIntl();
  const selectedBanner = banners[0];
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.ResetPassword.Title" })}</title>
      </Helmet>
      <LoginContainer banner={selectedBanner}>
        <PasswordResetForm resetPasswordToken={id} />
      </LoginContainer>
    </>
  );
};

const banners = [
  {
    title: "Built to support careers, not gigs.",
    label: "Faith Adekogbe",
    description: "Frontend Developer",
    cover: `url(${backgroundImage})`,
  },
];

export default ResetPassword;
