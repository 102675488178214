import React from "react";
import PropTypes from "prop-types";
import { CommentsText, KeyValueText } from "components/ActivityTooltip";

/**
 * CommentActivityTooltip
 *
 * @params {Object}      tooltipData
 */
const CommentActivityTooltip = ({ tooltipData }) => {
  const { type, length, count, pullRequestTitle, pullRequestTitles, repository, repositories, users, isMembersView } =
    tooltipData;

  return isMembersView ? (
    <>
      <div>
        <CommentsText values={{ type: type?.toUpperCase() }} />
      </div>
      <div>
        <KeyValueText id="Length" value={length} />
      </div>
      <div>
        <KeyValueText id="Repository" value={repository} />
      </div>
      <div>
        <KeyValueText id="Pull Request" value={pullRequestTitle} />
      </div>
    </>
  ) : (
    <>
      <div>
        <CommentsText values={{ commentsCount: count, pullRequestsCount: pullRequestTitles.length }} />
      </div>
      <div>
        <KeyValueText id="Repositories" value={repositories.join(", ")} />
      </div>
      <div>
        <KeyValueText id="Users" value={users.join(", ")} />
      </div>
    </>
  );
};

CommentActivityTooltip.propTypes = {
  tooltipData: PropTypes.object,
};

export default CommentActivityTooltip;
