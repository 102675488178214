const ARCHIVE_REASON = [
  {
    label: "Insufficient Skills",
    value: "INSUFFICIENT_SKILLS",
  },
  {
    label: "Code of Conduct Violation",
    value: "BAD_BEHAVIOR",
  },
  {
    label: "Wrong Cultural Fit",
    value: "WRONG_CULTURE_FIT",
  },
  {
    label: "Spam - no reply",
    value: "SPAM",
  },
  {
    label: "Full-time Employment",
    value: "FULL_TIME_EMPLOYMENT",
  },
  {
    label: "Inactive Profile",
    value: "INACTIVE_PROFILE",
  },
];

export default ARCHIVE_REASON;
