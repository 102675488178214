import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { colors, fontSize, margins } from "style";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { TIMELINE_EVENTS } from "constants/index";
import { TimelineEventTitle, TimelineEventBody } from "components/Timeline";

/**
 * TimelineEvent
 *
 * @param {Object} data
 */
const TimelineEvent = ({ data, profileId }) => {
  const { key, createdAt, user, impersonator, payload } = data;

  // Hide events that are not defined in our constants
  if (!payload || !Object.values(TIMELINE_EVENTS).includes(key)) {
    return null;
  }

  return (
    <>
      <div css={styles.header}>
        <div css={styles.icon_container}>
          <Icon type={ICON_TYPE.clock} size={ICON_SIZE.large} color={colors.purpleRainLight1} />
        </div>
        <div css={styles.text}>
          <TimelineEventTitle
            keyName={key}
            payload={payload}
            createdAt={createdAt}
            user={user}
            impersonator={impersonator}
            profileId={profileId}
          />
        </div>
      </div>
      <div css={styles.details}>
        <TimelineEventBody data={{ key, payload, user }} />
      </div>
    </>
  );
};

const styles = {
  header: css`
    display: flex;
    gap: 2rem;
    align-items: center;
  `,
  text: css`
    font-size: ${fontSize.small};
    font-weight: 400;
    color: ${colors.grayAnatomyLight6};
    padding-left: ${margins.normal};
  `,
  details: css`
    padding-left: 3.5rem;
    margin-left: 1.4rem;
    border-left: 1px dashed ${colors.purpleRainLight5};
    padding-bottom: 2rem;
  `,
  icon_container: css`
    width: 3rem;
    height: 3rem;
    background: ${colors.purpleRainLight5};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};

TimelineEvent.propTypes = {
  data: PropTypes.object.isRequired,
  profileId: PropTypes.string,
};

export default TimelineEvent;
