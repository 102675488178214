import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Form, { SIZE, TYPE } from "components/Form";
import { Col } from "components/Containers";
import { breakpoints, colors, fontSize, margins } from "style";
import { LINKS } from "constants/index";
import { PrimaryLink } from "components/Links";
import { RESET_PASSWORD } from "graphql/mutations";
import { MUTATION_TYPE, useToast } from "hooks";
import { validatePassword } from "utils";

/**
 * PasswordResetForm
 *
 * @param {String}  resetPasswordToken
 */
const PasswordResetForm = ({ resetPasswordToken }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [values, setValues] = useState({});
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const pw = values?.password;
    const pwConfirm = values?.passwordConfirmation;

    setPasswordsMatch(!pwConfirm || pwConfirm?.length === 0 || pwConfirm === pw);
    setIsValid(validatePassword(values.password));
  }, [values]);

  /**
   * handle login complete
   */
  const handleComplete = () => {
    toast.success(<FormattedMessage id="Login.PasswordResetForm.Success" />);
    navigate(LINKS.login);
  };

  return (
    <Col>
      <span css={styles.title}>
        <FormattedMessage id="Login.PasswordResetForm.Title" />
      </span>
      <span css={styles.subtitle}>
        <FormattedMessage id="Login.PasswordResetForm.Subtitle" />
      </span>
      <Form
        data={FORM_DATA(passwordsMatch)}
        onChange={setValues}
        initialValues={{
          resetPasswordToken,
        }}
        saveButton={{
          show: true,
          labelId: intl.formatMessage({ id: "Login.PasswordResetForm.Button" }),
          props: {
            css: styles.button,
          },
          disabled: !values?.password || !values?.passwordConfirmation || !passwordsMatch || !isValid,
        }}
        mutationData={{
          mutationName: {
            add: RESET_PASSWORD,
          },
          unWrapParams: true,
        }}
        mutationType={MUTATION_TYPE.add}
        onCompleted={handleComplete}
        marginSize={margins.none}
      />
      <span css={styles.already_member}>
        <FormattedMessage
          id="Login.PasswordResetForm.LoginLabel"
          values={{
            link: (
              <PrimaryLink href={LINKS.login} css={styles.link} routerEnabled>
                <FormattedMessage id="Login.PasswordResetForm.LoginLink" />
              </PrimaryLink>
            ),
          }}
        />
      </span>
    </Col>
  );
};

const styles = {
  title: css`
    color: ${colors.black};
    font-size: ${fontSize.xxlarge};
    margin-bottom: 1.5rem;
    font-weight: 700;
  `,
  subtitle: css`
    font-size: ${fontSize.normal};
    color: ${colors.grayAnatomyLight1};
    margin-bottom: 3rem;
    font-weight: 500;
  `,
  already_member: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyLight1};
    margin: 3rem auto 0 auto;
  `,
  link: css`
    @media (max-width: ${breakpoints.mobile}) {
      color: ${colors.purpleRainLight3};
    }
  `,
  button: css`
    width: 100%;
  `,
  form_container: css`
    width: 100%;
  `,
};

const FORM_DATA = (passwordsMatch) => [
  {
    style: styles.form_container,
    items: [
      {
        label: "New Password",
        type: TYPE.password,
        size: SIZE.xlarge,
        properties: {
          name: "password",
          autoFocus: false,
          required: true,
          type: "password",
          autoComplete: "new-password",
        },
      },
      {
        label: "Confirm New Password",
        type: TYPE.input,
        size: SIZE.xlarge,
        hasError: !passwordsMatch,
        errorMessage: "Passwords do not match",
        properties: {
          name: "passwordConfirmation",
          required: true,
          type: "password",
          autoComplete: "new-password",
        },
      },
    ],
  },
];

PasswordResetForm.propTypes = {
  resetPasswordToken: PropTypes.string,
};

export default PasswordResetForm;
