import React from "react";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { ARCHIVE_PARTNER, ACTIVATE_PARTNER, REMOVE_PARTNERS } from "graphql/mutations";
import { GET_PARTNERS } from "graphql/queries";
import { useToast } from "hooks";

/**
 * usePartners
 *
 */
export default function usePartners() {
  const [archivePartner, { loading: archiving }] = useMutation(ARCHIVE_PARTNER);
  const [activatePartner, { loading: activating }] = useMutation(ACTIVATE_PARTNER);
  const [removePartners, { loading: removing }] = useMutation(REMOVE_PARTNERS);
  const { toast } = useToast();

  const handleArchivePartner = (id) => {
    archivePartner({
      variables: {
        input: {
          id,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="usePartners.Archive.MessageSuccess" />),
      onError: () => toast.error(<FormattedMessage id="usePartners.Archive.MessageError" />),
    });
  };

  const handleActivatePartner = (id) => {
    activatePartner({
      variables: {
        input: {
          id,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="usePartners.Activate.MessageSuccess" />),
      onError: () => toast.error(<FormattedMessage id="usePartners.Activate.MessageError" />),
    });
  };

  const handleRemovePartners = (sel) => {
    removePartners({
      variables: {
        input: {
          ids: sel,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="usePartners.Remove.MessageSuccess" />),
      onError: () => toast.error(<FormattedMessage id="usePartners.Remove.MessageError" />),
      update: (cache) => {
        const query = GET_PARTNERS;
        const {
          partners: { nodes },
        } = cache.readQuery({ query });

        cache.writeQuery({
          query,
          data: { partners: { nodes: nodes.filter((item) => !sel.includes(item.id)) } },
        });
      },
    });
  };

  return {
    handleArchivePartner,
    handleActivatePartner,
    handleRemovePartners,
    activating,
    archiving,
    removing,
  };
}
