import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Row } from "components/Containers";
import { breakpoints, colors, fontSize, margins } from "style";
import Icon, { ICON_TYPE } from "components/Icon";

/**
 * Formats a page title
 *
 * @param {Element} title
 * @param {Element} actionBar
 * @param {Boolean} noMargin
 * @param {Boolean} isExpandable
 * @param {Boolean} isExpanded
 * @param {Function} onExpand
 * @param {String} expandableId
 */
const SectionTitle = ({ title, actionBar, noMargin, isExpandable, isExpanded, onExpand, expandableId, ...props }) => (
  <div css={styles.container(noMargin, isExpandable)} onClick={() => onExpand?.(!isExpanded, expandableId)} {...props}>
    <StyledRow>
      <div css={styles.title}>{title}</div>
      <Row>
        {isExpandable && (
          <div css={styles.expanded_container(isExpanded)}>
            <Icon type={ICON_TYPE.chevronDown} />
          </div>
        )}
        <div css={styles.action_container}>
          <Row>{actionBar}</Row>
        </div>
      </Row>
    </StyledRow>
  </div>
);

const StyledRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
`;

const styles = {
  container: (noMargin, isExpandable) => css`
    cursor: ${isExpandable ? "pointer" : "default"};
    margin: 1rem ${noMargin ? 0 : margins.normal} 0 ${noMargin ? 0 : margins.normal};

    @media (max-width: ${breakpoints.portrait}) {
      margin: 1rem ${noMargin ? 0 : `2.5rem`} 0rem ${noMargin ? 0 : `2.5rem`};
    }

    @media (max-width: ${breakpoints.mobile}) {
      margin: 1rem ${noMargin ? 0 : `2rem`} 0rem ${noMargin ? 0 : `2rem`};
    }
  `,
  action_container: css`
    margin-left: auto;
  `,
  title: css`
    color: ${colors.purpleRainDark2};
    font-size: ${fontSize.medium};
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    @media (max-width: ${breakpoints.tablet}) {
      font-size: ${fontSize.small};
    }
  `,
  expanded_container: (isExpanded) => css`
    transform: rotate(${isExpanded ? 0 : 270}deg);
  `,
};

SectionTitle.defaultProps = {
  isExpandable: false,
};

SectionTitle.propTypes = {
  title: PropTypes.element.isRequired,
  actionBar: PropTypes.any,
  noMargin: PropTypes.bool,
  isExpandable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onExpand: PropTypes.func,
  expandableId: PropTypes.string,
};

export default SectionTitle;
