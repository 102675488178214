import { gql } from "@apollo/client";

const GET_PROFILE_MISSION_INTERVIEWS = gql`
  query profileMissionInterviews($profileId: ID!, $first: Int, $last: Int, $after: String, $before: String) {
    profileMissionInterviews(profileId: $profileId, first: $first, last: $last, after: $after, before: $before) {
      nodes {
        calendlyLink
        createdAt
        interviewer {
          name
          firstName
          lastName
          profile {
            slug
            avatarUrl
          }
        }
        missionRole {
          mission {
            uuid
          }
          slug
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export { GET_PROFILE_MISSION_INTERVIEWS };
