const VETTING_STEPS = {
  codeChallenge: "CODE_CHALLENGE",
  videoInterview: "VIDEO_INTERVIEW",
  profileCompletion: "PROFILE_COMPLETION",
};

const VETTING_STEPS_OPTIONS = [
  {
    label: "Profile Completion",
    value: VETTING_STEPS.profileCompletion,
  },
  {
    label: "Code Challenge",
    value: VETTING_STEPS.codeChallenge,
  },
  {
    label: "Video Interview",
    value: VETTING_STEPS.videoInterview,
  },
];

export { VETTING_STEPS };

export default VETTING_STEPS_OPTIONS;
