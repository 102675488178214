/**
 * Validates the password structure
 *
 * @param {string} str
 *
 * @returns {object}
 */
export default function validatePassword(str) {
  const REG_UPPERCASE = /[A-Z]/;
  const REG_LOWERCASE = /[a-z]/;
  const REG_NUMBER =    /[0-9]/;
  const REG_SPECIAL =   /[!@#\$%\^&\*]/;
  const MIN_LENGTH = 7;
  const hasUpperCase = str && REG_UPPERCASE.test(str);
  const hasLowerCase = str && REG_LOWERCASE.test(str);
  const hasNumberOrSpecial = str && (REG_NUMBER.test(str) || REG_SPECIAL.test(str));
  const hasMinLength = str && (str.length > MIN_LENGTH);

  return {
    hasUpperCase,
    hasLowerCase,
    hasNumberOrSpecial,
    hasMinLength,
    isValid: hasUpperCase && hasLowerCase && hasNumberOrSpecial && hasMinLength
  };
}
