const PAGES = {
  roleSelectionSignUp: "roleSelectionSignUp",
  roleDetailsSignUp: "roleDetailsSignUp",
  personalDetailsSignUp: "personalDetailsSignUp",
  eventDetails: "eventDetails",
  overAll: "overAll",
  teamDynamic: "teamDynamic",
  engineeringDashboard: "engineeringDashboard",
  productDelivery: "productDelivery",
  clientSatisfaction: "clientSatisfaction",
  clientSearch: "clientSearch",
  publicProfile: "publicProfile",
  profile: "profile",
  showcaseHome: "showcaseHome",
  showcaseApplicants: "showcaseApplicants",
  clientCommunity: "clientCommunity",
  showcaseTeamBuilder: "showcaseTeamBuilder",
  showcaseBookMeeting: "showcaseBookMeeting",
  showcaseMissionBoard: "showcaseMissionBoard",
  showcaseSignUp: "showcaseSignUp",
  squadDetail: "squadDetail",
  squadActivityLog: "squadActivityLog",
  addShortBio: "addShortBio",
  profileDiscipline: "profileDiscipline",
  topSkills: "topSkills",
  contactInfo: "contactInfo",
  profileWorkExperience: "profileWorkExperience",
  profileEducation: "profileEducation",
  externalLink: "externalLink",
  profilePhoto: "profilePhoto",
  rateCapacity: "rateCapacity",
  workInterest: "workInterest",
  badges: "badges",
  workSchedule: "workSchedule",
  vacationDates: "vacationDates",
  addToList: "addToList",
  profileInviteMember: "profileInviteMember",
  archiveProfile: "archiveProfile",
  ATS: "ATS",
  allMissions: "allMissions",
  missionDetails: "missionDetails",
  newMission: "newMission",
  applicants: "applicants",
  missionInviteMember: "missionInviteMember",
  profileMission: "profileMission",
  effortReportMission: "effortReportMission",
  missionEngineering: "missionEngineering",
  missionReport: "missionReport",
  missionDailyReport: "missionDailyReport",
  roleDetails: "roleDetails",
  editMissionRole: "editMissionRole",
  missionBoardDetails: "missionBoardDetails",
  editMissionDetails: "editMissionDetails",
  addMissionRole: "addMissionRole",
  reopenTimecards: "reopenTimecards",
  engineering: "ENGINEERING",
  daily_report: "DAILY_REPORT",
  mission_report: "MISSION_REPORT",
  term_sheet: "TERM_SHEET",
  partners: "partners",
  partnerDetails: "partnerDetails",
};

export default PAGES;
