import { SET_NOTIFICATION_SETTINGS } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import { useAuth } from "hooks";

export default function useNotificationSettings({ name }) {
  const { notificationSettings } = useAuth();
  const [notificationSetting] = notificationSettings.filter((setting) => setting.name === name);

  const [setNotificationSettings, { data, loading, error }] = useMutation(SET_NOTIFICATION_SETTINGS);

  const handleNotificationSubscription = async ({ isSubscribed, userId, onComplete, onError }) => {
    setNotificationSettings({
      variables: {
        input: {
          userId: userId,
          params: {
            name: name,
            enabled: isSubscribed,
          },
        },
      },
      onCompleted: (response) => onComplete(response),
      onError: onError,
    });
  };

  return {
    data,
    loading,
    error,
    notificationSetting,
    handleNotificationSubscription,
  };
}
