import React from "react";
import PropTypes from "prop-types";
import { ICON_SIZE } from "./Icon";
import { css } from "@emotion/react";

/**
 * ColorIcon
 *
 * @params {String} size
 */
const NotifyIcon = ({ size }) => <span css={styles.icon(size)}>📢</span>;

const styles = {
  icon: (size) => css`
    color: inherit;
    font-size: ${size};
  `,
};

NotifyIcon.defaultProps = {
  size: ICON_SIZE.small,
};

NotifyIcon.propTypes = {
  size: PropTypes.string,
};

export default NotifyIcon;
