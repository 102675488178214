import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useList } from "hooks";
import { BrandedButton, TertiaryButton } from "components/Buttons";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { Spinner } from "components/Loader";

/**
 * AddToListButton
 *
 * @param {String}     profileId
 * @param {Object}     list
 * @param {String}     listType
 * @param {Function}   onCompleted
 */
const AddToListButton = ({ profileId, list, listType, onCompleted }) => {
  const [isProfileAdded, setIsProfileAdded] = useState(false);
  const { addToList, adding } = useList({
    variables: {
      filters: {
        type: listType,
      },
    },
    profileId,
  });

  useEffect(() => {
    if (list && list.profiles && list.profiles.find((p) => p.id === profileId)) {
      setIsProfileAdded(true);
    }
  }, [list, profileId]);

  return (
    <Button
      isProfileAdded={isProfileAdded}
      disabled={adding || isProfileAdded}
      onClick={!isProfileAdded && (() => addToList(list?.id, onCompleted))}
    >
      {adding ? (
        <Spinner size={`2rem`} />
      ) : (
        <>
          <FormattedMessage
            id={`Lists.ListFavoriteButton.${isProfileAdded ? `AlreadyAdded` : `Add`}`}
            values={{
              icon: <Icon type={ICON_TYPE.heart} size={ICON_SIZE.medium} color="inherit" />,
            }}
          />
        </>
      )}
    </Button>
  );
};

const Button = ({ isProfileAdded, ...props }) =>
  isProfileAdded ? <TertiaryButton {...props} /> : <BrandedButton {...props} />;

Button.propTypes = {
  isProfileAdded: PropTypes.bool.isRequired,
};

AddToListButton.propTypes = {
  profileId: PropTypes.string.isRequired,
  list: PropTypes.object.isRequired,
  listType: PropTypes.string.isRequired,
  onCompleted: PropTypes.func,
};

export default AddToListButton;
