import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { UserList } from "components/User";
import { useAuth } from "hooks";
import Tooltip from "components/Tooltip";
import { LINKS } from "constants/index";
import { ProfileModal } from "components/Profile";

/**
 * SquadMembers
 *
 * @param {Object}   data
 * @param {Boolean}  loading
 * @param {Boolean}  hideRate
 * @param {Boolean}  hidePerformance
 */
const SquadMembers = ({ data, loading, hideRate, hidePerformance, ...props }) => {
  const { isAdmin, isFreelancer } = useAuth();
  const navigate = useNavigate();
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const handleRowClick = (data) => {
    if (isFreelancer) {
      window.open(LINKS.public_profile(data?.uuid), "_blank", "noreferrer");
    } else {
      setSelectedProfileId(data?.uuid);
      setShowProfileModal(true);
    }
  };

  return (
    <>
      <UserList
        data={data?.assignments?.map((item) => ({ ...item, ...item?.profile, ...item?.profile?.oldLocation }))}
        team={data}
        title={
          <>
            <Tooltip tooltipId="Squads.Squad.Profile.SquadMembers.Title.Tooltip">
              <FormattedMessage id="Squads.Squad.Profile.SquadMembers.Title" />
            </Tooltip>
          </>
        }
        loading={loading}
        hideRate={hideRate}
        hidePerformance={hidePerformance}
        onClick={handleRowClick}
        {...props}
      />
      {selectedProfileId && (
        <ProfileModal
          id={selectedProfileId}
          show={showProfileModal}
          handleClose={() => setShowProfileModal(false)}
          handlePrimary={() => {
            setShowProfileModal(false);
            if (isAdmin) {
              navigate(LINKS.private_profile(selectedProfileId));
            } else {
              window.open(LINKS.public_profile(selectedProfileId), "_blank");
            }
          }}
        />
      )}
    </>
  );
};

SquadMembers.defaultProps = {
  hideRate: true,
  hidePerformance: true,
};

SquadMembers.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  hideRate: PropTypes.bool,
  hidePerformance: PropTypes.bool,
};

export default SquadMembers;
