import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "components/Buttons";
import { MissionForm } from "components/Missions";
import { LINKS } from "constants/index";
import { Link } from "components/Links";

/**
 * MissionAddButton
 *
 * @param {Boolean} isModal
 */
const MissionAddButton = ({ isModal, ...props }) => {
  const [showForm, setShowForm] = useState(false);

  if (isModal) {
    return (
      <>
        <PrimaryButton onClick={() => setShowForm(true)}>
          <FormattedMessage id={`Missions.MissionAddButton`} />
        </PrimaryButton>
        <MissionForm show={showForm} onClose={() => setShowForm(false)} isModal {...props} />
      </>
    );
  }

  return (
    <Link to={LINKS.mission_create} routerEnabled>
      <PrimaryButton href={LINKS.mission_create}>
        <FormattedMessage id={`Missions.MissionAddButton`} />
      </PrimaryButton>
    </Link>
  );
};

MissionAddButton.propTypes = {
  isModal: PropTypes.bool,
};

export default MissionAddButton;
