import React, { useContext } from "react";
import styled from "@emotion/styled";
import { colors, ThemeContext, fontSize, breakpoints } from "style";

/**
 * Heading 2
 */
const StyledH2 = styled.h2`
  font-size: ${fontSize.large};
  font-weight: 600;
  margin: 0;
  color: ${colors.purpleRainDark2};
  ${(props) => props.themeStyle}

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${fontSize.normal};
  }
`;

const H2 = ({ ...props }) => {
  const theme = useContext(ThemeContext);

  return <StyledH2 themeStyle={theme.h1} {...props} />;
};

export default H2;
