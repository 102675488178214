import React from "react";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { PAGINATION_TYPE } from "components/Pagination";
import { GET_CLIENT_FEEDBACKS } from "graphql/queries";
import { REMOVE_CLIENT_FEEDBACK } from "graphql/mutations";
import { useQueryData, useToast } from "hooks";

export default function useClient({ teamId, resultsPerPage }) {
  const { toast } = useToast();
  const [removeClientFeedback, { loading: deleting }] = useMutation(REMOVE_CLIENT_FEEDBACK, {
    refetchQueries: [GET_CLIENT_FEEDBACKS],
  });
  const { hasNextPage, handleNextPage, hasPreviousPage, handlePreviousPage, loading, data, error } = useQueryData({
    queryName: GET_CLIENT_FEEDBACKS,
    keyName: "clientFeedbacks",
    resultsPerPage,
    paginationType: PAGINATION_TYPE.append,
    variables: {
      filters: {
        teamId,
      },
    },
  });

  /**
   * Remove feedback by id and remove from local cache
   */
  const handleRemoveClientFeedback = (id) => {
    removeClientFeedback({
      variables: {
        input: {
          id,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id={"useClient.RemoveSuccess"} />),
      onError: () => toast.error(<FormattedMessage id={"useClient.RemoveError"} />),
    });
  };

  return {
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    loading: loading || deleting,
    data: data?.clientFeedbacks?.nodes,
    error,
    handleRemoveClientFeedback,
  };
}
