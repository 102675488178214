import React from "react";
import PropTypes from "prop-types";
import { colors } from "style";
import Tooltip from "components/Tooltip";

/**
 * MissionAccomplished2
 *
 * @param {String}   fill
 */
const MissionAccomplished2 = ({ fill, ...props }) => (
  <Tooltip tooltipId={"Badges.MissionAccomplished2"} hideIcon>
    <svg viewBox="0 0 145 137" fill="none" {...props}>
      <path
        d="M87.7557 13.4853L131.822 45.5015C138.832 50.5944 141.765 59.6218 139.088 67.8622L122.256 119.666C119.578 127.906 111.899 133.485 103.235 133.485H48.7654C40.1008 133.485 32.4217 127.906 29.7442 119.666L12.9123 67.8623C10.2348 59.6218 13.1679 50.5944 20.1777 45.5016L64.2443 13.4853C71.254 8.39242 80.746 8.39241 87.7557 13.4853Z"
        stroke={fill || colors.skyblue}
        stroke-width="8"
      />
      <path
        d="M58.1944 37H92.8056C96.9002 37 100.238 40.3637 100.083 44.4371C100.052 45.2703 100.021 46.0727 99.975 46.875H116.292C118.347 46.875 120 48.526 120 50.5781C120 64.866 114.824 74.8027 107.886 81.5455C101.041 88.1957 92.6974 91.5439 86.5323 93.2412C82.9012 94.2287 80.4444 97.2529 80.4444 100.277C80.4444 103.502 83.0712 106.125 86.3005 106.125H90.3333C93.0682 106.125 95.2778 108.331 95.2778 111.062C95.2778 113.794 93.0682 116 90.3333 116H60.6667C57.9318 116 55.7222 113.794 55.7222 111.062C55.7222 108.331 57.9318 106.125 60.6667 106.125H64.6995C67.9288 106.125 70.5556 103.502 70.5556 100.277C70.5556 97.2529 68.0833 94.2441 64.4677 93.2412C58.3181 91.5439 49.9743 88.1957 43.1293 81.5455C36.1762 74.8027 31 64.866 31 50.5781C31 48.526 32.6533 46.875 34.7083 46.875H51.025C50.9786 46.0727 50.9477 45.2549 50.9168 44.4371C50.7623 40.3637 54.0998 37 58.1944 37ZM51.5967 54.2812H38.5712C39.3438 64.5111 43.3611 71.4391 48.3056 76.2377C51.7667 79.6014 55.7686 81.993 59.616 83.6902C56.1085 77.4721 53.0028 68.1834 51.5967 54.2812ZM91.3995 83.6902C95.2469 81.993 99.2488 79.6014 102.71 76.2377H102.725C107.654 71.4391 111.672 64.5111 112.46 54.2812H99.4188C98.0127 68.1834 94.9069 77.4721 91.3995 83.6902ZM69.1709 74.3447V79H82.9521V73.9961H75.3643L80.1016 65.0752C81.0723 63.2568 81.7969 61.6367 82.2754 60.2148C82.7539 58.7793 82.9932 57.5146 82.9932 56.4209V56.0312C82.9932 53.625 82.4121 51.834 81.25 50.6582C80.1016 49.4688 78.3516 48.874 76 48.874C73.6484 48.874 71.8916 49.5098 70.7295 50.7812C69.5811 52.0527 69.0068 53.9873 69.0068 56.585V57.6514H74.1748V56.585C74.1748 55.6553 74.3252 54.9717 74.626 54.5342C74.9404 54.0967 75.4326 53.8779 76.1025 53.8779C76.7041 53.8779 77.1416 54.0557 77.415 54.4111C77.6885 54.7529 77.8252 55.2861 77.8252 56.0107V56.4004C77.8252 57.3164 77.6133 58.3965 77.1895 59.6406C76.7656 60.8848 76.0957 62.2998 75.1797 63.8857L69.1709 74.3447Z"
        fill={fill || colors.skyblue}
      />
    </svg>
  </Tooltip>
);

MissionAccomplished2.propTypes = {
  fill: PropTypes.string,
};

export default MissionAccomplished2;
