import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import {
  ProfileHeader,
  ProfileDescription,
  ProfilePic,
  ProfileSocialIcons,
  ProfileMemberSince,
  HireButton,
  BookMeetingButton,
} from "components/Profile";
import { breakpoints, margins } from "style";

/**
 * ProfileBio
 *
 * @param {Object}   profileCompletion
 * @param {String}   marginSize
 * @param {Boolean}  hideDescription
 * @param {Object}   profilePicProps
 */
const ProfileBio = ({ profileCompletion, marginSize, hideDescription, profilePicProps, ...props }) => (
  <div css={styles.container}>
    <div css={styles.left_column}>
      <ProfileHeader {...props} />
      {!hideDescription && (
        <ProfileDescription marginSize={marginSize} isEmpty={profileCompletion?.bio?.isEmpty} {...props} />
      )}
    </div>
    <div css={styles.right_column}>
      <ProfilePic marginSize={marginSize} isEmpty={profileCompletion?.photo?.isEmpty} {...profilePicProps} {...props} />
      <HireButton style={styles.hire_button} />
      <BookMeetingButton style={styles.hire_button} />
      <ProfileMemberSince {...props} />
      <ProfileSocialIcons marginSize={marginSize} {...props} />
    </div>
  </div>
);

const styles = {
  container: css`
    display: flex;
    font-size: 12px;

    @media (max-width: ${breakpoints.mobile}) {
      flex-direction: column-reverse;
    }
  `,
  left_column: css`
    flex: 1;
    margin-right: 4rem;

    @media (max-width: ${breakpoints.mobile}) {
      margin-right: 0;
      min-height: 0;
    }
  `,
  right_column: css`
    @media (max-width: ${breakpoints.mobile}) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 0;
    }
  `,
  hire_button: css`
    margin-top: 1rem;
  `,
};

ProfileBio.defaultProps = {
  marginSize: margins.normal,
  hideDescription: false,
};

ProfileBio.propTypes = {
  profileCompletion: PropTypes.object,
  marginSize: PropTypes.string,
  hideDescription: PropTypes.bool,
  profilePicProps: PropTypes.object,
};

export default ProfileBio;
