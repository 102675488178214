import { gql } from "@apollo/client";

const GET_ACQUISITION_SOURCE = gql`
  query GetSource ($filters: SourceFilter){
    acquisitionSource(filters: $filters) {
      nodes {
        name
        id
      }
    }
  }
`;

export { GET_ACQUISITION_SOURCE };
