import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { colors, fontSize } from "style";
import { UserFullName } from "components/User";
import { formatDateFromNow } from "utils";

/**
 * CardPostTitle
 *
 * @params {Object}   user
 * @params {String}   date
 */
const CardPostTitle = ({ user, date }) => (
  <div css={styles.title}>
    <FormattedMessage
      id="Card.CardPostTitle.Name"
      values={{
        name: (
          <UserFullName
            data={user}
            photoProps={{
              width: `3rem`,
              height: `3rem`,
              borderRadius: `100%`,
            }}
            withPhoto
          />
        ),
        date: <span css={styles.time}>{formatDateFromNow(date)}</span>,
      }}
    />
  </div>
);

const styles = {
  title: css`
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
  time: css`
    font-weight: 300;
    color: ${colors.grayAnatomyLight6};
    font-size: ${fontSize.xsmall};
  `,
};

CardPostTitle.propTypes = {
  user: PropTypes.object.isRequired,
  date: PropTypes.string,
};

export default CardPostTitle;
