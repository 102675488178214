import * as am5 from "@amcharts/amcharts5";
import { AM5_LICENSE_KEY } from "constants/index";

/**
 * Initialize AmCharts
 *
 * @returns {void}
 */

export default function initializeAmCharts() {
  if (AM5_LICENSE_KEY) {
    am5.addLicense(AM5_LICENSE_KEY);
  }
}
