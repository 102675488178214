import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { MUTATION_TYPE } from "hooks";
import { LIST_TYPES } from "constants/index";
import { CREATE_NEW_LIST } from "graphql/mutations";
import { GET_LIST } from "graphql/queries";
import { MODAL_SIZE, ModalForm } from "components/Modal";
import { SIZE, TYPE } from "components/Form";

/**
 * CreateListModal
 * @param {String}    profileId
 * @param {String}    listType
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Function}  onComplete
 */
const CreateListModal = ({ profileId, listType, show, onClose, onComplete }) => {
  const formProps = {
    initialValues: { type: listType, profileId },
    mutationType: MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: CREATE_NEW_LIST,
      },
      refetchAfterMutate: true,
      refetchQueries: [GET_LIST],
    },
  };

  return (
    <ModalForm
      show={show}
      size={MODAL_SIZE.small}
      data={formData({ listType })}
      title={<FormattedMessage id="Lists.AddToListsModal.TitleCreate" />}
      onCompleted={onComplete}
      onClose={onClose}
      {...formProps}
    />
  );
};

const formData = ({ listType }) => {
  const defaultData = [
    {
      style: css`
        width: 100%;
      `,
      items: [
        {
          label: "Name",
          type: TYPE.input,
          size: SIZE.xlarge,
          properties: {
            name: "title",
            type: "text",
            required: true,
            autoFocus: true,
            placeholder: "Name of List",
          },
        },
        {
          type: TYPE.hidden,
          properties: {
            name: "profileId",
          },
        },
      ],
    },
  ];

  if (listType) {
    defaultData[0].items.push({
      type: TYPE.hidden,
      properties: {
        name: "type",
      },
    });
  } else {
    defaultData[0].items.push({
      label: "Type",
      type: TYPE.select,
      size: SIZE.xlarge,
      properties: {
        name: "type",
        required: true,
        options: [
          { label: "General", value: LIST_TYPES.general },
          { label: "Featured Team", value: LIST_TYPES.featuredTeam },
          { label: "Favorite", value: LIST_TYPES.favorite },
        ],
      },
    });
  }

  return defaultData;
};

CreateListModal.propTypes = {
  profileId: PropTypes.string,
  listType: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
};

export default CreateListModal;
