import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import { UserPhoto } from "components/User";
import MissionLogo from "assets/images/white-mission-logo.png";
import styled from "@emotion/styled";
import Image from "components/Image";
import { formatDateFromNow } from "utils";
import { Spinner } from "components/Loader";

/**
 * ChatMessage
 *
 * @param {String}  content
 * @param {String}  createdAt
 * @param {Object}  profile
 * @param {String}  role
 * @param {Boolean} isLoading
 */
const ChatMessage = ({ content, createdAt, profile, role, isLoading, ...props }) => {
  const isMe = role === "user";

  return (
    <>
      <div css={styles.container(isMe)} {...props}>
        <div css={styles.pic}>
          {isMe ? (
            <UserPhoto data={profile} width="3rem" height="3rem" borderRadius="100%" />
          ) : (
            <div css={styles.system_pic}>
              <Logo url={MissionLogo} alt="Mission Logo" />
            </div>
          )}
        </div>
        <div css={styles.text(isMe)}>
          {content}
          {isLoading && <Spinner size="1.5rem" />}
        </div>
      </div>
      {!isLoading && <div css={styles.time}>{formatDateFromNow(createdAt)}</div>}
    </>
  );
};

const Logo = styled(Image)`
  width: 2rem;
`;

const styles = {
  container: (isMe) => css`
    display: flex;
    padding: 0 2rem;
    gap: 1rem;
    font-size: ${fontSize.small};
    line-height: 1.5rem;
    align-items: flex-end;
    max-width: 80%;

    ${isMe &&
    css`
      flex-direction: row-reverse;
      align-items: flex-end;
      margin-left: auto;
      margin-right: 0;
    `}
  `,
  system_pic: css`
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    background: ${colors.black};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  pic: css`
    position: relative;
    top: 1.5rem;
  `,
  text: (isMe) => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 0.5rem;
    line-height: normal;
    color: ${isMe ? colors.white : colors.grayAnatomyBase};
    background: ${isMe ? colors.blueVelvetBase : colors.grayAnatomyLight4};
    font-weight: ${isMe ? 600 : 400};
    padding: 2rem;
    border-radius: ${isMe ? `2rem 2rem 0rem 2rem` : `2rem 2rem 2rem 0`};
    white-space: pre-line;
  `,
  time: css`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: ${fontSize.xxsmall};
    color: ${colors.grayAnatomyLight1};
    justify-content: center;
    text-align: center;
    margin: 0.5rem 0;
  `,
};

ChatMessage.propTypes = {
  content: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default ChatMessage;
