/** @jsx jsx */
import styled from "@emotion/styled";
import { colors, fontSize } from "style";

/**
 * Label
 */
const Button = styled.button`
  height: 3.8rem;
  min-height: 3.8rem;
  font-size: ${fontSize.small};
  font-weight: 600;
  background: ${colors.purpleRainBase};
  color: #fff;
  border-radius: 0.6rem;
  line-height: 2.4rem;
  border: solid 2px ${colors.purpleRainBase};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.2rem;
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;
  width:100%;

  &:hover:not(:disabled) {
    color: #fff;
    background: ${colors.purpleRainDark1};
    box-shadow: 0 1px 6px 0 rgba(56, 51, 148, 0.3);
  }
`;

export default Button;
