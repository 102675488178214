import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { fontSize, margins } from "style";
import { useProfile } from "hooks";
import { Toggle } from "components/Form";
import DisplayBox from "components/DisplayBox";
import { css } from "@emotion/react";
import { TextLoader } from "components/Loader";

/**
 * PreferencesComponent
 *
 * @param {String} id - Profile ID
 */
const PreferencesComponent = ({ id }) => {
  const { data, loading, updatePreferences } = useProfile({ profileId: id });

  return (
    <DisplayBox
      title={<FormattedMessage id="Profile.ProfilePreferences.Title" />}
      numColumns={1}
      marginSize={margins.normal}
      data={Object.entries(data?.profile?.user?.preferences.profile || [])}
      loading={loading}
      itemRenderer={(item) => {
        const key = item[0];
        const isEnabled = item[1];

        return loading ? (
          <TextLoader />
        ) : (
          <div css={styles.container}>
            <span css={styles.info}>
              <FormattedMessage id={`Profile.ProfilePreferences.${key}.Title`} />
            </span>
            <Toggle value={isEnabled} onChange={() => updatePreferences(key, !isEnabled)} />
          </div>
        );
      }}
    />
  );
};

const styles = {
  info: css`
    max-width: 70%;
    line-height: ${fontSize.medium};
  `,
  container: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
};

PreferencesComponent.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PreferencesComponent;
