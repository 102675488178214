import React from "react";
import PropTypes from "prop-types";
import { LINKS } from "constants";
import Overlay from "components/Overlay";
import { PrimaryLink } from "components/Links";
import { OrganizationCard } from ".";
import { MissionOrganizationName } from "components/Missions";
import { useAuth } from "hooks";

/**
 * OrganizationLink
 *
 * @param {Object}  data
 * @param {Boolean} withOverlay
 * @param {String}  placement
 */
const OrganizationLink = ({ data, withOverlay, placement, ...props }) => {
  const { isAdmin } = useAuth();

  const Link = () => (
    <PrimaryLink to={LINKS.organization_profile(data?.slug)} routerEnabled>
      <MissionOrganizationName data={data} />
    </PrimaryLink>
  );

  if (!isAdmin) {
    return <MissionOrganizationName data={data} />;
  }

  if (!withOverlay) {
    return <Link />;
  }

  return (
    <Overlay
      asCardOverlay
      placement={placement}
      width={`30rem`}
      overlay={<OrganizationCard data={data} />}
      trigger={<Link />}
      delay={{ show: 500 }}
      {...props}
    />
  );
};

OrganizationLink.defaultProps = {
  withOverlay: false,
  placement: "right",
};

OrganizationLink.propTypes = {
  data: PropTypes.object.isRequired,
  withOverlay: PropTypes.bool,
  placement: PropTypes.string,
};

export default OrganizationLink;
