import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactEditor } from "slate-react";
import styled from "@emotion/styled";
import DropDown, { DropDownMenu, DropDownItem } from "components/DropDown";
import { GET_ADMINS } from "graphql/queries";
import { useQueryData } from "hooks";

/**
 * MentionList
 *
 * @param {Object}    editor
 * @param {Number}    index
 * @param {Object}    target
 * @param {String}    search
 * @param {Function}  onSelect
 */
const MentionList = ({ editor, index, target, search, onSelect }) => {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [chars, setChars] = useState([]);

  const { data } = useQueryData({
    queryName: GET_ADMINS,
    keyName: "admins",
    resultsPerPage: 20,
    variables: {
      id: "admins",
      filters: {
        search,
      },
    },
  });

  useEffect(() => {
    setChars(data?.admins?.nodes?.filter((c) => c?.name?.toLowerCase().startsWith(search.toLowerCase())).slice(0, 10));
  }, [data?.admins?.nodes, search]);

  useEffect(() => {
    if (target && chars?.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();

      el.style.top = `${rect.top + window.pageYOffset + 14}px`; // eslint-disable-line
      el.style.left = `${rect.left + window.pageXOffset}px`; // eslint-disable-line
      setShow(true);
    }
  }, [chars?.length, editor, index, search, target]);

  if (!target || chars?.length === 0) return null;

  return (
    <Container ref={ref}>
      <DropDown>
        <DropDownMenu show={show}>
          {chars?.map((char, i) => (
            <DropDownItem key={char?.id} onClick={() => onSelect(i, char)}>
              {char?.name}
            </DropDownItem>
          ))}
        </DropDownMenu>
      </DropDown>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: 1000;
`;

MentionList.propTypes = {
  editor: PropTypes.object,
  index: PropTypes.number,
  target: PropTypes.object,
  search: PropTypes.string,
  onSelect: PropTypes.func,
};

export default MentionList;
