import { gql } from "@apollo/client";

const GET_DISCIPLINES = gql`
  query GetDisciplines($filters: TagFilter) {
    disciplines(filters: $filters) {
      nodes {
        name
        id
      }
    }
  }
`;

export { GET_DISCIPLINES };
