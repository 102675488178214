import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Tutorials from "./Tutorials";
import { CLOUDFRONT_URL } from "constants";
import ShowcaseDemo from "assets/images/video_covers/showcase_demo.jpg";
import { css } from "@emotion/react";

const TUTORIAL_SHOWCASE_KEYNAME = `showcase_tour_visible`;

/**
 * WelcomeVideo
 *
 * @param {Boolean} isShowcase
 */
const WelcomeVideo = ({ isShowcase, ...props }) => {
  if (!isShowcase) return null;

  return (
    <VideoContainer>
      <Tutorials
        source="ShowcaseDemo"
        keyName={TUTORIAL_SHOWCASE_KEYNAME}
        videoList={[
          {
            name: "Showcase Demo",
            cover: ShowcaseDemo,
            source: `${CLOUDFRONT_URL}videos/demos/demo_product.m4v`,
            duration: "2:08",
          },
        ]}
        text={{}} // sending empty to avoid failure
        hideOnClose
        isCloseable={false}
        videoProps={{
          css: css`
            border-radius: 0;
          `,
        }}
        {...props}
      />
    </VideoContainer>
  );
};

const VideoContainer = styled.div``;

WelcomeVideo.propTypes = {
  isShowcase: PropTypes.bool,
};

export default WelcomeVideo;
