import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import Form, { SIZE, TYPE } from "components/Form";
import { GET_INDUSTRIES } from "graphql/queries";
import { margins } from "style";

/**
 * CompanyDetailForm
 *
 * @params {Object}     values
 */
const CompanyDetailForm = ({ values, ...props }) => {
  const [initialValues] = useState(values);

  return (
    <Form
      data={FORM_DATA()}
      initialValues={initialValues}
      marginSize={margins.none}
      saveButton={{
        show: true,
        labelId: "Global.Continue",
      }}
      {...props}
    />
  );
};

const FORM_DATA = () => [
  {
    style: css`
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
    `,
    items: [
      {
        label: <FormattedMessage id="Signup.CompanyDetail.FormLabelTitle" />,
        type: TYPE.h4,
        size: SIZE.xlarge,
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.CompanyDetail.FormLabelName" />,
        parentObjectKey: "organization",
        properties: {
          name: "name",
          placeholder: "Company Name",
          autoFocus: true,
          required: true,
        },
      },
      {
        type: TYPE.tags,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.CompanyDetail.FormLabelIndustry" />,
        parentObjectKey: "organization",
        properties: {
          name: "industries",
          queryName: GET_INDUSTRIES,
          queryKeyName: "industries",
          minLength: 0,
          required: true,
        },
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.CompanyDetail.FormLabelLocation" />,
        parentObjectKey: "organization",
        properties: {
          name: "location",
          placeholder: "Location",
          required: true,
        },
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.CompanyDetail.FormLabelEmployees" />,
        parentObjectKey: "organization",
        properties: {
          type: "number",
          name: "noOfEmployees",
          required: true,
        },
      },
      {
        type: TYPE.input,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Signup.CompanyDetail.FormLabelEngineers" />,
        parentObjectKey: "organization",
        properties: {
          type: "number",
          name: "noOfEngineers",
          required: true,
        },
      },
    ],
  },
];

CompanyDetailForm.propTypes = {
  values: PropTypes.object,
};

export default CompanyDetailForm;
