import { gql } from "@apollo/client";
import {
  APPLICANT_RATE_FIELDS,
  CHALLENGE_RESPONSES_FIELDS,
  MINIMAL_MISSION_ROLE_FIELDS,
  MINIMAL_PROFILE_FIELDS,
} from "graphql/fragments";

const GET_MISSION_ROLE_APPLICANT = gql`
  ${APPLICANT_RATE_FIELDS}
  ${MINIMAL_PROFILE_FIELDS}
  ${MINIMAL_MISSION_ROLE_FIELDS}
  ${CHALLENGE_RESPONSES_FIELDS}
  query missionRoleApplicant($id: ID!, $profileChallengesStatus: ChallengeResponseStatus) {
    missionRoleApplicant(id: $id) {
      id
      state
      appliedAt
      noticePeriod
      availablePartTimeDuringNotice
      recommendedRate {
        amount
        currency
        frequency
      }
      rateScore
      matchScore
      subScores
      privateNotes
      privateNotesJson
      challengesCompletedByProfile {
        ...ChallengeResponseFields
      }
      profile {
        ...ProfileFields
        id
        archetype
        memberSince
        email
        disciplines {
          id
          name
        }
        skills {
          id
          name
        }
        challengeResponses(status: $profileChallengesStatus) {
          ...ChallengeResponseFields
        }
      }
      missionRole {
        ...MissionRoleFields
        id
        assessmentId
        archetype
        disciplines {
          id
          name
        }
        primarySkills {
          id
          name
        }
        secondarySkills {
          id
          name
        }
        locations {
          id
          name
        }
      }
      ...ApplicantRateFields
    }
  }
`;

export { GET_MISSION_ROLE_APPLICANT };
