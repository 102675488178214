/**
 * Format to hours clockFormat (01:30) or hour format (1h30)
 *
 * @param {String}      str
 * @param {Boolean}     clockFormat
 *
 * @returns {String} The formatted hours
 */
export default function formatHours(input = 0, clockFormat = true, humanize = false) {
  let num = parseFloat(input);

  const isNegative = num < 0;
  const divider = 60;
  const separator = clockFormat ? `:` : `h`;

  num = Math.abs(num);

  // Used Math.floor as we are tracking minutes along with hours
  // so we don't count minutes into hours
  let hours = String(Math.floor(num / divider));
  // Used Math.ceil as we don't track seconds
  // so what we have is all about minutes
  let minutes = String(Math.ceil(num % divider));
  let hoursPadded = String(hours).padStart(2, 0);
  let minutesPadded = String(minutes).padStart(2, 0);

  if (humanize) {
    let hoursReadable = hours === 1 ? `hour` : `hours`;
    let minsReadable = minutes === 1 ? `min` : `mins`;

    return hours > 0 ? `${hours} ${hoursReadable} ${minutes} ${minsReadable}` : `${minutes} ${minsReadable}`;
  } else {
    let formattedTime = clockFormat
      ? `${hoursPadded}${separator}${minutesPadded}`
      : `${hours}${separator}${minutes > 0 ? minutes : ``}`;

    if (isNegative) {
      formattedTime = `-${formattedTime}`;
    }

    return formattedTime;
  }
}
