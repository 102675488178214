import { gql } from "@apollo/client";

const UPDATE_TIME_SLIP = gql`
  mutation UpdateTimeSlip($input: UpdateTimeSlipInput!) {
    updateTimeSlip(input: $input) {
      timeSlip {
        timeCardId
        teamId
        versionId
        assignmentId
        activityType
        activityDate
        timeLogged
        description
      }
    }
  }
`;

const UPDATE_TIME_SLIPS = gql`
  mutation UpdateTimeSlips($input: UpdateTimeSlipsInput!) {
    updateTimeSlips(input: $input) {
      timeSlips {
        timeCardId
        teamId
        versionId
        assignmentId
        activityType
        activityDate
        timeLogged
        description
      }
    }
  }
`;

export { UPDATE_TIME_SLIP, UPDATE_TIME_SLIPS };
