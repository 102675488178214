import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { UPDATE_PROFILE } from "graphql/mutations";
import { GET_BADGES } from "graphql/queries";
import DisplayBox from "components/DisplayBox";
import Tags from "components/Tags";
import { SIZE, TYPE } from "components/Form";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { PAGES } from "constants/index";

/**
 * ProfileBadges
 *
 * @params {Array}    data
 */
const ProfileBadges = ({ data, ...props }) => (
  <DisplayBox
    title={<FormattedMessage id="Profile.ProfileBadges.TitleBadges" />}
    editButtonText={<FormattedMessage id="Profile.ProfileBadges.AddBadges" />}
    numColumns={1}
    data={BADGES_DATA(data)}
    parentKeyName="id"
    parentKeyValue={data?.id}
    updateId={data?.id}
    initialValues={{
      badges: data?.badges?.map((item) => ({
        name: item.name,
        id: item.id,
      })),
    }}
    {...props}
  />
);

const BADGES_DATA = (data) => [
  {
    title: null,
    body: data?.badges?.length > 0 && <Tags data={data?.badges} />,
  },
];

const FORM_DATA = [
  {
    items: [
      {
        label: "Badges",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "badges",
          queryName: GET_BADGES,
          placeholder: "Add badges...",
          queryKeyName: "badges",
          labelkey: "name",
          secondaryLabelKey: "description",
          minLength: 0,
          autoFocus: true,
          defaultOpen: true,
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Profile.ProfileBadges.AddBadges" />,
  mutationData: {
    mutationName: {
      update: UPDATE_PROFILE,
      add: UPDATE_PROFILE,
    },
  },
  page: PAGES.badges,
};

ProfileBadges.propTypes = {
  data: PropTypes.object,
};

export { FORM_SETTINGS };

export default withModalForm(ProfileBadges, FORM_SETTINGS);
