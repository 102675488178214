import { gql } from "@apollo/client";

const SHOWCASE_SIGNUP = gql`
  mutation showcaseSignUp($input: ShowcaseSignUpInput!) {
    showcaseSignUp(input: $input) {
      user {
        id
        email
      }
    }
  }
`;

export { SHOWCASE_SIGNUP };
