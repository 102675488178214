import React, { useState, useMemo, useRef } from "react";
import { FormattedMessage } from "react-intl";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { DataTableProvider } from "components/DataTable";
import { PAGINATION_TYPE } from "components/Pagination";
import Status from "components/Status";
import { UserFullName } from "components/User";
import { UserUpdateForm } from "components/Settings/Users";
import Tabs from "components/Tabs";
import { Grid } from "components/Containers";
import { Select, TextInput } from "components/Form";
import { ICON_POSITION, ICON_SIZE, ICON_TYPE, withIcon } from "components/Icon";
import { USER_ACTIVATION_STATUSES } from "constants";
import { GET_PLATFORM_ACCESS_USERS } from "graphql/queries";
import { useAccessUsers } from "hooks";
import { OrganizationLink } from "components/Organization";

/**
 * UserList
 *
 * @param {Number} resultsPerPage
 */
const UserList = ({ resultsPerPage }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [keyword, setKeyword] = useState();
  const [debounceKeyword, setDebounceKeyword] = useState();
  const [selectedUserType, setSelectedUserType] = useState(false);
  const datatable = useRef(null);
  const { getAdvancedOptions, selectedUser, showForm, setShowForm, keyName } = useAccessUsers();
  const debouncedSetKeyword = useMemo(() => debounce((val) => setDebounceKeyword(val), 500), []);
  const refetch = () => datatable.current.refetch();

  const handleSearchInputChange = (val) => {
    setKeyword(val);
    debouncedSetKeyword(val);
  };

  return (
    <>
      <Grid>
        <Grid.col start={1} end={7}>
          <Tabs
            data={TAB_OPTIONS?.map(({ name }) => name)}
            selected={selectedTab}
            onSelect={setSelectedTab}
            withUrlParams
          />
        </Grid.col>
        <Grid.col start={7} end={10}>
          <Select
            value={selectedUserType}
            placeholder="Status"
            options={USER_ACTIVATION_STATUSES}
            valueKey="value"
            labelKey="name"
            onChange={(name, val) => setSelectedUserType(val)}
            isClearable
          />
        </Grid.col>
        <Grid.col start={10} end={13}>
          <SearchInput
            value={keyword}
            onChange={(name, val) => handleSearchInputChange(val)}
            type="search"
            placeholder="Search by name or email..."
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <DataTableProvider
            ref={datatable}
            columns={COLUMN_DATA(selectedTab)}
            rowOptions={getAdvancedOptions}
            resultsPerPage={resultsPerPage}
            variables={{
              filters: {
                type: TAB_OPTIONS[selectedTab].value,
                search: debounceKeyword,
                deactivated: selectedUserType,
              },
            }}
            keyName={keyName}
            queryName={GET_PLATFORM_ACCESS_USERS}
            paginationType={PAGINATION_TYPE.classic}
            selectable={false}
            paginationProps={{
              countMessage: "Global.Users.Count",
            }}
          />
        </Grid.col>
      </Grid>
      <UserUpdateForm show={showForm} onClose={() => setShowForm(false)} userData={selectedUser} refetch={refetch} />
    </>
  );
};

const TAB_OPTIONS = [
  { name: "All", id: 0, value: "all" },
  { name: "Admin", id: 1, value: "admin" },
  { name: "Client", id: 2, value: "client" },
  { name: "Recruiter", id: 3, value: "recruiter" },
  { name: "Partner", id: 4, value: "partner" },
  { name: "Showcase", id: 5, value: "showcase" },
  { name: "Signal", id: 6, value: "signal" },
];

const COLUMN_DATA = (selectedTab) => [
  {
    label: "Users",
    width: `30%`,
    cell: (row) => (
      <UserFullName data={row} withPhoto photoProps={{ width: "3rem", height: "3rem", borderRadius: "100%" }} />
    ),
  },
  {
    label: "Email",
    cell: (row) => row?.email,
    width: "25%",
  },
  ...(TAB_OPTIONS[selectedTab].value === "partner" ? PARTNER_COLUMN : []),
  ...(TAB_OPTIONS[selectedTab].value === "showcase" ? SHOWCASE_COLUMN : []),
  ...(TAB_OPTIONS[selectedTab].value === "client" ? CLIENT_COLUMN : []),
  ...(TAB_OPTIONS[selectedTab].value === "signal" ? CLIENT_COLUMN : []),
  ...(TAB_OPTIONS[selectedTab].value === "all" ? ALL_COLUMN : []),
  {
    label: "",
    cell: ({ signInCount }) =>
      signInCount === 0 && (
        <Status enabled={false}>
          <FormattedMessage id={`Settings.Users.UsersList.Pending`} />
        </Status>
      ),
    width: "15%",
  },
  {
    label: "Status",
    width: "15%",
    cell: ({ deactivated }) => (
      <div>
        <Status enabled={!deactivated}>
          <FormattedMessage id={`Settings.Users.UsersList.${deactivated ? `Inactive` : `Active`}`} />
        </Status>
      </div>
    ),
  },
];

const PARTNER_COLUMN = [
  {
    label: "Partner",
    width: "15%",
    cell: (row) => row?.partner,
  },
];

const SHOWCASE_COLUMN = [
  {
    label: "Referrer",
    width: "15%",
    cell: (row) => row?.referrer,
  },
];

const CLIENT_COLUMN = [
  {
    label: "Organization",
    width: "25%",
    cell: (row) => <OrganizationLink data={row?.organization} withOverlay />,
  },
];

const ALL_COLUMN = [
  {
    label: "Role",
    cell: (row) => row?.role,
    width: "15%",
  },
];

const SearchInput = withIcon(TextInput, {
  type: ICON_TYPE.search,
  size: ICON_SIZE.medium,
  position: ICON_POSITION.overlay,
  order: 0,
});

UserList.propTypes = {
  resultsPerPage: PropTypes.number,
};

UserList.defaultProps = {
  resultsPerPage: 10,
};

export default UserList;
