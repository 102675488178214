import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useToast, useFormData, MUTATION_TYPE } from "hooks";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { SUBMIT_TIME_CARD } from "graphql/mutations";
import { GET_MISSION_REQUIREMENTS } from "graphql/queries";
/**
 * SubmitForm
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Boolean}   loading
 * @param {Boolean}   isEditing
 * @param {String}    title
 * @param {Function}  description
 * @param {Number}    surveyId
 * @param {Function}  onComplete
 */
const SubmitForm = ({ show, onClose, loading, isEditing, title, description, surveyId, onComplete, ...props }) => {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(loading);

  const queryData = {
    queryName: GET_MISSION_REQUIREMENTS,
    keyName: "assessmentFetch",
    skip: !surveyId,
    variables: {
      id: surveyId,
    },
  };

  const { data, initialValues, loading: formDataLoading } = useFormData(queryData);

  useEffect(() => {
    setIsLoading(loading || formDataLoading);
  }, [loading, formDataLoading]);

  const mutationData = {
    mutationName: {
      update: SUBMIT_TIME_CARD,
    },
    refetchAfterMutate: false,
  };

  const formProps = {
    initialValues,
    mutationData,
    queryData,
    mutationType: MUTATION_TYPE.update,
  };

  if (isEditing) return null;

  const handleComplete = (response) => {
    toast.success(<FormattedMessage id="TimeSheet.SubmitMonthButton.Success" />);
    onComplete?.(response);
  };

  const handleError = () => {
    toast.error(<FormattedMessage id="TimeSheet.SubmitMonthButton.Error" />);
  };

  return (
    <ModalForm
      loading={isLoading}
      data={data}
      show={show}
      size={MODAL_SIZE.large}
      onClose={onClose}
      title={title}
      description={description(isLoading)}
      onCompleted={handleComplete}
      onError={handleError}
      {...formProps}
      {...props}
    />
  );
};

SubmitForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  isEditing: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.func.isRequired,
  surveyId: PropTypes.number,
  onComplete: PropTypes.func,
};

export default SubmitForm;
