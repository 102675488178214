import React from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { LINKS } from "constants/index";
import { colors, fontSize, breakpoints } from "style";
import { WhiteLogo } from "components/Logo";
import { PrimaryLink } from "components/Links";

/**
 * Signup Header
 */
const SignupHeader = () => (
  <div css={styles.logo_container}>
    <span css={styles.already_member}>
      <FormattedMessage
        id="Signup.SignupHeader.Text"
        values={{
          link: (
            <PrimaryLink href={LINKS.login} css={styles.link}>
              <FormattedMessage id="Signup.SignupHeader.Link" />
            </PrimaryLink>
          ),
        }}
      />
    </span>
  </div>
);

const styles = {
  logo_container: css`
    position: absolute;
    top: 2.5rem;
    left: 2.5rem;
    right: 2.5rem;
    height: 3rem;
    display: flex;
    justify-content: flex-end;

    @media (max-width: ${breakpoints.mobile}) {
      position: static;
      width: 100%;
      height: auto;
      padding: 2rem 2.5rem;
      background: ${colors.greyBackground};
    }
  `,
  already_member: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyLight1};
  `,
  link: css`
    @media (max-width: ${breakpoints.mobile}) {
      color: ${colors.purpleRainLight3};
    }
  `,
};

export default SignupHeader;
