import { css } from "@emotion/react";
import PropTypes from "prop-types";
import React from "react";
import { Tag, EditableTag, TAG_SIZE } from "components/Tags";
import { formatNumber } from "utils";

/**
 * TagsWrapper
 *
 * @param {Array}      data
 * @param {Boolean}    isRemoveable
 * @param {Function}   onRemove
 * @param {String}     color
 * @param {String}     background
 * @param {Number}     limit
 * @param {String}     size
 * @param {Boolean}    unwrap
 */
const Tags = ({ data, isRemoveable, onRemove, color, background, limit, size, unwrap, ...props }) => {
  if (!data || data.length === 0) return null;

  const Container = ({ children }) => unwrap ? children : <div css={styles.tags_wrapper(size)}>{children}</div>;

  return (
    <Container {...props}>
      {data.slice(0, limit).map((item) =>
        !isRemoveable ? (
          <Tag key={item.name} color={item.color || color} background={background} size={size}>
            {item.name}
          </Tag>
        ) : (
          <EditableTag key={item.name} onRemove={onRemove}>
            {item.name}
          </EditableTag>
        )
      )}
      {!isRemoveable && data?.length > limit && (
        <Tag size={size} color={color} background={background}>
          +{formatNumber(data?.length - limit)}
        </Tag>
      )}
    </Container>
  );
};

const styles = {
  tags_wrapper: (size) => css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: ${size === TAG_SIZE.small ? `0.2rem` : `0.4rem`};
  `,
};

Tags.defaultProps = {
  limit: 50,
  size: "medium",
  unwrap: false
};

Tags.propTypes = {
  data: PropTypes.array,
  isRemoveable: PropTypes.bool,
  onRemove: PropTypes.func,
  color: PropTypes.string,
  background: PropTypes.string,
  limit: PropTypes.number,
  size: PropTypes.string,
  unwrap: PropTypes.bool
};

export default Tags;
