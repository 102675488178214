import React from "react";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useList } from "hooks";
import { LINKS, LIST_TYPES } from "constants/index";
import { Grid, PageWrapper } from "components/Containers";
import { BannerShowcaseSignup } from "components/Banner";
import { css } from "@emotion/react";
import PageTitle from "components/PageTitle";
import { TeamCard } from "components/Teams";
import { CardList } from "components/Card";

/**
 * Favorites
 */
const Favorites = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { data, loading } = useList({
    variables: {
      includeProfileList: false,
      includeUser: false,
      includeBadgesFields: false,
      includeProfileAvailabilityFields: false,
      includeDisciplineFields: false,
      includeSkillsFields: false,
      includeLocationFields: false,
      includeRateFields: false,
      includeWorkExperiencesFields: false,
      filters: {
        type: LIST_TYPES.favorite,
      },
    },
  });

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Favorites.Title" })}</title>
      </Helmet>
      <BannerShowcaseSignup
        css={css`
          margin: 4rem 0 1rem 0;
        `}
      />
      <Grid cols={3}>
        <Grid.col start={1} end={4}>
          <PageTitle title={<FormattedMessage id="Favorites.H1" />} />
        </Grid.col>
        <Grid.col start={1} end={4}>
          <CardList
            renderer={TeamCard}
            data={data}
            loading={loading}
            rendererProps={{
              onClick: (list) => navigate(LINKS.favorite(list.slug)),
            }}
          />
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default Favorites;
