import { gql } from "@apollo/client";
import { CLIENT_FEEDBACK_FIELDS } from "graphql/fragments";

const GET_CLIENT_FEEDBACKS = gql`
  ${CLIENT_FEEDBACK_FIELDS}
  query GetClientFeedbacks($filters: ClientFeedbackFilter, $first: Int, $last: Int, $before: String, $after: String) {
    clientFeedbacks(filters: $filters, first: $first, last: $last, before: $before, after: $after) {
      nodes {
        ...ClientFeedbackFields
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export { GET_CLIENT_FEEDBACKS };
