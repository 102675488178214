import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { fontSize } from "style";
import { MODAL_SIZE } from "components/Modal";
import Modal from "components/Modal";
import { CheckBox } from "components/Form";
import { PrivacyPolicyContent, TermsAndConditionsContent } from "components/Legal";
import { useAuth, useLegalModal } from "hooks";

/**
 * SignModal
 */
const SignModal = (props) => {
  const { isImpersonating, ...me } = useAuth();
  const { handleAcceptTerms, handleAcceptPrivacy, privacySaving, termsSaving, needsSignPrivacy, needsSignTerms } =
    useLegalModal(me);
  const [show, setShow] = useState(false);
  const [stepsRemaining, setStepsRemaining] = useState(0);
  const [isChecked, setIsChecked] = useState({});
  const [page, setPage] = useState(PAGES.terms);
  const handleChange = (name, value) => setIsChecked((prev) => ({ ...prev, [page]: value }));

  const handleAccept = () => {
    if (page === PAGES.terms) {
      handleAcceptTerms();
    } else {
      handleAcceptPrivacy();
    }
  };

  useEffect(() => {
    setPage(needsSignTerms ? PAGES.terms : "privacy");
  }, [needsSignTerms, needsSignPrivacy]);

  useEffect(() => {
    if (isImpersonating) return;

    setStepsRemaining(needsSignPrivacy && needsSignTerms ? 1 : 0);
    setShow(needsSignPrivacy || needsSignTerms);
  }, [needsSignPrivacy, needsSignTerms, isImpersonating]);

  return (
    <>
      <Outlet {...props} />
      <Modal
        onSubmit={handleAccept}
        canSubmit={isChecked[page]}
        disabled={false}
        loading={privacySaving || termsSaving}
        show={show}
        showSave={true}
        showCancel={false}
        size={MODAL_SIZE.medium}
        info={
          !isChecked[page] && <FormattedMessage id={`LegalModal.Info${page === PAGES.terms ? "Terms" : "Privacy"}`} />
        }
        primaryButtonMessageId={stepsRemaining > 0 ? "Global.Continue" : "Global.Complete"}
        title={<FormattedMessage id={`LegalModal.Title${page === PAGES.terms ? "Terms" : "Privacy"}`} />}
        footer={
          <CheckBox
            name={page}
            label={<FormattedMessage id={`LegalModal.Accept${page === PAGES.terms ? "Terms" : "Privacy"}`} />}
            value={isChecked[page]}
            onChange={handleChange}
          />
        }
        {...props}
      >
        <div css={styles.text}>{page === PAGES.terms ? <TermsAndConditionsContent /> : <PrivacyPolicyContent />}</div>
      </Modal>
    </>
  );
};

const PAGES = {
  terms: "terms",
  privacy: "privacy",
};

const styles = {
  text: css`
    max-height: 50vh !important;
    overflow: auto;
    font-size: ${fontSize.small};
    line-height: 1.33;
    margin-bottom: 2rem;
  `,
};

export default SignModal;
