/**
 * Convert camel string to snake case
 *
 * @param {string} str
 *
 * @returns {string}
 */
export default function camelToSnake(key) {
  return key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)  
}
