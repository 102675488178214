import { GET_TEAM_METRICS } from "graphql/queries";
import { useQueryData } from "hooks";
import { useEffect, useState } from "react";

/**
 * useTeamMetricsScores
 * @param {string} teamId
 */
export default function useTeamMetricsScores({ teamId }) {
  const [data, setData] = useState({});
  const {
    loading,
    data: response,
    error,
  } = useQueryData({
    queryName: GET_TEAM_METRICS,
    keyName: "teamMetricsScores",
    variables: {
      teamId,
    },
    skip: !teamId,
  });

  useEffect(() => {
    if (response?.teamMetricsScores) {
      setData(addContributionPercent(response.teamMetricsScores));
    }
  }, [response]);

  return {
    loading,
    data,
    error,
  };
}

const addContributionPercent = (data) => {
  // Create a deep copy of the data
  const copiedData = JSON.parse(JSON.stringify(data));
  const metrics = Object.keys(copiedData);

  metrics.forEach((metric) => {
    const metricData = copiedData[metric] || {};
    const { scoreBreakdown } = metricData;

    if (scoreBreakdown && scoreBreakdown.length > 0) {
      // Calculate the sum of all weights
      const totalWeight = scoreBreakdown.reduce((sum, breakdown) => sum + breakdown.weight, 0);

      // Add the relativeScore to each breakdown entry
      metricData.scoreBreakdown = scoreBreakdown.map((breakdown) => ({
        ...breakdown,
        contributionPercent: (100 / totalWeight) * breakdown.weight,
      }));
    }
  });

  return copiedData;
};
