import React from "react";
import PropTypes from "prop-types";
import { ChangesText, KeyValueText } from "components/ActivityTooltip";

/**
 * CommitActivityTooltip
 *
 * @params {Object}      tooltipData
 */
const CommitActivityTooltip = ({ tooltipData }) => {
  const {
    diffAdditions,
    diffDeletions,
    diffTotalChanges,
    count,
    repository,
    isMembersView,
    totalDiffAdditions,
    totalDiffDeletions,
    totalDiffTotalChanges,
    repositories,
    users,
  } = tooltipData;

  return isMembersView ? (
    <>
      <div>
        <ChangesText additions={diffAdditions} deletions={diffDeletions} changes={diffTotalChanges} />
      </div>
      <div>
        <KeyValueText id="Repository" value={repository} />
      </div>
    </>
  ) : (
    <>
      <div>
        <KeyValueText id="Count" value={count} />
      </div>
      <div>
        <ChangesText additions={totalDiffAdditions} deletions={totalDiffDeletions} changes={totalDiffTotalChanges} />
      </div>
      <div>
        <KeyValueText id="Repositories" value={repositories.join(", ")} />
      </div>
      <div>
        <KeyValueText id="Users" value={users.join(", ")} />
      </div>
    </>
  );
};

CommitActivityTooltip.propTypes = {
  tooltipData: PropTypes.object,
};

export default CommitActivityTooltip;
