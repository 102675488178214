/** @jsx jsx */
import { jsx, css, keyframes } from "@emotion/react";
import PropTypes from "prop-types";
import { colors } from "style";

/**
 * Shows a loading spinner
 *
 * @param {Number}    size
 * @param {String}    margin
 */
const Spinner = ({ size, margin }) => (
  <div css={styles.container} data-loader>
    <div css={styles.ring(size, margin)}>
      {[1, 2, 3, 4].map((i) => (
        <div key={i} css={styles.child(i, size)} />
      ))}
    </div>
  </div>
);

const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  ring: (size, margin) => css`
    display: inline-block;
    position: relative;
    width: ${size};
    height: ${size};
    margin: ${margin ? margin : `0 auto`};
  `,
  child: (i, size) => css`
    ${i === 1 && `animation-delay: -0.45s;`}
    ${i === 2 && `animation-delay: -0.3s;`}
    ${i === 3 && `animation-delay: -0.15s;`}
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${size};
    height: ${size};
    border: 2px solid ${colors.grayAnatomyLight2};
    border-radius: 50%;
    animation: ${animationRotate} 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${colors.grayAnatomyLight2} transparent transparent transparent;
  `,
};

const animationRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
`;

Spinner.defaultProps = {
  size: `3rem`,
};

Spinner.propTypes = {
  size: PropTypes.string,
  margin: PropTypes.string,
};

export default Spinner;
