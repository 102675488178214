import { useQueryData } from "hooks";
import { GET_PROFILE_MISSION_INTERVIEWS } from "graphql/queries";
import { PAGINATION_TYPE } from "components/Pagination";

/**
 * useMissionInterviews
 *
 * @params {String}   profileId
 * @params {Number}   resultsPerPage
 */
export default function useMissionInterviews({ profileId, resultsPerPage = 2 }) {
  const { hasNextPage, handleNextPage, loading, data, refetch } = useQueryData({
    queryName: GET_PROFILE_MISSION_INTERVIEWS,
    keyName: "profileMissionInterviews",
    resultsPerPage,
    paginationType: PAGINATION_TYPE.append,
    variables: {
      profileId,
    },
  });

  return {
    hasNextPage: hasNextPage,
    handleNextPage,
    refetch,
    loading: loading,
    data: data?.profileMissionInterviews?.nodes,
  };
}
