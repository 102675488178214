import { PAGINATION_TYPE } from "components/Pagination";
import { GET_MISSION_CONTACTS } from "graphql/queries";
import { useQueryData, useAuth } from "hooks";

export default function useMissionContacts({ resultsPerPage }) {
  const { profileId } = useAuth();
  const { hasNextPage, handleNextPage, loading, data } = useQueryData({
    queryName: GET_MISSION_CONTACTS,
    keyName: "missionContacts",
    resultsPerPage,
    paginationType: PAGINATION_TYPE.append,
    variables: {
      profileId
    },
  });

  return {
    hasNextPage,
    handleNextPage,
    loading,
    data: data?.missionContacts?.nodes,
  };
}
