import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { Row } from "components/Containers";
import { Toggle } from "components/Form";
import { colors, fontSize } from "style";
import { useDailyReportSettings } from "hooks";

/**
 * DailyReportStatusButton
 *
 * @param {Boolean}     isActive
 * @param {String}      teamId
 * @param {Function}    refetch
 */
const DailyReportStatusButton = ({ isActive: activeProp, teamId, refetch }) => {
  const [isActive, setIsActive] = useState(activeProp);
  const { handleStatusChange, changingStatus } = useDailyReportSettings({ skip: true });

  useEffect(() => {
    if (isActive !== activeProp) {
      handleStatusChange(teamId, isActive ? "enable" : "disable");
      refetch();
    }
  }, [isActive]);

  return (
    <Row>
      <span css={styles.label}>{<FormattedMessage id="Squads.Squad.Settings.DailyReport.ActivateButton.Label" />}</span>
      <Toggle value={isActive} onChange={() => setIsActive(!isActive)} disabled={changingStatus} />
    </Row>
  );
};

const styles = {
  label: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyLight6};
    font-weight: 500;
  `,
};

DailyReportStatusButton.defaultProps = {
  refetch: () => {},
};

DailyReportStatusButton.propTypes = {
  isActive: PropTypes.boolean,
  teamId: PropTypes.string,
  refetch: PropTypes.func,
};

export default DailyReportStatusButton;
