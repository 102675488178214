import React from "react";
import propTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { LINKS } from "constants/index";
import { PrimaryLink } from "components/Links";

/**
 * GitHubInstallationLink
 * @param {String} organizationSlug
 * @param {String} teamSlug
 */
const GitHubInstallationLink = ({ organizationSlug, teamSlug }) => {
  const state = { org: organizationSlug, team: teamSlug };
  const stateBase64 = btoa(JSON.stringify(state));
  const githubAppLink = window.ENV === "production" ? LINKS.mission_github_app : LINKS.mission_github_staging_app;

  return (
    <PrimaryLink
      css={css`
        margin-left: auto;
      `}
      to={`${githubAppLink}?state=${stateBase64}`}
      withMoreIcon
    >
      <FormattedMessage id="Squads.Profile.MessageRepoLink" />
    </PrimaryLink>
  );
};

GitHubInstallationLink.propTypes = {
  organizationSlug: propTypes.string.isRequired,
  teamSlug: propTypes.string.isRequired,
};

export default GitHubInstallationLink;
