import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import PageTitle from "components/PageTitle";
import { RolesList } from "components/Missions/Roles";
import { useAuth } from "hooks";
import { RolesSubscribeToggle } from "components/Missions/Roles";
import { Grid } from "components/Containers";

/**
 * MissionBoard
 */
const MissionBoard = () => {
  const intl = useIntl();
  const { isClient, isShowcase, isAdmin } = useAuth();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Board.Title" })}</title>
      </Helmet>

      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            {...(isAdmin
              ? {
                  subtitle: <FormattedMessage id={`Missions.Board.H1`} />,
                }
              : {
                  title: <FormattedMessage id={`Missions.Board.H1`} />,
                })}
            actionBar={<RolesSubscribeToggle />}
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <RolesList isCardView={true} filters={{ published: true }} hideTutorial={isClient || isShowcase} />
        </Grid.col>
      </Grid>
    </>
  );
};

export default MissionBoard;
