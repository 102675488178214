import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { TERMSHEET_EVENTS } from "constants/index";
import { formatCurrency } from "utils";

/**
 * TermSheetEventDescription
 *
 * @param {String} keyName
 * @param {Object} payload
 */
const TermSheetEventDescription = ({ keyName, payload }) => {
  const [titleKey, setKey] = useState(keyName);

  useEffect(() => {
    setKey(getKey(keyName));
  }, [keyName]);

  return (
    <>
      <FormattedMessage
        id={`TermSheets.TermSheetEventDescription.${titleKey}`}
        values={{
          ...payload,
          buyingRate: formatCurrency(payload?.buyingRate, payload?.buyingCurrency),
          sellingBasePrice: formatCurrency(payload?.sellingBasePrice, payload?.sellingCurrency),
        }}
      />
    </>
  );
};

/**
 * Get the formatted message keyname for titles
 *
 * @param {*} kName
 *
 * @returns String
 */
const getKey = (kName) => {
  switch (kName) {
    case TERMSHEET_EVENTS.teamStart:
      return `TeamStart`;

    case TERMSHEET_EVENTS.teamPause:
      return `TeamPause`;

    case TERMSHEET_EVENTS.teamResume:
      return `TeamResume`;

    case TERMSHEET_EVENTS.teamEnd:
      return `TeamEnd`;

    case TERMSHEET_EVENTS.orgChange:
      return `OrgChange`;

    case TERMSHEET_EVENTS.teamBillingAccountChange:
      return `TeamBillingAccountChange`;

    case TERMSHEET_EVENTS.teamBillingIntervalChange:
      return `TeamBillingIntervalChange`;

    case TERMSHEET_EVENTS.memberOnboarding:
      return `MemberOnboarding`;

    case TERMSHEET_EVENTS.memberOffboarding:
      return `MemberOffboarding`;

    case TERMSHEET_EVENTS.memberArchetypeChange:
      return `MemberArchetypeChange`;

    case TERMSHEET_EVENTS.memberContractChange:
      return `MemberContractChange`;

    case TERMSHEET_EVENTS.memberBuyingQuantityChange:
      return `MemberBuyingQuantityChange`;

    case TERMSHEET_EVENTS.memberBuyingRateChange:
      return `MemberBuyingRateChange`;

    case TERMSHEET_EVENTS.memberCommissionPercentChange:
      return `MemberCommissionPercentChange`;

    case TERMSHEET_EVENTS.memberSellingBasePriceChange:
      return `MemberSellingBasePriceChange`;

    case TERMSHEET_EVENTS.memberSellingDiscountChange:
      return `MemberSellingDiscountChange`;

    default:
      return kName;
  }
};

TermSheetEventDescription.propTypes = {
  keyName: PropTypes.string.isRequired,
  payload: PropTypes.object.isRequired,
};

export default TermSheetEventDescription;
