import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { css } from "@emotion/react";
import { breakpoints, colors } from "style";
import { AdvancedButton } from "components/Buttons";
import { NavLink } from "components/Links";

/**
 * Navigation list
 *
 * @param {Array}     pages
 * @param {Boolean}   onDark
 * @param {Boolean}   end
 * @param {Boolean}   hideOnMobile
 */
const NavList = ({ pages, end, onDark, hideOnMobile, ...props }) => {
  const navigate = useNavigate();

  return (
    <nav css={styles.container(hideOnMobile)}>
      <ul css={styles.list}>
        {pages?.map((page, index) => (
          <li key={index} css={styles.list_item}>
            <StyledNavLink
              to={page.to}
              isActive={page.isActive}
              onDark={onDark || false}
              end={end}
              routerEnabled
              {...page.props}
              {...props}
              isAdmin={page.isAdmin}
            >
              {page.label}
            </StyledNavLink>
          </li>
        ))}
      </ul>
      <div css={styles.mobile_menu}>
        <AdvancedButton options={pages.map(({ label, to }) => ({ label, onClick: () => navigate(to) }))} />
      </div>
    </nav>
  );
};

const StyledNavLink = styled(NavLink, { shouldForwardProp: (prop) => prop !== "isAdmin" })`
  display: block;
  white-space: nowrap;
  ${({ isAdmin }) => isAdmin && `color: ${colors.peach} !important;`}
`;

const styles = {
  container: (hideOnMobile) => css`
    ${hideOnMobile &&
    `
      @media (max-width: ${breakpoints.portrait}) {
        display: none;
      }    
    `}
  `,
  list: css`
    display: flex;
    align-items: center;
    padding-left: 0;
    list-style: none;
    margin: 0;

    @media (max-width: ${breakpoints.portrait}) {
      display: none;
    }
  `,
  list_item: css`
    position: relative;
    display: inline-block;
    padding: 0 3.2rem 0 0;
    margin-right: 0;

    &:last-of-type {
      padding: 0;
    }
  `,
  mobile_menu: css`
    margin-bottom: 0rem;
    display: none;

    @media (max-width: ${breakpoints.portrait}) {
      display: block;
    }
  `,
};

NavList.propTypes = {
  pages: PropTypes.array,
  end: PropTypes.bool,
  onDark: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
};

NavList.defaultProps = {
  end: true,
  hideOnMobile: false,
};

export default NavList;
