import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * @description Scrolls to the top of the page on route change
 */
export default function useScrollTop() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
