import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import PageTitle from "components/PageTitle";
import { PageWrapper, Grid } from "components/Containers";
import { TimelineList } from "components/Timeline";
import { ProfileNav } from "components/Profile";
import { useProfile } from "hooks";
import { DatePicker } from "components/Form";
import { formatDate } from "utils";

/**
 * Timeline
 */
const Timeline = () => {
  const { id } = useParams();
  const [eventStartDate, setEventStartDate] = useState(formatDate(new Date(), "YYYY-MM-DD"));
  const { data } = useProfile({ profileId: id });
  const intl = useIntl();

  return (
    <>
      <ProfileNav id={id} />
      <PageWrapper>
        <Helmet>
          <title>{intl.formatMessage({ id: "Meta.Profiles.Timeline.Title" })}</title>
        </Helmet>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              subtitle={<FormattedMessage id="Profile.Timeline.Title" />}
              actionBar={
                <>
                  <FormattedMessage id="Profile.Timeline.EventStartDate" />
                  <DatePicker
                    value={eventStartDate}
                    maxDate={new Date()}
                    onChange={(_name, date) => setEventStartDate(date)}
                  />
                </>
              }
            />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <TimelineList id={id} user={data} eventStartDate={eventStartDate} />
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default Timeline;
