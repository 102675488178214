import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { UPDATE_PROFILE } from "graphql/mutations";
import { GET_INTERESTS } from "graphql/queries";
import DisplayBox from "components/DisplayBox";
import Tags from "components/Tags";
import { SIZE, TYPE } from "components/Form";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { PAGES } from "constants/index";

/**
 * ProfileInterests
 *
 * @params {Array}    data
 */
const ProfileInterests = ({ data, ...props }) => (
  <DisplayBox
    title={<FormattedMessage id="Profile.ProfileInterests.TitleInterests" />}
    editButtonText={<FormattedMessage id="Profile.ProfileInterests.AddInterests" />}
    numColumns={1}
    data={INTERESTS_DATA(data)}
    parentKeyName="id"
    parentKeyValue={data?.id}
    updateId={data?.id}
    initialValues={{
      interests: data?.interests?.map((item) => ({
        name: item.name,
        id: item.id,
      })),
    }}
    {...props}
  />
);

const INTERESTS_DATA = (data) => [
  {
    title: null,
    body: data?.interests?.length > 0 && <Tags data={data?.interests} />,
  },
];

const FORM_DATA = [
  {
    items: [
      {
        label: "Area of Interests",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "interests",
          queryName: GET_INTERESTS,
          placeholder: "Add area of interest...",
          queryKeyName: "interests",
          labelkey: "name",
          secondaryLabelKey: "description",
          minLength: 0,
          autoFocus: true,
          defaultOpen: true,
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Profile.ProfileInterests.TitleInterests" />,
  description: <FormattedMessage id="Profile.ProfileInterests.FormDescription" />,
  mutationData: {
    mutationName: {
      update: UPDATE_PROFILE,
      add: UPDATE_PROFILE,
    },
  },
  page: PAGES.workInterest,
};

ProfileInterests.propTypes = {
  data: PropTypes.object,
};

export { FORM_SETTINGS };

export default withModalForm(ProfileInterests, FORM_SETTINGS);
