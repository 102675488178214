import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { PrimaryLink } from "components/Links";
import Icon, { withIcon, ICON_TYPE, ICON_SIZE } from "components/Icon";
import { colors, fontSize } from "style";

/**
 * Resume
 *
 * @params {Object}     value
 * @params {Function}   onChange
 * @params {String}     name
 */
const Resume = ({ value, onChange, name }) => {
  const [resumeInput, setResumeInput] = useState(value);
  const [resumeStatus, setResumeStatus] = useState(value ? true : false);
  const fileInput = useRef(null);

  const handleFileSelect = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid) {
      onChange(name, file);
      setResumeStatus(true);
    }
  };

  const handleReplaceResume = () => fileInput.current.click();

  const handleRemoveResume = () => {
    setResumeInput(null);
    setResumeStatus(false);
    onChange(name, null);
  };

  const LinkUploadResumeWithIcon = withIcon(PrimaryLink, {
    type: ICON_TYPE.upload,
    size: ICON_SIZE.xlarge,
    color: "inherit",
    position: "relative",
  });

  const LinkDeleteResumeWithIcon = withIcon(PrimaryLink, {
    type: ICON_TYPE.close,
    size: ICON_SIZE.xlarge,
    color: "inherit",
    position: "relative",
  });

  const LinkResume = withIcon(PrimaryLink, {
    type: ICON_TYPE.file,
    size: ICON_SIZE.xxxlarge,
    color: "inherit",
  });

  return (
    <>
      <div css={styles.container}>
        {resumeStatus === true && (
          <div css={styles.resume_container}>
            <LinkResume target={`_blank`} href={`${resumeInput}`} css={styles.resume_link(resumeStatus)} />
            <div css={styles.success_container}>
              <Icon type={ICON_TYPE.checkFilled} size={ICON_SIZE.xxlarge} color={colors.green} />
            </div>
            <span css={styles.success_indicator}>
              <FormattedMessage id="Profile.Form.Resume.UploadSuccess" />
            </span>
          </div>
        )}
        <div css={styles.link_container}>
          <LinkUploadResumeWithIcon onClick={handleReplaceResume}>
            <FormattedMessage
              id={value ? `Profile.ProfileWorkExperience.UpdateResume` : `Profile.ProfileWorkExperience.AddResume`}
            />
          </LinkUploadResumeWithIcon>
        </div>
        {value && (
          <div css={styles.link_container}>
            <LinkDeleteResumeWithIcon onClick={handleRemoveResume}>
              <FormattedMessage id="Profile.ProfileWorkExperience.DeleteResume" />
            </LinkDeleteResumeWithIcon>
          </div>
        )}
      </div>
      <div css={styles.input}>
        <input ref={fileInput} type="file" accept="application/pdf" onChange={handleFileSelect} />
      </div>
    </>
  );
};

const styles = {
  container: css`
    display: flex;
    align-items: center;
    font-size: ${fontSize.small};
    font-weight: 600;
  `,
  success_container: css`
    position: absolute;
    bottom: 53%;
    left: 50%;
    display: flex;
    align-items: center;
  `,
  success_indicator: css`
    color: ${colors.grayAnatomyLight1};
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: ${fontSize.xsmall};
    font-weight: 700;
  `,
  resume_container: css`
    width: 18rem;
    height: 18rem;
    position: relative;
    margin-right: 2rem;
  `,
  resume_link: (fileUploaded) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.grayAnatomyLight5};
    width: 18rem;
    height: 18rem;
    border-radius: 0.6rem;
    color: ${fileUploaded ? colors.green : colors.grayAnatomyLight3};

    ${!fileUploaded &&
    `
      border:2px dashed ${colors.grayAnatomyLight2};
    `}

    &:hover {
      background: ${colors.grayAnatomyLight4};
      color: ${colors.purpleRainBase};
    }
  `,
  input: css`
    display: none;
  `,
  link_container: css`
    margin-right: 2rem;
  `,
};

Resume.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default Resume;
