import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { PageWrapper } from "components/Containers";
import BookAMeeting from "components/BookAMeeting";
import { useTracking } from "hooks";
import { PAGES, TRACKING_PAGE_VIEWS } from "constants/index";
/**
 * BookMeeting
 */
const BookMeeting = () => {
  const intl = useIntl();
  const { trackPage } = useTracking();

  useEffect(() => {
    trackPage(TRACKING_PAGE_VIEWS[PAGES.showcaseBookMeeting].page_name, TRACKING_PAGE_VIEWS[PAGES.showcaseBookMeeting]);
  }, []);

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Client.BooKMeeting.Title" })}</title>
      </Helmet>
      <BookAMeeting />
    </PageWrapper>
  );
};

export default BookMeeting;
