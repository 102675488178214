import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { useProfile, MUTATION_TYPE, useToast, useQueryData } from "hooks";
import { SIZE, TYPE } from "components/Form";
import {
  GET_MISSIONS,
  GET_PROFILES,
  GET_MISSION_ROLES,
  GET_PROFILE_MISSION_INTERVIEWS,
  GET_PROFILE,
} from "graphql/queries";
import { ADD_INTERVIEW } from "graphql/mutations";

/**
 * CandidateInterviewsForm
 *
 * @param {Boolean}     show
 * @param {Function}    onClose
 * @param {String}      profileId
 */
const CandidateInterviewsForm = ({ show, onClose, profileId, ...props }) => {
  const { toast } = useToast();
  const [missionId, setMissionId] = useState(null);
  const [roleOptions, setRoleOptions] = useState([]);
  const [interviewerId, setInterviewerId] = useState(null);
  const [calendlyLink, setCalendlyLink] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const [values, setValues] = useState({});
  const { data } = useProfile({ profileId });
  // WARNING: To fetch the interviewer data we can't use
  // the useProfile hook twice on the profile page
  // as it impacts the profileCompletion var
  const { data: interviewerData } = useQueryData({
    queryName: GET_PROFILE,
    variables: { id: interviewerId },
    skip: !interviewerId,
  });

  const { data: roles } = useQueryData({
    queryName: GET_MISSION_ROLES,
    keyName: "missionRoles",
    variables: { id: missionId },
  });

  const handleClose = () => {
    setMissionId(null);
    setInterviewerId(null);
    setCalendlyLink("");
    onClose();
  };

  const handleComplete = () => {
    onClose();
    toast.success(<FormattedMessage id={"Candidate.CandidateInterviews.CreateSuccess"} />);
  };

  const handleError = () => {
    toast.error(<FormattedMessage id={"Candidate.CandidateInterviews.CreateError"} />);
  };

  useEffect(() => {
    if (data) {
      setInitialValues({ ...initialValues, intervieweeId: data?.profile?.id });
    }
  }, [data]);

  useEffect(() => {
    if (roles && roles.missionRoles?.nodes && missionId) {
      setRoleOptions(roles.missionRoles?.nodes);
    } else {
      setRoleOptions([]);
      setValues({ missionRoleId: null });
    }
  }, [roles, missionId]);

  useEffect(() => {
    setValues({ calendlyLink });
  }, [calendlyLink]);

  useEffect(() => {
    // trigger setValues everytime interviewer gets change
    setCalendlyLink(
      interviewerData?.profile?.externalLinks?.find((e) => e.type === REQUIRED_LINK)?.url ||
        (calendlyLink === "" ? null : "")
    );
  }, [interviewerData]);

  const formProps = {
    initialValues,
    values,
    mutationType: MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: ADD_INTERVIEW,
      },
      refetchAfterMutate: true,
      refetchQueries: [GET_PROFILE_MISSION_INTERVIEWS],
    },
    ...props,
  };

  return (
    <ModalForm
      data={FORM_DATA(missionId, setMissionId, setInterviewerId, calendlyLink, roleOptions)}
      show={show}
      onClose={handleClose}
      onCompleted={handleComplete}
      onError={handleError}
      size={MODAL_SIZE.medium}
      numColumns={1}
      title={<FormattedMessage id="Candidate.CandidateInterviews.Title" />}
      {...formProps}
    />
  );
};

const REQUIRED_LINK = "CALENDLY";

const FORM_DATA = (missionId, setMissionId, setInterviewerId, calendlyLink, options) => [
  {
    style: css`
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
    `,
    items: [
      {
        label: "Interviewee",
        type: TYPE.select,
        size: SIZE.large,
        disabled: true,
        properties: {
          name: "intervieweeId",
          required: true,
          isClearable: false,
          queryName: GET_PROFILES,
          queryKeyName: "profiles",
          queryFieldName: "profiles",
          refetchAfterMutate: false,
          labelKey: "name",
          valueKey: "id",
        },
      },
      {
        label: "Interviewer",
        type: TYPE.select,
        size: SIZE.large,
        properties: {
          name: "interviewerId",
          placeholder: "Search by name...",
          required: true,
          isClearable: true,
          queryName: GET_PROFILES,
          queryKeyName: "profiles",
          queryFieldName: "profiles",
          queryVariables: { filters: { archetypes: ["SQUAD_LEAD", "TECH_LEAD"] } },
          refetchAfterMutate: false,
          labelKey: "name",
          valueKey: "id",
          reloadData: (id) => setInterviewerId(id),
          isSearchable: true,
          querySearchFieldName: "search",
          noOptionsMessage: "No interviewer found",
        },
      },
      {
        label: "Calendly",
        type: TYPE.input,
        size: SIZE.large,
        disabled: !!calendlyLink,
        properties: {
          type: "url",
          name: "calendlyLink",
          required: true,
        },
      },
      {
        label: "Mission",
        type: TYPE.select,
        size: SIZE.large,
        properties: {
          name: "missionId",
          required: true,
          isClearable: true,
          queryName: GET_MISSIONS,
          queryKeyName: "missions",
          queryFieldName: "missions",
          refetchAfterMutate: false,
          labelKey: "name",
          valueKey: "id",
          reloadData: (id) => id != missionId && setMissionId(id),
          noOptionsMessage: "No Missions found",
        },
      },
      {
        label: "Role",
        type: TYPE.select,
        size: SIZE.large,
        properties: {
          name: "missionRoleId",
          required: true,
          isClearable: true,
          labelKey: "name",
          valueKey: "id",
          parentKey: "id",
          options,
        },
      },
    ],
  },
];

CandidateInterviewsForm.propTypes = {
  profileId: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CandidateInterviewsForm;
