import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import Card from "components/Card";
import PageTitle from "components/PageTitle";
import { Grid, PageWrapper } from "components/Containers";
import { PromptAddButton, PromptsList } from "components/Prompts";

/**
 * Prompts
 */
const Prompts = () => {
  const intl = useIntl();

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Settings.Prompts.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle subtitle={<FormattedMessage id="Settings.Prompts.H1" />} actionBar={<PromptAddButton />} />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <PromptsList />
          </Card>
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default Prompts;
