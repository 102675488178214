import React, { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { chart_colors, colors } from "style";
import { initializeAmCharts } from "utils";
import { Spinner } from "components/Loader";
import { MessagesError, MessagesNoResults } from "components/Messages";

/**
 * StackedClusteredVerticalBar
 *
 * @param {Array}   data
 * @param {String}  height
 * @param {Boolean} loading
 * @param {String}  error
 */
const StackedClusteredVerticalBar = ({ data, loading, height, error, ...props }) => {
  const [rootId] = useState(`stacked-clustered-vertical-bar-${Math.random()}`);

  useLayoutEffect(() => {
    if (!data?.data || loading) return;

    initializeAmCharts();

    let root = am5.Root.new(rootId);
    let customTheme = am5.Theme.new(root);

    customTheme.rule("AxisLabel", ["minor"]).setAll({
      fill: am5.color(0xff0000),
      fontSize: 11,
    });

    customTheme.rule("Label").setAll({
      fontFamily: "Matter",
      fill: am5.color(colors.grayAnatomyBase),
      fontSize: 11,
      paddingTop: 9,
    });

    root.setThemes([am5themes_Animated.new(root), customTheme]);

    // create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 0,
        paddingLeft: 5,
      })
    );

    chart.zoomOutButton.set("forceHidden", true);

    chart.get("colors").set("colors", chart_colors.categorical);

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minorGridEnabled: true,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "bucket",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xRenderer.grid.template.set("strokeDasharray", [3]);

    xRenderer.grid.template.setAll({
      location: 1,
    });

    xAxis.data.setAll(data.data);

    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1,
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: yRenderer,
      })
    );

    yRenderer.grid.template.set("strokeDasharray", [3]);

    // Add legend
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    // Add series
    function makeSeries(name, fieldName, stacked) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          stacked: stacked,
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "bucket",
        })
      );

      series.columns.template.setAll({
        tooltipText: "{name}, {categoryX}: {valueY}",
        tooltipY: am5.percent(10),
      });

      series.data.setAll(data.data);

      // Make stuff animate on load
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.percent(50),
            centerX: am5.percent(50),
          }),
        });
      });

      legend.data.push(series);
    }

    data.series.forEach((item) => {
      makeSeries(item.name, item.fieldName, item.stacked);
    });

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data, loading, rootId]);

  return (
    <ChartContainer height={height}>
      <ChartCanvas id={rootId} height={height} shouldCenter={loading || (!loading && !data?.data)} {...props}>
        {loading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : !data?.data && !error ? (
          <MessagesNoResults title="Squads.NoActivityTitle" description="Squads.NoActivityDescription" />
        ) : error ? (
          <MessagesError />
        ) : null}
      </ChartCanvas>
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  width: 100%;
  overflow: visible;
  padding: 0;
  height: calc(${(props) => props.height} + 10px);
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1.5rem;
  margin-left: -1.5rem;
`;

const ChartCanvas = styled.div`
  width: 100%;
  height: ${(props) => props.height};
  margin-left: -5px;
  overflow: visible;
  ${(props) =>
    props.shouldCenter &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

StackedClusteredVerticalBar.defaultProps = {
  height: `27rem`,
};

StackedClusteredVerticalBar.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

export default StackedClusteredVerticalBar;
