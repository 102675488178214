import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import PageTitle from "components/PageTitle";
import { OrganizationsList, AddOrganizationButton } from "components/Organizations";
import Card from "components/Card";
import { Grid, PageWrapper } from "components/Containers";

/**
 * Organizations
 */
const Organizations = () => {
  const intl = useIntl();

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Organizations.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            title={<FormattedMessage id={`Organizations.H1`} />}
            actionBar={<AddOrganizationButton isModal />}
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <OrganizationsList />
          </Card>
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default Organizations;
