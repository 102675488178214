import React from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useToast } from "hooks";
import { useMutation } from "@apollo/client";
import { REMOVE_PROFILE_FROM_LIST } from "graphql/mutations";
import { TertiaryButton } from "components/Buttons";
import { Spinner } from "components/Loader";

/**
 * RemoveFromListButton
 *
 * @param {String} id
 * @param {String} listId
 */
const RemoveFromListButton = ({ id, listId }) => {
  const { toast } = useToast();
  const [removeProfile, { loading: removing }] = useMutation(REMOVE_PROFILE_FROM_LIST);

  if (!listId) {
    const { id } = useParams();
    listId = id;
  }

  return (
    <TertiaryButton
      disabled={removing}
      onClick={() =>
        removeProfile({
          variables: {
            input: {
              profileId: id,
              listId: listId,
            },
          },
          onCompleted: () => toast.success(<FormattedMessage id="Profile.RemovedFromProfileList" />),
          onError: () => toast.error(<FormattedMessage id="Profile.ErrorOnRemoveFromProfileList" />),
        })
      }
    >
      {removing ? <Spinner size={`2rem`} /> : <FormattedMessage id={`Lists.ListFavoriteButton.Remove`} />}
    </TertiaryButton>
  );
};

RemoveFromListButton.propTypes = {
  id: PropTypes.string.isRequired,
  listId: PropTypes.string,
};

export default RemoveFromListButton;
