import { gql } from "@apollo/client";
import {
  PUBLIC_CORE_PROFILE_FIELDS,
  PUBLIC_DISCIPLINE_FIELDS,
  PUBLIC_LANGUAGE_FIELDS,
  PUBLIC_EDUCATION_FIELDS,
  PUBLIC_INTERESTS_FIELDS,
  PUBLIC_OLD_LOCATION_FIELDS,
  PUBLIC_SKILLS_FIELDS,
  PUBLIC_TAGS_FIELDS,
  PUBLIC_WORK_EXPERIENCES_FIELDS,
  PUBLIC_WORK_SCHEDULE_FIELDS,
  PUBLIC_BADGES_FIELDS,
  PUBLIC_ENGINEERING_LEVEL_FIELDS,
} from "graphql/fragments";

const GET_PUBLIC_PROFILE = gql`
  ${PUBLIC_CORE_PROFILE_FIELDS}
  ${PUBLIC_DISCIPLINE_FIELDS}
  ${PUBLIC_LANGUAGE_FIELDS}
  ${PUBLIC_EDUCATION_FIELDS}
  ${PUBLIC_OLD_LOCATION_FIELDS}
  ${PUBLIC_SKILLS_FIELDS}
  ${PUBLIC_TAGS_FIELDS}
  ${PUBLIC_WORK_EXPERIENCES_FIELDS}
  ${PUBLIC_WORK_SCHEDULE_FIELDS}
  ${PUBLIC_INTERESTS_FIELDS}
  ${PUBLIC_BADGES_FIELDS}
  ${PUBLIC_ENGINEERING_LEVEL_FIELDS}
  query GetProfile($id: ID!) {
    publicProfile(id: $id) {
      ...PublicProfileFields
      ...PublicDisciplineFields
      ...PublicLanguageFields
      ...PublicEducationFields
      ...PublicOldLocationFields
      ...PublicSkillsFields
      ...PublicTagsFields
      ...PublicWorkExperiencesFields
      ...PublicWorkScheduleFields
      ...PublicInterestsFields
      ...PublicBadgesFields
      ...PublicEngineeringLevelFields
    }
  }
`;

export { GET_PUBLIC_PROFILE };
