const margins = {
  xxsmall: `1rem`,
  xsmall: `2rem`,
  small: `2.5rem`,
  normal: `3rem`,
  large: `6rem`,
  none: `0`,
  negative: `-3rem`,
};

export { margins };
