export { default as TextBlock } from "./TextBlock";
export { default as SectionTitle } from "./SectionTitle";

const LAYOUT_SIZE = {
  small: "small",
  medium: "medium",
  large: "large",
};

export { LAYOUT_SIZE };
