import React, { createRef } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { EditIcon } from "components/Icon";
import { colors } from "style";

/**
 * EditInPlace
 *
 * @param {Function} onSubmit
 * @param {Boolean} isEditable
 * @param {Boolean} updating
 * @param {Element} children
 */
const EditInPlace = ({ onSubmit, isEditable, updating, children, ...props }) => {
  const input = createRef();

  const handleSubmit = (e) => {
    if (children !== e.target.innerText) {
      onSubmit?.(e.target.innerText);
    }
  };

  const handleChange = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleFocus = () => {
    input.current.focus();
  };

  return (
    <>
      <span
        ref={input}
        onKeyDown={handleChange}
        onBlur={handleSubmit}
        contentEditable={isEditable && !updating}
        css={styles.input}
        {...props}
      >
        {children}
      </span>
      {isEditable && <EditIcon onClick={handleFocus} loading={updating} disabled={updating} isRightSide />}
    </>
  );
};

const styles = {
  input: css`
    outline: none;
    border-bottom: 1px transparent solid;

    $:hover {
      border-bottom-color: ${colors.grayAnatomyLight3};
    }

    &:focus {
      border-bottom-color: ${colors.purpleRainBase};
    }
  `,
};

EditInPlace.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  updating: PropTypes.bool,
  children: PropTypes.any,
};

export default EditInPlace;
