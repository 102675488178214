import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { fontSize } from "style";
import { WhiteLink } from "components/Links";
import { useAuth } from "hooks";
import { LINKS } from "constants/index";
import { UserArchetype, UserFullName, UserPhoto } from "components/User";
import { Col, Row } from "components/Containers";

/**
 * MenuItemAccount
 */
const MenuItemAccount = (props) => {
  const { isClient, isShowcase, isImpersonating, ...me } = useAuth();

  const profile = {
    firstName: me?.firstName,
    lastName: me?.lastName,
    avatarUrl: me?.avatarUrl,
    ...me?.profile,
  };

  return (
    <Account {...props}>
      <Row>
        {!isClient && !isShowcase && (
          <div>
            <UserPhoto data={{ ...profile, canShowInterviews: false }} width="3rem" height="3rem" borderRadius="100%" />
          </div>
        )}
        <Col gap="0.25rem">
          <UserFullName data={profile} disableLink />
          {isImpersonating ? (
            <ImpersonatingLink to={LINKS.stop_impersonating}>
              <FormattedMessage id="Global.StopImpersonating" />
            </ImpersonatingLink>
          ) : (
            <UserArchetype data={profile} />
          )}
        </Col>
      </Row>
    </Account>
  );
};

const ImpersonatingLink = styled(WhiteLink)`
  font-size: ${fontSize.xxsmall};
  text-decoration: underline !important;
  font-weight: 400;
`;

const Account = styled.div`
  display: flex;
  flex-direction: colum;
  gap: 0.5rem;
  font-weight: 500;
  color: #fff;
  font-size: ${fontSize.xsmall};
`;

export default MenuItemAccount;
