import { gql } from "@apollo/client";

const GET_EXISTING_TIME_SLIPS = gql`
  query TimeSlipsFetch($startDate: String!, $endDate: String!, $teamSlug: ID, $profileId: ID) {
    timeSlipsFetch(startDate: $startDate, endDate: $endDate, teamSlug: $teamSlug, profileId: $profileId) {
      nodes {
        assignmentDate
        assignments {
          assignmentId
          teamName
          teamId
          averageCapacity
          description
          communication {
            timeLogged
            timeSlipId
          }
          planning {
            timeLogged
            timeSlipId
          }
          review {
            timeLogged
            timeSlipId
          }
          production {
            timeLogged
            timeSlipId
          }
        }
      }
    }
  }
`;

export { GET_EXISTING_TIME_SLIPS };
