/**
 * Sort array of objects by keyname date (immutable)
 *
 * @param {Array}   obj
 * @param {String}  keyName
 * @param {String}  order
 *
 * @returns {Array} the sorted aray
 */
export default function sortByDate(obj, keyName, order = "asc") {
  if (order === "asc") {
    return obj?.slice().sort((a, b) => new Date(a[keyName]) - new Date(b[keyName]));
  } else {
    return obj?.slice().sort((a, b) => new Date(b[keyName]) - new Date(a[keyName]));
  }
}
