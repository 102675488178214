import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid } from "components/Containers";
import { colors } from "style";
import { TertiaryButton } from "components/Buttons";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * ProgressMenu
 *
 * @description A progress menu component that displays a list of items with an arrow between them
 *
 * @param {Array}     items
 * @param {Function}  onSelect
 * @param {Number}    selected
 */
const ProgressMenu = ({ items, onSelect, selected }) => (
  <Grid css={styles.navigator} cols={items.length * 3 - 1}>
    {items.map((item, index) => (
      <Fragment key={index}>
        <Grid.col colSpan={2}>
          <Selector {...(selected === index && { selected: true })} onClick={() => onSelect(index)}>
            {item}
          </Selector>
        </Grid.col>
        {index < items.length - 1 && (
          <Grid.col>
            <Icon type={ICON_TYPE.arrowRight} size={ICON_SIZE.large} />
          </Grid.col>
        )}
      </Fragment>
    ))}
  </Grid>
);

const Selector = styled(TertiaryButton)`
  width: 100%;

  ${(props) =>
    props.selected &&
    css`
      color: ${colors.purpleRainBase};
      box-shadow: 0 0 0 2px ${colors.purpleRainBase};
      background: ${colors.purpleRainLight5} !important;
      border: 2px #fff solid;
    `}
`;

const styles = {
  navigator: css`
    > * {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
};

ProgressMenu.propTypes = {
  items: PropTypes.array,
  onSelect: PropTypes.func,
  selected: PropTypes.number,
};

export default ProgressMenu;
