import { gql } from "@apollo/client";

const GET_ORGANIZATION_BILLING_ACCOUNTS = gql`
  query organizationBillingAccounts($filters: OrganizationBillingAccountFilter) {
    organizationBillingAccounts(filters: $filters) {
      nodes {
        id
        name
        currencyCode
      }
    }
  }
`;

export { GET_ORGANIZATION_BILLING_ACCOUNTS };
