import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import { useQueryData } from "hooks";
import DataTable from "components/DataTable";
import { PAGINATION_TYPE, ShowMore, Paginate } from "components/Pagination";
 
/**
 * DataTableProvider
 *
 * @param {Function} onSelect
 * @param {String}   keyName
 * @param {Object}   queryName
 * @param {String}   fetchPolicy
 * @param {Object}   variables
 * @param {Number}   resultsPerPage
 * @param {Array}    columnData
 * @param {String}   paginationType
 * @param {Function} setTotalCount
 * @param {Object}   paginationProps
 * @param {Boolean}  onLoading
 * @param {Boolean}  selectable
 * @param {Boolean}  disablePagination
 * @param {Function} onDataFetched
 */
const DataTableProvider = forwardRef(
  (
    {
      onSelect,
      keyName,
      queryName,
      fetchPolicy,
      variables,
      resultsPerPage,
      columnData,
      paginationType,
      setTotalCount,
      paginationProps,
      selectable,
      onLoading,
      disablePagination,
      onDataFetched,
      ...props
    },
    ref
  ) => {

  const [storedTotalCount, setStoredTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);

  const {
    loadingMore,
    hasPreviousPage,
    hasNextPage,
    handlePreviousPage,
    handleNextPage,
    handlePageChange,
    refetch,
    loading,
    error,
    data,
    pageInfo,
    totalCount,
    currentPage,
    perPage
  } = useQueryData({
    queryName,
    variables,
    fetchPolicy,
    keyName,
    resultsPerPage,
    paginationType,
    disablePagination,
  });

  useEffect(() => {
    onDataFetched(data);
  }, [data]);

  useEffect(() => {
    if (typeof totalCount !== "undefined") {
      setStoredTotalCount(totalCount);
    }
  }, [totalCount]);


  useEffect(() => {
    if (setTotalCount) {
      setTotalCount(totalCount || 0, data);
    }
  },[totalCount, data]);

  useEffect(() => {
    onLoading?.(loading);
  },[loading]);

  useImperativeHandle(ref, () => ({
    refetch,
  }),[]);

  useEffect(() => {
    const getTableData = (data, keyName) => {
      let tableData = data;
  
      // If the keyName is a nested object, we need to traverse the object to get the data.
      if (keyName) {
        const keys = keyName.split('.');
        for (const key of keys) {
          if (tableData && tableData[key]) {
            tableData = tableData[key];
          } else {
            tableData = [];
            break;
          }
        }
      }
  
      return tableData?.nodes ? tableData.nodes : tableData;
    };
  
    setTableData(getTableData(data, keyName));
  }, [data, keyName]);

  const pProps = {
    type: paginationType,
    onNextPage: handleNextPage,
    onPreviousPage: handlePreviousPage,
    handlePageChange: handlePageChange,
    hasNextPage: !loading && hasNextPage,
    hasPreviousPage: !loading && hasPreviousPage,
    loading,
    loadingMore,
    pageInfo,
    totalCount: storedTotalCount,
    currentPage,
    resultsPerPage: perPage,
    ...paginationProps,
  };

  return (
    <>
      {!disablePagination && paginationType === PAGINATION_TYPE.classic && <Paginate {...pProps} isTop />}
      <DataTable
        data={tableData}
        pagination={!disablePagination && data && data[keyName].pageInfo}
        resultsPerPage={perPage}
        error={error}
        loading={loading || loadingMore}
        columns={columnData}
        selectable={selectable}
        onSelect={onSelect}
        {...props}
      />
      {!disablePagination && paginationType === PAGINATION_TYPE.append && (
        <ShowMore loading={loading} hasNextPage={!loading && hasNextPage} onNextPage={handleNextPage} />
      )}
      {!disablePagination && paginationType && (
        <Paginate {...pProps} />
      )}
    </>
  );
});

DataTableProvider.defaultProps = {
  resultsPerPage: 10,
  paginationType: PAGINATION_TYPE.classic,
  paginationProps: {},
  disablePagination: false,
  onDataFetched: () => {},
};

DataTableProvider.propTypes = {
  onSelect: PropTypes.func,
  keyName: PropTypes.string,
  queryName: PropTypes.object,
  resultsPerPage: PropTypes.number,
  columnData: PropTypes.array,
  paginationType: PropTypes.string,
  fetchPolicy: PropTypes.string,
  variables: PropTypes.object,
  setTotalCount: PropTypes.func,
  paginationProps: PropTypes.object,
  selectable: PropTypes.bool,
  onLoading: PropTypes.func,
  disablePagination: PropTypes.bool,
  onDataFetched: PropTypes.func,
};

export default DataTableProvider;
