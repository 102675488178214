import { gql } from "@apollo/client";
import { CORE_MISSION_FIELDS, CORE_MISSION_ROLE_FIELDS } from "graphql/fragments";

const GET_MISSIONS = gql`
  ${CORE_MISSION_FIELDS}
  ${CORE_MISSION_ROLE_FIELDS}
  query GetMissions(
    $paginationInfo: PaginationInfo
    $orderBy: MissionOrderBy
    $filters: MissionFilter
    $disablePagination: Boolean
    $first: Int
    $last: Int
    $after: String
    $before: String
    $includeRoles: Boolean = true
  ) {
    missions(
      paginationInfo: $paginationInfo
      orderBy: $orderBy
      filters: $filters
      first: $first
      last: $last
      after: $after
      before: $before
      disablePagination: $disablePagination
    ) {
      totalCount
      currentPage
      perPage
      currentOffset
      nodes {
        ...MissionFields
        roles(filters: $filters) @include(if: $includeRoles) {
          ...MissionRoleFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export { GET_MISSIONS };
