import React from "react";
import { FormattedMessage } from "react-intl";
import { Row } from "components/Containers";
import styled from "@emotion/styled";
import { breakpoints, colors, fontSize, gradients, margins } from "style";
import { SecondaryButton } from "components/Buttons";
import { Link } from "components/Links";
import { LINKS } from "constants/index";
import { WhiteLogo } from "components/Logo";
import { useAuth } from "hooks";

/**
 * BannerShowcaseSignup
 */
const BannerShowcaseSignup = (props) => {
  const { isShowcase, isSignalClient } = useAuth();

  if (!(isShowcase || isSignalClient)) return null;

  return (
    <div {...props}>
      <StyledRow>
        <StyledLogo width={`10rem`} />
        <FormattedMessage id="Banner.BannerShowcaseSignup.Text" />
        <StyledButton>
          <Link to={LINKS.book_meeting_link}>
            <FormattedMessage id="Banner.BannerShowcaseBookMeeting.CTA" />
          </Link>
        </StyledButton>
      </StyledRow>
    </div>
  );
};

const StyledRow = styled(Row)`
  background: ${gradients.missionLinearGradient};
  color: ${colors.white};
  font-size: ${fontSize.normal};
  padding: 0.75rem ${margins.normal};
  border-radius: 0.6rem;
  margin: 0rem 0rem 1rem 0rem;
  justify-content: space-between;

  @media (max-width: ${breakpoints.portrait}) {
    padding: 2rem 2.5rem;
    text-align: center;
    font-size: ${fontSize.small};
    font-weight: 500;
    flex-direction: column;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 1.5rem 2rem;
  }
`;

const StyledButton = styled(SecondaryButton)`
  @media (max-width: ${breakpoints.portrait}) {
    width: 100%;
    margin-top: 0rem;
  }
`;

const StyledLogo = styled(WhiteLogo)`
  @media (max-width: ${breakpoints.portrait}) {
    display: none;
  }
`;

export default BannerShowcaseSignup;
