const TIME_CARD_STATUS = {
  open: "OPEN",
  accepted: "ACCEPTED",
  review: "REVIEW",
};

const TIME_CARD_OPTIONS = [
  {
    name: "Open",
    value: TIME_CARD_STATUS.open,
  },
  {
    name: "Accepted",
    value: TIME_CARD_STATUS.accepted,
  },
  {
    name: "In Review",
    value: TIME_CARD_STATUS.review,
  },
];

export { TIME_CARD_OPTIONS };

export default TIME_CARD_STATUS;
