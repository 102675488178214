import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTracking } from "hooks";
import { TRACKING_PAGE_VIEWS } from "constants/index";

/**
 * Tracks page views in the app
 */
const Tracker = () => {
  const location = useLocation();
  const locationPathName = location?.pathname;
  const locationBits = locationPathName?.split("/");
  const pathname = locationBits?.length > 0 && locationBits[locationBits.length - 1];
  const { trackPage, trackablePage } = useTracking();

  useEffect(() => {
    if (pathname && trackablePage(pathname)) {
      trackPage(TRACKING_PAGE_VIEWS[pathname].page_name, TRACKING_PAGE_VIEWS[pathname]);
    }
  }, [pathname]);

  return null;
};

export default Tracker;
