import React, { useState } from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useModalConfirmation, useToast } from "hooks";
import { ACCEPT_TIME_CARD, REOPEN_TIME_CARD, SEND_TIME_CARD_TO_DEEL } from "graphql/mutations";
import { MODAL_SIZE } from "components/Modal";
import { formatCurrency, formatDate } from "utils";
import Icon, { ICON_TYPE } from "components/Icon";
import { TIME_CARD_STATUS } from "constants/index";
import { UserFullName } from "components/User";
import { Row, Col } from "components/Containers";
import { colors } from "style";

/**
 * useTimeCard
 */
export default function useTimeCard() {
  const [showReopenForm, setShowReopenForm] = useState(false);
  const [selected, setSelected] = useState({});
  const { toast } = useToast();
  const { show } = useModalConfirmation();
  const [handleReopen, { loading: reopenTimeCardLoading }] = useMutation(REOPEN_TIME_CARD);
  const [handleAccept, { loading: acceptTimeCardLoading }] = useMutation(ACCEPT_TIME_CARD);
  const [handleSendToDeel, { loading: sendToDeelLoading }] = useMutation(SEND_TIME_CARD_TO_DEEL);

  /**
   * Get advanced options for timeCard
   *
   * @param {object} data
   * @param {boolean} isBatch
   * @param {array} listData
   * @param {array} selection
   * @param {boolean} disabled
   * @param {number} month
   * @param {number} year
   *
   * @returns {array}
   */
  const getAdvancedOptions = ({ data, isBatch, listData, selection, month, year }) => {
    const options = [];
    const status = data?.timeCard?.status;
    const deelLastSyncAt = data?.timeCard?.deelLastSyncAt;
    const timeCardId = data?.timeCard?.id;

    /**
     * Sets timeCard to "Accepted"
     */
    const acceptOption = {
      label: <FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.ApproveButton"} />,
      icon: ICON_TYPE.checkmark,
      onClick: () => handleAcceptTimeCard(timeCardId, selection, listData, isBatch),
    };

    /**
     * Sets timeCard to "Accepted" and then to "Sent to Deel"
     */
    const deelAcceptOption = {
      label: <FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.DeelApproveButton"} />,
      icon: ICON_TYPE.dollarBill,
      disabled: !data?.profile?.deelContractUrl,
      onClick: () => handleConfirmationModal(timeCardId, month, year, data),
    };

    /**
     * Opens contract overview page on Deel
     */
    const openDeelContract = {
      label: <FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.OpenDeelContract"} />,
      icon: ICON_TYPE.share,
      onClick: () => handleDeelNavigation(data?.profile?.deelContractUrl),
    };

    /**
     * Sets timeCard to "Open"
     */
    const reopenOption = {
      label: <FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.ReopenButton"} />,
      icon: ICON_TYPE.rotate,
      onClick: () => {
        setShowReopenForm(true);
        setSelected(data);
      },
    };

    if (status === TIME_CARD_STATUS.review || isBatch) {
      options.push(acceptOption);
    }

    if (status === TIME_CARD_STATUS.review) {
      options.push(reopenOption);
    }

    if (status === TIME_CARD_STATUS.accepted && !isBatch) {
      options.push(reopenOption, deelLastSyncAt ? openDeelContract : deelAcceptOption);
    }

    return options;
  };

  const handleConfirmationModal = async (id, month, year, data) => {
    const values = {
      name: <b>{`${data?.profile?.firstName} ${data?.profile?.lastName}`}</b>,
      count: <b>{`${data?.breakdown?.length}`}</b>,
      timespan: <b>{`${formatDate(data?.lastActivityDate, "MMM, YYYY")}`}</b>,
    };

    const response = await show(
      <FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.DeelTaskConfirmation"} />,
      <>
        <FormattedMessage id="TimeCard.TimeCardTable.TimeCardAdvanceOptions.DeelConfirmation" values={values} />
        <div css={styles.line_items_container}>
          {data.breakdown.map((breakdown) => (
            <>
              <div>
                <FormattedMessage
                  key={breakdown.assignmentId}
                  id="TimeCard.TimeCardTable.TimeCardAdvanceOptions.DeelConfirmationLineItem"
                  values={{
                    team: <b>{breakdown?.team?.name}</b>,
                    hours: <b>{breakdown?.timeLoggedFormatted}</b>,
                    rate: <b>{formatCurrency(breakdown?.rate, breakdown?.currency)}</b>,
                    total: (
                      <b>
                        {formatCurrency((breakdown?.timeLoggedMinutes / 60) * breakdown?.rate, breakdown?.currency)}
                      </b>
                    ),
                  }}
                />
              </div>
            </>
          ))}
        </div>
      </>,
      {
        showCancel: true,
        showSave: true,
        primaryButtonMessageId: "Global.Yes",
        size: "md",
      }
    );

    if (response) {
      handleSendTimeCardToDeel(id, month, year);
    }
  };

  const handleSendTimeCardToDeel = (id) => {
    handleSendToDeel({
      variables: {
        input: {
          id: id,
        },
      },
      onCompleted: () => {
        toast.success(<FormattedMessage id={"TimeCard.TimeCardsTable.TimeCardAdvanceOptions.Deel.Success"} />);
      },
      onError: () => {
        toast.error(<FormattedMessage id={"TimeCard.TimeCardsTable.TimeCardAdvanceOptions.Deel.Error"} />);
      },
    });
  };

  const handleAcceptTimeCard = (id, selection, listData, isBatch) => {
    handleAccept({
      variables: {
        input: {
          ids: isBatch ? selection : [id],
        },
      },
      onCompleted: (result) => {
        const { accepted, failedToAccept } = result.acceptTimeCard;

        if (isBatch) {
          const failed = failedToAccept?.map((item) => item.id);
          const acc = accepted?.map((item) => item.id);
          const failedTimeSheets = listData?.filter((item) => failed.indexOf(item.id) !== -1);
          const successTimeSheets = listData?.filter((item) => acc.indexOf(item.id) !== -1);

          handleApproveBatch(failedTimeSheets, successTimeSheets);
        } else if (accepted.length > 0) {
          toast.success(<FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.Success"} />);
        } else {
          toast.error(<FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.ApproveError"} />);
        }
      },
      onError: () => {
        toast.error(<FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.Error"} />);
      },
    });
  };

  const handleApproveBatch = async (failedTimeSheets, successTimeSheets) => {
    await show(
      <FormattedMessage id="TimeCard.TimeCardTable.TimeCardAdvanceOptions.ApproveBatchResultTitle" />,
      <Col gap="0.5rem">
        <div>
          <b>
            <FormattedMessage id="TimeCard.TimeCardTable.TimeCardAdvanceOptions.ApproveBatchResultSubtitle" />
          </b>
        </div>
        {successTimeSheets.length > 0 && (
          <>
            <p>
              <FormattedMessage id="TimeCard.TimeCardTable.TimeCardAdvanceOptions.ApproveBatchResultSuccess" />
            </p>
            {successTimeSheets.map((item) => (
              <Row key={item.id}>
                <Icon type={ICON_TYPE.checkFilled} color={colors.green} />
                <UserFullName data={item.profile} />
              </Row>
            ))}
          </>
        )}
        {failedTimeSheets.length > 0 && (
          <>
            <p>
              <FormattedMessage id="TimeCard.TimeCardTable.TimeCardAdvanceOptions.ApproveBatchResultFail" />
            </p>
            {failedTimeSheets.map((item) => (
              <Row key={item.id}>
                <Icon type={ICON_TYPE.unavailable} color={colors.red} />
                <UserFullName data={item.profile} />
              </Row>
            ))}
          </>
        )}
      </Col>,
      {
        showCancel: true,
        showSave: false,
        secondaryButtonMessageId: "Global.Close",
        size: MODAL_SIZE.small,
      }
    );
  };

  const handleReopenTimeCard = (id, note) => {
    handleReopen({
      variables: {
        input: {
          id,
          notes: note,
        },
      },
      onCompleted: () => {
        toast.success(
          <FormattedMessage
            id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.Reopen.Success"}
            values={{
              timeCardCount: 1,
            }}
          />
        );
      },
      onError: () => {
        toast.error(<FormattedMessage id={"TimeCard.TimeCardTable.TimeCardAdvanceOptions.Reopen.Error"} />);
      },
    });
  };

  const handleDeelNavigation = (url) => window.open(url, "_blank");

  return {
    getAdvancedOptions,
    showReopenForm,
    setShowReopenForm,
    acceptTimeCardLoading,
    reopenTimeCardLoading,
    sendToDeelLoading,
    handleReopenTimeCard,
    selected,
  };
}

const styles = {
  line_items_container: css`
    margin-top: 2rem;
  `,
};
