import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize, margins } from "style";
import Modal from "components/Modal";
import Form from "components/Form";
import { useTracking } from "hooks";
import { TRACKING_PAGE_VIEWS } from "constants/index";

/**
 * From wrapped in a Modal
 *
 * @params {Element}  title
 * @params {Element}  subTitle
 * @params {Element}  description
 * @params {Element}  info
 * @params {Boolean}  show
 * @params {Function} onClose
 * @params {Function} onDestructive
 * @params {Function} onCompleted
 * @params {String}   size
 * @params {Boolean}  isDestructable
 * @params {String}   mutationType
 * @params {Boolean}  loading
 * @params {String}   primaryButtonMessageId
 * @params {String}   secondaryButtonMessageId
 * @params {Node}     children
 * @params {Boolean}  showSave
 * @params {Boolean}  showCancel
 * @params {Function} onError
 * @params {Boolean}  disabled
 * @params {Node}     disclaimer
 * @params {String}   marginSize
 */
const ModalForm = ({
  title,
  subTitle,
  description,
  info,
  show,
  onClose,
  closeOnComplete,
  onDestructive,
  onCompleted,
  onError,
  size,
  isDestructable,
  mutationType,
  loading: loadingProp,
  primaryButtonMessageId,
  secondaryButtonMessageId,
  footer,
  children,
  showSave,
  showCancel,
  page,
  disabled,
  disclaimer,
  marginSize,
  ...props
}) => {
  const { trackPage, trackablePage } = useTracking();
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const childForm = useRef(null);
  const handleSubmit = () => childForm.current.triggerSubmitForm();

  const handleError = () => {
    handleTrack();
    setLoading(false);

    if (typeof onError === "function") onError();
  };

  const handleComplete = (payload) => {
    handleTrack();

    if (closeOnComplete && typeof onClose === "function") onClose();
    if (typeof onCompleted === "function") onCompleted(payload);
  };

  const handleClose = () => {
    if (page && trackablePage(page)) {
      trackPage(TRACKING_PAGE_VIEWS[page].page_name, TRACKING_PAGE_VIEWS[page]);
    }
    onClose();
  };

  const handleTrack = () => {
    if (page && trackablePage(page)) {
      trackPage(TRACKING_PAGE_VIEWS[page].page_name, TRACKING_PAGE_VIEWS[page]);
    }
  };

  // FE tests are failing by keeping margins.normal as default prop
  const FormDescription = () =>
    description ? <div css={styles.description(marginSize || margins.normal)}>{description}</div> : null;

  return (
    <Modal
      title={title}
      subTitle={subTitle}
      show={show}
      isDestructable={isDestructable}
      onDestructive={onDestructive}
      onClose={handleClose}
      onSubmit={handleSubmit}
      loading={loading || loadingProp}
      canSubmit={canSubmit}
      size={size}
      primaryButtonMessageId={primaryButtonMessageId}
      secondaryButtonMessageId={secondaryButtonMessageId}
      footer={footer}
      showSave={showSave}
      showCancel={showCancel}
      disabled={disabled}
      info={info}
    >
      <>
        <FormDescription />
        {children}
        <Form
          ref={childForm}
          onCompleted={handleComplete}
          onError={handleError}
          onLoadingState={setLoading}
          mutationType={mutationType}
          onRequiredValuesChange={setCanSubmit}
          disabled={disabled}
          noPadding
          {...props}
        />
        {disclaimer}
      </>
    </Modal>
  );
};

const styles = {
  description: (marginSize) => css`
    font-size: ${fontSize.small};
    line-height: 2.4rem;
    color: ${colors.grayAnatomyBase};
    margin-bottom: ${marginSize};
  `,
};

ModalForm.propTypes = {
  title: PropTypes.any,
  subTitle: PropTypes.string,
  description: PropTypes.any,
  info: PropTypes.any,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  closeOnComplete: PropTypes.bool,
  onDestructive: PropTypes.func,
  onCompleted: PropTypes.func,
  size: PropTypes.string,
  isDestructable: PropTypes.bool,
  mutationType: PropTypes.string,
  loading: PropTypes.bool, // loadingProp
  primaryButtonMessageId: PropTypes.string,
  secondaryButtonMessageId: PropTypes.string,
  children: PropTypes.node,
  showSave: PropTypes.bool,
  showCancel: PropTypes.bool,
  onError: PropTypes.func,
  footer: PropTypes.any,
  page: PropTypes.string,
  disabled: PropTypes.bool,
  disclaimer: PropTypes.node,
  marginSize: PropTypes.string,
};

ModalForm.defaultProps = {
  closeOnComplete: true,
};

export default ModalForm;
