/** @jsx jsx */
import styled from "@emotion/styled";
import { colors, fontSize } from "style";
import { withIcon, ICON_TYPE, ICON_SIZE } from "components/Icon";

/**
 * Primary
 */
const EditButton = styled.button`
  width: 100%;
  height: 4rem;
  min-height: 4rem;
  font-size: ${fontSize.small};
  font-weight: 600;
  background: none;
  color: ${colors.purpleRainBase};
  border-radius: 0.6rem;
  border: dashed 1px ${colors.grayAnatomyLight2};
  text-align: left;
  padding: 0px 1.2rem;
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  &:hover:not(:disabled) {
    color: ${colors.purpleRainDark1};
    border-color: ${colors.grayAnatomyLight1};
  }

  &:disabled {
    color: ${colors.grayAnatomyLight2};
    border-color: ${colors.grayAnatomyLight1};
  }

  & + button {
    margin-bottom: 1.6rem;
  }
`;

export default withIcon(EditButton, {
  type: ICON_TYPE.plus,
  size: ICON_SIZE.xlarge,
  color: "inherit",
});
