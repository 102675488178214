/** @jsx jsx */
import styled from "@emotion/styled";
import { colors, fontSize } from "style";

/**
 * FooterTableCell
 */
const FooterTableCell = styled.td`
  color: ${colors.purpleRainDark2};
  font-size: ${fontSize.small};
  vertical-align: top;
  padding: 1rem 1rem;
  line-height: 2;
  height: 4.7rem;

  &:first-of-type {
    padding-left: ${(props) => props.marginSize || `4rem`};
  }

  &:last-of-type {
    padding-right: ${(props) => props.marginSize || `4rem`};
  }
`;

export default FooterTableCell;
