import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useToast } from "hooks";
import { MUTATION_TYPE } from "hooks";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { SIZE, TYPE } from "components/Form";
import { ADD_PROMPT, UPDATE_PROMPT } from "graphql/mutations";
import { GET_PROMPTS } from "graphql/queries";

/**
 * PromptForm
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Object}    data
 * @param {Boolean}   loading
 * @param {Boolean}   isEditing
 * @param {Function}  onComplete
 */
const PromptForm = ({ show, onClose, data, loading, isEditing, onComplete, ...props }) => {
  const { toast } = useToast();

  if (isEditing && loading) return null;

  const handleComplete = (payload) => {
    onComplete(payload);
    onClose();
    toast.success(<FormattedMessage id={`Prompt.PromptForm.${isEditing ? `EditSuccess` : `CreateSuccess`}`} />);
  };

  return (
    <ModalForm
      data={FORM_DATA}
      show={show}
      size={MODAL_SIZE.medium}
      onClose={onClose}
      title={<FormattedMessage id={`Prompt.PromptForm.${isEditing ? `Edit` : `Create`}FormTitle`} />}
      description={
        <FormattedMessage id={`Prompt.PromptForm.${isEditing ? `Edit` : `Create`}FormDescription`} />
      }
      onCompleted={handleComplete}
      updateId={data?.id}
      initialValues={{
        title: data?.title,
        content: data?.content,
        isActive: data?.isActive
      }}
      mutationType={isEditing ? MUTATION_TYPE.update : MUTATION_TYPE.add}
      mutationData={{
        mutationName: {
          add: ADD_PROMPT,
          update: UPDATE_PROMPT,
        },
        refetchQueries: [GET_PROMPTS],
        refetchAfterMutate: true,
      }}
      {...props}
    />
  );
};

const FORM_DATA = [
  {
    items: [
      {
        label: "Title",
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "title",
          type: "text",
          maxLength: 100,
          required: true,
        },
      },
      {
        label: "Content",
        type: TYPE.textarea,
        size: SIZE.xlarge,
        properties: {
          name: "content",
          required: true,
          placeholder: "Add content...",
        },
      },
      {
        type: TYPE.checkbox,
        size: SIZE.xlarge,
        properties: {
          name: "isActive",
          label: <FormattedMessage id="Prompt.PromptForm.Activate.Label" />,
          description: <FormattedMessage id="Prompt.PromptForm.Activate.Description" />,
        },
      },
    ],
  },
];

PromptForm.defaultProps = {
  show: false,
  isEditing: false,
};

PromptForm.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
  loading: PropTypes.bool,
  isEditing: PropTypes.bool,
  onComplete: PropTypes.func,
};

export default PromptForm;
