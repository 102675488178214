import { gql } from "@apollo/client";
import {
  CORE_PROFILE_FIELDS,
  PROFILE_ID_FIELDS,
  OLD_LOCATION_FIELDS,
  TAGS_FIELDS,
  SKILLS_FIELDS,
  CORE_TEAM_ADMIN_FIELDS,
  CORE_FIELDS_FOR_LEADS,
  CORE_TEAM_MEMBER_FIELDS,
  ORGANIZATION_TEAM_FIELDS,
  ASSIGNMENTS_TEAM_FIELDS,
} from "graphql/fragments";

const GET_TEAM = (isAdmin, isSLTL) => gql`
${PROFILE_ID_FIELDS}
${CORE_PROFILE_FIELDS}
${OLD_LOCATION_FIELDS}
${TAGS_FIELDS}
${SKILLS_FIELDS}
${isAdmin ? CORE_TEAM_ADMIN_FIELDS : isSLTL ? CORE_FIELDS_FOR_LEADS : CORE_TEAM_MEMBER_FIELDS}
${ORGANIZATION_TEAM_FIELDS}
  query GetTeam($id: ID!, $organization: Boolean = false, $assignments: Boolean = false, $limit: Int = 4) {
    team(id: $id) {
      ${isAdmin ? "...TeamAdminFields" : isSLTL ? "...TeamLeadFields" : "...TeamMemberFields"}
      ...OrganizationTeamFields
      totalCapacity
      totalSellingCost
      sellingCurrency
      teamVersionType
      assignments(limit: $limit) @include(if: $assignments) {
        ${ASSIGNMENTS_TEAM_FIELDS}
        profile {
          ...ProfileIdFields
          ...ProfileFields
          ...SkillsFields
          ...TagsFields
          ...OldLocationFields
        }
      }
    }
  }
`;

export { GET_TEAM };
