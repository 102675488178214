import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { Grid, PageWrapper } from "components/Containers";
import { SearchInput, SearchWithFilters } from "components/Search";
import { PAGES, STATIC_FILTERS, TRACKING_PAGE_VIEWS } from "constants/index";
import { Banner, BannerShowcaseSignup, BookMeetingMemberBanner } from "components/Banner";
import { useAuth, useTracking, useParamFunctions } from "hooks";
import { HireButton } from "components/Profile";
import { NetworkList } from "components/Network";
import { ListFavoriteButton } from "components/Lists";
import Disclaimer from "components/Disclaimer";
import { PARAM_KEYS } from "hooks/useSearch";

/**
 * ClientSearch
 */
const ClientSearch = () => {
  const { trackPage } = useTracking();
  const { paramParse } = useParamFunctions();
  const [search, setSearch] = useState(paramParse(PARAM_KEYS.filters)?.search || "");
  const intl = useIntl();
  const { isShowcase, isClient } = useAuth();
  const staticFilters = isShowcase || isClient ? [STATIC_FILTERS.featured] : [];

  useEffect(() => {
    trackPage(TRACKING_PAGE_VIEWS[PAGES.clientSearch].page_name, TRACKING_PAGE_VIEWS[PAGES.clientSearch]);
  }, []);

  return (
    <>
      <SearchInput onChange={setSearch} initialValue={search}>
        <BannerShowcaseSignup />
      </SearchInput>
      <PageWrapper>
        <Helmet>
          <title>{intl.formatMessage({ id: "Meta.ClientSearch.Title" })}</title>
        </Helmet>
        <Banner keyName="showClientSearchBanner">
          <FormattedMessage
            id="Client.Search.Banner.Title1"
            values={{
              intro: (
                <b>
                  <FormattedMessage id="Client.Search.Banner.Title2" />
                </b>
              ),
            }}
          />
        </Banner>
        <Grid topMargin>
          <Grid.col start={1} end={13}>
            <SearchWithFilters
              staticFilters={staticFilters}
              search={search}
              actions={[HireButton, ListFavoriteButton]}
              renderer={NetworkList}
            />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <BookMeetingMemberBanner />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <Disclaimer />
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default ClientSearch;
