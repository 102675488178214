import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { GET_PROFILES } from "graphql/queries";
import { INSIGHT_METRICS_OPTIONS, INSIGHT_TYPES_OPTIONS } from "constants/index";
import Form, { TYPE, SIZE } from "components/Form";

/**
 * SquadInsightsFilterMenu
 * @param {string} teamId
 */
const SquadInsightsFilterMenu = ({ teamId, ...props }) => <Form data={COLUMNS(teamId)} {...props} />;

const COLUMNS = (teamId) => [
  {
    style: css`
      width: 100%;
      padding: 0;
    `,
    items: [
      {
        label: "Insights",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "types",
          placeholder: "Insight Types",
          options: INSIGHT_TYPES_OPTIONS,
          labelKey: "name",
          valueKey: "value",
          minLength: 0,
        },
      },
      {
        label: "Metrics",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "metrics",
          placeholder: "Insight Metrics",
          options: INSIGHT_METRICS_OPTIONS,
          labelKey: "name",
          valueKey: "value",
          minLength: 0,
        },
      },
      {
        label: "Profiles",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "profileIds",
          placeholder: "Search a profile...",
          queryName: GET_PROFILES,
          queryKeyName: "profiles",
          queryVariables: { filters: { teamId } },
          labelKey: "name",
          valueKey: "id",
          minLength: 0,
        },
      },
    ],
  },
];

SquadInsightsFilterMenu.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export default SquadInsightsFilterMenu;
