/** @jsx jsx */
import styled from "@emotion/styled";
import { colors, fontSize } from "style";
import { BUTTON_SIZE } from "components/Buttons";

/**
 * TertiaryButton
 */
const TertiaryButton = styled.button`
  height: ${(props) => props.size || BUTTON_SIZE.medium};
  min-height: ${(props) => props.size || BUTTON_SIZE.medium};
  font-size: ${fontSize.small};
  font-weight: 600;
  color: ${colors.purpleRainBase};
  background: ${colors.grayAnatomyLight5};
  border-radius: 0.6rem;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.2rem;
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;
  white-space: nowrap;
  gap: 0.5rem;

  ${(props) =>
    props.size === BUTTON_SIZE.small &&
    `
    padding: 0 1rem;
    font-size: ${fontSize.xsmall};
    font-weight:400;
    border-radius: 0.5rem;
  `}

  &:hover:not(:disabled) {
    color: ${colors.purpleRainBase};
    background: ${colors.grayAnatomyLight7};
  }

  &:disabled {
    color: ${colors.grayAnatomyLight2};
    box-shadow: none;
  }

  &.dropdown-toggle {
    &:after {
      content: "\\e911";
      font-family: "mission-icon";
      peak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      border-width: 0;
    }
  }
`;

export default TertiaryButton;
