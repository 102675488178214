const PARTNER_TYPES = [
  {
    name: "Active",
    value: "ACTIVE",
  },
  {
    name: "Inactive",
    value: "INACTIVE",
  },
];

export default PARTNER_TYPES;
