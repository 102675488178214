import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "components/Buttons";
import { EventForm } from "components/Events";

/**
 * EventAddButton
 */
const EventAddButton = props => {
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      <PrimaryButton onClick={() => setShowForm(true)}>
        <FormattedMessage id="Events.EventAddButton.Button" />
      </PrimaryButton>
      <EventForm show={showForm} onClose={() => setShowForm(false)} {...props} />
    </>
  );
};

export default EventAddButton;
