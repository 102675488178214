import React from "react";
import { FormattedMessage } from "react-intl";
import { Outlet } from "react-router-dom";
import { LINKS } from "constants/index";
import PageTitle from "components/PageTitle";
import { NavList } from "components/Nav";

/**
 * PlatformSettings
 */
const PlatformSettings = () => {
  const pages = [
    {
      label: <FormattedMessage id="Settings.PlatformAccess.Title" />,
      to: LINKS.setting_platform_access,
      isActive: true,
    },
    {
      label: <FormattedMessage id="Events.MissionEvents.Title" />,
      to: LINKS.setting_mission_events,
      isActive: true,
    },
    {
      label: <FormattedMessage id="Settings.CodeChallenges.H1" />,
      to: LINKS.settings_code_challenges,
      isActive: true,
    },
    {
      label: <FormattedMessage id="Settings.Prompts.H1" />,
      to: LINKS.setting_prompts,
      isActive: true,
    },
  ];

  return (
    <>
      <PageTitle
        title={<FormattedMessage id="Settings.Prompts.Back" />}
        actionBar={<NavList pages={pages} routerEnabled end={false} />}
        asHeader
      />
      <Outlet />
    </>
  );
};

export default PlatformSettings;
