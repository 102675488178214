import { gql } from "@apollo/client";

const GET_PROFILES_AFFINITIES = gql`
  query profilesAffinities($profileIds: [ID!]!, $month: String) {
    profilesAffinities(profileIds: $profileIds, month: $month) {
      profile {
        id
        firstName
        avatarUrl
        archetype
      }
      affinities {
        profile {
          id
          firstName
          avatarUrl
          archetype
        }
        value
      }
    }
  }
`;

export { GET_PROFILES_AFFINITIES };
