import React from "react";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { getYearFromDate, getMonthFromDate } from "utils";
import { breakpoints } from "style";

/**
 * ProfileMemberSince
 *
 * @params {Object}   data
 */
const ProfileMemberSince = ({ data }) => {
  if (!data || !data.memberSince) return null;

  return (
    <p css={styles.container}>
      <FormattedMessage
        id="Profile.ProfileBio.MemberSince"
        values={{
          month: getMonthFromDate(data.memberSince),
          year: getYearFromDate(data.memberSince),
        }}
      />
    </p>
  );
};

const styles = {
  container: css`
    font-size: ${fontSize.small};
    line-height: 1;
    color: ${colors.grayAnatomyLight1};
    text-align: center;
    margin-top: 1rem;

    @media (max-width: ${breakpoints.mobile}) {
      margin-bottom: 2rem;
    }
  `,
};

ProfileMemberSince.propTypes = {
  data: PropTypes.object,
};

export default ProfileMemberSince;
