import { GET_MISSION_ROLE_ELIGIBILITY } from "graphql/queries";
import { useQueryData } from "hooks";
import { useEffect, useState } from "react";

/**
 * useMissionRoleEligibility
 *
 * @param {String}  profileId
 * @param {String}  missionRoleId
 */
export default function useMissionRoleEligibility({ profileId, missionRoleId, skip }) {
  const keyName = 'missionApplicantEligibilityCheck';
  const [isEligible, setIsEligible] = useState(false);
  const [reason, setReason] = useState([]);
  const { loading, data, error } = useQueryData({
    queryName: GET_MISSION_ROLE_ELIGIBILITY,
    keyName,
    skip: skip || !profileId || !missionRoleId,
    variables: {
      profileId, 
      missionRoleId
    },
  });

  
  useEffect(() => {
    if (!loading && data && data[keyName]) {
      setIsEligible(data[keyName]?.eligible);
      setReason(data[keyName]?.unfulfilledPolicies);
    }
  },[data, loading]);

  return { loading, isEligible, reason, error };
}
