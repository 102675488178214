import moment from "moment";

/**
 * Get all the months between the given range
 *
 * @param {String} startDate
 * @param {String} endDate
 * @returns {Array}
 */
export default function getMonthsBetween(startDate, endDate) {
  // The start date is set to the beginning of the month in the correct time zone
  // which prevents issues with time zones shifting dates unintentionally
  const start = moment(startDate).startOf("month");
  const end = moment(endDate).startOf("month");
  const months = [];

  while (start.isSameOrBefore(end)) {
    months.push(start.format("MMM YYYY"));
    start.add(1, "month");
  }

  return months;
}
