/**
 * Format number
 *
 * @param {Number}    number
 * @param {String}    postFix
 * @param {Number}    minimumFractionDigits
 * @param {String}    style
 *
 * @returns {String} The formatted number
 */
export default function formatNumber(number, postFix = "", minimumFractionDigits = 0, style = "decimal") {
  if (!number && number !== 0) return "—";

  const userLocale = navigator.language || "en-US";
  const formattedNumber = Intl.NumberFormat(userLocale, { style, minimumFractionDigits }).format(number);

  return `${formattedNumber}${postFix}`;
}
