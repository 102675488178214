import React from "react";
import PropTypes from "prop-types";
import { colors } from "style";
import Tooltip from "components/Tooltip";

/**
 * FoundingMember
 *
 * @param {String}   fill
 */
const FoundingMember = ({ fill, ...props }) => (
  <Tooltip tooltipId={"Badges.FoundingMember"} hideIcon>
    <svg viewBox="0 0 136 134" {...props}>
      <path 
        d="M135.384 73.4423L126.863 36.0769C125.173 28.6554 120.196 22.4089 113.344 19.1002L78.8452 2.47385C71.9929 -0.824615 64.0071 -0.824615 57.1548 2.47385L22.6667 19.1105C15.8144 22.4089 10.8375 28.6657 9.14758 36.0872L0.626004 73.4423C-1.06388 80.8638 0.708438 88.6565 5.44837 94.6143L29.3232 124.579C34.0631 130.537 41.2555 134 48.8599 134H87.14C94.7445 134 101.937 130.526 106.677 124.579L130.552 94.6143C135.292 88.6668 137.064 80.8742 135.374 73.4423H135.384ZM103.039 98.0055C103.039 100.284 101.195 102.129 98.9177 102.129H86.2642C83.987 102.129 82.1425 100.284 82.1425 98.0055V85.3477C82.1425 83.0697 83.987 81.2246 86.2642 81.2246H98.9177C101.195 81.2246 103.039 83.0697 103.039 85.3477V98.0055ZM77.9384 98.0055C77.9384 100.284 76.0939 102.129 73.8167 102.129H61.1632C58.8859 102.129 57.0415 100.284 57.0415 98.0055V85.3477C57.0415 83.0697 58.8859 81.2246 61.1632 81.2246H73.8167C76.0939 81.2246 77.9384 83.0697 77.9384 85.3477V98.0055ZM52.8374 98.0055C52.8374 100.284 50.9929 102.129 48.7157 102.129H36.0621C33.7849 102.129 31.9405 100.284 31.9405 98.0055V85.3477C31.9405 83.0697 33.7849 81.2246 36.0621 81.2246H48.7157C50.9929 81.2246 52.8374 83.0697 52.8374 85.3477V98.0055ZM48.3963 76.9366C46.119 76.9366 44.2746 75.0915 44.2746 72.8135V60.1557C44.2746 57.8777 46.119 56.0326 48.3963 56.0326H61.0498C63.327 56.0326 65.1715 57.8777 65.1715 60.1557V72.8135C65.1715 75.0915 63.327 76.9366 61.0498 76.9366H48.3963ZM56.6087 35.0462C56.6087 32.7682 58.4532 30.9231 60.7304 30.9231H73.3839C75.6612 30.9231 77.5056 32.7682 77.5056 35.0462V47.704C77.5056 49.982 75.6612 51.8271 73.3839 51.8271H60.7304C58.4532 51.8271 56.6087 49.982 56.6087 47.704V35.0462ZM73.7446 76.9366C71.4674 76.9366 69.6229 75.0915 69.6229 72.8135V60.1557C69.6229 57.8777 71.4674 56.0326 73.7446 56.0326H86.3981C88.6754 56.0326 90.5198 57.8777 90.5198 60.1557V72.8135C90.5198 75.0915 88.6754 76.9366 86.3981 76.9366H73.7446Z"
        fill={fill || colors.orangeCountyBase}
      />
    </svg>
  </Tooltip>
);

FoundingMember.propTypes = {
  fill: PropTypes.string,
};

export default FoundingMember;
