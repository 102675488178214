import { GET_CLIENT } from "graphql/queries";
import { useQueryData } from "hooks";

export default function useSignupDetails({ showcaseId }) {
  const { data, loading } = useQueryData({
    queryName: GET_CLIENT,
    keyName: "client",
    variables: { uuid: showcaseId },
  });

  return {
    loading,
    data: data?.showcaseUserFetch,
  };
}
