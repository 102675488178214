import moment from "moment";

/**
 * Format a date from now
 *
 * @param {String}    str
 *
 * @returns {String} The formatted date
 */
export default function formatDateFromNow(str) {
  return moment(str).fromNow();
}
