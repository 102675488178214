import { gql } from "@apollo/client";
import { TEAM_SCORE_SUMMARY_FIELDS } from "graphql/fragments";

const GET_TEAM_CLIENT_SATISFACTION_SCORE_SUMMARY = gql`
  ${TEAM_SCORE_SUMMARY_FIELDS}
  query clientSatisfactionScoreSummaryForTeam($teamId: ID!) {
    clientSatisfactionScoreSummaryForTeam(teamId: $teamId) {
      ...TeamScoreSummaryFields
    }
  }
`;

const GET_TEAM_PULSE_SCORE_SUMMARY = gql`
  ${TEAM_SCORE_SUMMARY_FIELDS}
  query pulseScoreSummaryForTeam($teamId: ID!) {
    pulseScoreSummaryForTeam(teamId: $teamId) {
      ...TeamScoreSummaryFields
    }
  }
`;

const GET_TEAM_DYNAMICS_SCORE_SUMMARY = gql`
  ${TEAM_SCORE_SUMMARY_FIELDS}
  query teamDynamicsScoreSummary($teamId: ID!) {
    teamDynamicsScoreSummary(teamId: $teamId) {
      ...TeamScoreSummaryFields
    }
  }
`;

export { GET_TEAM_CLIENT_SATISFACTION_SCORE_SUMMARY, GET_TEAM_PULSE_SCORE_SUMMARY, GET_TEAM_DYNAMICS_SCORE_SUMMARY };
