/**
 * Removes the Javscript date offset
 *
 * @param {String} dateStr
 *
 * @returns {String}
 */
export default function convertDateToUTC(dateStr) {
  const _date = new Date(dateStr);

  return new Date(_date.getTime() + Math.abs(_date.getTimezoneOffset() * 60000));
}
