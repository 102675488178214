const TEAM_TYPES = {
  team: "TEAM",
  userGroup: "USERGROUP",
  external: "EXTERNAL",
};

const TEAM_TYPES_OPTIONS = [
  {
    label: "General",
    value: TEAM_TYPES.team,
  },
  {
    label: "User Group",
    value: TEAM_TYPES.userGroup,
  },
  {
    label: "External (For Signal)",
    value: TEAM_TYPES.external,
  },
];

export { TEAM_TYPES };

export default TEAM_TYPES_OPTIONS;
