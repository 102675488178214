import { gql } from "@apollo/client";

const GET_TEAM_VERSIONS = gql`
  query teamVersions($teamId: ID!, $filters: VersionFilter) {
    teamVersions(teamId: $teamId, filters: $filters) {
      nodes {
        id
        name
        slug
        type
        title
        status
        startDate
        endDate
      }
    }
  }
`;

export { GET_TEAM_VERSIONS };
