import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import Tooltip from "components/Tooltip";
import { TextInput, Select, Label } from "components/Form";
import { EXTERNAL_LINK_TYPES } from "../../constants";
import { EditButton } from "components/Buttons";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { colors } from "style";
import { Row, Grid } from "components/Containers";

/**
 * Links
 *
 * @params {String}   value
 * @params {String}   name
 * @params {Function} onChange
 */
const Links = ({ value, name, onChange }) => {
  const defaultValues = (uuid) => [
    {
      uuid,
      url: "",
      type: "",
      name: "",
    },
  ];

  const [localValue, setLocalValue] = useState(value || defaultValues(generateUuid()));

  // When updating (immutable)
  const handleUpdate = (itemName, itemValue, index) => {
    const clonedValues = [...localValue];

    clonedValues[index] = {
      ...clonedValues[index],
      ...{
        [itemName]: itemValue,
      },
    };

    setLocalValue(clonedValues);
  };

  // When adding an item (immutable)
  const handleAdd = (e) => {
    e.preventDefault();
    setLocalValue([...localValue, ...defaultValues(generateUuid())]);
  };

  // When removing an item (immutable)
  const handleRemove = (e, idx) => {
    e.preventDefault();
    setLocalValue([...localValue.slice(0, idx), ...localValue.slice(idx + 1, localValue.length)]);
  };

  // When localValue changes, send onChange event
  useEffect(() => {
    handleChange(localValue);
  }, [localValue]);

  const handleChange = useCallback(
    (val) => {
      onChange(name, val);
    },
    [onChange, name]
  );

  return (
    <>
      {!localValue ||
        (localValue.length === 0 && (
          <div>
            <EditButton onClick={handleAdd}>
              <FormattedMessage id="Global.Add" />
            </EditButton>
          </div>
        ))}
      {localValue && localValue.length > 0 && (
        <div css={styles.header}>
          <Grid css={styles.grid}>
            <Grid.col start={1} end={4}>
              <Label>
                <FormattedMessage id="Form.Links.LabelType" />
              </Label>
            </Grid.col>
            <Grid.col start={4} end={9}>
              <Label>
                <FormattedMessage id="Form.Links.LabelUrl" />
              </Label>
            </Grid.col>
            <Grid.col start={9} end={13}>
              <Tooltip tooltipId={"Profile.ProfileSocialIcons.Tooltip"}>
                <Label>
                  <FormattedMessage id="Form.Links.LabelName" />
                </Label>
              </Tooltip>
            </Grid.col>
          </Grid>
        </div>
      )}
      {localValue?.map((item, index) => (
        <Row key={index}>
          <div css={styles.icon_container} onClick={(e) => handleRemove(e, index)}>
            <Icon type={ICON_TYPE.line} size={ICON_SIZE.normal} color={colors.white} />
          </div>
          <div key={item.uuid} css={styles.container}>
            <div css={styles.form_container}>
              <Grid css={styles.grid}>
                <Grid.col start={1} end={4}>
                  <Select
                    name="type"
                    value={item.type}
                    onChange={(n, v) => handleUpdate(n, v, index)}
                    options={EXTERNAL_LINK_TYPES}
                    required={true}
                    defaultValue={EXTERNAL_LINK_TYPES[0].value}
                  />
                </Grid.col>
                <Grid.col start={4} end={9}>
                  <TextInput
                    name="url"
                    value={item.url}
                    onChange={(n, v) => handleUpdate(n, v, index)}
                    required={true}
                    placeholder="https://"
                    type="url"
                  />
                </Grid.col>
                <Grid.col start={9} end={13}>
                  <TextInput
                    name="name"
                    value={item.name}
                    onChange={(n, v) => handleUpdate(n, v, index)}
                    required={true}
                  />
                </Grid.col>
              </Grid>
            </div>
          </div>
        </Row>
      ))}
      {localValue && localValue.length > 0 && (
        <Row>
          <div css={[styles.icon_container, styles.icon_add]} onClick={handleAdd}>
            <Icon type={ICON_TYPE.plus} size={ICON_SIZE.normal} color={colors.white} />
          </div>
        </Row>
      )}
    </>
  );
};

function generateUuid() {
  return Math.floor(Math.random() * 1000000000);
}

const styles = {
  container: css`
    flex: 1;
  `,
  form_container: css`
    margin-bottom: 1rem;
  `,
  icon_container: css`
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    background: ${colors.grayAnatomyLight2};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 0.7rem;

    &:hover {
      background: ${colors.red};
    }
  `,
  icon_add: css`
    margin-top: 1rem;
    background: ${colors.grayAnatomyLight6};

    &:hover {
      background: ${colors.green};
    }
  `,
  header: css`
    margin-left: 3rem;
  `,
  grid: css`
    column-gap: 1rem;
  `,
};

Links.propTypes = {
  value: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default Links;
