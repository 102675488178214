import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Switch, Select } from "components/Form";
import { colors } from "style";
import { capitalizeFirst } from "utils";
import { TIME_PERIODS } from "../../constants";
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeaderCell } from "components/Table";

/**
 * Schedule
 */
const Schedule = ({ value, name, onChange }) => {
  const handleChange = (itemName, itemValue, index) => {
    const clonedValue = [...value];

    clonedValue[index] = {
      ...clonedValue[index],
      ...{
        [itemName]: itemValue,
      },
    };
    onChange(name, clonedValue);
  };

  return (
    <Table layout="auto" fullWidth>
      <TableHead>
        <TableRow>
          <TableHeaderCell colSpan={2} fullWidth>
            <FormattedMessage id="Form.Schedule.TitleDay" />
          </TableHeaderCell>
          <TableHeaderCell>
            <FormattedMessage id="Form.Schedule.TitleTo" />
          </TableHeaderCell>
          <TableHeaderCell>
            <FormattedMessage id="Form.Schedule.TitleFrom" />
          </TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {value.map((item, index) => {
          let weekday = item.weekday.toLocaleLowerCase();
          return (
            <TableRow key={item.id}>
              <TableCell width={100} css={[styles.week(item.working), styles.center]} fullWidth>
                {capitalizeFirst(weekday)}
              </TableCell>
              <TableCell width={50} css={styles.center}>
                <Switch
                  type="switch"
                  defaultChecked={item.working}
                  onChange={(name, value) => handleChange("working", value, index)}
                />
              </TableCell>
              <TableCell>
                <Select
                  options={TIME_PERIODS}
                  value={item.dayStartsAt}
                  onChange={(name, value) => handleChange("dayStartsAt", value, index)}
                  isDisabled={!item.working}
                />
              </TableCell>
              <TableCell fullWidth>
                <Select
                  options={TIME_PERIODS}
                  value={item.dayEndsAt}
                  onChange={(name, value) => handleChange("dayEndsAt", value, index)}
                  isDisabled={!item.working}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const styles = {
  week: (isWorking) => css`
    color: ${isWorking ? colors.purpleRainDark2 : colors.grayAnatomyLight1};
  `,
  center: css`
    vertical-align: middle;
  `,
};

Schedule.defaultProps = {
  value: [],
};

Schedule.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default Schedule;
