import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Radio } from "components/Form";
import { Row } from "components/Containers";
import { RADIO_DIRECTION, RADIO_SIZE } from "components/Form/Radio";

/**
 * OperatorToggle
 *
 * @param {Function}  onChange
 * @param {Boolean}  value
 */
const OperatorToggle = ({ onChange, value, ...props }) => {
  const [val, setVal] = useState(value === true ? "and" : "or");
  const handleChange = (n, v) => {
    setVal(v);
    onChange(n, v === "and");
  };

  useEffect(() => {
    setVal(value === true ? "and" : "or");
  }, [value]);

  return (
    <Row>
      <Radio
        {...props}
        direction={RADIO_DIRECTION.horizontal}
        size={RADIO_SIZE.small}
        onChange={handleChange}
        value={val}
        options={[
          {
            value: "and",
            label: "and",
          },
          {
            value: "or",
            label: "or",
          },
        ]}
      />
    </Row>
  );
};

OperatorToggle.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

export default OperatorToggle;
