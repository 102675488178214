import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useQueryData, useToast } from "hooks";
import { GET_CONNECTION } from "graphql/queries";
import { UPDATE_CONNECTION } from "graphql/mutations";

/**
 * useConnection
 *
 * @param {String}  id
 * @param {String}  type
 * @param {String}  recordType
 * @param {String}  recordId
 * @param {Boolean} shouldSkip
 */
export default function useConnection({ id, type, recordType, recordId, shouldSkip }) {
  const { toast } = useToast();
  const [data, setData] = useState();

  const [handleUpdateConnection, { loading: updating }] = useMutation(UPDATE_CONNECTION);

  const {
    loading,
    data: response,
    error,
  } = useQueryData({
    queryName: GET_CONNECTION,
    keyName: "connection",
    skip: shouldSkip,
    variables: { id, type, recordType, recordId },
  });

  useEffect(() => {
    if (response) {
      setData(response.connection);
    }
  }, [response]);

  const updateConnection = (id, params) => {
    handleUpdateConnection({
      variables: {
        input: {
          id,
          params,
        },
      },
      onCompleted: () => toast.success("Connection updated successfully"),
      onError: () => toast.error("Failed to update connection"),
    });
  };

  return {
    loading,
    data,
    error,
    updating,
    updateConnection,
  };
}
