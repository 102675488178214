import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { MUTATION_TYPE, useToast } from "hooks";
import { MODAL_SIZE, ModalForm } from "components/Modal";
import { css } from "@emotion/react";
import { GET_MISSIONS } from "graphql/queries";
import { UPDATE_TEAM } from "graphql/mutations";
import { SIZE, TYPE } from "components/Form";

/**
 * EditSquadMissionForm
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Object}    mission
 * @param {Object}    team
 */
const EditSquadMissionForm = ({ show, onClose, mission, team }) => {
  const { toast } = useToast();

  const initialValues = {
    name: team?.name,
    missionId: mission?.id,
  };

  const handleComplete = () => {
    toast.success(<FormattedMessage id="Squads.EditSquadMissionForm.Success" />);
  };

  const handleError = () => {
    toast.error(<FormattedMessage id="Squads.EditSquadMissionForm.Error" />);
  };

  return (
    <ModalForm
      title={<FormattedMessage id="Squads.EditSquadMissionForm.Title" />}
      data={FORM_DATA({ id: mission?.id, nameWithOrganization: mission?.nameWithOrganization })}
      initialValues={initialValues}
      updateId={team?.id}
      mutationType={MUTATION_TYPE.update}
      mutationData={{
        mutationName: {
          update: UPDATE_TEAM,
        },
      }}
      show={show}
      size={MODAL_SIZE.small}
      onClose={onClose}
      onCompleted={handleComplete}
      onError={handleError}
    />
  );
};

const FORM_DATA = (selection) => [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: <FormattedMessage id="Squads.EditSquadMissionForm.SquadName" />,
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "name",
          placeholder: "Type here...",
          required: true,
        },
      },
      {
        label: <FormattedMessage id="Squads.EditSquadMissionForm.Mission" />,
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "missionId",
          queryName: GET_MISSIONS,
          queryKeyName: "missions",
          queryFieldName: "missions",
          queryVariables: { includeRoles: false },
          labelKey: "nameWithOrganization",
          valueKey: "id",
          isSearchable: true,
          isClearable: true,
          noOptionsMessage: "No missions found",
          querySearchFieldName: "name",
          placeholder: "Search by name...",
          selection,
        },
      },
    ],
  },
];

EditSquadMissionForm.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mission: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
};

export default EditSquadMissionForm;
