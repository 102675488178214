import { gql } from "@apollo/client";

const MOVE_PROFILE_TO_VETTING = gql`
mutation MoveProfileToVetting($input: MoveProfileToVettingInput!) {
    moveProfileToVetting(input: $input) {
      profile {
        id
      }
    }
  }
`;

export { MOVE_PROFILE_TO_VETTING };
