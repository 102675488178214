import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { pointer } from "d3-selection";
import { css } from "@emotion/react";
import { COLOR_TYPES } from "components/ActivityLog";
import { TEAM_ACTIVITY_TYPES } from "constants/index";
import { ActivityTooltip } from "components/ActivityTooltip";

/**
 * ActivityLogCell
 *
 * @param {Object}   activities
 * @param {Number}   width
 * @param {Number}   height
 */
const ActivityLogCell = ({ activities, width: widthProp, height: heightProp, ...props }) => {
  const [data, setData] = useState([]);
  const [tooltipData, settooltipData] = useState({});
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [width, setWidth] = useState(widthProp);
  const [height, setHeight] = useState(heightProp);
  const ref = useRef();
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (!containerRef.current) return;

      setWidth(containerRef?.current?.offsetWidth);
      setHeight(containerRef?.current?.offsetHeight);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef.current]);

  useEffect(() => {
    // maps color and value from props based on TEAM_ACTIVITY_TYPES mapping
    // to make the data like [{ colorType: 0, value: 2 },....]
    // ignores 0 values as 0 values are showing up
    const buildData = () =>
      activities
        .map((activity) => ({
          colorType: colorTypeLookup[activity.type],
          value: activity.count,
          metaData: activity.metaData,
          type: activity.type,
        }))
        .filter(({ value }) => value > 0);

    setData(buildData());
  }, [activities]);

  useEffect(() => {
    // Clear the canvas by removing the existing SVG element
    d3.select(ref.current).select("svg").remove();

    const svg = d3.select(ref.current).append("svg").attr("width", width).attr("height", height);
    const range = width < 110 ? [3, 15] : [3, 25];
    const size = d3.scaleLinear().domain([0, 50]).range(range);

    // Initialize the circle: all located at the center of the svg area
    var node = svg
      .append("g")
      .selectAll("circle")
      .data(data)
      .join("circle")
      .attr("class", "node")
      .attr("r", (d) => size(d.value))
      .attr("cx", width / 2)
      .attr("cy", height / 2)
      .style("fill", (d) => COLOR_TYPES[d.colorType])
      .on("mouseover", function (event, data) {
        const [x, y] = pointer(event);

        settooltipData({ ...data.metaData, activityTypeIndex: data?.colorType, count: data?.value, ...props });
        setTooltipPosition({ x, y, width, height, clientX: event.clientX });
      })
      .on("mouseout", function () {
        settooltipData(null);
      });

    // Features of the forces applied to the nodes:
    const simulation = d3
      .forceSimulation()
      .force(
        "center",
        d3
          .forceCenter()
          .x(width / 2)
          .y(height / 2)
      ) // Attraction to the center of the svg area
      .force("charge", d3.forceManyBody().strength(1)) // Nodes are attracted one each other of value is > 0
      .force(
        "collide",
        d3
          .forceCollide()
          .strength(0.2)
          .radius((d) => size(d.value) + 2)
          .iterations(1)
      ); // Force that avoids circle overlapping

    simulation.nodes(data).on("tick", () => node.attr("cx", (d) => d.x).attr("cy", (d) => d.y));
  }, [data, width, height]);

  return (
    <div css={styles.container} ref={containerRef}>
      <svg width={width} height={height} ref={ref} />
      <ActivityTooltip tooltipData={tooltipData} tooltipPosition={tooltipPosition} />
    </div>
  );
};

const colorTypeLookup = Object.fromEntries(Object.values(TEAM_ACTIVITY_TYPES).map((value, index) => [value, index]));

const styles = {
  container: css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `,
};

ActivityLogCell.propTypes = {
  activities: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
};

ActivityLogCell.defaultProps = {
  width: 120,
  height: 120,
  activities: [],
};

export default ActivityLogCell;
