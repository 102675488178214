import React, { useState } from "react";
import { forwardRef } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import DropDown, { DropDownMenu, DropDownToggle } from "components/DropDown";
import { colors, fontSize } from "style";
import { CheckBoxGroup } from "components/Form";
import { Spinner } from "components/Loader";

/**
 * CheckBoxDropDown
 *
 * @param {String}   name
 * @param {Element}  label
 * @param {Array}    value
 * @param {Array}    options
 * @param {String}   valueKey
 * @param {String}   labelKey
 * @param {String}   descriptionKey
 * @param {Function} onChange
 * @param {Boolean}  hasIcon
 * @param {Boolean}  loading
 */
const CheckBoxDropDown = ({
  name,
  label,
  value,
  options,
  valueKey,
  labelKey,
  descriptionKey,
  onChange,
  hasIcon,
  loading,
  disabled,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = (nextShow) => setIsOpen(nextShow);

  return (
    <DropDown autoClose="outside" onToggle={handleToggle}>
      <DropDownToggle
        as={CustomToggle}
        hasIcon={hasIcon}
        isOpen={isOpen}
        disabled={!options || options?.length === 0 || disabled}
      >
        {loading ? (
          <Spinner size={`1.5rem`} />
        ) : (
          <>
            <span>{label}</span>
            <div css={styles.arrow_container}>
              <Icon
                type={ICON_TYPE.chevronDown}
                size={ICON_SIZE.medium}
                color={disabled ? colors.grayAnatomyLight3 : colors.grayAnatomyLight1}
              />
            </div>
          </>
        )}
      </DropDownToggle>
      <DropDownMenu css={styles.dropdownMenu}>
        <CheckBoxGroup
          name={name}
          value={value}
          options={options}
          valueKey={valueKey}
          labelKey={labelKey}
          descriptionKey={descriptionKey}
          onChange={onChange}
          {...props}
        />
      </DropDownMenu>
    </DropDown>
  );
};

const CustomToggle = forwardRef(({ children, onClick, isOpen, hasIcon, ...props }, ref) => (
  <button ref={ref} css={styles.button(isOpen, hasIcon)} onClick={onClick} {...props}>
    {children}
  </button>
));

const styles = {
  button: (isOpen, hasIcon) => css`
    display: flex;
    align-items:center;
    justify-content: space-between;
    font-size: ${fontSize.xsmall};
    background: #fff;
    font-weight: normal;
    height: 4rem;
    min-width:24rem;
    padding-left: ${hasIcon ? `3.4rem` : `1rem`};
    padding-right: 1.4rem !important;
    border-radius: 0.6rem;
    border: 1px solid ${colors.grayAnatomyLight3};
    width: 100%;
    color: ${colors.secondary};
    order: 1;
    resize: none;
    appearance: none;
    background-clip: padding-box;
    outline: none;
    transition: all 0.3s ease;
    line-height:1.7rem;
    cursor:pointer;

    &:after {
      display:none;
    }

    &:hover {
      border-color: ${colors.purpleRainBase};
    }

    ${
      isOpen &&
      `
      border-color: ${colors.purpleRainBase};
      box-shadow: 0px 0px 0px 3px ${colors.purpleRainLight3};

      & + label {
        color: ${colors.purpleRainBase};
      }
    `
    }

    &:disabled {
      border-color: ${colors.grayAnatomyLight3} !important;
      color: ${colors.grayAnatomyLight2} !important;
      background-color: ${colors.grayAnatomyLight5} !important;
      cursor: default;
    }
  }
  `,
  arrow_container: css`
    padding-top: 0.6rem;
  `,
  dropdownMenu: css`
    padding: 1rem 1.4rem;
    overflow: auto;
    max-height: 20rem;
  `,
};

CustomToggle.displayName = "CustomToggle";

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  hasIcon: PropTypes.bool,
};

CheckBoxDropDown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  value: PropTypes.array,
  options: PropTypes.array,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  descriptionKey: PropTypes.string,
  onChange: PropTypes.func,
  hasIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

CheckBoxDropDown.defaultProps = {
  valueKey: "id",
  labelKey: "label",
  descriptionKey: "description",
  options: [],
};

export default CheckBoxDropDown;
