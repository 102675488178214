import React from "react";
import { Outlet } from "react-router-dom";
import { PageWrapper } from "components/Containers";
import Header from "components/Header";
import Impersonating from "components/Impersonating";

/**
 * Home
 */
const Home = () => (
  <Header>
    <PageWrapper>
      <Outlet />
      <Impersonating offset={0} />
    </PageWrapper>
  </Header>
);

export default Home;
