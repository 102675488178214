import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { colors } from "style";
import ActionButton from "components/Buttons/Action";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { Spinner } from "components/Loader";

/**
 * EditIcon
 *
 * @param {Boolean} isRightSide
 * @param {Boolean} loading
 */
const EditIcon = ({ isRightSide, loading, ...props }) => (
  <ActionButton css={styles.action_button(isRightSide)} {...props}>
    {loading ? <Spinner size={`2rem`} /> : <Icon type={ICON_TYPE.edit} size={ICON_SIZE.medium} css={styles.icon} />}
  </ActionButton>
);

const styles = {
  action_button: (isRightSide) => css`
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.grayAnatomyLight1};
    border: 1px ${colors.grayAnatomyLight4} solid;

    ${isRightSide &&
    `
      transform: scale(-1, 1);
    `}

    &:hover {
      color: ${colors.purpleRainBase};
      border: 1px ${colors.grayAnatomyLight5} solid;
    }
  `,
  icon: css`
    color: inherit;
  `,
};

EditIcon.propTypes = {
  isRightSide: PropTypes.bool,
  loading: PropTypes.bool,
};

export default EditIcon;
