import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import DisplayBox from "components/DisplayBox";
import { GET_PROFILE } from "graphql/queries";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { SIZE, TYPE } from "components/Form";
import { UPDATE_PROFILE } from "graphql/mutations";
import { capitalizeFirst } from "utils";
import { colors } from "style";
import Icon, { ICON_TYPE } from "components/Icon";
import { PAGES } from "constants/index";

/**
 * Profile Rate & Availability
 *
 * @params {Array}    data
 */
const ProfileWorkSchedule = ({ data, ...props }) => (
  <DisplayBox
    title={<FormattedMessage id="Profile.ProfileWorkingSchedule.Title" />}
    numColumns={3}
    data={WS_DATA(data?.workSchedule)}
    parentKeyName="id"
    parentKeyValue={data?.id}
    updateId={data?.id}
    initialValues={{
      workSchedule: data?.workSchedule?.map((ws) => ({
        dayEndsAt: ws.dayEndsAt,
        dayStartsAt: ws.dayStartsAt,
        id: ws.id,
        working: ws.working,
        weekday: ws.weekday,
      })),
    }}
    isEditable={true}
    {...props}
  />
);

const WS_DATA = (data) =>
  data?.map((item) => ({
    id: item.id,
    title: <LineItemTitle item={item} />,
    body: <LineItemBody item={item} />,
    isEditable: false,
  }));

/**
 * Shows the body for respective day of week
 *
 * @params {Object}   item
 */
const LineItemBody = ({ item: { dayStartsAt, dayEndsAt, working } }) => (
  <span css={styles.body(working)}>
    <Icon
      type={working ? ICON_TYPE.clock : ICON_TYPE.unavailable}
      color={working ? colors.grayAnatomyLight1 : colors.grayAnatomyLight2}
    />
    <FormattedMessage
      id={working ? `ProfileWorkingSchedule.Body.Hours` : `ProfileWorkingSchedule.Body.NotAvailable`}
      values={{
        start: dayStartsAt,
        end: dayEndsAt,
      }}
    />
  </span>
);

/**
 * Shows the title for respective day of week
 *
 * @params {Object}   item
 */
const LineItemTitle = ({ item: { weekday } }) => capitalizeFirst(weekday);

const styles = {
  body: (isAvailable) => css`
    display: flex;
    align-items: center;
    color: ${isAvailable ? `inherit` : colors.grayAnatomyLight2};
    
    * {
      margin-right: 0.5rem;
    }
  `,
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        type: TYPE.schedule,
        size: SIZE.large,
        properties: {
          name: "workSchedule",
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Profile.ProfileWorkingSchedule.FormTitle" />,
  description: <FormattedMessage id="Profile.ProfileWorkingSchedule.FormDescription" />,
  numColumns: 3,
  mutationData: {
    mutationName: {
      add: UPDATE_PROFILE,
      update: UPDATE_PROFILE,
    },
    refetchQueries: [GET_PROFILE],
    refetchAfterMutate: false,
  },
  page: PAGES.workSchedule,
};

LineItemBody.propTypes = {
  item: PropTypes.object,
};

LineItemTitle.propTypes = {
  item: PropTypes.object,
};

ProfileWorkSchedule.propTypes = {
  data: PropTypes.object,
};

export default withModalForm(ProfileWorkSchedule, FORM_SETTINGS);
