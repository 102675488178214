import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "components/Buttons";
import { MEMBER_STATUS } from "constants/index";
import { useProfile } from "hooks";

/**
 * CandidateApproveButton
 *
 * @param {String} profileId
 */
const CandidateApproveButton = ({ profileId, ...props }) => {
  const { data, approving, handleApprove } = useProfile({ profileId });
  const [status, setStatus] = useState();

  useEffect(() => {
    setStatus(data?.profile?.status);
  }, [data?.profile]);

  if (!status || status === MEMBER_STATUS.rejected) {
    return null;
  }

  return (
    <PrimaryButton disabled={approving} variant="green" css={styles.button} onClick={handleApprove} {...props}>
      <FormattedMessage id="Candidate.CandidateApproveButton.CTA" />
    </PrimaryButton>
  );
};

const styles = {
  button: css`
    width: 100%;
  `,
};

CandidateApproveButton.propTypes = {
  profileId: PropTypes.string,
};

export default CandidateApproveButton;
