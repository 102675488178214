import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

/**
 * User Capacity
 *
 * @params {Object}   data
 */
const UserCapacity = ({ data }) => {
  if (!data) return null;

  return <FormattedMessage id={`User.CapacityStatus_${String(data?.capacityStatus).toLowerCase()}`} />;
};

UserCapacity.propTypes = {
  data: PropTypes.object,
};

export default UserCapacity;
