import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { css } from "@emotion/react";
import { useParams } from "react-router";
import { PageWrapper, Grid } from "components/Containers";
import { DailyReportStatusButton, DailyReportForm } from "components/Squads/settings";
import { useDailyReportSettings, useTeam } from "hooks";
import { Spinner } from "components/Loader";
import { margins } from "style";
import PageTitle from "components/PageTitle";
import Card from "components/Card";
import { Select, SIZE } from "components/Form";
import { snakeToCapitalized } from "utils";

/**
 * DailyReportSettings
 */
const DailyReportSettings = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [templateId, setTemplateId] = useState();
  const { id } = useParams();
  const { loading: teamLoading, data: team, refetch } = useTeam({ id });
  const editMode = !!team?.dailyReportUuid;
  const {
    loading: settingsLoading,
    data: settings,
    refetch: refetchSettings,
  } = useDailyReportSettings({
    id: team?.dailyReportUuid,
    skip: !team?.id,
  });
  const { templates, report } = settings || {};
  const selectedTemplate = useMemo(() => templates?.find((t) => t.uuid === templateId), [templates, templateId]);
  const templateOptions = useMemo(
    () => templates?.map(({ uuid, name }) => ({ uuid, name: snakeToCapitalized(name) })),
    [templates]
  );

  const reportWithTemplate = useMemo(() => {
    if (editMode) {
      // update action on the backend requires template info which is not in the report
      const template = selectedTemplate || templates?.[0];
      return { ...report, templateId: template?.uuid, template: template?.name };
    }
    return report;
  }, [editMode, report, selectedTemplate, templates]);

  useEffect(() => {
    setLoading(teamLoading || settingsLoading);
  }, [teamLoading, settingsLoading]);

  if (loading) {
    return <Spinner margin={margins.normal} size={`1.5rem`} />;
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.DailyReport.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              subtitle={<FormattedMessage id="Squads.Settings.DailyReport.Title" />}
              actionBar={
                team?.dailyReportUuid ? (
                  <DailyReportStatusButton
                    isActive={settings?.report?.isEnabled}
                    teamId={id}
                    refetch={refetchSettings}
                  />
                ) : (
                  <Select
                    css={styles.template_selector}
                    value={templateId}
                    size={SIZE.medium}
                    name="templateId"
                    placeholder="Select a Daily Report Template"
                    options={templateOptions}
                    valueKey="uuid"
                    labelKey="name"
                    onChange={(_name, val) => setTemplateId(val)}
                    isClearable
                  />
                )
              }
            />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <Card css={styles.card}>
              <DailyReportForm
                settings={{ ...settings, report: reportWithTemplate }}
                team={team}
                isEdit={editMode}
                refetch={editMode ? refetchSettings : refetch}
                template={selectedTemplate}
              />
            </Card>
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

const styles = {
  card: css`
    padding: 0;
  `,
  template_selector: css`
    width: 25rem;
  `,
};

export default DailyReportSettings;
