/**
 * convertUrlToFileObject
 *
 * @param {String}    image
 * @param {String}    fileName
 *
 * @returns {Object} The File Object
 */
export default async function convertUrlToFileObject(image, fileName) {
  const response = await fetch(image);
  const blob = await response.blob();
  const file = new File([blob], fileName, {type: blob.type});
  return file;
}
