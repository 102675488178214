import React from "react";
import { css } from "@emotion/css";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import DisplayBox from "components/DisplayBox";
import { SIZE, TYPE } from "components/Form";
import Tags from "components/Tags";
import { useOrganization } from "hooks";
import { getLegacyCountryCode } from "utils";
import { UPDATE_ORGANIZATION } from "graphql/mutations";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { NO_OF_SOFTWARE_ENGINEERS, NO_OF_EMPLOYEES } from "constants/index";
import { GET_INDUSTRIES, GET_ORGANIZATION } from "graphql/queries";

/**
 * OrganizationBasicProfile
 */
const OrganizationBasicProfile = ({ ...props }) => {
  const { id } = useParams();
  const { data, loading, error } = useOrganization({ id })

  const profileData = {
    name: data?.name,
    noOfEngineers: data?.noOfEngineers,
    noOfEmployees: data?.noOfEmployees,
    location: data?.location,
    primaryContactPhone: data?.primaryContactPhone,
    country: {
      city: data?.city,
      state: data?.state,
      country: data?.country,
      country_code: getLegacyCountryCode(data?.country),
      label: data?.city,
    },
    industries: data?.industries?.map((item)=> ({
      id: item.id,
      name: item.name,
    })),
  };

  return (
    <DisplayBox
      title={
        <FormattedMessage
          id="Organization.OrganizationBasicProfile.Title"
        />
      }
      numColumns={2}
      data={BASIC_PROFILE_DATA(data)}
      parentKeyName="id"
      parentKeyValue={id}
      updateId={id}
      initialValues={profileData} 
      isEditable={true}
      loading={loading}
      error={error}
      {...props}
    />
  );
};

/**
 * Profile fields
 */

const BASIC_PROFILE_DATA = (data) => [
  {
    title: "Organization Name",
    body: data?.name,
  },
  {
    title: "Industry",
    body: <Tags data={data?.industries} />,
  },
  {
    title: "Number of Software Engineers",
    body: data?.noOfEngineers,
  },
  {
    title: "Number of Employees",
    body: data?.noOfEmployees,
  },
  {
    title: "Country",
    body: data?.country,
  },
  {
    title: "State or Province",
    body: data?.state,
  },
  {
    title: "City",
    body: data?.city,
  },
  {
    title: "Phone Number",
    body: data?.primaryContactPhone,
  },
  {
    title: "Address",
    body: data?.location,
  },
];

/**
 * FORM - Basic Organization fields
 */

 const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Organization Name",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "name",
          required: true,
        },
      },
      {
        label: "Phone Number",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "primaryContactPhone",
          required: true,
        },
      },
      {
        label: "Industry",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "industries",
          placeholder: "Type a industry...",
          queryName: GET_INDUSTRIES,
          queryKeyName: "industries",
          minLength: 0,
          required: true,
        },
      },
      {
        label: "Number of Software Engineers",
        type: TYPE.select,
        size: SIZE.medium,
        properties: {
          name: "noOfEngineers",
          required: true,
          options: NO_OF_SOFTWARE_ENGINEERS,
        },
      },
      {
        label: "Number of Employees",
        type: TYPE.select,
        size: SIZE.medium,
        properties: {
          name: "noOfEmployees",
          required: true,
          options: NO_OF_EMPLOYEES,
        },
      },
      {
        type: TYPE.geoLocator,
        size: SIZE.xlarge,
        properties: {
          name: "country",
          type: "text",
          required: true,
        },
      },
      {
        label: "Address",
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "location",
          required: true,
          type: "text",
          placeholder: "number, street, postal code",
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Organization.OrganizationBasicProfile.Title" />,
  mutationData: {
    mutationName: {
      add: UPDATE_ORGANIZATION,
      update: UPDATE_ORGANIZATION,
    },
    refetchQueries: [GET_ORGANIZATION],
    refetchAfterMutate: true,
  },
};

export default withModalForm(OrganizationBasicProfile, FORM_SETTINGS);
