import moment from "moment";

/**
 * Format a date
 *
 * @param {String}    str
 * @param {String}    format
 * @param {Boolean}   withTime
 *
 * @returns {String} The formatted date
 */
export default function formatDate(str, format = "MMM DD, YYYY", withTime = false) {
  const date = str || new Date();
  return withTime ? moment.utc(date).format(`${format} , ha z`) : moment.utc(date).format(format);
}
