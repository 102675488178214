import React from "react";
import PropTypes from "prop-types";
import DisplayBox from "components/DisplayBox";
import { PreferredRateTag } from "components/Profile";
import { FormattedMessage } from "react-intl";
import { TAG_SIZE } from "components/Tags";

/**
 * ApplicationRate
 *
 * @param {Object} data
 */
const ApplicationRate = ({ data, ...props }) => (
  <DisplayBox
    numColumns={3}
    hideHeader
    data={[
      {
        title: <FormattedMessage id="MissionRoleApplicant.DisplayBox.TitleRate" />,
        body: <PreferredRateTag rate={data?.rate} size={TAG_SIZE.medium} />,
      },
      {
        title: <FormattedMessage id="MissionRoleApplicant.DisplayBox.TitlePreferredRate" />,
        body: <PreferredRateTag rate={data?.profile?.rate} size={TAG_SIZE.medium} />,
      },
      {
        title: <FormattedMessage id="MissionRoleApplicant.DisplayBox.TitleSuggestedRate" />,
        body: <PreferredRateTag rate={data?.recommendedRate} size={TAG_SIZE.medium} />,
      },
    ]}
    {...props}
  />
);

ApplicationRate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ApplicationRate;
