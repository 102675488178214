import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { ProfileRate, ProfileAvailabilityToggle, ProfileAssignedRateAndCapacity, ProfileNav } from "components/Profile";
import { PageWrapper, Grid } from "components/Containers";
import PageTitle from "components/PageTitle";
import { useAuth } from "hooks";
import Card from "components/Card";
import { FEATURE_FLAGS } from "constants/index";

/**
 * Rate & Availabilities Page
 */
const Rate = () => {
  const { id } = useParams();
  const intl = useIntl();
  const { canViewEditRate, featureFlags, isAdmin } = useAuth();
  const showRate = featureFlags?.some(
    ({ enabled, name }) => name === FEATURE_FLAGS.hideProfilesRate && enabled === false
  );

  return (
    <>
      <ProfileNav id={id} />
      <PageWrapper>
        <Helmet>
          <title>
            {intl.formatMessage({
              id: canViewEditRate ? "Meta.Profiles.Rate.Title" : "Meta.Profiles.Availability.Title",
            })}
          </title>
        </Helmet>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              {...(isAdmin
                ? {
                    subtitle: (
                      <FormattedMessage id={canViewEditRate ? "Profile.Rate.Title" : "Profile.Availability.Title"} />
                    ),
                  }
                : {
                    title: (
                      <FormattedMessage id={canViewEditRate ? "Profile.Rate.Title" : "Profile.Availability.Title"} />
                    ),
                  })}
            />
          </Grid.col>
          <Grid.col start={1} end={9} row={2} rowSpan={100}>
            <ProfileRate id={id} />
          </Grid.col>
          <Grid.col start={9} end={13}>
            <ProfileAvailabilityToggle id={id} />
          </Grid.col>
          {showRate && (
            <Grid.col start={9} end={13}>
              <Card>
                <ProfileAssignedRateAndCapacity profileId={id} />
              </Card>
            </Grid.col>
          )}
        </Grid>
      </PageWrapper>
    </>
  );
};

Rate.propTypes = {
  id: PropTypes.any,
};

export default Rate;
