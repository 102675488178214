import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Form, { LAYOUT_DIRECTION, SIZE, TYPE } from "components/Form";
import { margins } from "style";
import { MUTATION_TYPE, useToast } from "hooks";
import { DAYS_OF_WEEK } from "constants/index";
import { toUcFirst } from "utils";
import { ADD_DAILY_REPORT_SETTINGS, UPDATE_DAILY_REPORT_SETTINGS } from "graphql/mutations";

/**
 * Daily Report Form
 *
 * @param {Object}    settings
 * @param {Object}    team
 * @param {Boolean}   isEdit
 * @param {Function}  refetch
 * @param {Object}    template
 */
const DailyReportForm = ({ settings, team, isEdit, refetch, template }) => {
  const [data, setData] = useState({});
  const { toast } = useToast();
  const { report, ...options } = settings;

  useEffect(() => {
    if (report && Object.keys(report).length) {
      setData(report);
    }
  }, [report]);

  useEffect(() => {
    if (template) {
      setData({ ...template, name: team?.name });
    }
  }, [template]);

  const handleComplete = () => {
    toast.success(<FormattedMessage id={`Squads.Squad.Settings.DailyReport.${isEdit ? "Update" : "Create"}Success`} />);
    refetch();
  };

  const {
    name,
    reportChannels,
    reportParticipants,
    reportQuestions,
    template: templateName,
    templateId,
    welcomeMessage,
    reportSchedule: { startDate, broadcastTime, days } = {},
  } = data;
  const values = {
    broadcastTime,
    name,
    startDate,
    welcomeMessage,
    channels: reportChannels?.map(({ name, detailedId }) => ({ name, detailedId })),
    days: days?.map((d) => ({ id: d, label: toUcFirst(d.toLowerCase()) })),
    participants: reportParticipants?.map(({ name, detailedId }) => ({ name, detailedId })),
    questions: {
      ...reportQuestions
        ?.map(({ title, id }, i) => ({ [id || i + 1]: title }))
        ?.reduce((val, obj = {}) => ({ ...val, ...obj })),
    },
    template: template?.name || templateName,
    templateId: template?.uuid || templateId,
  };

  const formProps = {
    values,
    mutationType: isEdit ? MUTATION_TYPE.update : MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: ADD_DAILY_REPORT_SETTINGS,
        update: UPDATE_DAILY_REPORT_SETTINGS,
      },
    },
    numColumns: 4,
    saveButton: {
      show: true,
      props: {
        css: styles.updateButton,
      },
    },
    parentKeyName: "teamId",
    parentKeyValue: team?.id,
    updateId: team?.id,
    onCompleted: handleComplete,
    data: FORM_DATA(reportQuestions, options),
    disabled: !isEdit && !template && !Object.keys(data).length,
  };

  return <Form {...formProps} />;
};

const styles = {
  updateButton: css`
    margin-bottom: ${margins.xsmall};
  `,
};

const FORM_DATA = (customQuestions, options) => [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: <FormattedMessage id="Squads.Squad.Settings.DailyReport.Form.MissionBot" />,
        type: TYPE.h4,
        size: SIZE.xlarge,
      },
      ...SETTINGS_FORM_ITEMS(customQuestions, options),
    ],
  },
];

const SETTINGS_FORM_ITEMS = (customQuestions = [], { channels, users }) => [
  {
    label: "Name",
    type: TYPE.input,
    size: SIZE.medium,
    properties: {
      name: "name",
      maxLength: 100,
      required: true,
    },
  },
  {
    label: "Start Date",
    type: TYPE.datePicker,
    size: SIZE.small,
    properties: {
      name: "startDate",
      required: true,
    },
  },
  {
    label: "Broadcast Time",
    type: TYPE.input,
    size: SIZE.small,
    properties: {
      name: "broadcastTime",
      maxLength: 100,
      required: true,
    },
  },
  {
    label: "Report Days",
    type: TYPE.checkBoxGroup,
    size: SIZE.xlarge,
    properties: {
      name: "days",
      required: true,
      options: DAYS_OF_WEEK,
      labelKey: "label",
      valueKey: "id",
      direction: LAYOUT_DIRECTION.horizontal,
    },
  },
  {
    label: "Participants",
    type: TYPE.tags,
    size: SIZE.xlarge,
    properties: {
      name: "participants",
      minLength: 0,
      options: users,
      required: true,
      labelKey: "name",
      valueKey: "detailedId",
    },
  },
  {
    label: "Channels",
    type: TYPE.tags,
    size: SIZE.xlarge,
    properties: {
      name: "channels",
      minLength: 0,
      options: channels,
      required: true,
      labelKey: "name",
      valueKey: "detailedId",
    },
  },
  {
    label: "Welcome Message",
    type: TYPE.input,
    size: SIZE.xlarge,
    properties: {
      name: "welcomeMessage",
      maxLength: 100,
      required: true,
    },
  },
  ...customQuestions.map(({ id }, i) => ({
    label: `Question ${i + 1}`,
    type: TYPE.input,
    size: SIZE.xlarge,
    parentObjectKey: "questions",
    properties: {
      name: id || i + 1,
      maxLength: 100,
      required: true,
    },
  })),
  ...HIDDEN_FORM_FIELDS,
];

const HIDDEN_FORM_FIELDS = [
  {
    type: TYPE.hidden,
    properties: {
      name: "template",
    },
  },
];

DailyReportForm.defaultProps = {
  settings: {},
  refetch: () => {},
};

DailyReportForm.propTypes = {
  settings: PropTypes.object,
  team: PropTypes.object,
  isEdit: PropTypes.bool,
  refetch: PropTypes.bool,
  template: PropTypes.object,
};

export default DailyReportForm;
