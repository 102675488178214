/** @jsx jsx */
import styled from "@emotion/styled";

/**
 * TableHead
 */
const TableFoot = styled.tfoot`
border-color: inherit;
border-style: solid;
border-width: 0;
box-sizing: border-box;
padding: 0;
margin: 0;
`;

export default TableFoot;
