/**
 * checkIsNull
 *
 * @param {string} str
 *
 * @returns {boolean}
 */
export default function isNull(str) {
  return str && str.length > 0 ? false : true;
}
