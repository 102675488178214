import React from "react";
import PropTypes from "prop-types";
import Modal, { MODAL_SIZE } from "components/Modal";
import { SearchWithFilters } from "components/Search";
import { NetworkList } from "components/Network";

/**
 * SearchModal
 *
 * @param {Object}    initialValues
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Function}  renderer
 * @param {Array}     actions
 * @param {Boolean}   isCardView
 * @param {Function}  onItemClick
 */
const SearchModal = ({ initialValues, show, onClose, renderer, actions, isCardView, onItemClick, ...props }) => (
  <Modal
    show={show}
    onClose={onClose}
    showSave={false}
    showCancel={true}
    secondaryButtonMessageId="Global.Close"
    size={MODAL_SIZE.xlarge}
    {...props}
  >
    <SearchWithFilters
      search={false}
      renderer={renderer}
      actions={actions}
      isCardView={isCardView}
      hideSearch={false}
      onItemClick={onItemClick}
      initialValues={initialValues}
    />
  </Modal>
);

SearchModal.propTypes = {
  initialValues: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  renderer: PropTypes.any,
  actions: PropTypes.array,
  isCardView: PropTypes.bool,
  onItemClick: PropTypes.func,
};

SearchModal.defaultProps = {
  renderer: NetworkList,
  actions: [],
};

export default SearchModal;
