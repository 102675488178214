import React from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { GET_PROFILE } from "graphql/queries";
import { ARCHIVE_MEMBER_PROFILE } from "graphql/mutations";
import { MODAL_SIZE, ModalForm } from "components/Modal";
import { useToast, useProfile } from "hooks";
import { ARCHIVE_REASON, PAGES } from "constants/index";
import { METHOD, SIZE, TYPE } from "components/Form";

/**
 * Profile Archive Form
 *
 * @params {String}   profileId
 * @params {Boolean}  show
 * @params {Function} onClose
 *
 */
const ProfileArchiveForm = ({ profileId, show, onClose }) => {
  const { toast } = useToast();
  const { data } = useProfile({ profileId });

  const [handleArchive, { loading: archiveProfileLoading }] = useMutation(ARCHIVE_MEMBER_PROFILE, {
    refetchQueries: [GET_PROFILE],
  });

  const handleSubmit = (formData) => {
    const { archiveReason, message } = formData;
    handleArchiveProfile(data?.profile?.id, archiveReason, message);
  };

  const handleArchiveProfile = (id, archiveReason, message) => {
    handleArchive({
      variables: {
        input: {
          id: id,
          params: {
            archiveReason: archiveReason,
            archiveDescription: message,
          },
        },
      },
      onCompleted: () => {
        onClose(false);
        toast.success(<FormattedMessage id={"Profile.ArchiveProfile.Success"} />);
      },
      onError: () => {
        onClose(false);
        toast.error(<FormattedMessage id={"Profile.ArchiveProfile.Error"} />);
      },
    });
  };

  return (
    <div>
      <ModalForm
        show={show}
        size={MODAL_SIZE.medium}
        onClose={() => onClose(false)}
        data={FORM_DATA(data?.profile)}
        title={<FormattedMessage id="Profile.Archive" />}
        onSubmit={handleSubmit}
        method={METHOD.post}
        loading={archiveProfileLoading}
        description={
          <FormattedMessage
            id="Profile.ProfileArchiveForm.Label"
            values={{
              name: data?.profile?.name,
              archetype: <FormattedMessage id={`User.Archetype_${String(data?.profile?.archetype).toLowerCase()}`} />,
            }}
          />
        }
        page={PAGES.archiveProfile}
      />
    </div>
  );
};

const FORM_DATA = () => [
  {
    style: css`
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
    `,
    items: [
      {
        type: TYPE.select,
        size: SIZE.xlarge,
        label: <FormattedMessage id="Profile.ProfileArchiveForm.Reason" />,
        properties: {
          name: "archiveReason",
          required: true,
          options: ARCHIVE_REASON,
        },
      },
      {
        label: <FormattedMessage id="Profile.ProfileArchiveForm.Notes" />,
        type: TYPE.textarea,
        properties: {
          name: "message",
        },
      },
    ],
  },
];

ProfileArchiveForm.propTypes = {
  profileId: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ProfileArchiveForm;
