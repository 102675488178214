import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { DataTableProvider } from "components/DataTable";
import { PAGINATION_TYPE } from "components/Pagination";
import { FormattedMessage } from "react-intl";
import { QUESTION_TYPES } from "constants";
import Status from "components/Status";
import { shortenText } from "utils";
import { colors, fontSize } from "style";

/**
 * QuestionsList
 *
 * @param {Array}     data
 * @param {Boolean}   loading
 * @param {Number}    resultsPerPage
 */
const QuestionsList = ({ data, loading, resultsPerPage, ...props }) => (
  <DataTableProvider
    keyName="questions"
    loading={loading}
    data={data}
    resultsPerPage={resultsPerPage}
    columns={COLUMN_DATA}
    paginationType={PAGINATION_TYPE.classic}
    paginationProps={{
      countMessage: "Global.Questions.Count",
    }}
    {...props}
  />
);

const COLUMN_DATA = [
  {
    label: <FormattedMessage id="Missions.Roles.QuestionsList.Wording" />,
    cell: ({ wording, properties }) => (
      <>
        <div css={styles.title}>{shortenText(wording, 120)}</div>
        <div css={styles.subtitle}>
          <FormattedMessage
            id="Missions.Roles.QuestionsList.CharactersLimit"
            values={{ limit: properties?.charactersLimit }}
          />
        </div>
      </>
    ),
    width: "70%",
  },
  {
    label: <FormattedMessage id="Missions.Roles.QuestionsList.Type" />,
    cell: ({ type }) => QUESTION_TYPES[type],
    width: "15%",
  },
  {
    label: <FormattedMessage id="Missions.Roles.QuestionsList.Optionality" />,
    cell: ({ properties }) => (
      <Status enabled={properties?.required}>
        <FormattedMessage id={`Global.${properties?.required ? "Yes" : "No"}`} />
      </Status>
    ),
    width: "15%",
  },
];

const styles = {
  title: css`
    font-weight: 400;
  `,
  subtitle: css`
    font-weight: 400;
    padding-top: 3px;
    font-size: ${fontSize.xsmall};
    color: ${colors.grayAnatomyLight1};
  `,
};

QuestionsList.defaultProps = {
  resultsPerPage: 20,
  data: [],
};

QuestionsList.propTypes = {
  resultsPerPage: PropTypes.number,
  loading: PropTypes.bool,
  data: PropTypes.array,
};

export default QuestionsList;
