/** @jsx jsx */
import styled from "@emotion/styled";
import { colors, fontSize } from "style";

/**
 * Primary
 */
const PrimaryButton = styled.button`
  height: ${(props) => props.size || BUTTON_SIZE.medium};
  min-height: ${(props) => props.size || BUTTON_SIZE.medium};
  font-size: ${(props) => (props.size === BUTTON_SIZE.large ? fontSize.normal : fontSize.small)};
  font-weight: 600;
  background: ${colors.purpleRainBase};
  color: #fff;
  border-radius: 0.6rem;
  border: solid 2px ${colors.purpleRainBase};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.size === BUTTON_SIZE.large ? `1rem 3.5rem` : `1rem 2.2rem`)};
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;
  gap: 0.5rem;

  ${(props) =>
    props.size === BUTTON_SIZE.small &&
    `
    padding: 0.5rem 1rem;
    font-size: ${fontSize.small};
    font-weight:500;
    border-radius: 0.6rem;
  `}

  &:hover:not(:disabled) {
    color: #fff;
    background: ${colors.purpleRainDark1};
    box-shadow: 0 1px 6px 0 rgba(56, 51, 148, 0.3);
  }

  ${(props) =>
    props.variant &&
    `
    background: ${colors[props.variant]};
    border-color: ${colors[props.variant]};

    &:hover:not(:disabled) {
      color: #fff;
      background: ${colors[props.variant]};
      box-shadow: 0 1px 6px 0 rgba(56, 51, 148, 0.3);
    }
  `}

  &:disabled {
    color: ${colors.grayAnatomyLight2};
    background: ${colors.grayAnatomyLight4};
    border-color: ${colors.grayAnatomyLight4};
    box-shadown: none;
    font-weight: 500;
  }

  &:focus {
    box-shadow: 0px 0px 0px 3px ${colors.purpleRainLight3};
  }

  &.dropdown-toggle {
    &:after {
      content: "\\e911";
      font-family: "mission-icon";
      peak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      border-width: 0;
    }
  }

  ${(props) =>
    props.isExpand &&
    `
    position:relative;

    &:before {
      content: " ";
      position: absolute;
      right: 0.25rem;
      top: 0.25rem;
      background: #fff;
      width:1.6rem;
      height:1.6rem;
      border-radius: 0.3rem;
      opacity:0.4;
    }

    &:after {
      content: "\\e926";
      font-family: "mission-icon";
      font-size:2rem;
      peak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      border-width: 0;
      position:absolute;
      top: 0.05rem;
      right:0.05rem;
      transform: rotate(45deg);
      color: ${colors.purpleRainBase};
    }
  `}
`;

const BUTTON_SIZE = {
  small: "3rem",
  medium: "4rem",
  large: "4.5rem",
};

export { BUTTON_SIZE };

export default PrimaryButton;
