import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useParams } from "react-router-dom";
import Form, { SIZE, TYPE } from "components/Form";
import { useAuth, useProfile } from "hooks";
import { ARCHETYPES, PARTNER_CHANNELS, READ_ONLY_CHANNELS } from "constants/index";
import { GET_ACQUISITION_SOURCE, GET_PARTNERS } from "graphql/queries";
import { margins } from "style";

/**
 * Profile Setting Form
 */
const ProfileSettingForm = () => {
  const { id } = useParams();
  const { data, loading, updateProfile } = useProfile({ profileId: id });
  const { isAdmin, isRecruiter } = useAuth();
  const [channel, setChannel] = useState();
  const [profileSource, setProfileSource] = useState();
  const [profile, setProfileData] = useState();
  const [allowedSkip, setAllowedSkip] = useState(true);
  const [defaultSourceName, setDefaultSourceName] = useState();

  const handleChannelChange = (value) => {
    setAllowedSkip(false);
    setChannel(value);
    setProfileSource(null);
  };

  const handleChangeSource = (source) => {
    setAllowedSkip(true);
    setProfileSource(source);
  };

  useEffect(() => {
    setProfileData(data?.profile);
    setProfileSource(data?.profile?.source?.id);
    setDefaultSourceName(data?.profile?.source?.name);
  }, [data?.profile]);

  const handleSubmit = (formData) => {
    const { email, missionEmail, partnerId, channel, archetype, deelContractId } = formData;

    updateProfile(id, {
      user: {
        email,
        missionEmail,
      },
      channel,
      source: profileSource,
      archetype,
      deelContractId,
      partnerId,
    });
  };

  if (loading || !profile) return null;

  const initialValues = {
    email: profile?.email,
    missionEmail: profile?.missionEmail,
    partnerId: profile?.partner?.id,
    channel: profile?.channel,
    source: profileSource,
    archetype: profile?.archetype,
    deelContractId: profile?.deelContractId,
  };

  return (
    <Form
      data={FORM_DATA(
        channel,
        defaultSourceName,
        handleChannelChange,
        handleChangeSource,
        allowedSkip,
        isAdmin,
        isRecruiter
      )}
      saveButton={{
        show: true,
        labelId: "Profile.Setting.FormSaveButton",
        disabled: loading,
      }}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      loading={loading}
      marginSize={margins.none}
      numColumns={4}
      noPadding
    />
  );
};

const FORM_DATA = (channel, defaultSourceName, handleChannelChange, handleChangeSource, allowedSkip, isAdmin) => [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Email",
        type: TYPE.h4,
        size: SIZE.xlarge,
      },
      {
        label: "Personal Email Address",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          required: true,
          name: "email",
          type: "email",
        },
      },
      {
        label: "Mission Email Address",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "missionEmail",
          type: "email",
        },
      },
      {
        label: "Partner",
        type: TYPE.h4,
        size: SIZE.xlarge,
      },
      {
        type: TYPE.select,
        size: SIZE.small,
        properties: {
          name: "partnerId",
          queryName: GET_PARTNERS,
          queryKeyName: "partners",
          queryFieldName: "partners",
          labelKey: "formattedName",
          valueKey: "id",
          isClearable: true,
        },
      },
      {
        label: "Acquisition",
        type: TYPE.h4,
        size: SIZE.xlarge,
      },
      {
        label: "Channel",
        type: TYPE.select,
        size: SIZE.small,
        properties: {
          name: "channel",
          required: false,
          reloadData: (channel) => handleChannelChange(channel),
          options: PARTNER_CHANNELS,
          labelKey: "label",
          valueKey: "value",
        },
      },
      {
        label: "Source",
        type: TYPE.select,
        size: SIZE.small,
        disabled: READ_ONLY_CHANNELS.includes(channel) || !channel,
        properties: {
          name: "source",
          queryVariables: { filters: { channel: channel } },
          queryName: GET_ACQUISITION_SOURCE,
          queryKeyName: "acquisitionSource",
          queryFieldName: "acquisitionSource",
          querySearchFieldName: "search",
          fetchPolicy: "network-only",
          nextFetchPolicy: "network-only",
          defaultSearch: defaultSourceName,
          labelKey: "name",
          valueKey: "id",
          isSearchable: true,
          allowedSkip: allowedSkip,
          reloadData: (source) => handleChangeSource(source),
        },
      },
      {
        label: "Role & Contract ID",
        type: TYPE.h4,
        size: SIZE.xlarge,
      },
      {
        label: "Role",
        type: TYPE.select,
        size: SIZE.small,
        properties: {
          name: "archetype",
          options: Object.values(ARCHETYPES),
          labelKey: "name",
          valueKey: "id",
        },
      },
      ...(isAdmin ? ADMIN_COLUMNS : []),
    ],
  },
];

const ADMIN_COLUMNS = [
  {
    label: "Deel Contract ID",
    type: TYPE.input,
    size: SIZE.small,
    properties: {
      name: "deelContractId",
      type: "text",
    },
  },
];

export default ProfileSettingForm;
