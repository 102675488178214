import { gql } from "@apollo/client";
import { CORE_MISSION_ROLE_FIELDS, EXTENDED_MISSION_FIELDS } from "graphql/fragments";

const GET_MISSION_ROLES = gql`
  ${EXTENDED_MISSION_FIELDS}
  ${CORE_MISSION_ROLE_FIELDS}
  query GetMissionRoles(
    $id: ID = null
    $filters: MissionRoleFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    missionRoles(id: $id, filters: $filters, first: $first, last: $last, after: $after, before: $before) {
      nodes {
        ...MissionRoleFields
        mission {
          ...ExtendedMissionFields
        }
        applicantsProfileIds
        missionRoleApplicant {
          id
          message
          profile {
            id
            description
            archetype
          }
          state
          appliedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export { GET_MISSION_ROLES };
