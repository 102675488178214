import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import DisplayBox from "components/DisplayBox";
import Tags from "components/Tags";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { SIZE, TYPE } from "components/Form";
import { UPDATE_PROFILE } from "graphql/mutations";
import { GET_DISCIPLINES } from "graphql/queries";
import { PAGES } from "constants/index";

/**
 * ProfileDisciplines
 *
 * @params {Array}    data
 */
const ProfileDisciplines = ({ data, ...props }) => {
  return (
    <DisplayBox
      title={<FormattedMessage id="Profile.ProfileBio.TitleDisciplines" />}
      editButtonText={<FormattedMessage id="Profile.ProfileBio.AddDisciplines" />}
      numColumns={1}
      data={DISCIPLINES_DATA(data)}
      parentKeyName="id"
      parentKeyValue={data?.id}
      updateId={data?.id}
      initialValues={{
        disciplines: data?.disciplines?.map((item) => ({
          name: item.name,
          id: item.id,
        })),
      }}
      {...props}
    />
  );
};

const DISCIPLINES_DATA = (data) => [
  {
    title: null,
    body: data?.disciplines?.length > 0 && <Tags data={data?.disciplines} />,
  },
];

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Disciplines",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "disciplines",
          placeholder: "Type a discipline...",
          queryName: GET_DISCIPLINES,
          queryKeyName: "disciplines",
          minLength: 0,
          autoFocus: true,
          defaultOpen: true,
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Profile.ProfileDisciplines.FormTitle" />,
  description: <FormattedMessage id="Profile.ProfileDisciplines.FormDescription" />,
  mutationData: {
    mutationName: {
      add: UPDATE_PROFILE,
      update: UPDATE_PROFILE,
    },
  },
  page: PAGES.profileDiscipline,
};

ProfileDisciplines.propTypes = {
  data: PropTypes.object,
};

export default withModalForm(ProfileDisciplines, FORM_SETTINGS);
