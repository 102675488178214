import React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { ADD_PARTNER, UPDATE_PARTNER } from "graphql/mutations";
import { GET_LOCATIONS, GET_PARTNERS } from "graphql/queries";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { TYPE, SIZE } from "components/Form";
import { PrimaryButton } from "components/Buttons";
import { PARTNER_CHANNELS } from "constants/index";

/**
 * Add (Edit) Partner
 *
 * @params {Object}     data
 * @params {Function}   onHideModal
 * @params {Boolean}    showModal
 * @params {Boolean}    onEdit        -withModalForm
 * @params {Boolean}    onAdd         -withModalForm
 */
const ButtonAddPartner = ({ data, showModal, onEdit, onAdd }) => {
  useEffect(() => {
    if (data.id && showModal) {
      onEdit(
        {
          name: data.name,
          website: data.website,
          slug: data.slug,
          channel: data.channel,
          trusted: data.trusted,
          canControlRate: data.canControlRate,
          memberTrial: data.memberTrial,
          location: data?.location?.id,
        },
        data.id
      );
    }
  }, [data, showModal]);

  const handleAdd = () => onAdd();

  return (
    <PrimaryButton onClick={handleAdd}>
      <FormattedMessage id="Partners.Button" />
    </PrimaryButton>
  );
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: <FormattedMessage id="Partners.ListPartners.FormName" />,
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "name",
          type: "text",
          maxLength: 100,
          required: true,
          autoFocus: true,
          placeholder: "Name of company",
        },
      },
      {
        label: <FormattedMessage id="Partners.ListPartners.FormWebsite" />,
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "website",
          type: "text",
          maxLength: 100,
          required: true,
          placeholder: "Company website address",
        },
      },
      {
        label: <FormattedMessage id="Partners.ListPartners.FormChannel" />,
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "channel",
          required: false,
          options: PARTNER_CHANNELS,
        },
      },
      {
        label: <FormattedMessage id="Partners.ListPartners.FormSlug" />,
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "slug",
          type: "text",
          maxLength: 100,
          required: true,
          placeholder: "Enter a slug",
        },
      },
      {
        label: <FormattedMessage id="Partners.ListPartners.FormCountry" />,
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "location",
          placeholder: "Type a location...",
          queryName: GET_LOCATIONS,
          queryKeyName: "locations",
          queryVariables: { filters: { search: null, locationType: "Country" } },
          querySearchFieldName: "search",
          allowedSkip: false,
          labelKey: "name",
          valueKey: "id",
        },
      },
      {
        type: TYPE.checkbox,
        size: SIZE.xlarge,
        properties: {
          name: "trusted",
          label: <FormattedMessage id="Partners.PartnerAddButton.FormPartnerTrustedTitle" />,
        },
      },
      {
        type: TYPE.checkbox,
        size: SIZE.xlarge,
        properties: {
          name: "canControlRate",
          label: <FormattedMessage id="Partners.PartnerAddButton.FormPartnerRestrictedTitle" />,
          description: <FormattedMessage id="Partners.PartnerAddButton.FormPartnerRestrictedDescription" />,
        },
      },
      {
        type: TYPE.checkbox,
        size: SIZE.xlarge,
        properties: {
          name: "memberTrial",
          label: <FormattedMessage id="Partners.PartnerAddButton.FormPartnerTryMembersTitle" />,
          description: <FormattedMessage id="Partners.PartnerAddButton.FormPartnerTryMembersDescription" />,
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.small,
  data: FORM_DATA,
  title: <FormattedMessage id="Partners.ModalPartner.Title" />,
  isDestructable: false,
  mutationData: {
    mutationName: {
      update: UPDATE_PARTNER,
      add: ADD_PARTNER,
    },
    refetchQueries: [GET_PARTNERS],
    refetchAfterMutate: true,
  },
};

ButtonAddPartner.propTypes = {
  data: PropTypes.object,
  onHideModal: PropTypes.func,
  showModal: PropTypes.bool,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
};

export default withModalForm(ButtonAddPartner, FORM_SETTINGS);
