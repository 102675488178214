import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { H3 } from "components/Text";
import { colors, fontSize } from "style";
import { Paragraph } from "components/Text";
import { Header } from "components/DisplayBox";
import { LAYOUT_SIZE } from ".";

/**
 * MissionAbout
 *
 * @param {String}   title
 * @param {String}   body
 * @param {String}   size
 * @param {Boolean}  isLoading
 * @param {Object}   cta
 */
const LayoutTextBlock = ({ title, body, size, cta, isLoading }) => (
  <>
    {title && (
      <Header>
        <H3>{title}</H3>
        {cta}
      </Header>
    )}
    <div css={styles.description(size)}>
      <Paragraph data={body} isLoading={isLoading} />
    </div>
  </>
);

const styles = {
  description: (size) => css`
    font-size: ${size === LAYOUT_SIZE.small ? fontSize.small : fontSize.normal};
    color: ${size === LAYOUT_SIZE.small ? colors.grayAnatomyLight1 : colors.purpleRainDark2};
    line-height: normal;
  `,
};

LayoutTextBlock.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  size: PropTypes.string,
  isLoading: PropTypes.bool,
  cta: PropTypes.node,
};

export default LayoutTextBlock;
