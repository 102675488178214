import { useEffect, useState } from "react";
import { breakpoints } from "style";

/**
 * useViewport
 */
export default function useViewport() {
  const offset = 20; // Because innerWidth is not always accurate
  const [width, setWidth] = useState(window?.innerWidth);
  const [isMobile, setIsMobile] = useState((window?.innerWidth - offset) < parseInt(breakpoints.tablet));

  useEffect(() => {
    setIsMobile((width - offset) < parseInt(breakpoints.tablet));
  }, [width, breakpoints.tablet, offset]);

  useEffect(() => {
    const handleWindowSizeChange = () => setWidth(window?.innerWidth);

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return {
    isMobile,
    width,
  };
}
