import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { MissionInviteMember } from "components/Missions";
import { ApplicantListLayout } from "components/Missions/Applicants";
import PageTitle from "components/PageTitle";
import { Grid } from "components/Containers";

/**
 * Candidates Page
 */
const Candidates = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Applicants.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            subtitle={<FormattedMessage id={`Missions.Applicants.H1`} />}
            actionBar={<MissionInviteMember />}
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <ApplicantListLayout />
        </Grid.col>
      </Grid>
    </>
  );
};

export default Candidates;
