import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Header } from "components/DisplayBox";
import { H3 } from "components/Text";
import { useQueryData } from "hooks";

/**
 * ChartDataProvider
 *
 * @param {String} title
 * @param {String} seriesField
 * @param {String} seriesName
 * @param {Function} renderer
 * @param {Function} formatData
 * @param {Boolean} loading
 * @param {String} queryName
 * @param {String} keyName
 * @param {String} teamId
 * @param {Boolean} skip
 * @param {Object} variables
 * @param {Array} filteredProfiles
 */
const ChartDataProvider = ({
  title,
  seriesField,
  seriesName,
  renderer,
  formatData,
  loading,
  queryName,
  keyName,
  teamId,
  skip,
  variables,
  filteredProfiles,
  ...props
}) => {
  const [formattedData, setFormattedData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { data, loading: queryLoading, error } = useQueryData({ queryName, keyName, skip, variables });
  const ChartComponent = renderer;

  useEffect(() => {
    const isFiltered = (item) =>
      filteredProfiles?.length === 0 || filteredProfiles?.indexOf(item?.profile?.uuid) !== -1;

    setFormattedData({
      teamId,
      seriesField,
      seriesName,
      ...formatData(data?.[keyName], isFiltered),
    });
  }, [data?.[keyName], filteredProfiles, teamId, formatData, keyName, seriesField, seriesName]);

  useEffect(() => {
    setIsLoading(queryLoading || loading);
  }, [queryLoading, loading]);

  return (
    <>
      <Header {...props}>
        <H3>
          <FormattedMessage id={title} />
        </H3>
      </Header>
      <ChartComponent data={formattedData} loading={isLoading} error={error} {...props} />
    </>
  );
};

ChartDataProvider.propTypes = {
  title: PropTypes.string,
  seriesField: PropTypes.string,
  seriesName: PropTypes.string,
  renderer: PropTypes.func,
  formatData: PropTypes.func,
  loading: PropTypes.bool,
  queryName: PropTypes.string,
  keyName: PropTypes.string,
  teamId: PropTypes.string,
  skip: PropTypes.bool,
  variables: PropTypes.object,
  filteredProfiles: PropTypes.array,
};

export default ChartDataProvider;
