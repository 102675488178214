import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { ActivityLogBody, ActivityLogHeader, ActivityPeriodSelector, COLOR_TYPES } from "components/ActivityLog";
import { useTeamActivityLogs } from "hooks";
import { getCurrentUTCDate } from "utils";
import { Row } from "components/Containers";
import { Header } from "components/DisplayBox";
import { H3 } from "components/Text";
import { PrimaryLink } from "components/Links";
import { LINKS, TEAM_ACTIVITY_TYPES } from "constants";
import Tooltip from "components/Tooltip";
import Legend from "components/Legend";
import { margins } from "style";
import { MessagesNoResults } from "components/Messages";

/**
 * SquadActivityLogLayout
 *
 * @param {Object}  teamId
 * @param {String}  titleId
 * @param {String}  marginSize
 * @param {String}  slug
 */
const SquadActivityLogLayout = ({ teamId, titleId, slug, marginSize, ...props }) => {
  const [selectedDate, setSelectedDate] = useState(getCurrentUTCDate());
  const { data, days, loading, error } = useTeamActivityLogs({ teamId, selectedDate });
  const handleChange = (_name, value) => setSelectedDate(value);

  return (
    <>
      <Header marginSize={marginSize} {...props}>
        <H3>
          <Tooltip tooltipId="Squads.SquadActivityLogLayout.Title.Tooltip">
            <FormattedMessage id={titleId || "Squads.SquadActivityLogLayout.Title"} />
          </Tooltip>
        </H3>
        <Row>
          <ActivityPeriodSelector value={selectedDate} onChange={handleChange} />
          <div css={styles.team_view}>
            <PrimaryLink to={LINKS.squad_activity_report(slug)} withMoreIcon routerEnabled>
              <FormattedMessage id="Squads.SquadActivityLogLayout.FullView" />
            </PrimaryLink>
          </div>
        </Row>
      </Header>
      {((data && data.length > 0) || loading) && (
        <>
          <Legend
            data={Object.values(TEAM_ACTIVITY_TYPES)?.map((type, index) => ({
              name: <FormattedMessage id={`Dashboard.ActivityLog.ActivityLogLayout.Legend.${type}`} />,
              color: COLOR_TYPES[index],
            }))}
            marginSize={marginSize}
          />
          <ActivityLogHeader showMemberCell={false} days={days} />
          <ActivityLogBody
            isMembersView={false}
            days={days}
            loading={loading}
            error={error}
            numRows={1}
            marginSize={marginSize}
            data={[
              {
                activityLogs: data,
              },
            ]}
          />
        </>
      )}
      {!loading && (!data || data.length === 0) && (
        <MessagesNoResults title="Squads.NoActivityTitle" description="Squads.NoActivityDescription" />
      )}
    </>
  );
};

const styles = {
  team_view: css`
    order: 1;
  `,
};

SquadActivityLogLayout.defaultProps = {
  marginSize: margins.none,
};

SquadActivityLogLayout.propTypes = {
  teamId: PropTypes.string.isRequired,
  titleId: PropTypes.string,
  marginSize: PropTypes.string,
  slug: PropTypes.string,
};

export default SquadActivityLogLayout;
