import { gql } from "@apollo/client";

const GET_SKILLS = gql`
  query GetSkills($filters: TagFilter) {
    skills(filters: $filters) {
      nodes {
        name
        id
      }
    }
  }
`;

export { GET_SKILLS };
