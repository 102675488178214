import React from "react";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";

/**
 * DropDownItem
 *
 * @params {Element}  children
 */
const DropDownItem = ({ children, ...props }) => (
  <Dropdown.Item as={CustomItem} {...props}>
    {children}
  </Dropdown.Item>
);

const CustomItem = ({ children, onClick, isDisabled }) => (
  <div onClick={!isDisabled && onClick} css={styles.dropdown_item(isDisabled)}>
    {children}
  </div>
);

const styles = {
  dropdown_item: (isDisabled) => css`
    height: 4rem;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    font-size: ${fontSize.xsmall};
    color: ${colors.purpleRainDark2};
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: 19rem;
    display: flex;
    gap: 0.5rem;
    ${isDisabled && `opacity: 0.5;`}
    ${isDisabled && `cursor: not-allowed;`}

    > * + * {
      margin-left: 0.8rem;
    }

    &:hover {
      background: ${colors.grayAnatomyLight5};
    }
  `,
};

CustomItem.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

DropDownItem.propTypes = {
  children: PropTypes.node.isRequired,
};

CustomItem.displayName = "CustomItem";

export default DropDownItem;
