import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { SET_PRIVACY_POLICY, SET_TERMS_SERVICE } from "graphql/mutations";

export default function useLegalModal(me) {
  const [needsSignTerms, setNeedsSignTerms] = useState(false);
  const [needsSignPrivacy, setNeedsSignPrivacy] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [handleTermService, { loading: termsSaving }] = useMutation(SET_TERMS_SERVICE);
  const [handlePrivacy, { loading: privacySaving }] = useMutation(SET_PRIVACY_POLICY);

  useEffect(() => {
    if (isInitial) {
      setNeedsSignTerms(me?.needsToSignTerms || false);
      setNeedsSignPrivacy(me?.needsToSignPrivacyPolicy || false);
      setIsInitial(false);
    }
  }, [me, isInitial]);

  const handleAcceptTerms = () => {
    handleTermService({
      variables: {
        input: {
          id: me?.id,
          params: {
            termsService: true,
          },
        },
      },
    }).then((result) => {
      setNeedsSignTerms(result?.setTermsService?.user?.needsToSignTerms);
    });
  };

  const handleAcceptPrivacy = () => {
    handlePrivacy({
      variables: {
        input: {
          id: me?.id,
          params: {
            privacyPolicy: true,
          },
        },
      },
    }).then((result) => {
      setNeedsSignPrivacy(result?.setPrivacyPolicy?.user?.needsToSignPrivacyPolicy);
    });
  };

  return {
    handleAcceptTerms,
    handleAcceptPrivacy,
    termsSaving,
    privacySaving,
    needsSignTerms,
    needsSignPrivacy,
  };
}
