const APPLICANT_TYPES = {
  applied: "APPLIED",
  declined: "DECLINED",
  retained: "RETAINED",
  selected: "SELECTED",
  unselected: "UNSELECTED",
  shortlisted: "SHORTLISTED",
  interviewed: "INTERVIEWED",
  recommended: "RECOMMENDED",
};

export default APPLICANT_TYPES;
